import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { nestableMinusIcon, plusIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';
import NestablePanelCrudItemList, {
	PanelItemCrudType,
} from '../NestablePanelCrudItemList';

const FoldContainer = styled.div<{ $isExpand: boolean; height: number }>`
	${({ $isExpand, height }) =>
		$isExpand
			? css`
					height: ${height}px;
			  `
			: css`
					height: 0;
			  `};

	transition: all 0.3s ease-in-out;
	overflow: hidden;
`;

const Fold = ({
	children,
	$isExpand,
}: PropsWithChildren<{
	$isExpand: boolean;
}>) => {
	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current && $isExpand) {
			setHeight(ref.current.getBoundingClientRect().height);
		}
	}, [$isExpand]);

	return (
		<FoldContainer $isExpand={$isExpand} height={height}>
			<div ref={ref}>{children}</div>
		</FoldContainer>
	);
};

type BasicDataItemType = { title: string; key: string };

export type CrudDataItemType = {
	isCreate: boolean;
	isRead: boolean;
	isUpdate: boolean;
	isDelete: boolean;
	isSuper: boolean;
} & BasicDataItemType;

interface CustomDataType {
	isCustomList: true;
	onCrudToggle?: () => void;
	data: {
		key: string;
		title: string;
		item: BasicDataItemType[];
	}[];
}

interface CrudDataType {
	isCustomList?: false;
	onCrudToggle: ({
		type,
		panelParentKey,
		panelItemKey,
	}: {
		type: PanelItemCrudType;
		panelParentKey: string;
		panelItemKey: string;
	}) => void;
	data: {
		key: string;
		title: string;
		item: CrudDataItemType[];
	}[];
}

type NestablePanelProps = (CustomDataType | CrudDataType) & {
	defaultExpandKeyList?: string[];
	style?: CSSObject;
};

const PanelSection = styled.section`
	padding-bottom: 16px;
`;

const StyledIcon = styled(Icon)``;

const NestablePanelHeader = styled.header`
	display: flex;
	align-items: center;
	background: ${({ theme }) => theme.common.colors.white};
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
	border-radius: 8px;
	padding: 10px 14px;
	${({ theme }) => theme.typo.b7};
	color: ${({ theme }) => theme.common.colors.gray_11};
	cursor: pointer;

	${StyledIcon} {
		margin-right: 8px;
	}
`;

function NestablePanels(props: PropsWithChildren<NestablePanelProps>) {
	const [expandKeyList, setExpandKeyList] = useState<string[]>([]);

	const {
		defaultExpandKeyList,
		data,
		style,
		isCustomList,
		children,
		onCrudToggle,
	} = props;

	useEffect(() => {
		if (defaultExpandKeyList) {
			setExpandKeyList(defaultExpandKeyList);
		} else {
			const allList = data.map((item) => item.key);
			setExpandKeyList(allList);
		}
	}, [defaultExpandKeyList, setExpandKeyList, data]);

	return (
		<article style={{ width: '100%' }}>
			{data.map((list) => {
				const isExpand = expandKeyList.includes(list.key);

				const handlePanelToggle = () => {
					if (isExpand) {
						setExpandKeyList((prev) => {
							return prev.filter((itemKey) => itemKey !== list.key);
						});
					} else {
						setExpandKeyList((prev) => {
							return [...prev, list.key];
						});
					}
				};

				return (
					<PanelSection style={style} key={list.key}>
						<>
							<NestablePanelHeader onClick={handlePanelToggle}>
								{isExpand ? (
									<StyledIcon width={20} height={20} src={nestableMinusIcon} />
								) : (
									<StyledIcon width={20} height={20} src={plusIcon} />
								)}

								{list.title}
							</NestablePanelHeader>

							<Fold $isExpand={isExpand}>
								<>
									{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
									{isCustomList && <>{children}</>}

									{!isCustomList && (
										<NestablePanelCrudItemList
											data={list.item as CrudDataItemType[]}
											parentKey={list.key}
											onCrudToggle={onCrudToggle}
										/>
									)}
								</>
							</Fold>
						</>
					</PanelSection>
				);
			})}
		</article>
	);
}

export default NestablePanels;

// const mkDt = [
// 	{
// 		key: 'userManage',
// 		title: '회원관리',
// 		item: [
// 			{
// 				itemTitle: '개인고객',
// 				key: 'private',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 			{
// 				itemTitle: '매니저',
// 				key: 'manager',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 			{
// 				itemTitle: '관리자',
// 				key: 'executive',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 		],
// 	},
// 	{
// 		key: 'taskManage',
// 		title: '작업관리',
// 		item: [
// 			{
// 				itemTitle: '',
// 				key: 'taskManage1',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 			{
// 				itemTitle: '',
// 				key: 'taskManage1',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 			{
// 				itemTitle: '',
// 				key: 'taskManage2',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 			{
// 				itemTitle: '',
// 				key: 'taskManage3',
// 				isCreate: false,
// 				isRead: true,
// 				isUpdate: false,
// 				isDelete: false,
// 			},
// 		],
// 	},
// {
// 	key: 'productManage',
// 	title: '상품관리',
// 	item: [
// 		{
// 			itemTitle: '',
// 			key: 'productManage1',
// 			isCreate: false,
// 			isRead: true,
// 			isUpdate: false,
// 			isDelete: false,
// 		},
// 		{ itemTitle: '', key: 'productManage2' },
// 		{ itemTitle: '', key: 'productManage3' },
// 		{ itemTitle: '', key: 'productManage4' },
// 	],
// },
// {
// 	key: 'orderManage',
// 	title: '주문관리',
// 	item: [
// 		{
// 			itemTitle: '',
// 			key: 'orderManage1',
// 			isCreate: false,
// 			isRead: true,
// 			isUpdate: false,
// 			isDelete: false,
// 		},
// 		{ itemTitle: '', key: 'orderManage2' },
// 		{ itemTitle: '', key: 'orderManage3' },
// 		{ itemTitle: '', key: 'orderManage4' },
// 	],
// },
// {
// 	key: 'leftoverManage',
// 	title: '재고관리',
// 	item: [
// 		{
// 			itemTitle: '',
// 			key: 'leftoverManage1',
// 			isCreate: false,
// 			isRead: true,
// 			isUpdate: false,
// 			isDelete: false,
// 		},
// 		{ itemTitle: '', key: 'leftoverManage2' },
// 		{ itemTitle: '', key: 'leftoverManage3' },
// 		{ itemTitle: '', key: 'leftoverManage4' },
// 	],
// },
// ];
