import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import 'antd/dist/reset.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

const TextEditorStyle = css`
	.ql-editor {
		&.ql-blank::before {
			font-style: normal;
			color: ${({ theme }) => theme.common.colors.gray_6};
		}
	}
`;

const AntdGlobalStyle = css`
	textarea.ant-input {
		transition: 0.3s;

		&:hover {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
			box-shadow: 0 0 0 2px ${({ theme }) => theme.common.colors.primary_1};
		}

		&:focus-within {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
			box-shadow: none;
		}
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		transition: 0.3s;

		&:hover {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
			box-shadow: 0 0 0 2px ${({ theme }) => theme.common.colors.primary_1};
		}

		&:focus-within {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main} !important;
			box-shadow: none !important;
		}
	}

	.ant-picker-focused {
		box-shadow: none;
	}

	.ant-pagination-item {
		border: none;
	}

	.ant-pagination-item.ant-pagination-item-active > a {
		color: ${({ theme }) => theme.common.colors.gray_11};
	}

	.ant-pagination-item > a {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}

	.ant-pagination-item-link {
		border: none !important;
	}

	.ant-pagination {
		display: flex;
		align-items: center;
	}

	.ant-pagination > li {
		order: 2;
	}

	.ant-pagination > .ant-pagination-options {
		order: 1;
		margin-left: 0;
		margin-right: 16px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 8px;
		padding: 0 12px;
	}

	.ant-input::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}

	.textarea.ant-input {
		border-radius: 8px;
	}

	.ant-form-item-label > label.ant-form-item-no-colon {
		&::before {
			content: '' !important;
		}

		&::after {
			content: '*';
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
		}
	}

	.ant-select-arrow {
		color: black;
	}

	.ant-picker.time-picker.ant-picker-focused {
		border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
		outline: none;
		box-shadow: none;
	}

	.ant-picker.time-picker {
		box-shadow: none;
		padding: 5px 10px;
		border: 1px solid ${({ theme }) => theme.common.colors.gray_4};

		&:hover {
			color: ${({ theme }) => theme.common.colors.primary_6_main};
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
			outline: 2px solid ${({ theme }) => theme.common.colors.primary_1};
		}

		&:disabled {
			background: ${({ theme }) => theme.common.colors.gray_2};
			border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
		}

		&::placeholder {
			color: ${({ theme }) => theme.common.colors.gray_6};
			${({ theme }) => theme.typo.input2};
		}
	}

	.ant-upload.ant-upload-drag .ant-upload {
		padding: 32px 0;
	}

	.ant-upload.ant-upload-drag {
		background: ${({ theme }) => theme.common.colors.white};
		border: 1px dashed ${({ theme }) => theme.common.colors.gray_4};
	}

	.ant-upload-list-text .ant-upload-span {
		height: 78px;
		padding: 16px 20px;
		border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
		border-radius: 12px;
	}

	.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-text {
		&:hover {
			background: none;
		}
	}

	.ant-upload-list-text-container {
		height: 78px;
	}

	.ant-upload.ant-upload-select-picture-card > .ant-upload {
		background: ${({ theme }) => theme.common.colors.white};
		border-radius: 8px;
	}

	.ant-picker.time-picker {
		width: 100%;
		border-radius: 8px;
	}

	.ant-radio-wrapper {
		color: ${({ theme }) => theme.common.colors.gray_11};
		appearance: none;
		align-items: center;

		.ant-radio {
			top: 0;
			appearance: none;
		}

		input {
			appearance: none;
			width: 24px;
			height: 24px;
			background: red;
		}
	}

	.ant-radio-input:focus + .ant-radio-inner,
	.ant-radio-checked .ant-radio-inner {
		appearance: none;
		border: 8px solid ${({ theme }) => theme.common.colors.primary_6_main};
	}

	.ant-radio-checked .ant-radio-inner {
		&:after {
			background: ${({ theme }) => theme.common.colors.white};
		}
	}

	.ant-radio-inner {
		appearance: none;
		width: 24px;
		height: 24px;
	}

	.ant-switch {
		width: 48px;
	}

	.ant-switch-checked {
		background: ${({ theme }) => theme.common.colors.primary_6_main};
	}

	.ant-select-multiple .ant-select-selection-item {
		background: ${({ theme }) => theme.common.colors.primary_1};
		border: 1px solid ${({ theme }) => theme.common.colors.primary_2};
		border-radius: 4px;
	}

	.ant-tag {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: rgba(0, 0, 0, 0.88);
		font-size: 15px;
		line-height: 20px;
		/* list-style: none; */
		display: inline-block;
		height: auto;
		margin-inline-end: 8px;
		padding-inline: 7px;
		white-space: nowrap;
		background: rgba(0, 0, 0, 0.02);
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		opacity: 1;
		transition: all 0.2s;
		text-align: start;
	}

	.ant-tag .ant-tag a .ant-tag a:hover {
		color: rgba(0, 0, 0, 0.88);
	}

	.ant-tag-magenta {
		color: #c41d7f;
		background: #fff0f6;
		border-color: #ffadd2;
	}

	.ant-tag-volcano {
		color: #d4380d;
		background: #fff2e8;
		border-color: #ffbb96;
	}

	.ant-tag-red {
		color: #cf1322;
		background: #fff1f0;
		border-color: #ffa39e;
	}

	.ant-tag-orange {
		color: #d46b08;
		background: #fff7e6;
		border-color: #ffd591;
	}

	.ant-tag-blue {
		color: #0958d9;
		background: #e6f4ff;
		border-color: #91caff;
	}

	.ant-tag-cyan {
		color: #08979c;
		background: #e6fffb;
		border-color: #87e8de;
	}

	.ant-tag-lime {
		color: #7cb305;
		background: #fcffe6;
		border-color: #eaff8f;
	}

	.ant-tag-green {
		color: #389e0d;
		background: #f6ffed;
		border-color: #b7eb8f;
	}

	.ant-tag-purple {
		color: #531dab;
		background: #f9f0ff;
		border-color: #d3adf7;
	}

	.ant-modal {
		top: 5vh;
	}
`;

const AgGridGlobalStyle = css`
	.ag-layout-normal {
		border: none;
	}

	.ag-pinned-right-header {
		border-left: 1px solid transparent;
	}

	.ag-header-cell,
	.ag-cell {
		padding: 0 8px;
	}

	.ag-cell,
	.ag-header-cell-text {
		font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
			'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
	}

	.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
	// disabled prettier anymore
			.ag-cell-range-single-cell
		) {
		border-left: 1px solid transparent;
	}

	.ag-theme-alpine,
	.ag-theme-alpine-dark {
		--ag-alpine-active-color: ${({ theme }) =>
			theme.common.colors.primary_6_main};
	}

	.ag-header-cell {
		${({ theme }) => theme.typo.hm8};
	}

	.ag-header-container .ag-header-cell:last-child {
		.ag-header-cell-resize {
			display: none;
		}
	}

	.ag-header.ag-pivot-off {
		border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_3};
	}
`;

const GlobalStyle = createGlobalStyle`
	${reset}

	html {
		font-family: Pretendard, 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, system-ui, "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
	}

	.ag-root-wrapper {
		font-family: Pretendard, sans-serif !important
	}
	
	.mt-4 {
		margin-top: 32px;
	}

	* {
		box-sizing: border-box;
	}

	input::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}


	#root, #root > div {
		height: 100%;
	}

	#toast {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	${TextEditorStyle}
	${AntdGlobalStyle}
	${AgGridGlobalStyle}
`;

export default GlobalStyle;

export const zIndex = {
	modal: 1000,
	alert: 1005,
	toast: 1010,
	fullLoading: 1015,
};
