import {
	GenerateSubscriptionProductDto,
	GeneratesubscriptionProductViewableState,
	SubscriptionProductDto,
	SubscriptionProductOnSale,
	SubscriptionProductResponseDto,
} from '../../../interface/subscriptionProducts';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const subscribeRegisterApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionProductsOnSale: builder.query<
			ListResponse<SubscriptionProductOnSale>,
			Record<any, any>
		>({
			query: () => ({
				method: 'GET',
				url: `/members/subscription-products`,
			}),
		}),
		getSubscriptionProducts: builder.query<
			ListResponse<SubscriptionProductOnSale>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/admin/subscription-products`,
				params: args,
			}),
			providesTags: ['SubscriptionProducts'],
		}),
		subscribeCreate: builder.mutation<
			ObjectResponse<SubscriptionProductResponseDto>,
			GenerateSubscriptionProductDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/admin/subscription-products`,
				body,
			}),
		}),
		updateSubscribeViewable: builder.mutation<
			ObjectResponse<SubscriptionProductResponseDto>,
			{ id: number; body: GeneratesubscriptionProductViewableState }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-products/${id}/viewable`,
				body,
			}),
		}),
		updateSubscribe: builder.mutation<
			ObjectResponse<SubscriptionProductResponseDto>,
			{ id: number; body: GenerateSubscriptionProductDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-products/${id}`,
				body,
			}),
		}),
		deleteSubscribe: builder.mutation<
			ObjectResponse<SubscriptionProductResponseDto>,
			number
		>({
			query: (id) => ({
				method: 'DELETE',
				url: `/members/admin/subscription-products/${id}`,
			}),
		}),
	}),
});

export const getSubscribeProductDetail = async (id: number) => {
	const url = `/members/admin/subscription-products/${id}`;

	const { data } = await axiosInstance.get<
		ObjectResponse<SubscriptionProductDto>
	>(url);

	return data.row;
};

export const {
	useGetSubscriptionProductsOnSaleQuery,
	useGetSubscriptionProductsQuery,
	useSubscribeCreateMutation,
	useUpdateSubscribeMutation,
	useUpdateSubscribeViewableMutation,
	useDeleteSubscribeMutation,
} = subscribeRegisterApi;
export default subscribeRegisterApi;
