import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import {
	PRODUCT_CREATE_PATH,
	PRODUCT_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	useGetProductsQuery,
	useUpdateIndividualProductMutation,
} from '../../../../store/apis/product';
import {
	PRODICT_STATE_OPTIONS,
	PRODUCT_STATE_VISIBLE_VALUE,
} from '../../../../utils/const';
import { createSelectRenderer } from '../../../../utils/row-data-util';
import gridData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

enum AlertState {
	NULL,
	UPDATE_STATE,
}

function ProductPage() {
	const setToast = useContext(ToastContext);

	const { formInitialValues, colsList, forms } = gridData;
	const navigate = useNavigate();
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NULL);
	const [updateStateBody, setUpdateStateBody] = useState<{
		id: number;
		state: string;
	} | null>(null);

	const [updateProductState] = useUpdateIndividualProductMutation();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		refetch,
	} = useGridDataWithRTK({
		useQuery: useGetProductsQuery,
		formInitialValues,
		colsList,
		detailPath: PRODUCT_DETAIL_PATH,
	});

	const goTo = (route: string) => {
		navigate(route);
	};

	const handleAlertClose = () => {
		setAlertState(AlertState.NULL);
	};

	const handleUpdateState = async () => {
		if (updateStateBody !== null) {
			const result = await updateProductState({
				id: updateStateBody.id,
				body: {
					productViewable:
						updateStateBody.state === PRODUCT_STATE_VISIBLE_VALUE,
				},
			});

			if ('error' in result) {
				setToast(
					'error',
					(result.error as CustomErrorType).data.translate ||
						(result.error as CustomErrorType).data.message,
				);
			} else {
				setToast('info', '상태변경이 완료되었습니다.');
				handleAlertClose();
				refetch();
			}

			setUpdateStateBody(null);
		}
	};

	const renderAlert = () => {
		if (alertState === AlertState.UPDATE_STATE) {
			return (
				<Alert
					title="상품 상태를 변경하시겠습니까?"
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="변경하기"
					onConfirmButtonClick={handleUpdateState}
					isVisible
				/>
			);
		}

		return null;
	};

	return (
		<>
			<GridContentTemplate
				gridRef={setGridApi}
				mainTitle="개별상품 관리"
				gridTitle="개별상품 목록"
				totalCount={totalCount}
				currentPage={page}
				currentPageSize={pageSize}
				onPageChange={handlePageChange}
				onPageSizeChange={handlePageSizeChange}
				rowData={rowData}
				cols={cols}
				formInitialValues={formInitialValues}
				forms={forms}
				onSearchSubmit={handleSearchSubmit}
				onRowClicked={handleDetailClick}
				frameworkComponents={{
					...createSelectRenderer({
						options: PRODICT_STATE_OPTIONS,
						onChange: (body) => {
							setUpdateStateBody(body);
							setAlertState(AlertState.UPDATE_STATE);
						},
					}),
				}}
				rightAccessory={
					<ButtonContainer>
						<Button onClick={() => goTo(PRODUCT_CREATE_PATH)} size="small">
							개별 상품 추가
						</Button>
					</ButtonContainer>
				}
			/>

			{renderAlert()}
		</>
	);
}

export default ProductPage;
