import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { LabelValue } from '../../../../utils/const';
import { phoneFormat } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';

const gridData: (optionList: LabelValue[]) => GridDataModel = (
	optionList: LabelValue[],
) => ({
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'updatedAt',
				headerName: '업데이트 일시',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'phoneCalledAt',
				headerName: '통화일시',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'category',
				headerName: '문의유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '휴대폰 번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'body',
				headerName: '내용',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'administratorName',
				headerName: '관리자명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'customerName',
			label: '고객명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerPhone',
			label: '휴대폰 번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'category',
			label: '문의유형',
			type: FilterElementType.SELECT,
			options: optionList,
		},
	],
});

export default gridData;
