import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	ADMIN_CREATE_PATH,
	ADMIN_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import userData from './gridData';
import DepartmentModal from '../../../../components/templates/DepartmentModal';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetAdministratorsQuery } from '../../../../store/apis/administrator';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function AdminPage() {
	const { formInitialValues, colsList, forms } = userData();
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetAdministratorsQuery,
		formInitialValues,
		colsList,
		detailPath: ADMIN_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(ADMIN_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="관리자"
			gridTitle="관리자 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			rightAccessory={
				<ButtonContainer>
					<DepartmentModal userType="ADMIN" />
					<Button onClick={handleCreateClick} size="small">
						관리자 등록
					</Button>
				</ButtonContainer>
			}
		/>
	);
}

export default AdminPage;
