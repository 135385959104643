import {
	TerminationDetailResponseDto,
	TerminationDto,
} from '../../../interface/termination';
import { axiosInstance } from '../../../network/axios';
import { ObjectResponse, ListResponse } from '../@types';
import { authApiSlice } from '../query';

const terminationApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionsCancellation: builder.query<
			ListResponse<TerminationDto>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/subscriptions-cancellation`,
				params: args,
			}),
		}),
		getSubscriptionsCancellationDetail: builder.query<
			ObjectResponse<TerminationDetailResponseDto>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-cancellation/${subscriptionNo}`,
			}),
		}),
		terminationFee: builder.mutation<
			ObjectResponse<TerminationDetailResponseDto>,
			{
				subscriptionNo: string;
				body: { cancellationFee: number; cancellationWord: string };
			}
		>({
			query: ({ subscriptionNo, body }) => ({
				method: 'PUT',
				url: `/members/subscriptions-cancellation/${subscriptionNo}`,
				body,
			}),
		}),
		terminationCancel: builder.mutation<
			ObjectResponse<TerminationDetailResponseDto>,
			{
				subscriptionNo: string;
			}
		>({
			query: ({ subscriptionNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-cancellation/${subscriptionNo}/withdrawal`,
			}),
		}),
		terminationApprovedConfirm: builder.mutation<
			ObjectResponse<TerminationDetailResponseDto>,
			{
				subscriptionNo: string;
			}
		>({
			query: ({ subscriptionNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-cancellation/${subscriptionNo}/confirmation-paid`,
			}),
		}),
		terminationConfirm: builder.mutation<
			ObjectResponse<TerminationDetailResponseDto>,
			{
				subscriptionNo: string;
			}
		>({
			query: ({ subscriptionNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-cancellation/${subscriptionNo}/confirmation`,
			}),
		}),
	}),
});

export const getTerminationApiDetail = async (subscriptionNo: string) => {
	const url = `/members/subscriptions-cancellation/${subscriptionNo}`;

	const { data } = await axiosInstance.get<
		ObjectResponse<TerminationDetailResponseDto>
	>(url);

	return data.row;
};

export const {
	useGetSubscriptionsCancellationQuery,
	useGetSubscriptionsCancellationDetailQuery,
	useTerminationFeeMutation,
	useTerminationCancelMutation,
	useTerminationApprovedConfirmMutation,
	useTerminationConfirmMutation,
} = terminationApi;
export default terminationApi;
