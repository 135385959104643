import {
	APP_VERSION_MANAGEMENT_OS_OPTION,
	APP_VERSION_MANAGEMENT_PLATFORM_OPTION,
} from '../../../../utils/const';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_OPTIONS_TEXT,
} from '../../../../utils/row-data-util';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'service',
				headerName: '플랫폼',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_OPTIONS_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'type',
				headerName: 'OS',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_OPTIONS_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'version',
				headerName: '버전',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'description',
				headerName: '릴리즈 노트',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 3,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'service',
			label: '플랫폼',
			type: FilterElementType.SELECT,
			options: APP_VERSION_MANAGEMENT_PLATFORM_OPTION,
		},
		{
			key: 'type',
			label: 'OS',
			type: FilterElementType.SELECT,
			options: APP_VERSION_MANAGEMENT_OS_OPTION,
		},
	],
};

export default userData;
