import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import { AxiosError } from 'axios';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import DateTimePicker from '../../../../components/atoms/DateTimePicker';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { CS_TEL_PATH } from '../../../../routes/constants/urls';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';

import { ButtonTypeType } from '../../../../styles/theme';
import { GenerateTelephoneInquiryDto } from '../../../../interface/telephoneInquiry';
import {
	getTelephoneCategory,
	useCreateTelephoneInquiryMutation,
} from '../../../../store/apis/telephoneInquiry';
import { CustomErrorType } from '../../../../store/apis/@types';
import { clockIcon } from '../../../../assets/icon';
import Icon from '../../../../components/atoms/Icon';
import { phoneFormat } from '../../../../utils/data-format';
import Select from '../../../../components/atoms/Select';
import TextArea from '../../../../components/atoms/TextArea';
import { LabelValue } from '../../../../utils/const';

const initialValues: GenerateTelephoneInquiryDto = {
	category: '',
	phoneCalledAt: '',
	customerName: '',
	customerPhone: '',
	body: '',
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

const StyleDateTimePicker = styled(DateTimePicker)`
	height: 34px;
	padding: 5px 10px;
	border-radius: 8px;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};

	.ant-picker-input > input {
		color: ${({ theme }) => theme.common.colors.gray_11};
		${({ theme }) => theme.typo.input2};
	}
`;

function TelInquiryCreatePage() {
	const navigate = useNavigate();
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();
	const [createTelephoneInquiry] = useCreateTelephoneInquiryMutation();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [isCategoryLoading, setIsCategoryLoading] = useState(true);
	const [categoryList, setCategoryList] = useState<LabelValue[]>([]);

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const goTo = () => {
		navigate(-1);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.category === '') {
			errors.category = '문의 유형을 작성해 주세요.';
		}
		if (values.phoneCalledAt === '') {
			errors.phoneCalledAt = '통화 일시를 선택해주세요';
		}
		if (values.customerName === '') {
			errors.customerName = '고객명을 입력해주세요.';
		}
		if (
			!/^01([0|1|6|7|8|9]){1}-[0-9]{3,4}-[0-9]{4}/.test(values.customerPhone)
		) {
			errors.customerPhone = '유효한 휴대폰번호를 입력해 주세요.';
		}
		if (!values.customerPhone.length) {
			errors.customerPhone = '휴대폰번호를 입력해 주세요.';
		}
		if (values.body === '') {
			errors.body = '내용을 입력해주세요.';
		}
		return errors;
	};

	const handleSubmitClick = async (body: GenerateTelephoneInquiryDto) => {
		dispatch(fullLoadingOn());
		handleAlertCloseClick();

		const { customerPhone, phoneCalledAt, ...rest } = body;

		const removeDashPhone = customerPhone.replaceAll('-', '');

		const phoneCalledToIsoString = new Date(phoneCalledAt).toISOString();

		const transformedBody: GenerateTelephoneInquiryDto = {
			...rest,
			phoneCalledAt: phoneCalledToIsoString,
			customerPhone: removeDashPhone,
		};

		const result = await createTelephoneInquiry(transformedBody);

		if (!('error' in result)) {
			navigate(CS_TEL_PATH);
			setToast('info', '상담내역 등록이 완료되었습니다.');
		} else if ('error' in result) {
			const msg =
				(result.error as CustomErrorType).data.translate ||
				(result.error as CustomErrorType).data.message;

			setToast('error', msg);
		}

		dispatch(fullLoadingOff());
	};

	const {
		values,
		setFieldValue,
		handleSubmit,
		handleChange,
		errors,
		validateForm,
	} = useFormik({
		initialValues,
		onSubmit: handleSubmitClick,
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const getSelectTime = (
		value: DatePickerProps['value'] | RangePickerProps['value'],
		dateString: [string, string] | string,
	) => {
		setFieldValue('phoneCalledAt', dateString);
	};

	const phoneNumberAutoHyphen = phoneFormat(values.customerPhone);

	useEffect(() => {
		(async () => {
			try {
				const res = await getTelephoneCategory();
				setCategoryList(res);
				setIsCategoryLoading(false);
			} catch (e) {
				setIsCategoryLoading(true);

				const err = e as AxiosError<{ message: string; translate: string }>;
				if (err.response)
					setToast(
						'error',
						err.response.data.translate || err.response.data.message,
					);
			}
		})();
	}, [setToast]);

	const renderAlert = () => {
		if (!isAlertVisible) return null;

		return (
			<Alert
				title="상담내역 등록"
				closeButtonClick={handleAlertCloseClick}
				onConfirmButtonText="등록하기"
				onConfirmButtonClick={handleSubmit}
				isVisible
			>
				<Typo $typoType="b5" color="gray_8">
					상담내역을 등록하시겠습니까?
				</Typo>
			</Alert>
		);
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="상담내역 추가"
				onBack={goTo}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goTo}
							size="small"
						>
							취소
						</Button>
						<Button
							onClick={async () => {
								const res = await validateForm();

								const isValid = Object.keys(res).length === 0;

								if (isValid) {
									handleAlertOpenClick();
								}
							}}
							size="small"
						>
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="상담 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="category" essential>
								<span>
									<Typo $typoType="label2">문의유형</Typo>
								</span>
							</Label>
							<Select
								placeholder="문의 유형을 작성해 주세요"
								loading={isCategoryLoading}
								value={values.category}
								onChange={(value: string) => setFieldValue('category', value)}
							>
								{categoryList.map((option, idx) => (
									<Select.Option value={option.value} key={idx.toString()}>
										{option.label}
									</Select.Option>
								))}
							</Select>
							{errors.category && (
								<InputAlert $inputStatus="error">{errors.category}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="phoneCalledAt" essential>
								<span>
									<Typo $typoType="label2">통화일시</Typo>
								</span>
							</Label>
							<StyleDateTimePicker
								showTime
								onChange={getSelectTime}
								placeholder="통화 일시 선택"
								suffixIcon={<Icon src={clockIcon} width={24} height={24} />}
								format="YYYY-MM-DD HH:mm:ss"
							/>
							{errors.phoneCalledAt && (
								<InputAlert $inputStatus="error">
									{errors.phoneCalledAt}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="customerName" essential>
								<span>
									<Typo $typoType="label2">고객명</Typo>
								</span>
							</Label>
							<Input
								name="customerName"
								placeholder="고객명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.customerName}
								onChange={handleChange}
								onReset={() => setFieldValue('customerName', '')}
							/>
							{errors.customerName && (
								<InputAlert $inputStatus="error">
									{errors.customerName}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="customerPhone" essential>
								<span>
									<Typo $typoType="label2">휴대폰 번호</Typo>
								</span>
							</Label>
							<Input
								name="customerPhone"
								placeholder="‘-없이’ 숫자를 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneNumberAutoHyphen}
								onChange={handleChange}
								onReset={() => setFieldValue('customerPhone', '')}
								maxLength={13}
							/>
							{errors.customerPhone && (
								<InputAlert $inputStatus="error">
									{errors.customerPhone}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="body" essential>
								<span>
									<Typo $typoType="label2">내용</Typo>
								</span>
							</Label>
							<TextArea
								style={{
									minHeight: '240px',
								}}
								placeholder="전화 문의 내용을 작성해 주세요"
								value={values.body}
								defaultValue={values.body}
								onChange={(e) => setFieldValue('body', e.target.value)}
							/>
							{errors.body && (
								<InputAlert $inputStatus="error">{errors.body}</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{renderAlert()}
		</>
	);
}

export default TelInquiryCreatePage;
