import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import {
	CELL_TYPE_BADGE_TEXT,
	CELL_TYPE_DETAIL_ACTION,
} from '../../../utils/row-data-util';
import { FilterElementType } from '../../../utils/filter-util';
import {
	vehicleSubscriptionRequestType,
	vehicleSubscriptionStatus,
} from '../../../interface/vehicleSubscription';
import { numberWithCommas } from '../../../utils/data-format';
import { LabelValue } from '../../../utils/const';

const vehicleSubscriptionData: ({
	isSuper,
	companyOptions,
}: {
	isSuper: boolean;
	companyOptions: LabelValue[];
}) => GridDataModel = ({ isSuper, companyOptions }) => {
	return {
		formInitialValues: {},
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '계약일자',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscription.subscriptionStartedAt',
					headerName: '구독시작일자',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return dayjs(value).format('YYYY.MM.DD(dd)');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscription.subscriptionEndedAt',
					headerName: '구독종료일자',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return dayjs(value).format('YYYY.MM.DD(dd)');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionPeriod',
					headerName: '구독기간(일)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'status',
					headerName: '상태',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscriptionNo',
					headerName: '구독신청번호',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
						return value?.split('_')[0];
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionNo',
					headerName: '차량순번',
				},
				options: {
					minWidth: ColSize.CW_X_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
						return Number(value?.split('_')[1]) + 1;
					},
				},
			},
			{
				tableInfo: {
					field: 'subscription.clientType',
					headerName: '계약유형',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
						return value === 'COMPANY' ? '법인' : '개인';
					},
				},
			},
			{
				tableInfo: {
					field: 'subscription.clientName',
					headerName: '계약자',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscription.vehicle.vehicleNumber',
					headerName: '차량번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscription.vehicle.vin',
					headerName: '차대번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscriptionPrice',
					headerName: '월 구독료(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'vehiclePrice',
					headerName: '차량가(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'prePaymentPrice',
					headerName: '선납금(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'acquisitionPrice',
					headerName: '인수잔가(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'endActionType',
					headerName: '만기시',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'paymentMethod',
					headerName: '결제수단',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'agCommissionPct',
					headerName: 'AG수수료(%)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field:
						'subscription.subscriptionCarCare.subscriptionProduct.subscriptionProductName',
					headerName: '카케어',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field:
						'subscription.subscriptionCarCare.subscriptionProduct.monthlyPrice',
					headerName: '카케어금액',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams<any, number>) => {
						return numberWithCommas(
							(data.subscription.subscriptionCarCare.subscriptionProduct
								.monthlyPrice ?? 0) +
								(data.subscription.subscriptionCarCare.subscriptionProduct
									.monthlyTax ?? 0),
						);
					},
				},
			},
			{
				tableInfo: {
					field: 'subscription.subscriptionCancelStatus',
					headerName: '해지상태',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_BADGE_TEXT,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '상세보기',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: isSuper
			? [
					{
						key: 'companyId',
						type: FilterElementType.SELECT,
						label: '법인명',
						options: [{ label: '전체', value: '' }, ...companyOptions],
					},
					{
						key: 'subscriptionVehicleRequestNo',
						type: FilterElementType.INPUT,
						label: '구독신청번호',
					},
					{
						key: 'vehicleNumber',
						type: FilterElementType.INPUT,
						label: '차량번호',
					},
					{
						key: 'vehicleType',
						type: FilterElementType.SELECT,
						label: '출고유형',
						options: [
							{ label: '전체', value: '' },
							...vehicleSubscriptionRequestType.map((item) => ({
								value: item,
								label: item,
							})),
						],
					},
					{
						key: 'status',
						type: FilterElementType.SELECT,
						label: '구독신청상태',
						span: 1,
						options: [
							{ label: '전체', value: '' },
							...vehicleSubscriptionStatus.map((item) => ({
								value: item,
								label: item,
							})),
						],
					},
					{
						key: 'subscriptionStartedAt',
						type: FilterElementType.DATE_RANGE_PICKER,
						label: '구독시작일',
					},
			  ]
			: [
					{
						key: 'subscriptionVehicleRequestNo',
						type: FilterElementType.INPUT,
						label: '구독신청번호',
					},
					{
						key: 'vehicleNumber',
						type: FilterElementType.INPUT,
						label: '차량번호',
					},
					{
						key: 'vehicleType',
						type: FilterElementType.SELECT,
						label: '출고유형',
						options: [
							{ label: '전체', value: '' },
							...vehicleSubscriptionRequestType.map((item) => ({
								value: item,
								label: item,
							})),
						],
					},
					{
						key: 'status',
						type: FilterElementType.SELECT,
						label: '구독신청상태',
						span: 1,
						options: [
							{ label: '전체', value: '' },
							...vehicleSubscriptionStatus.map((item) => ({
								value: item,
								label: item,
							})),
						],
					},
					{
						key: 'subscriptionStartedAt',
						type: FilterElementType.DATE_RANGE_PICKER,
						label: '구독시작일',
					},
			  ],
	};
};

export default vehicleSubscriptionData;
