import React, { useContext } from 'react';
import { Skeleton, Table, Tag } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import dayjs from 'dayjs';
import {
	useGetCarServiceCallHistoryQuery,
	useMakeCarServiceCallHistoryMutation,
} from '../../../store/apis/carService';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Input from '../../atoms/Input';
import { CarServiceCallHistory } from '../../../interface/carService';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import DateTimePicker from '../../atoms/DateTimePicker';
import Select from '../../atoms/Select';
import {
	ALL_OPTION_VALUE,
	INSPECTION_CALL_RESULT_TYPE,
} from '../../../utils/const';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import TextArea from '../../atoms/TextArea';
import { phoneFormat } from '../../../utils/data-format';

interface CallHistoryTabPaneProps {
	rwManager: string | undefined;
	inspectionId: string | undefined;
}

const CallHistoryTabPane = ({
	rwManager,
	inspectionId,
}: CallHistoryTabPaneProps) => {
	const setToast = useContext(ToastContext);

	const columns = [
		{
			title: '통화일',
			dataIndex: 'proxyInspectionCustomerCallDate',
			key: 'proxyInspectionCustomerCallDate',
			width: 72,
			render: (record: any) => record && dayjs(record).format('YYMMDD'),
		},
		{
			title: '통화자',
			dataIndex: 'proxyInspectionCustomerCallManagerName',
			key: 'proxyInspectionCustomerCallManagerName',
			width: 72,
		},
		{
			title: '연락처',
			dataIndex: 'proxyInspectionCustomerPhoneNumber',
			key: 'proxyInspectionCustomerPhoneNumber',
			width: 110,
		},
		{
			title: '고객명',
			dataIndex: 'proxyInspectionCustomerName',
			key: 'proxyInspectionCustomerName',
			width: 110,
		},
		{
			title: '결과',
			dataIndex: 'proxyInspectionCustomerCallResult',
			key: 'proxyInspectionCustomerCallResult',
			width: 92,
		},
	];

	const { isLoading, data, refetch } = useGetCarServiceCallHistoryQuery(
		{
			inspectionId,
		},
		{ skip: !inspectionId },
	);

	const callHistory = data ? data.rows : [];

	const [makeCallHistory, { isLoading: isUpdating }] =
		useMakeCarServiceCallHistoryMutation();

	const initialValues: Partial<CarServiceCallHistory> = {
		proxyInspectionCustomerCallManagerName: rwManager ?? '',
		proxyInspectionCustomerCallDate: '',
		proxyInspectionCustomerPhoneNumber: '',
		proxyInspectionCustomerName: '',
		proxyInspectionCustomerCallResult: '',
		proxyInspectionCustomerCallContents: '',
	};

	const handleSubmit = async (
		values: Partial<CarServiceCallHistory>,
		formikHelpers: FormikHelpers<any>,
	) => {
		const res = await makeCallHistory({ inspectionId, body: values });

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			refetch();
			setToast('info', '등록이 완료되었습니다.');
			await formikHelpers.setValues(initialValues);
		}
	};

	const {
		// validateForm,
		// setValues,
		values: formikValues,
		// errors: formikErrors,
		handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<ContentBoxWithHeader title="통화내역" borderRadius="10px" $isCompact>
			{isLoading ? (
				<Skeleton active style={{ marginBottom: '16px' }} />
			) : (
				<Table
					dataSource={callHistory}
					columns={columns}
					size="small"
					pagination={{ pageSize: 5 }}
					expandable={{
						// eslint-disable-next-line react/no-unstable-nested-components
						expandedRowRender: (record) => (
							<p style={{ margin: 0 }}>
								{record.proxyInspectionCustomerCallContents}
							</p>
						),
						defaultExpandAllRows: true,
					}}
					rowKey={(record) =>
						String(
							record.proxyInspectionCustomerCallDate +
								record.proxyInspectionCustomerCallTime,
						)
					}
					style={{ marginBottom: '16px' }}
				/>
			)}
			<GridLayout $columnCount={2} $isCompact>
				<GridItem
					key="proxyInspectionCustomerCallManagerName"
					title="통화자"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Input
						name="proxyInspectionCustomerCallManagerName"
						placeholder="통화자"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={formikValues.proxyInspectionCustomerCallManagerName ?? ''}
						onChange={handleChange}
						onReset={() =>
							setFieldValue('proxyInspectionCustomerCallManagerName', '')
						}
						$isCompact
					/>
				</GridItem>
				<GridItem
					key="proxyInspectionCustomerCallDate"
					title="통화일"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Tag style={{ fontSize: '13px' }}>
						{formikValues.proxyInspectionCustomerCallDate || '없음'}
					</Tag>
					<DateTimePicker
						name="proxyInspectionCustomerCallDate"
						onChange={(date, dateString) => {
							setFieldValue('proxyInspectionCustomerCallDate', dateString);
						}}
						placeholder="통화일 변경"
						style={{ borderRadius: '4px' }}
					/>
				</GridItem>
				<GridItem
					key="proxyInspectionCustomerPhoneNumber"
					title="연락처"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Input
						name="proxyInspectionCustomerPhoneNumber"
						placeholder="연락처"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={formikValues.proxyInspectionCustomerPhoneNumber ?? ''}
						onChange={(e) =>
							setFieldValue(
								'proxyInspectionCustomerPhoneNumber',
								phoneFormat(e.target.value),
							)
						}
						onReset={() =>
							setFieldValue('proxyInspectionCustomerPhoneNumber', '')
						}
						$isCompact
					/>
				</GridItem>
				<GridItem
					key="proxyInspectionCustomerName"
					title="고객명"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Input
						name="proxyInspectionCustomerName"
						placeholder="고객명"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={formikValues.proxyInspectionCustomerName ?? ''}
						onChange={handleChange}
						onReset={() => setFieldValue('proxyInspectionCustomerName', '')}
						$isCompact
					/>
				</GridItem>
				<GridItem
					key="proxyInspectionCustomerCallResult"
					title="결과"
					$isCompact
					size={GridTitleSize.GT_X_SMALL}
				>
					<Select
						value={formikValues.proxyInspectionCustomerCallResult ?? ''}
						onChange={(value: string) =>
							setFieldValue('proxyInspectionCustomerCallResult', value)
						}
						placeholder="결과"
						allowClear
						style={{ minWidth: '100px', fontSize: '14px' }}
					>
						{INSPECTION_CALL_RESULT_TYPE.filter(
							(item) => item.value !== ALL_OPTION_VALUE,
						).map((item, idx) => (
							<Select.Option value={item.value} key={idx.toString()}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</GridItem>
			</GridLayout>
			<TextArea
				name="proxyInspectionCustomerCallContents"
				placeholder="메모를 입력해주세요"
				value={formikValues.proxyInspectionCustomerCallContents}
				onChange={handleChange}
				onReset={() => setFieldValue('proxyInspectionCustomerCallContents', '')}
				style={{ marginTop: '8px' }}
			/>
			<Button
				size="default"
				$buttonType={ButtonTypeType.PRIMARY_LIGHT}
				disabled={isUpdating}
				onClick={() => formikSubmit()}
				style={{ marginTop: '8px' }}
			>
				{isUpdating ? '등록중..' : '등록하기'}
			</Button>
		</ContentBoxWithHeader>
	);
};

export default CallHistoryTabPane;
