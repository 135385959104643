import {
	MakeVehicleSubscriptionQuoteDto,
	VehicleSubscriptionQuoteDto,
} from '../../../interface/vehicleSubscriptionsQuotes';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const vehicleSubscriptionQuotesApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// 차량 구독 견적 생성
		makeSubscriptionVehicleQuotes: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteDto>,
			{ rows: MakeVehicleSubscriptionQuoteDto[] }
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle/quotes`,
				body,
			}),
			invalidatesTags: ['VehicleSubscriptionQuotes'],
		}),

		// 차량 구독 견적 전체 목록 조회
		getVehicleSubscriptionsQuotes: builder.query<
			ListResponse<VehicleSubscriptionQuoteDto>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/quotes`,
				params: args,
			}),
			providesTags: ['VehicleSubscriptionQuotes'],
		}),

		// 차량 구독 견적 번호로 목록 조회
		getVehicleSubscriptionQuoteDetails: builder.query<
			ListResponse<
				VehicleSubscriptionQuoteDto & MakeVehicleSubscriptionQuoteDto
			>,
			{ quoteNo: string }
		>({
			query: ({ quoteNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/quotes/${quoteNo}`,
			}),

			providesTags: (args) => [
				{
					type: 'VehicleSubscriptionQuotes',
					quoteNo: args?.rows[0].subscriptionVehicleQuoteNo,
				},
			],
		}),

		// 차량 구독 견적 발송
		makeVehicleSubscriptionQuoteSend: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteDto>,
			{ quoteNo: string; body: { customerPhone: string; link: string[] } }
		>({
			query: ({ quoteNo, body }) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle/quotes/${quoteNo}/send`,
				body,
			}),
		}),

		// 차량 구독 견적 제거
		deleteVehicleSubscriptionQuote: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteDto>,
			{
				quoteNo: string;
				vehicleIndex: number;
			}
		>({
			query: ({ quoteNo, vehicleIndex }) => ({
				method: 'DELETE',
				url: `/members/subscriptions-vehicle/quotes/${quoteNo}/${vehicleIndex}`,
			}),
			invalidatesTags: ['VehicleSubscriptionQuotes'],
		}),

		// 차량 구독 견적 수정
		updateVehicleSubscriptionQuote: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteDto>,
			{
				quoteNo: string;
				vehicleIndex: number;
				body: MakeVehicleSubscriptionQuoteDto;
			}
		>({
			query: ({ quoteNo, vehicleIndex, body }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle/quotes/${quoteNo}/${vehicleIndex}`,
				body,
			}),
			invalidatesTags: ['VehicleSubscriptionQuotes'],
		}),

		// 차량 구독 견적 조회
		getVehicleSubscriptionQuoteDetailsByVehicleIndex: builder.query<
			ObjectResponse<VehicleSubscriptionQuoteDto>,
			{ quoteNo: string; vehicleIndex: number }
		>({
			query: ({ quoteNo, vehicleIndex }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/quotes/${quoteNo}/${vehicleIndex}`,
			}),
		}),
	}),
});

export const {
	useGetVehicleSubscriptionsQuotesQuery,
	useMakeSubscriptionVehicleQuotesMutation,
	useGetVehicleSubscriptionQuoteDetailsQuery,
	useMakeVehicleSubscriptionQuoteSendMutation,
	useDeleteVehicleSubscriptionQuoteMutation,
	useUpdateVehicleSubscriptionQuoteMutation,
	useGetVehicleSubscriptionQuoteDetailsByVehicleIndexQuery,
} = vehicleSubscriptionQuotesApi;

export default vehicleSubscriptionQuotesApi;
