import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import storage from 'redux-persist/lib/storage';
import authSignIn from './apis/auth';
import authToken from './apis/authToken';
import authTokenVerify from './apis/authTokensVerify';
import authReducer from './auth';
import webUtilReducer from './webUtil';
import asideMenuReducer from './menuOpen';
import popupReducer from './popup';
import { authApiSlice } from './apis/query';
import findAdminId from './apis/findAdminId';
import resetPasswordApi from './apis/passwordReset';
import authPasswordApi from './apis/password';
import vehicleSubscriptionReducer from './vehicleSubscription';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'debugPath'],
};

const reducers = combineReducers({
	auth: authReducer,
	webUtil: webUtilReducer,
	popup: popupReducer,
	asideMenu: asideMenuReducer,
	vehicleSubscription: vehicleSubscriptionReducer,
	[authApiSlice.reducerPath]: authApiSlice.reducer,
	[authSignIn.reducerPath]: authSignIn.reducer,
	[authToken.reducerPath]: authToken.reducer,
	[authTokenVerify.reducerPath]: authTokenVerify.reducer,
	[authPasswordApi.reducerPath]: authPasswordApi.reducer,
	[resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
	[findAdminId.reducerPath]: findAdminId.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat([
			authSignIn.middleware,
			authToken.middleware,
			authTokenVerify.middleware,
			authPasswordApi.middleware,
			resetPasswordApi.middleware,
			authApiSlice.middleware,
			findAdminId.middleware,
		]),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
