import React, { useState } from 'react';
import { Skeleton } from 'antd';
import Button from '../../atoms/Button';
import {
	useGetMyVehicleSubscriptionContractQuery,
	useGetVSContractAdminQuery,
} from '../../../store/apis/vehicleSubscription';
import useCheckRole from '../../../hooks/useCheckRole';

const GetContractLink = ({ svNo }: { svNo: string }) => {
	const [isClick, setIsClick] = useState(false);
	const isSuperAdmin = useCheckRole({
		roleName: '현황관리',
		roleType: 'isRead',
		includeSuper: true,
	});

	const { isLoading: isSuperLoading, data: superData } =
		useGetVSContractAdminQuery({ svNo }, { skip: !isClick || !isSuperAdmin });

	const { isLoading, data } = useGetMyVehicleSubscriptionContractQuery(
		{ svNo },
		{ skip: !isClick || isSuperAdmin },
	);
	const contractLink = isSuperAdmin ? superData?.row : data?.row;

	if (isLoading || isSuperLoading) {
		return <Skeleton.Input active style={{ borderRadius: '10px' }} />;
	}

	return isClick && contractLink ? (
		<a href={contractLink.presignedUrl} target="_blank" rel="noreferrer">
			{`${Number(svNo.split('_')[1]) + 1}번 차량 계약서`}
		</a>
	) : (
		<Button size="small" onClick={() => setIsClick(true)}>
			{`${Number(svNo.split('_')[1]) + 1}번 차량 계약서 확인`}
		</Button>
	);
};

export default GetContractLink;
