import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Form, Select, Input } from 'antd';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/atoms/Modal';
import Button from '../../../components/atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../../components/atoms/Typo';
import Alert from '../../../components/atoms/Alert';
import {
	CUSTOMER_STATE_DEREGISTERED_VALUE,
	CUSTOMER_STATE_OPTIONS,
	CUSTOMER_STATE_SUSPENSION_VALUE,
} from '../../../utils/const';
import { useUpdateCustomerStateMutation } from '../../../store/apis/customer';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';

interface EditModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: { statusChangedReason: string; status: string };
	userId: string;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormLabel = styled.p`
	${({ theme }) => theme.typo.label1};
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

function EditModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	userId,
}: EditModalProps) {
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [content, setContent] = useState(defaultValue.statusChangedReason);
	const [stateValue, setStateValue] = useState(defaultValue.status);
	const [updateUserState] = useUpdateCustomerStateMutation();

	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);

	const handleModalClose = () => {
		onClose();
		setContent(defaultValue.statusChangedReason);
		setStateValue(defaultValue.status);
	};

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubmit = async () => {
		dispatch(fullLoadingOn());
		setIsAlertOpen(false);

		const result = await updateUserState({
			id: userId,
			body:
				stateValue === CUSTOMER_STATE_SUSPENSION_VALUE
					? { customerStatus: stateValue, statusChangedReason: content }
					: { customerStatus: stateValue },
		});

		if (!('error' in result) && result.data.row.updateResult) {
			setToast('info', '회원상태 변경이 완료되었습니다.');

			if (onRefetch) onRefetch();
			if (onClose) onClose();
		} else if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}

		dispatch(fullLoadingOff());
	};

	return (
		<>
			<Modal
				title={<ModalTitle>회원상태 변경</ModalTitle>}
				open={isVisible}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button
							disabled={
								stateValue === CUSTOMER_STATE_SUSPENSION_VALUE &&
								!content.length
							}
							onClick={handleAlertOpen}
						>
							완료
						</Button>
					</ModalFooterWrapper>
				}
			>
				<Form
					initialValues={{
						userStatus: defaultValue.status,
						reason: content,
					}}
				>
					<FormItem
						name="userStatus"
						label={<FormLabel>회원상태</FormLabel>}
						rules={[{ required: true, message: '' }]}
						colon={false}
					>
						<Select
							style={{ fontSize: '16px' }}
							value={stateValue}
							onChange={(e) => {
								setStateValue(e);
							}}
						>
							{CUSTOMER_STATE_OPTIONS.filter(
								(item) =>
									item.value &&
									item.value !== CUSTOMER_STATE_DEREGISTERED_VALUE,
							).map((option, idx) => (
								<Select.Option value={option.value} key={idx.toString()}>
									{option.label}
								</Select.Option>
							))}
						</Select>
					</FormItem>
					{stateValue === CUSTOMER_STATE_SUSPENSION_VALUE && (
						<FormItem
							name="reason"
							label={<FormLabel>제한사유</FormLabel>}
							rules={[{ required: true, message: '제한사유를 입력해 주세요' }]}
							colon={false}
						>
							<Input.TextArea
								style={{ minHeight: '200px', fontSize: 16 }}
								placeholder="제한 사유를 입력해 주세요"
								value={content}
								onChange={(e) => setContent(e.target.value)}
							/>
						</FormItem>
					)}
				</Form>
			</Modal>

			{isAlertOpen && (
				<Alert
					title="회원상태 변경"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={handleSubmit}
					onConfirmButtonText="변경하기"
					isVisible={isAlertOpen}
				>
					<Typo $typoType="b5" color="gray_8">
						회원상태를 변경하시겠습니까?
					</Typo>
				</Alert>
			)}
		</>
	);
}

export default EditModal;
