import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import dayjs from 'dayjs';
import Typo from '../../../../components/atoms/Typo';
import {
	CUSTOMER_STATE_DEREGISTERED_LABEL,
	CUSTOMER_STATE_DEREGISTERED_VALUE,
	CUSTOMER_STATE_NORMAL_LABEL,
	CUSTOMER_STATE_NORMAL_VALUE,
	CUSTOMER_STATE_OPTIONS,
	CUSTOMER_STATE_SUSPENSION_LABEL,
	CUSTOMER_STATE_SUSPENSION_VALUE,
	MARKETING_RECEIVABLE_OPTIONS,
	PROVIDER_OPTIONS,
} from '../../../../utils/const';
import { phoneFormat } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DATE,
	CELL_TYPE_DETAIL_ACTION,
} from '../../../../utils/row-data-util';
import { getProviderName } from '../../../../utils/string-util';

const renderAgreeCell = ({ value }: ICellRendererParams) => {
	return value ? (
		<Typo color="gray_11">&#8226; 동의</Typo>
	) : (
		<Typo color="danger_5_main">&#8226; 미동의</Typo>
	);
};

export const renderCustomerStatusCell = ({
	value,
}: ICellRendererParams | { value: string }) => {
	switch (value) {
		case CUSTOMER_STATE_NORMAL_VALUE:
			return (
				<Typo $typoType="b6" color="gray_11">
					{' '}
					&#8226; {CUSTOMER_STATE_NORMAL_LABEL}
				</Typo>
			);
		case CUSTOMER_STATE_SUSPENSION_VALUE:
			return (
				<Typo $typoType="b6" color="gray_7">
					{' '}
					&#8226; {CUSTOMER_STATE_SUSPENSION_LABEL}
				</Typo>
			);
		case CUSTOMER_STATE_DEREGISTERED_VALUE:
			return (
				<Typo $typoType="b6" color="danger_5_main">
					{' '}
					&#8226; {CUSTOMER_STATE_DEREGISTERED_LABEL}
				</Typo>
			);
		default:
			return '-';
	}
};

const renderProviderCell = ({ value }: ICellRendererParams) => {
	return getProviderName(value);
};

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '가입일자',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_DATE,
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'provider',
				headerName: '가입채널',
			},
			options: {
				minWidth: ColSize.CW_SMALL_M,
				flex: 1,
				cellRenderer: renderProviderCell,
			},
		},
		{
			tableInfo: {
				field: 'customerApplicationId',
				headerName: '아이디',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '휴대폰번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					return data.vehicle?.vehicleNumber ?? '-';
				},
				valueGetter: ({ data }) => {
					return data.vehicle?.vehicleNumber ?? '';
				},
			},
		},
		{
			tableInfo: {
				field: 'emailReceivable',
				headerName: '이메일 수신동의',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: renderAgreeCell,
			},
		},
		{
			tableInfo: {
				field: 'pushReceivable',
				headerName: '푸시 수신동의',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: renderAgreeCell,
			},
		},
		{
			tableInfo: {
				field: 'messageReceivable',
				headerName: '메세지 수신동의',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: renderAgreeCell,
			},
		},
		{
			tableInfo: {
				field: 'customerStatus',
				headerName: '회원상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL_M,
				flex: 1,
				cellRenderer: renderCustomerStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'vehicle.lastInspectionDate',
				headerName: '마지막 점검일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '가입일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'customerName',
			label: '고객명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerPhone',
			label: '휴대폰번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'provider',
			label: '가입채널',
			type: FilterElementType.SELECT,
			options: PROVIDER_OPTIONS,
		},
		{
			key: 'vehicleNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerStatus',
			label: '회원상태',
			type: FilterElementType.SELECT,
			options: CUSTOMER_STATE_OPTIONS,
		},
		{
			key: 'emailReceivable',
			label: '이메일수신동의',
			type: FilterElementType.SELECT,
			options: MARKETING_RECEIVABLE_OPTIONS,
		},
		{
			key: 'pushReceivable',
			label: '푸시수신동의',
			type: FilterElementType.SELECT,
			options: MARKETING_RECEIVABLE_OPTIONS,
		},
		{
			key: 'messageReceivable',
			label: '메세지수신동의',
			type: FilterElementType.SELECT,
			options: MARKETING_RECEIVABLE_OPTIONS,
		},
	],
};

export default userData;
