import React from 'react';
import DefaultLayout from '../../../components/organisms/DefaultLayout';
import UserPasswordSearchTemplate from '../../../components/templates/UserPasswordSearch';

function UserPasswordSearch() {
	return (
		<DefaultLayout>
			<UserPasswordSearchTemplate title="비밀번호 찾기" />
		</DefaultLayout>
	);
}

export default UserPasswordSearch;
