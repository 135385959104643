import React from 'react';
import styled, { css } from 'styled-components';

interface IconProps {
	src: string;
	width?: number | string;
	height?: number | string;
	className?: string;
	onClick?: (event: React.ChangeEvent<any>) => void;
}

interface IconStyleProps {
	width: string;
	height: string;
	src: string;
	$isCursor: boolean;
}

const IconContainer = styled.span<IconStyleProps>`
	display: inline-block;
	${({ width, height, src }) => css`
		width: ${width};
		height: ${height};
		background: url(${src}) no-repeat center center / contain;
	`}

	${({ $isCursor }) =>
		$isCursor &&
		css`
			cursor: pointer;
		`};
`;

function Icon({ width, height, src, className, onClick }: IconProps) {
	let iconWidth = typeof width === 'number' ? `${width}px` : width;
	let iconHeight = typeof height === 'number' ? `${height}px` : height;
	if (!iconWidth) iconWidth = '100%';
	if (!iconHeight) iconHeight = '100%';

	return (
		<IconContainer
			className={className}
			width={iconWidth}
			height={iconHeight}
			src={src}
			$isCursor={!!onClick}
			onClick={onClick}
		/>
	);
}

export default Icon;
