import {
	CreateDepartmentDto,
	DepartmentDto,
	UpdateDepartmentDto,
	UpdatedDepartmentState,
} from '../../../interface/department';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const DepartmentApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDepartments: builder.query<
			ListResponse<DepartmentDto>,
			{
				service?: string;
				companyId?: number;
				departmentName?: string;
				page?: number;
				pageSize?: number;
			}
		>({
			query: ({ service, companyId, departmentName, page, pageSize }) => ({
				url: `/members/departments`,
				params: { service, companyId, departmentName, page, pageSize },
			}),
			providesTags: ['Departments'],
		}),
		getDepartmentDetail: builder.query<
			ObjectResponse<DepartmentDto>,
			{ departmentId: number }
		>({
			query: ({ departmentId }) => ({
				url: `/members/departments/${departmentId}`,
			}),
			providesTags: ['Departments'],
		}),
		createDepartment: builder.mutation<
			ObjectResponse<DepartmentDto>,
			CreateDepartmentDto
		>({
			query: ({ service, departmentName, companyId }) => ({
				method: 'POST',
				url: `/members/departments`,
				body: { service, departmentName, companyId },
			}),
			invalidatesTags: ['Departments'],
		}),
		updateDepartment: builder.mutation<
			ObjectResponse<UpdatedDepartmentState>,
			UpdateDepartmentDto
		>({
			query: ({ departmentId, departmentName }) => ({
				method: 'PUT',
				url: `/members/departments/${departmentId}`,
				body: { departmentName },
			}),
			invalidatesTags: ['Departments'],
		}),
	}),
});

export const getDepartmentsAll = async (service: string) => {
	const url = `/members/departments`;

	const { data } = await axiosInstance.get<ListResponse<DepartmentDto>>(url, {
		params: { service },
	});

	return data.rows;
};

export const getDepartmentsDetailViaId = async (id: string) => {
	const url = `/members/departments/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<DepartmentDto>>(url);

	return data.row;
};

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetDepartmentsQuery,
	useGetDepartmentDetailQuery,
	useCreateDepartmentMutation,
	useUpdateDepartmentMutation,
} = DepartmentApiSlice;
