import { GridApi, RowSelectedEvent, SortChangedEvent } from 'ag-grid-community';
import { Pagination, Skeleton } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import { FilterFormInfo } from '../../../utils/filter-util';
import { TableColumn } from '../../../utils/grid-util';
import ContentBox, { ContentBoxProps } from '../../atoms/ContentBox';
import Grid from '../../atoms/Grid';
import Typo from '../../atoms/Typo';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridFilterForm from '../../organisms/GridFilterForm';
import MainHeader from '../../organisms/MainHeader';
import { FilterSelectFormInfo } from '../../../utils/filter-select-util';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface GridContentTemplateProps {
	gridRef?: (api: GridApi) => void;
	mainTitle: string;
	gridTitle: string;
	rowData: any[] | null;
	totalCount: number;
	currentPage: number;
	currentPageSize: number;
	cols: TableColumn[];
	forms?: FilterFormInfo[];
	selectForms?: FilterSelectFormInfo[];
	formInitialValues?: Object;
	selectedOptions?: {
		selectedRowIds: number[];
		handleRowSelected: (e: RowSelectedEvent<any>) => void;
	};
	frameworkComponents?: Record<string, React.ComponentType<any>>;
	rightAccessory?: ReactNode;
	onSortChange?: (api: SortChangedEvent<any>) => void;
	onSearchSubmit?: (values: any) => void;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (page: number, size: number) => void;
	onRowClicked?: (id: RowSelectedEvent<any>) => void;
	isFetching?: boolean;
	$isCompact?: boolean;
	editable?: boolean;
	sortable?: boolean;
	columnName?: string;
}

const GridContentTemplateContainer = styled.div<ContentBoxProps>`
	display: flex;
	flex-direction: column;

	.content-wrapper {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border: none;
	}

	.inner-content-grid {
		flex: 1;
	}

	.grid-content-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.grid-content {
		flex: 1;
		margin-bottom: 16px;
	}

	.grid-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-pagination {
		display: flex;
		align-items: center;
	}
	.grid-total-count {
		display: flex;
		gap: 8px;
	}
`;

function GridContentTemplate({
	mainTitle,
	gridTitle,
	rowData,
	totalCount,
	currentPage,
	currentPageSize,
	cols,
	forms,
	selectForms,
	formInitialValues,
	selectedOptions,
	frameworkComponents = {},
	rightAccessory,
	onSortChange,
	onSearchSubmit,
	onPageChange,
	onPageSizeChange,
	gridRef,
	onRowClicked,
	isFetching = false,
	$isCompact = false,
	editable = true,
	sortable = true,
	columnName,
}: GridContentTemplateProps) {
	return (
		<GridContentTemplateContainer
			style={{ height: '100%' }}
			$isCompact={$isCompact}
		>
			{!$isCompact ? <MainHeader mainTitle={mainTitle} /> : ''}
			<ContentBox
				backgroundColor={theme.common.colors.gray_3}
				className="content-wrapper"
				$isCompact={$isCompact}
			>
				{forms && forms.length ? (
					<ContentBox
						borderRadius="10px"
						className="inner-content"
						$isCompact={$isCompact}
					>
						<GridFilterForm
							forms={forms}
							selectForms={selectForms}
							formInitialValues={formInitialValues}
							onSearchSubmit={onSearchSubmit}
						/>
					</ContentBox>
				) : null}
				<ContentBoxWithHeader
					title={gridTitle}
					borderRadius="10px"
					className="inner-content-grid"
					innerClassName="grid-content-wrapper"
					rightAccessory={rightAccessory}
					$isCompact={$isCompact}
				>
					{isFetching ? (
						<Skeleton
							active
							paragraph={{ rows: 6 }}
							style={{ borderRadius: '10px' }}
						/>
					) : (
						<>
							<div className="grid-content">
								<Grid
									cols={cols}
									onSortChange={onSortChange}
									rowData={rowData}
									selectedOptions={selectedOptions}
									gridRef={gridRef}
									frameworkComponents={frameworkComponents}
									onRowClicked={onRowClicked}
									$isCompact={$isCompact}
									editable={editable}
									sortable={sortable}
									columnName={columnName}
								/>
							</div>
							<div className="grid-footer">
								<div className="grid-total-count">
									<Typo $typoType="hm8" color="gray_8">
										총
									</Typo>
									<Typo $typoType="hm8">
										{totalCount.toLocaleString()}
										{selectedOptions &&
											`중 ${selectedOptions.selectedRowIds.length} 선택`}
									</Typo>
								</div>
								<div className="grid-pagination">
									<Pagination
										showSizeChanger
										onShowSizeChange={onPageSizeChange}
										onChange={onPageChange}
										defaultCurrent={DEFAULT_CURRENT_PAGE}
										current={currentPage}
										defaultPageSize={DEFAULT_PAGE_SIZE}
										pageSize={currentPageSize}
										total={totalCount}
										locale={{ items_per_page: '개씩 보기' }}
									/>
								</div>
							</div>
						</>
					)}
				</ContentBoxWithHeader>
			</ContentBox>
		</GridContentTemplateContainer>
	);
}

export default GridContentTemplate;
