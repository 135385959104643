import React, { PropsWithChildren, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { arrowUpIcon } from '../../../assets/icon';
import Badge, { BadgeColorType } from '../../atoms/Badge';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';

type TabPaneBoxItemValueType = 'text' | 'accordion' | 'badge';

interface TabPaneBoxItemValueProps {
	type: TabPaneBoxItemValueType;
	content?: ReactNode;
	onClick?: (isOn: boolean) => void;
	badgeColor?: BadgeColorType;
	defaultAccordionOpen?: boolean;
}

export const TabPaneBoxItemTitle = styled.span`
	flex: 1;
	${({ theme }) => theme.typo.b5};
	color: ${({ theme }) => theme.common.colors.gray_8};
`;

export const TabPaneBoxItemRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const GrayBoxContentList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const GrayBoxContentListItem = styled.li`
	display: flex;
	align-items: center;
	text-indent: 10px;
	color: ${({ theme }) => theme.common.colors.gray_8};

	.gray-box-right-accessary {
		margin-left: auto;
	}
`;

export const GrayBox = styled.div`
	padding: 16px;
	background: ${({ theme }) => theme.common.colors.gray_1};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Text = styled.span`
	${({ theme }) => theme.typo.b5};
	color: ${({ theme }) => theme.common.colors.gray_11};
`;

const AccordionText = styled(Text)`
	display: flex;
	align-items: center;

	.accordion {
		cursor: pointer;
		transition: transform 0.2s ease-in-out;
		margin-left: 4px;
	}

	.accordion-down {
		transform: rotate(180deg);
	}

	.accordion-up {
		transform: rotate(0deg);
	}
`;

function TabPaneBoxItemValue({
	type,
	content = '-',
	onClick,
	badgeColor = 'blue',
	defaultAccordionOpen = true,
}: TabPaneBoxItemValueProps) {
	const [isAccordionOn, setIsAccordionOn] = useState(defaultAccordionOpen);

	const renderValue = () => {
		switch (type) {
			case 'text':
				return <Text>{content}</Text>;

			case 'accordion':
				return (
					<AccordionText>
						{content}{' '}
						<Icon
							src={arrowUpIcon}
							width={16}
							height={16}
							onClick={() => {
								if (onClick) {
									onClick(!isAccordionOn);
								}
								setIsAccordionOn((prev) => !prev);
							}}
							className={`accordion ${
								isAccordionOn ? 'accordion-down' : 'accordion-up'
							}`}
						/>
					</AccordionText>
				);

			case 'badge':
				return <Badge color={badgeColor}>{content}</Badge>;

			default:
				return null;
		}
	};

	return <>{renderValue()}</>;
}

export default TabPaneBoxItemValue;

interface GrayBoxListProps {
	title: ReactNode;
}

export const GrayBoxList = ({
	title,
	children,
}: PropsWithChildren<GrayBoxListProps>) => {
	const renderTitle = () => {
		if (React.isValidElement(title)) {
			return title;
		}

		return <Typo $typoType="hm7">{title}</Typo>;
	};
	return (
		<GrayBoxContentList>
			{renderTitle()}
			{children}
		</GrayBoxContentList>
	);
};

interface GrayBoxListItemProps {
	content: ReactNode;
	rightAccessary?: ReactNode;
}

export const GrayBoxListItem = ({
	content,
	rightAccessary,
}: GrayBoxListItemProps) => {
	return (
		<GrayBoxContentListItem>
			{content}

			{rightAccessary && (
				<span className="gray-box-right-accessary">{rightAccessary}</span>
			)}
		</GrayBoxContentListItem>
	);
};
