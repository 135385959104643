import { useGetManagersQuery } from '../../store/apis/manager';

const useManagersOption = (departmentName?: string) => {
	const managersOption: Array<{ label: string; value: number }> = [];

	const { data } = useGetManagersQuery(
		departmentName
			? {
					managerDepartment: departmentName,
			  }
			: {},
	);
	const managers = data ? data.rows : [];

	managers.map((manager) => {
		return managersOption.push({
			label: manager.managerName,
			value: manager.id,
		});
	});

	return managersOption;
};

export default useManagersOption;
