import React from 'react';
import { MakeCompanyDta } from '../../../../interface/company';
import CompanyTemplate from '../../../../components/templates/CompanyTemplate';

const CompanyCreate = () => {
	const initialValues: MakeCompanyDta = {
		signUpChannel: '일반회원',
		name: '',
		registrationNumber: '',
		businessLicenseNumber: '',
		businessLicense: '',
		representativePhoneNumber: '',
		contactName: '',
		contactPhoneNumber: '',
		adminApplicationId: '',
		roleId: 0,
		departmentService: 'CUSTOMER_APP',
	};

	return <CompanyTemplate initialValues={initialValues} />;
};

export default CompanyCreate;
