import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { toastCloseIcon, toastNoticeIcon } from '../../../assets/icon';
import Icon from '../Icon';
import Typo from '../Typo';
import { ThemeType } from '../../../styles/theme';
import { zIndex } from '../../../styles/GlobalStyle';
import { ToastItemType } from '../../../contexts/Toast';

export type ToastStatusType = 'info' | 'error';
export interface ToastProps {
	toastList: ToastItemType[];
	setList: React.Dispatch<React.SetStateAction<ToastItemType[]>>;
}

const getToastColor = (status: ToastStatusType, theme: ThemeType) => {
	let backgroundColor;
	switch (status) {
		case 'info':
			backgroundColor = theme.common.colors.info_4;
			break;
		case 'error':
			backgroundColor = theme.common.colors.danger_4;
			break;
		default:
			return null;
	}
	return css`
		background: ${backgroundColor};
	`;
};

const toastFadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const toastFadeOut = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-15px);
    }
`;

const ToastContainer = styled.div<{ $fadeOut: boolean }>`
	display: flex;
	flex-direction: column-reverse;
	position: fixed;
	z-index: ${zIndex.toast};
	left: 50%;
	transform: translateX(-50%);
	top: 2%;

	${({ $fadeOut }) =>
		$fadeOut &&
		css`
			animation: 0.5s ${toastFadeOut};
			height: 0;
			margin: 0;
		`}
`;

const ToastLayout = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
	gap: 13px;
	min-width: 264px;
	width: 100%;

	animation: 0.5s ${toastFadeIn};
	transition: 0.3s;
	height: 56px;

	& > div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		${({ $status, theme }) => getToastColor($status, theme)};
		padding: 16px;
		border-radius: 18px;
		transition: 0.3s;
	}

	&.fade-out {
		height: 0;
		margin: 0;
		& > div {
			opacity: 0;
		}
	}

	figure {
		display: flex;
		align-items: center;
		gap: 13px;

		.toast-info-icon {
			flex-shrink: 0;
		}

		p {
			color: ${({ theme }) => theme.common.colors.white};
		}
	}

	.toast-close-icon {
		flex-shrink: 0;
		cursor: pointer;
	}
`;

function Toast({ toastList, setList }: ToastProps) {
	const [fadeOut, setFadeOut] = useState(false);
	const toastContainerRef = useRef<HTMLDivElement>(null);

	const handleRemoveToast = useCallback(
		(id: string) => {
			if (toastContainerRef.current) {
				const target = toastContainerRef.current.querySelector(
					`[data-id="${id}"]`,
				);

				if (target) {
					target.classList.add('fade-out');

					target.addEventListener('transitionend', () => {
						const toastListItem = toastList.filter((item) => item.id !== id);
						setList(toastListItem);
					});
				}
			}
		},
		[toastList, setList],
	);

	const handleRemoveAllToasts = useCallback(() => {
		setFadeOut(true);
	}, []);

	useEffect(() => {
		if (toastList.length) {
			const timer = setTimeout(() => {
				handleRemoveAllToasts();
				setList([]);
				setFadeOut(false);
			}, 2000);

			return () => {
				clearTimeout(timer);
			};
		}
		return () => {};
	}, [toastList, handleRemoveAllToasts, setList]);

	const toastListRender = () => {
		return (
			<ToastContainer ref={toastContainerRef} $fadeOut={fadeOut}>
				{toastList.map(({ id, description, status }) => (
					<ToastLayout $status={status} key={id} data-id={id}>
						<div>
							<figure>
								<Icon
									className="toast-info-icon"
									src={toastNoticeIcon}
									width={18}
									height={18}
								/>
								<p>
									<Typo $typoType="b5" color="white">
										{description}
									</Typo>
								</p>
							</figure>

							<Icon
								className="toast-close-icon"
								src={toastCloseIcon}
								width={18}
								height={18}
								onClick={() => handleRemoveToast(id)}
							/>
						</div>
					</ToastLayout>
				))}
			</ToastContainer>
		);
	};

	return ReactDOM.createPortal(
		toastListRender(),
		document.getElementById('toast') as HTMLElement,
	);
}

export default Toast;
