import React from 'react';
import dayjs from 'dayjs';
import VehicleSubscriptionConsultingTemplate from '../../../../components/templates/VehicleSubscriptionConsultingTemplate';
import { MakeVehicleSubscriptionConsultingDto } from '../../../../interface/vehicleSubscriptionConsulting';

const VehicleSubscriptionConsultingCreate = () => {
	const initialValues: MakeVehicleSubscriptionConsultingDto = {
		consultationType: '',
		customerType: '개인',
		customerName: '',
		customerPhone: '',
		customerEmail: '',
		contactName: '',
		subscriptionStartedAt: dayjs().format('YYYY-MM-DD'),
		subscriptionEndedAt: dayjs().format('YYYY-MM-DD'),
		vehicleName: '',
		memo: '',
	};

	return (
		<VehicleSubscriptionConsultingTemplate initialValues={initialValues} />
	);
};

export default VehicleSubscriptionConsultingCreate;
