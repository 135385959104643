import React, { ReactNode, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { popupUpdateHandler } from '../../../store/popup';
import Alert from '../../../components/atoms/Alert';
import Badge from '../../../components/atoms/Badge';
import Button from '../../../components/atoms/Button';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../components/molecules/GridLayout';
import Typo from '../../../components/atoms/Typo';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	useCancelPaymentMutation,
	useGetPaymentDetailQuery,
	useConfirmPaymentMutation,
} from '../../../store/apis/payment';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { ButtonTypeType } from '../../../styles/theme';
import {
	PAYMENT_STATUS_BADGE_COLOR,
	PAYMENT_STATUS_EXPECTATION_VALUE,
	PAYMENT_STATUS_OPTION,
	PAYMENT_STATUS_SUCCESS_VALUE,
} from '../../../utils/const';
import { phoneFormat } from '../../../utils/data-format';
import { NewTableValueType } from '../../../utils/table-util';

const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, NewTableValueType>;
	data: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof T];
		const { label, span } = value;

		let installmentValue;

		switch (key) {
			case 'confirmedAt':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);
			case 'subscriptionStartedAt':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);
			case 'subscriptionEndedAt':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);
			case 'clientType':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent === 'COMPANY' ? '법인' : '개인'}
					</GridItem>
				);
			case 'customerPhone':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent ? phoneFormat(tableContent as string) : '-'}
					</GridItem>
				);
			case 'administratorPhone':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent ? phoneFormat(tableContent as string) : '-'}
					</GridItem>
				);
			case 'paymentStatus':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						<Badge color={PAYMENT_STATUS_BADGE_COLOR[tableContent] || 'red'}>
							{PAYMENT_STATUS_OPTION.find((item) => item.value === tableContent)
								?.label || '-'}
						</Badge>
					</GridItem>
				);
			case 'installment':
				if (tableContent === 'SINGLE_PAYMENT') {
					installmentValue = '일시불';
				}
				if (tableContent === 'TWO_MONTH_INSTALLMENT') {
					installmentValue = '2개월';
				}
				if (tableContent === 'THREE_MONTH_INSTALLMENT') {
					installmentValue = '3개월';
				}
				if (tableContent === 'FOUR_MONTH_INSTALLMENT') {
					installmentValue = '4개월';
				}
				if (tableContent === 'FIVE_MONTH_INSTALLMENT') {
					installmentValue = '5개월';
				}
				if (tableContent === 'SIX_MONTH_INSTALLMENT') {
					installmentValue = '6개월';
				}

				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent ? `${installmentValue}` : '일시불'}
					</GridItem>
				);
			case 'paymentMethod':
			case 'customerName':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
			case 'paymentPriceTax':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent ? `${tableContent?.toLocaleString()}원` : '-'}
					</GridItem>
				);
			case 'detail':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
						$isAutoHeight
					>
						{(tableContent as any[]).length >= 1 ? (
							<ul
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 8,
									listStyle: 'initial',
									paddingLeft: 20,
								}}
							>
								{(tableContent as any[]).map((item: any, index) => (
									<li key={index.toString()}>
										{item.product.productName}{' '}
										{(item.productPrice + item.productTax).toLocaleString()}원
									</li>
								))}
							</ul>
						) : (
							'-'
						)}
					</GridItem>
				);
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};

const monthlyTableKey: Record<string, NewTableValueType> = {
	confirmedAt: {
		key: 'confirmedAt',
		label: '결제일',
	},
	paymentStatus: {
		key: 'paymentStatus',
		label: '결제상태',
	},
	installment: {
		key: 'installment',
		label: '할부',
	},
	paymentMethod: {
		key: 'paymentMethod',
		label: '결제수단',
	},
	paymentPriceTax: {
		key: 'paymentPriceTax',
		label: '총 결제금액',
	},
	paymentName: {
		key: 'paymentName',
		label: '결제항목',
	},
	detail: {
		key: 'detail',
		label: '상세 결제항목',
		span: 3,
	},
};

const subscriptionTableKey: Record<string, NewTableValueType> = {
	clientType: {
		key: 'clientType',
		label: '계약종류',
	},
	clientName: {
		key: 'clientName',
		label: '계약자명',
	},
	subscriptionStartedAt: {
		key: 'subscriptionStartedAt',
		label: '계약시작일',
	},
	subscriptionEndedAt: {
		key: 'subscriptionEndedAt',
		label: '계약종료일',
	},
	subscriptionNo: {
		key: 'subscriptionNo',
		label: '계약번호',
	},
	vehicleNumber: {
		key: 'vehicleNumber',
		label: '차량번호',
	},
};

const customerTableKey: Record<string, NewTableValueType> = {
	customerName: {
		key: 'customerName',
		label: '고객명',
	},
	customerPhone: {
		key: 'customerPhone',
		label: '고객 전화번호',
	},
};

const adminTableKey: Record<string, NewTableValueType> = {
	administratorName: {
		key: 'administratorName',
		label: '담당자명',
	},
	administratorPhone: {
		key: 'administratorPhone',
		label: '담당자 전화번호',
	},
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

function MonthlySalesDetailPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);

	const [isCancelAlertVisible, setIsCancelAlertVisible] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [cancelMutation] = useCancelPaymentMutation();
	const [confirmPayment] = useConfirmPaymentMutation();

	const goToBackPage = () => {
		navigate(-1);
	};

	const { data, refetch } = useGetPaymentDetailQuery({ id });
	const detailData = data && data.row;

	const handleCancelSubmit = async () => {
		dispatch(fullLoadingOn());

		const result = await cancelMutation({ id: detailData?.id });

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					typeof (result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '승인 취소가 완료되었습니다.');
			refetch();
		}

		setIsCancelAlertVisible(false);
		dispatch(fullLoadingOff());
	};

	const handleCancelClick = () => {
		setIsCancelAlertVisible(true);
	};

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleConfirmPaymentClick = async () => {
		if (id) {
			const res = await confirmPayment({
				id,
			});

			if (!('error' in res)) {
				handleAlertClose();
				toast('info', '결제완료 처리되었습니다.');
				dispatch(popupUpdateHandler());
			}
			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				handleAlertClose();
				toast('error', errorMessage);
			}
		}
	};

	const renderAlertOpen = () => {
		return (
			<Alert
				title="결제완료 변경"
				closeButtonClick={handleAlertClose}
				onConfirmButtonClick={handleConfirmPaymentClick}
				onConfirmButtonText="현금결제 완료 처리"
				isVisible={isAlertOpen}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						결제완료 상태로 변경하시겠습니까?
					</Typo>
				</p>
			</Alert>
		);
	};

	return (
		<>
			<GridDetailTemplate detailTitle="매출 상세" onBack={goToBackPage}>
				<ContentBoxWithHeader
					title="매출 내용"
					borderRadius="10px"
					className="inner-content"
					rightAccessory={
						<ButtonContainer>
							{detailData?.paymentStatus ===
							PAYMENT_STATUS_EXPECTATION_VALUE ? (
								<Button
									$buttonType={ButtonTypeType.GHOST_BLACK}
									onClick={handleAlertOpen}
									size="small"
								>
									현금결제 완료 처리
								</Button>
							) : null}
							{detailData?.paymentStatus === PAYMENT_STATUS_SUCCESS_VALUE ? (
								<Button
									$buttonType={ButtonTypeType.GHOST_DANGER}
									onClick={handleCancelClick}
									size="small"
									disabled={detailData?.paymentMethod === '현금'}
								>
									승인 취소
								</Button>
							) : null}
						</ButtonContainer>
					}
				>
					<GridLayout>
						{detailData &&
							renderTable({
								tableKeys: monthlyTableKey,
								data: detailData as any,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="계약 정보"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						{detailData?.subscription &&
							renderTable({
								tableKeys: subscriptionTableKey,
								data: detailData.subscription as any,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="상품 정보"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						<GridItem title="오픈트럭 계약기간" size={GridTitleSize.GT_MEDIUM}>
							{detailData?.subscription.subscriptionVehicle
								.subscriptionPeriod || '-'}
						</GridItem>
						<GridItem title="오픈트럭 계약상태" size={GridTitleSize.GT_MEDIUM}>
							{detailData?.subscription.subscriptionVehicle.status || '-'}
						</GridItem>
						<GridItem title="오픈스패너" size={GridTitleSize.GT_MEDIUM}>
							{detailData?.subscription.subscriptionCarCare
								.subscriptionProductName || '-'}
						</GridItem>
					</GridLayout>
				</ContentBoxWithHeader>
				{detailData?.userType === 'CUSTOMER' && (
					<ContentBoxWithHeader
						title="고객 정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{detailData?.customer &&
								renderTable({
									tableKeys: customerTableKey,
									data: detailData.customer as any,
								})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
				{detailData?.userType === 'ADMIN' && (
					<ContentBoxWithHeader
						title="법인 담당자 정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{detailData?.admin &&
								renderTable({
									tableKeys: adminTableKey,
									data: detailData.admin as any,
								})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
			</GridDetailTemplate>
			<Alert
				isVisible={isCancelAlertVisible}
				title="승인 취소"
				closeButtonClick={() => {
					setIsCancelAlertVisible(false);
				}}
				onConfirmButtonText="취소"
				onConfirmButtonClick={handleCancelSubmit}
			>
				결제 승인을 취소하시겠습니까?
			</Alert>
			{/* 결제완료 처리 Alert */}
			{isAlertOpen && renderAlertOpen()}
		</>
	);
}

export default MonthlySalesDetailPage;
