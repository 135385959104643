import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useGridData from '../../../../hooks/useGridData';
import {
	CS_APP_VERSION_CREATE_PATH,
	CS_APP_VERSION_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import { CUSTOMER_APP, MANAGER_APP } from '../../../../utils/const';
import {
	createOptionsBulletTextCellRender,
	createOptionsTextCellRender,
} from '../../../../utils/row-data-util';
import userData from './gridData';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function AppVersionPage() {
	const navigate = useNavigate();
	const { formInitialValues, colsList, forms } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
	} = useGridData({
		queryPath: 'core/app-versions',
		detailPath: CS_APP_VERSION_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const handleCreateClick = () => {
		navigate(CS_APP_VERSION_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="앱 버전 관리"
			gridTitle="앱 버전 리스트"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleCreateClick} size="small">
						앱 버전 등록
					</Button>
				</ButtonContainer>
			}
			frameworkComponents={{
				...createOptionsTextCellRender([
					{ label: '소비자 앱', value: CUSTOMER_APP, color: 'black' },
					{ label: '매니저 앱', value: MANAGER_APP, color: 'black' },

					{ label: 'iOS', value: 'ios', color: 'black' },
					{ label: 'AOS', value: 'android', color: 'black' },
				]),
				...createOptionsBulletTextCellRender([
					{ label: '활성화', value: true, color: 'black' },
					{ label: '비활성화', value: false, color: 'black' },
				]),
			}}
		/>
	);
}

export default AppVersionPage;
