import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface TabProps<T> {
	isActive: boolean;
	value: T;
	label: ReactNode;
	onClick: ((value: T) => void) | undefined;
}

type ArticleStyle = {
	$isActive: boolean;
};

const Article = styled.article<ArticleStyle>`
	${({ theme }) => theme.typo.hm6};
	display: inline-block;
	cursor: pointer;

	${({ $isActive }) =>
		$isActive
			? css`
					color: ${({ theme }) => theme.common.colors.primary_6_main};
					border-bottom: 3px solid
						${({ theme }) => theme.common.colors.primary_6_main};

					p.tab-value {
						padding-bottom: 10px;
					}
			  `
			: css`
					color: ${({ theme }) => theme.common.colors.gray_6};

					p.tab-value {
						padding-bottom: 13px;
					}
			  `};
`;

function Tab<T>({ isActive, value, label, onClick }: TabProps<T>) {
	return (
		<Article $isActive={isActive} onClick={onClick && (() => onClick(value))}>
			<p className="tab-value">{label}</p>
		</Article>
	);
}

export default Tab;
