import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Typo from '../../../../components/atoms/Typo';
import {
	MANAGER_STATE_NORMAL_LABEL,
	MANAGER_STATE_NORMAL_VALUE,
	MANAGER_STATE_OPTIONS,
	MANAGER_STATE_PENDING_LABEL,
	MANAGER_STATE_PENDING_VALUE,
	MANAGER_STATE_SUSPENSION_LABEL,
	MANAGER_STATE_SUSPENSION_VALUE,
} from '../../../../utils/const';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DATE,
	CELL_TYPE_DETAIL_ACTION,
} from '../../../../utils/row-data-util';
import { phoneFormat } from '../../../../utils/data-format';

const renderManagerStatusCell = ({ value }: ICellRendererParams) => {
	switch (value) {
		case MANAGER_STATE_NORMAL_VALUE:
			return (
				<Typo $typoType="b6" color="gray_11">
					{' '}
					&#8226; {MANAGER_STATE_NORMAL_LABEL}
				</Typo>
			);
		case MANAGER_STATE_PENDING_VALUE:
			return (
				<Typo $typoType="b6" color="gray_11">
					{' '}
					&#8226; {MANAGER_STATE_PENDING_LABEL}
				</Typo>
			);
		case MANAGER_STATE_SUSPENSION_VALUE:
			return (
				<Typo $typoType="b6" color="gray_7">
					{' '}
					&#8226; {MANAGER_STATE_SUSPENSION_LABEL}
				</Typo>
			);
		default:
			return '-';
	}
};

const userData: GridDataModel = {
	formInitialValues: {
		createdAt: '',
		managerName: '',
		managerDepartment: '',
		managerPhone: '',
		managerStatus: '',
	},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '가입일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DATE,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'managerName',
				headerName: '매니저명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'managerDepartment',
				headerName: '소속',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'managerPhone',
				headerName: '휴대폰번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'managerStatus',
				headerName: '매니저상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				cellRenderer: renderManagerStatusCell,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '가입일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'managerName',
			label: '매니저명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'managerDepartment',
			label: '소속',
			type: FilterElementType.INPUT,
		},
		{
			key: 'managerPhone',
			label: '휴대폰 번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'managerStatus',
			label: '매니저상태',
			type: FilterElementType.SELECT,
			options: MANAGER_STATE_OPTIONS,
		},
		{
			key: 'accountStatus',
			label: '계정상태',
			type: FilterElementType.SELECT,
			options: [
				{ label: '활성화', value: 'activated' },
				{ label: '비활성화', value: 'deactivated' },
			],
		},
	],
};

export default userData;
