import { AdminDuplicateDto } from '../../../interface/adminDuplicate';
import { axiosInstance } from '../../../network/axios';

const getAdminDuplicate = async (params: AdminDuplicateDto) => {
	const url = `/members/administrators/this/duplicate`;
	const { data } = await axiosInstance.request({ url, params });
	return data;
};

export default getAdminDuplicate;
