import { axiosInstance } from '../../../network/axios';
import { authApiSlice } from '../query';

const asidePopupApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAsidePopup: builder.query<Record<string, number>, {}>({
			query: () => ({
				method: 'GET',
				url: `/members/label`,
			}),
		}),
	}),
});

export const { useGetAsidePopupQuery } = asidePopupApiSlice;

const getAsidePopup = async () => {
	const url = `/members/label`;
	const { data } = await axiosInstance.get(url);

	return data;
};

export default getAsidePopup;
