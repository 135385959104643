import React from 'react';
import styled from 'styled-components';
import departmentData from './gridData/gridData';
import useCompaniesOption from '../../../hooks/useCompaniesOption';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import useCheckRole from '../../../hooks/useCheckRole';
import { useGetDepartmentsQuery } from '../../../store/apis/department';
import { createDetailAction } from '../../../utils/row-data-util';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import DepartmentModal from '../../../components/templates/DepartmentModal';
import { DEPARTMENT_DETAIL_PATH } from '../../../routes/constants/urls';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function DepartmentsManagementPage() {
	const companiesOption = useCompaniesOption();

	const { formInitialValues, colsList, forms } = departmentData({
		companiesOption,
	});

	const isCreateDepartment = useCheckRole({
		roleType: 'isCreate',
		roleName: '소속관리',
		includeSuper: true,
	});

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetDepartmentsQuery,
		detailPath: DEPARTMENT_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="소속 관리"
			gridTitle="소속 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'id'),
			}}
			rightAccessory={
				isCreateDepartment && (
					<ButtonContainer>
						<DepartmentModal userType="CUSTOMER" />
					</ButtonContainer>
				)
			}
		/>
	);
}

export default DepartmentsManagementPage;
