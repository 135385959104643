import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { zIndex } from '../../../styles/GlobalStyle';
import { ButtonTypeType, ThemeType } from '../../../styles/theme';
import Button from '../Button';
import Typo from '../Typo';

interface AlertProps {
	title: string;
	closeButtonText?: string;
	closeButtonClick: () => void;
	onConfirmButtonText: string;
	onConfirmButtonClick?: () => void;
	isVisible: boolean;
}

const AlertContainer = styled.div``;

const AlertContentContainer = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: ${zIndex.alert};
`;

const AlertContent = styled.div<{ theme: ThemeType }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 360px;
	padding: 24px;
	border-radius: 16px;
	box-shadow: 0px 4px 24px rgba(94, 101, 110, 0.2);
	color: ${({ theme }) => theme.common.colors.gray_8};
	background: ${({ theme }) => theme.common.colors.white};
	z-index: 100;

	.alert-title {
		margin-bottom: 4px;
	}

	.alert-children {
		margin-bottom: 24px;
	}

	.alert-button-container {
		display: flex;
		justify-content: flex-end;
		gap: 14px;
	}
`;

function Alert({
	title,
	children,
	closeButtonText = '닫기',
	closeButtonClick,
	onConfirmButtonText,
	onConfirmButtonClick,
	isVisible,
}: PropsWithChildren<AlertProps>) {
	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			if (e.key === 'Enter' && onConfirmButtonClick) {
				onConfirmButtonClick();
			}
		};

		window.addEventListener('keyup', listener);

		return () => {
			window.removeEventListener('keyup', listener);
		};
	}, [onConfirmButtonClick]);

	const alertLayoutRender = () => {
		return (
			<AlertContainer>
				{isVisible && (
					<AlertContentContainer onClick={closeButtonClick}>
						<AlertContent
							onClick={(e: React.MouseEvent<HTMLDivElement>) => {
								e.stopPropagation();
							}}
						>
							<h1 className="alert-title">
								<Typo $typoType="h3">{title}</Typo>
							</h1>
							<div className="alert-children">{children}</div>
							<div className="alert-button-container">
								<Button
									$buttonType={ButtonTypeType.OPAQUE}
									onClick={closeButtonClick}
								>
									{closeButtonText}
								</Button>
								<Button onClick={onConfirmButtonClick}>
									{onConfirmButtonText}
								</Button>
							</div>
						</AlertContent>
					</AlertContentContainer>
				)}
			</AlertContainer>
		);
	};

	return ReactDOM.createPortal(
		alertLayoutRender(),
		document.getElementById('alert') as HTMLElement,
	);
}

export default Alert;
