import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import { FilterElementType } from '../../../utils/filter-util';
import { numberWithCommas } from '../../../utils/data-format';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'publicCorporationInspectionReceptionNo',
				headerName: '공단접수번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carLicensePlateNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleInformationNumber',
				headerName: '차량안내번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'channel',
				headerName: '채널',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carName',
				headerName: '차량모델',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'shopName',
				headerName: '검사소',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'resultDate',
				headerName: '검사결과일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'firstRegistrationDate',
				headerName: '차량등록일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'restrictedDate',
				headerName: '검사만료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'carAgeRestrictedDate',
				headerName: '차량만료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestBank',
				headerName: '은행코드',
			},
			options: {
				maxWidth: 100,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionType',
				headerName: '검사종류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionResult',
				headerName: '검사결과',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'noxYn',
				headerName: 'nox 여부',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carMileage',
				headerName: '주행거리',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value ? numberWithCommas(value) : '';
				},
			},
		},
	],
	forms: [
		{
			key: 'carLicensePlateNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
