import React, { useState } from 'react';
import { Drawer, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import TabPane from '../../../components/organisms/TabPane';
import TabPaneBox from '../../../components/organisms/TabPane/TabPaneBox';
import TabPaneBoxItemValue, {
	TabPaneBoxItemRow,
	TabPaneBoxItemTitle,
} from '../../../components/organisms/TabPane/TabPaneBoxItemValue';
import Divider from '../../../styles/divider';

import { DTGInfo } from '../../../interface/vehicleConditions';
import {
	formatDateString,
	formatTimeToString,
	transportDatetime,
} from '../../../utils/date-util';
import { useGetCoordinateDetailRegionQuery } from '../../../store/apis/etc';
import { numberWithCommas } from '../../../utils/data-format';
import VehiclesMap from '../../../components/organisms/NaverMap/VehiclesMap';
import VehicleCalendar from '../../../components/organisms/VehicleCalendar';

interface DTGInfoTabPaneProps {
	data: DTGInfo | null;
	open: boolean;
	onClose: () => void;
	currentLocationCase?: boolean;
}

export const vehicleStatus = (status: number) => {
	switch (status) {
		case 0:
			return '운행기록장치 정상';
		case 11:
			return '위치추적장치(GPS수신기) 이상';
		case 12:
			return '속도센서 이상';
		case 13:
			return 'RPM 센서 이상';
		case 14:
			return '브레이크 신호감지 센서 이상';
		case 21:
			return '센서 입력부 장치 이상';
		case 22:
			return '센서 출력부 장치 이상';
		case 31:
			return '데이터 출력부 장치 이상';
		case 32:
			return '통신 장치 이상';
		case 41:
			return '운행거리 산정 이상';
		case 99:
			return '전원공급 이상';
		default:
			return '알수없음';
	}
};

const DetailRegion = ({
	x,
	y,
	skip,
}: {
	x: string;
	y: string;
	skip: boolean;
}) => {
	const { data: regionData, isError } = useGetCoordinateDetailRegionQuery(
		{
			lat: y,
			lng: x,
		},
		{ skip },
	);
	return isError ? (
		<TabPaneBoxItemRow>
			<TabPaneBoxItemTitle>주소</TabPaneBoxItemTitle>
		</TabPaneBoxItemRow>
	) : (
		<TabPaneBoxItemRow>
			<TabPaneBoxItemTitle>주소</TabPaneBoxItemTitle>
			<TabPaneBoxItemValue
				type="text"
				content={regionData?.street || regionData?.zibun}
			/>
		</TabPaneBoxItemRow>
	);
};

function DTGInfoPane({
	data,
	open,
	onClose,
	currentLocationCase = false,
}: DTGInfoTabPaneProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const initialDate =
		searchParams.get('date') || dayjs(data?.t).format('YYYY-MM-DD');

	const [selectedDate, setSelectedDate] = useState<Dayjs>(
		dayjs(dayjs(data?.t).format('YYYY-MM-DD')),
	);
	const [selectedYear, setSelectedYear] = useState(dayjs(initialDate).year());
	const [selectedMonth, setSelectedMonth] = useState<number>(
		dayjs(initialDate).month(),
	);
	const [vehicleNumber, setVehicleNumber] = useState(
		searchParams.get('vehicleNumber') || '',
	);

	const handleDateSelect = (value: any, { source }: any) => {
		const dateSelected = value.format('YYYYMM');
		setVehicleNumber(data?.vehicleNumber || '');
		setSearchParams({
			vehicleNumber,
			date: dateSelected,
		});

		if (source === 'date') {
			setSelectedYear(value.get('year'));
			setSelectedMonth(value.get('month'));
		}
	};

	return (
		<Drawer open={open} width={720} closable={false} onClose={onClose}>
			<TabPane
				title="차량 정보"
				style={
					data
						? { gap: '24px', height: 'auto', padding: '1rem' }
						: { padding: '1rem' }
				}
				isPlaceHolder={!data}
			>
				<TabPaneBox
					title={
						!data?.vehicleNumber
							? `운행시간 ${data && formatTimeToString(data.t)}`
							: data.vehicleNumber
					}
				>
					{currentLocationCase && (
						<TabPaneBoxItemRow>
							<TabPaneBoxItemTitle>마지막 운행시각</TabPaneBoxItemTitle>
							<TabPaneBoxItemValue
								type="text"
								content={
									data && (
										<>
											<Tag color="red">
												{transportDatetime(
													dayjs(
														`20${data.t.slice(0, 6)}T${data.t.slice(6)}`,
													).toISOString(),
												)}
											</Tag>
											<Tag color="red">
												{formatDateString(
													`20${data.t.slice(0, 6)}T${data.t.slice(6)}`,
													true,
												)}
											</Tag>
										</>
									)
								}
							/>
						</TabPaneBoxItemRow>
					)}
					{currentLocationCase || (
						<>
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>차량상태</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									// @ts-ignore
									content={data && vehicleStatus(data.s)}
								/>
							</TabPaneBoxItemRow>
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>브레이크</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									content={data && data.b === 1 ? '밟음' : '밟지않음'}
								/>
							</TabPaneBoxItemRow>
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>RPM</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									content={data && numberWithCommas(data.r)}
								/>
							</TabPaneBoxItemRow>
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>속도</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									content={data && `${numberWithCommas(data.v)} km/h`}
								/>
							</TabPaneBoxItemRow>
							<Divider $verticalGap={10} />
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>금일 운행거리</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									content={data && `${numberWithCommas(data.d)} km`}
								/>
							</TabPaneBoxItemRow>
							<TabPaneBoxItemRow>
								<TabPaneBoxItemTitle>총 운행거리</TabPaneBoxItemTitle>
								<TabPaneBoxItemValue
									type="text"
									content={data && `${numberWithCommas(data.m)} km`}
								/>
							</TabPaneBoxItemRow>
						</>
					)}
					<Divider $verticalGap={10} />
					{data && (
						<DetailRegion
							x={String(data.x / 1000000)}
							y={String(data.y / 1000000)}
							skip={!data}
						/>
					)}
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>위도</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={data && data.y / 1000000}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>경도</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={data && data.x / 1000000}
						/>
					</TabPaneBoxItemRow>
					<Divider $verticalGap={10} />
					{data && (
						<div style={{ width: '100%', height: '400px' }}>
							<VehiclesMap
								mapId="dtgMap"
								markerList={[data]}
								hasZoomController={false}
								imageIcon
							/>
						</div>
					)}
				</TabPaneBox>
			</TabPane>
			<VehicleCalendar
				vehicleNumber={data?.vehicleNumber || ''}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				selectedYear={selectedYear ? dayjs(`${selectedYear}`).year() : 0}
				setSelectedYear={setSelectedYear}
				selectedMonth={data?.t ? selectedMonth : 0}
				setSelectedMonth={setSelectedMonth}
				isYearSelected
				handleDateSelect={handleDateSelect}
			/>
		</Drawer>
	);
}

export default DTGInfoPane;
