// eslint-disable-next-line import/no-extraneous-dependencies
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

export interface ToolTipProps {
	title: string;
}

const ToolTip = ({ title }: ToolTipProps) => {
	return (
		<Tooltip title={title}>
			<QuestionCircleOutlined
				style={{
					marginLeft: '3px',
					fontSize: '12px',
					cursor: 'pointer',
					color: '#1890ff',
				}}
			/>
		</Tooltip>
	);
};

export default ToolTip;
