import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import { Form, Image } from 'antd';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import FormItem from 'antd/es/form/FormItem';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { InquiryDto } from '../../../../interface/inquiry';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	getInquiryItemViaId,
	useUpdateInquiryResponseMutation,
} from '../../../../store/apis/inquiry';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import theme, { ButtonTypeType } from '../../../../styles/theme';
import { formatDateString } from '../../../../utils/date-util';
import { NewTableValueType } from '../../../../utils/table-util';
import { getIsReplied } from './utils';
import { inquiryTablekeys, answerTablekeys } from './gridData';
import { INQUIRY_PENDING_VALUE } from '../../../../utils/const';
import InputAlert from '../../../../components/atoms/InputAlert';
import TextArea from '../../../../components/atoms/TextArea';
import { popupUpdateHandler } from '../../../../store/popup';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

enum AlertState {
	CLOSE,
	OPEN,
}

type InquiryTableValueType = Pick<
	InquiryDto,
	'updatedAt' | 'reply' | 'replier'
>;

function OneOnOneChatDetailPage() {
	const [textAreaValue, setTextAreaValue] = useState('');
	const [textAreaValueError, setTextAreaValueError] = useState<string | null>(
		null,
	);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.CLOSE);
	const [data, setData] = useState<InquiryDto | null>(null);
	const [inquiryTableValue, setInquiryTableValue] =
		useState<InquiryTableValueType | null>(null);

	const [isError, setIsError] = useState(false);

	const [updateInquiryResponse] = useUpdateInquiryResponseMutation();
	const { detailId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);

	const goBack = () => {
		navigate(-1);
	};

	const openAlert = () => {
		setAlertState(AlertState.OPEN);
	};

	const closeAlert = () => {
		setAlertState(AlertState.CLOSE);
	};

	const handleCreateClick = () => {
		if (!textAreaValue.length) {
			setTextAreaValueError('답변 내용을 입력해주세요.');
			return;
		}

		openAlert();
	};

	const fetchData = useCallback(async () => {
		if (detailId) {
			try {
				dispatch(fullLoadingOn());
				const itemInfo = await getInquiryItemViaId(detailId);

				const { createdAt, customer, manager, ...rest } = itemInfo;

				setData({
					...rest,
					...customer,
					...manager,
					createdAt: formatDateString(dayjs(createdAt), true),
				});

				if (getIsReplied(itemInfo.status)) {
					setInquiryTableValue({
						updatedAt: formatDateString(dayjs(itemInfo.updatedAt), true),
						reply: itemInfo.reply,
						replier: itemInfo.replier,
					});
				}
			} catch (e) {
				const err = e as AxiosError<{
					error: string;
					message: string;
					statusCode: number;
					translate: string;
				}>;

				if (err.response) {
					toast(
						'error',
						err.response?.data?.translate || err.response?.data?.message,
					);
				}

				setIsError(true);
			} finally {
				dispatch(fullLoadingOff());
			}
		}
	}, [detailId, dispatch, toast]);

	const handleAlertConfirm = async () => {
		if (!detailId) return;

		const result = await updateInquiryResponse({
			id: detailId,
			body: {
				reply: textAreaValue,
			},
		});

		if (!('error' in result)) {
			toast('info', '답변 등록이 완료되었습니다.');
			fetchData();
			await dispatch(popupUpdateHandler());
		} else if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}

		closeAlert();
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const renderHeaderRightAccessary = () => {
		if (data && !getIsReplied(data?.status)) {
			return (
				<ButtonContainer>
					<Button
						$buttonType={ButtonTypeType.OPAQUE}
						onClick={goBack}
						size="small"
					>
						취소
					</Button>
					<Button onClick={handleCreateClick} size="small">
						등록하기
					</Button>
				</ButtonContainer>
			);
		}
		return null;
	};

	const renderTable = <T extends Record<keyof T, ReactNode>>({
		tableKeys,
		tableData,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		tableData: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value], idx) => {
			const tableContent = tableData[key as keyof T];
			const { label, span } = value;

			let serviceValue = '';

			if (key === 'service' && tableContent === 'CUSTOMER_APP') {
				serviceValue = '소비자';
			}
			if (key === 'service' && tableContent === 'MANAGER_APP') {
				serviceValue = '매니저';
			}

			const cardNumberReg = /[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/g;
			const juminNumberReg =
				/\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]\d{6}/g;

			switch (key) {
				case 'body':
				case 'reply':
					return (
						<GridItem
							key={key}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
							$isAutoHeight
						>
							<Typo $typoType="b6">
								{tableContent
									? String(tableContent)
											.replaceAll(cardNumberReg, '****-****-****-****')
											.replaceAll(juminNumberReg, '******-*******')
									: '-'}
							</Typo>
						</GridItem>
					);
				case 'createdAt':
				case 'updatedAt':
					return (
						<GridItem
							key={key}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent
								? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
								: '-'}
						</GridItem>
					);
				case 'inquiryMedias':
					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							<div style={{ display: 'flex', gap: '4px' }}>
								{(tableContent as string[])?.map((src: string, index) => {
									return (
										<Image
											key={index.toString()}
											width={32}
											height={32}
											src={src as string}
										/>
									);
								})}
							</div>
						</GridItem>
					);
				case 'status':
					switch (tableContent) {
						case INQUIRY_PENDING_VALUE:
							return (
								<GridItem
									key={idx.toString()}
									title={value.label}
									size={GridTitleSize.GT_MEDIUM}
									span={value.span}
								>
									<span style={{ color: theme.common.colors.danger_5_main }}>
										&#8226; 답변대기
									</span>
								</GridItem>
							);
						default:
							return (
								<GridItem
									key={idx.toString()}
									title={value.label}
									size={GridTitleSize.GT_MEDIUM}
									span={value.span}
								>
									<span style={{ color: theme.common.colors.gray_11 }}>
										&#8226; 답변완료
									</span>
								</GridItem>
							);
					}
				case 'customerName':
					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{tableContent ||
								tableData['managerName' as keyof T] ||
								'탈퇴회원'}
						</GridItem>
					);
				case 'customerPhone':
					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{String(
								tableContent ||
									tableData['managerPhone' as keyof T] ||
									'탈퇴회원',
							)?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) || '-'}
						</GridItem>
					);
				case 'service':
					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{serviceValue}
						</GridItem>
					);
				default:
					return (
						<GridItem
							key={key}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent || '-'}
						</GridItem>
					);
			}
		});
	};

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (isError) return <></>;

	return (
		<>
			<GridDetailTemplate
				detailTitle="1:1문의 등록"
				onBack={goBack}
				rightAccessory={renderHeaderRightAccessary()}
			>
				<ContentBoxWithHeader
					title="문의 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						{data &&
							renderTable({
								tableKeys: inquiryTablekeys,
								tableData: data,
							})}
					</GridLayout>
				</ContentBoxWithHeader>

				<ContentBoxWithHeader
					title="답변 내용"
					borderRadius="10px"
					className="inner-content"
				>
					{getIsReplied(data?.status) ? (
						<GridLayout $columnCount={2}>
							{inquiryTableValue &&
								renderTable({
									tableKeys: answerTablekeys,
									tableData: inquiryTableValue,
								})}
						</GridLayout>
					) : (
						<Form>
							<FormItem
								name="reason"
								rules={[
									{ required: true, message: '중지사유를 입력해 주세요' },
								]}
								colon={false}
							>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<TextArea
										style={{ width: '830px', height: '240px' }}
										placeholder="답변을 작성해 주세요. 답글 입력 시 자동으로 답변완료 상태로 변경됩니다."
										value={textAreaValue}
										onChange={(e) => {
											if (textAreaValueError && e.target.value.length) {
												setTextAreaValueError(null);
											}

											setTextAreaValue(e.target.value);
										}}
									/>
								</div>
								{textAreaValueError && (
									<InputAlert $inputStatus="error">
										{textAreaValueError}
									</InputAlert>
								)}
							</FormItem>
						</Form>
					)}
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{alertState === AlertState.OPEN && (
				<Alert
					title="답변 등록"
					closeButtonClick={closeAlert}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleAlertConfirm}
					isVisible={alertState === AlertState.OPEN}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							답변을 등록하시겠습니까?
						</Typo>
					</p>
				</Alert>
			)}
		</>
	);
}

export default OneOnOneChatDetailPage;
