import { AxiosError } from 'axios';
import {
	ManagerStatusType,
	ProductType,
	SubscriptionItemDto,
	SubscriptionItemStatusType,
} from '../../../interface/subscriptionItem';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

export interface GetSubscriptionParam {
	productType?: ProductType;
	customerRequestVisitedAt?: string;
	subscriptionItemStatus?: SubscriptionItemStatusType;
	departmentId?: number;
	customerRequestVisitedAtFrom?: string;
	customerRequestVisitedAtTo?: string;
	maximumAdditionalPaidPriceTax?: string;
	minimumAdditionalPaidPriceTax?: string;
	subscriptionItemManagerStatus?: ManagerStatusType;
	managerPhone?: string;
	managerName?: string;
	street?: string;
	managerDepartment?: string;
	requestedAtFrom?: string;
	requestedAtTo?: string;
	customerName?: string;
	customerPhone?: string;
	administratorName?: string;
	page?: number;
	pageSize?: number;
}

const subscriptionItemApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionItemAll: builder.query<
			ListResponse<SubscriptionItemDto>,
			GetSubscriptionParam
		>({
			query: (args) => {
				return { url: '/members/admin/subscription-items', params: args };
			},
		}),
		subscriptionItemUpdate: builder.mutation<
			ObjectResponse<SubscriptionItemDto>,
			{ id: string; subscriptionMemo: string }
		>({
			query: ({ id, subscriptionMemo }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-items/${id}/memo`,
				body: { subscriptionMemo },
			}),
		}),
		subscriptionItemManagerUpdate: builder.mutation<
			ObjectResponse<SubscriptionItemDto>,
			{ id: string; body: { managerId: number; expectationVisitedAt: string } }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-items/${id}/manager`,
				body,
			}),
		}),
		subscriptionItemImageUpdate: builder.mutation<
			ObjectResponse<SubscriptionItemDto>,
			{
				id: string;
				body: {
					beforeMedias: string[] | undefined;
				};
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-items/${id}`,
				body,
			}),
		}),
		subscriptionItemManagerCancel: builder.mutation<
			ObjectResponse<SubscriptionItemDto>,
			string
		>({
			query: (id) => ({
				method: 'DELETE',
				url: `/members/admin/subscription-items/${id}/manager`,
			}),
		}),
		subscriptionItemExpectationVisitDateUpdate: builder.mutation<
			ObjectResponse<SubscriptionItemDto>,
			{ id: string; body: { reservationVisitedAt: string } }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/subscription-items/${id}/reservation-visit-date`,
				body,
			}),
		}),
	}),
});

export const {
	useGetSubscriptionItemAllQuery,
	useSubscriptionItemUpdateMutation,
	useSubscriptionItemImageUpdateMutation,
	useSubscriptionItemManagerCancelMutation,
	useSubscriptionItemManagerUpdateMutation,
	useSubscriptionItemExpectationVisitDateUpdateMutation,
} = subscriptionItemApi;

export const getSubscriptionItemAll: (
	params?: GetSubscriptionParam,
) => Promise<SubscriptionItemDto[]> = async (params?: GetSubscriptionParam) => {
	const baseUrl = '/members/admin/subscription-items';

	try {
		const res = await axiosInstance(baseUrl, {
			method: 'get',
			params,
		});
		return res.data.rows;
	} catch (e) {
		const err = e as AxiosError<{ message: string; translate: string }>;

		if (err.response?.status === 404) {
			return [];
		}

		throw err;
	}
};
