import React from 'react';
import { Select, Input } from 'antd';
import { FormikHelpers, FormikValues } from 'formik';
import styled from 'styled-components';

export enum FilterElementType {
	INPUT,
	NUMBER_INPUT,
	PHONE_INPUT,
	SELECT,
	OPTIONAL_SELECT,
	CUSTOM,
	DATE_PICKER,
	DATE_RANGE_PICKER,
}

export interface FilterSelectFormInfo {
	key: string;
	label: string;
	type: number;
}

const MySelectContainer = styled.div`
	display: flex;

	.ant-select .ant-select-selector {
		border-radius: 0px;
		background-color: #f8f8f8;
		border: none;
	}
`;

const RenderSelectFormElement = ({
	selectForms,
	formik,
	selectedOption,
	setSelectedOption,
	isSelectValueSelected,
	setIsSelectValueSelected,
}: {
	selectForms: FilterSelectFormInfo[];
	formik: FormikHelpers<FormikValues> & FormikValues;
	selectedOption: FilterSelectFormInfo | null;
	setSelectedOption: (option: FilterSelectFormInfo | null) => void;
	isSelectValueSelected: boolean;
	setIsSelectValueSelected: (value: boolean) => void;
}) => {
	const { values, setFieldValue, setValues } = formik;

	const handleSelectChange = (key: string) => {
		const newSelectedOption = selectForms?.find(
			(selectForm: FilterSelectFormInfo) => selectForm.key === key,
		);

		if (newSelectedOption?.key !== selectedOption?.key) {
			setSelectedOption(newSelectedOption as FilterSelectFormInfo);
			setIsSelectValueSelected(true);

			const updatedValues: FormikValues = { ...values };

			selectForms.forEach((option) => {
				if (option.key !== key) {
					updatedValues[option.key] = '';
				}
			});

			setValues(updatedValues);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (selectedOption) {
			setFieldValue(selectedOption.key, inputValue);
		}
	};

	return (
		<MySelectContainer>
			<Select
				style={{ width: '200px', borderRadius: '0px' }}
				placeholder="옵션"
				onChange={handleSelectChange}
				value={selectedOption ? selectedOption.key : undefined}
			>
				{selectForms?.map((selectForm: FilterSelectFormInfo) => (
					<Select.Option key={selectForm.key} value={selectForm.key}>
						{selectForm.label}
					</Select.Option>
				))}
			</Select>

			<Input
				placeholder={
					isSelectValueSelected ? '입력' : '옵션을 먼저 선택해주세요'
				}
				value={selectedOption ? values[selectedOption.key] : ''}
				style={{ borderRadius: '0px' }}
				onChange={handleInputChange}
				type="text"
				disabled={!isSelectValueSelected}
			/>
		</MySelectContainer>
	);
};

export default RenderSelectFormElement;
