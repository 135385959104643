import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import Icon from '../Icon';
import { grayCalendarIcon } from '../../../assets/icon';

interface DatePickerDateProps extends PickerDateProps<Dayjs> {
	isGrid?: boolean;
}

const DateTimePickerContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
	width: 100%;
	height: 100%;

	input::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}

	transition: 0.3s;
	&:hover {
		border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
		box-shadow: 0 0 0 2px ${({ theme }) => theme.common.colors.primary_1};
	}
	&:focus-within {
		border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
		box-shadow: none;
	}
`;

const StyledGridDatePicker = styled(DatePicker)`
	width: 100%;
	height: 100%;
	border: none;
	padding: 0;

	&:hover,
	&:visited,
	&:active,
	&:focus,
	&:focus-within,
	&:after {
		border: none;
		box-shadow: none;
	}
`;

function DateTimePicker(props: DatePickerDateProps) {
	const { isGrid, ...rest } = props;

	const renderDatePicker = () => {
		if (isGrid) {
			return (
				<StyledGridDatePicker
					{...rest}
					format="YYYY-MM-DD"
					suffixIcon={<Icon src={grayCalendarIcon} width={20} height={20} />}
				/>
			);
		}
		return <StyledDatePicker {...rest} />;
	};

	return (
		<DateTimePickerContainer>{renderDatePicker()}</DateTimePickerContainer>
	);
}

export default DateTimePicker;
