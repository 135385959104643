import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import {
	PRODICT_STATE_OPTIONS,
	PRODUCT_TYPE_OPTIONS,
} from '../../../../utils/const';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_PRICE_TEXT,
	CELL_TYPE_SELECT,
} from '../../../../utils/row-data-util';

const gridData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'id',
				headerName: 'No',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'productType',
				headerName: '상품 유형',
			},
			options: {
				editable: false,
				width: ColSize.CW_LARGE,
				cellRenderer: ({ value }: ICellRendererParams) => {
					const renderValue = PRODUCT_TYPE_OPTIONS.find(
						(item) => item.value === value,
					);

					return renderValue?.label || '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'productName',
				headerName: '상품명',
			},
			options: {
				minWidth: ColSize.CW_DESCRIPTION,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'productPriceIncludedTax',
				headerName: '판매가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: (params: any) => {
					return `${Number(
						params.data.productPrice + params.data.productTax,
					).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'productPrice',
				headerName: '공급가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'productTax',
				headerName: '부가세',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'productAddible',
				headerName: '추가 주문 가능',
			},
			options: {
				width: ColSize.CW_MEDIUM,
				cellRenderer: ({ value }: any) => {
					return value ? 'O' : 'X';
				},
			},
		},
		{
			tableInfo: {
				field: 'productViewable',
				headerName: '상태',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: CELL_TYPE_SELECT,
			},
		},
		{
			tableInfo: {
				field: 'generalPriceIncludedTax',
				headerName: '일반판매가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: (params: any) => {
					return `${Number(
						params.data.generalPrice + params.data.generalTax,
					).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'generalPrice',
				headerName: '일반공급가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'generalTax',
				headerName: '일반부가세',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일자',
			},
			options: {
				minWidth: ColSize.CW_DATE_RANGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'updatedAt',
				headerName: '수정일자',
			},
			options: {
				minWidth: ColSize.CW_DATE_RANGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'productType',
			label: '상품 유형',
			type: FilterElementType.SELECT,
			options: PRODUCT_TYPE_OPTIONS,
		},
		{
			key: 'productViewable',
			label: '상태',
			type: FilterElementType.SELECT,
			options: PRODICT_STATE_OPTIONS,
		},
		{
			key: 'productName',
			label: '개별 상품명',
			type: FilterElementType.INPUT,
		},
	],
};

export default gridData;
