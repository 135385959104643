import React, { useContext, useRef, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../atoms/Button';
import { axiosInstance } from '../../../network/axios';
import { blobFileDownload } from '../../../utils/file-util';
import { ToastContext } from '../../../contexts/Toast';

const CallHistoryExcelDownload = () => {
	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);
	const fileRef = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState('');

	const handleFocus = () => {
		fileRef?.current?.click();
	};

	const readExcelAndDownload = async () => {
		const file = fileRef.current?.files ? fileRef.current.files[0] : null;
		if (!file) {
			toast('error', '파일이 첨부되지 않았습니다.');
			return;
		}
		if (
			file?.type !==
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		) {
			toast('error', '엑셀파일만 지원합니다.');
			return;
		}

		readXlsxFile(file).then(async (rows) => {
			const vehicleNumberList: (string | number | boolean | DateConstructor)[] =
				rows.map((row) => row[0]);
			setIsLoading(true);
			await axiosInstance
				.post(
					`/car-services/file-download/calls`,
					{ vehicleNumberList },
					{
						responseType: 'blob',
					},
				)
				.then((response) => response.data)
				.then((blob) => {
					const name = `통화내역.xlsx`;
					blobFileDownload(blob, name);
				})
				.catch(async (err) => {
					const responseData = JSON.parse(await err.response.data.text()); // blob 타입으로 json response 받을 때 처리
					toast('error', responseData.translate);
				})
				.finally(() => setIsLoading(false));
		});
	};

	return (
		<ContentBoxWithHeader
			title="통화내역 다운로드"
			className="inner-content"
			borderRadius="10px"
		>
			<GridLayout $columnCount={3}>
				<GridItem title="차량번호 목록" span={3}>
					<span style={{ marginRight: 16 }}>
						{(fileRef.current?.files?.length && fileName) ||
							'엑셀의 첫번째 컬럼(A)에 차량번호만 적어서 첨부하세요'}
					</span>
					<Button
						size="small"
						$buttonType={ButtonTypeType.PRIMARY_LIGHT}
						onClick={() => handleFocus()}
					>
						파일 선택하기
					</Button>
				</GridItem>
			</GridLayout>
			<input
				type="file"
				ref={fileRef}
				onChange={(e) => {
					if (e.target.files?.length) {
						setFileName(e.target.files[0].name);
					}
				}}
				hidden
			/>
			<Button
				size="large"
				$buttonType={ButtonTypeType.GREEN}
				onClick={readExcelAndDownload}
				disabled={isLoading}
				style={{ marginTop: '10px' }}
			>
				{!isLoading ? '다운로드 받기' : '다운로드 중..'}
			</Button>
		</ContentBoxWithHeader>
	);
};

export default CallHistoryExcelDownload;
