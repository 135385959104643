/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface popupState {
	popup: boolean;
}

const initialState: popupState = {
	popup: false,
};

const popupSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {
		popupUpdateHandler(state: popupState) {
			state.popup = !state.popup;
		},
	},
});

export const { popupUpdateHandler } = popupSlice.actions;
export default popupSlice.reducer;
