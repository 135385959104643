/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface menuOpenState {
	asideOpen: boolean;
	filterOpen: boolean;
}

const initialState: menuOpenState = {
	asideOpen: true,
	filterOpen: true,
};

const menuOpenSlice = createSlice({
	name: 'asideMenu',
	initialState,
	reducers: {
		asideMenuHandler(state: menuOpenState) {
			state.asideOpen = !state.asideOpen;
		},
		searchFilterHandler(state: menuOpenState) {
			state.filterOpen = !state.filterOpen;
		},
	},
});

export const { asideMenuHandler, searchFilterHandler } = menuOpenSlice.actions;
export default menuOpenSlice.reducer;
