import { useFormik } from 'formik';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import TextArea from '../../../../components/atoms/TextArea';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { GenerateAppVersionResponseDto } from '../../../../interface/appVersion';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	getAppVersionApiDetailId,
	useAppVersionModifyMutation,
} from '../../../../store/apis/appVersion';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { ButtonTypeType } from '../../../../styles/theme';

import { NewTableValueType } from '../../../../utils/table-util';
import { ButtonContainer } from '../AppVersionCreate';

const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, NewTableValueType>;
	data: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof T];
		const { label, span } = value;
		let typeValue = '';
		let serviceValue = '';

		if (key === 'type' && tableContent === 'ios') {
			typeValue = 'iOS';
		}
		if (key === 'type' && tableContent === 'android') {
			typeValue = 'AOS';
		}

		if (key === 'service' && tableContent === 'CUSTOMER_APP') {
			serviceValue = '소비자 앱';
		}
		if (key === 'service' && tableContent === 'MANAGER_APP') {
			serviceValue = '매니저 앱';
		}

		switch (key) {
			case 'service':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{serviceValue}
					</GridItem>
				);
			case 'type':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{typeValue}
					</GridItem>
				);

			default:
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};

const initialValues = {
	description: '',
};

const appVersionTableKey: Record<string, NewTableValueType> = {
	service: {
		key: 'service',
		label: '플랫폼',
	},
	type: {
		key: 'type',
		label: 'OS',
	},
	version: {
		key: 'version',
		label: '버전',
	},
};

function AppVersionDetailPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);

	const [data, setData] = useState<GenerateAppVersionResponseDto>();
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [appVersionModify] = useAppVersionModifyMutation();

	const goToBackPage = () => {
		navigate(-1);
	};

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.description === '') {
			errors.description = '릴리즈 노트를 입력해주세요.';
		}
		return errors;
	};

	const { setFieldValue, values, errors, handleSubmit, validateForm } =
		useFormik({
			initialValues: { ...initialValues },
			onSubmit: async (value) => {
				setAlertVisible(false);
				dispatch(fullLoadingOn());
				const res = await appVersionModify({
					id: Number(id),
					description: value.description,
				});

				if ('error' in res) {
					toast(
						'error',
						(res.error as CustomErrorType).data.translate ||
							(res.error as CustomErrorType).data.message,
					);
				} else {
					toast('info', '릴리즈노트 저장이 완료되었습니다.');
				}
				dispatch(fullLoadingOff());
			},
			validate: handleValidate,
			validateOnChange: isTrySubmit,
		});

	const handleSubmitAlertClick = async () => {
		const validateValue = await validateForm();

		if (Object.keys(validateValue).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleSubmitClick = () => {
		handleSubmit();
		handleAlertCloseClick();
	};

	useEffect(() => {
		(async () => {
			if (!id) return;

			dispatch(fullLoadingOn());
			const res = await getAppVersionApiDetailId(Number(id));

			if (!('error' in res)) {
				setData(res.row);
				setFieldValue('description', res.row.description);
			}

			if ('error' in res) {
				toast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			}

			dispatch(fullLoadingOff());
		})();
	}, [dispatch, id, setFieldValue, toast]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="앱 버전 상세"
				onBack={goToBackPage}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goToBackPage}
							size="small"
						>
							뒤로가기
						</Button>
						<Button onClick={handleSubmitAlertClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="버전 상세"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						{data &&
							renderTable({
								tableKeys: appVersionTableKey,
								data: data as any,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="릴리즈"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBoxItem>
						<Label name="description" essential>
							<span>
								<Typo $typoType="label2">릴리즈 노트</Typo>
							</span>
						</Label>
						<TextArea
							placeholder="릴리즈 노트를 입력해 주세요"
							value={values.description}
							defaultValue={values.description}
							onChange={(e) => setFieldValue('description', e.target.value)}
						/>
						{errors.description && (
							<InputAlert $inputStatus="error">{errors.description}</InputAlert>
						)}
					</ContentBoxItem>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible ? (
				<Alert
					title="앱 버전 저장"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={handleSubmitClick}
					isVisible={isAlertVisible}
				>
					앱 버전을 저장하시겠습니까?
				</Alert>
			) : null}
		</>
	);
}

export default AppVersionDetailPage;
