import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VehicleSubscriptionConsultingTemplate from '../../../../components/templates/VehicleSubscriptionConsultingTemplate';
import { UpdateVehicleSubscriptionConsultingDto } from '../../../../interface/vehicleSubscriptionConsulting';
import {
	useGetSubscriptionVehicleConsultingDetailQuery,
	useGetSubscriptionVehicleConsultingHistoryQuery,
} from '../../../../store/apis/vehicleSubscriptionConsulting';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import VehicleConsultingGridData from './gridData';
import {
	VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
} from '../../../../routes/constants/urls';

const VehicleSubscriptionConsultingDetail = () => {
	const { consultationId } = useParams();
	const navigate = useNavigate();

	const { data } = useGetSubscriptionVehicleConsultingDetailQuery(
		{ id: Number(consultationId) },
		{ skip: !consultationId },
	);
	const vehicleConsultingDetail = data?.row;

	const { colsList, formInitialValues } = VehicleConsultingGridData;

	const {
		rowData,
		totalCount,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: (args) =>
			useGetSubscriptionVehicleConsultingHistoryQuery({
				id: Number(consultationId),
				page: args.page,
				pageSize: args.pageSize,
			}),
		detailPath: VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH,
		formInitialValues,
		colsList,
		navigateCallback: () =>
			navigate(VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH),
	});

	const initialValues: UpdateVehicleSubscriptionConsultingDto = {
		consultationType: vehicleConsultingDetail?.consultationType,
		customerType: vehicleConsultingDetail?.customerType,
		customerName: vehicleConsultingDetail?.customerName,
		customerPhone: vehicleConsultingDetail?.customerPhone,
		customerEmail: vehicleConsultingDetail?.customerEmail,
		contactName: vehicleConsultingDetail?.contactName,
		subscriptionStartedAt: vehicleConsultingDetail?.subscriptionStartedAt,
		subscriptionEndedAt: vehicleConsultingDetail?.subscriptionEndedAt,
		vehicleName: vehicleConsultingDetail?.vehicleName,
		memo: vehicleConsultingDetail?.memo,
		historyList: vehicleConsultingDetail?.historyList,
	};
	return (
		<VehicleSubscriptionConsultingTemplate
			gridRef={setGridApi}
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			initialValues={initialValues}
			isFetching={isFetching}
		/>
	);
};

export default VehicleSubscriptionConsultingDetail;
