/* eslint-disable no-new */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SubscriptionItemStatusType } from '../../../interface/subscriptionItem';
import getPinSrc from './utils';

export interface MarkerProp {
	lat: number;
	lng: number;
	subscriptionItemStatus: SubscriptionItemStatusType | null;
	subscriptionItemNo?: string;
}

interface NaverMapProps {
	markerList?: MarkerProp[]; // TODO 마커 아이템 리스트 아이템 타입 정의
	onMarkerClick?: (markerInfo: any) => void;
	hasZoomController?: boolean;
	defaultZoom?: number;
	selectedItem?: MarkerProp;
	mapId?: string;
	isStatic?: boolean;
}

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

function NaverMap({
	markerList = [],
	onMarkerClick,
	hasZoomController = true,
	defaultZoom = 15,
	selectedItem,
	mapId = 'map',
	isStatic = false,
}: NaverMapProps) {
	const [defaultMarkerList] = useState(markerList);
	const mapRef = useRef<naver.maps.Map | undefined>(undefined);
	const markerListRef = useRef<naver.maps.Marker[]>([]);

	const selectedMarkerRef = useRef<naver.maps.Marker | null>(null);

	const resetMarker = useCallback(
		(marker: naver.maps.Marker) => {
			const previousCursorCoordi = marker.getPosition();

			const { x: prevCursorX } = previousCursorCoordi;
			const { y: prevCursorY } = previousCursorCoordi;

			const prevStatus = markerList.find(({ lat, lng }) => {
				return prevCursorX === lng && prevCursorY === lat;
			})?.subscriptionItemStatus;

			marker.setZIndex(0);

			marker.setAnimation(null!);

			marker.setIcon(
				getPinSrc({ status: prevStatus || null, isMarkerOn: false }),
			);
		},
		[markerList],
	);

	const handleMarkerClick = useCallback(
		(markerItemInfo: MarkerProp, noMove?: boolean) => {
			if (onMarkerClick) {
				onMarkerClick(markerItemInfo);
			}

			if (selectedMarkerRef.current) {
				selectedMarkerRef.current.setZIndex(1);

				if (!isStatic) {
					selectedMarkerRef.current.setAnimation(naver.maps.Animation.BOUNCE);
				}

				selectedMarkerRef.current.setIcon(
					getPinSrc({
						status: markerItemInfo.subscriptionItemStatus,
						isMarkerOn: true,
					}),
				);
			}
			if (mapRef.current && !noMove) {
				mapRef.current.morph(markerItemInfo, 17);
			}
		},
		[onMarkerClick, isStatic],
	);

	useEffect(() => {
		if (mapRef.current) return;

		const staticOptions: naver.maps.MapOptions = isStatic
			? { minZoom: 15, maxZoom: 15, draggable: false }
			: {};

		mapRef.current = new naver.maps.Map(mapId || 'map', {
			// zoom: defaultZoom || 15,
			zoomControlOptions: {
				position: naver.maps.Position.RIGHT_TOP,
				style: naver.maps.ZoomControlStyle.SMALL,
			},
			zoomControl: hasZoomController,
			...staticOptions,
		});
	}, [hasZoomController, defaultZoom, mapId, isStatic]);

	useEffect(() => {
		if (markerListRef.current.length) {
			markerListRef.current.forEach((item) => {
				item.setMap(null);
			});
		}

		const markerListTemp: naver.maps.Marker[] = [];

		markerList.forEach((markerItem) => {
			if (mapRef.current === undefined) return;

			const marker = new naver.maps.Marker({
				map: mapRef.current,
				position: markerItem,
				icon: getPinSrc({
					status: markerItem.subscriptionItemStatus,
					isMarkerOn: false,
				}),
			});

			marker.addListener('click', () => {
				if (
					selectedMarkerRef.current &&
					!(marker === selectedMarkerRef.current)
				) {
					resetMarker(selectedMarkerRef.current);
				}

				selectedMarkerRef.current = marker;

				handleMarkerClick(markerItem);
			});

			if (
				selectedItem &&
				selectedItem.subscriptionItemNo === markerItem.subscriptionItemNo
			) {
				if (
					selectedMarkerRef.current &&
					!(marker === selectedMarkerRef.current)
				) {
					resetMarker(selectedMarkerRef.current);
				}

				selectedMarkerRef.current = marker;

				handleMarkerClick(markerItem, true);
			}

			markerListTemp.push(marker);
		});

		markerListRef.current = markerListTemp;
	}, [selectedItem, markerList, resetMarker, handleMarkerClick]);

	const handleInitZoomCenter = useCallback(() => {
		if (defaultMarkerList.length > 0) {
			if (defaultMarkerList.length === 1) {
				if (mapRef.current) {
					mapRef.current.morph({
						lat: defaultMarkerList[0].lat,
						lng: defaultMarkerList[0].lng,
					});
				}
			} else {
				const board = defaultMarkerList.reduce(
					(acc, val) => {
						const getMinValue: (type: 'minX' | 'minY') => number = (
							type: 'minX' | 'minY',
						) => {
							if (type === 'minX') {
								if (acc.minX === 0) {
									return val.lng;
								}

								return val.lng < acc.minX ? val.lng : acc.minX;
							}
							if (type === 'minY') {
								if (acc.minY === 0) {
									return val.lat;
								}

								return val.lat < acc.minY ? val.lat : acc.minY;
							}

							return 0;
						};

						return {
							maxX: val.lng > acc.maxX ? val.lng : acc.maxX,
							minX: getMinValue('minX'),
							maxY: val.lat > acc.maxY ? val.lat : acc.maxY,
							minY: getMinValue('minY'),
						};
					},
					{
						maxX: 0,
						minX: 0,
						maxY: 0,
						minY: 0,
					},
				);

				if (mapRef.current) {
					mapRef.current.panToBounds(
						board,
						{ duration: 500 },
						{ bottom: 200, left: 100, right: 100 },
					);
				}
			}
		}
	}, [defaultMarkerList]);

	useEffect(() => {
		handleInitZoomCenter();
	}, [handleInitZoomCenter]);

	return <MapContainer id={mapId || 'map'} />;
}

export default NaverMap;
