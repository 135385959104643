import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import {
	vehicleSubscriptionConsultationCustomerType,
	vehicleSubscriptionConsultationType,
} from '../../../../interface/vehicleSubscriptionConsulting';

const userData: () => GridDataModel = () => {
	return {
		formInitialValues: {},
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '등록일자',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'consultationType',
					headerName: '상담유형',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerType',
					headerName: '고객유형',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerName',
					headerName: '고객명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerPhone',
					headerName: '고객연락처',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerEmail',
					headerName: '고객이메일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'contactName',
					headerName: '담당자명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicleName',
					headerName: '차량명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscriptionStartedAt',
					headerName: '구독시작일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionEndedAt',
					headerName: '구독종료일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'memo',
					headerName: '메모',
				},
				options: {
					minWidth: ColSize.CW_LONG_TEXT,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: [
			{
				key: 'consultationType',
				label: '상담유형',
				type: FilterElementType.SELECT,
				options: [
					{ label: '전체', value: '' },
					...vehicleSubscriptionConsultationType.map((item) => ({
						value: item,
						label: item,
					})),
				],
			},
			{
				key: 'subscriptionStartedAt',
				type: FilterElementType.DATE_RANGE_PICKER,
				label: '구독시작일',
			},
			{
				key: 'customerType',
				label: '고객유형',
				type: FilterElementType.SELECT,
				options: [
					{ label: '전체', value: '' },
					...vehicleSubscriptionConsultationCustomerType.map((item) => ({
						value: item,
						label: item,
					})),
				],
			},
			{
				key: 'customerName',
				type: FilterElementType.INPUT,
				label: '고객명',
			},
			{
				key: 'vehicleName',
				type: FilterElementType.INPUT,
				label: '차량명',
			},
			{
				key: 'customerPhone',
				type: FilterElementType.INPUT,
				label: '고객 휴대폰 번호',
			},
		],
	};
};

export default userData;
