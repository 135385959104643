import React from 'react';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	MANAGER_STATE_NORMAL_LABEL,
	MANAGER_STATE_NORMAL_VALUE,
	MANAGER_STATE_SUSPENSION_LABEL,
	MANAGER_STATE_SUSPENSION_VALUE,
} from '../../../../utils/const';
import { createOptionsBulletTextCellRender } from '../../../../utils/row-data-util';
import userData from './gridData';
import { MANAGER_DETAIL_PATH } from '../../../../routes/constants/urls';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetManagersQuery } from '../../../../store/apis/manager';

function ManagersPage() {
	const { formInitialValues, colsList, forms } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetManagersQuery,
		detailPath: MANAGER_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="매니저"
			gridTitle="매니저 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createOptionsBulletTextCellRender([
					{
						label: MANAGER_STATE_NORMAL_LABEL,
						value: MANAGER_STATE_NORMAL_VALUE,
						color: 'gray_11',
					},
					{
						label: MANAGER_STATE_SUSPENSION_LABEL,
						value: MANAGER_STATE_SUSPENSION_VALUE,
						color: 'gray_7',
					},
				]),
			}}
		/>
	);
}

export default ManagersPage;
