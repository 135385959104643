import React, { PropsWithChildren } from 'react';
import styled, { CSSObject } from 'styled-components';
import theme from '../../../styles/theme';

interface DotProps {
	$dotColor?: keyof typeof theme.common.colors;
	style?: CSSObject;
}

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const DotSpan = styled.span<{ $dotColor?: keyof typeof theme.common.colors }>`
	width: 10px;
	height: 10px;
	background: ${({ $dotColor }) =>
		$dotColor
			? theme.common.colors[$dotColor]
			: theme.common.colors.primary_6_main};
	border-radius: 100%;
`;

function Dot({ $dotColor, children, style }: PropsWithChildren<DotProps>) {
	return (
		<Container style={style}>
			<DotSpan $dotColor={$dotColor} />
			{children}
		</Container>
	);
}

export default Dot;
