import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Button from '../../../components/atoms/Button';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import {
	VEHICLES_CREATE_PATH,
	VEHICLES_DETAIL_PATH,
} from '../../../routes/constants/urls';
import userData from './gridData';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import { useGetVehiclesQuery } from '../../../store/apis/vehicle';
import { RootState } from '../../../store';
import useDepartmentsOption from '../../../hooks/useDepartmentsOption';
import useCheckRole from '../../../hooks/useCheckRole';
import useCompaniesOption from '../../../hooks/useCompaniesOption';
import { ToastContext } from '../../../contexts/Toast';
import { axiosInstance } from '../../../network/axios';
import { blobFileDownload } from '../../../utils/file-util';
import { ButtonTypeType } from '../../../styles/theme';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function VehiclesManagementPage() {
	const { user } = useSelector((state: RootState) => state.auth);
	const departmentsOption = useDepartmentsOption({ pageSize: 500 });
	const companiesOption = useCompaniesOption();

	const isSuper = useCheckRole({
		roleType: 'isRead',
		roleName: '차량관리',
		includeSuper: true,
	});
	const { formInitialValues, colsList, forms } = userData({
		ownerCompanyId: user?.company?.id ?? 0,
		companiesOption,
		departmentsOption,
		isSuper,
	});
	const navigate = useNavigate();

	const isCreateVehicle = useCheckRole({
		roleType: 'isCreate',
		roleName: '차량관리',
	});

	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);

	const downloadFile = async () => {
		setIsLoading(true);
		await axiosInstance
			.get(`/members/vehicles/excel`, {
				responseType: 'blob',
			})
			.then((response) => response.data)
			.then((blob) => {
				const fileName = `오픈트럭 자산현황.xlsx`;
				blobFileDownload(blob, fileName);
			})
			.catch((err) => {
				if (err.response && err.response.status !== 404) {
					toast('error', '요청을 처리할 수 없습니다.');
				}
				if (err.response && err.response.status === 404) {
					toast('error', '데이터가 없습니다.');
				}
			})
			.finally(() => setIsLoading(false));
	};

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetVehiclesQuery,
		formInitialValues,
		colsList,
		detailPath: VEHICLES_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(VEHICLES_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="소속차량 관리"
			gridTitle="소속차량 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			rightAccessory={
				<ButtonContainer>
					{isSuper && (
						<Button
							$buttonType={ButtonTypeType.GHOST}
							size="small"
							onClick={downloadFile}
						>
							{!isLoading ? '엑셀 다운로드' : '다운로드 중..'}
						</Button>
					)}
					{isCreateVehicle && (
						<Button onClick={handleCreateClick} size="small">
							차량 등록
						</Button>
					)}
				</ButtonContainer>
			}
		/>
	);
}

export default VehiclesManagementPage;
