import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import Popup from '../../atoms/PopUp';
import Typo from '../../atoms/Typo';

interface AccordionItemProps {
	isActive?: boolean;
	count?: number;
}

const AccordionItemLayout = styled.div<{ $isActive: boolean }>`
	position: relative;
	padding: 0 8px;

	.inner-item {
		display: flex;
		gap: 4px;
		padding: 8px 8px 8px 16px;
		border-radius: 8px;
		white-space: nowrap;
	}

	/* :hover {
		.inner-item {
			background-color: #e3f0fc;
		}
	} */
	.item-text {
		font-weight: 400;
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			.item-text {
				font-weight: 500;
			}
			.inner-item {
				background-color: #e3f0fc;
			}
			.inner-text {
				color: black;
			}
			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 4px;
				height: 100%;
				background-color: #2559f6;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		`}
`;

function AccordionItem({
	children,
	isActive = false,
	count,
}: PropsWithChildren<AccordionItemProps>) {
	return (
		<AccordionItemLayout $isActive={isActive}>
			<div className="inner-item">
				<Typo
					$typoType="hm8"
					className="item-text"
					color={isActive ? 'black' : 'gray_9'}
				>
					{children}
				</Typo>
				<Popup count={count} />
			</div>
		</AccordionItemLayout>
	);
}

export default AccordionItem;
