import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormikErrors } from 'formik';
import { useBigFilesUploadSecureMutation } from '../../../store/apis/upload';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import Badge from '../Badge';
import Button from '../Button';
import { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';

interface ImageUploadProps {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<void> | Promise<FormikErrors<any>>;
	field: string;
	targetS3Key: string;
	fileType?: string;
}

function FileUpload({
	setFieldValue,
	field,
	targetS3Key,
	fileType,
}: ImageUploadProps) {
	const toast = useContext(ToastContext);
	const [fileUploadSecure] = useBigFilesUploadSecureMutation();

	const handleUploadFile = useCallback(
		async (acceptedFiles: File[]) => {
			if (acceptedFiles.length) {
				const res = await fileUploadSecure({
					file: acceptedFiles,
					folder: targetS3Key,
				});

				if ('error' in res) {
					const errorMessage =
						(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message;

					toast('error', errorMessage);

					return null;
				}
				await setFieldValue(field, res.data.rows[0] as string);
			}

			return true;
		},
		[field, fileUploadSecure, setFieldValue, targetS3Key, toast],
	);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			// Do something with the files
			const res = await handleUploadFile(acceptedFiles);
			if (res) {
				toast('info', '업로드하였습니다.');
			}
		},
		[handleUploadFile, toast],
	);
	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		onDrop,
		noKeyboard: true,
	});

	return (
		<ContentBoxWithHeader
			title={fileType ?? '파일'}
			essential
			borderRadius="10px"
		>
			{acceptedFiles.length ? (
				acceptedFiles.map((file) => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
						key={file.name}
					>
						<input {...getInputProps()} />
						<span>
							<Badge color="blue">업로드 파일</Badge> {file.name}
						</span>
						<div {...getRootProps()}>
							<Button size="x-small" $buttonType={ButtonTypeType.GHOST_DANGER}>
								다시 올리기
							</Button>
						</div>
					</div>
				))
			) : (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<input {...getInputProps()} />
					<div {...getRootProps()}>
						<Button size="small" $buttonType={ButtonTypeType.GHOST_BLACK}>
							{fileType ?? '파일'}을 첨부해주세요
						</Button>
					</div>
				</div>
			)}
		</ContentBoxWithHeader>
	);
}

export default FileUpload;
