import React from 'react';
import dayjs from 'dayjs';
import Typo from '../../../components/atoms/Typo';
import { GridDataModel } from '../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../utils/row-data-util';
import { NewTableValueType } from '../../../utils/table-util';

export const termDetailTablekeys: Record<string, NewTableValueType> = {
	title: {
		key: 'title',
		label: '약관명',
	},
	createdAt: {
		key: 'createdAt',
		label: '등록 날짜',
	},
	service: {
		key: 'service',
		label: '플랫폼',
	},
	type: {
		key: 'type',
		label: '적용 영역',
	},
	optionable: {
		key: 'optionable',
		label: '필수 여부',
		bulletOptions: [
			{
				key: false,
				value: '필수',
				color: 'primary_6_main',
			},
			{
				key: true,
				value: '선택',
				color: 'gray_11',
			},
		],
	},
	viewable: {
		key: 'viewable',
		label: '노출 여부',
		span: 1,
		bulletOptions: [
			{
				key: true,
				value: '노출',
				color: 'primary_6_main',
			},
			{
				key: false,
				value: '미노출',
				color: 'gray_11',
			},
		],
	},
};

export const termHistoryGridKeys: GridDataModel = {
	colsList: [
		{
			tableInfo: {
				field: 'releasedAt',
				headerName: '게시 날짜',
			},
			options: {
				flex: 1,
				cellRenderer: ({ value }: any) => {
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				headerName: '게시 상태',
				field: 'releasable',
			},
			options: {
				flex: 1,
				cellRenderer: (params: any) => {
					const { data } = params;

					if (data.releasable) {
						return (
							<Typo $typoType="b6" color="primary_6_main">
								&#8226; 게시
							</Typo>
						);
					}
					return <Typo $typoType="b6">&#8226; 중지</Typo>;
				},
			},
		},
		{
			tableInfo: {
				headerName: '등록 날짜',
				field: 'createdAt',
			},
			options: {
				flex: 1,
				cellRenderer: ({ value }: any) => {
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				headerName: '관리',
				field: '',
			},
			options: {
				pinned: 'right',

				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
};
