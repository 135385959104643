export const adminInfoTableKey = {
	createdAt: {
		key: 'createdAt',
		label: '등록일자',
	},
	department: {
		key: 'department',
		label: '관리자 분류',
	},
	departmentManagement: {
		key: 'department',
		label: '소속 관리하기',
	},
	isLock: {
		key: 'isLock',
		label: '잠금해제',
	},
};

export const roleInfoTableKey = {
	roleName: {
		key: 'roleName',
		label: '권한',
	},
};
