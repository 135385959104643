import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Image, Skeleton } from 'antd';
import { SubscriptionDto } from '../../../interface/subscription';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import { customerInfoTableKey, purchaseInfoTableKey } from './gridData';
import Button from '../../atoms/Button';
import theme, { ButtonTypeType } from '../../../styles/theme';
import { NewTableValueType } from '../../../utils/table-util';
import Typo from '../../atoms/Typo';
import { getProviderName } from '../../../utils/string-util';
import {
	CUSTOMER_STATE_DEREGISTERED_VALUE,
	CUSTOMER_STATE_SUSPENSION_VALUE,
} from '../../../utils/const';
import { phoneFormat } from '../../../utils/data-format';
import ContentBox from '../../atoms/ContentBox';

interface CarCareDetailProps {
	detailData: SubscriptionDto | undefined;
	handleAlertOpen?: () => void;
}

const FlexItem = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	img {
		object-fit: contain;
	}
`;

const renderTable = ({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, NewTableValueType>;
	data: SubscriptionDto;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof SubscriptionDto];

		switch (key) {
			case 'purchasedAt':
			case 'customerCreatedAt':
			case 'startedAt':
			case 'endedAt':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);

			case 'provider': {
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Typo $typoType="b6" color="gray_11">
							{getProviderName(tableContent as string)}
						</Typo>
					</GridItem>
				);
			}
			case 'marketingAgreement': {
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Typo $typoType="b6" color={tableContent ? 'gray_11' : 'gray_6'}>
							{tableContent ? '동의' : '미동의'}
						</Typo>
					</GridItem>
				);
			}
			case 'customerStatus': {
				let color: keyof typeof theme.common.colors = 'gray_11';
				let contentValue = '활동중';

				switch (tableContent) {
					case CUSTOMER_STATE_SUSPENSION_VALUE:
						color = 'gray_6';
						contentValue = '제한됨';
						break;
					case CUSTOMER_STATE_DEREGISTERED_VALUE:
						color = 'danger_5_main';
						contentValue = '탈퇴';
						break;
					case undefined:
						contentValue = '정보없음';
						break;
					default:
						break;
				}

				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Typo $typoType="b6" color={color}>
							{contentValue}
						</Typo>
					</GridItem>
				);
			}
			case 'subscriptionStatus': {
				let color: keyof typeof theme.common.colors = 'gray_11';
				let contentValue = '구독중';

				switch (tableContent) {
					case 'PENDING':
						color = 'danger_6';
						contentValue = '결제대기중';
						break;
					case 'GOING':
						color = 'gray_11';
						contentValue = '구독중';
						break;
					case 'DONE':
						color = 'gray_6';
						contentValue = '구독 종료';
						break;
					case 'QUIT':
						color = 'gray_6';
						contentValue = '중도 해지';
						break;
					default:
						break;
				}

				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Typo $typoType="b6" color={color}>
							{contentValue}
						</Typo>
					</GridItem>
				);
			}
			case 'subscriptionProductName':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<FlexItem>
							<Image
								width={32}
								height={32}
								src={(data as any).subscriptionProductImage}
							/>
							<p>{tableContent as string}</p>
						</FlexItem>
					</GridItem>
				);
			case 'vehicleRegistration':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Image width={32} height={32} src={tableContent as string} />
					</GridItem>
				);
			case 'totalTasks':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
						$isAutoHeight
					>
						<ul
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 8,
								listStyle: 'initial',
								paddingLeft: 20,
							}}
						>
							{(tableContent as any[]).map((item: any, index) => (
								<li key={index.toString()}>
									{item.productName} {item.totalCount}회
								</li>
							))}
						</ul>
					</GridItem>
				);
			case 'totalRemainTasks':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
						$isAutoHeight
					>
						<ul
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 8,
								listStyle: 'initial',
								paddingLeft: 20,
							}}
						>
							{(tableContent as any[]).map((item: any, index) => (
								<li key={index.toString()}>
									{item.productName} {item.taskCount || 0}회
								</li>
							))}
						</ul>
					</GridItem>
				);
			case 'currentCount':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{(tableContent as string) || '0'}차
					</GridItem>
				);
			case 'monthlyPriceTax':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{Number(tableContent).toLocaleString() || '-'}원
					</GridItem>
				);
			case 'customerPhone':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{tableContent ? phoneFormat(tableContent as string) : '-'}
					</GridItem>
				);
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{(tableContent as string) || '-'}
					</GridItem>
				);
		}
	});
};

const CarCareDetail = ({ detailData, handleAlertOpen }: CarCareDetailProps) => {
	return detailData ? (
		<>
			<ContentBoxWithHeader
				title="회원정보"
				borderRadius="10px"
				className="inner-content"
			>
				<GridLayout>
					{detailData &&
						renderTable({
							tableKeys: customerInfoTableKey,
							data: detailData as any,
						})}
				</GridLayout>
			</ContentBoxWithHeader>
			<ContentBoxWithHeader
				title="구독정보"
				borderRadius="10px"
				className="inner-content"
				rightAccessory={
					handleAlertOpen && detailData?.startedAt === null ? (
						<Button
							$buttonType={ButtonTypeType.PRIMARY}
							onClick={handleAlertOpen}
							size="small"
						>
							구독 시작으로 변경
						</Button>
					) : null
				}
			>
				<GridLayout $columnCount={2}>
					{detailData &&
						renderTable({
							tableKeys: purchaseInfoTableKey,
							data: detailData as any,
						})}
				</GridLayout>
			</ContentBoxWithHeader>
		</>
	) : (
		<ContentBox borderRadius="10px">
			<Skeleton
				active
				paragraph={{ rows: 6 }}
				style={{ borderRadius: '10px' }}
			/>
		</ContentBox>
	);
};

export default CarCareDetail;
