import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button';
import {
	EditVehicleSubscriptionContract,
	VehicleSubscription,
	vehicleSubscriptionStatus,
} from '../../../interface/vehicleSubscription';
import { ButtonTypeType } from '../../../styles/theme';
import {
	useEditVSContractMutation,
	useForceDeleteVSContractMutation,
} from '../../../store/apis/vehicleSubscription';
import Modal from '../../atoms/Modal';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import InputForm from '../../molecules/InputForm';
import DateTimePicker from '../../atoms/DateTimePicker';
import TextArea from '../../atoms/TextArea';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';
import { numberWithCommas } from '../../../utils/data-format';
import Icon from '../../atoms/Icon';
import { plusIcon } from '../../../assets/icon';
import Typo from '../../atoms/Typo';
import Select from '../../atoms/Select';
import Divider from '../../../styles/divider';
import Alert from '../../atoms/Alert';
import FileUpload from '../../atoms/FileUpload';

interface EditContractInfoButtonProps {
	svNo: string;
	detailInfo?: VehicleSubscription;
}

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;

const ModalFooterInnerWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h3};
`;

const EditContractInfoButton = ({
	svNo,
	detailInfo = undefined,
}: EditContractInfoButtonProps) => {
	const toast = useContext(ToastContext);
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAlert, setIsAlert] = useState(false);
	const [editVSContract] = useEditVSContractMutation();
	const [deleteVSContract] = useForceDeleteVSContractMutation();

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleAlertCloseClick = () => {
		setIsAlert(false);
	};

	const initialValues: EditVehicleSubscriptionContract = {
		// vehicleId: undefined,
		// subscriptionPeriod: undefined,
		// endActionType: undefined,
		// vehiclePrice: undefined,
		// prePaymentPrice: undefined,
		// acquisitionPrice: undefined,
		// agCommissionPct: undefined,
		// paymentMethod: undefined,
		// subscriptionPrice: undefined,
		// subscriptionStartedAt: undefined,
		// subscriptionEndedAt: undefined,
		// eContract: undefined,
		// status: undefined,
		memo: '',
	};

	const handleSubmit = async (
		values: typeof initialValues,
		formikHelpers: FormikHelpers<EditVehicleSubscriptionContract>,
	) => {
		let result;
		if (!!values.subscriptionStartedAt || !!values.subscriptionEndedAt) {
			const subscriptionPeriod = dayjs(
				values.subscriptionEndedAt ??
					detailInfo?.subscription.subscriptionStartedAt,
			).diff(
				values.subscriptionStartedAt ??
					detailInfo?.subscription.subscriptionEndedAt,
				'day',
			);
			result = await editVSContract({
				svNo,
				body: {
					subscriptionPeriod,
					vehicleId: detailInfo?.subscription.vehicle.id,
					...values,
				},
			});
		} else {
			result = await editVSContract({
				svNo,
				body: values,
			});
		}

		if (!('error' in result)) {
			toast('info', '계약을 수정하였습니다.');
			formikHelpers.setValues({ memo: '' });
			handleModalClose();
		} else if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}
	};

	const handleValidate = async (values: typeof initialValues) => {
		const errors: FormikErrors<typeof initialValues> = {};
		if (!values.memo) {
			errors.memo = '사유를 입력해주세요';
		}
		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate: handleValidate,
		onSubmit: handleSubmit,
	});

	const handleDelete = async () => {
		if (!formik.values.memo) {
			toast('error', '사유를 입력해주세요');
			return;
		}

		const result = await deleteVSContract({
			svNo,
			memo: formik.values.memo,
		});

		if (!('error' in result)) {
			toast('info', '계약을 삭제하였습니다.');
			formik.setValues({ memo: '' });
			handleModalClose();
			navigate(-1);
		} else if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}
	};

	return (
		<>
			<Button
				size="small"
				$buttonType={ButtonTypeType.GHOST_BLACK}
				onClick={handleModalOpen}
			>
				계약내용 수정하기
			</Button>
			<Modal
				title={<ModalTitle>계약내용 수정하기</ModalTitle>}
				open={isModalOpen}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={() => setIsAlert(true)}
						>
							삭제하기
						</Button>
						<ModalFooterInnerWrapper>
							<Button
								$buttonType={ButtonTypeType.OPAQUE}
								onClick={handleModalClose}
							>
								닫기
							</Button>
							<Button onClick={() => formik.handleSubmit()}>수정하기</Button>
						</ModalFooterInnerWrapper>
					</ModalFooterWrapper>
				}
			>
				<Alert
					title="계약서 삭세"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="삭제하기"
					onConfirmButtonClick={() => handleDelete()}
					isVisible={isAlert}
				>
					계약서를 정말로 삭제하시겠습니까?
				</Alert>
				<InputForm>
					<Label name="subscriptionPrice">구독일자</Label>
					{formik.values.subscriptionStartedAt === undefined ||
					formik.values.subscriptionEndedAt === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() => {
								formik.setFieldValue(
									'subscriptionStartedAt',
									detailInfo?.subscription.subscriptionStartedAt ?? undefined,
								);
								formik.setFieldValue(
									'subscriptionEndedAt',
									detailInfo?.subscription.subscriptionEndedAt ?? undefined,
								);
							}}
							height={12}
							width={12}
						/>
					) : (
						<>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'flex-start',
									gap: 6,
								}}
							>
								<Button
									size="x-small"
									$buttonType={ButtonTypeType.GHOST_DANGER}
									onClick={() => {
										formik.setFieldValue('subscriptionStartedAt', undefined);
										formik.setFieldValue('subscriptionEndedAt', undefined);
									}}
								>
									-
								</Button>
								<Typo>
									{dayjs(formik.values.subscriptionEndedAt).diff(
										formik.values.subscriptionStartedAt,
										'day',
									)}
									일
								</Typo>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: 6,
								}}
							>
								<DateTimePicker
									name="subscriptionStartedAt"
									value={dayjs(
										formik.values.subscriptionStartedAt || undefined,
									)}
									onChange={(date, dateString) => {
										formik.setFieldValue(
											'subscriptionStartedAt',
											dayjs(dateString).format('YYYY-MM-DD'),
										);
									}}
									placeholder="차량구독 시작날짜"
									allowClear={false}
									style={{ borderRadius: '4px' }}
								/>
								<Typo>~</Typo>
								<DateTimePicker
									name="subscriptionEndedAt"
									value={dayjs(formik.values.subscriptionEndedAt || undefined)}
									onChange={(date, dateString) => {
										formik.setFieldValue(
											'subscriptionEndedAt',
											dayjs(dateString).format('YYYY-MM-DD'),
										);
									}}
									placeholder="차량구독 종료날짜"
									allowClear={false}
									style={{ borderRadius: '4px' }}
								/>
							</div>
						</>
					)}
					<Label name="subscriptionPrice">월 구독료</Label>
					{formik.values.subscriptionPrice === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue(
									'subscriptionPrice',
									detailInfo?.subscriptionPrice ?? 0,
								)
							}
							height={12}
							width={12}
						/>
					) : (
						<Input
							name="subscriptionPrice"
							type="text"
							value={numberWithCommas(formik.values.subscriptionPrice ?? 0)}
							onChange={(e) =>
								formik.setFieldValue(
									'subscriptionPrice',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							unitText="원"
							onReset={() =>
								formik.setFieldValue('subscriptionPrice', undefined)
							}
						/>
					)}
					<Label name="vehiclePrice">차량가</Label>
					{formik.values.vehiclePrice === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue(
									'vehiclePrice',
									detailInfo?.vehiclePrice ?? 0,
								)
							}
							height={12}
							width={12}
						/>
					) : (
						<Input
							name="vehiclePrice"
							type="text"
							value={numberWithCommas(formik.values.vehiclePrice ?? 0)}
							onChange={(e) =>
								formik.setFieldValue(
									'vehiclePrice',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							unitText="원"
							onReset={() => formik.setFieldValue('vehiclePrice', undefined)}
						/>
					)}
					<Label name="prePaymentPrice">선입금</Label>
					{formik.values.prePaymentPrice === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue(
									'prePaymentPrice',
									detailInfo?.prePaymentPrice ?? 0,
								)
							}
							height={12}
							width={12}
						/>
					) : (
						<Input
							name="prePaymentPrice"
							type="text"
							value={numberWithCommas(formik.values.prePaymentPrice ?? 0)}
							onChange={(e) =>
								formik.setFieldValue(
									'prePaymentPrice',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							unitText="원"
							onReset={() => formik.setFieldValue('prePaymentPrice', undefined)}
						/>
					)}
					<Label name="acquisitionPrice">인수잔가</Label>
					{formik.values.acquisitionPrice === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue(
									'acquisitionPrice',
									detailInfo?.acquisitionPrice ?? 0,
								)
							}
							height={12}
							width={12}
						/>
					) : (
						<Input
							name="acquisitionPrice"
							type="text"
							value={numberWithCommas(formik.values.acquisitionPrice ?? 0)}
							onChange={(e) =>
								formik.setFieldValue(
									'acquisitionPrice',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							unitText="원"
							onReset={() =>
								formik.setFieldValue('acquisitionPrice', undefined)
							}
						/>
					)}
					<Label name="agCommissionPct">AG 수수료</Label>
					{formik.values.agCommissionPct === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue(
									'agCommissionPct',
									detailInfo?.agCommissionPct ?? 0,
								)
							}
							height={12}
							width={12}
						/>
					) : (
						<Input
							name="agCommissionPct"
							type="text"
							value={numberWithCommas(formik.values.agCommissionPct ?? 0)}
							onChange={(e) =>
								formik.setFieldValue(
									'agCommissionPct',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							unitText="%"
							onReset={() => formik.setFieldValue('agCommissionPct', undefined)}
						/>
					)}
					<Label name="status">상태</Label>
					{formik.values.status === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() =>
								formik.setFieldValue('status', detailInfo?.status ?? '구독중')
							}
							height={12}
							width={12}
						/>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: 6,
								alignItems: 'flex-start',
							}}
						>
							<Button
								size="x-small"
								$buttonType={ButtonTypeType.GHOST_DANGER}
								onClick={() => formik.setFieldValue('status', undefined)}
							>
								-
							</Button>
							<Select
								value={formik.values.status}
								onChange={(value) => formik.setFieldValue('status', value)}
								style={{ width: 120 }}
							>
								{vehicleSubscriptionStatus.map((status) => (
									<Select.Option key={status} value={status}>
										{status}
									</Select.Option>
								))}
							</Select>
						</div>
					)}
					<Label name="eContract">계약서</Label>
					{formik.values.eContract === undefined ? (
						<Icon
							src={plusIcon}
							onClick={() => formik.setFieldValue('eContract', '')}
							height={12}
							width={12}
						/>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: 6,
								alignItems: 'flex-start',
							}}
						>
							<Button
								size="x-small"
								$buttonType={ButtonTypeType.GHOST_DANGER}
								onClick={() => formik.setFieldValue('eContract', undefined)}
							>
								-
							</Button>
							<div style={{ width: 500 }}>
								<FileUpload
									setFieldValue={formik.setFieldValue}
									field="eContract"
									targetS3Key="vehicleSubscription/general/contracts"
									fileType="계약서"
								/>
							</div>
						</div>
					)}
					<Divider />
					<Label name="memo" essential>
						사유
					</Label>
					<TextArea
						name="memo"
						value={formik.values.memo}
						onChange={formik.handleChange}
					/>
					<Typo color="danger_5_main">
						{formik.errors.memo && formik.errors.memo}
					</Typo>
				</InputForm>
			</Modal>
		</>
	);
};

export default EditContractInfoButton;
