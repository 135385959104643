import React from 'react';
import DefaultLayout from '../../../components/organisms/DefaultLayout';
import UserIdSearchTemplate from '../../../components/templates/UserIdSearch';

function UserIdSearch() {
	return (
		<DefaultLayout>
			<UserIdSearchTemplate title="아이디 찾기" />
		</DefaultLayout>
	);
}

export default UserIdSearch;
