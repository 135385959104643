import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import userData from './gridData';
import { CAR_SERVICE_PARTNER_DETAIL_PATH } from '../../../routes/constants/urls';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import { createDetailAction } from '../../../utils/row-data-util';
import { useGetPartnerCarServicesQuery } from '../../../store/apis/carService';
import { RootState } from '../../../store';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import Typo from '../../../components/atoms/Typo';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../../components/atoms/Button';
import Icon from '../../../components/atoms/Icon';
import { resetIcon } from '../../../assets/icon';
import Input from '../../../components/atoms/Input';

const FormContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	.reset-button {
		width: 150px;
	}
	.reset-text {
		color: ${({ theme }) => theme.common.colors.primary_5};
	}
	.reset-icon {
		margin-left: 4px;
	}
`;

const CarServicePartnerManagement = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const mainRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isRead)
			.find((item) => {
				return (
					item.roleTemplate.roleName.replaceAll(' ', '') === '통화및이체관리'
				);
			});

	const { formInitialValues, colsList, forms, selectForms } = userData(
		user?.administratorName ?? '',
		!!mainRole,
		dayjs().format('YYYY-MM-DD'),
	);
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		setQuickSearchText,
		isFetching,
		handleQuickSearchText,
		refetch,
		quickSearchText,
	} = useGridDataWithRTK({
		useQuery: useGetPartnerCarServicesQuery,
		detailPath: CAR_SERVICE_PARTNER_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const resetQuickFilterText = useCallback(() => {
		handleQuickSearchText();
	}, [handleQuickSearchText]);

	useEffect(() => {
		if (quickSearchText === '' || !quickSearchText) {
			resetQuickFilterText();
		}
	}, [quickSearchText, resetQuickFilterText]);

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="지급 요청(협력사)"
			gridTitle="지급 요청(협력사)"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			selectForms={selectForms}
			onSearchSubmit={handleSearchSubmit}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'proxyInspectionReceptionId'),
			}}
			isFetching={isFetching}
			$isCompact
			rightAccessory={
				<FormContainer>
					<Button
						size="small"
						$buttonType={ButtonTypeType.NO_LINE}
						onClick={refetch}
						className="reset-button"
					>
						<Typo className="reset-text" $typoType="b9">
							새로고침
						</Typo>
						<Icon
							src={resetIcon}
							className="reset-icon"
							width={24}
							height={24}
						/>
					</Button>
					<Input
						onChange={(e) => {
							setQuickSearchText(e.target.value);
						}}
						name="quickSearch"
						type="text"
						placeholder="페이지내 검색"
						value={quickSearchText}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleQuickSearchText();
							}
						}}
					/>
				</FormContainer>
			}
		/>
	);
};

export default CarServicePartnerManagement;
