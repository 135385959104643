import { FormikValues, useFormik } from 'formik';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { resetIcon } from '../../../assets/icon';
import { FilterFormInfo, renderFormElement } from '../../../utils/filter-util';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';
import GridLayout, { BlankGridItem } from '../../molecules/GridLayout';
import { RootState } from '../../../store';
import { searchFilterHandler } from '../../../store/menuOpen';
import renderSelectFormElement, {
	FilterSelectFormInfo,
} from '../../../utils/filter-select-util';
import { ButtonTypeType } from '../../../styles/theme';

interface GridFilterFormProps {
	forms?: FilterFormInfo[];
	selectForms?: FilterSelectFormInfo[];
	formInitialValues?: FormikValues;
	onSearchSubmit?: (values: any) => void;
	useQuerySave?: boolean;
	$columnCount?: number;
	className?: string;
}

const FilterFormContainer = styled.form`
	.form-buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 5px;
		margin-top: 12px;
	}
	.reset-text {
		color: ${({ theme }) => theme.common.colors.primary_5};
	}
	.reset-icon {
		margin-left: 4px;
	}
`;

function GridFilterForm({
	forms = [],
	selectForms = [],
	formInitialValues = {},
	onSearchSubmit,
	useQuerySave = true,
	$columnCount,
	className = '',
}: PropsWithChildren<GridFilterFormProps>) {
	const [selectedOption, setSelectedOption] =
		useState<FilterSelectFormInfo | null>(null);
	const [isSelectValueSelected, setIsSelectValueSelected] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams(
		new URLSearchParams(window.location.search),
	);
	const [isInit, setIsInit] = useState(true);
	const dispatch = useDispatch();
	const { filterOpen: open } = useSelector(
		(state: RootState) => state.asideMenu,
	);

	const filter: Record<string, string | string[]> = JSON.parse(
		searchParams.get('filter') as string,
	);

	const getSearchFormikValues = () => {
		if (!filter || !Object.keys(filter).length) {
			return null;
		}

		const formikValues: Record<string | number, any> = { ...filter };

		Object.keys(formikValues).forEach((key) => {
			const includeFrom = key.split('From');
			const includeTo = key.split('To');

			if (includeFrom.length !== 1) {
				if (!formikValues[includeFrom[0]]) {
					formikValues[includeFrom[0]] = [];
				}

				formikValues[includeFrom[0]][0] = dayjs(formikValues[key]);
			}

			if (includeTo.length !== 1) {
				if (!formikValues[includeTo[0]]) {
					formikValues[includeTo[0]] = [];
				}

				formikValues[includeTo[0]][1] = dayjs(formikValues[key]);
			}
		});

		return formikValues;
	};

	const formik = useFormik({
		initialValues: getSearchFormikValues() || formInitialValues,
		onSubmit: (values) => {
			if (onSearchSubmit) {
				const requestValues = { ...values };

				// date 배열 처리
				Object.keys(values).forEach((key) => {
					if (
						typeof values[key] === 'object' &&
						values[key].length &&
						values[key].length > 0
					) {
						requestValues[`${key}From`] = dayjs(values[key][0])
							.startOf('date')
							.format('YYYY-MM-DD');
						requestValues[`${key}To`] = dayjs(values[key][1])
							.endOf('date')
							.format('YYYY-MM-DD');

						delete requestValues[key];
					}
				});

				searchParams.set('filter', JSON.stringify(requestValues));
				if (useQuerySave) setSearchParams(searchParams);
				onSearchSubmit(requestValues);
			}
		},
		onReset: (values) => {
			if (onSearchSubmit) onSearchSubmit(values);
		},
	});

	const handleReset = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		formik.setValues(formInitialValues);
		formik.handleSubmit();
		setSelectedOption(null);
		setIsSelectValueSelected(false);
	};

	const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		const phoneNumber = formik.values.customerPhoneNumber1;

		const isValidPhoneNumber = /^\d{3}-\d{4}-\d{4}$/.test(phoneNumber);
		const isLengthValid = phoneNumber?.length >= 4;

		if (
			selectedOption?.key === 'customerPhoneNumber1' &&
			(!isValidPhoneNumber || !isLengthValid)
		) {
			// eslint-disable-next-line no-alert
			alert(
				'올바른 전화번호 형식이 아닙니다. 최소 4자리의 숫자 또는 전화번호를 다시 확인해주세요. (예: 010-1234-5678)',
			);
		} else {
			formik.handleSubmit();
		}
	};

	useEffect(() => {
		if (filter && isInit && onSearchSubmit) {
			onSearchSubmit({ ...filter });
		}

		setIsInit(false);
	}, [filter, isInit, onSearchSubmit]);

	const renderBlank = () => {
		const blankResult = [];

		if (forms) {
			for (let i = 0; i < forms.length % 3; i += 1) {
				blankResult.push(i);
			}
		}

		return blankResult.map((index) => <BlankGridItem key={index} />);
	};

	return (
		<FilterFormContainer className={className} onSubmit={formik.handleSubmit}>
			{!open ? (
				<Button
					size="x-small"
					$buttonType={ButtonTypeType.GHOST}
					onClick={() => dispatch(searchFilterHandler())}
				>
					검색필터 펼치기
				</Button>
			) : (
				<>
					<GridLayout $columnCount={$columnCount ?? 4}>
						{forms &&
							forms.map(
								({
									key,
									label,
									type,
									placeholder,
									options,
									optionalActivedKey,
									optionalActivedValue,
								}) => {
									return renderFormElement({
										key,
										type,
										label,
										placeholder,
										isFilterForm: true,
										options,
										formik,
										optionalActivedValue,
										optionalActivedKey,
									});
								},
							)}

						{selectForms.length > 0 &&
							renderSelectFormElement({
								selectForms,
								formik,
								selectedOption,
								setSelectedOption,
								isSelectValueSelected,
								setIsSelectValueSelected,
							})}
						{renderBlank()}
					</GridLayout>
					<div className="form-buttons">
						<Button
							size="x-small"
							$buttonType={ButtonTypeType.BLACK}
							onClick={() => dispatch(searchFilterHandler())}
						>
							검색필터 숨기기
						</Button>
						<Button
							size="small"
							$buttonType={ButtonTypeType.NO_LINE}
							onClick={handleReset}
						>
							<Typo className="reset-text" $typoType="h11">
								초기화
							</Typo>
							<Icon
								src={resetIcon}
								className="reset-icon"
								width={24}
								height={24}
							/>
						</Button>
						<Button
							type="submit"
							size="x-small"
							$buttonType={ButtonTypeType.GHOST}
							onClick={handleSubmit}
						>
							조회하기
						</Button>
					</div>
				</>
			)}
		</FilterFormContainer>
	);
}

export default GridFilterForm;
