import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useGetCarServiceDetailQuery,
	useUpdateCarServiceDetailMutation,
} from '../../../../store/apis/carService';
import { CarServiceDto } from '../../../../interface/carService';
import CarServiceDetailTemplate from '../../../../components/templates/CarServiceTemplate/CarServiceDetailTemplate';

type TabName =
	| 'callHistory'
	| 'transfers'
	| 'additionalTransfers'
	| 'settlement'
	| 'inspectionPayment'
	| 'inspectionPaymentStatus'
	| 'transferHistory'
	| 'billing';

const CarServiceDetail: React.FC = () => {
	const { inspectionId } = useParams();
	const [activeTab, setActiveTab] = useState<TabName>('callHistory');

	const handleTabChange = (tabName: TabName) => {
		setActiveTab(tabName);
	};

	const tabConfig: any = {
		callHistory: {
			toUse: true,
			open: activeTab === 'callHistory',
			handleTabPane: () => handleTabChange('callHistory'),
			onCloseTab: () => setActiveTab(null as any),
		},
		inspectionPayment: {
			toUse: true,
			open: activeTab === 'inspectionPayment',
			handleTabPane: () => handleTabChange('inspectionPayment'),
			onCloseTab: () => setActiveTab(null as any),
		},
		inspectionPaymentStatus: {
			toUse: true,
			open: activeTab === 'inspectionPaymentStatus',
			handleTabPane: () => handleTabChange('inspectionPaymentStatus'),
			onCloseTab: () => setActiveTab(null as any),
		},
		transferHistory: {
			toUse: true,
			open: activeTab === 'transferHistory',
			handleTabPane: () => handleTabChange('transferHistory'),
			onCloseTab: () => setActiveTab(null as any),
		},
		settlement: {
			toUse: false,
			open: activeTab === 'settlement',
			handleTabPane: () => handleTabChange('settlement'),
			onCloseTab: () => setActiveTab(null as any),
		},
		billing: {
			toUse: false,
			open: activeTab === 'billing',
			handleTabPane: undefined,
			onCloseTab: undefined,
		},
	};

	const { isLoading, data, refetch } = useGetCarServiceDetailQuery({
		inspectionId,
	});

	const initialValues: Partial<CarServiceDto> | undefined = data?.row;

	const [updateCarServiceDetail, { isLoading: isUpdating }] =
		useUpdateCarServiceDetailMutation();

	return (
		<CarServiceDetailTemplate
			inspectionId={inspectionId}
			initialValues={initialValues}
			updateCarServiceDetail={updateCarServiceDetail}
			refetch={refetch}
			isLoading={isLoading}
			isUpdating={isUpdating}
			callHistoryTabPane={tabConfig.callHistory}
			settlementTabPane={tabConfig.settlement}
			inspectionPaymentTabPane={tabConfig.inspectionPayment}
			inspectionPaymentStatusTabPane={tabConfig.inspectionPaymentStatus}
			transferHistoryTabPane={tabConfig.transferHistory}
			billingTabPane={tabConfig.billing}
		/>
	);
};

export default CarServiceDetail;
