import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	ADMIN_ROLE_CREATE_PATH,
	ADMIN_ROLE_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetRolesQuery } from '../../../../store/apis/role';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function AdminRolePage() {
	const { colsList } = userData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetRolesQuery,
		formInitialValues: {},
		colsList,
		detailPath: ADMIN_ROLE_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(ADMIN_ROLE_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="관리자 권한 관리"
			gridTitle="권한 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleCreateClick} size="small">
						권한 등록
					</Button>
				</ButtonContainer>
			}
		/>
	);
}

export default AdminRolePage;
