export const customerInfoTableKey = {
	vehicleNumber: {
		key: 'vehicleNumber',
		label: '차량번호',
	},
	customerName: {
		key: 'customerName',
		label: '회원명',
	},
	customerCreatedAt: {
		key: 'customerCreatedAt',
		label: '가입일자',
	},
	provider: {
		key: 'provider',
		label: '가입채널',
	},
	customerPhone: {
		key: 'customerPhone',
		label: '휴대폰 번호',
	},
	customerStatus: {
		key: 'customerStatus',
		label: '회원상태',
	},
	vehicleRegistration: {
		key: 'vehicleRegistration',
		label: '차량등록증',
		span: 3,
	},
};

export const purchaseInfoTableKey = {
	purchasedAt: {
		key: 'purchasedAt',
		label: '구독일자',
	},
	subscriptionProductName: {
		key: 'subscriptionProductName',
		label: '구독 상품명',
	},
	startedAt: {
		key: 'startedAt',
		label: '구독 시작일자',
	},
	endedAt: {
		key: 'endedAt',
		label: '구독 종료일자',
	},
	currentCount: {
		key: 'currentCount',
		label: '서비스 회차',
	},
	monthlyPriceTax: {
		key: 'monthlyPriceTax',
		label: '월 단위 가격',
	},
	subscriptionStatus: {
		key: 'subscriptionStatus',
		label: '구독 상태',
		span: 2,
	},
	totalTasks: {
		key: 'totalTasks',
		label: '기본 제공 서비스',
		span: 2,
	},
	totalRemainTasks: {
		key: 'totalRemainTasks',
		label: '잔여 서비스',
		span: 2,
	},
	addOptions: {
		key: 'addOptions',
		label: '추가 옵션',
		span: 2,
	},
};
