import { Modal, Upload, UploadProps } from 'antd';
import type { RcFile } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { imageUploadIcon } from '../../../assets/icon';
import Icon from '../Icon';
import Typo from '../Typo';

export type ImageUploadSizeType = 'small' | 'large';
interface ImageUploadProps extends UploadProps {
	isMultiple?: boolean;
	size?: ImageUploadSizeType;
}

const getImageUploadSize = (size: ImageUploadSizeType) => {
	let width;
	let height;
	switch (size) {
		case 'large':
			width = '200px';
			height = '200px';
			break;
		case 'small':
			width = '100px';
			height = '100px';
			break;
		default:
			return null;
	}
	return css`
		width: ${width};
		height: ${height};
	`;
};

const UploadButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 8px;
`;

const ImageUploadContainer = styled.div<{ size: ImageUploadSizeType }>`
	.ant-upload-list.ant-upload-list-picture-card {
		display: flex;
		flex-wrap: wrap;

		& > .ant-upload-list-picture-card-container {
			order: 2;
			&:last-child {
				order: 1;
			}
		}
	}
	.ant-upload.ant-upload-select-picture-card,
	.ant-upload-list-picture-card-container {
		${({ size }) => size && getImageUploadSize(size)}
	}
`;

function ImageUpload({
	isMultiple = false,
	size = 'small',
	fileList,
	onChange,
	onRemove,
}: ImageUploadProps) {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			// eslint-disable-next-line no-param-reassign
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		);
	};

	const uploadButton = (
		<UploadButtonContainer>
			<Icon
				src={imageUploadIcon}
				width={24}
				height={24}
				className="file-upload-illust"
			/>
			<Typo $typoType="b7">이미지 업로드</Typo>
		</UploadButtonContainer>
	);

	return (
		<ImageUploadContainer size={size}>
			<Upload
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={onChange}
				onRemove={onRemove}
				beforeUpload={() => false}
			>
				{!isMultiple && fileList && fileList.length === 1 ? null : uploadButton}
			</Upload>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</ImageUploadContainer>
	);
}

export default ImageUpload;
