import dayjs, { Dayjs } from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { numberWithCommas } from '../../../../utils/data-format';

const VehicleSubscriptionDetailGridData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '수정일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: { value: string | Dayjs }) => {
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'memo',
				headerName: '메모',
			},
			options: {
				minWidth: ColSize.CW_LONG_TEXT,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionStart',
				headerName: '구독 시작일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: { value: string | null }) => {
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'administratorName',
				headerName: '관리자 이름',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionEnd',
				headerName: '구독 종료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: { value: string | null }) => {
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'subscriptionPrice',
				headerName: '월 구독료',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
					return value ? numberWithCommas(value) : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'vehiclePrice',
				headerName: '차량가',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
					return value ? numberWithCommas(value) : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'prePaymentPrice',
				headerName: '선입금',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
					return value ? numberWithCommas(value) : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'acquisitionPrice',
				headerName: '인수잔가',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
					return value ? numberWithCommas(value) : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'agCommissionPct',
				headerName: 'AG 수수료',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
					if (value === undefined) {
						return '-';
					}
					return `${value}%`;
				},
			},
		},
		{
			tableInfo: {
				field: 'status',
				headerName: '상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
	],
};

export default VehicleSubscriptionDetailGridData;
