import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { loginSearchSuccessImage } from '../../../assets/images';
import Typo from '../../atoms/Typo';

interface LoginSearchResultProps {
	notice?: boolean;
}

const LoginSearchResultLayoutContainer = styled.div`
	text-align: center;

	.login-search-image {
		margin-bottom: 57px;
		height: 126px;
		object-fit: contain;
	}

	.login-search-notice {
		display: inline-block;
		color: ${({ theme }) => theme.common.colors.gray_8};
		line-height: 20px;
		margin-top: 12px;
	}
`;

function LoginSearchResultLayout({
	children,
	notice = false,
}: PropsWithChildren<LoginSearchResultProps>) {
	return (
		<LoginSearchResultLayoutContainer>
			<img
				className="login-search-image"
				src={loginSearchSuccessImage}
				alt="success-img"
			/>
			{children}
			{notice && (
				<p>
					<Typo $typoType="b6" className="login-search-notice">
						개인정보 도용에 대한 방지를 위하여
						<br /> 아이디 끝 두자리에서 **처리합니다.
					</Typo>
				</p>
			)}
		</LoginSearchResultLayoutContainer>
	);
}

export default LoginSearchResultLayout;
