import { TableValueType, TableContentType } from '../../../../utils/table-util';

export const inquiryTablekeys: Record<any, TableValueType> = {
	service: {
		key: 'service',
		label: '문의출처',
		type: TableContentType.TEXT,
	},
	createdAt: {
		key: 'createdAt',
		label: '문의일자',
		type: TableContentType.TEXT,
	},
	category: {
		key: 'category',
		label: '문의유형',
		type: TableContentType.TEXT,
	},
	customerName: {
		key: 'customerName',
		label: '고객명',
		type: TableContentType.TEXT,
	},
	status: {
		key: 'status',
		label: '상태',
		type: TableContentType.BULLET,
		bulletOptions: [
			{ key: 'replied', value: '답변완료' },
			{ key: 'pending', value: '답변대기', color: 'danger_5_main' },
		],
	},
	customerPhone: {
		key: 'customerPhone',
		label: '휴대폰 번호',
		type: TableContentType.TEXT,
	},
	inquiryMedias: {
		key: 'inquiryMedias',
		label: '사진',
		type: TableContentType.IMAGES,
		span: 3,
	},
	title: {
		key: 'title',
		label: '제목',
		type: TableContentType.TEXT,
		span: 3,
	},
	body: {
		key: 'body',
		label: '내용',
		type: TableContentType.TEXT,
		span: 3,
	},
};

export const answerTablekeys: Record<any, TableValueType> = {
	updatedAt: {
		key: 'updatedAt',
		label: '답변일자',
		type: TableContentType.TEXT,
	},
	replier: {
		key: 'replier',
		label: '관리자 명',
		type: TableContentType.TEXT,
	},
	reply: {
		key: 'reply',
		label: '답변내용',
		type: TableContentType.TEXT,
		span: 2,
	},
};
