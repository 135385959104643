import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tag, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { SelectInfo } from 'antd/lib/calendar/generateCalendar';
import Select from '../../../components/atoms/Select';
import { useGetVehicleCurrentLocationsQuery } from '../../../store/apis/vehicleConditions';
import { CustomErrorType } from '../../../store/apis/@types';
import VehiclesMap from '../../../components/organisms/NaverMap/VehiclesMap';
import { DTGInfo } from '../../../interface/vehicleConditions';
import SectionLoading from '../../../components/organisms/SectionLoading';
import { ButtonTypeType } from '../../../styles/theme';
import Icon from '../../../components/atoms/Icon';
import { resetIcon } from '../../../assets/icon';
import Button from '../../../components/atoms/Button';
import { persistor } from '../../../index';
import { resetAuth } from '../../../store/auth';
import { RootState } from '../../../store';
import { useGetDepartmentsQuery } from '../../../store/apis/department';
import useCheckRole from '../../../hooks/useCheckRole';
import Typo from '../../../components/atoms/Typo';
import TabPaneBox from '../../../components/organisms/TabPane/TabPaneBox';
import {
	formatDateString,
	formatTimeToString,
	transportDatetime,
} from '../../../utils/date-util';
import VehicleCalendar from '../../../components/organisms/VehicleCalendar';
import TabPaneBoxItemValue, {
	TabPaneBoxItemRow,
	TabPaneBoxItemTitle,
} from '../../../components/organisms/TabPane/TabPaneBoxItemValue';
import { useGetCoordinateDetailRegionQuery } from '../../../store/apis/etc';
import Divider from '../../../styles/divider';
import { useGetCompaniesQuery } from '../../../store/apis/company';
import SelectionModal from '../../../components/organisms/SelectionModal';
import { companyModalColumn } from '../../../components/templates/modalColumn';

const Article = styled.article`
	display: flex;
	height: 100%;
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 8px 0;
`;

const RowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	margin-top: 10px;
	padding: 4px 12px 0 12px;
`;

const VehicleListContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	margin: 0 0 16px 0;
	padding: 0 10px;
`;

const MapContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`;

const MapOverlayContainer = styled.div<{ $showContentOverlay: boolean }>`
	height: 100%;
	display: ${({ $showContentOverlay }) =>
		$showContentOverlay ? 'grid' : 'flex'};
	grid-template-columns: ${($showContentOverlay) =>
		$showContentOverlay ? '2fr 1fr' : 'auto'};
	width: ${({ $showContentOverlay }) =>
		$showContentOverlay ? '100%' : 'auto'};
`;

const ContentOverlay = styled.div<{ $showContentOverlay: boolean }>`
	display: ${({ $showContentOverlay }) =>
		$showContentOverlay ? 'block' : 'none'};
	overflow-y: auto;
`;

const OverlayContainer = styled.div<{ $dtgInfo: boolean }>`
	padding: 0 1rem 1rem 1rem;
	gap: ${({ $dtgInfo }) => $dtgInfo && '24px'};
	height: ${({ $dtgInfo }) => $dtgInfo && 'auto'};
	width: ${({ $dtgInfo }) => $dtgInfo && '100%'};
`;

const ErrorMessage = styled.p`
	padding: 24px;
`;

interface DepartmentSelectionProps {
	companyId: number;
	departmentId: number | undefined;
	setDepartmentId: React.Dispatch<React.SetStateAction<number | undefined>>;
	setVehicleList: React.Dispatch<
		React.SetStateAction<{
			list: DTGInfo[];
			title?: string;
		}>
	>;
	onDrawerReset: () => void;
}

const DetailRegion = ({
	x,
	y,
	skip,
}: {
	x: string;
	y: string;
	skip: boolean;
}) => {
	const { data: regionData, isError } = useGetCoordinateDetailRegionQuery(
		{
			lat: y,
			lng: x,
		},
		{ skip },
	);
	return isError ? (
		<TabPaneBoxItemRow>
			<TabPaneBoxItemTitle>주소</TabPaneBoxItemTitle>
		</TabPaneBoxItemRow>
	) : (
		<TabPaneBoxItemRow>
			<TabPaneBoxItemTitle>주소</TabPaneBoxItemTitle>
			<TabPaneBoxItemValue
				type="text"
				content={regionData?.street || regionData?.zibun}
			/>
		</TabPaneBoxItemRow>
	);
};

const transformDTGDate = (vehicle: DTGInfo) => {
	return `20${vehicle.t.slice(0, 6)}T${vehicle.t.slice(6)}`;
};

const DepartmentSelection = ({
	companyId,
	departmentId,
	setDepartmentId,
	setVehicleList,
	onDrawerReset,
}: DepartmentSelectionProps) => {
	const [prevDepartmentId, setPrevDepartmentId] = useState<number | undefined>(
		undefined,
	);

	const {
		isLoading,
		isFetching,
		data: customerAppdata,
	} = useGetDepartmentsQuery(
		{
			companyId,
			service: 'CUSTOMER_APP',
		},
		{ skip: !companyId },
	);
	const customerAppDepartments = customerAppdata ? customerAppdata.rows : [];

	useEffect(() => {
		if (prevDepartmentId !== departmentId) {
			onDrawerReset();
		}

		setPrevDepartmentId(departmentId);
	}, [departmentId, onDrawerReset, prevDepartmentId]);

	return !companyId ? (
		<Select
			placeholder="법인을 먼저 선택해주세요."
			size="small"
			style={{ minWidth: '160px', fontSize: '14px', marginRight: '8px' }}
		/>
	) : (
		<Select
			value={departmentId}
			onChange={(value: number) => {
				setDepartmentId(value);
				setVehicleList({
					list: [],
				});
			}}
			placeholder="소속"
			size="small"
			style={{ minWidth: '160px', fontSize: '14px', marginRight: '8px' }}
			loading={isLoading || isFetching}
		>
			{customerAppDepartments.map((item) => (
				<Select.Option value={item.id} key={item.id}>
					{item.departmentName}
				</Select.Option>
			))}
		</Select>
	);
};

const VehiclesCurrentLocations = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.auth);

	const [mapKey] = useState('mapDTG');
	const [vehicleList, setVehicleList] = useState<{
		list: DTGInfo[];
		title?: string;
	}>({ list: [] });
	const [dtgInfo, setDtgInfo] = useState<DTGInfo | null>(null);

	const [isCompanySelectionModal, setIsCompanySelectionModal] = useState(false);
	const [companyId, setCompanyId] = useState<number>(user?.company?.id || 0);
	const [departmentId, setDepartmentId] = useState<number | undefined>(
		user?.department.id || undefined,
	);
	const isSuperCurrentLocations = useCheckRole({
		roleType: 'isSuper',
		roleName: '실시간차량위치',
	});

	const [showContentOverlay, setShowContentOverlay] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const paramCompanyName = searchParams.get('companyName');
	const paramCompanyId = searchParams.get('companyId');

	const initialDate =
		searchParams.get('date') || dayjs(dtgInfo?.t).format('YYYY-MM-DD');
	const [selectedDate, setSelectedDate] = useState<Dayjs>(
		dayjs(dayjs(dtgInfo?.t).format('YYYY-MM-DD')),
	);
	const [selectedYear, setSelectedYear] = useState<number>(
		dayjs(selectedDate).year(),
	);
	const [selectedMonth, setSelectedMonth] = useState<number>(
		dayjs(initialDate).month(),
	);

	const onDrawerReset = () => {
		setShowContentOverlay(false);
		setDtgInfo(null);
		setVehicleList({ list: [] });
	};

	const handleDateSelect = (value: Dayjs, info: SelectInfo) => {
		const dateSelected = value.format('YYYYMM');
		setSearchParams({
			vehicleNumber: dtgInfo?.vehicleNumber || '',
			date: dateSelected,
		});

		if (info.source === 'date') {
			setSelectedYear(value.get('year'));
			setSelectedMonth(value.get('month'));
		}
	};

	const { isLoading, isFetching, currentData, isError, error, refetch } =
		useGetVehicleCurrentLocationsQuery(
			departmentId
				? {
						departmentId,
				  }
				: { companyId },
			{ skip: !user || !companyId },
		);
	const locations = currentData
		? currentData.rows.filter((item) => item.t && item.x && item.y)
		: [];
	const totalLocations = currentData?.rows ?? [];
	const DTGNotInstalled = currentData
		? currentData.rows.filter((item) => !item.boolAnyMobility)
		: [];

	const runningVehicles = locations.filter(
		(vehicle) => dayjs().diff(dayjs(transformDTGDate(vehicle)), 'minutes') <= 3,
	);
	const restingVehicles = locations.filter(
		(vehicle) => dayjs().diff(dayjs(transformDTGDate(vehicle)), 'minutes') > 3,
	);
	const fiveDaysErrorVehicles = locations.filter(
		(vehicle) => dayjs().diff(dayjs(transformDTGDate(vehicle)), 'days') >= 5,
	);
	const threeDayErrorVehicles = locations.filter(
		(vehicle) =>
			dayjs().diff(dayjs(transformDTGDate(vehicle)), 'days') >= 3 &&
			dayjs().diff(dayjs(transformDTGDate(vehicle)), 'days') < 5,
	);

	const renderVehicleList = useCallback(() => {
		return (
			<>
				<Typo>{vehicleList.title ?? '차량목록'}</Typo>
				{vehicleList.list.map((vehicle) => (
					<Button
						key={vehicle.vehicleNumber}
						size="small"
						$buttonType={ButtonTypeType.GHOST_BLACK}
						onClick={() => {
							setDtgInfo(vehicle);
							setShowContentOverlay(!showContentOverlay);
						}}
					>
						{vehicle.vehicleNumber}
					</Button>
				))}
			</>
		);
	}, [showContentOverlay, vehicleList.list, vehicleList.title]);

	return (
		<Article>
			<MapContainer>
				{isSuperCurrentLocations && (
					<RowWrapper>
						<Button
							$buttonType={ButtonTypeType.GHOST}
							onClick={() => setIsCompanySelectionModal(true)}
							size="small"
						>
							법인 선택
						</Button>
						<SelectionModal
							onClose={() => setIsCompanySelectionModal(false)}
							onChange={(e) => {
								setCompanyId(e[0].id);
								setDepartmentId(undefined);
								setSearchParams((prev) => ({
									companyName: e[0].name,
									companyId: e[0].id,
									...prev,
								}));
								onDrawerReset();
							}}
							column={companyModalColumn.colsList}
							useQuery={useGetCompaniesQuery}
							isVisible={isCompanySelectionModal}
							title="법인 선택"
							defaultSelectedIdList={[companyId]}
							width={700}
						/>
						{companyId && Number(paramCompanyId) === companyId ? (
							<Typo>{paramCompanyName}</Typo>
						) : (
							''
						)}
					</RowWrapper>
				)}
				<TopContainer>
					<Button
						size="x-small"
						$buttonType={ButtonTypeType.NO_LINE}
						onClick={refetch}
					>
						<Icon
							src={resetIcon}
							className="reset-icon"
							width={24}
							height={24}
						/>
					</Button>
					<DepartmentSelection
						companyId={companyId}
						departmentId={departmentId}
						setDepartmentId={setDepartmentId}
						setVehicleList={setVehicleList}
						onDrawerReset={onDrawerReset}
					/>
					<Tag color="blue">전체 차량 {totalLocations.length}대</Tag>
					<Tag
						color="green"
						onClick={() =>
							setVehicleList({
								list: runningVehicles,
								title: '운행',
							})
						}
					>
						<Tooltip title="데이터 수집 후 3분 이내" placement="bottom">
							운행중{' '}
						</Tooltip>
						<Tooltip
							title={runningVehicles.map((info) => (
								<p key={info.vehicleNumber}>{info.vehicleNumber}</p>
							))}
							placement="bottom"
						>
							{runningVehicles.length}대
						</Tooltip>
					</Tag>
					<Tag
						color="yellow"
						onClick={() =>
							setVehicleList({
								list: restingVehicles,
								title: '미운행',
							})
						}
					>
						<Tooltip title="데이터 수집 후 3분 초과" placement="bottom">
							미운행중{' '}
						</Tooltip>
						<Tooltip
							title={restingVehicles.map((info) => (
								<p key={info.vehicleNumber}>{info.vehicleNumber}</p>
							))}
							placement="bottom"
						>
							{restingVehicles.length}대
						</Tooltip>
					</Tag>
					<Tag>
						<Tooltip title="수신정보 없음" placement="bottom">
							미설치{' '}
						</Tooltip>
						<Tooltip
							title={DTGNotInstalled.map((info) => (
								<p key={info.vehicleNumber}>{info.vehicleNumber}</p>
							))}
							placement="bottom"
						>
							{DTGNotInstalled.length}대
						</Tooltip>
					</Tag>
					<Tag
						color="orange"
						onClick={() =>
							setVehicleList({
								list: threeDayErrorVehicles,
								title: '3일이상 미운행',
							})
						}
					>
						<Tooltip title="3일이상 미운행" placement="bottom">
							3일이상 미운행{' '}
						</Tooltip>
						<Tooltip
							title={threeDayErrorVehicles.map((info) => (
								<p key={info.vehicleNumber}>{info.vehicleNumber}</p>
							))}
							placement="bottom"
						>
							{threeDayErrorVehicles.length}대
						</Tooltip>
					</Tag>
					<Tag
						color="red"
						onClick={() =>
							setVehicleList({
								list: fiveDaysErrorVehicles,
								title: '5일이상 미운행',
							})
						}
					>
						<Tooltip title="5일이상 미운행" placement="bottom">
							5일이상 미운행{' '}
						</Tooltip>
						<Tooltip
							title={fiveDaysErrorVehicles.map((info) => (
								<p key={info.vehicleNumber}>{info.vehicleNumber}</p>
							))}
							placement="bottom"
						>
							{fiveDaysErrorVehicles.length}대
						</Tooltip>
					</Tag>
				</TopContainer>
				{!!vehicleList.list.length && (
					<VehicleListContainer>{renderVehicleList()}</VehicleListContainer>
				)}
				{isError && (
					<ErrorMessage>
						{(error as CustomErrorType).data.translate}
						{(error as CustomErrorType).status !== 404 && (
							<Button
								onClick={async () => {
									await persistor.purge();
									dispatch(resetAuth);
									navigate('/login');
								}}
								style={{ marginTop: '24px' }}
							>
								다시 로그인하기
							</Button>
						)}
					</ErrorMessage>
				)}
				{isLoading || isFetching ? (
					<SectionLoading />
				) : (
					<MapOverlayContainer
						$showContentOverlay={showContentOverlay}
						className="map-overlay-container"
					>
						<VehiclesMap
							key={`${mapKey}-${departmentId}`}
							markerList={locations}
							onMarkerClick={(markerItem: DTGInfo | null) => {
								setDtgInfo(markerItem);
								setShowContentOverlay(!showContentOverlay);
							}}
						/>
						<ContentOverlay
							$showContentOverlay={showContentOverlay}
							className="content-overlay"
						>
							<OverlayContainer $dtgInfo={!!dtgInfo}>
								<TabPaneBox
									title={
										!dtgInfo?.vehicleNumber
											? `운행시간 ${dtgInfo && formatTimeToString(dtgInfo.t)}`
											: dtgInfo.vehicleNumber
									}
								>
									<TabPaneBoxItemRow>
										<TabPaneBoxItemTitle>마지막 운행시각</TabPaneBoxItemTitle>
										<TabPaneBoxItemValue
											type="text"
											content={
												dtgInfo && (
													<>
														<Tag color="red">
															{transportDatetime(
																dayjs(
																	`20${dtgInfo.t.slice(0, 6)}T${dtgInfo.t.slice(
																		6,
																	)}`,
																).toISOString(),
															)}
														</Tag>
														<Typo>
															{formatDateString(
																`20${dtgInfo.t.slice(0, 6)}T${dtgInfo.t.slice(
																	6,
																)}`,
																true,
															)}
														</Typo>
													</>
												)
											}
										/>
									</TabPaneBoxItemRow>
									<Divider $verticalGap={10} />
									{dtgInfo && (
										<DetailRegion
											x={String(dtgInfo.x / 1000000)}
											y={String(dtgInfo.y / 1000000)}
											skip={!dtgInfo}
										/>
									)}
									<TabPaneBoxItemRow>
										<TabPaneBoxItemTitle>위도</TabPaneBoxItemTitle>
										<TabPaneBoxItemValue
											type="text"
											content={dtgInfo && dtgInfo.y / 1000000}
										/>
									</TabPaneBoxItemRow>
									<TabPaneBoxItemRow>
										<TabPaneBoxItemTitle>경도</TabPaneBoxItemTitle>
										<TabPaneBoxItemValue
											type="text"
											content={dtgInfo && dtgInfo.x / 1000000}
										/>
									</TabPaneBoxItemRow>
									<Divider $verticalGap={10} />
									{dtgInfo && (
										<div style={{ width: '100%', height: '400px' }}>
											<VehiclesMap
												mapId="dtgMap"
												markerList={[dtgInfo]}
												hasZoomController={false}
												imageIcon
												isStatic
											/>
										</div>
									)}
									<Divider $verticalGap={10} />
									<VehicleCalendar
										vehicleNumber={dtgInfo?.vehicleNumber || ''}
										selectedDate={selectedDate}
										setSelectedDate={setSelectedDate}
										selectedYear={
											selectedYear ? dayjs(`${selectedYear}`).year() : 0
										}
										setSelectedYear={setSelectedYear}
										selectedMonth={dtgInfo?.t ? selectedMonth : 0}
										setSelectedMonth={setSelectedMonth}
										isYearSelected
										handleDateSelect={handleDateSelect}
										$isCompact
									/>
									<Divider $verticalGap={10} />
								</TabPaneBox>
							</OverlayContainer>
						</ContentOverlay>
					</MapOverlayContainer>
				)}
			</MapContainer>
		</Article>
	);
};

export default VehiclesCurrentLocations;
