import {
	workDoneOnPin,
	workDoneOffPin,
	workPendingOnPin,
	workPendingOffPin,
	workRequestOnPin,
	workRequestOffPin,
	managerOnPin,
	managerOffPin,
} from '../../../../assets/icon';
import { SubscriptionItemStatusType } from '../../../../interface/subscriptionItem';

export const getManagerPinSrc = ({ isMarkerOn }: { isMarkerOn: boolean }) => {
	if (isMarkerOn) {
		return managerOnPin;
	}
	return managerOffPin;
};

const getPinSrc = ({
	status,
	isMarkerOn,
}: {
	status: SubscriptionItemStatusType | null;
	isMarkerOn: boolean;
}) => {
	switch (status) {
		case 'REQUESTED':
			if (isMarkerOn) {
				return workRequestOnPin;
			}
			return workRequestOffPin;
		case 'ASSIGNED':
			if (isMarkerOn) {
				return workPendingOnPin;
			}
			return workPendingOffPin;
		case 'COMPLETED':
			if (isMarkerOn) {
				return workDoneOnPin;
			}
			return workDoneOffPin;
		default:
			if (isMarkerOn) {
				return managerOnPin;
			}
			return managerOffPin;
	}
};

export default getPinSrc;
