import dayjs, { Dayjs } from 'dayjs';

export const transformTimeToHour = (time: number, multiple = 3600) => {
	const hour = Math.floor(time / multiple);
	const minute = Math.round((time / multiple - hour) * 60);
	return hour ? `${hour}시간 ${minute}분` : `${minute}분`;
};

export const formatDateString = (date: Dayjs | string, withTime = false) => {
	if (date) {
		if (!withTime) return dayjs(date).format('YYYY-MM-DD');
		return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
	}
	return '';
};

export const transportDatetime = (datetime: string) => {
	// datetime === moment.toISOString()
	// 출처: https://kdinner.tistory.com/68
	const today = new Date();
	const timeValue = new Date(datetime);

	const betweenTime = Math.floor(
		(today.getTime() - timeValue.getTime()) / 1000 / 60,
	);
	if (betweenTime < 1) return '방금전';
	if (betweenTime < 60) {
		return `${betweenTime}분전`;
	}

	const betweenTimeHour = Math.floor(betweenTime / 60);
	if (betweenTimeHour < 24) {
		return `${betweenTimeHour}시간전`;
	}

	const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
	if (betweenTimeDay < 365) {
		return `${betweenTimeDay}일전`;
	}

	return `${Math.floor(betweenTimeDay / 365)}년전`;
	// return datetime.split("T")[0] + " " + datetime.split("T")[1].split(".")[0];
};

export const getTodayDateRange = () => {
	const startDate = dayjs().startOf('day');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const getWeekDateRange = () => {
	const startDate = dayjs().subtract(1, 'week');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get1MonthDateRange = () => {
	const startDate = dayjs().subtract(1, 'M');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get3MonthDateRange = () => {
	const startDate = dayjs().subtract(3, 'M');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get1YearDateRange = () => {
	const startDate = dayjs().subtract(1, 'year');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const getYesterdayDateRange = () => {
	const startDate = dayjs().subtract(1, 'day');
	const endDate = dayjs().subtract(1, 'day');
	return [startDate, endDate];
};

export const formatTimeToString = (time: string) => {
	return `${time.substring(0, 2)}시 
	${time.substring(2, 4)}분 ${time.substring(4, 6)}초`;
};
