import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ResponsivePie } from '@nivo/pie';
import { PieChartTotalItem } from '../../../../interface/carService';

interface PieChartTotalProps {
	pieChartTotalData: PieChartTotalItem[];
}

const PieChartTotal = ({ pieChartTotalData }: PieChartTotalProps) => {
	// console.log(pieChartTotalData);
	const styles: Record<string, React.CSSProperties> = {
		root: {
			position: 'relative',
			width: '100%',
			height: '400px',
		},
		overlay: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, 0%)',
			fontSize: 36,
			color: 'black',
			textAlign: 'center',
			pointerEvents: 'none',
		},
		totalLabel: {
			fontSize: 24,
		},
	};

	// 중간에 들어가는 텍스트에 다른 요소 필요시 조합해서 사용 (현재는 검사완료만 사용중)
	const completedPercentValue = pieChartTotalData[3]?.id
		.split('(')[1]
		.split(')')[0]
		.trim();

	return (
		<div style={styles.root}>
			<ResponsivePie
				data={pieChartTotalData}
				colors={{ scheme: 'nivo' }}
				margin={{ top: 40, right: 100, bottom: 10, left: 80 }}
				valueFormat=" >-,~g"
				innerRadius={0.5}
				startAngle={20}
				padAngle={5}
				cornerRadius={5}
				activeOuterRadiusOffset={8}
				borderWidth={0}
				borderColor={{
					from: 'color',
					modifiers: [
						['darker', 0.2],
						['opacity', 0.2],
					],
				}}
				arcLinkLabelsSkipAngle={1}
				arcLinkLabelsTextColor="#333333"
				arcLinkLabelsThickness={2}
				arcLinkLabelsColor={{ from: 'color' }}
				arcLabelsSkipAngle={1}
				arcLabelsTextColor={{
					from: 'color',
					modifiers: [
						['darker', 2],
						['opacity', 1],
					],
				}}
				defs={[
					{
						id: 'dots',
						type: 'patternDots',
						background: 'inherit',
						color: 'rgba(255, 255, 255, 0.3)',
						size: 4,
						padding: 1,
						stagger: true,
					},
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: 'rgba(255, 255, 255, 0.3)',
						rotation: -45,
						lineWidth: 6,
						spacing: 10,
					},
				]}
				fill={[
					{
						match: { id: 'ruby' },
						id: 'dots',
					},
				]}
				legends={[]}
				theme={{
					text: {
						fontFamily:
							'Pretendard, "Noto Sans KR", "Malgun Gothic", sans-serif',
						fontSize: '14',
					},
					axis: { ticks: { text: { fontSize: '12' } } },
					legends: { text: { fontSize: '11' } },
					labels: { text: { fontSize: '11' } },
				}}
			/>
			<div style={styles.overlay}>
				<span>{completedPercentValue}</span>
			</div>
		</div>
	);
};

export default PieChartTotal;
