import React, { Fragment, PropsWithChildren } from 'react';
import { Modal as AntdModal, ModalProps } from 'antd';
import styled from 'styled-components';

interface CustomModalProps extends ModalProps {
	noContentPadding?: boolean;
}

const StyledAntdModal = styled(AntdModal)`
	.ant-modal-header {
		padding: 34px 32px 20px;
		border-radius: 16px 16px 0 0;
	}

	.ant-modal-close-x {
		color: ${({ theme }) => theme.common.colors.gray_11};
	}

	.ant-modal-body {
		padding: 0;
	}

	.ant-modal-footer {
		padding: 20px 32px;
	}

	.ant-modal-content {
		border-radius: 16px;
	}
`;

const ModalContentSection = styled.section`
	padding: 20px 32px;
`;

const ModalHeaderWrapper = styled.div``;

const ModalFooter = styled.footer``;

function Modal(props: PropsWithChildren<CustomModalProps>) {
	const { children, noContentPadding = false, title, footer, ...rest } = props;

	return (
		<StyledAntdModal
			title={<ModalHeaderWrapper>{title}</ModalHeaderWrapper>}
			footer={<ModalFooter>{footer as React.ReactNode}</ModalFooter>}
			{...rest}
		>
			{noContentPadding ? (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>{children}</>
			) : (
				<ModalContentSection>{children}</ModalContentSection>
			)}
		</StyledAntdModal>
	);
}

export default Modal;
