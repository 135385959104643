import styled, { css } from 'styled-components';
import theme from './theme';

const Divider = styled.div<{
	$verticalGap?: number;
	color?: keyof typeof theme.common.colors;
}>`
	width: 100%;

	${({ $verticalGap }) =>
		$verticalGap &&
		css`
			margin: ${$verticalGap}px 0;
		`}

	${({ color }) =>
		color
			? css`
					border-top: 1px solid ${theme.common.colors[color]};
			  `
			: css`
					border-top: 1px solid ${theme.common.colors.gray_4};
			  `}
`;

export default Divider;
