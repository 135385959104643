import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import Modal from '../../../components/atoms/Modal';
import Button from '../../../components/atoms/Button';
import Editor from '../../../components/atoms/Editor';
import Typo from '../../../components/atoms/Typo';
import { ButtonTypeType } from '../../../styles/theme';
import {
	getTermsHistoryDetailViaId,
	useDeleteTermsHistoryMutation,
	useUpdateTermsHistoryMutation,
} from '../../../store/apis/termsHistory';
import {
	ModifyTermsHistoryDto,
	TermsHistoryDto,
} from '../../../interface/termsHistory';
import Alert from '../../../components/atoms/Alert';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import SectionLoading from '../../../components/organisms/SectionLoading';
import Icon from '../../../components/atoms/Icon';
import { grayCalendarIcon } from '../../../assets/icon';
import DateTimePicker from '../../../components/atoms/DateTimePicker';

interface EditModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	id: number;
}

const StyledModal = styled(Modal)`
	.ant-modal-footer {
		padding: 20px 32px;
	}
	.ant-modal-body {
		padding: 0;
	}
`;

const ModalContentContainer = styled.div`
	padding: 32px;

	.antd-date-picker {
		border-radius: 8px;

		input {
			${({ theme }) => theme.typo.label1};
		}
	}
`;

const ModalFooter = styled.footer`
	display: flex;
`;

const RightButtonWrapper = styled.div`
	display: flex;
	gap: 8px;
	margin-left: auto;
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

enum AlertState {
	NULL,
	EDIT,
	DELETE,
}

function EditModal({ isVisible, id, onClose, onRefetch }: EditModalProps) {
	const [data, setData] = useState<TermsHistoryDto | null>(null);
	const [alert, setAlert] = useState<AlertState>(AlertState.NULL);
	const [sectionLoading, setSectionLoading] = useState<boolean>(false);

	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);

	const [updateTermsHistory] = useUpdateTermsHistoryMutation();
	const [deleteTermsHistory] = useDeleteTermsHistoryMutation();

	const closeAlert = () => {
		setAlert(AlertState.NULL);
	};

	const handleClickEdit = () => {
		setAlert(AlertState.EDIT);
	};

	const handleClickDelete = () => {
		setAlert(AlertState.DELETE);
	};

	const handleUpdateTermsHistory = async () => {
		if (!data) return;

		const body: ModifyTermsHistoryDto = {
			body: data.body,
			releasedAt: dayjs(data.releasedAt).format('YYYY-MM-DD'),
		};

		const result = await updateTermsHistory({ id, body });

		if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '약관 이력 수정이 완료되었습니다.');
		}
	};

	const handleDeleteTermsHistory = async () => {
		if (!data) return;

		const result = await deleteTermsHistory({ id });

		if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '약관 이력 삭제가 완료되었습니다.');
		}
	};

	const handleEditorChange = (editorValue: string) => {
		setData((prev) => {
			if (!prev) return null;

			return {
				...prev,
				body: editorValue,
			};
		});
	};

	const handleDateChange = (date: Dayjs) => {
		setData((prev) => {
			if (!prev) return null;

			return {
				...prev,
				releasedAt: date.format('YYYY-MM-DD'),
			};
		});
	};

	const handleConfirm = async () => {
		dispatch(fullLoadingOn());
		await handleUpdateTermsHistory();

		try {
			await onRefetch();
			onClose();
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const msg =
					(error as AxiosError<{ translate: string }>).response?.data!
						.translate ||
					(error as AxiosError<{ message: string }>).response?.data.message;

				setToast('error', msg as string);
			}
		} finally {
			closeAlert();
			dispatch(fullLoadingOff());
		}
	};

	const handleDelete = async () => {
		dispatch(fullLoadingOn());
		await handleDeleteTermsHistory();

		try {
			await onRefetch();
			onClose();
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const msg =
					(error as AxiosError<{ translate: string }>).response?.data!
						.translate ||
					(error as AxiosError<{ message: string }>).response?.data.message;

				setToast('error', msg as string);
			}
		} finally {
			closeAlert();
			dispatch(fullLoadingOff());
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setSectionLoading(true);
				const result = await getTermsHistoryDetailViaId(id);

				setData(result);
			} catch (error) {
				const msg =
					(error as AxiosError<{ translate: string }>).response?.data!
						.translate ||
					(error as AxiosError<{ message: string }>).response?.data.message;

				setToast('error', msg as string);
			} finally {
				setSectionLoading(false);
			}
		})();
	}, [id, setToast]);

	const renderContent = () => {
		if (sectionLoading) return <SectionLoading padding={60} />;

		if (!data) return null;

		return (
			<>
				<Form layout="vertical">
					<FormItem
						name="serviceDate"
						label={
							<Typo $typoType="label2" color="gray_11">
								게시 날짜
							</Typo>
						}
						rules={[{ required: true }]}
						colon={false}
					>
						<DateTimePicker
							allowClear={false}
							style={{ width: '100%', height: 32 }}
							disabled={!data.editable}
							defaultValue={dayjs(data.releasedAt)}
							showTime={{ defaultValue: dayjs(data.releasedAt) }}
							format="YYYY-MM-DD"
							className="antd-date-picker"
							onOk={(value) => handleDateChange(value)}
							suffixIcon={
								<Icon src={grayCalendarIcon} width={20} height={20} />
							}
						/>
					</FormItem>
				</Form>

				<Editor
					value={data.body}
					onChange={(value) => handleEditorChange(value)}
					disabled={!data.editable}
				/>
			</>
		);
	};

	const renderAlert = () => {
		if (alert === AlertState.EDIT) {
			return (
				<Alert
					title="약관 이력 수정"
					closeButtonClick={closeAlert}
					onConfirmButtonText="수정하기"
					onConfirmButtonClick={handleConfirm}
					isVisible
				>
					<Typo color="gray_8">약관 이력을 수정하시겠습니까?</Typo>
				</Alert>
			);
		}

		if (alert === AlertState.DELETE) {
			return (
				<Alert
					title="약관 이력 삭제"
					closeButtonClick={closeAlert}
					onConfirmButtonText="삭제하기"
					onConfirmButtonClick={handleDelete}
					isVisible
				>
					<Typo color="gray_8">약관 이력을 삭제하시겠습니까?</Typo>
				</Alert>
			);
		}

		return null;
	};

	return (
		<StyledModal
			title={<Typo $typoType="h2">약관 이력 상세</Typo>}
			open={isVisible}
			onCancel={onClose}
			width={980}
			destroyOnClose
			noContentPadding
			footer={
				<ModalFooter>
					{data?.editable && (
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={handleClickDelete}
						>
							삭제하기
						</Button>
					)}
					<RightButtonWrapper>
						<Button $buttonType={ButtonTypeType.OPAQUE} onClick={onClose}>
							닫기
						</Button>
						{data?.editable && (
							<Button onClick={handleClickEdit}>저장하기</Button>
						)}
					</RightButtonWrapper>
				</ModalFooter>
			}
		>
			<ModalContentContainer>{renderContent()}</ModalContentContainer>

			{renderAlert()}
		</StyledModal>
	);
}

export default EditModal;
