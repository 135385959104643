import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import styled, { css } from 'styled-components';
import {
	GridApi,
	// ICellRendererParams,
	RowSelectedEvent,
	SortChangedEvent,
	ValueParserParams,
} from 'ag-grid-community';
import { TableColumn } from '../../../utils/grid-util';
// import {
// 	createDateCellRender,
// 	createDetailAction,
// 	createPriceTextCellRender,
// 	createScheduleManagementDetailAction,
// } from '../../../utils/row-data-util';
import { LoadingTemplate, NoRowsTemplate } from '../SelectionGrid';
import {
	createDetailAction,
	createDateCellRender,
	createPriceTextCellRender,
	createScheduleManagementDetailAction,
} from '../../../utils/row-data-util';

interface GridProps extends AgGridReactProps {
	gridRef?: (api: GridApi) => void;
	className?: string;
	cols: TableColumn[];
	rowData: any[] | null;
	width?: number | string;
	height?: number | string;
	apiRef?: (api: GridApi) => void;
	onSortChange?: (api: SortChangedEvent<any>) => void;
	selectedOptions?: {
		selectedRowIds: number[];
		handleRowSelected: (e: RowSelectedEvent<any>) => void;
	};
	frameworkComponents?: Record<string, React.ComponentType<any>>;
	onRowClicked?: (id: RowSelectedEvent<any>) => void;
	onRowScheduleClicked?: (id: RowSelectedEvent<any>) => void;
	pageSize?: number;
	$isCompact?: boolean;
	editable?: boolean;
	sortable?: boolean;
	columnName?: string;
}

interface GridLayoutStyleProps {
	width: string;
	height: string;
}
const GridContainer = styled.div<GridLayoutStyleProps>`
	${({ width, height }) =>
		css`
			width: ${width};
			height: ${height};
		`}
`;

function Grid({
	gridRef,
	className = '',
	width,
	height,
	rowData,
	cols,
	apiRef,
	onSortChange,
	selectedOptions,
	frameworkComponents = {},
	onRowClicked,
	onRowScheduleClicked,
	$isCompact = false,
	editable = true,
	sortable = true,
	columnName,
	pageSize = 50,
	...props
}: GridProps) {
	const [gridApi, setGridApi] = useState<GridApi>();

	let gridWidth = typeof width === 'number' ? `${width}px` : width;
	let gridHeight = typeof height === 'number' ? `${height}px` : height;
	if (!gridWidth) {
		gridWidth = '100%';
	}
	if (!gridHeight) {
		gridHeight = '100%';
	}

	useEffect(() => {
		if (selectedOptions && gridApi && rowData && rowData.length > 0) {
			gridApi.forEachNode((node) => {
				if (selectedOptions.selectedRowIds.includes(node.data.id))
					node.setSelected(true);
			});
		}
	}, [gridApi, rowData, selectedOptions]);

	const components = useMemo(
		() => ({
			...createDetailAction(onRowClicked),
			...createScheduleManagementDetailAction(onRowScheduleClicked),
			...createDateCellRender(),
			...createPriceTextCellRender(),
			...frameworkComponents,
		}),
		[frameworkComponents, onRowClicked, onRowScheduleClicked],
	);

	return (
		<GridContainer
			className={`ag-theme-alpine ${className}`}
			width={gridWidth}
			height={gridHeight}
		>
			<AgGridReact
				columnDefs={cols}
				onGridReady={(ref) => {
					setGridApi(ref.api);
					if (columnName) {
						const columnState = localStorage.getItem(columnName);
						if (columnState) {
							gridApi?.applyColumnState({
								state: JSON.parse(columnState),
							});
						}
					}

					if (gridRef) gridRef(ref.api);
					if (apiRef) apiRef(ref.api);
				}}
				onSortChanged={onSortChange}
				onRowSelected={selectedOptions && selectedOptions.handleRowSelected}
				onColumnMoved={(params) => {
					const columnState = JSON.stringify(params.api.getColumnState());
					if (columnName) {
						localStorage.setItem(columnName, columnState);
					}
				}}
				rowData={rowData}
				rowHeight={32}
				defaultColDef={{
					resizable: true,
					// https://ag-grid.com/react-data-grid/column-properties/ - 타입참고
					valueParser: (params: string | ValueParserParams<any, any>) => {
						if (params === null || params === undefined) {
							return '';
						}
						return params;
					},
					sortable,
					editable,
					cellRenderer: ({ value }: any) => {
						if (!value) {
							return '-';
						}
						return value;
					},
					cellStyle: {
						fontSize: !$isCompact ? '13px' : '12px',
						display: 'flex',
						alignItems: 'center',
					},
					width: 100,
				}}
				paginationPageSize={pageSize}
				pagination
				readOnlyEdit
				cacheQuickFilter
				stopEditingWhenCellsLoseFocus
				suppressPaginationPanel
				// enableCellTextSelection
				// ensureDomOrder
				rowSelection={selectedOptions ? 'multiple' : 'single'}
				suppressRowClickSelection
				components={components}
				reactiveCustomComponents
				// frameworkComponents={{
				// 	...createDetailAction(onRowClicked),
				// 	...createScheduleManagementDetailAction(onRowScheduleClicked),
				// 	...createDateCellRender(),
				// 	...createPriceTextCellRender(),
				// 	...frameworkComponents,
				// }}
				noRowsOverlayComponent={NoRowsTemplate}
				loadingOverlayComponent={LoadingTemplate}
				suppressCellFocus
				suppressRowDeselection
				// onRowClicked={onRowScheduleClicked}
				{...props}
			>
				{/* {selectedOptions && (
					<AgGridColumn
						field=""
						headerCheckboxSelection
						checkboxSelection
						showDisabledCheckboxes
						cellRenderer={({ value }: ICellRendererParams) => value}
						width={60}
						resizable={false}
						lockPosition
					/>
				)} */}

				{/* {cols.map(
					({
						headerName,
						field,
						valueGetter,
						minWidth,
						editable: columnEditable,
						...rest
					}) => (
						<AgGridColumn
							valueGetter={valueGetter}
							key={headerName}
							field={field}
							editable={columnEditable}
							headerName={headerName}
							minWidth={minWidth}
							{...rest}
						/>
					),
				)} */}
			</AgGridReact>
		</GridContainer>
	);
}

export default Grid;
