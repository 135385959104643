import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Divider } from 'antd';
import Button from '../../atoms/Button';
import {
	VehicleSubscriptionRequestEndType,
	VehicleSubscriptionRequestEnd,
} from '../../../interface/vehicleSubscription';
import { ButtonTypeType } from '../../../styles/theme';
import { useRequestVehicleSubscriptionEndMutation } from '../../../store/apis/vehicleSubscription';
import Modal from '../../atoms/Modal';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import InputForm from '../../molecules/InputForm';
import Input from '../../atoms/Input';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';

interface EndActionButtonProps {
	endType: VehicleSubscriptionRequestEndType;
	contactName: string;
	contactPhoneNumber: string;
	vehicleNumber: string;
}

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h3};
`;

const EndActionButton = ({
	endType,
	contactName,
	contactPhoneNumber,
	vehicleNumber,
}: EndActionButtonProps) => {
	const toast = useContext(ToastContext);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [requestEndAction] = useRequestVehicleSubscriptionEndMutation();

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const initialValues: VehicleSubscriptionRequestEnd = {
		endType,
		contactName,
		contactPhoneNumber,
		returnAddrStreet: undefined,
		returnAddrDetail: undefined,
		returnAddrZibun: undefined,
		vehicleNumberList: [vehicleNumber],
	};

	const handleSubmit = async (values: typeof initialValues) => {
		const result = await requestEndAction({
			body: values,
		});

		if (!('error' in result)) {
			toast('info', '작업을 완료하였습니다.');
			handleModalClose();
		} else if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}
	};
	const formik = useFormik({ initialValues, onSubmit: handleSubmit });

	return (
		<>
			<Button
				size="small"
				$buttonType={ButtonTypeType.GHOST}
				onClick={handleModalOpen}
			>
				{endType}
			</Button>
			<Modal
				title={<ModalTitle>{endType}</ModalTitle>}
				open={isModalOpen}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button onClick={() => formik.handleSubmit()}>신청하기</Button>
					</ModalFooterWrapper>
				}
			>
				<InputForm>
					<Typo $typoType="b4">{endType}을 진행합니다.</Typo>
				</InputForm>
				{endType === '반납신청' && (
					<InputForm>
						<Divider />
						<Label name="returnAddrStreet" essential>
							반납지 주소
						</Label>
						<Input
							name="returnAddrStreet"
							type="text"
							value={formik.values.returnAddrStreet ?? ''}
							onChange={formik.handleChange}
						/>
					</InputForm>
				)}
			</Modal>
		</>
	);
};

export default EndActionButton;
