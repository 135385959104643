export const userInfoTableKey = {
	createdAt: {
		key: 'createdAt',
		label: '가입일자',
	},
	customerName: {
		key: 'customerName',
		label: '고객명',
	},
	provider: {
		key: 'provider',
		label: '가입채널',
	},
	customerApplicationId: {
		key: 'customerApplicationId',
		label: '아이디',
	},
	customerPhone: {
		key: 'customerPhone',
		label: '휴대폰 번호',
	},
	emailReceivable: {
		key: 'emailReceivable',
		label: '마케팅활용동의',
	},
	department: {
		key: 'department',
		label: '소속',
	},
	customerStatus: {
		key: 'customerStatus',
		label: '회원상태',
	},
	isLock: {
		key: 'isLock',
		label: '잠금상태',
	},
};

export const vehicleInfoTableKey = {
	manufacturingCompany: {
		key: 'manufacturingCompany',
		label: '제조사',
	},
	vehicleName: {
		key: 'vehicleName',
		label: '차량명',
	},
	vehicleNumber: {
		key: 'vehicleNumber',
		label: '차량번호',
	},
	vin: {
		key: 'vin',
		label: '차대번호',
	},
	loadingCapacity: {
		key: 'loadingCapacity',
		label: '적재량',
	},
	carLoadingBoxType: {
		key: 'carLoadingBoxType',
		label: '적재함',
	},
	vehicleRegistration: {
		key: 'vehicleRegistration',
		label: '차량 등록증',
	},
};

export const subscribeProductInfoTableKey = {
	subscriptionProductName: {
		key: 'subscriptionProductName',
		label: '구독 상품명',
	},
	subscriptionStartedAt: {
		key: 'subscriptionStartedAt',
		label: '결제 기준일',
	},
};
