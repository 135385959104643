import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, SelectionGridColumn } from '../../../utils/grid-util';
import { phoneFormat } from '../../../utils/data-format';
import {
	CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION,
	renderProductTypeCell,
	renderSubscriptionItemStatusCell,
	renderManagerStatusCell,
} from '../../../utils/row-data-util';

export const circuitInspectionGridData: SelectionGridColumn = {
	colsList: [
		{
			headerName: '생성일시',
			field: 'requestedAt',
			flex: 1,
			minWidth: ColSize.CW_LARGE,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value ? dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss') : '-',
		},
		{
			headerName: '서비스 희망일시',
			field: 'firstVisitedAt',
			flex: 1,
			minWidth: ColSize.CW_LARGE,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value ? dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss') : '-',
		},
		{
			headerName: '차량번호',
			field: 'vehicle.vehicleNumber',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value || '차량번호없음';
			},
		},
		{
			headerName: '고객명',
			field: 'customerName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value || '이름없음';
			},
		},
		{
			headerName: '고객 전화번호',
			field: 'customerPhone',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && phoneFormat(value);
			},
		},
		{
			headerName: '서비스 유형',
			field: 'productType',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			sortable: true,
			cellRenderer: renderProductTypeCell,
		},
		{
			headerName: '작업상태',
			field: 'subscriptionItemStatus',
			flex: 1,
			maxWidth: 110,
			minWidth: 110,
			sortable: true,
			cellRenderer: renderSubscriptionItemStatusCell,
		},
		{
			headerName: '매니저 작업상태',
			field: 'subscriptionItemManagerStatus',
			minWidth: ColSize.CW_MEDIUM,
			flex: 1,
			sortable: true,
			valueGetter: (params) => {
				if (params?.data?.subscriptionItemStatus === 'REQUESTED') {
					return '-';
				}
				return params.data.subscriptionItemManagerStatus;
			},
			valueSetter: (params) => {
				// eslint-disable-next-line no-param-reassign
				params.data.subscriptionItemStatus = params.newValue;
				return true;
			},
			cellRenderer: renderManagerStatusCell,
		},
		{
			headerName: '매니저 배정일시',
			field: 'managerAssignedAt',
			flex: 1,
			minWidth: ColSize.CW_LARGE,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value && dayjs(value).format('YYYY.MM.DD(dd) A hh:mm'),
		},
		{
			headerName: '추가금액',
			field: 'totalAdditionalPaidPriceTax',
			minWidth: ColSize.CW_MEDIUM,
			flex: 1,
			sortable: true,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && `${value.toLocaleString()}원`;
			},
		},
		{
			headerName: '서비스 확정일시',
			field: 'completeConfirmedAt',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_LARGE,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value && dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss'),
		},

		{
			headerName: '매니저 소속',
			field: 'managerDepartment',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && value;
			},
		},
		{
			headerName: '매니저명',
			field: 'managerName',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && value;
			},
		},
		{
			headerName: '매니저 전화번호',
			field: 'managerPhone',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && phoneFormat(value);
			},
		},
		{
			headerName: '차량 위치',
			field: 'street',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && value;
			},
		},
		{
			headerName: '작업내용',
			field: 'tasks',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_LONG_TEXT,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value.length
					? `${value[0].productName} ${
							value.length - 1 ? `외 ${value.length - 1}건` : ''
					  }`
					: '-';
			},
		},
		{
			headerName: '관리자명',
			field: 'administratorName',
			flex: 1,
			sortable: true,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && value;
			},
		},
		{
			headerName: '관리',
			field: 'detail',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			maxWidth: ColSize.CW_SMALL,
			sortable: true,
			cellRenderer: CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION,
			pinned: 'right',
		},
	],
};

export default circuitInspectionGridData;
