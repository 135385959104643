import { SelectProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { arrowDownIcon } from '../../../assets/icon';
import { FormOption } from '../../../utils/filter-util';

interface FilterSelectProps extends SelectProps {
	id?: string;
	name?: string;
	onChange?: React.ChangeEventHandler<HTMLSelectElement>;
	options?: FormOption[];
	className?: string;
}

const FilterSelectContainer = styled.select`
	width: 100%;
	border: none;
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: url(${arrowDownIcon}) no-repeat 100% 50%;
	cursor: pointer;
	font-size: 13px;

	&::-ms-expand {
		display: none;
	}

	&:disabled {
		color: ${({ theme }) => theme.common.colors.gray_4};
	}
`;

function FilterSelect({
	id = '',
	name = '',
	onChange,
	options = [],
	value,
	className,
	style,
	disabled,
}: FilterSelectProps) {
	return (
		<FilterSelectContainer
			id={id}
			name={name}
			onChange={onChange}
			value={value}
			className={className}
			style={style}
			disabled={disabled}
		>
			{options &&
				options.map((option) => (
					<option key={option.value} value={option.value || ''}>
						{option.label}
					</option>
				))}
		</FilterSelectContainer>
	);
}

export default FilterSelect;
