import React, { useContext, useState } from 'react';
import { Col, Drawer, List, Row } from 'antd';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
	CarCareTaskDto,
	RequestCarCareService,
} from '../../../interface/subscription';
import {
	useGetAddibleCarCareServicesQuery,
	useRequestCarCareServiceMutation,
} from '../../../store/apis/carCareSubscription';
import ContentBox from '../../atoms/ContentBox';
import Divider from '../../../styles/divider';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import {
	useLazyGetAddressQuery,
	useLazyGetCoordinateQuery,
} from '../../../store/apis/address';
import TextArea from '../../atoms/TextArea';
import { ButtonTypeType } from '../../../styles/theme';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import DateTimePicker from '../../atoms/DateTimePicker';
import Label from '../../atoms/Label';
import { numberWithCommas } from '../../../utils/data-format';
import Badge from '../../atoms/Badge';
import Typo from '../../atoms/Typo';

interface RequestCarCarServiceProps {
	open: boolean;
	onClose: () => void;
	taskInfo: CarCareTaskDto | undefined;
	subscriptionNo: string;
}

const RequestCarCarService = ({
	open,
	onClose,
	taskInfo,
	subscriptionNo,
}: RequestCarCarServiceProps) => {
	const toast = useContext(ToastContext);
	const [address, setAddress] = useState('');
	const [showAddressList, setShowAddressList] = useState(false);

	const [getAddress, { isError: isAddressError, currentData: addressData }] =
		useLazyGetAddressQuery();
	const [getCoordinate] = useLazyGetCoordinateQuery();
	const { data } = useGetAddibleCarCareServicesQuery({
		subscriptionNo,
		productType: taskInfo?.productType ?? 'REGULAR_INSPECTION',
	});
	const addibleList = data?.rows ?? [];
	const addressList = addressData?.rows ?? [];

	const [requestService] = useRequestCarCareServiceMutation();

	const initialValues: RequestCarCareService = {
		additionalPaidPriceTax: 0,
		country: 'KR',
		detail: '',
		firstVisitedAt: undefined,
		lastInspectionDate: '',
		lat: 0,
		lng: 0,
		productType: taskInfo?.productType ?? 'REGULAR_INSPECTION',
		regionDepth1: '',
		regionDepth2: '',
		regionDepth3: '',
		requestedMedias: [],
		street: '',
		subscriptionNo: subscriptionNo as string,
		subscriptionRequestedMemo: '',
		taskList: [
			...addibleList.map((item) => ({
				taskType: !item.taskCount ? 'PRODUCT' : 'SUBSCRIPTION',
				productId: item.productId,
				taskCount: 0,
			})),
			{
				taskType: 'SUBSCRIPTION',
				productId: taskInfo?.productId ?? 0,
				taskCount: 1,
			},
		],
		zibun: '',
		zipCode: '',
	};

	const handleSubmit = async (values: typeof initialValues) => {
		const { taskList, ...rest } = values;
		const finalTaskList = taskList.filter((task) => !!task.taskCount);

		const result = await requestService({
			body: { taskList: finalTaskList, ...rest },
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '서비스 신청이 완료되었습니다.');
		}
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<Drawer
			title={`${taskInfo?.productName} 신청`}
			open={open}
			width={980}
			closable={false}
			onClose={onClose}
		>
			<Row gutter={[12, 12]}>
				{addibleList &&
					addibleList.map((item, idx) => (
						<Col key={item.productId}>
							<ContentBox borderRadius="10px">
								{item.taskCount ? (
									<>
										<Badge color="blue">기본서비스</Badge>{' '}
										<Typo $typoType="label3">최대 {item.taskCount}개</Typo>
									</>
								) : (
									<>
										<Badge color="green">추가서비스</Badge>{' '}
										<Typo $typoType="label3">
											최대 {item.limitationCount}개
										</Typo>
									</>
								)}
								<Divider $verticalGap={8} />
								{item.productName}
								{numberWithCommas(item.productPrice + item.productTax)}원
								<Divider $verticalGap={8} />
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: 8,
									}}
								>
									<Button
										size="x-small"
										$buttonType={ButtonTypeType.GHOST_BLACK}
										onClick={() => {
											formik.setFieldValue(
												`taskList.${idx}.taskCount`,
												formik.values.taskList[idx].taskCount - 1,
											);
											formik.setFieldValue(
												'additionalPaidPriceTax',
												(formik.values.additionalPaidPriceTax ?? 0) -
													(item.productPrice + item.productTax),
											);
										}}
										disabled={formik.values.taskList[idx]?.taskCount <= 0}
									>
										-
									</Button>
									{formik.values.taskList[idx]?.taskCount}
									<Button
										size="x-small"
										$buttonType={ButtonTypeType.GHOST_BLACK}
										onClick={() => {
											formik.setFieldValue(
												`taskList.${idx}.taskCount`,
												formik.values.taskList[idx].taskCount + 1,
											);
											formik.setFieldValue(
												'additionalPaidPriceTax',
												(formik.values.additionalPaidPriceTax ?? 0) +
													item.productPrice +
													item.productTax,
											);
										}}
										disabled={
											item.taskCount
												? formik.values.taskList[idx]?.taskCount >=
												  item.taskCount
												: formik.values.taskList[idx]?.taskCount >=
												  item.limitationCount
										}
									>
										+
									</Button>
								</div>
								{/* 기본제공: {item.taskCount} */}
								{/* 추가횟수: {item.limitationCount} */}
							</ContentBox>
						</Col>
					))}
			</Row>
			<Divider $verticalGap={16} />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
					alignItems: 'flex-start',
				}}
			>
				<Label name="address">추가금액</Label>
				<p>{numberWithCommas(formik.values.additionalPaidPriceTax ?? 0)}원</p>
			</div>
			<Divider $verticalGap={16} />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
					alignItems: 'flex-start',
				}}
			>
				<Label name="address">희망주소</Label>
				{formik.values.lat && (
					<>
						<p>{formik.values.zipCode}</p>
						<p>{formik.values.street}</p>
						<p>(지번: {formik.values.zibun})</p>
					</>
				)}
				<Input
					onChange={(e) => setAddress(e.target.value)}
					type="text"
					name="address"
					value={address}
					placeholder="주소를 입력해주세요"
				/>
				<Button
					size="small"
					disabled={!address}
					onClick={() => {
						getAddress({ keyword: address });
						if (!isAddressError) {
							setShowAddressList(true);
						}
					}}
					// style={{ width: 100 }}
				>
					주소 검색
				</Button>
				{addressList && showAddressList && (
					<List
						size="small"
						dataSource={addressList}
						renderItem={(item) => (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: 10,
									alignItems: 'center',
									padding: '3px 0',
								}}
							>
								<Button
									size="x-small"
									$buttonType={ButtonTypeType.GHOST}
									onClick={() => {
										getCoordinate({ address: item.street }).then((res: any) => {
											if (res.isSuccess) {
												formik.setFieldValue('lat', Number(res.data.row.lat));
												formik.setFieldValue('lng', Number(res.data.row.lng));
												formik.setFieldValue('street', res.data.row.street);
												formik.setFieldValue('zipCode', res.data.row.zipCode);
												formik.setFieldValue('detail', res.data.row.detail);
												formik.setFieldValue('zibun', res.data.row.zibun);
												setShowAddressList(false);
											}
											if (res.isError) {
												toast(
													'error',
													'주소 데이터를 가져오는 중에 에러가 발생했습니다.',
												);
											}
										});
									}}
								>
									선택
								</Button>
								{item.street}
							</div>
						)}
					/>
				)}
			</div>
			<Divider $verticalGap={16} />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
					alignItems: 'flex-start',
				}}
			>
				<Label name="firstVisitedAt">희망방문일자</Label>
				<DateTimePicker
					name="firstVisitedAt"
					value={dayjs(formik.values.firstVisitedAt)}
					onChange={(date, dateString) => {
						formik.setFieldValue(
							'firstVisitedAt',
							dayjs(dateString).format('YYYY-MM-DD'),
						);
					}}
					placeholder="희망서비스일자"
					allowClear={false}
					style={{ borderRadius: '4px' }}
				/>
			</div>
			<Divider $verticalGap={16} />
			<TextArea
				name="subscriptionRequestedMemo"
				placeholder="요청사항"
				value={formik.values.subscriptionRequestedMemo}
				onChange={formik.handleChange}
			/>
			<Divider $verticalGap={16} />
			<Button onClick={() => formik.handleSubmit()}>서비스 신청하기</Button>
		</Drawer>
	);
};

export default RequestCarCarService;
