import React from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import VehicleSubscriptionQuotesTemplate from '../../../../components/templates/VehicleSubscriptionQuotesTemplate';
import { CustomerType } from '../../../../interface/vehicleSubscriptionConsulting';
import { MakeVehicleSubscriptionQuoteDto } from '../../../../interface/vehicleSubscriptionsQuotes';

export const commonProperties: {
	customerType: CustomerType;
	customerName: string;
	customerPhone: string;
	customerEmail: string;
} = {
	customerType: '개인',
	customerName: '',
	customerPhone: '',
	customerEmail: '',
};

const VehicleQuotesCreate = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const qpCustomerType = queryParams.get('customerType');
	const qpCustomerName = queryParams.get('customerName');
	const qpCustomerPhone = queryParams.get('customerPhone');
	const qpCustomerEmail = queryParams.get('customerEmail');
	const qpSubscriptionStartedAt = queryParams.get('subscriptionStartedAt');
	const qpSubscriptionEndedAt = queryParams.get('subscriptionEndedAt');

	const subscriptionStartedAt = qpSubscriptionStartedAt
		? dayjs(qpSubscriptionStartedAt)
		: dayjs();
	const subscriptionEndedAt = qpSubscriptionEndedAt
		? dayjs(qpSubscriptionEndedAt)
		: dayjs();

	const subscriptionPeriod = subscriptionEndedAt.diff(
		subscriptionStartedAt,
		'day',
	);

	const initialValues: {
		rows: MakeVehicleSubscriptionQuoteDto[];
	} = {
		rows: [
			{
				...commonProperties,
				customerType: (qpCustomerType as CustomerType) || '개인',
				customerName: qpCustomerName || '',
				customerPhone: qpCustomerPhone || '',
				customerEmail: qpCustomerEmail || '',
				subscriptionStartedAt:
					qpSubscriptionStartedAt || dayjs().format('YYYY-MM-DD'),
				subscriptionEndedAt:
					qpSubscriptionEndedAt || dayjs().format('YYYY-MM-DD'),
				contactName: '',
				subscriptionPeriod: subscriptionPeriod || 0,
				endActionType: '반납',
				subscriptionPrice: 0,
				vehiclePrice: 0,
				prePaymentPrice: 0,
				acquisitionPrice: 0,
				agCommissionPct: 0,
				memo: '',
				requestVehiclePlate: false,
				paymentMethod: '현금',
				vehicleId: 0,
				subscriptionProductId: 0,
				consultationId: 1,
			},
		],
	};

	return <VehicleSubscriptionQuotesTemplate initialValues={initialValues} />;
};

export default VehicleQuotesCreate;
