import React from 'react';
import { DatePicker } from 'antd';
// import { Moment } from 'moment';
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import styled from 'styled-components';
import {
	getTodayDateRange,
	getWeekDateRange,
	get1MonthDateRange,
	get3MonthDateRange,
	get1YearDateRange,
} from '../../../utils/date-util';
import Icon from '../Icon';
import { grayCalendarIcon } from '../../../assets/icon';

interface DateRangePickerProps extends RangePickerDateProps<any> {}

interface Preset {
	label: string;
	value: any;
}

const { RangePicker } = DatePicker;

const DateRangePickerContainer = styled(RangePicker)`
	width: 100%;
	/* height: 21px; */
	border: none;
	outline: none;
	padding: 0;
	.ant-picker-input > input {
		${({ theme }) => theme.typo.input2};
		font-size: 13px;
	}
	.ant-picker-input > input::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}

	.ant-picker {
		transition: 0.3s;
		&:hover {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
			box-shadow: 0 0 0 2px ${({ theme }) => theme.common.colors.primary_1};
		}
		&:focus-within {
			border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main} !important;
			box-shadow: none !important;
		}
	}
`;

const presets: Preset[] = [
	{ label: '오늘', value: getTodayDateRange() },
	{ label: '1주일', value: getWeekDateRange() },
	{ label: '1개월', value: get1MonthDateRange() },
	{ label: '3개월', value: get3MonthDateRange() },
	{ label: '1년', value: get1YearDateRange() },
];

function DateRangePicker(props: DateRangePickerProps) {
	const { style, ...rest } = props;

	return (
		<DateRangePickerContainer
			placeholder={['시작일', '종료일']}
			presets={presets}
			style={style}
			suffixIcon={<Icon src={grayCalendarIcon} width={16} height={16} />}
			{...rest}
		/>
	);
}

export default DateRangePicker;
