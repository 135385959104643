/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import {
	asideMenuList,
	AsideMenuItem,
} from '../../components/organisms/Aside/data';
import { AuthUser, RoleItem } from '../../interface/auth';
import { LOGIN_PATH } from '../../routes/constants/urls';

interface AuthState {
	user?: AuthUser;
	userTemp?: AuthUser;
	token?: string;
	defaultPath?: string;
}

const initialState: AuthState = {};

export const getDefaultPathUrl = (
	sideMenuList: AsideMenuItem[],
	roleItem: RoleItem[],
) => {
	const roleTemplateList = roleItem.find((item) => item.isRead)?.roleTemplate;

	return (
		asideMenuList
			.find(
				(item) =>
					item.title.replaceAll(' ', '') ===
					(roleTemplateList?.roleCategory || '').replaceAll(' ', ''),
			)
			?.subMenu.find(
				(item) =>
					item.title.replaceAll(' ', '') ===
					(roleTemplateList?.roleName || '').replaceAll(' ', ''),
			)?.key || LOGIN_PATH
	);
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<AuthUser | undefined>) {
			state.user = action.payload;

			if (action.payload?.role?.roleItems) {
				state.defaultPath = getDefaultPathUrl(
					asideMenuList,
					action.payload.role.roleItems,
				);
			}

			state.userTemp = undefined;
		},
		setUserTemp(state, action: PayloadAction<AuthUser | undefined>) {
			state.userTemp = action.payload;
			state.user = undefined;
		},
		setToken(state, action: PayloadAction<string | undefined>) {
			state.token = action.payload;
		},
		resetAuth(state) {
			state.user = undefined;
			state.token = undefined;
			state.userTemp = undefined;
			state.defaultPath = undefined;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(PURGE, () => initialState);
	},
});

export const { setUser, setUserTemp, setToken, resetAuth } = authSlice.actions;
export default authSlice.reducer;
