import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useGetVehiclePlateDetailQuery } from '../../../../store/apis/vehiclePlate';
import VehiclePlateTemplate from '../../../../components/templates/VehiclePlateTemplate';
import { UpdateVehiclePlateDto } from '../../../../interface/vehiclePlate';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';

const VehiclePlateDetail = () => {
	const { plateNumber } = useParams();
	const { isLoading, data } = useGetVehiclePlateDetailQuery({ plateNumber });
	const vehiclePlateDetail = data?.row;

	const initialValues: UpdateVehiclePlateDto = {
		vehicleNumber: vehiclePlateDetail?.vehicleNumber,
		vehiclePlateType: vehiclePlateDetail?.vehiclePlateType,
		companyId: vehiclePlateDetail?.companyId,
	};

	return isLoading ? (
		<GridDetailTemplate detailTitle="번호판 관리">
			<ContentBoxWithHeader title="번호판 정보" borderRadius="10px">
				<Skeleton
					active
					paragraph={{ rows: 6 }}
					style={{ borderRadius: '10px' }}
				/>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	) : (
		<VehiclePlateTemplate
			initialValues={initialValues}
			extraInfo={vehiclePlateDetail}
		/>
	);
};

export default VehiclePlateDetail;
