import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import {
	VehicleSubscription,
	RequestVehicleSubscription,
	VehicleSubscriptionRequestInfo,
	VehicleSubscriptionQuoteInfo,
	VehicleSubscriptionRequestEnd,
	VehicleSubscriptionRequestInfoAdmin,
	MakeVehicleSubscriptionQuote,
	MakeVehicleSubscriptionContract,
	VehicleSubscriptionCancellationInfo,
	VehicleSubscriptionPolicy,
	VehicleSubscriptionRequestType,
	EditVehicleSubscriptionPolicy,
	RegisterVehicleSubscriptionContract,
	ContractUpdatedHistory,
	EditVehicleSubscriptionContract,
} from '../../../interface/vehicleSubscription';

const vehicleSubscriptionMeApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVehicleSubscriptionRequestList: builder.query<
			ListResponse<VehicleSubscriptionRequestInfo>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/subscriptions-vehicle/requests/me',
				params: args,
			}),
			providesTags: ['VSRequest'],
		}),
		getVehicleSubscriptionRequestDetail: builder.query<
			ObjectResponse<VehicleSubscriptionRequestInfo>,
			{ svRequestNo: string | undefined }
		>({
			query: ({ svRequestNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/requests/me/${svRequestNo}`,
			}),
			providesTags: ['VSRequest'],
		}),
		requestVehicleSubscription: builder.mutation<
			ObjectResponse<VehicleSubscriptionRequestInfo>,
			{ body: RequestVehicleSubscription }
		>({
			query: ({ body }) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle/requests/me`,
				body,
			}),
			invalidatesTags: ['VSRequest'],
		}),
		getVehicleSubscriptionQuoteList: builder.query<
			ListResponse<VehicleSubscriptionQuoteInfo>,
			{ svRequestNo: string | undefined }
		>({
			query: ({ svRequestNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/requests/me/${svRequestNo}/quotes`,
			}),
			providesTags: ['VSQuotes'],
		}),
		getVehicleSubscriptionQuoteDetail: builder.query<
			ObjectResponse<VehicleSubscriptionQuoteInfo>,
			{ svRequestNo: string; vehicleIndex: number }
		>({
			query: ({ svRequestNo, vehicleIndex }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/requests/me/${svRequestNo}/quotes/${vehicleIndex}`,
			}),
			providesTags: ['VSQuotes'],
		}),
		confirmVehicleSubscriptionQuote: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteInfo>,
			{ svRequestNo: string }
		>({
			query: ({ svRequestNo }) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle/requests/me/${svRequestNo}/quotes/confirm`,
			}),
			invalidatesTags: ['VSQuotes', 'VSRequest'],
		}),
		getMyVehicleSubscriptionContract: builder.query<
			ObjectResponse<{ presignedUrl: string }>,
			{ svNo: string }
		>({
			query: ({ svNo }) => ({
				method: 'GET',
				url: `members/subscriptions-vehicle/me/${svNo}/contracts`,
			}),
			providesTags: ['VSContracts'],
		}),
		getMyVehicleSubscriptionList: builder.query<
			ListResponse<VehicleSubscription>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/subscriptions-vehicle/me',
				params: args,
			}),
			providesTags: ['VSContracts'],
		}),
		getMyVehicleSubscriptionDetail: builder.query<
			ObjectResponse<VehicleSubscription>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/me/${subscriptionNo}`,
			}),
			providesTags: ['VSContracts'],
		}),
		requestVehicleSubscriptionEnd: builder.mutation<
			ObjectResponse<VehicleSubscription>,
			{ body: VehicleSubscriptionRequestEnd }
		>({
			query: ({ body }) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle/me/request-end`,
				body,
			}),
			invalidatesTags: ['VSContracts'],
		}),
		cancelVehicleSubscription: builder.mutation<unknown, { svNo: string }>({
			query: ({ svNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle/${svNo}/cancellation`,
			}),
			invalidatesTags: ['VSContracts'],
		}),
	}),
});

const vehicleSubscriptionApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVSRequestListAdmin: builder.query<
			ListResponse<VehicleSubscriptionRequestInfoAdmin>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/admin/subscriptions-vehicle/requests',
				params: args,
			}),
			providesTags: ['VSRequest'],
		}),
		getVSRequestDetailAdmin: builder.query<
			ObjectResponse<VehicleSubscriptionRequestInfoAdmin>,
			{ svRequestNo: string | undefined }
		>({
			query: ({ svRequestNo }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}`,
			}),
			providesTags: ['VSRequest'],
		}),
		getVSQuoteListAdmin: builder.query<
			ListResponse<VehicleSubscriptionQuoteInfo>,
			{ svRequestNo: string | undefined }
		>({
			query: ({ svRequestNo }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/quotes`,
			}),
			providesTags: ['VSQuotes'],
		}),
		getVSQuoteDetailAdmin: builder.query<
			ObjectResponse<VehicleSubscriptionQuoteInfo>,
			{ svRequestNo: string | undefined; vehicleIndex: number }
		>({
			query: ({ svRequestNo, vehicleIndex }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/quotes/${vehicleIndex}`,
			}),
			providesTags: ['VSQuotes'],
		}),
		makeVSQuote: builder.mutation<
			ObjectResponse<VehicleSubscriptionQuoteInfo>,
			{
				svRequestNo: string;
				vehicleIndex: number;
				body: MakeVehicleSubscriptionQuote;
			}
		>({
			query: ({ svRequestNo, vehicleIndex, body }) => ({
				method: 'POST',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/quotes/${vehicleIndex}`,
				body,
			}),
			invalidatesTags: ['VSQuotes'],
		}),
		sendVSQuotes: builder.mutation<
			unknown,
			{
				svRequestNo: string;
			}
		>({
			query: ({ svRequestNo }) => ({
				method: 'POST',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/quotes/send`,
			}),
			invalidatesTags: ['VSQuotes', 'VSRequest'],
		}),
		makeVSContract: builder.mutation<
			unknown,
			{
				svRequestNo: string;
				vehicleIndex: number;
				body: MakeVehicleSubscriptionContract;
			}
		>({
			query: ({ svRequestNo, vehicleIndex, body }) => ({
				method: 'POST',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/contracts/${vehicleIndex}`,
				body,
			}),
			invalidatesTags: ['VSRequest', 'VSContracts'],
		}),
		deleteVSContract: builder.mutation<
			unknown,
			{
				svRequestNo: string;
				vehicleIndex: number;
			}
		>({
			query: ({ svRequestNo, vehicleIndex }) => ({
				method: 'DELETE',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/contracts/${vehicleIndex}`,
			}),
			invalidatesTags: ['VSRequest', 'VSContracts'],
		}),
		confirmVSContracts: builder.mutation<
			unknown,
			{
				svRequestNo: string;
				body: { contractList: { vehicleIndex: number; eContract: string }[] };
			}
		>({
			query: ({ svRequestNo, body }) => ({
				method: 'POST',
				url: `/members/admin/subscriptions-vehicle/requests/${svRequestNo}/contracts/confirm`,
				body,
			}),
			invalidatesTags: ['VSQuotes', 'VSRequest', 'VSContracts'],
		}),
		getVSContractAdmin: builder.query<
			ObjectResponse<{ presignedUrl: string }>,
			{ svNo: string }
		>({
			query: ({ svNo }) => ({
				method: 'GET',
				url: `members/admin/subscriptions-vehicle/${svNo}/contracts`,
			}),
			providesTags: ['VSContracts'],
		}),
		getVSListAdmin: builder.query<
			ListResponse<VehicleSubscription>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/admin/subscriptions-vehicle',
				params: args,
			}),
			providesTags: ['VSContracts'],
		}),
		getVSDetailAdmin: builder.query<
			ObjectResponse<VehicleSubscription>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions-vehicle/${subscriptionNo}`,
			}),
			providesTags: ['VSContracts'],
		}),
		registerVSContract: builder.mutation<
			ObjectResponse<VehicleSubscription>,
			RegisterVehicleSubscriptionContract
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/admin/subscriptions-vehicle`,
				body,
			}),
			invalidatesTags: ['VSContracts'],
		}),
		editVSContract: builder.mutation<
			ObjectResponse<ContractUpdatedHistory>,
			{ body: EditVehicleSubscriptionContract; svNo: string }
		>({
			query: ({ body, svNo }) => ({
				method: 'PUT',
				url: `/members/admin/subscriptions-vehicle/${svNo}`,
				body,
			}),
			invalidatesTags: ['VSContracts'],
		}),
		forceDeleteVSContract: builder.mutation<
			ObjectResponse<ContractUpdatedHistory>,
			{ svNo: string; memo: string }
		>({
			query: ({ svNo, memo }) => ({
				method: 'DELETE',
				url: `/members/admin/subscriptions-vehicle/${svNo}`,
				body: { memo },
			}),
			invalidatesTags: ['VSContracts'],
		}),
		// 차량 구독 변경 내역
		getVSContractHistories: builder.query<
			ListResponse<ContractUpdatedHistory>,
			{ svNo: string; page: number; pageSize: number }
		>({
			query: ({ svNo, page, pageSize }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions-vehicle/${svNo}/histories`,
				params: { page, pageSize },
			}),
			providesTags: ['VSContracts'],
		}),
	}),
});

const vehicleSubscriptionCancellationApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVSCancelList: builder.query<
			ListResponse<VehicleSubscription>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/subscriptions-cancellation',
				params: args,
			}),
			providesTags: ['VSContracts'],
		}),
		getVSCancelDetail: builder.query<
			ObjectResponse<VehicleSubscriptionCancellationInfo>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/subscriptions-cancellation/${subscriptionNo}`,
			}),
			providesTags: ['VSContracts'],
		}),
		setVSCancellationFee: builder.mutation<
			ObjectResponse<
				Omit<VehicleSubscriptionCancellationInfo, 'subscriptionVehicle'>
			>,
			{
				subscriptionNo: string;
				body: {
					cancellationFee: number;
					cancellationWord: string;
				};
			}
		>({
			query: ({ subscriptionNo, body }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle-cancellations/${subscriptionNo}`,
				body,
			}),
		}),
		approvedConfirmVSCancellation: builder.mutation<
			unknown,
			{
				subscriptionNo: string;
			}
		>({
			query: ({ subscriptionNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle-cancellations/${subscriptionNo}/confirmation-paid`,
			}),
		}),
		confirmVSCancellation: builder.mutation<
			unknown,
			{
				subscriptionNo: string;
			}
		>({
			query: ({ subscriptionNo }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle-cancellations/${subscriptionNo}/confirmation`,
			}),
		}),
	}),
});

const vehicleSubscriptionPolicyApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVSPolicies: builder.query<
			ListResponse<VehicleSubscriptionPolicy>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/subscriptions-vehicle/policies',
				params: args,
			}),
		}),
		getVSPolicyDetail: builder.query<
			ObjectResponse<VehicleSubscriptionPolicy>,
			{ vehicleType: VehicleSubscriptionRequestType }
		>({
			query: ({ vehicleType }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle/policies/${vehicleType}`,
			}),
		}),
		editVSPolicy: builder.mutation<
			ObjectResponse<VehicleSubscriptionPolicy>,
			EditVehicleSubscriptionPolicy
		>({
			query: (body) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle/policies`,
				body,
			}),
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetVehicleSubscriptionRequestListQuery,
	useGetVehicleSubscriptionRequestDetailQuery,
	useRequestVehicleSubscriptionMutation,
	useGetVehicleSubscriptionQuoteListQuery,
	useGetVehicleSubscriptionQuoteDetailQuery,
	useConfirmVehicleSubscriptionQuoteMutation,
	useGetMyVehicleSubscriptionContractQuery,
	useGetMyVehicleSubscriptionListQuery,
	useGetMyVehicleSubscriptionDetailQuery,
	useRequestVehicleSubscriptionEndMutation,
	useCancelVehicleSubscriptionMutation,
} = vehicleSubscriptionMeApiSlice;

export const {
	useGetVSRequestListAdminQuery,
	useGetVSRequestDetailAdminQuery,
	useMakeVSQuoteMutation,
	useSendVSQuotesMutation,
	useGetVSQuoteListAdminQuery,
	useGetVSQuoteDetailAdminQuery,
	useMakeVSContractMutation,
	useDeleteVSContractMutation,
	useConfirmVSContractsMutation,
	useGetVSContractAdminQuery,
	useGetVSListAdminQuery,
	useGetVSDetailAdminQuery,
	useRegisterVSContractMutation,
	useEditVSContractMutation,
	useForceDeleteVSContractMutation,
	useGetVSContractHistoriesQuery,
} = vehicleSubscriptionApiSlice;

export const {
	useGetVSCancelListQuery,
	useGetVSCancelDetailQuery,
	useSetVSCancellationFeeMutation,
	useConfirmVSCancellationMutation,
	useApprovedConfirmVSCancellationMutation,
} = vehicleSubscriptionCancellationApiSlice;

export const {
	useGetVSPoliciesQuery,
	useGetVSPolicyDetailQuery,
	useEditVSPolicyMutation,
} = vehicleSubscriptionPolicyApiSlice;
