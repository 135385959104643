export enum Service {
	BOS_WEB = 'BOS_WEB',
	CUSTOMER_APP = 'CUSTOMER_APP',
	MANAGER_APP = 'MANAGER_APP',
}

export type ServiceType = 'BOS_WEB' | 'CUSTOMER_APP' | 'MANAGER_APP';
export type OsType = 'ios' | 'android';

export interface GenerateAppVersionDto {
	// 서비스 명
	service: ServiceType;
	// ios, android 등 운영체제 타입(소문자 입력)
	type: OsType;
	// x.x.x(메이저, 마이너, 버그픽스) 형태의 버전
	version: string;
	// 버전에 대한 설명(릴리즈 노트)
	description: string;
	// 작성자 이름
	managerName: string;
}

export interface GenerateAppVersionResponseDto {
	// 데이터 ID(PK)
	id: number;
	// 생성일시
	createdAt: string;
	// 수정일시
	updatedAt: string;
	// 삭제일시
	deletedAt: string;
	// 서비스 명
	service: ServiceType;
	// ios, android 등 운영체제 타입(소문자 입력)
	type: OsType;
	// x.x.x(메이저, 마이너, 버그픽스) 형태의 버전
	version: string;
	// 버전에 대한 설명(릴리즈 노트)
	description: string;
	// 작성자 이름
	managerName: string;
	// 강제 업데이트 여부
	forceUpdate: boolean;
}
