import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { Skeleton } from 'antd';
import { SelectInfo } from 'antd/lib/calendar/generateCalendar';
import Typo from '../../../components/atoms/Typo';
import { useGetVehicleTripQuery } from '../../../store/apis/vehicleConditions';
import GridLayout, { GridItem } from '../../../components/molecules/GridLayout';
import Button from '../../../components/atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import ContentBox from '../../../components/atoms/ContentBox';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import VehiclesSelection from './VehiclesSelection';
import { RootState } from '../../../store';
import VehicleCalendar from '../../../components/organisms/VehicleCalendar';
import { transformTimeToHour } from '../../../utils/date-util';

dayjs.extend(localeData);

const Section = styled.div`
	margin-bottom: 16px;
`;

const TabWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TabList = styled.ul`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	align-items: center;
	margin-top: 12px;
`;

const SelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	//width: 180px;
`;

const formatTime = (selectedDate: Dayjs, time: string) => {
	return dayjs(`${selectedDate.format('YYYYMMDD')}T${time}`).format('HH:mm:ss');
};

const VehicleTrips = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const initialDate = searchParams.get('date') || dayjs().format('YYYY-MM-DD');
	const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(initialDate));
	const [selectedYear, setSelectedYear] = useState(dayjs(initialDate).year());
	const [selectedMonth, setSelectedMonth] = useState<number>(
		dayjs(initialDate).month(),
	);

	const [isYearSelected, setIsYearSelected] = useState(true);

	const { user } = useSelector((state: RootState) => state.auth);
	const [departmentId, setDepartmentId] = useState(user?.department.id);

	const navigate = useNavigate();
	const [vehicleNumber, setVehicleNumber] = useState(
		searchParams.get('vehicleNumber') || '',
	);

	const handleDateSelect = (value: Dayjs, { source }: SelectInfo) => {
		const dateSelected = value.format('YYYY-MM-DD');
		setSearchParams({
			vehicleNumber,
			date: dateSelected,
		});

		if (source === 'date') {
			setSelectedYear(value.get('year'));
			setSelectedMonth(value.get('month'));
		}
	};

	const handleCarNumberChange = useCallback(
		(carNum: string) => {
			setSearchParams({
				vehicleNumber: carNum,
				// date: dayjs(selectedDate).format('YYYY-MM-DD'),
			});
			setVehicleNumber(carNum);
		},
		[setSearchParams],
	);

	const { isError, isLoading, isFetching, currentData } =
		useGetVehicleTripQuery(
			{
				vehicleNumber,
				date: dayjs(selectedDate).format('YYMMDD'),
			},
			{ skip: !vehicleNumber || !selectedDate || !isYearSelected },
		);

	const trips = currentData && !isError ? currentData.rows : [];

	const renderTripsByTimeSection = () => {
		if (!vehicleNumber) {
			return <Typo>차량번호를 선택해주세요.</Typo>;
		}

		if (isError) {
			return <Typo>운행기록이 없습니다.</Typo>;
		}

		if (isLoading || isFetching) {
			return (
				<Section>
					<Skeleton.Input
						active
						size="large"
						style={{ borderRadius: '10px' }}
					/>
				</Section>
			);
		}
		return (
			<Section>
				<Typo>총 {trips.length}회의 운행기록이 있습니다.</Typo>
			</Section>
		);
	};

	return (
		<GridDetailTemplate detailTitle="차량 운행기록">
			<ContentBox borderRadius="10px" className="inner-content" $isCompact>
				<TabWrapper>
					<TabList>
						<Typo>차량번호</Typo>
						<SelectWrapper>
							<VehiclesSelection
								vehicleNumber={vehicleNumber}
								departmentId={departmentId}
								page={1}
								pageSize={500}
								setDepartmentId={setDepartmentId}
								setVehicleNumber={handleCarNumberChange}
							/>
						</SelectWrapper>
						<Typo $typoType="hm6">
							{selectedDate.format('YYYY년 MM월 DD일')}
						</Typo>
					</TabList>
				</TabWrapper>
			</ContentBox>
			<ContentBox borderRadius="10px" className="inner-content" $isCompact>
				{renderTripsByTimeSection()}
				{isLoading || isFetching ? (
					<Skeleton active style={{ borderRadius: '10px' }} />
				) : (
					trips.map((trip, index) => {
						const departureTime = trip.startedAt ? (
							formatTime(selectedDate, trip.startedAt)
						) : (
							<Typo color="danger_5_main">확인안됨</Typo>
						);
						const arrivalTime = trip.finishedAt ? (
							formatTime(selectedDate, trip.finishedAt)
						) : (
							<Typo color="danger_5_main">확인안됨</Typo>
						);

						const isButtonDisabled = !trip.startedAt || !trip.finishedAt;

						return (
							<GridLayout key={index} $columnCount={4} $isCompact>
								<GridItem title="출발시각">{departureTime}</GridItem>
								<GridItem title="도착시각">{arrivalTime}</GridItem>
								<GridItem title="총 운행시간">
									{trip.startedAt && trip.finishedAt ? (
										transformTimeToHour(
											dayjs(
												`${selectedDate.format('YYYY-MM-DD')}T${
													trip.finishedAt
												}`,
											).diff(
												dayjs(
													`${selectedDate.format('YYYY-MM-DD')}T${
														trip.startedAt
													}`,
												),
											),
											3600000,
										)
									) : (
										<Typo color="danger_5_main">확인안됨</Typo>
									)}
								</GridItem>
								<GridItem title="자세히보기">
									<Button
										size="small"
										$buttonType={ButtonTypeType.PRIMARY_LIGHT}
										onClick={() =>
											navigate(
												`/vehicle/trip/${vehicleNumber}?startDate=${selectedDate.format(
													'YYMMDD',
												)}&startedAt=${
													trip.startedAt
												}&finishDate=${selectedDate.format(
													'YYMMDD',
												)}&finishedAt=${trip.finishedAt}`,
											)
										}
										disabled={isButtonDisabled}
									>
										이동경로 보기
									</Button>
								</GridItem>
							</GridLayout>
						);
					})
				)}
			</ContentBox>
			<VehicleCalendar
				vehicleNumber={vehicleNumber}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				selectedYear={selectedYear}
				setSelectedYear={setSelectedYear}
				selectedMonth={selectedMonth}
				setSelectedMonth={setSelectedMonth}
				isYearSelected={isYearSelected}
				setIsYearSelected={setIsYearSelected}
				handleDateSelect={handleDateSelect}
			/>
		</GridDetailTemplate>
	);
};

export default VehicleTrips;
