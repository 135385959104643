import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { asideMenuList } from './components/organisms/Aside/data';
import ErrorPage from './pages/Error';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from './routes';
import { HOME_PATH, LOGIN_PATH } from './routes/constants/urls';
import PrivateRoutesGuard from './routes/PrivateRoutesGuard';
import { RootState } from './store';
import { getDefaultPathUrl } from './store/auth';

function App() {
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;

	const navigate = useNavigate();
	const sessionId = useSelector((state: RootState) => state.auth.user?.id);
	const isLogin = !!sessionId;

	useEffect(() => {
		if (userId) {
			const userKey = `tutorialguide_${userId}`;
			const tutorialguide = {
				vehiclePlateDetail: false,
				vehicleSubscriptionConsultingDetail: false,
				vehicleSubscriptionDetail: false,
				vehicleSubscriptionQuotes: false,
				vehiclesDetail: false,
			};

			if (!localStorage.getItem(userKey)) {
				localStorage.setItem(userKey, JSON.stringify(tutorialguide));
			}
		}
	}, [userId]);

	useEffect(() => {
		const url = window.location.href;
		const pathName = new URL(url).pathname;
		if (pathName === HOME_PATH) {
			navigate(LOGIN_PATH);
		}
		if (pathName === LOGIN_PATH && isLogin && user) {
			navigate(getDefaultPathUrl(asideMenuList, user.role.roleItems), {
				replace: true,
			});
		}
	}, [isLogin, navigate, user]);

	return (
		<Routes>
			<Route path={HOME_PATH}>
				{/* Public Routes */}
				{PUBLIC_ROUTES.map(({ path, element }) => {
					return <Route key={path} path={path} element={element} />;
				})}

				<Route element={<PrivateRoutesGuard />}>
					{PRIVATE_ROUTES.map(({ path, element }) => (
						<Route key={path} path={path} element={element} />
					))}
				</Route>
				<Route path="/*" element={<ErrorPage $status={404} />} />
			</Route>
		</Routes>
	);
}

export default App;
