import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../atoms/Typo';
import Alert from '../../atoms/Alert';
import Input from '../../atoms/Input';
import { ToastContext } from '../../../contexts/Toast';
import InputAlert from '../../atoms/InputAlert';
import TextArea from '../../atoms/TextArea';
import { CustomErrorType } from '../../../store/apis/@types';
import { numberWithCommas } from '../../../utils/data-format';

interface CancellationModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: { cancellationFee: number; cancellationWord: string };
	id: string;
	useMutation: UseMutation<any>;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormLabel = styled.p`
	${({ theme }) => theme.typo.label1};
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

function SetCancellationFeeModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	id,
	useMutation,
}: CancellationModalProps) {
	const toast = useContext(ToastContext);

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [terminationFee] = useMutation();

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleValidate = (values: typeof defaultValue) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof defaultValue, string>> = {};

		if (!values.cancellationWord) {
			errors.cancellationWord = '해지 안내 문구를 입력해주세요.';
		}

		if (!Object.keys(errors).length) {
			setIsTrySubmit(false);
		}

		setIsAlertOpen(false);

		return errors;
	};

	const getPriceNumberType = (value: string) => {
		const valueTypeNumber = Number(value.replace(/,/g, ''));

		if (Number.isNaN(valueTypeNumber)) {
			return Number(value.slice(0, -1).replace(/,/g, ''));
		}
		return Number(value.replace(/,/g, ''));
	};

	const handleSubmitClick = async (
		values: typeof defaultValue,
		formikHelpers: FormikHelpers<typeof defaultValue>,
	) => {
		const res = await terminationFee({
			subscriptionNo: id,
			body: {
				cancellationFee: getPriceNumberType(
					numberWithCommas(values.cancellationFee),
				),
				cancellationWord: values.cancellationWord,
			},
		});

		if (!('error' in res)) {
			onRefetch();
			onClose();
			toast('info', '해지 위약금 설정이 완료되었습니다.');
		}

		if ('error' in res) {
			const errorMessage =
				(res.error as CustomErrorType).data.translate ||
				(res.error as CustomErrorType).data.message;

			toast('error', errorMessage);
		}

		formikHelpers.setErrors({});
		await formikHelpers.setValues({
			cancellationFee: 0,
			cancellationWord: '',
		});
	};

	const {
		errors,
		setErrors,
		setFieldValue,
		values,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: defaultValue,
		onSubmit: handleSubmitClick,
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleModalClose = () => {
		onClose();

		setIsTrySubmit(false);
		setErrors({});
		setTimeout(() => {
			setFieldValue('cancellationWord', '');
		}, 0);
	};

	return (
		<>
			<Modal
				title={<ModalTitle>해지 위약금 설정</ModalTitle>}
				open={isVisible}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							취소
						</Button>
						<Button onClick={handleAlertOpen}>완료</Button>
					</ModalFooterWrapper>
				}
			>
				<Form>
					<FormItem
						name="cancellationFee"
						label={<FormLabel>해지 위약금</FormLabel>}
						rules={[{ required: true, message: '해지 위약금을 입력해 주세요' }]}
						required
						colon={false}
					>
						<Input
							name="cancellationFee"
							placeholder="숫자 입력"
							type="text"
							value={numberWithCommas(values.cancellationFee)}
							onChange={handleChange}
							onReset={() => setFieldValue('cancellationFee', '')}
							unitText="원"
						/>
						{errors.cancellationFee && (
							<InputAlert $inputStatus="error">
								{errors.cancellationFee}
							</InputAlert>
						)}
					</FormItem>
					<FormItem
						name="cancellationWord"
						label={<FormLabel>해지 안내 문구</FormLabel>}
						rules={[
							{ required: true, message: '해지 안내 문구를 입력해 주세요' },
						]}
						required
						colon={false}
					>
						<TextArea
							name="cancellationWord"
							style={{ minHeight: '200px', fontSize: 16 }}
							placeholder="해지 안내 문구를 작성해 주세요."
							value={values.cancellationWord}
							onChange={(e) =>
								setFieldValue('cancellationWord', e.target.value)
							}
						/>
						{errors.cancellationWord && (
							<InputAlert $inputStatus="error">
								{errors.cancellationWord}
							</InputAlert>
						)}
					</FormItem>
				</Form>
			</Modal>

			{isAlertOpen ? (
				<Alert
					title="해지 위약금 설정"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={() => {
						handleSubmit();
					}}
					onConfirmButtonText="확인"
					isVisible={isAlertOpen}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							해지 위약금을 설정하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default SetCancellationFeeModal;
