import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';
import {
	CompanyDto,
	MakeCompanyDta,
	UpdateCompanyDto,
} from '../../../interface/company';

const companyApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCompanies: builder.query<
			ListResponse<CompanyDto>,
			{ args: Record<any, any>; page: number; pageSize: number }
		>({
			query: ({ args, page, pageSize }) => {
				return {
					url: `/members/companies`,
					params: { ...args, page, pageSize },
				};
			},
			providesTags: ['Company'],
		}),
		getCompanyDetail: builder.query<
			ObjectResponse<CompanyDto>,
			{ companyId: string | undefined | number }
		>({
			query: ({ companyId }) => {
				return { url: `/members/companies/${companyId}` };
			},
			providesTags: ['Company'],
		}),

		getMyCompany: builder.query<ObjectResponse<CompanyDto>, void>({
			query: () => {
				return { url: `/members/companies/me` };
			},
			providesTags: ['Company'],
		}),

		makeCompany: builder.mutation<ObjectResponse<CompanyDto>, MakeCompanyDta>({
			query: (body) => ({
				method: 'POST',
				url: `/members/companies`,
				body,
			}),
			invalidatesTags: ['Company'],
		}),
		updateCompany: builder.mutation<
			ObjectResponse<CompanyDto>,
			{ body: UpdateCompanyDto; companyId: string | undefined }
		>({
			query: ({ body, companyId }) => ({
				method: 'PUT',
				url: `/members/companies/${companyId}`,
				body,
			}),
			invalidatesTags: (args) => [
				{ type: 'Company', id: args?.row.id },
				'Company',
			],
		}),
		updateMyCompany: builder.mutation<
			ObjectResponse<CompanyDto>,
			{ body: UpdateCompanyDto }
		>({
			query: ({ body }) => ({
				method: 'PUT',
				url: `/members/companies/me`,
				body,
			}),
			invalidatesTags: ['Company'],
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetCompaniesQuery,
	useGetCompanyDetailQuery,
	useGetMyCompanyQuery,
	useMakeCompanyMutation,
	useUpdateCompanyMutation,
	useUpdateMyCompanyMutation,
} = companyApi;
