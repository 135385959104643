import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	CS_NOTICE_CREATE_PATH,
	CS_NOTICE_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import { createOptionsBulletTextCellRender } from '../../../../utils/row-data-util';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetNoticeQuery } from '../../../../store/apis/notice';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function NoticePage() {
	const { formInitialValues, colsList } = userData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,

		handleDetailClick,
	} = useGridDataWithRTK({
		useQuery: useGetNoticeQuery,
		formInitialValues,
		colsList,
		detailPath: CS_NOTICE_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(CS_NOTICE_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="공지사항 관리 (매니저)"
			gridTitle="공지사항 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleCreateClick} size="small">
						공지사항 등록
					</Button>
				</ButtonContainer>
			}
			frameworkComponents={{
				...createOptionsBulletTextCellRender([
					{ label: '사용', value: true, color: 'black' },
					{ label: '미사용', value: false, color: 'gray_6' },
				]),
			}}
		/>
	);
}

export default NoticePage;
