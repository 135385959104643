import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { blackBackIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';

interface DetailHeaderProps {
	detailTitle: string;
	rightAccessory?: ReactNode;
	onBack?: () => void;
}

const Header = styled.header`
	display: flex;
	padding: 16px 24px;
	border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_3};

	p {
		display: inline-flex;
		align-items: center;
	}

	.go-back-icon {
		margin-right: 4px;
		cursor: pointer;
	}

	.bottom-container {
		display: flex;
		align-items: center;
	}
`;

const RightSection = styled.section`
	margin-left: auto;
`;

function DetailHeader({
	detailTitle,
	rightAccessory,
	onBack,
}: PropsWithChildren<DetailHeaderProps>) {
	return (
		<Header>
			<p>
				{onBack && (
					<Icon
						className="go-back-icon"
						width={20}
						height={20}
						src={blackBackIcon}
						onClick={() => {
							if (onBack) {
								onBack();
							}
						}}
					/>
				)}

				<Typo $typoType="h4">{detailTitle}</Typo>
			</p>

			{rightAccessory && <RightSection>{rightAccessory}</RightSection>}
		</Header>
	);
}

export default DetailHeader;
