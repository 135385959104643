import {
	GenerateStockDto,
	StockResponseDto,
} from '../../../interface/Inventory';
import { axiosInstance } from '../../../network/axios';
import { ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const inventoryApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		inventoryCreate: builder.mutation<
			ObjectResponse<StockResponseDto>,
			GenerateStockDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/stock`,
				body,
			}),
		}),
		inventoryDelete: builder.mutation<
			ObjectResponse<StockResponseDto>,
			{ id: number }
		>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `/members/stock/${id}`,
			}),
		}),
		inventoryListDelete: builder.mutation<
			ObjectResponse<StockResponseDto>,
			{ stockId: number }[]
		>({
			query: (stockId) => ({
				method: 'DELETE',
				url: `/members/stock`,
				body: {
					row: stockId,
				},
			}),
		}),
		inventoryUpdate: builder.mutation<
			ObjectResponse<StockResponseDto>,
			{ id: number; body: GenerateStockDto }
		>({
			query: ({ body, id }) => ({
				method: 'PUT',
				url: `/members/stock/${id}`,
				body,
			}),
		}),
	}),
});

export const {
	useInventoryCreateMutation,
	useInventoryDeleteMutation,
	useInventoryUpdateMutation,
	useInventoryListDeleteMutation,
} = inventoryApi;
export default inventoryApi;

export const getInventoryDetail = async (id: number) => {
	const url = `/members/stock/${id}`;
	const { data } = await axiosInstance.get(url);
	return data.row;
};
