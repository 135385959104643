import { Switch } from 'antd';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import Editor from '../../../../components/atoms/Editor';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { CS_NOTICE_PATH } from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import { useNoticeCreateMutation } from '../../../../store/apis/notice';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';

import { ButtonTypeType } from '../../../../styles/theme';

const initialValues = {
	viewable: true,
	title: '',
	body: '',
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

function NoticeCreatePage() {
	const navigate = useNavigate();
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [noticeCreate] = useNoticeCreateMutation();

	const handleAlertClose = () => {
		setAlertVisible(false);
	};

	const goTo = (route: number) => {
		navigate(route);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.title === '') {
			errors.title = '제목을 입력해주세요.';
		}
		if (values.body === '') {
			errors.body = '내용을 입력해주세요.';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			handleAlertClose();
			dispatch(fullLoadingOn());

			const data = await noticeCreate({
				viewable: values.viewable,
				title: values.title,
				body: values.body,
				service: 'MANAGER_APP',
			});

			dispatch(fullLoadingOff());

			if ('error' in data) {
				const msg =
					(data.error as CustomErrorType).data.translate ||
					(data.error as CustomErrorType).data.message;
				setToast('error', msg);
			} else {
				navigate(CS_NOTICE_PATH);
				setToast('info', '공지사항 등록이 완료되었습니다.');
			}
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertOpen = async () => {
		const validateResponse = await formik.validateForm();

		const isNoError = Object.keys(validateResponse).length === 0;

		if (isNoError) {
			setAlertVisible(true);
		}
	};

	const renderAlert = () => {
		if (isAlertVisible) {
			return (
				<Alert
					title="공지사항 등록"
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={formik.handleSubmit}
					isVisible={isAlertVisible}
				>
					<Typo $typoType="b5" color="gray_8">
						공지사항을 등록하시겠습니까?
					</Typo>
				</Alert>
			);
		}
		return null;
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="공지사항 등록"
				onBack={() => goTo(-1)}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={() => navigate(CS_NOTICE_PATH)}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleAlertOpen} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="공지사항 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="viewable" essential>
								<span>
									<Typo $typoType="label2">사용유무</Typo>
								</span>
							</Label>
							<Switch
								onChange={(value: boolean) => {
									formik.setFieldValue('viewable', value);
								}}
								checked={formik.values.viewable}
							/>
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="title" essential>
								<span>
									<Typo $typoType="label2">공지사항 제목</Typo>
								</span>
							</Label>
							<Input
								name="title"
								placeholder="공지 제목을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.title}
								onChange={formik.handleChange}
								onReset={() => formik.setFieldValue('title', '')}
							/>
							{formik.errors.title && (
								<InputAlert $inputStatus="error">
									{formik.errors.title}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="body" essential>
								<span>
									<Typo $typoType="label2">공지사항 내용</Typo>
								</span>
							</Label>
							<Editor
								value={formik.values.body}
								onChange={(value) => formik.setFieldValue('body', value)}
							/>
							{formik.errors.body && (
								<InputAlert $inputStatus="error">
									{formik.errors.body}
								</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{renderAlert()}
		</>
	);
}

export default NoticeCreatePage;
