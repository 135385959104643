import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useGetProductDetailQuery } from '../../../../store/apis/product';
import { ProductCreateUpdateDto } from '../../../../interface/product';
import ProductTemplate from '../../../../components/templates/ProductTemplate';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';

function ProductDetailPage() {
	const { id } = useParams();
	const { isLoading, data } = useGetProductDetailQuery({ productId: id });
	const productDetail = data?.row;

	const initialValues: ProductCreateUpdateDto = {
		productType: productDetail?.productType,
		productName: productDetail?.productName,
		productPrice: productDetail?.productPrice,
		productTax: productDetail?.productTax,
		productTaxType: productDetail?.productTaxType,
		productViewable: productDetail?.productViewable,
		productAddible: productDetail?.productAddible,
		productImage: productDetail?.productImage,
		productIcon: productDetail?.productIcon,
		productDescription: productDetail?.productDescription,
		generalPrice: productDetail?.generalPrice,
		generalTax: productDetail?.generalTax,
		generalCount: productDetail?.generalCount,
		generalExpireDays: productDetail?.generalExpireDays,
	};

	return isLoading ? (
		<GridDetailTemplate detailTitle="개별상품 등록">
			<ContentBoxWithHeader title="상품 상세" borderRadius="10px">
				<Skeleton
					active
					paragraph={{ rows: 6 }}
					style={{ borderRadius: '10px' }}
				/>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	) : (
		<ProductTemplate initialValues={initialValues} />
	);
}

export default ProductDetailPage;
