import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import useGridData from '../../../../hooks/useGridData';
import {
	CS_TEL_CREATE_PATH,
	CS_TEL_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import { getTelephoneCategory } from '../../../../store/apis/telephoneInquiry';
import { LabelValue } from '../../../../utils/const';

import gridData from './gridData';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function TelInquiryPage() {
	const [category, setCategory] = useState<LabelValue[]>([]);
	const { formInitialValues, colsList, forms } = gridData(category);
	const setToast = useContext(ToastContext);
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
	} = useGridData({
		queryPath: 'members/telephone-inquiries',
		formInitialValues,
		colsList,
		detailPath: CS_TEL_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(CS_TEL_CREATE_PATH);
	};

	useEffect(() => {
		(async () => {
			try {
				const res = await getTelephoneCategory();

				setCategory([
					{ label: '전체', value: '' },
					...res.map((item) => ({
						label: item.value as string,
						value: item.value,
					})),
				]);
			} catch (e) {
				const err = e as AxiosError<{ message: string; translate: string }>;

				if (err.response)
					setToast(
						'error',
						err.response?.data.translate || err.response?.data.message,
					);
			}
		})();
	}, [setToast]);

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="전화문의 현황 관리"
			gridTitle="전화문의 현황 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			forms={forms}
			formInitialValues={formInitialValues}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleCreateClick} size="small">
						상담내역 추가
					</Button>
				</ButtonContainer>
			}
		/>
	);
}

export default TelInquiryPage;
