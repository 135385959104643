import { Switch } from 'antd';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import Editor from '../../../../components/atoms/Editor';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { CS_NOTICE_PATH } from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	useGetNoticeDetailQuery,
	useNoticeDeleteMutation,
	useNoticeModifyMutation,
} from '../../../../store/apis/notice';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';

import { ButtonTypeType } from '../../../../styles/theme';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

enum AlertState {
	NULL,
	SAVE_ALERT,
	DELETE_ALERT,
}

function NoticeCreatePage() {
	const navigate = useNavigate();
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();
	const { id } = useParams();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [alertState, setAlertState] = useState(AlertState.NULL);
	const [isEmptyBody, setIsEmptyBody] = useState<boolean>(true);

	const [noticeModify] = useNoticeModifyMutation();
	const [noticeDelete] = useNoticeDeleteMutation();

	const handleAlertClose = () => {
		setAlertState(AlertState.NULL);
	};

	const handleDeleteAlertOpen = () => {
		setAlertState(AlertState.DELETE_ALERT);
	};

	const goToBackPage = () => {
		navigate(-1);
	};

	const { data } = useGetNoticeDetailQuery({ id }, { skip: !id });
	const noticeDetail = data?.row;

	const initialValues = {
		viewable: noticeDetail?.viewable ?? true,
		title: noticeDetail?.title ?? '',
		body: noticeDetail?.body ?? '',
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.title === '') {
			errors.title = '제목을 입력해주세요.';
		}
		if (isEmptyBody) {
			errors.body = '내용을 입력해주세요.';
		}

		return errors;
	};

	const {
		handleSubmit,
		errors,
		setFieldValue,
		values,
		handleChange,
		validateForm,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (value) => {
			handleAlertClose();
			dispatch(fullLoadingOn());
			const res = await noticeModify({
				id: Number(id),
				viewable: value.viewable,
				title: value.title,
				body: value.body,
				service: 'MANAGER_APP',
			});
			dispatch(fullLoadingOff());

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				setToast('info', '공지사항 저장이 완료되었습니다.');
			}
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertOpenClick = async () => {
		const validateResponse = await validateForm();

		const isNoError = Object.keys(validateResponse).length === 0;

		if (isNoError) {
			setAlertState(AlertState.SAVE_ALERT);
		}
	};

	const handleDelete = async () => {
		handleAlertClose();
		dispatch(fullLoadingOn());
		const res = await noticeDelete({ id: Number(id) });
		dispatch(fullLoadingOff());

		if ('error' in res) {
			const msg =
				(res.error as CustomErrorType).data.translate ||
				(res.error as CustomErrorType).data.message;

			setToast('error', msg);
		} else {
			setToast('info', '공지사항 삭제가 완료되었습니다.');
			navigate(CS_NOTICE_PATH);
		}
	};

	const renderAlert = () => {
		if (alertState === AlertState.SAVE_ALERT) {
			return (
				<Alert
					title="공지사항 저장"
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={handleSubmit}
					isVisible
				>
					<Typo $typoType="b5" color="gray_8">
						공지사항을 저장하시겠습니까?
					</Typo>
				</Alert>
			);
		}

		if (alertState === AlertState.DELETE_ALERT) {
			return (
				<Alert
					title="공지사항 삭제"
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="삭제하기"
					onConfirmButtonClick={handleDelete}
					isVisible
				>
					<Typo $typoType="b5" color="gray_8">
						공지사항을 삭제하시겠습니까?
					</Typo>
				</Alert>
			);
		}

		return null;
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="공지사항 상세"
				onBack={goToBackPage}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={handleDeleteAlertOpen}
							size="small"
						>
							삭제하기
						</Button>
						<Button onClick={handleAlertOpenClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="공지사항 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="viewable" essential>
								<span>
									<Typo $typoType="label2">사용유무</Typo>
								</span>
							</Label>
							<Switch
								onChange={(bool) => setFieldValue('viewable', bool)}
								checked={values.viewable}
							/>
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="title" essential>
								<span>
									<Typo $typoType="label2">공지사항 제목</Typo>
								</span>
							</Label>
							<Input
								name="title"
								placeholder="공지 제목을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.title}
								onChange={handleChange}
								onReset={handleChange}
							/>
							{errors.title && (
								<InputAlert $inputStatus="error">{errors.title}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="body" essential>
								<span>
									<Typo $typoType="label2">공지사항 내용</Typo>
								</span>
							</Label>
							<Editor
								value={values.body}
								onChange={(v, _, __, editor) => {
									if (editor.getLength() === 1) {
										setIsEmptyBody(true);
									} else {
										setIsEmptyBody(false);
									}

									setFieldValue('body', v);
								}}
							/>

							{errors.body && (
								<InputAlert $inputStatus="error">{errors.body}</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{renderAlert()}
		</>
	);
}

export default NoticeCreatePage;
