import React, { PropsWithChildren, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { LOGIN_PATH, PASSWORD_SEARCH } from '../../../routes/constants/urls';

import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import InputAlert, { InputAlertMessage } from '../../atoms/InputAlert';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import LoginInputForm from '../../molecules/LoginInputForm';
import Alert from '../../atoms/Alert';
import FormLayout from '../../organisms/LoginFormLayout';
import LoginSearchResultLayout from '../../organisms/LoginSearchResultLayout';
import { ButtonTypeType } from '../../../styles/theme';

import { useAuthTokenMutation } from '../../../store/apis/authToken';
import { useAuthTokenVerifyMutation } from '../../../store/apis/authTokensVerify';
import { ToastContext } from '../../../contexts/Toast';

import useTimer from '../../../hooks/useTimer';
import { useFindAdminIdMutation } from '../../../store/apis/findAdminId';
import { phoneFormat } from '../../../utils/data-format';
import { CustomErrorType } from '../../../store/apis/@types';

interface UserIdSearchTemplateProps {
	title: string;
}

const UserIdSearchTemplateContainer = styled.main`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;

	.title {
		margin-bottom: 40px;
	}

	.input-button-container {
		display: flex;
		gap: 6px;
	}

	.button-container {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
	}

	.label {
		display: inline-block;
		margin-bottom: 6px;
	}

	.login-form-layout {
		margin-bottom: 0;
	}

	.input-alert-pending-time {
		color: ${({ theme }) => theme.common.colors.danger_5_main};
	}

	.input-button {
		white-space: nowrap;
	}

	.search-result-layout-button {
		display: flex;
		flex-direction: column;
	}

	.search-result-userinfo {
		font-weight: 500;
	}

	.search-result-userid {
		color: ${({ theme }) => theme.common.colors.primary_6_main};
		font-weight: 700;
	}

	.search-result-form-layout {
		justify-content: space-between;
	}
`;

function UserIdSearchTemplate({
	title,
}: PropsWithChildren<UserIdSearchTemplateProps>) {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [userPhoneNumber, setUserPhoneNumber] = useState('');
	const [certificationNumber, setCertificationNumber] = useState('');
	const [isPhoneNumberPost, setIsPhoneNumberPost] = useState(false);
	const [isCertification, setIscertification] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [userAuthId, setUserAuthId] = useState('');
	const [accessToken, setAccessToken] = useState('');

	const [isAlertVisible, setAlertVisible] = useState(false);

	const [userId, setUserId] = useState('');
	const [createdAt, setCreatedAt] = useState('');
	const [authToken] = useAuthTokenMutation();
	const [authTokenVerify] = useAuthTokenVerifyMutation();
	const [findAdminId] = useFindAdminIdMutation();

	const goToLogin = () => {
		navigate(LOGIN_PATH);
	};

	const {
		state: { countDown, isCountEnd },
		func: { resetCount, setIsCountEnd },
	} = useTimer({ duration: 180 });

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const getUserPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
		const autoHyphen = phoneFormat(event.target.value);

		setUserPhoneNumber(autoHyphen);
	};
	const phoneNumberHideHyphen = userPhoneNumber.replace(/-/g, '');

	const getCertificationNumber = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const numberReg = event.target.value.replace(/[^0-9]/g, '');
		setCertificationNumber(numberReg);
	};

	const handlePhoneNumberPostClick = async () => {
		const res = await authToken({
			type: 'message',
			key: phoneNumberHideHyphen,
			kind: '가입 인증',
		});

		if (!('error' in res)) {
			setUserAuthId(res.data.row.id);
			setIsPhoneNumberPost(true);
			setIscertification(false);
			setCertificationNumber('');
			resetCount();
		}
		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	const handleCertificationClick = async () => {
		const res = await authTokenVerify({
			type: 'message',
			kind: '가입 인증',
			authTokenId: userAuthId,
			token: certificationNumber,
		});
		if (!('error' in res) && res.data.row.accessToken) {
			setAccessToken(res.data.row.accessToken);
			setIscertification(true);
			setIsCountEnd(true);
		}
		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	const handleSubmitButton = async () => {
		const res = await findAdminId({
			authTokenId: userAuthId,
			accessToken,
			removable: true,
		});

		if (!('error' in res)) {
			setUserId(res.data.row.applicationId);
			setCreatedAt(res.data.row.createdAt);
			setIsSubmit(true);
		}

		if ('error' in res) {
			handleAlertOpenClick();
		}
	};

	const goToPasswordSearch = () => {
		navigate(PASSWORD_SEARCH);
	};

	const userIdSearchDafaultLayout = () => {
		return (
			<>
				<h1 className="title">
					<Typo $typoType="h1">{title}</Typo>
				</h1>
				<FormLayout
					formTitle={
						<h2>
							<Typo $typoType="btn1">
								아이디를 찾기 위해
								<br />
								휴대폰 인증이 필요합니다
							</Typo>
						</h2>
					}
				>
					<LoginInputForm>
						<Label name="userPassword" essential>
							<span className="label">
								<Typo $typoType="label2">휴대폰 번호</Typo>
							</span>
						</Label>
						<div className="input-button-container">
							<Input
								name="userPassword"
								placeholder="휴대폰 번호 입력"
								$isDisabled={isCertification}
								type="text"
								$inputSize="large"
								$inputRadius="large"
								value={userPhoneNumber}
								onChange={getUserPhoneNumber}
								maxLength={13}
								onReset={() => setUserPhoneNumber('')}
							/>
							<Button
								className="input-button"
								$buttonType={
									isPhoneNumberPost
										? ButtonTypeType.GHOST
										: ButtonTypeType.GHOST_BLACK
								}
								disabled={
									!(
										/^01([0|1|6|7|8|9]){1}[0-9]{3,4}[0-9]{4}/.test(
											userPhoneNumber.replaceAll('-', ''),
										) && userPhoneNumber.length >= 10
									)
								}
								onClick={handlePhoneNumberPostClick}
							>
								{isPhoneNumberPost ? '재전송' : '인증번호 전송'}
							</Button>
						</div>
					</LoginInputForm>

					<LoginInputForm className="login-form-layout">
						<Label name="userPasswordCheck" essential>
							<span className="label">
								<Typo $typoType="label2">인증번호</Typo>
							</span>
						</Label>
						<div className="input-button-container">
							<Input
								name="userPasswordCheck"
								placeholder="인증번호 4자리 입력"
								$isDisabled={!isPhoneNumberPost || isCertification}
								type="text"
								$inputSize="large"
								$inputRadius="large"
								$inputStatus="normal"
								value={certificationNumber}
								onChange={getCertificationNumber}
								maxLength={4}
								onReset={() => setCertificationNumber('')}
							/>
							<Button
								className="input-button"
								$buttonType={
									certificationNumber.length === 4
										? ButtonTypeType.GHOST
										: ButtonTypeType.GHOST_BLACK
								}
								disabled={
									(!(certificationNumber.length === 4) && !isCertification) ||
									isCountEnd
								}
								onClick={handleCertificationClick}
							>
								{isCertification ? '인증완료' : '인증하기'}
							</Button>
						</div>
					</LoginInputForm>
					{isPhoneNumberPost && !isCertification && (
						<InputAlert $inputStatus="pending" $justifyContent="spaceBetween">
							<p>
								<Typo $typoType="label3" color="info_5_main">
									{InputAlertMessage.pending}
								</Typo>
							</p>
							<span className="input-alert-pending-time">
								<Typo $typoType="label3" color="danger_5_main">
									{countDown.toString()}
								</Typo>
							</span>
						</InputAlert>
					)}

					<div className="button-container">
						<Button
							size="large"
							$isFull
							$textCenter
							disabled={!isCertification}
							onClick={handleSubmitButton}
						>
							완료
						</Button>
					</div>
				</FormLayout>

				{isAlertVisible ? (
					<Alert
						title="가입내역이 없어요"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="홈으로"
						onConfirmButtonClick={goToLogin}
						isVisible={isAlertVisible}
					>
						<p>
							<Typo $typoType="b5" color="gray_8">
								가입내역이 존재하지 않습니다.
								<br />
								자세한 사항은 관리자에게 문의해 주세요.
							</Typo>
						</p>
					</Alert>
				) : null}
			</>
		);
	};

	const userIdSearchResultLayout = () => {
		return (
			<>
				<h1 className="title">
					<Typo $typoType="h1">{title}</Typo>
				</h1>
				<FormLayout
					isTitle={false}
					$status="search"
					className="search-result-form-layout"
				>
					<LoginSearchResultLayout notice>
						<div>
							<p>
								<Typo $typoType="h4" className="search-result-userinfo">
									회원님의 아이디는{' '}
									<span className="search-result-userid">{userId}</span>
									<br />
									(으)로 등록되어 있습니다 <br />
									등록일자는 {dayjs(createdAt).format('YYYY-MM-DD')}입니다.
								</Typo>
							</p>
						</div>
					</LoginSearchResultLayout>

					<div className="search-result-layout-button">
						<Button $isFull $textCenter onClick={goToLogin}>
							로그인하러 가기
						</Button>
						<Button
							$isFull
							$textCenter
							$buttonType={ButtonTypeType.UNDER_LINE}
							onClick={goToPasswordSearch}
						>
							비밀번호를 찾고 싶어요
						</Button>
					</div>
				</FormLayout>
			</>
		);
	};

	return (
		<UserIdSearchTemplateContainer>
			{isSubmit ? userIdSearchResultLayout() : userIdSearchDafaultLayout()}
		</UserIdSearchTemplateContainer>
	);
}

export default UserIdSearchTemplate;
