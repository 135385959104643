import React, { useContext, useState } from 'react';
import { ToastContext } from '../../../contexts/Toast';
import { axiosInstance } from '../../../network/axios';
import { blobFileDownload } from '../../../utils/file-util';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import DateRangePicker from '../../atoms/DateRangePicker';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';

const InspectionsExcelDownload = () => {
	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [managerName, setManagerName] = useState('');
	const [receptionChannel, setReceptionChannel] = useState('');
	const [customerName, setCustomerName] = useState('');

	const handleParams = () => {
		const params: {
			proxyInspectionReceptionRegistrationDateFrom: string;
			proxyInspectionReceptionRegistrationDateTo: string;
			rwManagerName?: string;
			proxyInspectionReceptionChannel?: string;
			customerName?: string;
		} = {
			proxyInspectionReceptionRegistrationDateFrom: dateFrom,
			proxyInspectionReceptionRegistrationDateTo: dateTo,
		};
		if (managerName) {
			params.rwManagerName = managerName;
		}
		if (receptionChannel) {
			params.proxyInspectionReceptionChannel = receptionChannel;
		}
		if (customerName) {
			params.customerName = customerName;
		}
		return params;
	};

	const downloadFile = async () => {
		setIsLoading(true);
		await axiosInstance
			.get(`/car-services/file-download/inspections`, {
				params: handleParams(),
				responseType: 'blob',
			})
			.then((response) => response.data)
			.then((blob) => {
				const fileName = `접수정보-[${dateFrom}]-[${dateTo}]-[담당자]${managerName}-[접수처]${receptionChannel}-[고객명]${customerName}.xlsx`;
				blobFileDownload(blob, fileName);
			})
			.catch((err) => {
				if (err.response && err.response.status !== 404) {
					toast('error', '요청을 처리할 수 없습니다.');
				}
				if (err.response && err.response.status === 404) {
					toast('error', '데이터가 없습니다.');
				}
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<ContentBoxWithHeader
			title="접수정보 다운로드"
			className="inner-content"
			borderRadius="10px"
		>
			<GridLayout $columnCount={3}>
				<GridItem title="날짜 선택" span={2}>
					<DateRangePicker
						name="dateRange"
						onChange={(dates, dateStrings) => {
							if (dateStrings) {
								setDateFrom(dateStrings[0]);
								setDateTo(dateStrings[1]);
							}
						}}
					/>
				</GridItem>
				<GridItem title="통화담당자">
					<Input
						name="managerName"
						placeholder="통화담당자"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={managerName}
						onChange={(e) => setManagerName(e.target.value)}
						onReset={() => setManagerName('')}
					/>
				</GridItem>
				<GridItem title="접수처">
					<Input
						name="receptionChannel"
						placeholder="접수처"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={receptionChannel}
						onChange={(e) => setReceptionChannel(e.target.value)}
						onReset={() => setReceptionChannel('')}
					/>
				</GridItem>
				<GridItem title="고객명">
					<Input
						name="customerName"
						placeholder="고객명"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={customerName}
						onChange={(e) => setCustomerName(e.target.value)}
						onReset={() => setCustomerName('')}
					/>
				</GridItem>
			</GridLayout>
			<Button
				size="large"
				$buttonType={ButtonTypeType.GREEN}
				onClick={downloadFile}
				disabled={isLoading}
				style={{ marginTop: '10px' }}
			>
				{!isLoading ? '다운로드 받기' : '다운로드 중..'}
			</Button>
		</ContentBoxWithHeader>
	);
};

export default InspectionsExcelDownload;
