import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse, List } from 'antd';
import { useGetVehicleDTGInfoQuery } from '../../../store/apis/vehicleConditions';
import { formatTimeToString } from '../../../utils/date-util';
import { DTGInfo } from '../../../interface/vehicleConditions';
import VehiclesMap from '../../../components/organisms/NaverMap/VehiclesMap';
import VehiclePolyline from '../../../components/organisms/NaverMap/VehiclePolyline';
import generatePathsAndPreviousSegments, {
	velocityRanges,
} from '../../../utils/paths-util';
import calculateOverallValue from '../../../utils/time-util';
import SectionLoading from '../../../components/organisms/SectionLoading';
import VelocityInfo from '../../../components/atoms/VelocityInfo';
import Typo from '../../../components/atoms/Typo';
import { vehicleStatus } from './DTGInfoPane';

interface VehiclePathMapProps {
	mapId: string | undefined;
	vehicleNumber: string;
	date: string;
	time: string;
}

const TimeContainer = styled.div`
	position: absolute;
	margin-left: 12px;
	top: 16px;
	left: 1px;
	bottom: 16px;
	z-index: 900;
`;

export const TimeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	border-radius: 8px;
	height: 100%;
	overflow-y: auto;
	cursor: pointer;
	position: relative;
	background: #fff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 5px 0 15px 0 rgba(0, 0, 0, 0.1);
`;

const MapWrapper = styled.div`
	flex: 1;
	position: relative;
	.view-point-button {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translate(-50%);
	}
	.content-wrapper {
		height: 100%;
	}
`;

export const MapOverlayContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
`;

const VehiclePathByDateAndTime = ({
	mapId,
	vehicleNumber,
	date,
	time,
}: VehiclePathMapProps) => {
	const [mapKey] = useState(mapId);
	const [dtgInfo, setDtgInfo] = useState<DTGInfo | null>(null);
	const { data, isLoading, isError, isFetching } = useGetVehicleDTGInfoQuery(
		{
			vehicleNumber,
			date,
			time,
		},
		{ skip: !time },
	);

	if (!time || isError) {
		return <VehiclesMap key={mapKey} />;
	}
	const pathList = data ? data.rows : [];
	const { pathsWithPreviousSegment } = generatePathsAndPreviousSegments(
		pathList,
		velocityRanges,
	);

	const items = pathsWithPreviousSegment.map((paths, index) => ({
		key: index.toString(),
		label: `
			구간 ${index + 1},
			속도: ${paths[0].velocityRange},
			시간: ${calculateOverallValue(paths)}
		`,
		children: (
			<List
				bordered={false}
				dataSource={paths}
				renderItem={(info) => (
					<List.Item
						onClick={() => {
							setDtgInfo({ vehicleNumber, boolAnyMobility: true, ...info });
						}}
					>
						<Typo
							color={info.s === 0 ? 'gray_11' : 'danger_5_main'}
							$typoType="b6"
						>
							{formatTimeToString(info.t)}{' '}
							{info.s ? (
								<>
									<br />
									<Typo $typoType="b7">{vehicleStatus(info.s)}</Typo>
								</>
							) : (
								''
							)}
						</Typo>
					</List.Item>
				)}
			/>
		),
	}));

	return (
		<MapWrapper>
			<TimeContainer>
				<TimeWrapper>
					{isLoading || isFetching ? (
						<SectionLoading />
					) : (
						<Collapse accordion items={items} />
					)}
				</TimeWrapper>
			</TimeContainer>

			<MapOverlayContainer>
				<VehiclePolyline
					mapId={mapKey}
					pathList={pathList}
					dtgInfo={dtgInfo}
					vehicleNumber={vehicleNumber}
					date={date}
					pathsWithPreviousSegment={pathsWithPreviousSegment}
				/>
				<VelocityInfo velocityRanges={velocityRanges} />
			</MapOverlayContainer>
		</MapWrapper>
	);
};

export default VehiclePathByDateAndTime;
