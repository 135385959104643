import React from 'react';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import { MONTHLY_SALES_DETAIL_PATH } from '../../../routes/constants/urls';
import {
	PAYMENT_STATUS_EXPECTATION_LABEL,
	PAYMENT_STATUS_EXPECTATION_VALUE,
	PAYMENT_STATUS_FAIL_LABEL,
	PAYMENT_STATUS_FAIL_VALUE,
	PAYMENT_STATUS_SUCCESS_LABEL,
	PAYMENT_STATUS_SUCCESS_VALUE,
} from '../../../utils/const';
import { createBadgeTextCellRender } from '../../../utils/row-data-util';
import userData from './gridData';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import { useGetPaymentsQuery } from '../../../store/apis/payment';

function MonthlySalesPage() {
	const { formInitialValues, colsList, forms } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetPaymentsQuery,
		detailPath: MONTHLY_SALES_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="매출 관리"
			gridTitle="매출 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createBadgeTextCellRender([
					{
						label: PAYMENT_STATUS_FAIL_LABEL,
						value: PAYMENT_STATUS_FAIL_VALUE,
						color: 'red',
					},
					{
						label: PAYMENT_STATUS_EXPECTATION_LABEL,
						value: PAYMENT_STATUS_EXPECTATION_VALUE,
						color: 'yellow',
					},
					{
						label: PAYMENT_STATUS_SUCCESS_LABEL,
						value: PAYMENT_STATUS_SUCCESS_VALUE,
						color: 'blue',
					},
				]),
			}}
		/>
	);
}

export default MonthlySalesPage;
