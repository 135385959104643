import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Carousel, Image } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Typo from '../../atoms/Typo';
import TabPane from '../../organisms/TabPane';
import TabPaneBox from '../../organisms/TabPane/TabPaneBox';
import TabPaneBoxItemValue, {
	GrayBox,
	GrayBoxList,
	GrayBoxListItem,
	TabPaneBoxItemRow,
	TabPaneBoxItemTitle,
} from '../../organisms/TabPane/TabPaneBoxItemValue';
import theme, { ButtonTypeType } from '../../../styles/theme';
import { BadgeColorType } from '../../atoms/Badge';
import Divider from '../../../styles/divider';
import Button from '../../atoms/Button';
import MemoModal from './MemoModal';
import AssignManagerModal from './AssignManagerModal';
import Icon from '../../atoms/Icon';
import {
	calendarIcon,
	circleArrowDisableIcon,
	circleArrowIcon,
	detailIcon,
} from '../../../assets/icon';
import ServiceDateModifyModal from './ServiceDateModifyModal';
import {
	ProductType,
	SubscriptionItemDto,
	SubscriptionItemStatusType,
} from '../../../interface/subscriptionItem';
import NaverMap from '../../organisms/NaverMap';
import { useSubscriptionItemManagerCancelMutation } from '../../../store/apis/subscriptionItem';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import Alert from '../../atoms/Alert';
import { popupUpdateHandler } from '../../../store/popup';
import { uploadLengthNullImage } from '../../../assets/images';
import {
	CUSTOMER_DETAIL_PATH,
	MANAGER_DETAIL_PATH,
} from '../../../routes/constants/urls';
import WorkImageModal from './WorkImageModal';
import useCheckRole from '../../../hooks/useCheckRole';
import { useCancelCarCarServiceMutation } from '../../../store/apis/carCareSubscription';

interface InspectionTabPaneProps {
	data: SubscriptionItemDto | null;
	refetch?: () => void;
}

const Inspection = styled.article`
	height: 100%;
	overflow-x: hidden;
`;

const AccordionWrapper = styled.div<{ height: number; $isOpen: boolean }>`
	height: ${({ height, $isOpen }) => ($isOpen ? height : 0)}px;
	transition: height 0.3s ease-in;
	overflow: hidden;
`;

const CenterDiv = styled.div`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled(CenterDiv)`
	padding-left: 6px;
`;

const StatusBar = styled.div<{ color: keyof typeof theme.common.colors }>`
	border-radius: 12px;
	padding: 8px 16px;
	text-align: center;
	background: ${({ color, theme: globalTheme }) =>
		globalTheme.common.colors[color]};
`;

const ImageCarousel = styled(Carousel)``;

const ImageCarouselButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	> button {
		cursor: pointer;
		background: none;
		background-image: url(${circleArrowIcon});
		background-position: center center;
		background-size: contain;
		border: none;
		outline: none;
		width: 24px;
		height: 24px;
		text-indent: -9999px;

		&:disabled {
			cursor: not-allowed;
			background-image: url(${circleArrowDisableIcon});
		}

		&.prev-button {
			transform: rotate(-180deg);
		}
	}
`;

const CarouselItem = styled.div`
	position: relative;
	height: 0;
	padding-top: calc(100% - 8px);
`;

const CarouselFigure = styled.figure`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-right: 8px;
	border-radius: 4px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const getStatusBadgeSet = (status?: SubscriptionItemStatusType) => {
	let content;
	let badgeColor: BadgeColorType;

	switch (status) {
		case 'REQUESTED':
			content = '작업요청';
			badgeColor = 'red';
			break;
		case 'ASSIGNED':
			content = '배정완료';
			badgeColor = 'blue';
			break;
		case 'COMPLETED':
			content = '작업완료';
			badgeColor = 'gray';
			break;

		default:
			content = '-';
			badgeColor = 'blue';
	}

	return { content, badgeColor };
};

const getProductTypeName = (productType?: ProductType) => {
	let content = '-';

	switch (productType) {
		case 'REGULAR_INSPECTION':
			content = '순회점검';
			break;
		case 'TIRE_REPLACEMENT':
			content = '타이어 교체';
			break;
		case 'INSPECTION_AGENCY':
			content = '검사대행';
			break;
		case 'EMERGENCY_RUSH':
			content = '긴급출동';
			break;
		default:
			content = '-';
	}

	return content;
};

const getDateFormat = (date?: string, format?: string) => {
	return date ? dayjs(date).format(format || 'YYYY.MM.DD HH:mm:ss') : '-';
};

function InspectionTabPane({ data, refetch }: InspectionTabPaneProps) {
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();

	const isReadCustomerRole = useCheckRole({
		roleType: 'isRead',
		roleName: '개인고객',
	});
	const isReadManagerRole = useCheckRole({
		roleType: 'isRead',
		roleName: '매니저',
	});
	const isUpdateRole = useCheckRole({
		roleType: 'isUpdate',
		roleName: '작업현황',
		includeSuper: true,
	});
	const isCreateRole = useCheckRole({
		roleType: 'isCreate',
		roleName: '작업현황',
		includeSuper: true,
	});
	const isSetManager = useCheckRole({
		roleType: 'isUpdate',
		roleName: '순회점검매니저배정',
		includeSuper: true,
	});
	const isUpdateService = useCheckRole({
		roleType: 'isUpdate',
		roleName: '서비스관리',
	});

	const [managerCancelAlertVisible, setManagerCancelAlertVisible] =
		useState(false);
	const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
	const [accordionItemHeight, setAccordionItemHeignt] = useState(0);
	const [isAbsolute, setIsAbsolute] = useState(false);
	const [memoModalOn, setMemoModalOn] = useState<boolean>(false);
	const [assignManagerModalOn, setAssignManagerModalOn] =
		useState<boolean>(false);
	const [serviceDateModifyModalOn, setServiceDateModifyModalOn] =
		useState<boolean>(false);
	const [selectedVisitDate, setSelectedVisitDate] = useState('');
	const [cancelAlert, setCancelAlert] = useState(false);

	const carouselBeforeRef = useRef<CarouselRef>(null);
	const carouselAfterRef = useRef<CarouselRef>(null);
	const [beforeImageIndex, setBeforeImageIndex] = useState(0);
	const [requestedImageIndex, setRequestedImageIndex] = useState(0);

	const [cancelManager] = useSubscriptionItemManagerCancelMutation();
	const [cancelService] = useCancelCarCarServiceMutation();

	const ref = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();

	const handleMemoModalOn = () => {
		setMemoModalOn(true);
	};

	const handleMemoModalClose = () => {
		setMemoModalOn(false);
	};

	const handleAssignManagerModalOn = () => {
		setAssignManagerModalOn(true);
	};

	const handelAssignManagerModalClose = () => {
		setAssignManagerModalOn(false);
	};

	const handleServiceDateModifyModalOn = () => {
		setServiceDateModifyModalOn(true);
	};

	const handleServiceDateModifyModalClose = () => {
		setServiceDateModifyModalOn(false);
	};

	const handleManagerCancel = async () => {
		if (!data) return;
		setManagerCancelAlertVisible(false);

		const res = await cancelManager(data?.subscriptionItemNo);

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			if (refetch) refetch();
			dispatch(popupUpdateHandler());
			setToast('info', '매니저 배정 취소가 완료되었습니다.');
		}
	};

	const handleServiceCancel = async () => {
		if (data?.subscriptionItemNo) {
			const res = await cancelService({
				subscriptionItemNo: data.subscriptionItemNo,
			});

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				if (refetch) refetch();
				dispatch(popupUpdateHandler());
				setToast('info', '서비스가 취소되었습니다.');
			}
		}
	};

	const handleManagerCancelClick = async () => {
		await handleManagerCancel();
	};

	const handleCarouselPrevClick = (refParam: CarouselRef | null) => {
		if (!refParam) return;

		refParam.prev();
	};
	const handleCarouselNextClick = (refParam: CarouselRef | null) => {
		if (!refParam) return;

		refParam.next();
	};

	const goTo = (route: string) => {
		navigate(route);
	};

	useEffect(() => {
		if (ref.current && data) {
			const { height } = ref.current.getBoundingClientRect();

			setAccordionItemHeignt(height);
		}

		if (data?.managerAssignedAt && data?.expectationVisitedAt) {
			setSelectedVisitDate(data.expectationVisitedAt);
		} else if (data?.firstVisitedAt) {
			setSelectedVisitDate(data.firstVisitedAt);
		}
	}, [data]);

	const renderStatusBar = () => {
		if (data?.subscriptionItemStatus === 'ASSIGNED') {
			if (data.subscriptionItemManagerStatus === 'PENDING') {
				return (
					<StatusBar color="warning_1">
						<Typo $typoType="hm10" color="gray_11">
							매니저가 ‘작업 전’ 상태입니다
						</Typo>
					</StatusBar>
				);
			}

			if (data.subscriptionItemManagerStatus === 'STARTED') {
				return (
					<StatusBar color="primary_1">
						<Typo $typoType="hm10" color="gray_11">
							매니저가 ‘작업 중’ 상태입니다
						</Typo>
					</StatusBar>
				);
			}

			if (data.subscriptionItemManagerStatus === 'COMPLETE') {
				return (
					<StatusBar color="gray_1">
						<Typo $typoType="hm10" color="gray_11">
							매니저가 ‘작업완료’ 상태입니다
						</Typo>
					</StatusBar>
				);
			}
		}

		return null;
	};

	const renderButtons = () => {
		if (!data || data?.subscriptionItemManagerStatus !== 'PENDING') return null;

		switch (data.subscriptionItemStatus) {
			case 'REQUESTED':
				return (
					<Button
						$textCenter
						onClick={handleAssignManagerModalOn}
						style={{ marginTop: '2px' }}
					>
						매니저 배정
					</Button>
				);

			case 'ASSIGNED':
				return (
					<>
						<Button
							$buttonType={ButtonTypeType.GHOST}
							$textCenter
							onClick={handleAssignManagerModalOn}
							style={{ marginTop: '10px' }}
						>
							매니저 변경
						</Button>
						<Button
							$buttonType={ButtonTypeType.UNDER_LINE}
							$textCenter
							onClick={() => setManagerCancelAlertVisible(true)}
							style={{
								padding: '2px 0 0',
							}}
						>
							매니저 배정 취소
						</Button>
					</>
				);

			default:
				return null;
		}
	};

	const renderModal = () => {
		if (!data) return null;

		if (memoModalOn) {
			return (
				<MemoModal
					isVisible
					onClose={handleMemoModalClose}
					onRefetch={() => {
						if (refetch) refetch();
					}}
					defaultValue={data.subscriptionMemo}
					id={data.subscriptionItemNo}
				/>
			);
		}

		if (assignManagerModalOn) {
			return (
				<AssignManagerModal
					isVisible
					onClose={handelAssignManagerModalClose}
					onRefetch={() => {
						if (refetch) refetch();
					}}
					defaultManagerIdList={
						data.managerId ? [String(data.managerId)] : undefined
					}
					defaultAsignedAt={selectedVisitDate}
					selectedItem={data}
				/>
			);
		}

		if (serviceDateModifyModalOn) {
			return (
				<ServiceDateModifyModal
					isVisible
					onClose={handleServiceDateModifyModalClose}
					onRefetch={() => {
						if (refetch) refetch();
					}}
					defaultValue={data.reservationVisitedAt}
					selectedId={data.subscriptionItemNo}
				/>
			);
		}

		return null;
	};

	return (
		<Inspection>
			<Alert
				title="서비스 취소"
				closeButtonClick={() => setCancelAlert(false)}
				onConfirmButtonText="취소하기"
				onConfirmButtonClick={handleServiceCancel}
				isVisible={cancelAlert}
			>
				서비스를 취소하시겠습니까? 매니저가 배정된 경우에는 직접 취소할 수
				없습니다.
			</Alert>
			<TabPane
				title="작업 내용"
				style={data ? { gap: '24px', height: 'auto' } : {}}
				isPlaceHolder={!data}
			>
				<TabPaneBox
					title="상세정보"
					rightAccessary={
						isUpdateService &&
						!isUpdateRole && (
							<Button
								size="x-small"
								$buttonType={ButtonTypeType.GHOST_DANGER}
								onClick={() => setCancelAlert(true)}
							>
								취소하기
							</Button>
						)
					}
				>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>차량번호</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={data?.vehicle.vehicleNumber}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>작업상태</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="badge"
							content={getStatusBadgeSet(data?.subscriptionItemStatus).content}
							badgeColor={
								getStatusBadgeSet(data?.subscriptionItemStatus).badgeColor
							}
						/>
					</TabPaneBoxItemRow>
					{renderStatusBar()}
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>고객명</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={
								<CenterDiv>
									{data?.customerName}
									{isReadCustomerRole && (
										<IconWrapper>
											<Icon
												src={detailIcon}
												width={20}
												height={20}
												onClick={() =>
													goTo(`${CUSTOMER_DETAIL_PATH}/${data?.customerId}`)
												}
											/>
										</IconWrapper>
									)}
								</CenterDiv>
							}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>서비스 유형</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={getProductTypeName(data?.productType)}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>작업내용</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="accordion"
							content={
								data?.tasks[0]
									? `${data.tasks[0].productName} 외 ${
											data.tasks.length - 1
									  } 건`
									: '-'
							}
							onClick={(value) => {
								if (value) {
									setIsAbsolute(false);
								}
								setIsAccordionOpen(value);
							}}
							defaultAccordionOpen={isAccordionOpen}
						/>
					</TabPaneBoxItemRow>

					<AccordionWrapper
						$isOpen={isAccordionOpen}
						height={accordionItemHeight}
						style={{ position: isAbsolute ? 'absolute' : 'static' }}
						onTransitionEnd={() => {
							if (!isAccordionOpen) {
								setIsAbsolute(true);
							}
						}}
					>
						<GrayBox ref={ref}>
							{data?.tasks?.filter((item) => item.taskType === 'SUBSCRIPTION')
								.length ? (
								<GrayBoxList title={data?.subscriptionItemName || '-'}>
									{data.tasks
										.filter((item) => item.taskType === 'SUBSCRIPTION')
										.map((item, idx) => (
											<GrayBoxListItem
												key={idx.toString()}
												content={
													<Typo $typoType="b5">• {item.productName}</Typo>
												}
											/>
										))}
								</GrayBoxList>
							) : null}
							{data?.tasks?.filter((item) => item.taskType === 'PRODUCT')
								.length ? (
								<GrayBoxList title="추가한 서비스">
									{data.tasks
										.filter((item) => item.taskType === 'PRODUCT')
										.map((item, idx) => (
											<GrayBoxListItem
												key={idx.toString()}
												rightAccessary={`${(
													item.taskCount * item.productPriceTax
												).toLocaleString()}원`}
												content={
													<Typo $typoType="b5">• {item.productName}</Typo>
												}
											/>
										))}
								</GrayBoxList>
							) : null}
						</GrayBox>
					</AccordionWrapper>

					{data?.tasks?.filter((item) => item.taskType === 'PRODUCT').length ? (
						<TabPaneBoxItemRow>
							<TabPaneBoxItemTitle>추가금액</TabPaneBoxItemTitle>
							<TabPaneBoxItemValue
								type="text"
								content={`${
									data?.tasks
										? data?.tasks
												?.filter((item) => item.taskType === 'PRODUCT')
												.reduce(
													(acc, current) =>
														acc + current.productPriceTax * current.taskCount,
													0,
												)
												.toLocaleString()
										: 0
								}원`}
							/>
						</TabPaneBoxItemRow>
					) : null}
					<Divider $verticalGap={10} />
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>서비스 희망 일시</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={getDateFormat(
								data?.firstVisitedAt,
								'YYYY.MM.DD(dd) A hh:mm',
							)}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>차량 위치</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue type="text" content={data?.street} />
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						{data && (
							<div style={{ width: '100%', height: '160px' }}>
								<NaverMap
									mapId="staticMap"
									markerList={[data]}
									selectedItem={data}
									isStatic
									hasZoomController={false}
								/>
							</div>
						)}
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>차량 위치 사진</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={
								<ImageCarouselButtonContainer>
									<button
										className="prev-button"
										type="button"
										onClick={() =>
											handleCarouselPrevClick(carouselAfterRef.current)
										}
										disabled={requestedImageIndex === 0}
									>
										prev
									</button>
									<button
										className="next-button"
										type="button"
										onClick={() =>
											handleCarouselNextClick(carouselAfterRef.current)
										}
										disabled={
											data?.requestedMedias === null ||
											(data?.requestedMedias &&
												data.requestedMedias.length - 4 < 0) ||
											requestedImageIndex ===
												(data?.requestedMedias &&
													data.requestedMedias.length - 4)
										}
									>
										next
									</button>
								</ImageCarouselButtonContainer>
							}
						/>
					</TabPaneBoxItemRow>
					<ImageCarousel
						slidesToShow={4}
						dots={false}
						ref={carouselAfterRef}
						infinite={false}
						afterChange={(e) => setRequestedImageIndex(e)}
					>
						{data?.requestedMedias &&
							data.requestedMedias.map((item, idx) => (
								<CarouselItem key={idx.toString()}>
									<CarouselFigure>
										<Image
											src={item}
											alt="차량 위치 사진"
											width="100%"
											height="100%"
										/>
									</CarouselFigure>
								</CarouselItem>
							))}
					</ImageCarousel>
					{(data?.requestedMedias === null ||
						!data?.requestedMedias.length) && (
						<img src={uploadLengthNullImage} alt="upload-img-null" />
					)}
					<Divider $verticalGap={10} />
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>작업 사진</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={
								<ImageCarouselButtonContainer>
									<button
										className="prev-button"
										type="button"
										onClick={() =>
											handleCarouselPrevClick(carouselBeforeRef.current)
										}
										disabled={beforeImageIndex === 0}
									>
										prev
									</button>
									<button
										className="next-button"
										type="button"
										onClick={() =>
											handleCarouselNextClick(carouselBeforeRef.current)
										}
										disabled={
											data?.beforeMedias === null ||
											(data?.beforeMedias &&
												data.beforeMedias.length - 4 < 0) ||
											beforeImageIndex ===
												(data?.beforeMedias && data.beforeMedias.length - 4)
										}
									>
										next
									</button>
								</ImageCarouselButtonContainer>
							}
						/>
					</TabPaneBoxItemRow>
					<ImageCarousel
						slidesToShow={4}
						dots={false}
						ref={carouselBeforeRef}
						infinite={false}
						afterChange={(e) => setBeforeImageIndex(e)}
					>
						{data?.beforeMedias &&
							data.beforeMedias.map((item, idx) => (
								<CarouselItem key={idx.toString()}>
									<CarouselFigure>
										<Image
											src={item}
											alt="작업 사진"
											width="100%"
											height="100%"
										/>
									</CarouselFigure>
								</CarouselItem>
							))}
					</ImageCarousel>
					{data?.beforeMedias === null && (
						<img src={uploadLengthNullImage} alt="upload-img-null" />
					)}
					{isUpdateRole && (
						<TabPaneBoxItemRow>
							<TabPaneBoxItemTitle />
							<TabPaneBoxItemValue
								type="text"
								content={
									<WorkImageModal
										id={
											data?.subscriptionItemNo ? data?.subscriptionItemNo : ''
										}
										beforeMedias={data?.beforeMedias ? data.beforeMedias : []}
										refetch={refetch}
									/>
								}
							/>
						</TabPaneBoxItemRow>
					)}
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>업데이트 일시</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={getDateFormat(data?.updatedAt, 'YYYY.MM.DD(dd) A hh:mm')}
						/>
					</TabPaneBoxItemRow>
				</TabPaneBox>
				<TabPaneBox title="매니저 배정">
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>매니저 배정 일시</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={getDateFormat(
								data?.managerAssignedAt,
								'YYYY.MM.DD(dd) A hh:mm',
							)}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>서비스 확정 일시</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={
								<CenterDiv>
									{getDateFormat(
										data?.reservationVisitedAt,
										'YYYY.MM.DD(dd) A hh:mm',
									)}

									{!!data?.reservationVisitedAt &&
										data?.subscriptionItemManagerStatus === 'PENDING' && (
											<IconWrapper>
												<Icon
													src={calendarIcon}
													width={20}
													height={20}
													onClick={handleServiceDateModifyModalOn}
												/>
											</IconWrapper>
										)}
								</CenterDiv>
							}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>작업완료 확정 일시</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={getDateFormat(
								data?.completeConfirmedAt,
								'YYYY.MM.DD(dd) A hh:mm',
							)}
						/>
					</TabPaneBoxItemRow>
					<TabPaneBoxItemRow>
						<TabPaneBoxItemTitle>매니저</TabPaneBoxItemTitle>
						<TabPaneBoxItemValue
							type="text"
							content={
								<CenterDiv>
									{data?.managerName ?? '-'}

									{data?.managerName && isReadManagerRole && (
										<IconWrapper>
											<Icon
												src={detailIcon}
												width={20}
												height={20}
												onClick={() =>
													goTo(`${MANAGER_DETAIL_PATH}/${data?.managerId}`)
												}
											/>
										</IconWrapper>
									)}
								</CenterDiv>
							}
						/>
					</TabPaneBoxItemRow>
					{isSetManager && renderButtons()}
				</TabPaneBox>
				<TabPaneBox
					title="관리자 메모"
					rightAccessary={
						isCreateRole && (
							<Button
								$buttonType={ButtonTypeType.GHOST}
								size="x-small"
								onClick={handleMemoModalOn}
							>
								메모작성
							</Button>
						)
					}
				>
					{data?.subscriptionMemo || '-'}
				</TabPaneBox>
			</TabPane>
			{isUpdateRole && renderModal()}
			{managerCancelAlertVisible && isUpdateRole && (
				<Alert
					title="매니저 배정 취소"
					closeButtonClick={() => setManagerCancelAlertVisible(false)}
					onConfirmButtonText="취소하기"
					onConfirmButtonClick={handleManagerCancelClick}
					isVisible={managerCancelAlertVisible}
				>
					매니저 배정을 취소하시겠습니까?
				</Alert>
			)}
		</Inspection>
	);
}

export default InspectionTabPane;
