import {
	CustomerSubscriptionDto,
	SubscriptionDto,
} from '../../../interface/subscription';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const subscriptionsApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptions: builder.query<
			ListResponse<SubscriptionDto>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/admin/subscriptions`,
				params: args,
			}),
		}),
		getSubscriptionDetail: builder.query<
			ObjectResponse<SubscriptionDto>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions/${subscriptionNo}`,
			}),
		}),
		getSubscriptionDetailByAccountId: builder.query<
			ObjectResponse<CustomerSubscriptionDto>,
			{ accountId: number | undefined }
		>({
			query: ({ accountId }) => ({
				method: 'GET',
				url: `/members/admin/subscriptions/${accountId}/current`,
			}),
		}),
		updateSubscriptionStart: builder.mutation<
			ObjectResponse<CustomerSubscriptionDto>,
			{ subscriptionNo: string; body: { startedAt?: string } }
		>({
			query: ({ subscriptionNo, body }) => ({
				method: 'POST',
				url: `/members/admin/subscriptions/${subscriptionNo}/subscribe-start`,
				body,
			}),
		}),
	}),
});

export const {
	useGetSubscriptionsQuery,
	useGetSubscriptionDetailQuery,
	useGetSubscriptionDetailByAccountIdQuery,
	useUpdateSubscriptionStartMutation,
} = subscriptionsApiSlice;

export const getSubscriptionDetailViaId = async (id: string) => {
	const url = `/members/admin/subscriptions/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<SubscriptionDto>>(
		url,
	);

	return data.row;
};

export const getSubscriptionDetailVidCustomerAccountId = async (
	accountId: number,
) => {
	const url = `/members/admin/subscriptions/${accountId}/current`;

	const { data } = await axiosInstance.get<
		ObjectResponse<CustomerSubscriptionDto>
	>(url);

	return data.row;
};
