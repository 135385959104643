import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { popupUpdateHandler } from '../../../../store/popup';
import { ToastContext } from '../../../../contexts/Toast';
import { CustomErrorType } from '../../../../store/apis/@types';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import {
	useGetSubscriptionDetailQuery,
	useUpdateSubscriptionStartMutation,
} from '../../../../store/apis/subscription';
import Typo from '../../../../components/atoms/Typo';
import Alert from '../../../../components/atoms/Alert';
import CarCareDetail from '../../../../components/templates/CarCareTemplate/CarCareDetail';

function PurchaseDetailPage() {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();

	const { subscriptionNo } = useParams();
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [startedAt] = useState(dayjs().toISOString());

	const { data } = useGetSubscriptionDetailQuery({ subscriptionNo });
	const [updateSubscriptionStart] = useUpdateSubscriptionStartMutation();

	const goBack = () => {
		navigate(-1);
	};

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubscribeStartClick = async () => {
		if (subscriptionNo) {
			const res = await updateSubscriptionStart({
				subscriptionNo,
				body: { startedAt },
			});

			if (!('error' in res)) {
				handleAlertClose();
				toast('info', '구독이 시작되었습니다');
				dispatch(popupUpdateHandler());
			}
			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				handleAlertClose();
				toast('error', errorMessage);
			}
		}
	};

	const renderAlertOpen = () => {
		return (
			<Alert
				title="구독시작 설정"
				closeButtonClick={handleAlertClose}
				onConfirmButtonClick={handleSubscribeStartClick}
				onConfirmButtonText="구독 시작하기"
				isVisible={isAlertOpen}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						결제가 완료되었는지 한번 더 확인해주세요. 해당 구독을 시작상태로
						변경하시겠습니까?
					</Typo>
				</p>
			</Alert>
		);
	};

	const detailData = data && data.row;

	return (
		<GridDetailTemplate detailTitle="구독내역 상세" onBack={goBack}>
			<CarCareDetail
				detailData={detailData}
				handleAlertOpen={handleAlertOpen}
			/>
			{/* 구독시작 Alert */}
			{isAlertOpen && renderAlertOpen()}
		</GridDetailTemplate>
	);
}

export default PurchaseDetailPage;
