import React, { PropsWithChildren, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import ContentBox, { ContentBoxProps } from '../../atoms/ContentBox';
import Typo from '../../atoms/Typo';
import { ThemeType } from '../../../styles/theme';
import ToolTip from '../../atoms/Tooltip';

interface ContentBoxWithHeaderProps extends ContentBoxProps {
	title: string;
	innerClassName?: string;
	rightAccessory?: ReactNode;
	id?: string;
	essential?: boolean;
	tooltip?: string;
}

const ContentBoxWithHeaderContainer = styled.div<ContentBoxProps>`
	.content-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: ${({ $isCompact }) => ($isCompact ? '12px' : '16px')};
	}
	.grid-content-wrapper {
		padding: ${({ $isCompact }) => ($isCompact ? '12px' : '16px')};
	}

	.min-height-standard {
		min-height: 200px;
		margin-bottom: 0.5rem;
	}

	.min-height-panel {
		min-height: 400px;
	}
`;

const StyledContentBox = styled(ContentBox)``;

const Essential = styled.span<{ theme: ThemeType }>`
	color: ${({ theme }) => theme.common.colors.danger_5_main};
	margin-left: 2px;
`;

const ContentBoxWithHeader = forwardRef<
	HTMLDivElement,
	PropsWithChildren<ContentBoxWithHeaderProps>
>(
	(
		{
			className,
			innerClassName = '',
			children,
			title,
			rightAccessory,
			$isCompact,
			id,
			essential = false,
			tooltip,
			...rest
		},
		ref,
	) => {
		return (
			<ContentBoxWithHeaderContainer
				className={className}
				$isCompact={$isCompact}
				id={id}
				ref={ref}
			>
				<StyledContentBox className={innerClassName} {...rest}>
					<div className="content-header">
						<h2>
							<Typo $typoType={!$isCompact ? 'h5' : 'h7'}>{title}</Typo>
							{essential ? <Essential>*</Essential> : <p> </p>}
							{tooltip && <ToolTip title={tooltip || ''} />}
						</h2>
						{rightAccessory && rightAccessory}
					</div>
					{children}
				</StyledContentBox>
			</ContentBoxWithHeaderContainer>
		);
	},
);

export default ContentBoxWithHeader;
