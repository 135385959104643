import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import Typo from '../../../../components/atoms/Typo';
import { phoneFormat } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_BADGE_TEXT,
	CELL_TYPE_DETAIL_ACTION,
} from '../../../../utils/row-data-util';
import { CANCEL_STATE_OPTIONS } from '../../../../utils/const';

const renderServiceCell = ({ value }: ICellRendererParams) => {
	return value ? (
		<Typo color="danger_5_main">&#8226; 존재</Typo>
	) : (
		<Typo color="gray_11">&#8226; 없음</Typo>
	);
};

const userData: GridDataModel = {
	formInitialValues: { subscriptionType: 'SUBSCRIPTION' },
	colsList: [
		{
			tableInfo: {
				field: 'cancelRequestedAt',
				headerName: '등록일자',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'cancelApprovedAt',
				headerName: '해지승인 일자',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'cancelConfirmedAt',
				headerName: '해지완료 일자',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'haveSubscription',
				headerName: '진행서비스',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: renderServiceCell,
			},
		},
		{
			tableInfo: {
				field: 'cancelStatus',
				headerName: '상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_BADGE_TEXT,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionProductName',
				headerName: '구독 상품명',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '휴대폰 번호',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'startedAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'customerName',
			label: '고객명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'subscriptionCancelStatus',
			label: '상태',
			type: FilterElementType.SELECT,
			options: CANCEL_STATE_OPTIONS,
		},
		{
			key: 'subscriptionProductName',
			label: '구독 상품명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerPhone',
			label: '휴대폰 번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'vehicleNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
