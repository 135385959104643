import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
	error404Image,
	error500Image,
	error503Image,
} from '../../assets/images';
import Button from '../../components/atoms/Button';
import Typo from '../../components/atoms/Typo';
import DefaultLayout from '../../components/organisms/DefaultLayout';
import { resetAuth } from '../../store/auth';
import { persistor } from '../../index';

interface ErrorPageProps {
	$status?: number;
}

const Main = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.common.colors.gray_1};
	text-align: center;
`;

const Image = styled.img`
	width: 320px;
	height: 320px;
`;

function ErrorPage({ $status }: ErrorPageProps) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const renderErrorStatus = (statusCode: number) => {
		switch (statusCode) {
			case 401:
				return (
					<Main>
						<Typo $typoType="hm7">만료된 토큰입니다. 다시 로그인해주세요.</Typo>
						<div>
							<Button
								onClick={async () => {
									await persistor.purge();
									dispatch(resetAuth);
									navigate('/login');
								}}
							>
								로그인 화면으로 이동
							</Button>
						</div>
					</Main>
				);
			case 403:
				return (
					<Main>
						<Typo $typoType="hm7">
							권한이 없습니다.
							<br /> 권한을 확인하거나 다시 로그인해주세요.
						</Typo>
						<div>
							<Button
								onClick={async () => {
									await persistor.purge();
									dispatch(resetAuth);
									navigate('/login');
								}}
							>
								로그인 화면으로 이동
							</Button>
						</div>
					</Main>
				);
			case 404:
				return (
					<Main>
						<Image src={error404Image} alt="404-image" />
						<Typo $typoType="hm7">
							찾을 수 없는 페이지 입니다
							<br /> 요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요
						</Typo>
						<div>
							<Button onClick={() => navigate('/')}>홈으로 이동</Button>
						</div>
					</Main>
				);
			case 500:
				return (
					<Main>
						<Image src={error500Image} alt="500-image" />
						<Typo $typoType="hm7">페이지가 작동하지 않습니다</Typo>
						<div>
							<Button onClick={() => navigate(-1)}>새로고침</Button>
						</div>
					</Main>
				);
			case 502:
				return (
					<Main>
						<Image src={error503Image} alt="503-image" />
						<Typo $typoType="hm7">서버에 문제가 발생하였습니다</Typo>
						<div>
							<Button onClick={() => navigate(-1)}>다시시도</Button>
						</div>
					</Main>
				);
			case 503:
				return (
					<Main>
						<Image src={error503Image} alt="503-image" />
						<Typo $typoType="hm7">서버 점검중입니다</Typo>
					</Main>
				);
			default:
				return null;
		}
	};

	return (
		<DefaultLayout>
			{$status
				? renderErrorStatus($status)
				: renderErrorStatus(params?.status ? Number(params.status) : 500)}
		</DefaultLayout>
	);
}

export default ErrorPage;
