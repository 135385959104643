import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';
import {
	GenerateTelephoneInquiryDto,
	ModifyTelephoneInquiryDto,
	TelephoneInquiryDto,
} from '../../../interface/telephoneInquiry';
import { LabelValue } from '../../../utils/const';

const telephoneInquiryApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createTelephoneInquiry: builder.mutation<
			ObjectResponse<TelephoneInquiryDto>,
			GenerateTelephoneInquiryDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/telephone-inquiries`,
				body,
			}),
		}),
		updateTelephoneInquiry: builder.mutation<
			ObjectResponse<TelephoneInquiryDto>,
			{ id: number; body: ModifyTelephoneInquiryDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/telephone-inquiries/${id}`,
				body,
			}),
		}),
	}),
});

export const {
	useCreateTelephoneInquiryMutation,
	useUpdateTelephoneInquiryMutation,
} = telephoneInquiryApiSlice;

export const getTelephoneCategory = async () => {
	const url = `/members/telephone-inquiries/categories`;

	const { data } = await axiosInstance.get<ListResponse<LabelValue>>(url);

	return data.rows;
};

export const getTelephoneInquiryViaId = async (id: number | string) => {
	const url = `/members/telephone-inquiries/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<TelephoneInquiryDto>>(
		url,
	);

	return data.row;
};
