import React from 'react';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import {
	createDetailAction,
	createBadgeTextCellRender,
} from '../../../utils/row-data-util';
import { VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import vehicleSubscriptionData from './gridData';
import { useGetVSCancelListQuery } from '../../../store/apis/vehicleSubscription';
import {
	CANCEL_APPROVED_VALUE,
	CANCEL_APPROVED_LABEL,
	CANCEL_ENDED_LABEL,
	CANCEL_ENDED_VALUE,
	CANCEL_APPLY_LABEL,
	CANCEL_APPLY_VALUE,
} from '../../../utils/const';

const VSCancellationManagement = () => {
	const { formInitialValues, colsList, forms } = vehicleSubscriptionData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleDetailClick,
		handleSearchSubmit,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetVSCancelListQuery,
		detailPath: VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="구독 해지관리"
			gridTitle="구독 해지관리"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			onSearchSubmit={handleSearchSubmit}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'subscriptionNo'),
				...createBadgeTextCellRender([
					{
						label: CANCEL_APPLY_LABEL,
						value: CANCEL_APPLY_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_APPROVED_LABEL,
						value: CANCEL_APPROVED_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_ENDED_LABEL,
						value: CANCEL_ENDED_VALUE,
						color: 'gray',
					},
				]),
			}}
		/>
	);
};

export default VSCancellationManagement;
