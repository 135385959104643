import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Select, Input } from 'antd';
import Button from '../../../../components/atoms/Button';
import { ButtonTypeType } from '../../../../styles/theme';
import Typo from '../../../../components/atoms/Typo';
import Alert from '../../../../components/atoms/Alert';
import {
	MANAGER_STATE_OPTIONS,
	MANAGER_STATE_SUSPENSION_VALUE,
} from '../../../../utils/const';
import { ToastContext } from '../../../../contexts/Toast';
import { CustomErrorType } from '../../../../store/apis/@types';
import { useUpdateManagerStateMutation } from '../../../../store/apis/manager';
import Modal from '../../../../components/atoms/Modal';

interface EditModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: { statusChangedReason: string; status: string };
	userId: string;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormLabel = styled.p`
	${({ theme }) => theme.typo.label1};
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

function EditModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	userId,
}: EditModalProps) {
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [content, setContent] = useState(defaultValue.statusChangedReason);
	const [stateStatus, setStateStatus] = useState(defaultValue.status);
	const [updateManagerState] = useUpdateManagerStateMutation();

	const setToast = useContext(ToastContext);

	const handleModalClose = () => {
		onClose();
		setContent('');
		setStateStatus(defaultValue.status);
	};

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubmit = async () => {
		const result = await updateManagerState({
			id: userId,
			body: { managerStatus: stateStatus, statusChangedReason: content },
		});

		if (!('error' in result) && result.data.row.updateResult) {
			setToast('info', '매니저상태 변경이 완료되었습니다.');
			setIsAlertOpen(false);
			if (onRefetch) onRefetch();
			if (onClose) onClose();
		} else if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
			setIsAlertOpen(false);
		}

		setContent('');
	};

	useEffect(() => {
		setContent('');
	}, [stateStatus]);

	const renderContent = () => {
		return (
			<>
				<FormItem
					name="userStatus"
					label={<FormLabel>매니저상태</FormLabel>}
					rules={[{ required: true, message: '' }]}
					colon={false}
				>
					<Select
						defaultValue={defaultValue.status}
						value={stateStatus}
						onChange={(e) => setStateStatus(e)}
					>
						{MANAGER_STATE_OPTIONS.filter((item) => item.value).map(
							(option) => (
								<Select.Option key={option.label} value={option.value}>
									{option.label}
								</Select.Option>
							),
						)}
					</Select>
				</FormItem>
				{stateStatus === MANAGER_STATE_SUSPENSION_VALUE && (
					<FormItem
						name="reason"
						label={<FormLabel>제한사유</FormLabel>}
						rules={[{ required: true, message: '제한사유를 입력해 주세요' }]}
						colon={false}
					>
						<Input.TextArea
							style={{ minHeight: '200px', fontSize: 16 }}
							placeholder="제한사유를 입력해 주세요"
							value={content}
							defaultValue={defaultValue.statusChangedReason}
							onChange={(e) => setContent(e.target.value)}
						/>
					</FormItem>
				)}
			</>
		);
	};

	return (
		<>
			<Modal
				title={<ModalTitle>매니저상태 변경</ModalTitle>}
				open={isVisible}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button onClick={handleAlertOpen}>완료</Button>
					</ModalFooterWrapper>
				}
			>
				<Form>{renderContent()}</Form>
			</Modal>

			{isAlertOpen && (
				<Alert
					title="매니저 상태"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={handleSubmit}
					onConfirmButtonText="변경하기"
					isVisible={isAlertOpen}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							매니저 상태를 변경하시겠습니까?
						</Typo>
					</p>
				</Alert>
			)}
		</>
	);
}

export default EditModal;
