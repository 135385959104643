import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GridApi } from 'ag-grid-community';
import ContentBox, {
	ContentBoxProps,
} from '../../../../../components/atoms/ContentBox';
import Grid from '../../../../../components/atoms/Grid';
import { CarServiceBadCaseItem } from '../../../../../interface/carService';
import { ColSize, TableColumn } from '../../../../../utils/grid-util';
import { ButtonTypeType } from '../../../../../styles/theme';
import Button from '../../../../../components/atoms/Button';
import Input from '../../../../../components/atoms/Input';
import Modal from '../../../../../components/atoms/Modal';
import {
	useGetCarServiceDashboadInspectionCallsByInspectionIdQuery,
	useGetCarServiceDashboardInspectionsByInspectionIdQuery,
} from '../../../../../store/apis/carService';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../../components/molecules/GridLayout';
import TextArea from '../../../../../components/atoms/TextArea';
import { INSPECTION_RECEPTION_STATE_CODE } from '../../../../../utils/const';
import { numberWithCommas } from '../../../../../utils/data-format';

interface ReportDataTableProps {
	dashboardStackDate: string;
	dashboardStackTitle: string;
	listData: CarServiceBadCaseItem[] | undefined;
}

interface ButtonCellRendererParams extends ReportDataTableProps {
	data: CarServiceBadCaseItem;
}

type CarServiceInspectionItem = {
	carLicensePlateNumber: string;
	proxyInspectionReceptionRegistrationDate: string;
	modifyCarLicensePlateNumber: string;
	carInspectionRestrictedDate: string;
	carName: string;
	carAgeRestrictedDate: string;
	proxyInspectionReceptionChannel: string;
	customerName: string;
	customerCompanyManagerName: string;
	modifyCustomerAddress: string;
	confirmationPhoneNumber: string;
	proxyInspectionReceptionStateCode: string;
	proxyInspectionReceptionCancellationDate: string;
	proxyInspectionReceptionCancellationReason: string;
	publicCorporationInspectionResultDate: string;
	publicCorporationInspectionResult: string;
	publicCorporationInspectionCarMileage: string;
	carInspectionType: string;
};

type Field = {
	key: string;
	title: string;
	size?: number;
	name: keyof CarServiceInspectionItem;
	span: number;
	value?: string | number;
	unitText?: string;
	textAlign?: 'left' | 'right' | 'center';
};

const InnerContentHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 1rem;

	.input-wrapper {
		width: 250px;
	}
`;

const GridContentTemplateContainer = styled.div<ContentBoxProps>`
	display: flex;
	flex-direction: column;

	.content-wrapper {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border: none;
	}

	.inner-content-grid {
		flex: 1;
	}

	.grid-content-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.grid-content {
		flex: 1;
		margin-bottom: 16px;
	}

	.grid-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-pagination {
		display: flex;
		align-items: center;
	}
	.grid-total-count {
		display: flex;
		gap: 8px;
	}
`;

const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const StyledModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-header {
		padding: 16px 0 8px 0;
	}
	.ant-modal-footer {
		padding: 0px;
	}
`;

const SelectionGridWrapper = styled.div`
	width: 100%;
	height: 100dvh;
`;

const ModalContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 1rem;
`;

const ModalLeft = styled.div`
	width: 40%;
`;

const ModalRight = styled.div`
	width: 60%;
`;

const GridInputItem = ({
	title,
	size,
	span,
	name,
	value,
	unitText,
	textAlign,
}: Omit<Field, 'key'>) => (
	<GridItem title={title} size={size} $isCompact span={span}>
		<Input
			name={name}
			type="text"
			value={value || ''}
			onChange={() => {}}
			$isCompact
			unitText={unitText}
			$textAlign={textAlign}
		/>
	</GridItem>
);

function ButtonCellRenderer({
	setIsDetailModal,
	setSelectedRowId,
	data,
}: {
	setIsDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedRowId: React.Dispatch<React.SetStateAction<string | null>>;
	data: CarServiceBadCaseItem;
}) {
	const proxyInspectionReceptionId = data?.proxyInspectionReceptionId;

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Button
				size="x-small"
				$buttonType={ButtonTypeType.GHOST}
				onClick={() => {
					setIsDetailModal(true);
					setSelectedRowId(data?.proxyInspectionReceptionId ?? '');
				}}
				disabled={!proxyInspectionReceptionId}
			>
				상세보기
			</Button>
		</div>
	);
}

const ReportDataTable = ({
	dashboardStackDate,
	dashboardStackTitle,
	listData,
}: ReportDataTableProps) => {
	const [isDetailModal, setIsDetailModal] = useState(false);
	const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

	const columns: TableColumn[] = [
		{
			headerName: '상세보기',
			field: 'detail',
			minWidth: ColSize.CW_X_SMALL,
			pinned: 'right',
			// eslint-disable-next-line react/no-unstable-nested-components
			cellRenderer: (params: ButtonCellRendererParams) => (
				<ButtonCellRenderer
					setIsDetailModal={setIsDetailModal}
					setSelectedRowId={setSelectedRowId}
					data={params.data}
				/>
			),
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
		},
		{
			headerName: '검사접수일',
			field: 'proxyInspectionReceptionRegistrationDate',
			getQuickFilterText: () => '',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '차량번호',
			field: 'carLicensePlateNumber',
			filter: 'agTextColumnFilter',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '검사만료일',
			field: 'carInspectionRestrictedDate',
			getQuickFilterText: () => '',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '검출유형',
			field: 'reasonTitle',
			getQuickFilterText: () => '',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '중단취소사유',
			field: 'proxyInspectionReceptionCancellationReason',
			getQuickFilterText: () => '',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '중단취소일',
			field: 'proxyInspectionReceptionCancellationDate',
			getQuickFilterText: () => '',
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
		{
			headerName: '메모',
			field: 'proxyInspectionReceptionMemo',
			getQuickFilterText: () => '',
			minWidth: ColSize.CW_LONG_TEXT,
			flex: 1,
			onCellClicked: (params) => {
				setSelectedRowId(params.data.proxyInspectionReceptionId);
				if (params.data.proxyInspectionReceptionId) {
					setIsDetailModal(true);
				}
			},
			editable: false,
		},
	];

	const callDetailColumns = [
		{
			headerName: '통화일',
			field: 'proxyInspectionCustomerCallDate',
			editable: false,
		},
		{
			headerName: '통화내용',
			field: 'proxyInspectionCustomerCallContents',
			minWidth: 500,
			editable: false,
		},
		{
			headerName: '결과',
			field: 'proxyInspectionCustomerCallResult',
			editable: false,
		},
		{
			headerName: '연락처',
			field: 'proxyInspectionCustomerPhoneNumber',
			minWidth: ColSize.CW_MEDIUM,
			editable: false,
		},
		{
			headerName: '고객명',
			field: 'proxyInspectionCustomerName',
			editable: false,
		},
	];

	const [gridApi, setGridApi] = useState<GridApi>();
	const [quickSearchText, setQuickSearchText] = useState('');

	const handleQuickSearchText = useCallback(() => {
		gridApi?.updateGridOptions({ quickFilterText: quickSearchText });
	}, [gridApi, quickSearchText]);

	const resetQuickFilterText = useCallback(() => {
		handleQuickSearchText();
	}, [handleQuickSearchText]);

	useEffect(() => {
		if (quickSearchText === '' || !quickSearchText) {
			resetQuickFilterText();
		}
	}, [quickSearchText, resetQuickFilterText]);

	const { data: inspectionsByIdData } =
		useGetCarServiceDashboardInspectionsByInspectionIdQuery({
			inspectionId: selectedRowId as string,
		});

	const inspectionsById = inspectionsByIdData?.row;

	const { data: inspectionsCallbyIdData } =
		useGetCarServiceDashboadInspectionCallsByInspectionIdQuery({
			inspectionId: selectedRowId as string,
		});

	const inspectionsCallList = inspectionsCallbyIdData?.rows;

	const statusLabel = INSPECTION_RECEPTION_STATE_CODE.find(
		(el) => el.value === inspectionsById?.proxyInspectionReceptionStateCode,
	);

	const topFields: Field[] = [
		{
			key: 'carLicensePlateNumber',
			title: '차량번호',
			name: 'carLicensePlateNumber',
			span: 2,
		},
		{
			key: 'proxyInspectionReceptionRegistrationDate',
			title: '접수요청일',
			name: 'proxyInspectionReceptionRegistrationDate',
			span: 2,
		},
		{
			key: 'modifyCarLicensePlateNumber',
			title: '변경번호',
			name: 'modifyCarLicensePlateNumber',
			span: 2,
		},
		{
			key: 'carInspectionRestrictedDate',
			title: '검사만료일',
			name: 'carInspectionRestrictedDate',
			span: 2,
		},
		{
			key: 'carName',
			title: '차량모델',
			name: 'carName',
			span: 2,
		},
		{
			key: 'carAgeRestrictedDate',
			title: '차량만료일',
			name: 'carAgeRestrictedDate',
			span: 2,
		},
		{
			key: 'proxyInspectionReceptionChannel',
			title: '접수처',
			name: 'proxyInspectionReceptionChannel',
			span: 4,
		},
		{
			key: 'customerName',
			title: '고객명',
			name: 'customerName',
			span: 4,
		},
		{
			key: 'customerCompanyManagerName',
			title: '법인담당자',
			name: 'customerCompanyManagerName',
			span: 4,
		},
		{
			key: 'modifyCustomerAddress',
			title: '확정주소',
			name: 'modifyCustomerAddress',
			span: 4,
		},
		{
			key: 'confirmationPhoneNumber',
			title: '확정연락처',
			name: 'confirmationPhoneNumber',
			span: 2,
		},
		{
			key: 'proxyInspectionReceptionStateCode',
			title: '진행상태',
			name: 'proxyInspectionReceptionStateCode',
			span: 2,
			value: statusLabel?.label,
		},
	];

	const bottomFields: Field[] = [
		{
			key: 'proxyInspectionReceptionCancellationDate',
			title: '불가고보일',
			name: 'proxyInspectionReceptionCancellationDate',
			span: 2,
		},
		{
			key: 'proxyInspectionReceptionCancellationReason',
			title: '불가사유',
			name: 'proxyInspectionReceptionCancellationReason',
			span: 2,
		},
		{
			key: 'publicCorporationInspectionResultDate',
			title: '검사완료일',
			name: 'publicCorporationInspectionResultDate',
			span: 2,
		},
		{
			key: 'publicCorporationInspectionResult',
			title: '검사결과',
			name: 'publicCorporationInspectionResult',
			span: 2,
		},
		{
			key: 'publicCorporationInspectionCarMileage',
			title: '주행거리',
			name: 'publicCorporationInspectionCarMileage',
			value: numberWithCommas(
				Number(inspectionsById?.publicCorporationInspectionCarMileage),
			),
			span: 2,
			unitText: 'km',
			textAlign: 'right',
		},
		{
			key: 'carInspectionType',
			title: '검사종류',
			name: 'carInspectionType',
			span: 2,
		},
	];

	return (
		<GridContentTemplateContainer>
			<ContentBox borderRadius="10px">
				<InnerContentHeader>
					<div className="input-container">
						<Input
							onChange={(e) => setQuickSearchText(e.target.value)}
							name="quickSearch"
							type="text"
							placeholder="차량번호 검색"
							value={quickSearchText}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleQuickSearchText();
								}
							}}
						/>
					</div>
					<p>
						기준 날짜: {dashboardStackTitle} ({dashboardStackDate}), 전체
						데이터:
						{listData?.length ?? 0}
					</p>
				</InnerContentHeader>
				<div className="grid-content" style={{ height: '500px' }}>
					<Grid rowData={listData ?? []} cols={columns} gridRef={setGridApi} />
				</div>
			</ContentBox>
			<SelectionGridWrapper style={{ height: '100%' }}>
				<StyledModal
					title={<ModalTitle>검사대행 상세정보</ModalTitle>}
					open={isDetailModal}
					onCancel={() => setIsDetailModal(false)}
					width={1300}
					destroyOnClose
					noContentPadding
				>
					<ModalContainer>
						<ModalLeft>
							<ContentBox borderRadius="10px" $isCompact>
								<GridLayout>
									{topFields.map(({ key, title, name, span }) => (
										<GridInputItem
											key={key}
											title={title}
											size={GridTitleSize.GT_X_SMALL}
											span={span}
											name={name as keyof CarServiceInspectionItem}
											value={
												(name === 'proxyInspectionReceptionStateCode'
													? statusLabel?.label
													: inspectionsById?.[name]) ?? ''
											}
										/>
									))}
								</GridLayout>
							</ContentBox>
							<div style={{ margin: '0.5rem 0' }}>
								<TextArea
									style={{ width: '100%', padding: '1rem' }}
									name="proxyInspectionReceptionMemo"
									placeholder="고객 요청사항"
									value={inspectionsById?.proxyInspectionReceptionMemo ?? ''}
								/>
							</div>
							<ContentBox borderRadius="10px" $isCompact>
								<GridLayout>
									{bottomFields.map(
										({ key, title, name, span, unitText, textAlign }) => (
											<GridInputItem
												key={key}
												title={title}
												size={GridTitleSize.GT_X_SMALL}
												span={span}
												name={name as keyof CarServiceInspectionItem}
												value={
													name === 'publicCorporationInspectionCarMileage'
														? numberWithCommas(Number(inspectionsById?.[name]))
														: inspectionsById?.[name] ?? ''
												}
												unitText={unitText}
												textAlign={textAlign}
											/>
										),
									)}
								</GridLayout>
							</ContentBox>
						</ModalLeft>
						<ModalRight>
							<ContentBox borderRadius="10px" $isCompact>
								<div className="grid-content" style={{ height: '535px' }}>
									<Grid
										rowData={inspectionsCallList ?? []}
										cols={callDetailColumns}
									/>
								</div>
							</ContentBox>
						</ModalRight>
					</ModalContainer>
				</StyledModal>
			</SelectionGridWrapper>
		</GridContentTemplateContainer>
	);
};

export default ReportDataTable;
