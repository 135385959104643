const blobFileDownload = (blob: Blob, fileName: string) => {
	// Create blob link to download
	const url = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
	}, 60000);

	// Clean up and remove the link
	link.remove();
};

// eslint-disable-next-line import/prefer-default-export
export { blobFileDownload };
