import { useGetDepartmentsQuery } from '../../store/apis/department';

const useDepartmentsOption = ({ pageSize }: { pageSize?: number }) => {
	const departmentsOption: Array<{ label: string; value: number }> = [];

	const { data } = useGetDepartmentsQuery({ pageSize: pageSize ?? 500 });

	const departments = data ? data.rows : [];

	departments.map((item) => {
		return departmentsOption.push({
			label: item.departmentName,
			value: item.id,
		});
	});

	return departmentsOption;
};

export default useDepartmentsOption;
