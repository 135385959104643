import {
	AdministratorDto,
	CreateAdministratorDto,
	UpdateAdministratorDto,
} from '../../../interface/administrator';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const administratorApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAdministrators: builder.query<
			ListResponse<AdministratorDto>,
			Record<any, any>
		>({
			query: (args) => ({
				url: `/members/administrators`,
				params: args,
			}),
			providesTags: ['Admin'],
		}),
		getAdministratorDetail: builder.query<
			ObjectResponse<AdministratorDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => ({
				url: `/members/administrators/${id}`,
			}),
			providesTags: ['Admin'],
		}),
		createAdministrator: builder.mutation<
			ObjectResponse<AdministratorDto>,
			CreateAdministratorDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/administrators`,
				body,
			}),
			invalidatesTags: ['Admin'],
		}),
		updateAdministrator: builder.mutation<
			ObjectResponse<AdministratorDto>,
			{ id: number; body: UpdateAdministratorDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/administrators/${id}`,
				body,
			}),
			invalidatesTags: ['InspectionDashboard', 'Admin'],
		}),
		updateAdministratorRole: builder.mutation<
			ObjectResponse<AdministratorDto>,
			{
				id: string;
				body: { roleId: number };
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/administrators/${id}`,
				body,
			}),
			invalidatesTags: ['Admin'],
		}),
		unlockAdministrator: builder.mutation<
			ObjectResponse<{ updateResult: boolean }>,
			{
				body: { accountId: number; isLock: boolean };
			}
		>({
			query: ({ body }) => ({
				method: 'PUT',
				url: `/members/administrators/lock`,
				body,
			}),
			invalidatesTags: ['Admin'],
		}),
	}),
});

export const {
	useGetAdministratorsQuery,
	useGetAdministratorDetailQuery,
	useCreateAdministratorMutation,
	useUpdateAdministratorRoleMutation,
	useUpdateAdministratorMutation,
	useUnlockAdministratorMutation,
} = administratorApi;

export const getAdministratorDetailViaId = async (id: string) => {
	const url = `/members/administrators/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<AdministratorDto>>(
		url,
	);

	return data.row;
};
