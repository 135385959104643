import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import {
	inputErrorIcon,
	inputSuccesIcon,
	inputPendingIcon,
} from '../../../assets/icon';
import { ThemeType } from '../../../styles/theme';

export type InputAlertStatusType = 'success' | 'error' | 'pending';
export type InputAlertjustifyContentType = 'spaceBetween' | 'flex-start';
export enum InputAlertMessage {
	error = '올바르지 않은 양식입니다',
	success = '사용 가능한 양식입니다',
	pending = '다시 시도하려면 상단의 재전송 버튼을 눌러주세요',
}
interface InputAlertProps {
	$inputStatus: InputAlertStatusType;
	$justifyContent?: InputAlertjustifyContentType;
}

const getInputAlertColor = (
	$inputStatus: InputAlertStatusType,
	theme: ThemeType,
) => {
	let fontColor;
	switch ($inputStatus) {
		case 'success':
			fontColor = theme.common.colors.success_5_main;
			break;
		case 'error':
			fontColor = theme.common.colors.danger_5_main;
			break;
		case 'pending':
			fontColor = theme.common.colors.info_5_main;
			break;
		default:
			return null;
	}
	return css`
		color: ${fontColor};
	`;
};

const getInputAlertIcon = ($inputStatus: InputAlertStatusType) => {
	let icon;
	switch ($inputStatus) {
		case 'success':
			icon = inputSuccesIcon;
			break;
		case 'error':
			icon = inputErrorIcon;
			break;
		case 'pending':
			icon = inputPendingIcon;
			break;
		default:
			return '';
	}
	return icon;
};

const getInputAlertJustify = (
	$justifyContent: InputAlertjustifyContentType,
) => {
	let justify;
	switch ($justifyContent) {
		case 'spaceBetween':
			justify = 'space-between';
			break;
		case 'flex-start':
			justify = 'flex-start';
			break;
		default:
			return 'null';
	}
	return css`
		justify-content: ${justify};
	`;
};

const InputAlertContainer = styled.div<InputAlertProps>`
	display: flex;
	align-items: center;
	margin-top: 7px;
	${({ $inputStatus, theme }) => getInputAlertColor($inputStatus, theme)};

	.input-alert-text {
		width: 100%;
		margin-left: 5px;
		display: flex;
		${({ $justifyContent }) =>
			$justifyContent && getInputAlertJustify($justifyContent)};
	}
`;
function InputAlert({
	$inputStatus,
	children,
	$justifyContent = 'flex-start',
}: PropsWithChildren<InputAlertProps>) {
	return (
		<InputAlertContainer
			$inputStatus={$inputStatus}
			$justifyContent={$justifyContent}
		>
			<Icon src={getInputAlertIcon($inputStatus)} width={16} height={16} />
			<div className="input-alert-text">{children}</div>
		</InputAlertContainer>
	);
}

export default InputAlert;
