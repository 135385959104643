import {
	GenerateProductDto,
	ProductCreateUpdateDto,
	ProductDto,
} from '../../../interface/product';
import { ProductType } from '../../../interface/subscriptionProducts';
import { axiosInstance } from '../../../network/axios';

import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const productApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query<ListResponse<ProductDto>, Record<any, any>>({
			query: (args) => ({
				method: 'GET',
				url: `/members/products`,
				params: args,
			}),
			providesTags: ['Products'],
		}),
		getProductDetail: builder.query<
			ObjectResponse<ProductDto>,
			{ productId: string | undefined }
		>({
			query: ({ productId }) => ({
				method: 'GET',
				url: `/members/products/${productId}`,
			}),
			providesTags: (args) => [{ type: 'Products', productId: args?.row.id }],
		}),
		createIndividualProduct: builder.mutation<
			ObjectResponse<ProductDto>,
			ProductCreateUpdateDto
		>({
			query: (body) => ({
				method: 'POST',
				url: '/members/products',
				body,
			}),
			invalidatesTags: ['Products'],
		}),
		updateIndividualProduct: builder.mutation<
			ObjectResponse<ProductDto>,
			{ id: number; body: ProductCreateUpdateDto }
		>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/products/${id}`,
				body,
			}),
			invalidatesTags: ['Products'],
		}),
		deleteIndividualProduct: builder.mutation<
			ObjectResponse<ProductDto>,
			string
		>({
			query: (id) => ({
				method: 'DELETE',
				url: `/members/products/${id}`,
			}),
			invalidatesTags: ['Products'],
		}),
	}),
});

export const getProductModalList = async (productType: ProductType) => {
	const url = `/members/products`;
	const { data } = await axiosInstance.get<ListResponse<GenerateProductDto>>(
		url,
		{ params: { productType } },
	);

	return data.rows;
};

export const {
	useGetProductsQuery,
	useGetProductDetailQuery,
	useUpdateIndividualProductMutation,
	useCreateIndividualProductMutation,
	useDeleteIndividualProductMutation,
} = productApiSlice;
