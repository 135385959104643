import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import InputForm from '../../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import Alert from '../../../../components/atoms/Alert';
import { ButtonTypeType } from '../../../../styles/theme';
import NestablePanels from '../../../../components/molecules/NestablePanels';
import {
	getRoleDetailViaId,
	useUpdateRoleMutation,
} from '../../../../store/apis/role';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { ToastContext } from '../../../../contexts/Toast';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	CreateRoleDto,
	CreateRoleItem,
	RoleView,
} from '../../../../interface/role';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

function AdminRoleDetailPage() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);

	const navigate = useNavigate();
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [roleName, setRoleName] = useState('');

	const [roleData, setRoleData] = useState<RoleView[]>([]);

	const [updateRole] = useUpdateRoleMutation();

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleUpdateClick = async () => {
		const roleItems: CreateRoleItem[] = [];

		roleData.forEach((item) => {
			item.item.forEach((subItem) => {
				roleItems.push({
					isRead: subItem.isRead,
					isCreate: subItem.isCreate,
					isUpdate: subItem.isUpdate,
					isDelete: subItem.isDelete,
					isSuper: subItem.isSuper,
					roleTemplateId: subItem.key,
				});
			});
		});

		const body: CreateRoleDto = {
			roleName,
			roleItems,
		};

		const result = await updateRole({ id, body });

		if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '권한 저장이 완료되었습니다.');
		}

		setAlertVisible(false);
	};

	const getData = useCallback(async () => {
		dispatch(fullLoadingOn());
		try {
			const result = await getRoleDetailViaId(id || '');

			const mappingRole = result.roleItems.map((item) => ({
				key: item.roleTemplate.id,
				roleCategory: item.roleTemplate.roleCategory,
				title: item.roleTemplate.roleName,
				isCreate: item.isCreate,
				isRead: item.isRead,
				isUpdate: item.isUpdate,
				isDelete: item.isDelete,
				isSuper: item.isSuper,
			}));

			const roleItem: Record<string, any[]> = {};

			mappingRole.forEach((item) => {
				if (roleItem[item.roleCategory]) {
					roleItem[item.roleCategory].push(item);
				} else {
					roleItem[item.roleCategory] = [item];
				}
			});

			const roleDataResult = Object.keys(roleItem).map((key, index) => ({
				key: String(index),
				title: key,
				item: roleItem[key],
			}));

			setRoleName(result.roleName);
			setRoleData(roleDataResult);
		} catch (e: any) {
			setToast(
				'error',
				(e.response as CustomErrorType).data.translate ||
					(e.response as CustomErrorType).data.message,
			);
		} finally {
			dispatch(fullLoadingOff());
		}
	}, [id, dispatch, setToast]);

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="권한 상세"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleAlertOpenClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="권한 관리"
					borderRadius="10px"
					className="inner-content"
				>
					<InnerContent>
						<InputForm>
							<Label name="roleName" essential>
								<span className="label">
									<Typo $typoType="label2">권한명</Typo>
								</span>
							</Label>
							<Input
								$isDisabled
								name="roleName"
								placeholder="권한명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={roleName}
								onChange={(e) => {
									setRoleName(e.target.value);
								}}
								onReset={() => setRoleName('')}
							/>
						</InputForm>
						<NestablePanels
							onCrudToggle={(toggleItem) => {
								setRoleData((prev) => {
									const cloneItem = [...prev];

									const targetParent = cloneItem.find(
										(item) => item.key === toggleItem.panelParentKey,
									);

									targetParent?.item.forEach((item: any) => {
										if (item.key === toggleItem.panelItemKey) {
											switch (toggleItem.type) {
												case 'read':
													// eslint-disable-next-line no-param-reassign
													item.isRead = !item.isRead;
													break;
												case 'create':
													// eslint-disable-next-line no-param-reassign
													item.isCreate = !item.isCreate;
													break;
												case 'update':
													// eslint-disable-next-line no-param-reassign
													item.isUpdate = !item.isUpdate;
													break;
												case 'delete':
													// eslint-disable-next-line no-param-reassign
													item.isDelete = !item.isDelete;
													break;
												case 'super':
													// eslint-disable-next-line no-param-reassign
													item.isSuper = !item.isSuper;
													break;
												default:
													break;
											}
										}
									});

									return cloneItem;
								});
							}}
							data={roleData as any}
						/>
					</InnerContent>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{isAlertVisible && (
				<Alert
					title="관리자 권한 저장"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={handleUpdateClick}
					isVisible={isAlertVisible}
				>
					관리자 권한을 저장하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default AdminRoleDetailPage;
