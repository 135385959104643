import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import userData from './gridData';
import {
	CAR_SERVICE_DETAIL_PATH,
	CAR_SERVICE_MANAGEMENT_PATH,
} from '../../../../routes/constants/urls';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { createDetailAction } from '../../../../utils/row-data-util';
import { useGetCarServicesQuery } from '../../../../store/apis/carService';
import Input from '../../../../components/atoms/Input';
import Button from '../../../../components/atoms/Button';
import Typo from '../../../../components/atoms/Typo';
import Icon from '../../../../components/atoms/Icon';
import { resetIcon } from '../../../../assets/icon';
import { ButtonTypeType } from '../../../../styles/theme';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';

const FormContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	.reset-button {
		width: 150px;
	}
	.reset-text {
		color: ${({ theme }) => theme.common.colors.primary_5};
	}
	.reset-icon {
		margin-left: 4px;
	}
`;

const CarService = () => {
	const { formInitialValues, colsList, forms, selectForms } = userData;
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
		handleQuickSearchText,
		setQuickSearchText,
		refetch,
		quickSearchText,
	} = useGridDataWithRTK({
		useQuery: useGetCarServicesQuery,
		detailPath: CAR_SERVICE_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const resetQuickFilterText = useCallback(() => {
		handleQuickSearchText();
	}, [handleQuickSearchText]);

	useEffect(() => {
		if (quickSearchText === '' || !quickSearchText) {
			resetQuickFilterText();
		}
	}, [quickSearchText, resetQuickFilterText]);

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="통화 및 이체 관리"
			gridTitle="통화 및 이체 관리"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			selectForms={selectForms}
			onSearchSubmit={handleSearchSubmit}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'proxyInspectionReceptionId'),
			}}
			isFetching={isFetching}
			$isCompact
			columnName={CAR_SERVICE_MANAGEMENT_PATH}
			rightAccessory={
				<FormContainer>
					<Button
						size="small"
						$buttonType={ButtonTypeType.NO_LINE}
						onClick={refetch}
						className="reset-button"
					>
						<Typo className="reset-text" $typoType="b9">
							새로고침
						</Typo>
						<Icon
							src={resetIcon}
							className="reset-icon"
							width={24}
							height={24}
						/>
					</Button>
					<Input
						onChange={(e) => {
							setQuickSearchText(e.target.value);
						}}
						name="quickSearch"
						type="text"
						placeholder="페이지내 검색"
						value={quickSearchText}
						onReset={() => setQuickSearchText('')}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleQuickSearchText();
							}
						}}
					/>
				</FormContainer>
			}
		/>
	);
};

export default CarService;
