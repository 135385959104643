import React from 'react';
import styled from 'styled-components';

const FilterInputContainer = styled.input`
	width: 100%;
	border: none;
	outline: none;
	padding: 0;
	font-size: 13px;
`;

function FilterInput({ ...rest }: React.InputHTMLAttributes<HTMLInputElement>) {
	return <FilterInputContainer {...rest} />;
}

export default FilterInput;
