import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContext } from '../../../../contexts/Toast';
import { useMakeCompanyCarServiceMutation } from '../../../../store/apis/carService';
import CarServiceCompanyInfo from '../../../../interface/carService';
import { CustomErrorType } from '../../../../store/apis/@types';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBox from '../../../../components/atoms/ContentBox';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import Button from '../../../../components/atoms/Button';
import { arrowLeftIcon } from '../../../../assets/icon';
import { ButtonTypeType } from '../../../../styles/theme';
import Input from '../../../../components/atoms/Input';
import { CAR_SERVICE_COMPANY_MANAGEMENT_PATH } from '../../../../routes/constants/urls';
import {
	ALL_OPTION_VALUE,
	COMPANY_CONTRACT_TYPE,
} from '../../../../utils/const';
import Select from '../../../../components/atoms/Select';
import CheckBankAccount from '../../../../components/templates/CarServiceTemplate/CheckBankAccount';
import TextArea from '../../../../components/atoms/TextArea';
import {
	companyRegisterNumberFormat,
	phoneFormat,
} from '../../../../utils/data-format';

const GridWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const TabWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const TabList = styled.ul`
	display: flex;
	align-items: center;
	gap: 24px;
`;

const CarServiceCompanyCreate = () => {
	const setToast = useContext(ToastContext);
	const navigate = useNavigate();

	const [makeCompany, { isLoading: isUpdating }] =
		useMakeCompanyCarServiceMutation();

	const handleSubmit = async (values: Partial<CarServiceCompanyInfo>) => {
		const res = await makeCompany({ body: values });

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '생성되었습니다.');
		}
	};

	const initialValues = {
		companyName: '',
		proxyInspectionOrderCompanyName: '오픈마일 연구소',
		companyRegistrationNumber: '123-12-1666',
		companyAddress: '서울특별시 광진구 능동로35길 22',
		companyAddressDetail: '4F',
		companyAddressZipCode: '01234',
		companyAddressSiDo: '서울특별시',
		companyAddressSiGunGu: '광진구',
		companyAddressEubMyeon: '읍면리',
		companyPhoneNumber: '010-1234-1234',
		companyFaxNumber: '02-1234-1234',
		companyEmail: 'test@openmile.kr',
		companyDescription: '',
		companyManagementRelationType: '협력A1',
		upperCompanyManagementYn: 'Y',
		upperCompanyManagementId: 'BB',
		companyRepresentativeName1: '한현석',
		companyRepresentativePhoneNumber1: '010-1234-1234',
		companyRepresentativeEmail1: 'test@openmile.kr',
		companyRepresentativeName2: '홍길동',
		companyRepresentativePhoneNumber2: '010-1234-1234',
		companyRepresentativeEmail2: 'test@openmile.kr',
		companyManagementContractDepartment: '연구소',
		companyManagementContractManagerName: '박한결',
		companyManagementContractSignedDate: '2020-01-01',
		companyManagementMemo: '',
	};

	const {
		// validateForm,
		// setValues,
		values: formikValues,
		// errors: formikErrors,
		handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<GridDetailTemplate detailTitle="업체 등록하기" $isCompact>
			<ContentBox borderRadius="10px" className="inner-content" $isCompact>
				<TabWrapper>
					<TabList>
						<Button
							size="default"
							iconUrl={arrowLeftIcon}
							$buttonType={ButtonTypeType.GHOST_BLACK}
							onClick={() => navigate(CAR_SERVICE_COMPANY_MANAGEMENT_PATH)}
							style={{ marginBottom: '13px', fontSize: '15px' }}
						>
							뒤로 가기
						</Button>
					</TabList>
					<TabList>
						<Button
							size="default"
							$buttonType={ButtonTypeType.PRIMARY_LIGHT}
							onClick={() => formikSubmit()}
							disabled={isUpdating}
						>
							{isUpdating ? '등록중...' : '등록하기'}
						</Button>
					</TabList>
				</TabWrapper>
				<GridWrapper>
					<ContentBoxWithHeader title="업체 정보" borderRadius="10px">
						<GridLayout $columnCount={3} $isCompact>
							<GridItem
								key="proxyInspectionOrderCompanyName"
								title="발주처명"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
								span={2}
							>
								<Input
									name="proxyInspectionOrderCompanyName"
									placeholder="발주처명"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.proxyInspectionOrderCompanyName ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('proxyInspectionOrderCompanyName', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementId"
								title="업체관리ID"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementId"
									placeholder="업체관리ID"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyManagementId ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyManagementId', '')}
								/>
							</GridItem>
							<GridItem
								key="companyName"
								title="업체명"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
								span={2}
							>
								<Input
									name="companyName"
									placeholder="업체명"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyName ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyName', '')}
								/>
							</GridItem>
							<GridItem
								key="companyRegistrationNumber"
								title="사업자번호"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyRegistrationNumber"
									placeholder="사업자등록번호"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyRegistrationNumber ?? ''}
									onChange={(e) =>
										setFieldValue(
											'companyRegistrationNumber',
											companyRegisterNumberFormat(e.target.value),
										)
									}
									onReset={() => setFieldValue('companyRegistrationNumber', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddress"
								title="업체주소"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
								span={3}
							>
								<Input
									name="companyAddress"
									placeholder="업체주소"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddress ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddress', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddressDetail"
								title="상세주소"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
								span={2}
							>
								<Input
									name="companyAddressDetail"
									placeholder="상세주소"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddressDetail ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddressDetail', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddressZipCode"
								title="우편번호"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyAddressZipCode"
									placeholder="우편번호"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddressZipCode ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddressZipCode', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddressSiDo"
								title="시/도"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyAddressSiDo"
									placeholder="시/도"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddressSiDo ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddressSiDo', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddressSiGunGu"
								title="시/군/구"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyAddressSiGunGu"
									placeholder="시/군/구"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddressSiGunGu ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddressSiGunGu', '')}
								/>
							</GridItem>
							<GridItem
								key="companyAddressEubMyeon"
								title="읍/면"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyAddressEubMyeon"
									placeholder="읍/면"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyAddressEubMyeon ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyAddressEubMyeon', '')}
								/>
							</GridItem>
							<GridItem
								key="companyFaxNumber"
								title="팩스"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyFaxNumber"
									placeholder="팩스"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyFaxNumber ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyFaxNumber', '')}
								/>
							</GridItem>
							<GridItem
								key="companyEmail"
								title="이메일"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyEmail"
									placeholder="이메일"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyEmail ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('companyEmail', '')}
								/>
							</GridItem>
							<GridItem
								key="companyPhoneNumber"
								title="전화번호"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyPhoneNumber"
									placeholder="전화번호"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyPhoneNumber ?? ''}
									onChange={(e) =>
										setFieldValue(
											'companyPhoneNumber',
											phoneFormat(e.target.value),
										)
									}
									onReset={() => setFieldValue('companyPhoneNumber', '')}
								/>
							</GridItem>
						</GridLayout>
						<TextArea
							name="companyDescription"
							placeholder="업체 관련 메모를 입력해주세요"
							value={formikValues.companyDescription ?? ''}
							onChange={handleChange}
							style={{ marginTop: '8px' }}
						/>
					</ContentBoxWithHeader>
					<ContentBoxWithHeader title="계약 정보" borderRadius="10px">
						<GridLayout $columnCount={3} $isCompact>
							<GridItem
								key="companyRepresentativeName1"
								title="담당자명"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyRepresentativeName1"
									placeholder="담당자명"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyRepresentativeName1 ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyRepresentativeName1', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyRepresentativeEmail1"
								title="이메일"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyRepresentativeEmail1"
									placeholder="이메일"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyRepresentativeEmail1 ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyRepresentativeEmail1', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyRepresentativePhoneNumber1"
								title="휴대폰"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyRepresentativePhoneNumber1"
									placeholder="휴대폰"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyRepresentativePhoneNumber1 ?? ''}
									onChange={(e) =>
										setFieldValue(
											'companyRepresentativePhoneNumber1',
											phoneFormat(e.target.value),
										)
									}
									onReset={() =>
										setFieldValue('companyRepresentativePhoneNumber1', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementContractManagerName"
								title="계약담당자"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementContractManagerName"
									placeholder="계약 담당자"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={
										formikValues.companyManagementContractManagerName ?? ''
									}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyManagementContractManagerName', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementContractDepartment"
								title="계약담당부서"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementContractDepartment"
									placeholder="계약담당부서"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formikValues.companyManagementContractDepartment ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyManagementContractDepartment', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementRelationType"
								title="계약관계유형"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Select
									value={formikValues.companyManagementRelationType ?? ''}
									onChange={(value: string) =>
										setFieldValue('companyManagementRelationType', value)
									}
									placeholder="검사유형"
									allowClear
								>
									{COMPANY_CONTRACT_TYPE.filter(
										(item) => item.value !== ALL_OPTION_VALUE,
									).map((item, idx) => (
										<Select.Option value={item.value} key={idx.toString()}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</GridItem>
						</GridLayout>
						<TextArea
							name="companyManagementMemo"
							placeholder="계약 관련 메모를 입력해주세요"
							value={formikValues.companyManagementMemo ?? ''}
							onChange={handleChange}
							style={{ marginTop: '8px' }}
						/>
					</ContentBoxWithHeader>
					<ContentBoxWithHeader title="계좌 정보" borderRadius="10px">
						<CheckBankAccount
							bankCodeFieldName={['companyManagementBankAccountBank']}
							bankNameFieldName={['companyManagementBankAccountBankName']}
							bankAccountFieldName={['companyManagementBankAccountNumber']}
							bankAccountOwnerFieldName={['companyManagementBankAccountName']}
							verifiedDateFieldName="companyManagementBankAccountVerifiedDate"
							setFieldValue={setFieldValue}
							setDuzonFieldValue={setFieldValue}
							style={{ marginBottom: '16px' }}
						/>
						<GridLayout $columnCount={4} $isCompact>
							<GridItem
								key="companyManagementBankAccountNumber"
								title="계좌번호"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementBankAccountNumber"
									placeholder="계좌번호"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									$isDisabled
									value={formikValues.companyManagementBankAccountNumber ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyManagementBankAccountNumber', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementBankAccountName"
								title="계좌주"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementBankAccountName"
									placeholder="계좌주"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									$isDisabled
									value={formikValues.companyManagementBankAccountName ?? ''}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyManagementBankAccountName', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementBankAccountBankName"
								title="은행명"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementBankAccountBankName"
									placeholder="은행명"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									$isDisabled
									value={
										formikValues.companyManagementBankAccountBankName ?? ''
									}
									onChange={handleChange}
									onReset={() =>
										setFieldValue('companyManagementBankAccountBankName', '')
									}
								/>
							</GridItem>
							<GridItem
								key="companyManagementBankAccountVerifiedDate"
								title="검증일자"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="companyManagementBankAccountVerifiedDate"
									placeholder="검증일자"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									$isDisabled
									value={
										formikValues.companyManagementBankAccountVerifiedDate ?? ''
									}
									onChange={handleChange}
									onReset={() =>
										setFieldValue(
											'companyManagementBankAccountVerifiedDate',
											'',
										)
									}
								/>
							</GridItem>
						</GridLayout>
					</ContentBoxWithHeader>
				</GridWrapper>
			</ContentBox>
		</GridDetailTemplate>
	);
};

export default CarServiceCompanyCreate;
