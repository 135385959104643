import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Radio, Select } from 'antd';
import { RowSelectedEvent } from 'ag-grid-community';
import dayjs from 'dayjs';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../components/molecules/GridLayout';
import { ToastContext } from '../../../contexts/Toast';
import { TermsDto, TermsType, TermsTypeList } from '../../../interface/terms';
import {
	getTermsDetailViaId,
	getTermsTypeList,
	useUpdateTermMutation,
} from '../../../store/apis/terms';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import theme, { ButtonTypeType } from '../../../styles/theme';
import { NewTableValueType } from '../../../utils/table-util';
import { termDetailTablekeys, termHistoryGridKeys } from './tableData';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import Button from '../../../components/atoms/Button';
// import { TERMS_MANAGEMENT_PATH } from '../../routes/constants/urls';
import ContentBoxItem from '../../../components/atoms/ContentBoxItem';
import Grid from '../../../components/atoms/Grid';
import { getTermsHistoryListViaId } from '../../../store/apis/termsHistory';
import { TermsHistoryDto } from '../../../interface/termsHistory';
import { createColList } from '../../../utils/grid-util';
import EditModal from './EditModal';
import RegisterModal from './RegisterModal';
import { Service } from '../../../interface/appVersion';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	TERMS_TYPE_CARD_REGISTER_LABEL,
	TERMS_TYPE_CARD_REGISTER_VALUE,
	TERMS_TYPE_SIGNUP_LABEL,
	TERMS_TYPE_SIGNUP_VALUE,
} from '../../../utils/const';
import Label from '../../../components/atoms/Label';
import Typo from '../../../components/atoms/Typo';
import InputForm from '../../../components/molecules/InputForm';
import Alert from '../../../components/atoms/Alert';
import Input from '../../../components/atoms/Input';

const Box = styled.div`
	height: 100%;
	display: flex;
	flex: 1;
	${({ theme: globalTheme }) => globalTheme.typo.b6};

	.term-detail-content-box {
		align-items: flex-start;
		height: 100%;
	}
`;

const GridWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	width: 100%;
`;

const StyledContentBoxWithHeader = styled(ContentBoxWithHeader)`
	flex: 1;
	height: 100%;

	.terms-inner-main-content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

enum TermDetailModalState {
	NULL,
	REGISTER,
	EDIT,
}

const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, NewTableValueType>;
	data: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof T];

		const { label, span, bulletOptions } = value;

		switch (key) {
			case 'createdAt':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY-MM-DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);

			case 'optionable':
			case 'viewable': {
				const color =
					value.bulletOptions?.find((item) => item.key === tableContent)
						?.color ?? 'black';

				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						<span style={{ color: theme.common.colors[color] }}>
							&#8226;{' '}
							{bulletOptions?.find((item) => item.key === tableContent)
								?.value ?? '-'}
						</span>
					</GridItem>
				);
			}

			case 'service': {
				let flatformToKor: string = '-';

				if (tableContent === Service.CUSTOMER_APP) {
					flatformToKor = '소비자 앱';
				}

				if (tableContent === Service.MANAGER_APP) {
					flatformToKor = '매니저 앱';
				}

				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{flatformToKor}
					</GridItem>
				);
			}
			case 'type': {
				let termsType: string = '-';

				if (tableContent === TERMS_TYPE_SIGNUP_VALUE) {
					termsType = TERMS_TYPE_SIGNUP_LABEL;
				}

				if (tableContent === TERMS_TYPE_CARD_REGISTER_VALUE) {
					termsType = TERMS_TYPE_CARD_REGISTER_LABEL;
				}

				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{termsType}
					</GridItem>
				);
			}
			case 'title':
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};

function TermsDetailPage() {
	const [termData, setTermData] = useState<TermsDto | null>(null);
	const [termsHistory, setTermsHistory] = useState<TermsHistoryDto[]>([]);
	const [flatform, setFlatform] = useState<Service>(Service.CUSTOMER_APP);
	const [termTitle, setTermtTitle] = useState<string>('');
	const [isTypeOptionLoading, setIsTypeOptionLoading] = useState(true);
	const [typeOptionList, setTypeOptionList] = useState<TermsTypeList[]>([]);
	const [isOptionable, setIsOptionable] = useState(false);
	const [termsType, setTermsType] = useState<TermsType>();
	const [isViewable, setIsViewable] = useState(true);
	const [isTermsModify, setIsTermsModify] = useState(false);

	const [modalState, setModalState] = useState<{
		state: TermDetailModalState;
		options?: any;
	}>({
		state: TermDetailModalState.NULL,
	});

	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const setToast = useContext(ToastContext);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [updateTerm] = useUpdateTermMutation();

	const goBackTo = (route: number) => {
		navigate(route);
	};

	const goDetailPage = () => {
		setIsTermsModify(false);
	};

	const handleConfrim = () => {
		setIsAlertVisible(true);
	};

	const handleAlertClose = () => {
		setIsAlertVisible(false);
	};

	const handleModalClose = () => {
		setModalState({ state: TermDetailModalState.NULL });
	};

	const handleDetailClick = (rowId: RowSelectedEvent<string>) => {
		setModalState({
			state: TermDetailModalState.EDIT,
			options: {
				id: rowId,
			},
		});
	};

	const handleRegisterClick = () => {
		setModalState({ state: TermDetailModalState.REGISTER });
	};

	const fetchData = useCallback(async () => {
		if (!id) return;

		const termsViaId = await getTermsDetailViaId(parseInt(id, 10));
		setTermData(termsViaId);
		setTermtTitle(termsViaId.title);
		setFlatform(termsViaId.service as Service);
		setIsOptionable(termsViaId.optionable);
		setTermsType(termsViaId.type);
		setIsViewable(termsViaId.viewable);

		const termsHistoryViaId = await getTermsHistoryListViaId(parseInt(id, 10));
		setTermsHistory(termsHistoryViaId);
	}, [id]);

	const handleSubmit = async () => {
		const res = await updateTerm({
			id: Number(id),
			body: {
				title: termTitle,
				service: flatform,
				viewable: isViewable,
			},
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '약관수정이 완료되었습니다.');
			handleAlertClose();
			await fetchData();
		}
	};

	useEffect(() => {
		if (id) {
			(async () => {
				try {
					dispatch(fullLoadingOn());
					await fetchData();
				} catch (e) {
					const err = e as AxiosError<{
						error: string;
						message: string;
						statusCode: number;
						translate: string;
					}>;

					if (err.response) {
						setToast(
							'error',
							(err.response as CustomErrorType).data.translate ||
								(err.response as CustomErrorType).data.message,
						);
					}
				} finally {
					dispatch(fullLoadingOff());
				}
			})();
		}
	}, [fetchData, id, dispatch, setToast]);

	useEffect(() => {
		(async () => {
			try {
				const res = await getTermsTypeList();
				setTypeOptionList(res);
				setIsTypeOptionLoading(false);
			} catch (e) {
				setIsTypeOptionLoading(true);
				const err = e as AxiosError<{ message: string; translate: string }>;

				if (err.response)
					setToast(
						'error',
						err.response.data.translate || err.response.data.message,
					);
			}
		})();
	}, [setToast]);

	const renderModal = () => {
		const { state, options } = modalState;
		if (state === TermDetailModalState.EDIT) {
			return (
				<EditModal
					isVisible
					id={options.id}
					onClose={handleModalClose}
					onRefetch={fetchData}
				/>
			);
		}

		if (state === TermDetailModalState.REGISTER) {
			return (
				<RegisterModal
					isVisible
					onClose={handleModalClose}
					onRefetch={fetchData}
				/>
			);
		}

		return null;
	};

	const renderAlert = () => {
		if (isAlertVisible) {
			return (
				<Alert
					title="약관 저장"
					isVisible={isAlertVisible}
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={handleSubmit}
				>
					<Typo $typoType="b5" color="gray_8">
						약관을 저장하시겠습니까?
					</Typo>
				</Alert>
			);
		}

		return null;
	};

	const renderTermsModify = () => {
		return (
			<>
				<GridDetailTemplate
					detailTitle="약관 수정"
					onBack={goDetailPage}
					rightAccessory={
						<ButtonContainer>
							<Button
								$buttonType={ButtonTypeType.OPAQUE}
								onClick={goDetailPage}
								size="small"
							>
								취소
							</Button>
							<Button
								onClick={handleConfrim}
								size="small"
								disabled={!termTitle}
							>
								저장하기
							</Button>
						</ButtonContainer>
					}
				>
					<ContentBoxWithHeader
						title="약관 내용"
						borderRadius="10px"
						className="inner-content"
					>
						<ContentBoxItem>
							<InputForm>
								<Label name="termTitle" essential>
									<span>
										<Typo $typoType="label2">약관명</Typo>
									</span>
								</Label>
								<Input
									name="termTitle"
									placeholder="약관명을 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={termTitle}
									onChange={({ target }) => {
										setTermtTitle(target.value);
									}}
								/>
							</InputForm>
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="flatform" essential>
								<span>
									<Typo $typoType="label2">플랫폼</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e) => {
									setFlatform(e.target.value);
								}}
								value={flatform}
							>
								<Radio value={Service.CUSTOMER_APP}>
									<Typo $typoType="label1">소비자 앱</Typo>
								</Radio>
								<Radio value={Service.MANAGER_APP}>
									<Typo $typoType="label1">매니저 앱</Typo>
								</Radio>
							</Radio.Group>
						</ContentBoxItem>

						<ContentBoxItem>
							<InputForm>
								<Label name="productType" essential>
									<span className="label">
										<Typo $typoType="label2">적용 영역</Typo>
									</span>
								</Label>
								<Select
									value={termsType}
									onChange={(value) => setTermsType(value)}
									loading={isTypeOptionLoading}
									placeholder="적용 영역을 선택해주세요"
									disabled
								>
									{typeOptionList.map(({ value, key }) => (
										<Select.Option value={key} key={key}>
											{value}
										</Select.Option>
									))}
								</Select>
							</InputForm>
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="viewable" essential>
								<span>
									<Typo $typoType="label2">필수 여부</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e) => {
									setIsOptionable(e.target.value);
								}}
								value={isOptionable}
								disabled
							>
								<Radio value={false}>
									<Typo $typoType="label1">필수</Typo>
								</Radio>
								<Radio value>
									<Typo $typoType="label1">선택</Typo>
								</Radio>
							</Radio.Group>
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="viewable" essential>
								<span>
									<Typo $typoType="label2">노출 여부</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e) => {
									setIsViewable(e.target.value);
								}}
								value={isViewable}
							>
								<Radio value>
									<Typo $typoType="label1">노출</Typo>
								</Radio>
								<Radio value={false}>
									<Typo $typoType="label1">미노출</Typo>
								</Radio>
							</Radio.Group>
						</ContentBoxItem>
					</ContentBoxWithHeader>
				</GridDetailTemplate>
				{renderAlert()}
			</>
		);
	};

	const renderTermsDetail = () => {
		return (
			<GridDetailTemplate
				detailTitle="서비스 이용약관 상세"
				onBack={() => goBackTo(-1)}
				rightAccessory={
					<ButtonContainer>
						<Button
							onClick={() => {
								setIsTermsModify(true);
							}}
							size="small"
						>
							수정하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="약관내용"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						{termData &&
							renderTable({
								tableKeys: termDetailTablekeys,
								data: termData,
							})}
					</GridLayout>
				</ContentBoxWithHeader>

				<StyledContentBoxWithHeader
					title="서비스 이용약관 이력"
					borderRadius="10px"
					className="inner-content"
					innerClassName="terms-inner-main-content"
				>
					<Box>
						<ContentBoxItem className="term-detail-content-box">
							<Button size="small" onClick={handleRegisterClick}>
								이력 등록
							</Button>

							<GridWrapper>
								<Grid
									cols={createColList(termHistoryGridKeys.colsList)}
									rowData={termsHistory}
									onRowClicked={handleDetailClick}
								/>
							</GridWrapper>
						</ContentBoxItem>
					</Box>
				</StyledContentBoxWithHeader>

				{renderModal()}
			</GridDetailTemplate>
		);
	};

	return isTermsModify ? renderTermsModify() : renderTermsDetail();
}

export default TermsDetailPage;
