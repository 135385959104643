// eslint-disable-next-line import/prefer-default-export
export const managerInfoTableKey = {
	createdAt: {
		key: 'createdAt',
		label: '가입일자',
	},
	managerName: {
		key: 'managerName',
		label: '매니저 명',
	},
	managerDepartment: {
		key: 'managerDepartment',
		label: '소속',
	},
	managerPhone: {
		key: 'managerPhone',
		label: '휴대폰 번호',
	},
	managerStatus: {
		key: 'managerStatus',
		label: '매니저 상태',
	},
	isLock: {
		key: 'isLock',
		label: '잠금 상태',
	},
	statusChangedReason: {
		key: 'statusChangedReason',
		label: '제한 사유',
	},
};
