import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { phoneFormat } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { getDepartmentServiceName } from '../../../../utils/string-util';

const userData: () => GridDataModel = () => ({
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'department',
				headerName: '관리자 분류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					return getDepartmentServiceName(data.department?.service);
				},
				valueGetter: ({ data }) => {
					return getDepartmentServiceName(data.department?.service);
				},
			},
		},
		{
			tableInfo: {
				field: 'departmentName',
				headerName: '소속명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					if (data.department) {
						return data.department.departmentName;
					}
					return '오픈마일';
				},
				valueGetter: ({ data }) => {
					if (data.department) {
						return data.department.departmentName;
					}
					return '오픈마일';
				},
			},
		},
		{
			tableInfo: {
				field: 'administratorApplicationId',
				headerName: '아이디',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'administratorName',
				headerName: '관리자명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'roleName',
				headerName: '관리자 권한',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					return data.role.roleName ?? '-';
				},
				valueGetter: ({ data }) => {
					return data.role.roleName ?? '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'administratorPhone',
				headerName: '휴대폰번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'adminType',
			label: '관리자 분류',
			type: FilterElementType.SELECT,
			options: [
				{ label: '전체', value: '' },
				{ label: '오픈마일 관리자', value: 'ADMIN' },
				{ label: '매니저 관리자', value: 'MANAGER' },
				{ label: '법인 관리자', value: 'CUSTOMER' },
			],
		},
		{
			key: 'department',
			label: '소속명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'administratorApplicationId',
			label: '아이디',
			type: FilterElementType.INPUT,
		},
		{
			key: 'administratorName',
			label: '관리자명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'administratorPhone',
			label: '휴대폰번호',
			type: FilterElementType.INPUT,
		},
	],
});

export default userData;
