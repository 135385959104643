import { LabelValue } from '../../utils/const';
import { useGetCompaniesQuery } from '../../store/apis/company';

const useCompaniesOption = () => {
	const { data: companies } = useGetCompaniesQuery({
		args: {},
		page: 1,
		pageSize: 500,
	});

	const companyOptionList: LabelValue[] = companies
		? companies.rows.map((item) => ({
				label: item.name,
				value: item.id,
		  }))
		: [];

	return companyOptionList;
};

export default useCompaniesOption;
