import React from 'react';
import Joyride, { Step } from 'react-joyride';
import { AlertOutlined } from '@ant-design/icons';
import markTutorialCompleted from '../../../utils/tutorial-utils';
import useTutorialGuide from '../../../hooks/useTutorialGuide';
import theme from '../../../styles/theme';

const TutorialSteps = ({
	userId,
	tutorialId,
}: {
	userId: number | undefined;
	tutorialId: string;
}) => {
	const handleTutorialCompletion = () => {
		markTutorialCompleted(userId!, tutorialId);
	};

	const steps: Step[] = [
		{
			content: (
				<div style={{ textAlign: 'left', padding: '1rem' }}>
					<h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
						차량구독 견적서
					</h3>

					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						차량 구독 상담 후, 고객이 선택한 차량을 등록하고 구체적인 정보(모델,
						오픈스페너 옵션 등)를 등록 및 수정할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'center',
			target: 'body',
		},
		{
			title: '고객 정보',
			content: (
				<div style={{ textAlign: 'left' }}>
					<span style={{ color: 'red' }}>(필수) </span>
					고객 정보를 입력해주세요.
					<br />
					<br />
					차량 구독 견적서에 들어갈 고객 정보를 입력해주세요.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-start',
			target: '#step-1',
		},
		{
			title: '구독 차량 정보',
			content: (
				<div style={{ textAlign: 'left' }}>
					<span style={{ color: 'red' }}>
						<AlertOutlined />
						(주의)
					</span>
					차량이 여러 대일 경우, 각 차량의 구독 정보를 개별적으로 등록하고
					수정해 주시기 바랍니다.
					<br />
					<br />
					특정 차량의 정보를 수정하려면 해당 차량의 수정 버튼을 클릭하세요.
					<span style={{ color: 'red' }}>
						&nbsp;여러 차량을 동시에 수정할 수는 없습니다.
					</span>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'top-start',
			target: '#step-2',
		},
		{
			content: (
				<div style={{ textAlign: 'left', padding: '1rem' }}>
					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						고객 정보와 구독 차량 정보를 모두 입력하셨다면, 위의 버튼을 클릭하여
						고객님께 문자 메시지를 발송할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'top',
			target: '#step-3',
		},
	];

	const { handleJoyrideCallback, localStorageDetail } = useTutorialGuide(
		userId!,
		tutorialId,
		steps,
	);

	return (
		<div>
			<Joyride
				continuous
				callback={handleJoyrideCallback}
				run={!localStorageDetail}
				steps={steps}
				hideCloseButton
				disableScrolling
				disableScrollParentFix
				showSkipButton
				showProgress
				styles={{
					options: {
						primaryColor: theme.common.colors.primary_6_main,
					},
				}}
			/>
		</div>
	);
};

export default TutorialSteps;
