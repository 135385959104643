const companyInfoTableKey = {
	createdAt: {
		key: 'createdAt',
		label: '등록일',
	},
	name: {
		key: 'name',
		label: '법인명',
	},
	registrationNumber: {
		key: 'registrationNumber',
		label: '법인등록번호',
	},
	businessLicenseNumber: {
		key: 'businessLicenseNumber',
		label: '사업자등록번호',
	},
	representativePhoneNumber: {
		key: 'representativePhoneNumber',
		label: '대표번호',
	},
	contactName: {
		key: 'contactName',
		label: '담당자명',
	},
	contactPhoneNumber: {
		key: 'contactPhoneNumber',
		label: '담당자번호',
	},
};

export default companyInfoTableKey;
