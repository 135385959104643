import React, { useEffect, useState } from 'react';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import {
	useGetMyCarServiceDashboardByStackDateQuery,
	useGetMyCarServiceDashboardStackDateListQuery,
	useGetMyLatestCarServiceDashboardQuery,
} from '../../../store/apis/carService';
import ChartGroup from '../CarServiceDashboard/ChartGroup';
import ContentBox from '../../../components/atoms/ContentBox';
import Select from '../../../components/atoms/Select';
import Typo from '../../../components/atoms/Typo';

const CarServiceMyDashboard = () => {
	const { data: myStackDateSet } =
		useGetMyCarServiceDashboardStackDateListQuery({});

	const { data: myLatestDashboardResult } =
		useGetMyLatestCarServiceDashboardQuery({});

	const dashboardDates = myStackDateSet?.rows.map((row) => ({
		dashboardStackDate: row.dashboardStackDate,
		dashboardStackTitle: row.dashboardStackTitle,
	}));

	const latestDashboardTitle = dashboardDates?.[0]?.dashboardStackTitle;
	const latestDashboardDate = dashboardDates?.[0]?.dashboardStackDate;

	const [dashboardSelectedStackDate, setDashboardSelectedStackDate] =
		useState(latestDashboardDate);

	const { data: dashboardMyDates } =
		useGetMyCarServiceDashboardByStackDateQuery({
			dashboardStackDate: dashboardSelectedStackDate || '',
		});

	useEffect(() => {
		setDashboardSelectedStackDate(dashboardSelectedStackDate);
	}, [dashboardSelectedStackDate]);

	const groupId = myLatestDashboardResult?.row?.dashboardGroupId;
	const groupName = myLatestDashboardResult?.row?.dashboardGroupName;

	return (
		<GridDetailTemplate detailTitle="대시보드(고객용)">
			<ContentBox borderRadius="10px">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typo $typoType="h6" style={{ marginRight: 12 }}>
						{groupName}
					</Typo>
					<Select
						placeholder="날짜 선택"
						defaultValue={latestDashboardTitle}
						value={
							dashboardSelectedStackDate ||
							myLatestDashboardResult?.row?.dashboardStackTitle
						}
						style={{ width: '150px' }}
						onChange={(value) => setDashboardSelectedStackDate(value)}
					>
						{dashboardDates?.map((item, idx) => (
							<Select.Option key={idx} value={item.dashboardStackDate}>
								{item.dashboardStackTitle}
							</Select.Option>
						))}
					</Select>
					{dashboardSelectedStackDate && (
						<Typo $typoType="b5" style={{ marginLeft: 12 }}>
							기준일자: {dashboardSelectedStackDate}
						</Typo>
					)}
				</div>
			</ContentBox>
			<ChartGroup
				groupId={groupId}
				selectedDate={dashboardSelectedStackDate}
				dashboardData={
					dashboardSelectedStackDate
						? dashboardMyDates?.row
						: myLatestDashboardResult?.row
				}
			/>
		</GridDetailTemplate>
	);
};

export default CarServiceMyDashboard;
