import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { UploadFile } from 'antd/es/upload/interface';
import { Image } from 'antd';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import ImageUpload from '../../atoms/ImageUpload';
import { useSubscriptionItemImageUpdateMutation } from '../../../store/apis/subscriptionItem';
import { useBigImagesUploadMutation } from '../../../store/apis/upload';
import Alert from '../../atoms/Alert';

interface WorkImageProps {
	id: string;
	beforeMedias: string[];
	// eslint-disable-next-line react/require-default-props
	refetch?: () => void;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const InnerRowContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 32px;
	margin: 16px auto 0 auto;
`;

function WorkImageModal({ id, beforeMedias, refetch }: WorkImageProps) {
	const toast = useContext(ToastContext);
	const [isAlertOpen, setIsAlertOpen] = useState(false);

	const [uploadImage] = useBigImagesUploadMutation();
	const [updateImages] = useSubscriptionItemImageUpdateMutation();
	const [newBeforeMedias, setNewBeforeMedias] = useState<UploadFile[]>([]);

	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubmitClick = () => {
		handleAlertOpen();
	};

	const handleUploadImage = async () => {
		const resultUrls: { beforeMedias: string[] } = {
			beforeMedias,
		};

		if (newBeforeMedias.length) {
			const res = await uploadImage({
				// @ts-ignore
				file: newBeforeMedias
					.map((media) => media.originFileObj)
					.filter((fileObj) => fileObj !== undefined),
				folder: '/manager/beforeWork',
			});

			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				toast('error', errorMessage);

				return null;
			}

			resultUrls.beforeMedias = [...beforeMedias, ...res.data.rows];
		}

		return resultUrls;
	};

	const handleSubmit = async () => {
		handleAlertClose();
		dispatch(fullLoadingOn());

		const imageUrls = await handleUploadImage();

		const result = await updateImages({
			id,
			body: {
				beforeMedias: imageUrls?.beforeMedias,
			},
		});

		if (!('error' in result)) {
			setToast('info', '작업을 완료하였습니다.');
			if (refetch) refetch();
			setNewBeforeMedias([]);
			handleModalClose();
		} else if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}

		dispatch(fullLoadingOff());
	};

	return (
		<>
			<Button
				size="small"
				$buttonType={ButtonTypeType.PRIMARY_LIGHT}
				onClick={handleModalOpen}
			>
				작업사진 수정하기
			</Button>
			<Modal
				title={<ModalTitle>작업사진 수정하기</ModalTitle>}
				open={isModalOpen}
				onCancel={handleModalClose}
				width={940}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button onClick={handleSubmitClick}>완료</Button>
					</ModalFooterWrapper>
				}
			>
				<div style={{ marginBottom: '16px' }}>
					<Typo>
						기존에 등록된 이미지에 새로운 이미지를 추가로 업로드합니다.
					</Typo>
				</div>
				<Label name="beforeWorkImages">
					<span>
						<Typo $typoType="label2">작업 사진</Typo>
					</span>
				</Label>
				<InnerRowContent>
					<Image.PreviewGroup>
						{beforeMedias.map((media) => (
							<Image key={media} width={100} src={media} />
						))}
					</Image.PreviewGroup>
					<ImageUpload
						isMultiple
						size="small"
						onChange={({ fileList }) => {
							if (fileList.length) {
								setNewBeforeMedias(fileList);
							} else {
								setNewBeforeMedias([]);
							}
						}}
						fileList={newBeforeMedias}
					/>
				</InnerRowContent>
			</Modal>
			{isAlertOpen && (
				<Alert
					title="작업사진 수정"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={handleSubmit}
					onConfirmButtonText="수정하기"
					isVisible={isAlertOpen}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							작업사진을 수정하시겠습니까?
						</Typo>
					</p>
				</Alert>
			)}
		</>
	);
}

export default WorkImageModal;
