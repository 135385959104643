import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
	Col,
	Divider,
	Radio,
	RadioChangeEvent,
	Row,
	Skeleton,
	Table,
	Tag,
} from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import TextArea from '../../atoms/TextArea';
import {
	useGetCarServicePaymentRequestQuery,
	useMakeCarServicePaymentRequestMutation,
	useUpdateCarServicePaymentRequestMutation,
	useMakeCarServiceTransfersMutation,
	useLazyGetCarServicePaymentRequestDetailQuery,
} from '../../../store/apis/carService';
import {
	CarServicePaymentRequest,
	DuzonTransfersDto,
} from '../../../interface/carService';
import useCheckRole from '../../../hooks/useCheckRole';
import {
	getPriceAsNumber,
	companyRegisterNumberFormat,
	numberWithCommas,
} from '../../../utils/data-format';
import Typo from '../../atoms/Typo';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import VerifyBankAccount from './VerifyBankAccount';

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	padding: 5px 0;
`;

export const requestColumns = [
	{
		title: '순번',
		dataIndex: 'proxyInspectionAmountPaymentRequestSequenceNo',
		key: 'proxyInspectionAmountPaymentRequestSequenceNo',
		width: 72,
	},
	{
		title: '처리일',
		dataIndex: 'proxyInspectionAmountPaymentRequestResultDate',
		key: 'proxyInspectionAmountPaymentRequestResultDate',
	},
	{
		title: '처리결과',
		dataIndex: 'proxyInspectionAmountPaymentRequestResult',
		key: 'proxyInspectionAmountPaymentRequestResult',
		render: (text: string) => {
			return <span style={{ fontWeight: 'bold' }}>{text}</span>;
		},
	},
	{
		title: '요청금액',
		key: 'proxyInspectionAmountPaymentRequestCost',
		// dataIndex: 'proxyInspectionAmountPaymentRequestInspectionCost',
		render: (record: CarServicePaymentRequest) => {
			return numberWithCommas(
				record.proxyInspectionAmountPaymentRequestInspectionCost +
					record.proxyInspectionAmountPaymentRequestAdditionalCost +
					record.proxyInspectionAmountPaymentRequestTransportCost -
					record.proxyInspectionAmountPaymentRequestDeductionCost,
			);
		},
	},
	{
		title: '요청일',
		dataIndex: 'proxyInspectionAmountPaymentRequestDate',
		key: 'proxyInspectionAmountPaymentRequestDate',
	},
	{
		title: '요청메모',
		dataIndex: 'proxyInspectionAmountPaymentRequestMemo',
		key: 'proxyInspectionAmountPaymentRequestMemo',
	},
];

interface InspectionPaymentTabPaneProps {
	inspectionId: string | undefined;
	carLicensePlateNumber: string | undefined;
	modifyCarLicensePlateNumber: string | undefined;
}

const InspectionPaymentTabPane = ({
	inspectionId,
	carLicensePlateNumber,
	modifyCarLicensePlateNumber,
}: InspectionPaymentTabPaneProps) => {
	const setToast = useContext(ToastContext);
	const [selectedSequenceNo, setSelectedSequenceNo] = useState(0);
	const [isVerified, setIsVerified] = useState(false);
	const [bankName, setBankName] = useState('');
	const [companyRegistrationNumber, setCompanyRegistrationNumber] =
		useState('');
	const [depositor, setDepositor] = useState('');
	const handleBankNameChange = (name: string) => {
		setBankName(name);
	};

	const handleVerifyBankAccount = (value: boolean) => {
		setIsVerified(value);
	};

	const isCreatePaymentRequest = useCheckRole({
		roleType: 'isCreate',
		roleName: '입금관리',
	});

	const isUpdatePaymentRequest = useCheckRole({
		roleType: 'isUpdate',
		roleName: '입금관리',
	});

	const { isLoading, data } = useGetCarServicePaymentRequestQuery(
		{ inspectionId },
		{ skip: !inspectionId },
	);

	const handleDepositorChange = (name: string) => {
		setDepositor(name); // Update the depositor state
	};

	const handleCompanyRegistrationNumberChange = (name: string) => {
		setCompanyRegistrationNumber(name); // Update the companyRegistrationNumber state
	};

	const [makePaymentRequest] = useMakeCarServicePaymentRequestMutation();

	const [updatePaymentRequest] = useUpdateCarServicePaymentRequestMutation();

	const [makeTransfers, { isLoading: isTransfersLoading, isSuccess }] =
		useMakeCarServiceTransfersMutation();

	const paymentRequest = data ? data.rows : [];

	const initialValues: Partial<CarServicePaymentRequest> = {
		proxyInspectionAmountPaymentRequestSequenceNo: 0,
		proxyInspectionAmountPaymentRequestDate: '',
		proxyInspectionAmountPaymentRequestType: '',
		proxyInspectionAmountPaymentRequestTypeCode: '',
		proxyInspectionAmountPaymentRequestAmount: 0,
		proxyInspectionAmountPaymentRequestInspectionCost: 0,
		proxyInspectionAmountPaymentRequestAdditionalCost: 0,
		proxyInspectionAmountPaymentRequestTransportCost: 0,
		proxyInspectionAmountPaymentRequestDeductionCost: 0,
		proxyInspectionAmountPaymentRequestMemo: '',
		proxyInspectionAmountPaymentRequestResultDate: '',
		proxyInspectionAmountPaymentRequestResult: '',
		proxyInspectionAmountPaymentRequestResultCode: '',
	};

	const duzonInitialValues: DuzonTransfersDto = {
		duzonTransferRequestBank: '', // 은행코드번호 (ex. 003)
		duzonTransferRequestAccount: '', // 계좌번호
		duzonTransferRequestName: '', // 예금주이름 (ex. mileopen)
		duzonTransferRequestProofType: '세금계산서', // 증빙유형
		duzonTransferRequestCompanyRegistrationNumber: '', // 사업자등록번호
	};

	const handleOperationClick = async (
		values: any,
		formikHelpers: FormikHelpers<any>,
		operationType: 'create' | 'update',
	) => {
		let res;
		let bodyValues;

		if (operationType === 'create') {
			bodyValues = {
				proxyInspectionAmountPaymentRequestInspectionCost:
					Number(
						values.row?.proxyInspectionAmountPaymentRequestInspectionCost,
					) || 0,
				proxyInspectionAmountPaymentRequestAdditionalCost:
					Number(
						values.row?.proxyInspectionAmountPaymentRequestAdditionalCost,
					) || 0,
				proxyInspectionAmountPaymentRequestTransportCost:
					Number(
						values.row?.proxyInspectionAmountPaymentRequestTransportCost,
					) || 0,
				proxyInspectionAmountPaymentRequestDeductionCost:
					Number(
						values.row?.proxyInspectionAmountPaymentRequestDeductionCost,
					) || 0,
				proxyInspectionAmountPaymentRequestMemo:
					values.row?.proxyInspectionAmountPaymentRequestMemo || '',
			};
			res = await makePaymentRequest({ inspectionId, body: bodyValues });
		} else if (operationType === 'update') {
			bodyValues = {
				proxyInspectionAmountPaymentRequestInspectionCost: Number(
					values.row?.proxyInspectionAmountPaymentRequestInspectionCost,
				),
				proxyInspectionAmountPaymentRequestAdditionalCost: Number(
					values.row?.proxyInspectionAmountPaymentRequestAdditionalCost,
				),
				proxyInspectionAmountPaymentRequestTransportCost: Number(
					values.row?.proxyInspectionAmountPaymentRequestTransportCost,
				),
				proxyInspectionAmountPaymentRequestDeductionCost: Number(
					values.row?.proxyInspectionAmountPaymentRequestDeductionCost,
				),
				proxyInspectionAmountPaymentRequestMemo:
					values.row?.proxyInspectionAmountPaymentRequestMemo,
			};

			res = await updatePaymentRequest({
				inspectionId,
				sequenceNo: selectedSequenceNo,
				body: bodyValues,
			});
		}

		if (res && 'error' in res) {
			const errorMessage =
				(res.error as CustomErrorType).data.translate ||
				(res.error as CustomErrorType).data.message;

			setToast('error', errorMessage);
		} else {
			await formikHelpers.setValues(initialValues);
		}
	};

	const {
		values: formikValues,
		setValues: setFormikValues,
		setFieldValue,
		handleSubmit: formikSubmit,
		resetForm,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (values, formikHelpers) => {
			if (selectedSequenceNo !== 0 && isUpdatePaymentRequest) {
				await handleOperationClick(values, formikHelpers, 'update');
			} else {
				await handleOperationClick(values, formikHelpers, 'create');
			}

			resetForm();
			setSelectedSequenceNo(0);
		},
	});

	const {
		values: duzonFormikValues,
		setFieldValue: setDuzonFieldValue,
		handleSubmit: formikDuzonSubmit,
		resetForm: resetDuzonForm,
	} = useFormik({
		initialValues: { ...duzonInitialValues },
		onSubmit: async (values, formikHelpers) => {
			if (!selectedSequenceNo) {
				setToast('error', '지급이체를 먼저 선택해주세요');
				return;
			}

			const bodyValues = {
				duzonTransferRequestAccount: values.duzonTransferRequestAccount,
				duzonTransferRequestBank: values.duzonTransferRequestBank,

				duzonTransferRequestCompanyRegistrationNumber:
					values.duzonTransferRequestCompanyRegistrationNumber ||
					companyRegistrationNumber,
				duzonTransferRequestName: values.duzonTransferRequestName || depositor,
				duzonTransferRequestProofType: values.duzonTransferRequestProofType,
			};

			const res = await makeTransfers({
				inspectionId,
				sequenceNo: selectedSequenceNo,
				body: bodyValues,
			});

			if (res && 'error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				setToast('error', errorMessage);
			} else {
				resetDuzonForm();

				await formikHelpers.setValues(duzonInitialValues);
				setToast('info', '계좌이체를 성공했습니다');
			}
		},
	});

	const calculateTotal = () => {
		const inspectionCost =
			Number(
				formikValues.row?.proxyInspectionAmountPaymentRequestInspectionCost,
			) || 0;
		const additionalCost =
			Number(
				formikValues.row?.proxyInspectionAmountPaymentRequestAdditionalCost,
			) || 0;
		const transportCost =
			Number(
				formikValues.row?.proxyInspectionAmountPaymentRequestTransportCost,
			) || 0;
		const deductionCost =
			Number(
				formikValues.row?.proxyInspectionAmountPaymentRequestDeductionCost,
			) || 0;

		return numberWithCommas(
			inspectionCost + additionalCost + transportCost - deductionCost,
		);
	};

	const [getCarServicePaymentRequestDetail, { isFetching }] =
		useLazyGetCarServicePaymentRequestDetailQuery();

	const handleRowDoubleClick = (record: CarServicePaymentRequest) => {
		const sequenceNo = record.proxyInspectionAmountPaymentRequestSequenceNo;
		getCarServicePaymentRequestDetail({
			inspectionId,
			sequenceNo,
		}).then((res: any) => {
			// lazy query 의 res typing 이슈는 찾아보자
			setFormikValues(res.data);
			setSelectedSequenceNo(sequenceNo);
		});
	};

	return (
		<Row gutter={[6, 6]}>
			<Col md={24}>
				<ContentBoxWithHeader title="" borderRadius="10px" $isCompact>
					{isLoading ? (
						<Skeleton active />
					) : (
						<Table
							dataSource={paymentRequest}
							columns={requestColumns}
							size="small"
							pagination={{ pageSize: 5 }}
							rowKey={(record) =>
								record.proxyInspectionAmountPaymentRequestSequenceNo
							}
							onRow={(record) => {
								return {
									onDoubleClick: () => handleRowDoubleClick(record),
								};
							}}
						/>
					)}
				</ContentBoxWithHeader>
			</Col>
			<Col md={24}>
				<Row gutter={[6, 6]}>
					<Col md={12}>
						<ContentBoxWithHeader
							title="지급이체정보"
							borderRadius="10px"
							$isCompact
							innerClassName="min-height-standard"
							rightAccessory={
								<ButtonContainer>
									<Button
										onClick={() => {
											resetForm();
											setSelectedSequenceNo(0);
										}}
										$buttonType={ButtonTypeType.PRIMARY_LIGHT}
										size="small"
										disabled={
											!isUpdatePaymentRequest || selectedSequenceNo === 0
										}
									>
										{selectedSequenceNo !== 0 && isUpdatePaymentRequest
											? '초기화'
											: '수정 권한없음'}
									</Button>
								</ButtonContainer>
							}
						>
							<GridLayout $columnCount={1} $isCompact>
								<GridItem
									key="requestDate"
									title="요청순번"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									{isFetching ? (
										<Skeleton.Input active block={false} size="small" />
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%',
												alignItems: 'center',
											}}
										>
											{formikValues?.row
												?.proxyInspectionAmountPaymentRequestSequenceNo ? (
												<Tag style={{ fontSize: '13px' }}>
													{
														formikValues.row
															.proxyInspectionAmountPaymentRequestSequenceNo
													}
												</Tag>
											) : null}
											<Typo style={{ marginLeft: '4px' }}>
												{formikValues?.row
													?.proxyInspectionAmountPaymentRequestType ?? ''}
											</Typo>
										</div>
									)}
								</GridItem>
								<GridItem
									key="requestType"
									title="요청일"
									$isCompact
									size={GridTitleSize.GT_X_SMALL}
								>
									{isFetching ? (
										<Skeleton.Input active block={false} size="small" />
									) : (
										<Typo style={{ marginLeft: '4px' }}>
											{formikValues?.row
												?.proxyInspectionAmountPaymentRequestDate ?? ''}
										</Typo>
									)}
								</GridItem>
								<GridItem
									key="requestResult"
									title="처리결과"
									$isCompact
									size={GridTitleSize.GT_X_SMALL}
								>
									{isFetching ? (
										<Skeleton.Input active block={false} size="small" />
									) : (
										<Typo style={{ marginLeft: '4px' }}>
											{formikValues?.row
												?.proxyInspectionAmountPaymentRequestResult ?? ''}
										</Typo>
									)}
								</GridItem>
								<GridItem
									key="processDate"
									title="처리일"
									$isCompact
									size={GridTitleSize.GT_X_SMALL}
								>
									{isFetching ? (
										<Skeleton.Input active block={false} size="small" />
									) : (
										<Typo style={{ marginLeft: '4px' }}>
											{formikValues?.row
												?.proxyInspectionAmountPaymentRequestResultDate ?? ''}
										</Typo>
									)}
								</GridItem>
							</GridLayout>
						</ContentBoxWithHeader>
						<ContentBoxWithHeader
							title="요청금액 상세"
							borderRadius="10px"
							$isCompact
							innerClassName="min-height-panel"
							rightAccessory={
								<ButtonContainer>
									{!selectedSequenceNo ? (
										<Button
											size="small"
											$buttonType={ButtonTypeType.PRIMARY}
											onClick={() => formikSubmit()}
											disabled={
												!isCreatePaymentRequest || calculateTotal() <= '0'
											}
										>
											{isCreatePaymentRequest ? '등록' : '등록 권한없음'}
										</Button>
									) : (
										<div style={{ display: 'flex' }}>
											<Button
												onClick={() => formikSubmit()}
												size="small"
												disabled={
													!isUpdatePaymentRequest || selectedSequenceNo === 0
												}
											>
												{selectedSequenceNo !== 0 && isUpdatePaymentRequest
													? '수정하기'
													: '수정 권한없음'}
											</Button>
										</div>
									)}
								</ButtonContainer>
							}
						>
							<GridItem
								key="proxyInspectionAmountPaymentRequestInspectionCost"
								title="검사비용 +"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="proxyInspectionAmountPaymentRequestInspectionCost"
									placeholder="검사비용을 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={numberWithCommas(
										formikValues.row
											?.proxyInspectionAmountPaymentRequestInspectionCost ?? 0,
									)}
									onChange={(e) => {
										setFieldValue(
											'row.proxyInspectionAmountPaymentRequestInspectionCost',
											getPriceAsNumber(e.target.value),
										);
									}}
									$isCompact
									unitText="원"
									$textAlign="right"
								/>
							</GridItem>
							<GridItem
								key="proxyInspectionAmountPaymentRequestAdditionalCost"
								title="추가비용 +"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="proxyInspectionAmountPaymentRequestAdditionalCost"
									placeholder="0"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={numberWithCommas(
										formikValues.row
											?.proxyInspectionAmountPaymentRequestAdditionalCost ?? 0,
									)}
									onChange={(e) => {
										setFieldValue(
											'row.proxyInspectionAmountPaymentRequestAdditionalCost',
											getPriceAsNumber(e.target.value),
										);
									}}
									$isCompact
									unitText="원"
									$textAlign="right"
								/>
							</GridItem>
							<GridItem
								key="proxyInspectionAmountPaymentRequestTransportCost"
								title="이동비용 +"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="proxyInspectionAmountPaymentRequestTransportCost"
									placeholder="0"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={numberWithCommas(
										formikValues.row
											?.proxyInspectionAmountPaymentRequestTransportCost ?? 0,
									)}
									onChange={(e) =>
										setFieldValue(
											'row.proxyInspectionAmountPaymentRequestTransportCost',
											getPriceAsNumber(e.target.value),
										)
									}
									$isCompact
									unitText="원"
									$textAlign="right"
								/>
							</GridItem>
							<GridItem
								key="proxyInspectionAmountPaymentRequestDeductionCost"
								title="차감비용 -"
								size={GridTitleSize.GT_X_SMALL}
								$isCompact
							>
								<Input
									name="proxyInspectionAmountPaymentRequestDeductionCost"
									placeholder="0"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={numberWithCommas(
										formikValues.row
											?.proxyInspectionAmountPaymentRequestDeductionCost ?? 0,
									)}
									onChange={(e) =>
										setFieldValue(
											'row.proxyInspectionAmountPaymentRequestDeductionCost',
											getPriceAsNumber(e.target.value),
										)
									}
									$isCompact
									unitText="원"
									$textAlign="right"
								/>
							</GridItem>
							<Divider style={{ margin: '10px' }} />
							<GridItem
								key="totalAmount"
								title="비용합계 ="
								$isCompact
								size={GridTitleSize.GT_X_SMALL}
							>
								<Typo style={{ marginLeft: '4px' }}>{calculateTotal()}원</Typo>
							</GridItem>
							<TextArea
								name="proxyInspectionAmountPaymentRequestMemo"
								placeholder="ex)비용상세 내용 등"
								value={
									formikValues.row?.proxyInspectionAmountPaymentRequestMemo ??
									''
								}
								onChange={(e) => {
									setFieldValue(
										'row.proxyInspectionAmountPaymentRequestMemo',
										e.target.value,
									);
								}}
								onReset={() =>
									setFieldValue('proxyInspectionAmountPaymentRequestMemo', '')
								}
								style={{ marginTop: '8px' }}
							/>
						</ContentBoxWithHeader>
					</Col>
					<Col md={12}>
						<VerifyBankAccount
							setFieldValue={setFieldValue}
							setDuzonFieldValue={setDuzonFieldValue}
							bankAccountFieldName={[
								'proxyInspectionAmountPaymentAccount',
								'duzonTransferRequestAccount',
							]}
							bankCodeFieldName={[
								'proxyInspectionAmountPaymentBank',
								'duzonTransferRequestBank',
							]}
							bankAccountOwnerFieldName={[
								'proxyInspectionAmountPaymentName',
								'duzonTransferRequestName',
							]}
							$columnCount={1}
							setBankName={handleBankNameChange}
							setDepositor={handleDepositorChange}
							setCompanyRegistrationNumber={
								handleCompanyRegistrationNumberChange
							}
							handleVerifyBankAccount={handleVerifyBankAccount}
						/>
						<ContentBoxWithHeader
							title="계좌이체"
							borderRadius="10px"
							$isCompact
							innerClassName="min-height-panel"
							rightAccessory={
								<ButtonContainer>
									<Button
										size="small"
										$buttonType={ButtonTypeType.PRIMARY}
										onClick={() => formikDuzonSubmit()}
										disabled={!isVerified || !depositor}
									>
										{isTransfersLoading ? '처리중' : '입금'}
										{isSuccess && !isTransfersLoading ? '완료' : null}
									</Button>
								</ButtonContainer>
							}
						>
							<GridLayout $columnCount={1} $isCompact>
								<GridItem
									key="proxyInspectionAmountPaymentBankName"
									title="은행기관"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									<Input
										name="proxyInspectionAmountPaymentBank"
										placeholder="은행기관"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										$isDisabled
										value={
											isVerified
												? duzonFormikValues.duzonTransferRequestBank
												: ''
										}
										onChange={(e) => {
											setFieldValue(
												'proxyInspectionAmountPaymentBank',
												e.target.value,
											);
											setDuzonFieldValue(
												'duzonTransferRequestBank',
												e.target.value,
											);
										}}
										$isCompact
									/>
									<Input
										name="proxyInspectionAmountPaymentBankName"
										placeholder="은행기관명"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										$isDisabled
										value={isVerified ? bankName : ''}
										onChange={(e) => {
											setFieldValue(
												'proxyInspectionAmountPaymentBankName',
												e.target.value,
											);
											setDuzonFieldValue(
												'duzonTransferRequestName',
												e.target.value,
											);
										}}
										$isCompact
									/>
								</GridItem>
								<GridItem
									key="proxyInspectionAmountPaymentAccount"
									title="계좌번호"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									<Input
										name="proxyInspectionAmountPaymentAccount"
										placeholder="계좌번호"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										$isDisabled
										value={
											isVerified
												? duzonFormikValues.duzonTransferRequestAccount
												: ''
										}
										onChange={(e) => {
											const inputValue = e.target.value;
											const cleanedValue = inputValue.replace(/[^0-9]/g, '');
											setFieldValue(
												'proxyInspectionAmountPaymentAccount',
												cleanedValue,
											);
											setDuzonFieldValue(
												'duzonTransferRequestAccount',
												cleanedValue,
											);
										}}
										$isCompact
									/>
								</GridItem>
								<GridItem
									key="duzonTransferRequestName"
									title="예금주"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									<Input
										name="duzonTransferRequestName"
										placeholder="예금주"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										$isDisabled
										value={isVerified ? depositor : ''}
										onChange={(e) => {
											setFieldValue('', e.target.value);
											setDuzonFieldValue(
												'duzonTransferRequestName',
												e.target.value,
											);
										}}
										$isCompact
									/>
								</GridItem>

								<Divider style={{ margin: '10px' }} />
								<GridItem
									key="proxyInspectionAmountPaymentRequestSequenceNo"
									title="요청순번"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									{formikValues?.row
										?.proxyInspectionAmountPaymentRequestSequenceNo ? (
										<Tag style={{ fontSize: '13px' }}>
											{formikValues?.row
												?.proxyInspectionAmountPaymentRequestSequenceNo || '0'}
										</Tag>
									) : null}
									<Typo>
										{modifyCarLicensePlateNumber || carLicensePlateNumber}
									</Typo>
								</GridItem>

								<GridItem
									key="proxyInspectionAmountPaymentAmount"
									title="이체금액"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
								>
									<Typo>{selectedSequenceNo && `${calculateTotal()}`}원</Typo>
								</GridItem>

								<GridItem
									key="duzonTransferRequestCompanyRegistrationNumber"
									title="사업자번호"
									$isCompact
									size={GridTitleSize.GT_X_SMALL}
								>
									<Input
										name="duzonTransferRequestCompanyRegistrationNumber"
										placeholder="사업자번호"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										maxLength={12}
										value={companyRegisterNumberFormat(
											duzonFormikValues.duzonTransferRequestCompanyRegistrationNumber ||
												companyRegistrationNumber,
										)}
										onChange={(e) => {
											setFieldValue(
												'duzonTransferRequestCompanyRegistrationNumber',
												e.target.value,
											);

											setDuzonFieldValue(
												'duzonTransferRequestCompanyRegistrationNumber',
												e.target.value,
											);
										}}
										onReset={() => {
											setFieldValue(
												'duzonTransferRequestCompanyRegistrationNumber',
												'',
											);

											setDuzonFieldValue(
												'duzonTransferRequestCompanyRegistrationNumber',
												'',
											);
										}}
										$isCompact
									/>
								</GridItem>

								<GridItem
									key="duzonTransferRequestProofType"
									title="증빙유형"
									size={GridTitleSize.GT_X_SMALL}
									$isCompact
									$isAutoHeight
								>
									<Radio.Group
										defaultValue="세금계산서"
										onChange={(e: RadioChangeEvent) => {
											setDuzonFieldValue(
												'duzonTransferRequestProofType',
												e.target.value,
											);

											setFieldValue(
												'proxyInspectionAmountPaymentProofType',
												e.target.value,
											);
										}}
										value={duzonFormikValues.duzonTransferRequestProofType}
										size="small"
									>
										<Radio value="세금계산서">
											<Typo $typoType="label2">세금계산서</Typo>
										</Radio>
										<Radio value="현금영수증">
											<Typo $typoType="label2">현금영수증</Typo>
										</Radio>
										<Radio value="기타(고객결제)">
											<Typo $typoType="label2">기타(고객결제)</Typo>
										</Radio>
									</Radio.Group>
								</GridItem>
							</GridLayout>
						</ContentBoxWithHeader>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default InspectionPaymentTabPane;
