import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BarChartMonthRequestItem } from '../../../../interface/carService';

interface BarChartMonthRequestProps {
	barMonthRequestData: BarChartMonthRequestItem[];
}

const BarChartMonthRequest = ({
	barMonthRequestData,
}: BarChartMonthRequestProps) => {
	const styles: Record<string, React.CSSProperties> = {
		root: {
			position: 'relative',
			width: '100%',
			height: '400px',
		},
		overlay: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, 0%)',
			fontSize: 36,
			color: 'black',
			textAlign: 'center',
			pointerEvents: 'none',
		},
		totalLabel: {
			fontSize: 24,
		},
	};

	return (
		<div style={styles.root}>
			<ResponsiveBar
				data={barMonthRequestData}
				keys={['접수건수']}
				indexBy="접수년월"
				margin={{ top: 40, right: 90, bottom: 40, left: 80 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				valueFormat=" >-,~g"
				indexScale={{ type: 'band', round: true }}
				// colors={{ scheme: 'set2' }}
				colors={{ scheme: 'nivo' }}
				borderRadius={3}
				borderColor={{
					from: 'color',
					modifiers: [
						['darker', 1.6],
						['opacity', 0.85],
					],
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: -25,
					legend: '',
					legendOffset: 44,
					legendPosition: 'end',
					truncateTickAt: 0,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendOffset: -45,
					legendPosition: 'end',
					truncateTickAt: 0,
				}}
				// enableTotals={false}
				labelSkipWidth={1}
				labelSkipHeight={1}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 12,
						effects: [
							{
								on: 'hover',
								style: { itemOpacity: 1 },
							},
						],
					},
				]}
				theme={{
					text: {
						fontFamily:
							'Pretendard, "Noto Sans KR", "Malgun Gothic", sans-serif',
						fontSize: '14',
					},
					axis: { ticks: { text: { fontSize: '12' } } },
					legends: { text: { fontSize: '11' } },
					labels: { text: { fontSize: '11' } },
				}}
				role="application"
				ariaLabel="검사대상 접수현황"
				// barAriaLabel={(e) =>
				// 	e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
				// }
			/>
			{/* <h5 className={`${subTitleClassName}`}>{subTitle}</h5> */}
		</div>
	);
};

export default BarChartMonthRequest;
