import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import ContentBox from '../../atoms/ContentBox';
import DetailHeader from '../../organisms/DetailHeader';

interface GridDetailTemplateProps {
	detailTitle: string;
	rightAccessory?: ReactNode;
	onBack?: () => void;
	$isCompact?: boolean;
}

export type DetailInformationType = {
	key: string;
	value: ReactNode;
	label: string;
	accessory?: ReactNode;
	span?: number;
};

export type DetailInformationListType<T> = {
	title?: string;
	content: T[];
};

const Layout = styled.article`
	display: flex;
	flex-direction: column;
	height: 100%;
	/* overflow: hidden; */
	background: #fff;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;
		overflow: auto;
		border: none;
	}
`;

function GridDetailTemplate({
	detailTitle,
	rightAccessory,
	onBack,
	$isCompact = false,
	children,
}: PropsWithChildren<GridDetailTemplateProps>) {
	return (
		<Layout>
			{$isCompact || (
				<DetailHeader
					detailTitle={detailTitle}
					rightAccessory={rightAccessory}
					onBack={onBack}
				/>
			)}
			<ContentBox
				className="content-wrapper"
				backgroundColor={theme.common.colors.gray_3}
				$isCompact={$isCompact}
			>
				{children}
			</ContentBox>
		</Layout>
	);
}

export default GridDetailTemplate;
