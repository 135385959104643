import React, { useContext } from 'react';
import { Drawer, Tag } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useUpdatePaymentsAndSettlementInfoMutation } from '../../../store/apis/carService';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Input from '../../atoms/Input';
import { PaymentsInfo, SettlementInfo } from '../../../interface/carService';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import TextArea from '../../atoms/TextArea';
import { numberWithCommas } from '../../../utils/data-format';
import Divider from '../../../styles/divider';
import Select from '../../atoms/Select';
import {
	ALL_OPTION_VALUE,
	INSPECTION_PAYMENT_STATUS,
	INSPECTION_PAYMENT_TYPE,
} from '../../../utils/const';
import DateTimePicker from '../../atoms/DateTimePicker';

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	margin-bottom: 16px;
`;

interface SettlementTabPaneProps {
	inspectionId: string | undefined;
	open: boolean;
	onClose: (() => void) | undefined;
	settlementInfoData: Partial<PaymentsInfo & SettlementInfo> | undefined;
	detailInfoRefetch: () => void;
}

const SettlementTabPane = ({
	inspectionId,
	open,
	onClose,
	settlementInfoData,
	detailInfoRefetch,
}: SettlementTabPaneProps) => {
	const setToast = useContext(ToastContext);

	const [updatePaymentsAndSettlement, { isLoading: isUpdating }] =
		useUpdatePaymentsAndSettlementInfoMutation();

	const initialValues: Partial<PaymentsInfo & SettlementInfo> =
		settlementInfoData || {
			purchaseInspectionAmount: 0,
			purchaseTransportAmount: 0,
			purchaseEtceteraAmount: 0,
			purchaseTotalAmount: 0,
			penaltyAmount: 0,
			phoneCallAmount: 0,
			proxyInspectionAmountPaymentDate: '',
			proxyInspectionAmountPaymentAmount: 0,
			proxyInspectionAmountPaymentMemo: '',
		};

	const handleSubmit = async (
		values: Partial<PaymentsInfo & SettlementInfo>,
		formikHelpers: FormikHelpers<any>,
	) => {
		const res = await updatePaymentsAndSettlement({
			inspectionId,
			body: {
				...values,
				proxyInspectionAmountPaymentDate: dayjs().format('YYYY-MM-DD'),
			},
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
			formikHelpers.setValues(initialValues);
		} else {
			detailInfoRefetch();
			setToast('info', '저장이 완료되었습니다.');
		}
	};

	const {
		// validateForm,
		// setValues,
		values: formikValues,
		// errors: formikErrors,
		// handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<Drawer open={open} width={980} closable={false} onClose={onClose}>
			<ContentBoxWithHeader title="정산관리" borderRadius="10px" $isCompact>
				<HeaderWrapper>
					<p>지급정보(VAT포함)</p>
					<p>고객청구정보(VAT포함)</p>
				</HeaderWrapper>
				<GridLayout $columnCount={2} $isCompact>
					<GridItem
						key="purchaseInspectionAmount"
						title="검사비매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseInspectionAmount"
							placeholder="검사비매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseInspectionAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseInspectionAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseInspectionAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="salesInspectionAmount"
						title="검사비매출"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="salesInspectionAmount"
							placeholder="검사비매출"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.salesInspectionAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'salesInspectionAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('salesInspectionAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="purchaseTransportAmount"
						title="이동비매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseTransportAmount"
							placeholder="이동비매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseTransportAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseTransportAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseTransportAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="salesTransportAmount"
						title="이동비매출"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="salesTransportAmount"
							placeholder="이동비매출"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.salesTransportAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'salesTransportAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('salesTransportAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="purchaseEtceteraAmount"
						title="기타매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseEtceteraAmount"
							placeholder="기타매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseEtceteraAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseEtceteraAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseEtceteraAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="salesEtceteraAmount"
						title="기타매출"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="salesEtceteraAmount"
							placeholder="기타매출"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(Number(formikValues.salesEtceteraAmount))}
							onChange={(e) =>
								setFieldValue(
									'salesEtceteraAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('salesEtceteraAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountPaymentAmount"
						title="매입총금액"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="proxyInspectionAmountPaymentAmount"
							placeholder="매입총금액"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							$isDisabled
							value={numberWithCommas(
								Number(formikValues.purchaseInspectionAmount ?? 0) +
									Number(formikValues.purchaseTransportAmount ?? 0) +
									Number(formikValues.purchaseEtceteraAmount ?? 0),
							)}
							onChange={(e) =>
								setFieldValue(
									'proxyInspectionAmountPaymentAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountBillingAmount"
						title="매출총금액"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="proxyInspectionAmountBillingAmount"
							placeholder="매출총금액"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							$isDisabled
							value={numberWithCommas(
								Number(formikValues.salesInspectionAmount ?? 0) +
									Number(formikValues.salesTransportAmount ?? 0) +
									Number(formikValues.salesEtceteraAmount ?? 0),
							)}
							onChange={(e) =>
								setFieldValue(
									'proxyInspectionAmountBillingAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountPaymentDate"
						title="매입일"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Tag color="blue" style={{ fontSize: '13px' }}>
							{formikValues.proxyInspectionAmountPaymentDate || '없음'}
						</Tag>
						<DateTimePicker
							name="proxyInspectionAmountPaymentDate"
							onChange={(date, dateString) => {
								setFieldValue('proxyInspectionAmountPaymentDate', dateString);
							}}
							placeholder="매입일 변경"
							style={{ borderRadius: '4px' }}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountBillingDate"
						title="매출일"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Tag color="blue" style={{ fontSize: '13px' }}>
							{formikValues.proxyInspectionAmountBillingDate || '없음'}
						</Tag>
						<DateTimePicker
							name="proxyInspectionAmountBillingDate"
							onChange={(date, dateString) => {
								setFieldValue('proxyInspectionAmountBillingDate', dateString);
							}}
							placeholder="매출일 변경"
							style={{ borderRadius: '4px' }}
						/>
					</GridItem>
				</GridLayout>
				<TextArea
					name="proxyInspectionAmountPaymentMemo"
					placeholder="매입 메모를 입력해주세요"
					value={formikValues.proxyInspectionAmountPaymentMemo}
					onChange={(e) => {
						setFieldValue('proxyInspectionAmountPaymentMemo', e.target.value);
					}}
					onReset={() => {
						setFieldValue('proxyInspectionAmountPaymentMemo', '');
					}}
					style={{ marginTop: '8px' }}
				/>
				<Divider $verticalGap={24} />
				<GridLayout title="정산정보" $isCompact $columnCount={2}>
					<GridItem
						key="paymentState"
						title="정산상태"
						$isCompact
						size={GridTitleSize.GT_X_SMALL}
					>
						<Select
							value={formikValues.paymentState ?? ''}
							onChange={(value: string) => setFieldValue('paymentState', value)}
							placeholder="정산상태"
							allowClear
						>
							{INSPECTION_PAYMENT_STATUS.filter(
								(item) => item.value !== ALL_OPTION_VALUE,
							).map((item, idx) => (
								<Select.Option value={item.value} key={idx.toString()}>
									{item.label}
								</Select.Option>
							))}
						</Select>
					</GridItem>
					<GridItem
						key="paymentStateModifyDate"
						title="정산일"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Tag color="blue" style={{ fontSize: '13px' }}>
							{formikValues.paymentStateModifyDate || '없음'}
						</Tag>
						<DateTimePicker
							name="paymentStateModifyDate"
							onChange={(date, dateString) => {
								setFieldValue('paymentStateModifyDate', dateString);
							}}
							placeholder="정산일 변경"
							style={{ borderRadius: '4px' }}
						/>
					</GridItem>
					<GridItem
						key="salesProfit"
						title="수익금액"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="salesProfit"
							placeholder="수익금액 = 매출총금액 - 매입총금액"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							$isDisabled
							value={numberWithCommas(
								Number(formikValues.salesInspectionAmount ?? 0) +
									Number(formikValues.salesTransportAmount ?? 0) +
									Number(formikValues.salesEtceteraAmount ?? 0) -
									Number(formikValues.purchaseInspectionAmount ?? 0) -
									Number(formikValues.purchaseTransportAmount ?? 0) -
									Number(formikValues.purchaseEtceteraAmount ?? 0),
							)}
							onChange={(e) =>
								setFieldValue(
									'salesProfit',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
						/>
					</GridItem>
					<GridItem
						key="paymentType"
						title="정산유형"
						$isCompact
						size={GridTitleSize.GT_X_SMALL}
					>
						<Select
							value={formikValues.paymentType ?? ''}
							onChange={(value: string) => setFieldValue('paymentType', value)}
							placeholder="정산유형"
							allowClear
						>
							{INSPECTION_PAYMENT_TYPE.filter(
								(item) => item.value !== ALL_OPTION_VALUE,
							).map((item, idx) => (
								<Select.Option value={item.value} key={idx.toString()}>
									{item.label}
								</Select.Option>
							))}
						</Select>
					</GridItem>
					<GridItem
						key="salesProfitExplaination"
						title="비고"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
						span={2}
					>
						수익금액 = 매출총금액 - 매입총금액
					</GridItem>
				</GridLayout>
				<TextArea
					name="proxyInspectionAmountSettlementMemo"
					placeholder="정산 메모를 입력해주세요"
					value={formikValues.proxyInspectionAmountSettlementMemo}
					onChange={(e) => {
						setFieldValue(
							'proxyInspectionAmountSettlementMemo',
							e.target.value,
						);
					}}
					onReset={() => {
						setFieldValue('proxyInspectionAmountSettlementMemo', '');
					}}
					style={{ marginTop: '8px' }}
				/>
				<Button
					size="default"
					$buttonType={ButtonTypeType.PRIMARY_LIGHT}
					onClick={() => formikSubmit()}
					style={{ marginTop: '8px' }}
				>
					{isUpdating ? '수정중..' : '수정하기'}
				</Button>
			</ContentBoxWithHeader>
		</Drawer>
	);
};

export default SettlementTabPane;
