import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface InputFormProps {
	className?: string;
	id?: string;
}

const InputFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
`;

function InputForm({
	children,
	className,
	id,
}: PropsWithChildren<InputFormProps>) {
	return (
		<InputFormContainer className={className} id={id}>
			{children}
		</InputFormContainer>
	);
}

export default InputForm;
