import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import DefaultLayout from '../../components/organisms/DefaultLayout';
import { RootState } from '../../store';

function PrivateRoutesGuard() {
	const sessionId = useSelector((state: RootState) => state.auth.user?.id);
	const isLogin = !!sessionId;

	return isLogin ? (
		<DefaultLayout hasAside>
			<Outlet />
		</DefaultLayout>
	) : (
		<Navigate to="/login" replace />
	);
}

export default PrivateRoutesGuard;
