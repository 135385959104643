const markTutorialCompleted = (userId: number, tutorialKey: string) => {
	const userKey = `tutorialguide_${userId}`;
	const storedValue = localStorage.getItem(userKey);

	if (storedValue) {
		const tutorialguide = JSON.parse(storedValue) || {};

		if (tutorialKey in tutorialguide) {
			tutorialguide[tutorialKey] = true;
			localStorage.setItem(userKey, JSON.stringify(tutorialguide));
		}
	}
};

export default markTutorialCompleted;
