import { CarLoadingBoxType, VehicleDetailDto } from './vehicle';
import { DriverDto } from './driver';
import { CompanyDto } from './company';
import { AdministratorDto } from './administrator';
import { SubscriptionCancelStatusType } from './termination';
import { CarCareSubscription } from './subscription';
import { SubscriptionProductDto } from './subscriptionProducts';

export const vehicleSubscriptionStatus = [
	'계약대기',
	'결제대기',
	'구독중',
	'재구독신청',
	'해지신청',
	'반납신청',
	'인수신청',
	'구독만료',
	'구독만료-인수',
	'구독만료-반납',
	'해지완료',
];
export type VehicleSubscriptionStatus =
	(typeof vehicleSubscriptionStatus)[number];

export const vehicleSubscriptionRequestStatus = [
	'구독신청',
	'견적발송',
	'견적확인',
	'계약발송',
	'계약완료',
] as const;
export type VehicleSubscriptionRequestStatus =
	(typeof vehicleSubscriptionRequestStatus)[number];

export const vehicleSubscriptionEndActionType = ['인수', '반납'] as const;
export type VehicleSubscriptionEndActionType =
	(typeof vehicleSubscriptionEndActionType)[number];

export const vehicleSubscriptionRequestEndType = [
	'재구독신청',
	'반납신청',
	'인수신청',
];
export type VehicleSubscriptionRequestEndType =
	(typeof vehicleSubscriptionRequestEndType)[number];

export const vehicleSubscriptionRequestType = [
	'신차출고',
	'중고차출고',
] as const;
export type VehicleSubscriptionRequestType =
	(typeof vehicleSubscriptionRequestType)[number];

export interface RequestVehicleSubscription {
	subscriptionStartedAt: string | null;
	subscriptionPeriod: number;
	vehicleType: VehicleSubscriptionRequestType;
	vehicleCategory?: string;
	vehicleName?: string;
	carLoadingBoxType: CarLoadingBoxType;
	loadingCapacity: number;
	vehicleCount: number;
	requestVehiclePlate: boolean;
	paymentMethod: string;
	subscriptionProductId: number;
}

export interface BasicSubscription {
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	subscriptionNo: string;
	vehicle: VehicleDetailDto;
	subscriptionStartedAt: string;
	subscriptionEndedAt: string;
	completedAt: string;
	cancelRequestedAt: string;
	cancelApprovedAt: string;
	cancelConfirmedAt: string;
	subscriptionCarCare: CarCareSubscription;
	subscriptionVehicle?: Record<any, any>;
	admin?: AdministratorDto;
	subscriptionCancelStatus: SubscriptionCancelStatusType;
	accountId: number;
	accountType: string;
	clientType: 'COMPANY' | 'CUSTOMER';
	clientId: number | null;
	clientName: string | null;
}

export interface VehicleSubscription {
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	subscription: BasicSubscription;
	subscriptionNo: string;
	subscriptionPeriod: number;
	company: CompanyDto;
	driver: DriverDto | null;
	endActionType: VehicleSubscriptionEndActionType;
	paymentMethod: string;
	status: VehicleSubscriptionStatus;
	subscriptionPrice: number;
	vehiclePrice: number;
	prePaymentPrice: number;
	acquisitionPrice: number;
	agCommissionPct: number;
	eContract: string;
}

export interface VehicleSubscriptionRequestInfo
	extends Omit<RequestVehicleSubscription, 'subscriptionProductId'> {
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	subscriptionVehicleRequestNo: string;
	status: VehicleSubscriptionRequestStatus;
	quoteSentAt: string;
	quoteApprovedAt: string;
	contractAt: string;
}

export interface VehicleSubscriptionRequestInfoAdmin
	extends VehicleSubscriptionRequestInfo {
	company: CompanyDto;
}

export interface MakeVehicleSubscriptionQuote {
	subscriptionStartedAt: string | null;
	subscriptionEndedAt: string | null;
	subscriptionPeriod: number;
	endActionType: VehicleSubscriptionEndActionType;
	subscriptionPrice: number;
	vehiclePrice: number;
	prePaymentPrice: number;
	acquisitionPrice: number;
	agCommissionPct: number;
	vehicleId: number;
	subscriptionProductId?: number;
}

export interface MakeVehicleSubscriptionContract
	extends MakeVehicleSubscriptionQuote {
	paymentMethod: string;
}

export interface EditVehicleSubscriptionContract {
	vehicleId?: number;
	subscriptionPeriod?: number;
	endActionType?: VehicleSubscriptionEndActionType;
	vehiclePrice?: number;
	prePaymentPrice?: number;
	acquisitionPrice?: number;
	agCommissionPct?: number;
	paymentMethod?: string;
	subscriptionPrice?: number;
	subscriptionStartedAt?: string;
	subscriptionEndedAt?: string;
	eContract?: string;
	status?: VehicleSubscriptionStatus;
	memo: string;
}

export interface RegisterVehicleSubscriptionContract
	extends Omit<MakeVehicleSubscriptionContract, 'vehicleId'> {
	clientType: 'CUSTOMER' | 'COMPANY';
	clientId: number;
	clientName: string;
	eContract: string;
	svInfoList: {
		vehicleId: number;
		subscriptionPeriod?: number;
		endActionType?: VehicleSubscriptionEndActionType;
		vehiclePrice?: number;
		prePaymentPrice?: number;
		acquisitionPrice?: number;
		agCommissionPct?: number;
		paymentMethod?: string;
		subscriptionPrice?: number;
		subscriptionStartedAt?: string | null;
		subscriptionEndedAt?: string | null;
		subscriptionProductId?: number;
		eContract?: string;
	}[];
}

export interface VehicleSubscriptionQuoteInfo
	extends Pick<
		VehicleSubscription,
		| 'createdAt'
		| 'updatedAt'
		| 'deletedAt'
		| 'endActionType'
		| 'subscriptionPrice'
		| 'vehiclePrice'
		| 'prePaymentPrice'
		| 'acquisitionPrice'
		| 'agCommissionPct'
	> {
	subscriptionProduct: SubscriptionProductDto;
	subscriptionStartedAt: string;
	subscriptionEndedAt: string;
	subscriptionPeriod: number;
	vehicle: VehicleDetailDto;
	subscriptionVehicleRequestNo: string;
	vehicleIndex: number;
}

export interface VehicleSubscriptionRequestEnd {
	endType: VehicleSubscriptionRequestEndType;
	contactName: string;
	contactPhoneNumber: string;
	returnAddrStreet?: string;
	returnAddrZibun?: string;
	returnAddrDetail?: string;
	vehicleNumberList: string[];
}

export interface VehicleSubscriptionCancellationInfo {
	createdAt: string;
	updatedAt: string;
	id: number;
	deletedAt: string | null;
	subscriptionNo: string;
	subscriptionType: 'SUBSCRIPTION_VEHICLE';
	admin: AdministratorDto | null;
	cancellationFee: number | null;
	cancellationWord: string | null;
	paymentMethod: string;
	cancelWithdrewAt: string | null;
	cancelApprovedAt: string | null;
	cancelConfirmedAt: string | null;
	subscriptionVehicle: VehicleSubscription;
}

export interface VehicleSubscriptionPolicy {
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	vehicleType: VehicleSubscriptionRequestType;
	minMonth: number;
	maxMonth: number;
	description: string;
	fundingRatePct: number;
	prePaymentPct: number;
	acquisitionPricePct: number;
	irr: number;
	vehicleRegistrationTax: number;
	insurancePrice: number;
	vehiclePlatePrice: number;
}

export interface EditVehicleSubscriptionPolicy
	extends Omit<
		VehicleSubscriptionPolicy,
		'createdAt' | 'updatedAt' | 'deletedAt'
	> {}

export interface ContractUpdatedHistory {
	historyActionType: 'UPDATE' | 'DELETE';
	memo: string;
	admin: AdministratorDto;
	payload?: Record<any, any>;
	id: number;
	createdAt: string;
}
