export const vehicleSubscriptionConsultationType = [
	'일 렌트',
	'월 렌트',
	'장기렌트',
	'보험대차',
];

export const vehicleSubscriptionConsultationCustomerType = [
	'개인',
	'법인',
	'개인사업자',
];

export type CustomerType = '개인' | '법인' | '개인사업자';
export interface MakeVehicleSubscriptionConsultingDto {
	consultationType: string;
	customerType: CustomerType;
	customerName: string;
	customerPhone: string;
	customerEmail: string;
	contactName: string;
	subscriptionStartedAt?: string | null;
	subscriptionEndedAt?: string | null;
	vehicleName: string;
	memo: string;
}

export interface VehicleSubscriptionConsultingDetailDto
	extends MakeVehicleSubscriptionConsultingDto {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: null;
	adminId: number;
	historyList: string[];
	payload: Record<any, any>;
}

export interface UpdateVehicleSubscriptionConsultingDto {
	consultationType?: string;
	customerType?: CustomerType;
	customerName?: string;
	customerPhone?: string;
	customerEmail?: string;
	contactName?: string;
	subscriptionStartedAt?: string | null;
	subscriptionEndedAt?: string | null;
	vehicleName?: string;
	memo?: string;
	historyList?: string[];
}
