import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, SelectionGridColumn } from '../../../utils/grid-util';
import {
	renderProductTypeCell,
	renderSubscriptionItemStatusCell,
	renderManagerStatusCell,
} from '../../../utils/row-data-util';

const renderDate = (value: string, format: string) => {
	return dayjs(value).format(format);
};

export const circuitInspectionGridData: SelectionGridColumn = {
	colsList: [
		{
			headerName: '관리자 배정 시간',
			field: 'managerAssignedAt',
			flex: 1,
			minWidth: ColSize.CW_DATE_RANGE,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value ? renderDate(value, 'YYYY.MM.DD(dd) HH:mm:ss') : '-',
		},
		{
			headerName: '작업상태',
			field: 'subscriptionItemStatus',
			flex: 1,
			maxWidth: 110,
			minWidth: 110,
			cellRenderer: renderSubscriptionItemStatusCell,
		},
		{
			headerName: '매니저 작업상태',
			field: 'managerStatus',
			minWidth: ColSize.CW_MEDIUM,
			flex: 1,
			cellRenderer: renderManagerStatusCell,
		},
		{
			headerName: '고객명',
			field: 'customerName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '고객 전화번호',
			field: 'customerPhone',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '배정 일시',
			field: 'expectationVisitedAt',
			flex: 1,
			minWidth: ColSize.CW_DATE_RANGE,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value ? renderDate(value, 'YYYY.MM.DD(dd) A hh:mm') : '-',
		},
		{
			headerName: '서비스 일시',
			field: 'completeConfirmedAt',
			flex: 1,
			minWidth: ColSize.CW_DATE_RANGE,
			cellRenderer: ({ value }: ICellRendererParams) =>
				value ? renderDate(value, 'YYYY.MM.DD(dd) HH:mm:ss') : '-',
		},
		{
			headerName: '서비스 유형',
			field: 'productType',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: renderProductTypeCell,
		},
		{
			headerName: '작업내용',
			field: 'tasks',
			flex: 1,
			minWidth: ColSize.CW_LONG_TEXT,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value.length
					? `${value[0].productName} 외 ${value.length - 1}건`
					: '-';
			},
		},
		{
			headerName: '관리자명',
			field: 'administratorName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '매니저명',
			field: 'managerName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
	],
};

export default circuitInspectionGridData;
