import React from 'react';
import DefaultLayout from '../../../components/organisms/DefaultLayout';
import LoginTemplate from '../../../components/templates/Login';

function Login() {
	return (
		<DefaultLayout>
			<LoginTemplate title="로그인" />
		</DefaultLayout>
	);
}

export default Login;
