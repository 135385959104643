import { FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import {
	useGetBankCodesQuery,
	useLazyCheckAccountQuery,
} from '../../../store/apis/carService';
import { LabelValue } from '../../../utils/const';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';

interface VerifyBankAccountProps {
	bankCodeFieldName: string[];
	bankAccountFieldName: string[];
	bankAccountOwnerFieldName: string[];
	verifiedDateFieldName?: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<FormikErrors<any>> | Promise<void>;
	setDuzonFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<FormikErrors<any>> | Promise<void>;
	$columnCount?: number;
	setBankName: (name: string) => void;
	setDepositor: (name: string) => void;
	setCompanyRegistrationNumber: (name: string) => void;
	handleVerifyBankAccount: any;
}

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	padding: 5px 0;
`;

const VerifyBankAccount = ({
	bankCodeFieldName,
	bankAccountFieldName,
	bankAccountOwnerFieldName,
	verifiedDateFieldName,
	setFieldValue,
	setDuzonFieldValue,
	$columnCount = 4,
	setBankName,
	setDepositor,
	setCompanyRegistrationNumber,
	handleVerifyBankAccount,
}: VerifyBankAccountProps) => {
	const [bankCode, setBankCode] = useState('');
	const [bankAccount, setBankAccount] = useState('');

	const { data: bankCodes } = useGetBankCodesQuery({
		bankCodeKey: 'B0001',
	});

	const bankCodeLabel: LabelValue[] = bankCodes
		? bankCodes.rows.map((row) => ({ label: row.NAME, value: row.CODE }))
		: [];

	const [
		checkAccount,
		{ data: depositorResult, isError, isSuccess, isLoading },
	] = useLazyCheckAccountQuery();

	const depositor =
		depositorResult && !isError ? depositorResult.row.depositor : '';

	const companyRegistrationNumber =
		depositorResult && !isError
			? depositorResult.row.companyRegistrationNumber
			: '';

	useEffect(() => {
		setDepositor(depositor);
		setCompanyRegistrationNumber(companyRegistrationNumber);
		if (isSuccess) {
			handleVerifyBankAccount(true);
		} else {
			handleVerifyBankAccount(false);
		}
	}, [
		companyRegistrationNumber,
		depositor,
		handleVerifyBankAccount,
		isSuccess,
		setCompanyRegistrationNumber,
		setDepositor,
	]);

	const handleBankNameChange = (name: string) => {
		setBankName(name);
	};

	const handleSetFieldValue = () => {
		bankCodeFieldName.forEach((fieldName) => {
			setFieldValue(fieldName, bankCode);
			setDuzonFieldValue(fieldName, bankCode);
		});

		bankAccountFieldName.forEach((fieldName) => {
			setFieldValue(fieldName, bankAccount);
			setDuzonFieldValue(fieldName, bankAccount);
		});

		bankAccountOwnerFieldName.forEach((fieldName) => {
			setFieldValue(fieldName, depositor);
			setDuzonFieldValue(fieldName, depositor);
		});

		if (verifiedDateFieldName) {
			setFieldValue(verifiedDateFieldName, dayjs().format('YYYY-MM-DD'));
		}
	};

	const resetState = () => {
		setDepositor('');
		setCompanyRegistrationNumber('');
		handleVerifyBankAccount(false);
	};

	return (
		<ContentBoxWithHeader
			title="예금주 확인"
			borderRadius="10px"
			$isCompact
			innerClassName="min-height-standard"
			rightAccessory={
				<ButtonContainer>
					<Button
						size="small"
						$buttonType={ButtonTypeType.PRIMARY_LIGHT}
						onClick={() => {
							handleSetFieldValue();
							checkAccount({
								bankCode,
								bankAccount,
							});
							resetState();
						}}
						disabled={!bankCode || !bankAccount || isSuccess}
					>
						{isSuccess ? '확인완료' : '예금주 확인'}
						{isLoading && ' (로딩중)'}
						{isError && ' (실패)'}
					</Button>
				</ButtonContainer>
			}
		>
			<GridLayout $columnCount={$columnCount} $isCompact>
				<GridItem
					key="bankCode"
					title="은행기관"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Select
						value={bankCode}
						onChange={(value: string, option) => {
							setBankCode(value);
							if (value) {
								handleBankNameChange(option.children);
							} else {
								handleBankNameChange('');
							}
						}}
						placeholder="은행기관"
						allowClear
						disabled={isSuccess}
						style={{ minWidth: '100px', fontSize: '14px' }}
					>
						{bankCodeLabel.map((item, idx) => (
							<Select.Option value={item.value} key={idx.toString()}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</GridItem>
				<GridItem
					key="bankAccount"
					title="계좌번호"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Input
						name="bankAccount"
						placeholder="계좌번호"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						$isDisabled={isSuccess}
						value={bankAccount}
						onChange={(e) => {
							const formattedValue = e.target.value.replace(/[^\d]/g, '');
							setBankAccount(formattedValue);
						}}
						onReset={() => setBankAccount('')}
						$isCompact
					/>
				</GridItem>
			</GridLayout>
		</ContentBoxWithHeader>
	);
};

export default VerifyBankAccount;
