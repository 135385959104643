import React from 'react';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import InspectionsExcelDownload from '../../../components/templates/CarServiceTemplate/InspectionsExcelDownload';
import PaymentsExcelDownload from '../../../components/templates/CarServiceTemplate/PaymentsExcelDownload';
import CallHistoryExcelDownload from '../../../components/templates/CarServiceTemplate/CallHistoryExcelDownload';

const CarServiceExcelDownload = () => {
	return (
		<GridDetailTemplate detailTitle="통합 다운로드">
			<InspectionsExcelDownload />
			<PaymentsExcelDownload />
			<CallHistoryExcelDownload />
		</GridDetailTemplate>
	);
};

export default CarServiceExcelDownload;
