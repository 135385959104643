import ExcelIcon from './icon-excel.svg';
import MainLogoIcon from './icon-main-logo.svg';
import ArrowUpIcon from './icon-arrow_top.svg';
import ArrowDownIcon from './icon-arrow_down.svg';
import ArrowLeftIcon from './icon-arrow_left.svg';
import ArrowRightIcon from './icon-arrow_right.svg';
import UserIcon from './icon-user.svg';
import DeleteIcon from './icon-delete.svg';
import GrayBackIcon from './icon-back_light_gray.svg';
import BlackBackIcon from './icon-back_black.svg';
import NestableMinusIcon from './icon-nestable-minus.svg';
import PlusIcon from './icon-plus.svg';
import WhiteListIcon from './icon-list-white.svg';
import WhiteMapIcon from './icon-map-white.svg';
import GrayNoticeIcon from './icon-notice-gray.svg';
import DetailIcon from './icon-detail.svg';
import CalendarIcon from './icon-calendar.svg';
import GrayCalendarIcon from './icon-calendar-gray.svg';
import ClockIcon from './icon-clock.svg';
import SelectArrow from './icon-select-arrow.svg';
import Truck from './truck.svg';

// checkbox
import CircleCheckIcon from './icon-circle_check.svg';
import CircleCheckNoneIcon from './icon-circle_none-check.svg';
import SquareCheckIcon from './icon-square_check.svg';
import SquareCheckNoneIcon from './icon-square_none-check.svg';
import TransparentCheckIcon from './icon-transparent_check.svg';
import TransparentCheckNoneIcon from './icon-transparent_none-check.svg';
import RadioCheckIcon from './icon-radio-check.svg';
import RadioCheckNoneIcon from './icon-radio-uncheck.svg';

// input Alert
import InputErrorIcon from './icon-input_error.svg';
import InputSuccesIcon from './icon-input_success.svg';
import InputPendingIcon from './icon-input_pending.svg';
import InputTypePasswordIcon from './icon-input_type_password.svg';
import InputTypePasswordVisibleIcon from './icon-input_type_password_visible.svg';

import TaskMenuIcon from './icon-task-menu.svg';
import CsMenuIcon from './icon-cs-menu.svg';
import InventoryMenuIcon from './icon-inventory-menu.svg';
import TermsMenuIcon from './icon-terms-menu.svg';
import MarketingMenuIcon from './icon-marketing-menu.svg';
import OrderMenuIcon from './icon-order-menu.svg';
import ProductMenuIcon from './icon-product-menu.svg';
import SettlementMenuIcon from './icon-settlement-menu.svg';
import SalesMenuIcon from './icon-sales-menu.svg';
import ResetIcon from './icon-reset.svg';

// toast
import ToastNoticeIcon from './icon-toast-notice.svg';
import ToastCloseIcon from './icon-toast_close.svg';

// upload
import ImageUploadIcon from './icon-image_upload.svg';
import FileUploadIcon from './icon-file_upload.svg';

import SelectGridNoDataIcon from './icon-select-grid_nodata.svg';

// pin
import WorkDoneOnPin from './icon-pin-work-done-on.svg';
import WorkDoneOffPin from './icon-pin-work-done-off.svg';
import WorkPendingOnPin from './icon-pin-work-pending-on.svg';
import WorkPendingOffPin from './icon-pin-work-pending-off.svg';
import WorkRequestOnPin from './icon-pin-work-request-on.svg';
import WorkRequestOffPin from './icon-pin-work-request-off.svg';
import WorkingOnPin from './icon-pin-working-on.svg';
import WorkingOffPin from './icon-pin-working-off.svg';
import ManagerOnPin from './icon-pin-manager-on.svg';
import ManagerOffPin from './icon-pin-manager-off.svg';

import CircleArrow from './icon-circle-arrow.png';
import CircleArrowDisable from './icon-circle-arrow_disable.png';

// menu icon
import menu01 from './menu/01.png';
import menu02 from './menu/02.png';
import menu03 from './menu/03.png';
import menu04 from './menu/04.png';
import menu05 from './menu/05.png';
import menu06 from './menu/06.png';
import menu07 from './menu/07.png';
import menu08 from './menu/08.png';
import menu09 from './menu/09.png';
import menu10 from './menu/10.png';

// map icon
import clusterMarker1 from './mapIcon/cluster-marker-1.png';
import clusterMarker2 from './mapIcon/cluster-marker-2.png';
import clusterMarker3 from './mapIcon/cluster-marker-3.png';
import clusterMarker4 from './mapIcon/cluster-marker-4.png';
import clusterMarker5 from './mapIcon/cluster-marker-5.png';

export const TruckICon = Truck;

export const circleArrowIcon = CircleArrow;
export const circleArrowDisableIcon = CircleArrowDisable;

export const excelIcon = ExcelIcon;
export const mainLogoIcon = MainLogoIcon;
export const arrowUpIcon = ArrowUpIcon;
export const arrowDownIcon = ArrowDownIcon;
export const arrowLeftIcon = ArrowLeftIcon;
export const arrowRightIcon = ArrowRightIcon;
export const userIcon = UserIcon;
export const deleteIcon = DeleteIcon;
export const grayBackIcon = GrayBackIcon;
export const blackBackIcon = BlackBackIcon;
export const nestableMinusIcon = NestableMinusIcon;
export const plusIcon = PlusIcon;
export const whiteListIcon = WhiteListIcon;
export const whiteMapIcon = WhiteMapIcon;
export const grayNoticeIcon = GrayNoticeIcon;
export const detailIcon = DetailIcon;
export const calendarIcon = CalendarIcon;
export const grayCalendarIcon = GrayCalendarIcon;
export const clockIcon = ClockIcon;
export const selectArrow = SelectArrow;

export const circleCheckIcon = CircleCheckIcon;
export const circleCheckNoneIcon = CircleCheckNoneIcon;
export const squareCheckIcon = SquareCheckIcon;
export const squareCheckNoneIcon = SquareCheckNoneIcon;
export const transparentCheckIcon = TransparentCheckIcon;
export const transparentCheckNoneIcon = TransparentCheckNoneIcon;
export const radioCheckIcon = RadioCheckIcon;
export const radioCheckNoneIcon = RadioCheckNoneIcon;

export const inputErrorIcon = InputErrorIcon;
export const inputSuccesIcon = InputSuccesIcon;
export const inputPendingIcon = InputPendingIcon;
export const inputTypePasswordIcon = InputTypePasswordIcon;
export const inputTypePasswordVisibleIcon = InputTypePasswordVisibleIcon;

export const taskMenuIcon = TaskMenuIcon;
export const csMenuIcon = CsMenuIcon;
export const inventoryMenuIcon = InventoryMenuIcon;
export const termsMenuIcon = TermsMenuIcon;
export const marketingMenuIcon = MarketingMenuIcon;
export const orderMenuIcon = OrderMenuIcon;
export const productMenuIcon = ProductMenuIcon;
export const settlementMenuIcon = SettlementMenuIcon;
export const salesMenuIcon = SalesMenuIcon;
export const resetIcon = ResetIcon;

export const toastNoticeIcon = ToastNoticeIcon;
export const toastCloseIcon = ToastCloseIcon;

export const imageUploadIcon = ImageUploadIcon;
export const fileUploadIcon = FileUploadIcon;

export const selectGridNoDataIcon = SelectGridNoDataIcon;

export const workDoneOnPin = WorkDoneOnPin;
export const workDoneOffPin = WorkDoneOffPin;
export const workPendingOnPin = WorkPendingOnPin;
export const workPendingOffPin = WorkPendingOffPin;
export const workRequestOnPin = WorkRequestOnPin;
export const workRequestOffPin = WorkRequestOffPin;
export const workingOnPin = WorkingOnPin;
export const workingOffPin = WorkingOffPin;
export const managerOnPin = ManagerOnPin;
export const managerOffPin = ManagerOffPin;

export const menu01Icon = menu01;
export const menu02Icon = menu02;
export const menu03Icon = menu03;
export const menu04Icon = menu04;
export const menu05Icon = menu05;
export const menu06Icon = menu06;
export const menu07Icon = menu07;
export const menu08Icon = menu08;
export const menu09Icon = menu09;
export const menu10Icon = menu10;

export const clusterMarkerIcon1 = clusterMarker1;
export const clusterMarkerIcon2 = clusterMarker2;
export const clusterMarkerIcon3 = clusterMarker3;
export const clusterMarkerIcon4 = clusterMarker4;
export const clusterMarkerIcon5 = clusterMarker5;
