import React from 'react';
import { Col, Row, Skeleton, Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import { useGetCarServiceTotalPaymentRequestQuery } from '../../../store/apis/carService';

const requestColumns = [
	{
		title: '지급유형',
		dataIndex: 'proxyInspectionAmountPaymentRequestType',
		key: 'proxyInspectionAmountPaymentRequestType',
	},
	{
		title: '대상건수',
		dataIndex: 'proxyInspectionAmountPaymentRequestTypeCode',
		key: 'proxyInspectionAmountPaymentRequestTypeCode',
	},
	{
		title: '대상금액',
		dataIndex: 'proxyInspectionAmountPaymentRequestAmount',
		key: 'proxyInspectionAmountPaymentRequestAmount',
	},
	{
		title: '지급결과',
		dataIndex: 'proxyInspectionAmountPaymentRequestResult',
		key: 'proxyInspectionAmountPaymentRequestResult',
	},
];

interface InspectionPaymentTabPaneProps {
	inspectionId: string | undefined;
}

const InspectionPaymentStatusTabPane = ({
	inspectionId,
}: InspectionPaymentTabPaneProps) => {
	const { isLoading: isStatusLoading, data } =
		useGetCarServiceTotalPaymentRequestQuery(
			{
				inspectionId,
			},
			{
				skip: !inspectionId,
			},
		);

	const totalPaymentRequest = data ? data.rows : [];

	return (
		<Row gutter={[6, 6]}>
			<Col span={24}>
				<ContentBoxWithHeader
					title="계좌이체 결과"
					borderRadius="10px"
					$isCompact
				>
					{isStatusLoading ? (
						<Skeleton active />
					) : (
						<Table
							dataSource={totalPaymentRequest}
							columns={requestColumns}
							size="small"
							pagination={false}
							rowKey={() => uuidv4()}
							onRow={(record, index) => {
								return {
									style:
										index === totalPaymentRequest.length - 1
											? { backgroundColor: '#91caff' }
											: {},
								};
							}}
						/>
					)}
				</ContentBoxWithHeader>
			</Col>
		</Row>
	);
};

export default InspectionPaymentStatusTabPane;
