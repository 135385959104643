import { authApiSlice } from '../query';
import {
	VehicleStatusItem,
	CreateVehicleStatus,
	VehicleStatusDto,
} from '../../../interface/vehicleStatus';
import { ObjectResponse, ListResponse } from '../@types';

const vehicleStatusApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVehicleStatusItems: builder.query<ListResponse<VehicleStatusItem>, {}>({
			query: () => `/members/vehicles/status/items`,
		}),
		getVehiclesStatus: builder.query<
			ListResponse<VehicleStatusDto>,
			{
				vehicleNumber: string | undefined;
				managerName: string | undefined;
				page: number;
				pageSize: number;
			}
		>({
			query: ({ vehicleNumber, managerName, page, pageSize }) => {
				return {
					url: `/members/admin/vehicles/status`,
					params: { vehicleNumber, managerName, page, pageSize },
				};
			},
			providesTags: ['VehicleStatus'],
		}),
		getVehicleStatus: builder.query<
			ObjectResponse<VehicleStatusDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => {
				return {
					url: `/members/admin/vehicles/status/${id}`,
				};
			},
			providesTags: (args) => [{ type: 'VehicleStatus', id: args?.row.id }],
		}),
		makeVehicleStatus: builder.mutation<
			ObjectResponse<VehicleStatusDto>,
			CreateVehicleStatus
		>({
			query: (body) => {
				return {
					method: 'POST',
					url: `/members/admin/vehicles/status`,
					body,
				};
			},
			invalidatesTags: ['VehicleStatus'],
		}),
		updateVehicleStatus: builder.mutation<
			ObjectResponse<VehicleStatusDto>,
			{ id: number } & CreateVehicleStatus
		>({
			query: (args) => {
				const { id, ...body } = args;
				return {
					method: 'PUT',
					url: `/members/admin/vehicles/status/${id}`,
					body: { ...body },
				};
			},
			invalidatesTags: ['VehicleStatus'],
		}),
	}),
});

export const {
	useLazyGetVehicleStatusItemsQuery,
	useGetVehiclesStatusQuery,
	useGetVehicleStatusQuery,
	useGetVehicleStatusItemsQuery,
	useMakeVehicleStatusMutation,
	useUpdateVehicleStatusMutation,
} = vehicleStatusApi;
export default vehicleStatusApi;
