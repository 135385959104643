/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeVehicleSubscriptionQuote } from '../../interface/vehicleSubscription';

interface VSQuoteState {
	quotes?: Record<string, MakeVehicleSubscriptionQuote>;
}

const initialState: VSQuoteState = {};

const vsSlice = createSlice({
	name: 'vsQuotes',
	initialState,
	reducers: {
		setVSQuotes(
			state: VSQuoteState,
			action: PayloadAction<
				Record<string, MakeVehicleSubscriptionQuote> | undefined
			>,
		) {
			state.quotes = action.payload;
		},
	},
});

export const { setVSQuotes } = vsSlice.actions;
export default vsSlice.reducer;
