/* eslint-disable no-use-before-define */
import { AxiosError } from 'axios';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import Dot from '../../atoms/Dot';
import SelectionGrid from '../../atoms/SelectionGrid';
import Tab from '../../atoms/Tab';
import Typo from '../../atoms/Typo';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import SwiperCalendar from '../../molecules/SwiperCalendar';
import ViewPointButton from '../../molecules/ViewPointButton';
import NaverMap from '../../organisms/NaverMap';
import { ToastContext } from '../../../contexts/Toast';
import useInterval from '../../../hooks/useInterval';
import {
	SubscriptionItemDto,
	SubscriptionItemStatusType,
} from '../../../interface/subscriptionItem';
import { RootState } from '../../../store';
import {
	getSubscriptionItemAll,
	GetSubscriptionParam,
	useGetSubscriptionItemAllQuery,
} from '../../../store/apis/subscriptionItem';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { createRadioCellRender } from '../../../utils/row-data-util';
import InspectionTabPane from './InspectionTabPane';
import circuitInspectionGridData from './gridData';
import { ProductType } from '../../../interface/subscription';

export enum TabState {
	ALL = '',
	REQUEST = 'REQUESTED',
	PENDING = 'PENDING',
	ASSIGNED = 'ASSIGNED',
	COMPLETED = 'COMPLETED',
}

interface InspectionProps {
	productType: ProductType | undefined;
	// eslint-disable-next-line react/require-default-props
	departmentId: boolean;
}

function InspectionManagerAssignment({
	productType,
	departmentId = false,
}: InspectionProps) {
	const isAutoFetching = useRef(false);
	const [mapKey, setMapKey] = useState(0);
	const [setCallback] = useInterval();

	const [searchParams, setSearchParams] = useSearchParams();
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();
	const { popup } = useSelector((state: RootState) => state.popup);

	const user = useSelector((state: RootState) => state.auth.user);

	const radioBoxRef = useRef<HTMLDivElement>(null);
	const [tab, setTab] = useState<TabState>(TabState.ALL);
	const [isMapRender, setIsMapRender] = useState(true);
	const [selectedWork, setSelectedWork] = useState<SubscriptionItemDto | null>(
		null,
	);
	const [selectedDate, setSelectedDate] = useState<string>(
		searchParams.get('selectedDate') || dayjs().format('YYYY-MM-DD'),
	);
	const [tableAllData, setTableAllData] = useState<SubscriptionItemDto[]>([]);
	const [tableData, setTableData] = useState<SubscriptionItemDto[]>([]);

	const [monthCounts, setMonthCounts] = useState<Record<number, number> | null>(
		null,
	);

	const isRefetch = useRef(false);

	const isInitRef = useRef(true);
	const defaultSelectedWorkRef = useRef<string | null>(
		searchParams.get('subscriptionItemNo') || null,
	);

	const allItemsParams: GetSubscriptionParam = {
		productType,
		customerRequestVisitedAtFrom: dayjs(selectedDate)
			.startOf('month')
			.format('YYYY-MM-DD'),
		customerRequestVisitedAtTo: dayjs(selectedDate)
			.endOf('month')
			.format('YYYY-MM-DD'),
	};
	const {
		isLoading: isSubscriptionAllItemLoading,
		currentData: subscriptionAllItemRows,
	} = useGetSubscriptionItemAllQuery(allItemsParams, {
		pollingInterval: 30000,
	});
	const subscriptionItems = subscriptionAllItemRows
		? subscriptionAllItemRows.rows
		: [];

	const monthExtraInfo: Record<number, string> = {};
	if (subscriptionAllItemRows && !isSubscriptionAllItemLoading) {
		const monthExtraInfoArrary: number[] = new Array(32).fill(0);

		subscriptionItems.forEach((item) => {
			const targetDate = Number(dayjs(item.firstVisitedAt).format('DD'));
			monthExtraInfoArrary[targetDate] += 1;
		});

		monthExtraInfoArrary.map(
			// eslint-disable-next-line no-return-assign
			(item, index) => item && (monthExtraInfo[index] = `${item}건`),
		);
	}

	const handleTabClick = (value: TabState) => {
		setTab(value);
	};

	const handleRadioButtonClick = useCallback((data: any) => {
		setSelectedWork(data);
	}, []);

	const getData = useCallback(async () => {
		const params: GetSubscriptionParam = !departmentId
			? {
					productType,
					customerRequestVisitedAt: dayjs(selectedDate).format('YYYY-MM-DD'),
			  }
			: {
					productType,
					customerRequestVisitedAt: dayjs(selectedDate).format('YYYY-MM-DD'),
					departmentId: user?.department?.id,
			  };
		try {
			const res = await getSubscriptionItemAll(params);

			const taskList = res.map((item) => ({
				...item,
				id: item.subscriptionItemNo,
			}));

			setTableAllData(taskList);

			if (defaultSelectedWorkRef.current) {
				setSelectedWork(
					res.find(
						(item) =>
							item.subscriptionItemNo === defaultSelectedWorkRef.current,
					) || null,
				);
			} else if (!isRefetch.current) {
				setSelectedWork(null);
			} else {
				setSelectedWork(
					(prev) =>
						res.find(
							(item) => item.subscriptionItemNo === prev?.subscriptionItemNo,
						) || null,
				);
			}
		} catch (e) {
			const err = e as AxiosError<{
				error: string;
				message: string;
				statusCode: number;
				translate: string;
			}>;

			if (err.response?.data) {
				setToast(
					'error',
					err.response?.data.translate || err.response?.data.message,
				);
			}

			setTableAllData([]);
			setSelectedWork(null);
		} finally {
			isRefetch.current = false;
		}
	}, [departmentId, productType, selectedDate, setToast, user?.department?.id]);

	const getMonthCount = useCallback(async () => {
		const params: GetSubscriptionParam = {
			productType,
			subscriptionItemStatus: 'REQUESTED',
			customerRequestVisitedAtFrom: dayjs(selectedDate)
				.startOf('month')
				.format('YYYY-MM-DD'),
			customerRequestVisitedAtTo: dayjs(selectedDate)
				.endOf('month')
				.format('YYYY-MM-DD'),
		};

		setMonthCounts({});

		try {
			const res = await getSubscriptionItemAll(params);

			let monthCountsTemp: Record<number, number> = {};

			res.forEach((item) => {
				const targetDate = Number(dayjs(item.firstVisitedAt).format('DD'));

				if (monthCountsTemp[targetDate]) {
					monthCountsTemp = {
						...monthCountsTemp,
						[targetDate]: monthCountsTemp[targetDate] + 1,
					};
				} else {
					monthCountsTemp = { ...monthCountsTemp, [targetDate]: 1 };
				}

				setMonthCounts(monthCountsTemp);
			});
		} catch (e) {
			setMonthCounts({});
			const err = e as AxiosError<{
				error: string;
				message: string;
				statusCode: number;
				translate: string;
			}>;

			if (err.response?.data) {
				setToast(
					'error',
					err.response?.data.translate || err.response?.data.message,
				);
			}
		}
	}, [productType, setToast, selectedDate]);

	const handleMarkerClick = useCallback((item: any) => {
		setSelectedWork(item);
	}, []);

	const handleDateChange = useCallback(
		(mObj: Dayjs) => {
			setSelectedDate(mObj.format('YYYY-MM-DD'));
			setSearchParams({ selectedDate: mObj.format('YYYY-MM-DD') });
		},
		[setSearchParams],
	);

	const handleRefetch = useCallback(() => {
		isRefetch.current = true;
		getData();
	}, [getData]);

	const getSubscriptionListCountViaStatus = (
		status: SubscriptionItemStatusType,
	) => {
		return tableAllData.filter((item) => item.subscriptionItemStatus === status)
			.length;
	};

	useEffect(() => {
		(async () => {
			dispatch(fullLoadingOn());
			await getData();
			dispatch(fullLoadingOff());
		})();
	}, [getData, dispatch]);

	useEffect(() => {
		getMonthCount();
	}, [getMonthCount, popup, selectedDate]);

	useEffect(() => {
		if (tab) {
			setTableData(
				tableAllData.filter((item) => item.subscriptionItemStatus === tab),
			);
		} else {
			setTableData(tableAllData);
		}
	}, [tab, tableAllData]);

	useEffect(() => {
		setSelectedWork(null);
	}, [tab]);

	useEffect(() => {
		if (!isInitRef.current) {
			defaultSelectedWorkRef.current = null;
		} else {
			isInitRef.current = false;
		}
	}, [selectedDate]);

	const callbackFunction = useCallback(() => {
		isRefetch.current = true;
		isAutoFetching.current = true;
		getData();
		getMonthCount();
	}, [getData, getMonthCount]);

	useEffect(() => {
		setCallback(() => callbackFunction);
	}, [setCallback, callbackFunction]);

	useEffect(() => {
		if (isAutoFetching.current) {
			isAutoFetching.current = false;
		} else {
			setMapKey((prev) => prev + 1);
		}
	}, [tableData]);

	// 스트릭 모드 렌더링 이슈 방지 클린업 함수
	useEffect(() => {
		return () => {
			isInitRef.current = true;
		};
	}, []);

	return (
		<Article>
			<MapContainer>
				<ControlSection>
					<CalendarWrapper>
						{monthCounts && (
							<SwiperCalendar
								defaultSelected={selectedDate}
								onClick={handleDateChange}
								monthCounts={monthCounts}
								monthExtraInfo={monthExtraInfo}
							/>
						)}
					</CalendarWrapper>
					<TabWrppaer>
						<TabList>
							<Tab
								isActive={tab === TabState.ALL}
								value={TabState.ALL}
								label={`전체 ${tableAllData.length}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.REQUEST}
								value={TabState.REQUEST}
								label={`작업요청 ${getSubscriptionListCountViaStatus(
									'REQUESTED',
								)}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.ASSIGNED}
								value={TabState.ASSIGNED}
								label={`배정완료 ${getSubscriptionListCountViaStatus(
									'ASSIGNED',
								)}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.COMPLETED}
								value={TabState.COMPLETED}
								label={`작업완료 ${getSubscriptionListCountViaStatus(
									'COMPLETED',
								)}`}
								onClick={handleTabClick}
							/>
						</TabList>
						<DotList>
							<Dot $dotColor="danger_4">
								<Typo $typoType="hm9" className="dot-text">
									작업요청
								</Typo>
							</Dot>
							<Dot>
								<Typo $typoType="hm9" className="dot-text">
									배정완료
								</Typo>
							</Dot>
							<Dot $dotColor="gray_6">
								<Typo $typoType="hm9" className="dot-text">
									작업완료
								</Typo>
							</Dot>
						</DotList>
					</TabWrppaer>
				</ControlSection>

				<MapWrapper>
					{isMapRender ? (
						<NaverMap
							key={mapKey}
							markerList={tableData}
							onMarkerClick={handleMarkerClick}
							selectedItem={selectedWork || undefined}
						/>
					) : (
						<Box key={dayjs(selectedDate).format('YYYY-MM-DD')}>
							<ContentBoxWithHeader
								title="작업 목록"
								borderRadius="10px"
								className="inner-content-grid"
								innerClassName="grid-content-wrapper"
							>
								<div className="grid-content" ref={radioBoxRef}>
									<SelectionGrid
										type="radio"
										rowData={tableData}
										column={circuitInspectionGridData.colsList}
										onRowSelected={() => {}}
										frameworkComponents={{
											...createRadioCellRender(
												(value) => {
													handleRadioButtonClick(value);
												},
												selectedWork ? [selectedWork.subscriptionItemNo] : [],
											),
										}}
									/>
								</div>
							</ContentBoxWithHeader>
						</Box>
					)}

					<ViewPointButton
						type={isMapRender ? 'list' : 'map'}
						className="view-point-button"
						onClick={() => setIsMapRender((prev) => !prev)}
					/>
				</MapWrapper>
			</MapContainer>

			{selectedWork && (
				<TabPaneWrapper>
					<InspectionTabPane data={selectedWork} refetch={handleRefetch} />
				</TabPaneWrapper>
			)}
		</Article>
	);
}

export default InspectionManagerAssignment;

const Article = styled.article`
	display: flex;
	height: 100%;
`;

const MapContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`;

const CalendarWrapper = styled.div`
	padding-top: 16px;
`;

const ControlSection = styled.section`
	padding: 0 32px;
	box-shadow: 0px 16px 16px rgba(94, 101, 110, 0.1);
	z-index: 1;
`;

const MapWrapper = styled.div`
	flex: 1;
	position: relative;

	.view-point-button {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translate(-50%);
	}

	.content-wrapper {
		height: 100%;
	}
`;

const TabWrppaer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TabList = styled.ul`
	display: flex;
	gap: 24px;
`;

const DotList = styled.ul`
	display: flex;
	gap: 20px;

	span.dot-text {
		padding-left: 6px;
	}
`;

const TabPaneWrapper = styled.div`
	overflow: auto;
`;

const Box = styled.div`
	padding: 20px;
	border: 1px solid
		${({ theme: globalTheme }) => globalTheme.common.colors.gray_3};
	background: ${({ theme: globalTheme }) => globalTheme.common.colors.gray_1};
	display: flex;
	height: 100%;

	.inner-content-grid {
		display: flex;
		flex: 1;
		height: 100%;

		.grid-content-wrapper {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;

			div.grid-content {
				display: flex;
				flex: 1;
			}
		}
	}
`;
