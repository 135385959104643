import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/atoms/Button';
import userData from './gridData';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { useGetVehiclesStatusQuery } from '../../../../store/apis/vehicleStatus';
import {
	VEHICLES_STATUS_CREATE_PATH,
	VEHICLES_STATUS_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import useCheckRole from '../../../../hooks/useCheckRole';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const VehiclesStatus = () => {
	const navigate = useNavigate();

	const isCreateVehicleStatus = useCheckRole({
		roleType: 'isCreate',
		roleName: '차량상태관리',
	});

	const { formInitialValues, colsList, forms } = userData;
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetVehiclesStatusQuery,
		detailPath: VEHICLES_STATUS_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="차량상태 관리"
			gridTitle="차량상태 관리"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			isFetching={isFetching}
			rightAccessory={
				isCreateVehicleStatus && (
					<ButtonContainer>
						<Button
							onClick={() => navigate(VEHICLES_STATUS_CREATE_PATH)}
							size="small"
						>
							차량상태 추가
						</Button>
					</ButtonContainer>
				)
			}
		/>
	);
};

export default VehiclesStatus;
