/* eslint-disable no-use-before-define */
import InspectionTemplate from '../../../components/templates/InspectionTemplate';

function InspectionAgencyManagerAssignmentPage() {
	return InspectionTemplate({
		productType: 'INSPECTION_AGENCY',
		departmentId: false,
	});
}

export default InspectionAgencyManagerAssignmentPage;
