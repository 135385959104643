import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataColsListModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_OPTIONS_BULLET_TEXT,
	CELL_TYPE_OPTIONS_TEXT,
} from '../../../../utils/row-data-util';

const userData: GridDataColsListModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'service',
				headerName: '적용할 서비스',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_OPTIONS_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'category',
				headerName: '분류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'question',
				headerName: '제목',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'viewable',
				headerName: '사용유무',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_OPTIONS_BULLET_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
};

export default userData;
