import { AnyMobilityDTGInfo } from '../interface/vehicleConditions';

const calculateOverallValue = (
	paths: (AnyMobilityDTGInfo & { velocityRange: string })[],
) => {
	const startParts = paths[0].t;
	const endParts = paths[paths.length - 1].t;

	const timeDifference = Number(endParts) - Number(startParts);

	const minutes = Math.floor((timeDifference % 3600) / 60);
	const seconds = timeDifference % 60;

	return `${minutes}분 ${seconds}초`;
};

export default calculateOverallValue;
