/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { useGetTimeIndexQuery } from '../../../store/apis/vehicleConditions';
import SwiperCalendar from '../../../components/molecules/SwiperCalendar';
import Select from '../../../components/atoms/Select';
import Typo from '../../../components/atoms/Typo';
import VehiclePathByDateAndTime from './VehicleDrivingRecordByTime';
import { RootState } from '../../../store';
import VehiclesSelection from './VehiclesSelection';

const Article = styled.article`
	display: flex;
	height: 100%;
`;

const MapContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`;

const ControlSection = styled.section`
	padding: 0 32px;
	box-shadow: 0 16px 16px rgba(94, 101, 110, 0.1);
	z-index: 1;
`;

const CalendarWrapper = styled.div`
	padding-top: 16px;
`;

const TabWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TabList = styled.ul`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	align-items: center;
	margin-top: 12px;
`;

const SelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	//width: 160px;
`;

interface TimeIndexProps {
	vehicleNumber: string;
	date: string;
	setTime: React.Dispatch<React.SetStateAction<string>>;
	time: string;
}

const TimeIndexOfVehicleNumber = ({
	vehicleNumber,
	date,
	setTime,
	time,
}: TimeIndexProps) => {
	const { isLoading, isError, isFetching, data } = useGetTimeIndexQuery(
		{ vehicleNumber, date: dayjs(date).format('YYMMDD') },
		{ skip: !vehicleNumber || !date },
	);

	const timeIndex = data ? data.rows : [];

	return (
		<>
			<Typo>운행시간</Typo>
			<SelectWrapper>
				{isLoading || isFetching ? (
					<Skeleton.Input active style={{ borderRadius: '10px' }} />
				) : (
					<Select
						onChange={(value) => setTime(value)}
						placeholder={
							timeIndex.length && !isError ? '운행시간' : '운행기록 없음'
						}
						value={time}
						disabled={!timeIndex.length || isError}
						allowClear
					>
						<Select.Option value="전일">모든 시간</Select.Option>
						{timeIndex.map((t) => (
							<Select.Option key={t} value={t}>
								{t}시
							</Select.Option>
						))}
					</Select>
				)}
			</SelectWrapper>
		</>
	);
};

const VehicleDTGInfo = () => {
	const { user } = useSelector((state: RootState) => state.auth);

	const [searchParams, setSearchParams] = useSearchParams();
	const [departmentId, setDepartmentId] = useState(user?.department.id);
	const [vehicleNumber, setVehicleNumber] = useState(
		searchParams.get('vehicleNumber') || '',
	);
	const [date, setDate] = useState<string>(
		searchParams.get('date') || dayjs().format('YYYY-MM-DD'),
	);
	const [timeIndex, setTimeIndex] = useState<string[]>([]);
	const [time, setTime] = useState('');

	const handleCarNumberChange = useCallback(
		(vehicleNum: string) => {
			setTimeIndex([]);
			setTime('');

			setVehicleNumber(vehicleNum ?? '');
			setSearchParams({
				vehicleNumber: vehicleNum,
				date: dayjs(date).format('YYYY-MM-DD'),
			});
		},
		[date, setSearchParams],
	);

	const handleDateChange = useCallback(
		(mObj: Dayjs) => {
			setTimeIndex([]);
			setTime('');

			setDate(mObj.format('YYYY-MM-DD'));
			setSearchParams({ vehicleNumber, date: mObj.format('YYYY-MM-DD') });
		},
		[vehicleNumber, setSearchParams],
	);

	useEffect(() => {
		if (!timeIndex.length) {
			setTimeIndex([]);
		}
	}, [timeIndex.length]);

	return (
		<Article>
			<MapContainer>
				<ControlSection>
					<CalendarWrapper>
						<SwiperCalendar
							defaultSelected={date}
							onClick={handleDateChange}
							monthCounts={{ 0: 0 }}
							monthExtraInfo={{ 0: '0' }}
						/>
					</CalendarWrapper>
					<TabWrapper>
						<TabList>
							<Typo>차량번호</Typo>
							<SelectWrapper>
								<VehiclesSelection
									vehicleNumber={vehicleNumber ?? ''}
									departmentId={departmentId}
									page={1}
									pageSize={500}
									setDepartmentId={setDepartmentId}
									setVehicleNumber={handleCarNumberChange}
								/>
							</SelectWrapper>
							<TimeIndexOfVehicleNumber
								vehicleNumber={vehicleNumber}
								date={date}
								setTime={setTime}
								time={time}
							/>
						</TabList>
					</TabWrapper>
				</ControlSection>
				<VehiclePathByDateAndTime
					mapId="mapDTG"
					vehicleNumber={vehicleNumber}
					date={dayjs(date).format('YYMMDD')}
					time={time}
				/>
			</MapContainer>
		</Article>
	);
};

export default VehicleDTGInfo;
