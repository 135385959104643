import { ColSize, SelectionGridColumn } from '../../../utils/grid-util';

// eslint-disable-next-line import/prefer-default-export
export const carServiceDashboardGroupColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '대상 그룹 아이디',
			field: 'dashboardGroupId',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '그룹명',
			field: 'dashboardGroupName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '메모',
			field: 'dashboardGroupMemo',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
	],
	forms: [],
};
