import { Radio, RadioChangeEvent } from 'antd';
import { useFormik } from 'formik';
import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../components/atoms/Alert';
import Button from '../../../components/atoms/Button';
import ContentBoxItem from '../../../components/atoms/ContentBoxItem';
import Input from '../../../components/atoms/Input';
import InputAlert from '../../../components/atoms/InputAlert';
import Label from '../../../components/atoms/Label';
import Typo from '../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../components/molecules/GridLayout';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../contexts/Toast';
import { GenerateStockDto } from '../../../interface/Inventory';
import { INVENTORY_STATE_STANDARD_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import { CustomErrorType } from '../../../store/apis/@types';
import { useInventoryCreateMutation } from '../../../store/apis/inventory';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';

import { ButtonTypeType } from '../../../styles/theme';
import {
	INANDOUT_RELEASED_VALUE,
	INANDOUT_STORED_VALUE,
} from '../../../utils/const';
import { NewTableValueType } from '../../../utils/table-util';

const initialValues: GenerateStockDto = {
	inAndOut: INANDOUT_STORED_VALUE,
	stockPlace: '',
	stockName: '',
	beforeQuantity: 0,
	beforePrice: 0,
	inAndOutPlace: '',
	changedQuantity: 0,
	changedPrice: 0,
	afterQuantity: 0,
	afterPrice: 0,
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.inventory-input-container {
		display: flex;
		gap: 32px;
	}
`;

const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	tableData,
}: {
	tableKeys: Record<string, NewTableValueType>;
	tableData: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = tableData[key as keyof T];
		const { label, span } = value;

		switch (key) {
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};

const inventoryTableKey: Record<string, NewTableValueType> = {
	afterQuantity: {
		key: 'afterQuantity',
		label: '잔량',
		span: 3,
	},
	afterPrice: {
		key: 'afterPrice',
		label: '잔량금액',
		span: 3,
	},
};

function InventoryCreatePage() {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);

	const [inventoryCreate] = useInventoryCreateMutation();

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};
	const goToBackPage = () => {
		navigate(-1);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.stockPlace === '') {
			errors.stockPlace = '지점명을 입력해주세요.';
		}
		if (values.stockName === '') {
			errors.stockName = '제품명을 입력해주세요.';
		}
		if (values.inAndOutPlace === '') {
			errors.inAndOutPlace = '입고/출고처를 입력해 주세요.';
		}

		handleAlertCloseClick();
		return errors;
	};

	const {
		handleSubmit,
		validateForm,
		setFieldValue,
		values,
		errors,
		handleChange,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (value) => {
			dispatch(fullLoadingOn());

			const res = await inventoryCreate(value);

			if ('error' in res) {
				toast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				handleAlertCloseClick();
				toast('info', '재고 등록이 완료되었습니다.');
				navigate(INVENTORY_STATE_STANDARD_MANAGEMENT_PATH);
			}

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const getAfterGridData = useCallback(
		(before: number, change: number) => {
			if (values.inAndOut === INANDOUT_STORED_VALUE) {
				return before + change;
			}

			if (values.inAndOut === INANDOUT_RELEASED_VALUE) {
				return Math.abs(before - change);
			}
			return 0;
		},
		[values.inAndOut],
	);

	const handleAfterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberRegex = /[^0-9]/;
		const value = e.target.value.replaceAll(',', '');
		const targetName = e.target.name;

		if (!numberRegex.test(value)) {
			setFieldValue(targetName, Number(value));
		}
	};

	const handleSubmitAlertClick = async () => {
		const validateValue = await validateForm();

		if (Object.keys(validateValue).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleSubmitClick = () => {
		handleSubmit();
		handleAlertCloseClick();
	};

	useEffect(() => {
		setFieldValue(
			'afterPrice',
			getAfterGridData(values.beforePrice, values.changedPrice),
		);
		setFieldValue(
			'afterQuantity',
			getAfterGridData(values.beforeQuantity, values.changedQuantity),
		);
	}, [
		getAfterGridData,
		setFieldValue,
		values.beforePrice,
		values.beforeQuantity,
		values.changedPrice,
		values.changedQuantity,
	]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="재고 등록"
				onBack={goToBackPage}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={goToBackPage}
							size="small"
							disabled
						>
							재고삭제
						</Button>
						<Button onClick={handleSubmitAlertClick} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="재고 상세"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="inAndOut" essential>
								<span>
									<Typo $typoType="label2">구분</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setFieldValue('inAndOut', e.target.value);
								}}
								value={values.inAndOut}
							>
								<Radio value={INANDOUT_STORED_VALUE}>
									<Typo $typoType="label1">입고</Typo>
								</Radio>
								<Radio value={INANDOUT_RELEASED_VALUE}>
									<Typo $typoType="label1">출고</Typo>
								</Radio>
							</Radio.Group>
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="stockPlace" essential>
								<span>
									<Typo $typoType="label2">지점명</Typo>
								</span>
							</Label>
							<Input
								name="stockPlace"
								placeholder="지점명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.stockPlace}
								onChange={handleChange}
								onReset={() => setFieldValue('stockPlace', '')}
							/>
							{errors.stockPlace && (
								<InputAlert $inputStatus="error">
									{errors.stockPlace}
								</InputAlert>
							)}
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="stockName" essential>
								<span>
									<Typo $typoType="label2">제품명</Typo>
								</span>
							</Label>
							<Input
								name="stockName"
								placeholder="제품명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.stockName}
								onChange={handleChange}
								onReset={() => setFieldValue('stockName', '')}
							/>
							{errors.stockName && (
								<InputAlert $inputStatus="error">{errors.stockName}</InputAlert>
							)}
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="inAndOutPlace" essential>
								<span>
									<Typo $typoType="label2">입고/출고처</Typo>
								</span>
							</Label>
							<Input
								name="inAndOutPlace"
								placeholder="입고/출고처를 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.inAndOutPlace}
								onChange={handleChange}
								onReset={() => setFieldValue('inAndOutPlace', '')}
							/>
							{errors.inAndOutPlace && (
								<InputAlert $inputStatus="error">
									{errors.inAndOutPlace}
								</InputAlert>
							)}
						</ContentBoxItem>

						<div className="inventory-input-container">
							<ContentBoxItem>
								<Label name="beforeQuantity" essential>
									<span>
										<Typo $typoType="label2">기존 수량</Typo>
									</span>
								</Label>
								<Input
									name="beforeQuantity"
									placeholder="숫자 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={values.beforeQuantity.toLocaleString()}
									onChange={handleAfterValueChange}
									onReset={() => setFieldValue('beforeQuantity', '')}
									unitText="개"
								/>
								{errors.beforeQuantity && (
									<InputAlert $inputStatus="error">
										{errors.beforeQuantity}
									</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="changedQuantity" essential>
									<span>
										<Typo $typoType="label2">
											{values.inAndOut === INANDOUT_STORED_VALUE
												? '추가 수량'
												: '소진 수량'}
										</Typo>
									</span>
								</Label>
								<Input
									name="changedQuantity"
									placeholder="숫자 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={values.changedQuantity.toLocaleString()}
									onChange={handleAfterValueChange}
									onReset={() => setFieldValue('changedQuantity', '')}
									unitText="개"
								/>
								{errors.changedQuantity && (
									<InputAlert $inputStatus="error">
										{errors.changedQuantity}
									</InputAlert>
								)}
							</ContentBoxItem>
						</div>

						<div className="inventory-input-container">
							<ContentBoxItem>
								<Label name="beforePrice" essential>
									<span>
										<Typo $typoType="label2">기존 금액</Typo>
									</span>
								</Label>
								<Input
									name="beforePrice"
									placeholder="숫자 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={values.beforePrice.toLocaleString()}
									onChange={handleAfterValueChange}
									onReset={() => setFieldValue('beforePrice', '')}
									unitText="원"
								/>
								{errors.beforePrice && (
									<InputAlert $inputStatus="error">
										{errors.beforePrice}
									</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="changedPrice" essential>
									<span>
										<Typo $typoType="label2">
											{values.inAndOut === INANDOUT_STORED_VALUE
												? '추가 금액'
												: '소진 금액'}
										</Typo>
									</span>
								</Label>
								<Input
									name="changedPrice"
									placeholder="숫자 입력해 주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={values.changedPrice.toLocaleString()}
									onChange={handleAfterValueChange}
									onReset={() => setFieldValue('changedPrice', '')}
									unitText="원"
								/>
								{errors.changedPrice && (
									<InputAlert $inputStatus="error">
										{errors.changedPrice}
									</InputAlert>
								)}
							</ContentBoxItem>
						</div>
					</ContentBox>

					<ContentBoxItem>
						<GridLayout>
							{renderTable({
								tableKeys: inventoryTableKey,
								tableData: {
									afterQuantity: `${values.afterQuantity.toLocaleString()}개`,
									afterPrice: `${values.afterPrice.toLocaleString()}원`,
								} as any,
							})}
						</GridLayout>
					</ContentBoxItem>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible ? (
				<Alert
					title="재고 등록"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleSubmitClick}
					isVisible={isAlertVisible}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							재고를 등록하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default InventoryCreatePage;
