import React from 'react';
import useCheckRole from '../../../../hooks/useCheckRole';
import CompanyInfoTemplate from '../../../../components/templates/CompanyTemplate/CompanyInfo';
import CompaniesListTemplate from '../../../../components/templates/CompanyTemplate/CompanyList';

function CompanyPage() {
	const isSuper = useCheckRole({
		roleType: 'isSuper',
		roleName: '법인관리',
		includeSuper: true,
	});

	return isSuper ? <CompaniesListTemplate /> : <CompanyInfoTemplate />;
}

export default CompanyPage;
