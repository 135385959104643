import { DepartmentService, UserType } from '../interface/department';

export const getProviderName = (value: string | undefined) => {
	switch (value) {
		case 'kakao':
			return '카카오';
		case 'facebook':
			return '페이스북';
		case 'google':
			return '구글';
		case 'naver':
			return '네이버';
		case 'apple':
			return '애플';
		case undefined:
			return '정보없음';
		default:
			return '일반회원';
	}
};

export const getDepartmentQuery = (userType: UserType): DepartmentService => {
	switch (userType) {
		case 'MANAGER':
			return 'MANAGER_APP';
		case 'CUSTOMER':
			return 'CUSTOMER_APP';
		case 'ADMIN':
			return 'BOS_WEB';
		default:
			return 'BOS_WEB';
	}
};

export const getDepartmentServiceName = (
	departmentService: DepartmentService,
): string => {
	switch (departmentService) {
		case 'MANAGER_APP':
			return '매니저 관리자';
		case 'CUSTOMER_APP':
			return '법인 관리자';
		case 'BOS_WEB':
			return '오픈마일 관리자';
		default:
			return '오픈마일 관리자';
	}
};

export const getAccountStateName = (value: string) => {
	switch (value) {
		case 'suspended':
			return '제한됨';
		default:
			return '활동중';
	}
};
