import {
	CreateRoleDto,
	RoleDto,
	RoleTemplateDto,
	UpdateRoleDto,
} from '../../../interface/role';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const RoleApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getRoles: builder.query<ListResponse<RoleDto>, {}>({
			query: ({ page, pageSize }: { page: number; pageSize: number }) => ({
				url: `/members/roles`,
				params: { page, pageSize },
			}),
		}),
		getRoleDetail: builder.query<
			ObjectResponse<RoleDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => ({
				url: `/members/roles/${id}`,
			}),
		}),
		createRole: builder.mutation<ObjectResponse<RoleDto>, CreateRoleDto>({
			query: (body) => ({
				method: 'POST',
				url: '/members/roles',
				body,
			}),
		}),
		updateRole: builder.mutation<
			ObjectResponse<RoleDto>,
			{ id: string | undefined; body: UpdateRoleDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/roles/${id}`,
				body,
			}),
		}),
	}),
});

export const getRoleAll = async () => {
	const url = `/members/roles`;

	const { data } = await axiosInstance.get<ListResponse<RoleTemplateDto>>(url);

	return data.rows;
};

export const getRoleTemplateAll = async () => {
	const url = `/members/role-templates`;

	const { data } = await axiosInstance.get<ListResponse<RoleTemplateDto>>(url);

	return data.rows;
};

export const getRoleDetailViaId = async (id: string) => {
	const url = `/members/roles/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<RoleDto>>(url);

	return data.row;
};

export const {
	useGetRolesQuery,
	useCreateRoleMutation,
	useUpdateRoleMutation,
	useGetRoleDetailQuery,
} = RoleApiSlice;
