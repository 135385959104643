import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import {
	AddibleTask,
	ProductType,
	RequestCarCareService,
	SubscriptionDto,
} from '../../../interface/subscription';
import { CarCareSubscription } from '../../../interface/carCareSubscription';

const myCarCareSubscriptionApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMyCarCareSubscriptionList: builder.query<
			ListResponse<CarCareSubscription>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: '/members/companies/me/subscriptions',
				params: args,
			}),
			providesTags: ['CarCareSubscription'],
		}),
		getMyCarCareSubscriptionDetail: builder.query<
			ObjectResponse<SubscriptionDto>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `/members/companies/me/subscriptions/${subscriptionNo}`,
			}),
			providesTags: ['CarCareSubscription'],
		}),
		getAddibleCarCareServices: builder.query<
			ListResponse<AddibleTask>,
			{ subscriptionNo: string | undefined; productType: ProductType }
		>({
			query: ({ subscriptionNo, productType }) => ({
				method: 'GET',
				url: `/members/companies/me/subscriptions/${subscriptionNo}/addible`,
				params: { productType },
			}),
			providesTags: ['CarCareSubscription'],
		}),
		getMyCarCareSubscriptionUsageHistory: builder.query<
			ListResponse<SubscriptionDto>,
			{ subscriptionNo: string | undefined }
		>({
			query: ({ subscriptionNo }) => ({
				method: 'GET',
				url: `members/companies/me/subscriptions/${subscriptionNo}/usage-history`,
			}),
			providesTags: ['CarCareSubscription'],
		}),
		requestCarCareService: builder.mutation<
			unknown,
			{ body: RequestCarCareService }
		>({
			query: ({ body }) => ({
				method: 'POST',
				url: `/members/companies/me/subscription-items`,
				body,
			}),
			invalidatesTags: ['CarCareSubscription'],
		}),
		cancelCarCarService: builder.mutation<
			unknown,
			{ subscriptionItemNo: string }
		>({
			query: ({ subscriptionItemNo }) => ({
				method: 'PUT',
				url: `/members/companies/me/subscription-items/${subscriptionItemNo}/task-start-cancelation`,
			}),
		}),
	}),
});

export const {
	useGetMyCarCareSubscriptionListQuery,
	useGetMyCarCareSubscriptionDetailQuery,
	useGetAddibleCarCareServicesQuery,
	useGetMyCarCareSubscriptionUsageHistoryQuery,
	useRequestCarCareServiceMutation,
	useCancelCarCarServiceMutation,
} = myCarCareSubscriptionApiSlice;
