import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccordionItem from '../../molecules/AccordionItem';
import Accordion from '../../molecules/Accordion';
import { RootState } from '../../../store';
import { AsideMenuItem, asideMenuList } from './data';
import { useGetAsidePopupQuery } from '../../../store/apis/aside';

const REFETCH_TIMEOUT = 60000;

interface AsideProps {
	className?: string;
}

const AsideContainer = styled.nav<{ $autoHeight: boolean }>`
	display: flex;
	flex-direction: column;
	width: 180px;
	height: ${({ $autoHeight }) => ($autoHeight ? 'auto' : 'calc(100vh - 52px)')};
	padding: 8px 0;
	overflow-y: auto;
	border-right: 1px solid ${({ theme }) => theme.common.colors.gray_3};
	cursor: pointer;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none; /* Chrome, Safari, Opera*/
	}

	a {
		color: inherit;
	}
`;

// 동적 라우팅 동적으로 받아오는 패스 전까지만 비교
const comparePathname = (path1: string, path2: string) => {
	if (path1 === path2) return true;

	const path1Segments = path1.split('/');
	const path2Segments = path2.split('/');

	return path1Segments.length <= path2Segments.length
		? path1Segments.every((segment, index) => segment === path2Segments[index])
		: path2Segments.every((segment, index) => segment === path1Segments[index]);
};

function Aside({ className }: AsideProps) {
	const [asideList, setAsideList] = useState<AsideMenuItem[]>([]);
	const { pathname } = useLocation();
	const { user } = useSelector((state: RootState) => state.auth);
	const { asideOpen: open } = useSelector(
		(state: RootState) => state.asideMenu,
	);

	const { data } = useGetAsidePopupQuery(
		{},
		{
			skip: user?.department.service !== 'BOS_WEB',
			pollingInterval: REFETCH_TIMEOUT,
		},
	);

	const asideCountList = data ?? {};

	useEffect(() => {
		if (user) {
			// 접근 가능한 패스 목록
			const allowPathnameList = user.role.roleItems
				.filter((item) => item.isRead)
				.map((item) => {
					return item.roleTemplate.roleName.replaceAll(' ', '');
				});

			setAsideList(
				// 접근 가능한 패스들만 서브 메뉴에 할당
				[...asideMenuList]
					.map((item) => {
						return {
							...item,
							subMenu: item.subMenu.filter((subItem) => {
								return allowPathnameList.includes(
									subItem.title.replaceAll(' ', ''),
								);
							}),
						};
					})
					// 접근 가능한 서브 메뉴가 없는 상위 메뉴 제외
					.filter((item) => item.subMenu.length),
			);
		}
	}, [user]);

	const isAutoHeight =
		(pathname.startsWith('/subscribe/') && pathname !== '/subscribes') ||
		(pathname.startsWith('/admin-role/') && pathname !== '/admin-roles');

	return (
		<AsideContainer
			className={className}
			$autoHeight={isAutoHeight}
			style={open ? {} : { display: 'none' }}
		>
			{asideList.map((menuItem) => (
				<div key={menuItem.key}>
					<Accordion
						title={menuItem.displayTitle || menuItem.title}
						iconSrc={menuItem.labelIcon || ''}
						isOpenInit
					>
						{menuItem.subMenu &&
							menuItem.subMenu.map((subItem) => (
								<Link
									to={subItem.key}
									key={subItem.key}
									style={{ textDecoration: 'none' }}
								>
									<AccordionItem
										count={asideCountList[subItem.title]}
										isActive={
											subItem.key === pathname ||
											!!subItem.includePaths.find((item: string) => {
												return comparePathname(item, pathname);
											})
										}
									>
										{subItem.displayTitle || subItem.title}
									</AccordionItem>
								</Link>
							))}
					</Accordion>
				</div>
			))}
		</AsideContainer>
	);
}

export default Aside;
