import { UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { ReactNode, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ImageUpload from '../../../../components/atoms/ImageUpload';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { MANAGER_MANAGEMENT_PATH } from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	useDeleteManagerMutation,
	useGetManagerDetailQuery,
	useUpdateManagerAccountStateMutation,
	useUpdateManagerProfileMutation,
} from '../../../../store/apis/manager';
import { useBigImagesUploadMutation } from '../../../../store/apis/upload';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import theme, { ButtonTypeType } from '../../../../styles/theme';
import { phoneFormat } from '../../../../utils/data-format';
import { NewTableValueType } from '../../../../utils/table-util';
import EditModal from './EditModal';
import { managerInfoTableKey } from './gridData';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBoxWithHeaderExtra = styled(ContentBoxWithHeader)`
	.upload-form {
		max-width: 830px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;

		.upload-form-label {
			display: block;
			margin-bottom: 12px;
		}

		.upload-form-image-box {
			min-height: 211px;
		}
	}

	.ant-upload-list {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
`;

const StatusButton = styled(Button)`
	margin-left: auto;
`;

enum AlertState {
	NONE,
	ACCOUNT_STATE_CHANGE,
	UPDATE_MANAGER_CHANGE,
	DELETE,
}

function ManagerDetailPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const { id } = useParams();

	const [isEditImage, setIsEditImage] = useState(false);
	const [newProfileImageList, setNewProfileImageList] = useState<
		UploadFile<any>[]
	>([]);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [showEditModal, setShowEditModal] = useState(false);

	const [updateManager] = useUpdateManagerAccountStateMutation();
	const [updateManagerProfile] = useUpdateManagerProfileMutation();
	const [imageUpload] = useBigImagesUploadMutation();
	const [deleteManager] = useDeleteManagerMutation();

	const { data, refetch } = useGetManagerDetailQuery({ id });
	const managerData = data && data.row;

	const profileImageList: UploadFile<any>[] =
		managerData && managerData.profileMedia
			? [
					{
						uid: '-1',
						name: 'image.png',
						status: 'done',
						url: managerData.profileMedia,
					},
			  ]
			: [];

	/*
		eventFunction
	*/
	const goBack = () => {
		navigate(-1);
	};

	const handleModalOpen = () => {
		setShowEditModal(true);
	};

	const handleModalClose = () => {
		setShowEditModal(false);
	};

	const handleImageChange = async ({
		fileList,
	}: UploadChangeParam<UploadFile<any>>) => {
		setNewProfileImageList(fileList);
	};

	const handleChangeAccountStatus = async () => {
		if (!id || !data) {
			return;
		}

		const res = await updateManager({
			id,
			body: {
				isLock: !(managerData && managerData.isLock),
			},
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '매니저 계정상태 수정이 완료되었습니다.');
			setAlertState(AlertState.NONE);
			refetch();
		}
	};

	const handleChangeProfileImage = async () => {
		if (!id) {
			return;
		}

		dispatch(fullLoadingOn());

		if (!newProfileImageList.length || newProfileImageList?.[0]?.uid === '-1') {
			setToast('error', '변경된 내용이 없습니다');

			dispatch(fullLoadingOff());

			setAlertState(AlertState.NONE);
			return;
		}

		if (newProfileImageList.length) {
			const res = await imageUpload({
				file: newProfileImageList[0].originFileObj,
				folder: '/profile/manager',
			});

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				const changeRes = await updateManagerProfile({
					id,
					body: { profileMedia: res.data.rows[0] },
				});

				if ('error' in changeRes) {
					setToast(
						'error',
						(changeRes.error as CustomErrorType).data.translate ||
							(changeRes.error as CustomErrorType).data.message,
					);
				} else {
					setToast('info', '매니저 수정이 완료되었습니다.');
				}
			}
		} else {
			const res = await updateManagerProfile({
				id,
				body: { profileMedia: '' },
			});

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				setToast('info', '매니저 수정이 완료되었습니다.');
			}
		}

		dispatch(fullLoadingOff());
		setAlertState(AlertState.NONE);
	};

	const handleDeleteManager = async () => {
		if (!id) {
			return;
		}

		const res = await deleteManager(id);

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '매니저 삭제가 완료되었습니다.');
			navigate(MANAGER_MANAGEMENT_PATH);
		}

		setAlertState(AlertState.NONE);
	};

	/*
		rednerFunction
	*/
	const renderTable = <T extends Record<keyof T, ReactNode | Object>>({
		tableKeys,
		tableData,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		tableData: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value]) => {
			const tableContent = tableData[key as keyof T];

			const { label, span } = value;

			switch (key) {
				case 'createdAt':
					return (
						<GridItem
							key={key}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent
								? dayjs(tableContent as string).format('YYYY-MM-DD  HH:mm:ss')
								: '-'}
						</GridItem>
					);
				case 'managerStatus': {
					const color = tableContent !== 'activated' ? 'gray_6' : 'gray_11';
					let status;
					switch (tableContent) {
						case 'suspended':
							status = '제한됨';
							break;
						case 'pending':
							status = '대기중';
							break;
						case 'activated':
							status = '활동중';
							break;
						default:
							status = '-';
					}
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								{status}
							</span>
							<StatusButton
								type="submit"
								size="small"
								$buttonType={ButtonTypeType.GHOST}
								onClick={handleModalOpen}
							>
								상태관리
							</StatusButton>
						</GridItem>
					);
				}
				case 'isLock': {
					const color = tableContent ? 'gray_6' : 'gray_11';
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								{tableContent ? '비밀번호 잠김' : '정상'}
							</span>
							<StatusButton
								type="submit"
								size="small"
								$buttonType={
									tableContent
										? ButtonTypeType.GHOST_DANGER
										: ButtonTypeType.GHOST_BLACK
								}
								onClick={() => setAlertState(AlertState.ACCOUNT_STATE_CHANGE)}
							>
								{tableContent ? '해제' : '비밀번호 잠금'}
							</StatusButton>
						</GridItem>
					);
				}
				case 'managerPhone':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{phoneFormat(tableContent as string)}
						</GridItem>
					);
				case 'statusChangedReason':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{tableContent as string}
						</GridItem>
					);
				default:
					return (
						<GridItem
							key={key}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{typeof tableContent !== 'object' ? tableContent : '-'}
						</GridItem>
					);
			}
		});
	};

	const renderAlert = () => {
		if (!data) return null;

		switch (alertState) {
			case AlertState.ACCOUNT_STATE_CHANGE:
				return (
					<Alert
						title="매니저 계정 상태"
						closeButtonClick={() => setAlertState(AlertState.NONE)}
						onConfirmButtonClick={handleChangeAccountStatus}
						onConfirmButtonText={
							managerData && managerData.isLock ? '잠금해제' : '잠그기'
						}
						isVisible={alertState === AlertState.ACCOUNT_STATE_CHANGE}
					>
						<Typo $typoType="b5" color="gray_8">
							{managerData && managerData.isLock
								? '계정의 잠금을 해제하시겠습니까?'
								: '계정을 잠금처리하시겠습니까?'}
						</Typo>
					</Alert>
				);
			case AlertState.UPDATE_MANAGER_CHANGE:
				return (
					<Alert
						title="매니저 상세 변경"
						closeButtonClick={() => setAlertState(AlertState.NONE)}
						onConfirmButtonClick={handleChangeProfileImage}
						onConfirmButtonText="변경하기"
						isVisible={alertState === AlertState.UPDATE_MANAGER_CHANGE}
					>
						<Typo $typoType="b5" color="gray_8">
							매니저 상세를 변경하시겠습니까?
						</Typo>
					</Alert>
				);
			case AlertState.DELETE:
				return (
					<Alert
						title="매니저 탈퇴처리"
						closeButtonClick={() => setAlertState(AlertState.NONE)}
						onConfirmButtonClick={handleDeleteManager}
						onConfirmButtonText="삭제하기"
						isVisible={alertState === AlertState.DELETE}
					>
						<Typo $typoType="b5" color="gray_8">
							매니저를 탈퇴 처리하시겠습니까?
						</Typo>
					</Alert>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="매니저 상세"
				onBack={goBack}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={() => setAlertState(AlertState.DELETE)}
							size="small"
						>
							매니저 탈퇴처리
						</Button>
						<Button
							onClick={() => setAlertState(AlertState.UPDATE_MANAGER_CHANGE)}
							size="small"
						>
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="매니저 정보"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						{managerData &&
							renderTable({
								tableKeys: managerInfoTableKey,
								tableData: managerData,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
				<ContentBoxWithHeaderExtra title="매니저 상세" borderRadius="10px">
					<Label className="upload-form-label" name="image" essential>
						프로필 이미지
					</Label>
					<div className="upload-form">
						<div className="upload-form-image-box">
							<ImageUpload
								fileList={isEditImage ? newProfileImageList : profileImageList}
								onChange={handleImageChange}
								size="large"
								onRemove={() => setIsEditImage(true)}
							/>
						</div>
					</div>
				</ContentBoxWithHeaderExtra>
			</GridDetailTemplate>
			{managerData && (
				<EditModal
					isVisible={showEditModal}
					onClose={handleModalClose}
					onRefetch={refetch}
					defaultValue={{
						status: managerData.managerStatus,
						statusChangedReason: managerData.statusChangedReason,
					}}
					userId={id || ''}
				/>
			)}
			{renderAlert()}
		</>
	);
}

export default ManagerDetailPage;
