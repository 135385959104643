import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { UpdateDriverDto } from '../../../../interface/driver';
import DriverTemplate from '../../../../components/templates/DriverTemplate';
import {
	useGetDriverDetailOfCompanyQuery,
	useGetMyDriverDetailQuery,
} from '../../../../store/apis/driver';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import useCheckRole from '../../../../hooks/useCheckRole';

function DriverDetailPage() {
	const navigate = useNavigate();
	const { driverId } = useParams();

	const isSuper = useCheckRole({
		roleType: 'isRead',
		roleName: '법인관리',
		includeSuper: true,
	});

	const { isLoading, data, isError } = useGetDriverDetailOfCompanyQuery(
		{
			driverId,
		},
		{ skip: !isSuper },
	);
	const {
		isLoading: isMyLoading,
		data: myData,
		isError: isMyError,
	} = useGetMyDriverDetailQuery(
		{
			driverId,
		},
		{ skip: isSuper },
	);

	const driverDetail = !isSuper ? myData?.row : data?.row;

	const initialValues: UpdateDriverDto = {
		customerId: driverDetail?.customer?.id ?? null,
		driverName: driverDetail?.driverName,
		driverPhone: driverDetail?.driverPhone,
		driverLicenseNumber: driverDetail?.driverLicenseNumber ?? null,
		freightForwarderLicense: driverDetail?.freightForwarderLicense,
		businessLicense: driverDetail?.businessLicense,
		bankAccountScan: driverDetail?.bankAccountScan,
	};
	if (isError || isMyError) {
		return (
			<GridDetailTemplate detailTitle="기사 관리" onBack={() => navigate(-1)}>
				<ContentBoxWithHeader title="기사 수정하기" borderRadius="10px">
					기사 정보를 찾을 수 없습니다.
				</ContentBoxWithHeader>
			</GridDetailTemplate>
		);
	}

	return isLoading || isMyLoading ? (
		<Skeleton active paragraph={{ rows: 6 }} style={{ borderRadius: '10px' }} />
	) : (
		<DriverTemplate
			initialValues={initialValues}
			title="기사 수정하기"
			additionalInfo={driverDetail}
		/>
	);
}

export default DriverDetailPage;
