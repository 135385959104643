import React from 'react';
import DefaultLayout from '../../../components/organisms/DefaultLayout';
import PasswordResetTemplate from '../../../components/templates/PasswordReset';

function PasswordReset() {
	return (
		<DefaultLayout>
			<PasswordResetTemplate title="비밀번호 재설정" />
		</DefaultLayout>
	);
}

export default PasswordReset;
