import { CustomerDto, MakeCustomer } from '../../../interface/customer';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const customerApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCustomers: builder.query<ListResponse<CustomerDto>, Record<any, any>>({
			query: (args) => ({
				method: 'GET',
				url: `/members/customers`,
				params: args,
			}),
		}),
		getCustomerDetail: builder.query<
			ObjectResponse<CustomerDto>,
			{ userId: string | undefined }
		>({
			query: ({ userId }) => ({
				method: 'GET',
				url: `/members/customers/${userId}`,
			}),
		}),
		makeCustomer: builder.mutation<ObjectResponse<CustomerDto>, MakeCustomer>({
			query: (body) => ({
				method: 'POST',
				url: '/members/customers',
				body,
			}),
		}),
		updateCustomerState: builder.mutation<
			ObjectResponse<{ updateResult: boolean }>,
			{
				id: string;
				body: { customerStatus: string; statusChangedReason?: string };
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/customers/${id}/status`,
				body,
			}),
		}),
		unlockCustomer: builder.mutation<
			ObjectResponse<{ updateResult: boolean }>,
			{
				body: { accountId: number; isLock: boolean };
			}
		>({
			query: ({ body }) => ({
				method: 'PUT',
				url: `/members/customers/lock`,
				body,
			}),
		}),
	}),
});

export const {
	useGetCustomersQuery,
	useGetCustomerDetailQuery,
	useMakeCustomerMutation,
	useUpdateCustomerStateMutation,
	useUnlockCustomerMutation,
} = customerApiSlice;

export const getCustomerDetailViaId = async (id: string) => {
	const url = `/members/customers/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<CustomerDto>>(url);

	return data.row;
};
