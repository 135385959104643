import { createApi } from '@reduxjs/toolkit/query/react';
import {
	SigninAdministratorDto,
	SigninAdministratorResponse,
} from '../../../interface/auth';
import { ObjectResponse } from '../@types';
import { baseLoginQuery } from '../query';

const authSignInApi = createApi({
	reducerPath: 'authSignIn',
	baseQuery: baseLoginQuery,
	endpoints: (builder) => ({
		login: builder.mutation<
			ObjectResponse<SigninAdministratorResponse>,
			SigninAdministratorDto
		>({
			query: ({ administratorApplicationId, password, signinKeepable }) => ({
				method: 'POST',
				url: `/members/administrators/signin`,
				body: {
					administratorApplicationId,
					password,
					signinKeepable,
				},
			}),
		}),
		logout: builder.mutation<void, void>({
			query: () => ({
				method: 'DELETE',
				url: `/members/administrators/logout`,
			}),
		}),
	}),
});

export const { useLoginMutation, useLogoutMutation } = authSignInApi;
export default authSignInApi;
