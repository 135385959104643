import { GridApi, RowSelectedEvent } from 'ag-grid-community';
import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/src/query/endpointDefinitions';
import dayjs from 'dayjs';
import { createColList, TableColItem } from '../../utils/grid-util';
import { ListResponse } from '../../store/apis/@types';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface useGridDataProps {
	useQuery: UseQuery<QueryDefinition<any, any, any, ListResponse<any>, 'api'>>;
	formInitialValues: any;
	colsList: TableColItem[];
	detailPath?: string;
	version?: string;
}

function useClientGridWithRTK({
	useQuery,
	formInitialValues,
	colsList,
	detailPath,
}: useGridDataProps) {
	const [searchParams, setSearchParams] = useSearchParams(
		new URLSearchParams(window.location.search),
	);
	const [quickSearchText, setQuickSearchText] = useState('');

	const filter = JSON.parse(searchParams.get('filter') as string);
	const pageFilter = JSON.parse(searchParams.get('page') as string);
	const pageSizeFilter = JSON.parse(searchParams.get('pageSize') as string);

	const navigate = useNavigate();

	const [gridApi, setGridApi] = useState<GridApi>();
	const [filterValues, setFilterValues] = useState(filter || formInitialValues);
	const [page, setPage] = useState(pageFilter || DEFAULT_CURRENT_PAGE);
	const [pageSize, setPageSize] = useState(pageSizeFilter || DEFAULT_PAGE_SIZE);
	const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

	const isInitRef = useRef(true);

	const params = useMemo(() => {
		const values = { ...filterValues };

		Object.keys(values).forEach((key) => {
			if (!values[key]) {
				delete values[key];
			}
			// date 배열 처리
			if (
				typeof values[key] === 'object' &&
				values[key].length &&
				values[key].length > 0
			) {
				values[`${key}From`] = dayjs(values[key][0])
					.startOf('date')
					.format('YYYY-MM-DD');
				values[`${key}To`] = dayjs(values[key][1])
					.endOf('date')
					.format('YYYY-MM-DD');

				delete values[key];
			}
		});

		return values;
	}, [filterValues]);

	const cols = createColList(colsList);

	const handlePageChange = (pageValue: number) => {
		setPage(pageValue);
		gridApi?.paginationGoToPage(pageValue - 1);
	};

	const handlePageSizeChange = useCallback(
		(pageValue: number, pageSizeValue: number) => {
			setPage(pageValue);
			setPageSize(pageSizeValue);
			gridApi?.paginationGoToPage(pageValue - 1);
			gridApi?.paginationSetPageSize(pageSizeValue);
		},
		[gridApi],
	);

	const handleSearchSubmit = (values: any) => {
		setFilterValues((prev: any) => ({
			page: prev.page,
			pageSize: prev.pageSize,
			...values,
		}));

		if (isInitRef.current) {
			isInitRef.current = false;
			return;
		}

		setPage(DEFAULT_CURRENT_PAGE);
	};

	const handleQuickSearchText = useCallback(() => {
		// gridApi?.setGridOption('quickFilterText', quickSearchText);
		gridApi?.updateGridOptions({ quickFilterText: quickSearchText });
	}, [gridApi, quickSearchText]);

	const handleRowSelected = (e: RowSelectedEvent<any>) => {
		if (e.node.isSelected()) {
			setSelectedRowIds((prev) => [...prev, e.data.id]);
		} else {
			setSelectedRowIds((prev) => prev.filter((id) => id !== e.data.id));
		}
	};

	const handleDetailClick = (id: RowSelectedEvent<any>) => {
		if (detailPath) {
			navigate(`${detailPath}/${id}`);
		}
	};

	useEffect(() => {
		searchParams.set('page', String(page));
		searchParams.set('pageSize', String(pageSize));

		setSearchParams(searchParams);
		gridApi?.updateGridOptions({ paginationPageSize: pageSize });
	}, [gridApi, page, pageSize, searchParams, setSearchParams]);

	const { isError, isFetching, isLoading, refetch, data } = useQuery(params);

	return {
		rowData: data ? data.rows : [],
		totalCount: data ? data.count || 0 : 0,
		cols,
		isFetching: isFetching || isLoading,
		isError,
		page,
		pageSize,
		selectedRowIds,
		setGridApi,
		refetch,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleRowSelected,
		handleDetailClick,
		setSelectedRowIds,
		setPage,
		handleQuickSearchText,
		quickSearchText,
		setQuickSearchText,
	};
}

export default useClientGridWithRTK;
