import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Alert from '../../../components/atoms/Alert';
import Button from '../../../components/atoms/Button';
import Label from '../../../components/atoms/Label';
import Input from '../../../components/atoms/Input';
import Typo from '../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../components/molecules/GridLayout';
import InputForm from '../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	useGetDepartmentDetailQuery,
	useUpdateDepartmentMutation,
} from '../../../store/apis/department';
import useCheckRole from '../../../hooks/useCheckRole';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { NewTableValueType } from '../../../utils/table-util';
import ContentBox from '../../../components/atoms/ContentBox';
import departmentInfoTableKey from './gridData/tableData';
import { UpdateDepartmentDto } from '../../../interface/department';
import { companyRegisterNumberFormat } from '../../../utils/data-format';
import { DEPARTMENT_MANAGEMENT_PATH } from '../../../routes/constants/urls';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

enum AlertState {
	NONE,
	MODIFY,
}

function DepartmentDetailPage() {
	const params = useParams();
	const { departmentId } = params as { departmentId: string };
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const navigate = useNavigate();
	const isUpdateDepartment = useCheckRole({
		roleType: 'isUpdate',
		roleName: '소속관리',
		includeSuper: true,
	});

	const { data: departmentInfo, isLoading } = useGetDepartmentDetailQuery({
		departmentId: Number(departmentId),
	});

	const [updateDepartment] = useUpdateDepartmentMutation();

	const handleSubmit = async (values: UpdateDepartmentDto) => {
		const res = await updateDepartment({
			departmentId: Number(departmentId),
			departmentName: values.departmentName,
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '소속 정보 저장이 완료되었습니다.');
			navigate(DEPARTMENT_MANAGEMENT_PATH);
		}
	};

	const initialValues: UpdateDepartmentDto = departmentInfo
		? {
				...departmentInfo.row,
				departmentId: departmentInfo.row.id,
		  }
		: {
				departmentId: 0,
				departmentName: '',
		  };

	const {
		setFieldValue,
		values: formikValues,
		validateForm,
		handleSubmit: formikHandleSubmit,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			setAlertState(AlertState.NONE);

			await handleSubmit(values);

			dispatch(fullLoadingOff());
		},
	});

	const handleModifyClick = async () => {
		const res = await validateForm();

		if (Object.keys(res).length) return;

		setAlertState(AlertState.MODIFY);
	};

	const renderTable = <T extends Record<keyof T, any>>({
		tableKeys,
		data,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		data: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value]) => {
			const tableContent = data[key as keyof T];
			const { label } = value;

			switch (key) {
				case 'createdAt':
					return (
						<GridItem key={key} title={label} size={GridTitleSize.GT_MEDIUM}>
							{dayjs(tableContent).format('YYYY-MM-DD HH:mm:ss')}
						</GridItem>
					);

				case 'company':
					return (
						<div key={key}>
							<GridItem
								key={`${key}-name`}
								title={label}
								size={GridTitleSize.GT_MEDIUM}
							>
								{tableContent ? tableContent.name : '-'}
							</GridItem>
							<GridItem
								key={`${key}-registrationNumber`}
								title="사업자번호"
								size={GridTitleSize.GT_MEDIUM}
							>
								{tableContent
									? companyRegisterNumberFormat(
											tableContent.businessLicenseNumber,
									  )
									: '-'}
							</GridItem>
						</div>
					);

				default:
					return (
						<GridItem key={key} title={label} size={GridTitleSize.GT_MEDIUM}>
							{tableContent || '-'}
						</GridItem>
					);
			}
		});
	};

	const renderAlert = () => {
		switch (alertState) {
			case AlertState.MODIFY:
				return (
					<Alert
						title="변경사항 저장"
						closeButtonClick={() => setAlertState(AlertState.NONE)}
						onConfirmButtonText="저장하기"
						onConfirmButtonClick={() => formikHandleSubmit()}
						isVisible={alertState === AlertState.MODIFY}
					>
						소속 정보를 저장하시겠습니까?
					</Alert>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="소속 상세"
				onBack={() => navigate(-1)}
				rightAccessory={
					<ButtonContainer>
						<Button
							onClick={handleModifyClick}
							size="small"
							disabled={!isUpdateDepartment}
						>
							{isUpdateDepartment ? '수정하기' : '권한 없음'}
						</Button>
					</ButtonContainer>
				}
			>
				{isLoading ? (
					<ContentBox borderRadius="10px">
						<Skeleton active paragraph={{ rows: 2 }} />
					</ContentBox>
				) : (
					<ContentBoxWithHeader
						title="소속 기본 정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{departmentInfo &&
								renderTable({
									tableKeys: departmentInfoTableKey,
									data: departmentInfo.row,
								})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
				<ContentBoxWithHeader
					title="소속 정보 수정"
					borderRadius="10px"
					className="inner-content"
				>
					<InnerContent>
						<InputForm>
							<Label name="departmentId" essential>
								<span className="label">
									<Typo $typoType="label2">소속명</Typo>
								</span>
							</Label>
							<Input
								name="departmentName"
								value={formikValues.departmentName}
								type="text"
								onChange={(e) => {
									setFieldValue('departmentName', e.target.value);
								}}
								placeholder="변경할 소속명을 입력해주세요."
							/>
						</InputForm>
					</InnerContent>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
}

export default DepartmentDetailPage;
