import {
	MakeVehiclePlateDto,
	UpdateVehiclePlateDto,
	VehiclePlateDto,
} from '../../../interface/vehiclePlate';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const vehiclePlateApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVehiclePlates: builder.query<
			ListResponse<VehiclePlateDto>,
			Record<any, any>
		>({
			query: (args) => {
				return {
					url: `/members/vehicle-plates`,
					params: args,
				};
			},
			providesTags: ['VehiclePlate'],
		}),
		getVehiclePlateDetail: builder.query<
			ObjectResponse<VehiclePlateDto>,
			{ plateNumber: string | undefined }
		>({
			query: ({ plateNumber }) => {
				return {
					url: `/members/vehicle-plates/${plateNumber}`,
				};
			},
			providesTags: (args) => [
				{ type: 'VehiclePlate', id: args?.row.vehicleNumber },
			],
		}),
		makeVehiclePlate: builder.mutation<
			ObjectResponse<VehiclePlateDto>,
			MakeVehiclePlateDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/vehicle-plates`,
				body,
			}),
			invalidatesTags: ['VehiclePlate'],
		}),
		updateVehiclePlate: builder.mutation<
			ObjectResponse<VehiclePlateDto>,
			{ body: UpdateVehiclePlateDto; plateNumber: string | undefined }
		>({
			query: ({ body, plateNumber }) => ({
				method: 'PUT',
				url: `/members/vehicle-plates/${plateNumber}`,
				body,
			}),
			invalidatesTags: ['VehiclePlate', 'Vehicles'],
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetVehiclePlatesQuery,
	useGetVehiclePlateDetailQuery,
	useMakeVehiclePlateMutation,
	useUpdateVehiclePlateMutation,
} = vehiclePlateApi;
