import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import Typo from '../../../components/atoms/Typo';
import { Service } from '../../../interface/appVersion';
import {
	CUSTOMER_APP_LABEL,
	MANAGER_APP_LABEL,
	TERMS_TYPE_CARD_REGISTER_LABEL,
	TERMS_TYPE_CARD_REGISTER_VALUE,
	TERMS_TYPE_SIGNUP_LABEL,
	TERMS_TYPE_SIGNUP_VALUE,
} from '../../../utils/const';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_DATE,
} from '../../../utils/row-data-util';

type termsManagementDataType = Omit<GridDataModel, 'forms'>;

const termsManagementData: termsManagementDataType = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'title',
				headerName: '약관명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록 날짜',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DATE,
			},
		},
		{
			tableInfo: {
				field: 'service',
				headerName: '플랫폼',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: ({ value }: { value: Service }) => {
					if (value === Service.CUSTOMER_APP) return CUSTOMER_APP_LABEL;

					if (value === Service.MANAGER_APP) return MANAGER_APP_LABEL;

					return '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'type',
				headerName: '적용 영역',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (value === TERMS_TYPE_CARD_REGISTER_VALUE)
						return TERMS_TYPE_CARD_REGISTER_LABEL;

					if (value === TERMS_TYPE_SIGNUP_VALUE) return TERMS_TYPE_SIGNUP_LABEL;

					return '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'optionable',
				headerName: '필수 여부',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: ({ value }: any) => {
					if (value) {
						return <Typo $typoType="b6">&#8226; 선택</Typo>;
					}

					return (
						<Typo $typoType="b6" color="primary_6_main">
							&#8226; 필수
						</Typo>
					);
				},
			},
		},
		{
			tableInfo: {
				field: 'viewable',
				headerName: '노출 여부',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: ({ value }: any) => {
					if (value) {
						return (
							<Typo $typoType="b6" color="primary_6_main">
								&#8226; 노출
							</Typo>
						);
					}

					return <Typo $typoType="b6">&#8226; 미노출</Typo>;
				},
			},
		},
		{
			tableInfo: {
				field: 'status',
				headerName: '관리',
			},
			options: {
				flex: 1,
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
};

export default termsManagementData;
