/* eslint-disable no-param-reassign */
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import store from '../store';

const axiosInstanceV1 = axios.create({
	baseURL: process.env.REACT_APP_API_URL_V1,
});

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

const axiosInstanceAnyMobility = axios.create({
	baseURL: 'https://asia-northeast3-anydtg-env.cloudfunctions.net',
});

const requestConfig = (config: InternalAxiosRequestConfig<any>) => {
	const { token } = store.getState().auth;
	if (token) {
		config.headers.setAuthorization(token, true);
		config.headers.set('service-name', 'BOS_WEB', true);
	}
	return config;
};

const responseOnRejected = (error: AxiosError<any>) => {
	if (
		error?.response?.data &&
		!error.response.data.translate &&
		typeof error.response.data.message === 'object' &&
		error.response.data.message.length &&
		(error.response.data.message as string[]).find(
			(item) => item.indexOf('regular expression') > -1,
		)
	) {
		error.response.data.translate = '양식이 잘못되었습니다.';
	}

	if (
		error.response?.status === 500 ||
		error.response?.status === 502 ||
		error.response?.status === 503
	) {
		window.location.href = '/error/503';
	}
	if (error.response?.status === 401 || error.response?.status === 403) {
		window.location.href = '/error/401';
	}
	return Promise.reject(error);
};

axiosInstanceV1.interceptors.request.use(requestConfig);
axiosInstanceV1.interceptors.response.use((res) => res, responseOnRejected);

axiosInstance.interceptors.request.use(requestConfig);
axiosInstance.interceptors.response.use((res) => res, responseOnRejected);

export default axiosInstanceV1;
export { axiosInstance, axiosInstanceAnyMobility };
