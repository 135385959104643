import { GridApi, RowSelectedEvent } from 'ag-grid-community';
import { AxiosError } from 'axios';
import {
	useEffect,
	useMemo,
	useState,
	useCallback,
	useContext,
	useRef,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/src/query/endpointDefinitions';
import { ToastContext } from '../../contexts/Toast';
import { getListViaPath, getListViaPathV1 } from '../../store/apis/common';
import { createColList, TableColItem } from '../../utils/grid-util';
import { ListResponse } from '../../store/apis/@types';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface useGridDataProps {
	useQuery?: UseQuery<QueryDefinition<any, any, any, ListResponse<any>, 'api'>>;
	queryPath: string;
	formInitialValues: any;
	colsList: TableColItem[];
	detailPath?: string;
	version?: string;
}

type GridDataErrorType = {
	error: string;
	message: string;
	statusCode: number;
	translate: string;
};

function useGridData({
	queryPath,
	formInitialValues,
	colsList,
	detailPath,
	version = 'v2',
}: useGridDataProps) {
	const [searchParams, setSearchParams] = useSearchParams(
		new URLSearchParams(window.location.search),
	);

	const filter = JSON.parse(searchParams.get('filter') as string);
	const pageFilter = JSON.parse(searchParams.get('page') as string);
	const pageSizeFilter = JSON.parse(searchParams.get('pageSize') as string);

	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [gridApi, setGridApi] = useState<GridApi>();
	const [filterValues, setFilterValues] = useState(filter || formInitialValues);
	const [page, setPage] = useState(pageFilter || DEFAULT_CURRENT_PAGE);
	const [pageSize, setPageSize] = useState(pageSizeFilter || DEFAULT_PAGE_SIZE);
	const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
	const [data, setData] = useState<any[] | null>(null);
	const [dataCount, setDataCount] = useState<number>(0);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const isInitRef = useRef(true);

	const params = useMemo(() => {
		const values = { ...filterValues };

		Object.keys(values).forEach((key) => {
			if (!values[key] || typeof values[key] !== 'string') {
				delete values[key];
			}
		});

		return values;
	}, [filterValues]);

	const getData = useCallback(async () => {
		const url = `${queryPath}`;

		try {
			setIsFetching(true);

			const { rows, count } =
				version === 'v1'
					? await getListViaPathV1(`${url}`, {
							...params,
							page,
							pageSize,
					  })
					: await getListViaPath(`${url}`, {
							...params,
							page,
							pageSize,
					  });

			setData(rows);
			setDataCount(count ?? 0);
			setIsError(false);
		} catch (e) {
			const err = e as AxiosError<GridDataErrorType>;

			if (err.response && err.response.status !== 404) {
				toast(
					'error',
					err.response?.data?.translate || err.response?.data?.message,
				);
			}

			setData([]);
			setDataCount(0);
			setIsError(true);
		} finally {
			setIsFetching(false);
		}
	}, [page, pageSize, params, queryPath, toast, version]);

	const cols = createColList(colsList);

	const handlePageChange = (pageValue: number) => {
		setPage(pageValue);
	};

	const handlePageSizeChange = (pageValue: number, pageSizeValue: number) => {
		setPage(pageValue);
		setPageSize(pageSizeValue);
	};

	const handleSearchSubmit = (values: any) => {
		setFilterValues((prev: any) => ({
			page: prev.page,
			pageSize: prev.pageSize,
			...values,
		}));

		if (isInitRef.current) {
			isInitRef.current = false;
			return;
		}

		setPage(DEFAULT_CURRENT_PAGE);
		setPageSize(DEFAULT_PAGE_SIZE);
	};

	const handleRowSelected = (e: RowSelectedEvent<any>) => {
		if (e.node.isSelected()) {
			setSelectedRowIds((prev) => [...prev, e.data.id]);
		} else {
			setSelectedRowIds((prev) => prev.filter((id) => id !== e.data.id));
		}
	};

	const handleDetailClick = (id: RowSelectedEvent<any>) => {
		if (detailPath) {
			navigate(`${detailPath}/${id}`);
		}
	};

	useEffect(() => {
		getData();
	}, [getData]);

	useEffect(() => {
		if (isFetching && gridApi) {
			gridApi.showLoadingOverlay();
		} else if (!isFetching && gridApi) {
			if (data && !data.length) {
				gridApi.showNoRowsOverlay();
			} else {
				gridApi.hideOverlay();
			}
		}
	}, [isFetching, data, gridApi]);

	useEffect(() => {
		searchParams.set('page', String(page));
		searchParams.set('pageSize', String(pageSize));

		setSearchParams(searchParams);
	}, [page, pageSize, searchParams, setSearchParams]);

	return {
		rowData: !isError ? data : [],
		totalCount: !isError ? dataCount || 0 : 0,
		cols,
		isFetching,
		isError,
		page,
		pageSize,
		selectedRowIds,
		setGridApi,
		refetch: getData,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleRowSelected,
		handleDetailClick,
		setSelectedRowIds,
		setPage,
	};
}

export default useGridData;
