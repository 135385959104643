import React, { useMemo } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import styled from 'styled-components';
import { ColDef, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'lottie-react';
import Icon from '../Icon';
import Typo from '../Typo';
import {
	radioCheckIcon,
	radioCheckNoneIcon,
	selectGridNoDataIcon,
} from '../../../assets/icon';
import LoadingLottie from '../../../assets/lottie/loading.json';
import { CELL_TYPE_RADIO } from '../../../utils/row-data-util';

type SelectionType = 'checkbox' | 'radio';

interface SelectionGridProps<T> extends AgGridReactProps<T> {
	type: SelectionType;
	rowData: T[];
	column: ColDef[];
	onGridReadyCallback?: (event: GridReadyEvent<T>, filedId: string) => void;
	frameworkComponents?: Record<string, React.ComponentType<any>>;
	// frameworkComponents?: { [key: string]: React.ComponentType<any> };
}

const SelectionGridContainer = styled.div`
	width: 100%;
	height: 100%;
	border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
	border-radius: 8px;
	overflow: hidden;

	.ag-theme-alpine {
		width: 100%;
		height: 100%;
	}

	.ag-radio-button {
		text-overflow: unset;
		padding: 0 11px;
	}

	.ag-header.ag-pivot-off {
		border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_3};
	}

	input[type='radio'] {
		appearance: none;
		width: 24px;
		height: 24px;
		background: url(${radioCheckNoneIcon});
		vertical-align: middle;
	}

	input[type='radio']:checked {
		background: url(${radioCheckIcon});
	}
`;

const NoRowsTemplateContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: ${({ theme }) => theme.common.colors.gray_6};
`;

const LoadingTemplateContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.common.colors.gray_6};
	background: #fff;
`;

export function NoRowsTemplate() {
	return (
		<NoRowsTemplateContainer className="no-rows-data-container">
			<Icon src={selectGridNoDataIcon} width={56} height={56} />
			<Typo $typoType="h8" color="gray_6">
				데이터가 존재하지 않습니다.
			</Typo>
		</NoRowsTemplateContainer>
	);
}

export function LoadingTemplate() {
	return (
		<LoadingTemplateContainer className="no-rows-data-container">
			<Lottie animationData={LoadingLottie} loop style={{ width: '250px' }} />
		</LoadingTemplateContainer>
	);
}

function SelectionGrid<T>(props: SelectionGridProps<T>) {
	const { column, type, onFirstDataRendered, onGridReadyCallback } = props;

	const defaultColDef: ColDef = {
		cellStyle: {
			fontSize: '14px',
			display: 'flex',
			alignItems: 'center',
		},
		resizable: true,
		cellRenderer: (value: ICellRendererParams) => {
			if (!value.value) {
				return '-';
			}
			return value.value;
		},
	};

	const fieldId = useMemo(() => uuidv4(), []);

	const getColumn = (): ColDef[] => {
		if (type === 'radio') {
			return [
				{
					headerName: '',
					field: fieldId,
					minWidth: 48,
					maxWidth: 48,
					cellClass: 'ag-radio-button',
					cellRenderer: CELL_TYPE_RADIO,
					pinned: true,
					lockPosition: true,
					resizable: false,
				},
				...column,
			];
		}

		return column;
	};

	const handleGridReady = (event: GridReadyEvent<T>) => {
		if (onGridReadyCallback) {
			onGridReadyCallback(event, fieldId);
		}
	};

	return (
		<SelectionGridContainer className="ag-theme-alpine">
			<AgGridReact
				columnDefs={getColumn()}
				rowSelection={type === 'radio' ? 'single' : 'multiple'}
				defaultColDef={defaultColDef}
				suppressRowClickSelection={type === 'checkbox'}
				noRowsOverlayComponent={NoRowsTemplate}
				singleClickEdit
				// enterMovesDownAfterEdit
				// enterMovesDown
				reactiveCustomComponents
				rowHeight={32}
				onFirstDataRendered={onFirstDataRendered}
				onGridReady={handleGridReady}
				loadingOverlayComponent={LoadingTemplate}
				{...props}
			/>
		</SelectionGridContainer>
	);
}

export default SelectionGrid;
