import React from 'react';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import {
	useUploadCarServiceExcelMutation,
	useUploadPublicCorpExcelMutation,
} from '../../../store/apis/carService';
import UploadCarServiceExcel from './UploadCarServiceExcel';

const CarServiceExcelUpload = () => {
	const [uploadCarServiceExcel, { isLoading: isCarServiceUpdating }] =
		useUploadCarServiceExcelMutation();

	const [uploadPublicCorpExcel, { isLoading: isPublicCorpUpdating }] =
		useUploadPublicCorpExcelMutation();

	return (
		<GridDetailTemplate detailTitle="통합 업로드">
			<ContentBoxWithHeader
				title="접수정보 업로드"
				className="inner-content"
				borderRadius="10px"
			>
				<UploadCarServiceExcel
					uploadExcel={uploadCarServiceExcel}
					isUpdating={isCarServiceUpdating}
				/>
			</ContentBoxWithHeader>
			<ContentBoxWithHeader
				title="공단검사결과 업로드"
				className="inner-content"
				borderRadius="10px"
			>
				<UploadCarServiceExcel
					uploadExcel={uploadPublicCorpExcel}
					isUpdating={isPublicCorpUpdating}
				/>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	);
};

export default CarServiceExcelUpload;
