import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetVehicleStatusQuery } from '../../../../store/apis/vehicleStatus';
import VehicleStatusTemplate from '../../../../components/templates/VehicleStatusTemplate';
import { UpdateVehicleStatus } from '../../../../interface/vehicleStatus';

function VehicleStatusDetailPage() {
	const { id } = useParams();

	const { data } = useGetVehicleStatusQuery({ id });
	const vehicleStatus = data?.row;

	const initialValues: UpdateVehicleStatus = {
		vehicleNumber: vehicleStatus?.vehicleNumber,
		managerName: vehicleStatus?.managerName,
		memo: vehicleStatus?.memo,
		mileage: vehicleStatus?.mileage,
		inspectedAt: vehicleStatus?.inspectedAt,
		managerId: vehicleStatus?.manager?.id,
		subscriptionItemNo: vehicleStatus?.subscriptionItem?.subscriptionItemNo,
		itemList: vehicleStatus?.itemList,
	};

	return (
		<VehicleStatusTemplate
			initialValues={initialValues}
			manager={vehicleStatus?.manager}
		/>
	);
}

export default VehicleStatusDetailPage;
