import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GridApi } from 'ag-grid-community';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import diff from 'microdiff';
import _ from 'lodash';
import Joyride, { Step } from 'react-joyride';
import GridDetailTemplate from '../GridDetailTemplate';
import {
	VEHICLE_SUBSCRIPTION_CONSULTING_CREATE_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH,
} from '../../../routes/constants/urls';
import Button from '../../atoms/Button';
import theme, { ButtonTypeType } from '../../../styles/theme';
import useCheckRole from '../../../hooks/useCheckRole';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import Typo from '../../atoms/Typo';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import DateTimePicker from '../../atoms/DateTimePicker';
import TextArea from '../../atoms/TextArea';
import {
	useMakeSubscriptionVehicleConsultingMutation,
	useUpdateSubscriptionVehicleConsultingMutation,
} from '../../../store/apis/vehicleSubscriptionConsulting';
import {
	MakeVehicleSubscriptionConsultingDto,
	UpdateVehicleSubscriptionConsultingDto,
	VehicleSubscriptionConsultingDetailDto,
} from '../../../interface/vehicleSubscriptionConsulting';
import { phoneFormat } from '../../../utils/data-format';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import Alert from '../../atoms/Alert';
import HistoryDetail from '../../molecules/HistoryDetail';
import sortedColsList from '../../../utils/historyDetail-util';
import VehicleConsultingGridData from '../../../pages/OpenTruckManagement/VehicleSubscriptionConsulting/ConsultingDetail/gridData';
import { RootState } from '../../../store';
import markTutorialCompleted from '../../../utils/tutorial-utils';
import useTutorialGuide from '../../../hooks/useTutorialGuide';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface VehicleSubscriptionTemplateProps {
	gridRef?: (api: GridApi) => void;
	initialValues:
		| MakeVehicleSubscriptionConsultingDto
		| UpdateVehicleSubscriptionConsultingDto;
	totalCount?: number;
	rowData?: Partial<VehicleSubscriptionConsultingDetailDto>[];
	currentPage?: number;
	currentPageSize?: number;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (page: number, size: number) => void;
	isFetching?: boolean;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
}

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ConsultingInfo = styled.div`
	display: flex;
	align-items: center;
`;

const consultingType = ['일 렌트', '월 렌트', '장기렌트', '보험대차'];
const customerType = ['개인', '법인', '개인사업자'];

const VehicleSubscriptionConsultingTemplate = ({
	initialValues,
	gridRef,
	totalCount = 0,
	rowData = [],
	currentPage = DEFAULT_CURRENT_PAGE,
	currentPageSize = DEFAULT_PAGE_SIZE,
	onPageChange,
	onPageSizeChange,
	isFetching = false,
}: VehicleSubscriptionTemplateProps) => {
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;
	const { consultationId } = useParams();
	const tutorialId = 'vehicleSubscriptionConsultingDetail';
	const location = useLocation();

	const handleTutorialCompletion = () => {
		markTutorialCompleted(userId!, tutorialId); // Pass userId and tutorialKey
	};

	const steps: Step[] = [
		{
			content: (
				<div style={{ maxWidth: '400px', textAlign: 'left', padding: '1rem' }}>
					<h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
						차량구독 상담 페이지입니다.
					</h3>

					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						차량 구독(오픈트럭) 서비스의 상담 내용을 작성합니다. 고객이 상담을
						받을 때 전달한 상담 유형 및 고객 정보, 상담 내용 등을 작성합니다.
					</p>
					<br />
					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						각 항목은 차량 구독 서비스에 대해 명확하게 이해할 수 있도록 필요한
						내용입니다. 상담 내용을 바탕으로 추후 고객에게 차량 구독 견적서를
						생성하고 발송할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'center',
			target: 'body',
		},
		{
			title: '상담 정보',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					구독의 유형을 선택해주세요. 한달이 넘어가는 기간은 장기렌트로
					선택해주세요.
				</div>
			),

			locale: {
				skip: (
					<div style={{ textAlign: 'center' }}>
						<strong
							style={{
								padding: '8px 16px',
								backgroundColor: theme.common.colors.gray_12,
								color: '#fff',
								borderRadius: '4px',
								cursor: 'pointer',
							}}
							aria-label="skip"
							onClick={handleTutorialCompletion}
						>
							건너뛰기
						</strong>
					</div>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-start',
			target: '#step-1',
		},
		{
			title: '고객 정보',
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>&bull; 고객 유형: 개인, 법인, 개인사업자 중 선택해주세요.</p>
					<p>
						<span style={{ color: 'red' }}>&bull; (필수) </span>
						고객명 : 고객의 성함을 입력하세요.
					</p>
					<p>
						<span style={{ color: 'red' }}>&bull; (필수) </span> 이메일 : 올바른
						이메일 형식(예: example@domain.com)으로 입력하세요.
					</p>
					<p>
						<span style={{ color: 'red' }}>&bull; (필수) </span>연락처 : 예시와
						같은 10~11자리 숫자를 입력해주세요. 중간에 -는 자동으로 입력됩니다.
						(예: 010-xxxx-xxxx)
					</p>
					<p>
						&bull; 담당자 이름 : 고객 유형이 법인, 개인사업자인 경우 담당자의
						이름을 따로 입력할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<div style={{ textAlign: 'center' }}>
						<strong
							style={{
								padding: '8px 16px',
								backgroundColor: theme.common.colors.gray_12,
								color: '#fff',
								borderRadius: '4px',
								cursor: 'pointer',
							}}
							aria-label="skip"
							onClick={handleTutorialCompletion}
						>
							건너뛰기
						</strong>
					</div>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-end',
			target: '#step-2',
		},
		{
			title: '상담내용',
			content: (
				<div style={{ textAlign: 'left' }}>
					<span style={{ color: 'red' }}>&bull; (필수) </span>
					배차일시 : 차량 구독 서비스 시작일을 선택하세요.
					<p>
						<span style={{ color: 'red' }}>&bull; (필수) </span>
						회차(반납)일시 : 차량 구독 서비스 종료일을 선택하세요.
					</p>
					<p>
						<span style={{ color: 'red' }}>&bull; (필수) </span>
						차량모델 : 포터, 봉고 등 모델을 자유롭게 입력할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<div style={{ textAlign: 'center' }}>
						<strong
							style={{
								padding: '8px 16px',
								backgroundColor: theme.common.colors.gray_12,
								color: '#fff',
								borderRadius: '4px',
								cursor: 'pointer',
							}}
							aria-label="skip"
							onClick={handleTutorialCompletion}
						>
							건너뛰기
						</strong>
					</div>
				),
				last: '닫기',
				back: '뒤로',
			},
			placement: 'auto',
			target: '#step-3',
		},
	];

	const { handleJoyrideCallback, localStorageDetail } = useTutorialGuide(
		userId!,
		tutorialId,
		steps,
	);

	const isVehicleSubscriptionConsultingCreatePage =
		location.pathname === VEHICLE_SUBSCRIPTION_CONSULTING_CREATE_PATH;

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);

	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();

	const [makeVehicleSubscriptionConsulting] =
		useMakeSubscriptionVehicleConsultingMutation();
	const [updateVehicleSubscriptionConsulting] =
		useUpdateSubscriptionVehicleConsultingMutation();

	const isCreateVehicleSubscriptionConsulting = useCheckRole({
		roleType: 'isCreate',
		roleName: '신청관리',
		includeSuper: true,
	});

	const isUpdateVehicleSubscriptionConsulting = useCheckRole({
		roleType: 'isUpdate',
		roleName: '신청관리',
		includeSuper: true,
	});

	const vehicleSubscriptionConsultingValues = rowData?.map(
		(vehicleConsulting) => {
			return {
				createdAt: vehicleConsulting.createdAt,
				memo: vehicleConsulting.payload?.memo,
				consultationType: vehicleConsulting.payload?.consultationType,
				customerType: vehicleConsulting.payload?.customerType,
				customerName: vehicleConsulting.payload?.customerName,
				customerPhone: vehicleConsulting.payload?.customerPhone,
				customerEmail: vehicleConsulting.payload?.customerEmail,
				contactName: vehicleConsulting.payload?.contactName,
				vehicleName: vehicleConsulting.payload?.vehicleName,
				subscriptionStartedAt: vehicleConsulting.payload?.subscriptionStartedAt,
				subscriptionEndedAt: vehicleConsulting.payload?.subscriptionEndedAt,
			};
		},
	);

	const { colsList } = VehicleConsultingGridData;

	const filteredCols = colsList;

	const fixedColumnsKeys = ['createdAt', 'memo'];

	const sortedColsListResult = sortedColsList(
		rowData,
		filteredCols,
		fixedColumnsKeys,
	);

	const handleValidate = (
		values:
			| MakeVehicleSubscriptionConsultingDto
			| UpdateVehicleSubscriptionConsultingDto,
	) => {
		setIsTrySubmit(true);

		const errors: Partial<
			Record<keyof MakeVehicleSubscriptionConsultingDto, string>
		> = {};

		if (!values.consultationType) {
			errors.consultationType = '상담 유형을 선택해주세요.';
		}

		if (values.customerName === '') {
			errors.customerName = '고객명을 입력해주세요.';
		}

		if (!values.customerPhone) {
			errors.customerPhone = '연락처를 입력해주세요.';
		} else {
			const formattedPhone = phoneFormat(values.customerPhone);
			if (!/^010-\d{3,4}-\d{4}$/.test(formattedPhone)) {
				errors.customerPhone = '유효한 전화번호 형식이 아닙니다.';
			}
		}

		if (values.vehicleName === '') {
			errors.vehicleName = '차량모델을 입력해주세요.';
		}

		if (values.customerType !== '개인' && values.contactName === '') {
			errors.contactName = '담당자를 입력해주세요.';
		}

		if (!values.customerEmail) {
			errors.customerEmail = '이메일을 입력해주세요.';
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.customerEmail)
		) {
			errors.customerEmail = '유효한 이메일 주소 형식이 아닙니다.';
		}

		const startDate = values.subscriptionStartedAt;
		const endDate = values.subscriptionEndedAt;

		if (startDate && endDate && dayjs(startDate).isAfter(dayjs(endDate))) {
			errors.subscriptionStartedAt = '시작일은 종료일보다 빨라야 합니다.';
			errors.subscriptionEndedAt = '종료일은 시작일보다 늦어야 합니다.';
		}
		return errors;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const result = await makeVehicleSubscriptionConsulting(
			values as MakeVehicleSubscriptionConsultingDto,
		);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 구독 상담이 등록되었습니다.');
			navigate(VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH);
		}
	};

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleCancelClick = () => {
		navigate(VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH);
	};

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const diffValues = diff(initialValues, values);
		const updateValues = {};
		diffValues.map((item) => {
			if (item.type === 'CREATE' || item.type === 'CHANGE') {
				_.set(updateValues, item.path.join('.'), item.value);
			}
			return null;
		});

		if (!diffValues.length) {
			toast('info', '차량 구독 상담내용 수정이 완료되었습니다.');
			setAlertState(AlertState.NONE);
			return;
		}

		const result = await updateVehicleSubscriptionConsulting({
			body: updateValues,
			consultationId: Number(consultationId),
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 구독 상담내용 수정이 완료되었습니다.');
		}
		setAlertState(AlertState.NONE);
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			if (!consultationId) {
				await handleCreateSubmit(values);
			} else {
				await handleUpdateSubmit(values);
			}

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const inputStyle = (hasError: string) => ({
		backgroundColor: hasError ? 'pink' : '',
		border: hasError ? '1px solid red' : '',
	});

	const handleAction = useCallback(
		async (type: number) => {
			const errorObject = await formik.validateForm(formik.values);

			const isValid = Object.keys(errorObject).length === 0;

			if (isValid) {
				setAlertState(type);
			} else {
				const consultationTypeError = errorObject.consultationType;
				const phoneError = errorObject.customerPhone;
				const emailError = errorObject.customerEmail;
				const startDateError = errorObject.subscriptionStartedAt;
				const endDateError = errorObject.subscriptionEndedAt;
				const contactError = errorObject.contactName;

				if (consultationTypeError) {
					toast('error', consultationTypeError);
				} else if (phoneError) {
					toast('error', phoneError);
				} else if (emailError) {
					toast('error', emailError);
				} else if (startDateError || endDateError) {
					toast('error', startDateError || endDateError || '');
				} else if (contactError) {
					toast('error', contactError);
				} else {
					toast('error', '필수입력값을 확인해주세요.');
				}
			}
		},
		[formik, toast],
	);

	const handleCreateQuote = (values: typeof initialValues) => {
		const queryParams = new URLSearchParams({
			customerType: values.customerType || '',
			customerName: values.customerName || '',
			customerPhone: values.customerPhone || '',
			customerEmail: values.customerEmail || '',
			subscriptionStartedAt: values.subscriptionStartedAt || '',
			subscriptionEndedAt: values.subscriptionEndedAt || '',
		});
		navigate(`${VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH}?${queryParams}`);
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="차량 구독 상담 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						상담을 등록하시겠습니까?
					</Alert>
				);

			case AlertState.UPDATE:
				return (
					<Alert
						title="차량 구독 상담 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						상담을 수정하시겠습니까?
					</Alert>
				);

			default:
				return null;
		}
	}, [alertState, formik]);

	return (
		<>
			<Joyride
				continuous
				callback={handleJoyrideCallback}
				run={isVehicleSubscriptionConsultingCreatePage && !localStorageDetail}
				steps={steps}
				hideCloseButton
				scrollToFirstStep
				showSkipButton
				showProgress
				styles={{
					options: {
						width: '100%',
						primaryColor: theme.common.colors.primary_6_main,
					},
				}}
			/>

			<GridDetailTemplate
				detailTitle="차량구독 상담 관리"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						{!consultationId ? (
							<Button
								onClick={() => handleAction(AlertState.CREATE)}
								size="small"
								disabled={!isCreateVehicleSubscriptionConsulting}
							>
								{isCreateVehicleSubscriptionConsulting
									? '등록하기'
									: '등록 권한없음'}
							</Button>
						) : (
							<>
								<Button
									onClick={() => handleAction(AlertState.UPDATE)}
									size="small"
									$buttonType={ButtonTypeType.GHOST}
									disabled={!isUpdateVehicleSubscriptionConsulting}
								>
									{isUpdateVehicleSubscriptionConsulting
										? '수정하기'
										: '수정 권한없음'}
								</Button>
								<Button
									size="small"
									onClick={() => handleCreateQuote(formik.values)}
								>
									견적 등록
								</Button>
							</>
						)}
					</ButtonContainer>
				}
			>
				<Row gutter={[12, 12]}>
					<Col md={12}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
							<ContentBoxWithHeader
								title="상담 정보"
								borderRadius="10px"
								id="step-1"
								$isCompact
							>
								<ConsultingInfo>
									<GridItem title="상담 유형" $isCompact essential>
										{consultationId ? (
											<Typo $typoType="label2">
												{formik.values.consultationType}
											</Typo>
										) : (
											<Radio.Group
												style={{ margin: '0 auto' }}
												onChange={(e: RadioChangeEvent) =>
													formik.setFieldValue(
														'consultationType',
														e.target.value,
													)
												}
												value={formik.values.consultationType}
											>
												{consultingType.map((option) => (
													<Radio key={option} value={option}>
														{option}
													</Radio>
												))}
											</Radio.Group>
										)}
									</GridItem>
								</ConsultingInfo>
							</ContentBoxWithHeader>
							<ContentBoxWithHeader
								title="고객 정보"
								borderRadius="10px"
								id="step-2"
								$isCompact
							>
								<GridLayout $columnCount={2}>
									<GridItem title="고객 유형" $isCompact>
										<Select
											value={formik.values.customerType}
											style={{ width: '100%' }}
											onChange={(value: string) =>
												formik.setFieldValue('customerType', value)
											}
										>
											{customerType.map((customer) => (
												<Select.Option key={customer} value={customer}>
													{customer}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="고객명" $isCompact essential>
										<Input
											required
											name="customerName"
											placeholder="고객명을 입력해 주세요"
											type="text"
											value={formik.values.customerName ?? ''}
											onChange={formik.handleChange}
											style={inputStyle(formik.errors.customerName || '')}
										/>
									</GridItem>

									<GridItem
										title="연락처"
										$isCompact
										essential
										tooltip="연락처 포맷: 010-xxxx-xxxx"
									>
										<Input
											required
											name="customerPhone"
											placeholder="연락처를 입력해 주세요"
											type="text"
											value={phoneFormat(formik.values.customerPhone ?? '')}
											onChange={(e) => {
												formik.setFieldValue(
													'customerPhone',
													e.target.value.replaceAll('-', ''),
												);
											}}
											style={inputStyle(formik.errors.customerPhone || '')}
										/>
									</GridItem>
									<GridItem
										title="이메일"
										$isCompact
										essential
										tooltip="이메일 형식: example@openmile.kr"
									>
										<Input
											name="customerEmail"
											placeholder="이메일을 입력해 주세요"
											type="email"
											value={formik.values.customerEmail ?? ''}
											onChange={formik.handleChange}
											style={inputStyle(formik.errors.customerEmail || '')}
										/>
									</GridItem>
									{formik.values.customerType !== '개인' && (
										<GridItem title="담당자 이름" $isCompact>
											<Input
												name="contactName"
												placeholder="담당자를 입력해 주세요"
												type="email"
												value={formik.values.contactName ?? ''}
												onChange={formik.handleChange}
												style={inputStyle(formik.errors.contactName || '')}
											/>
										</GridItem>
									)}
								</GridLayout>
							</ContentBoxWithHeader>
							<ContentBoxWithHeader
								title="상담 내용"
								borderRadius="10px"
								id="step-3"
								$isCompact
							>
								<GridLayout $columnCount={2}>
									<GridItem title="배차일시" $isCompact essential>
										<DateTimePicker
											name="subscriptionStartedAt"
											value={dayjs(
												formik.values.subscriptionStartedAt || undefined,
											)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'subscriptionStartedAt',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="배차일시"
											allowClear={false}
											style={{ borderRadius: '4px' }}
										/>
									</GridItem>

									<GridItem title="회차(반납)일시" $isCompact essential>
										<DateTimePicker
											name="subscriptionEndedAt"
											value={dayjs(
												formik.values.subscriptionEndedAt || undefined,
											)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'subscriptionEndedAt',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="배차일시"
											allowClear={false}
											style={{ borderRadius: '4px' }}
										/>
									</GridItem>
									<GridItem title="차량모델" $isCompact essential span={2}>
										<Input
											required
											name="vehicleName"
											placeholder="차량모델을 입력해 주세요"
											type="text"
											value={formik.values.vehicleName ?? ''}
											onChange={formik.handleChange}
											style={inputStyle(formik.errors.vehicleName || '')}
										/>
									</GridItem>
								</GridLayout>
							</ContentBoxWithHeader>
						</div>
					</Col>

					{/* Right Column */}
					<Col md={12}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
							<ContentBoxWithHeader title="메모" borderRadius="10px" $isCompact>
								<TextArea
									placeholder="메모 내용을 작성해 주세요"
									name="memo"
									value={formik.values.memo ?? ''}
									onChange={formik.handleChange}
								/>
							</ContentBoxWithHeader>

							<HistoryDetail
								gridRef={gridRef}
								gridTitle="상담변경 내역"
								colsList={sortedColsListResult}
								currentPage={currentPage ?? DEFAULT_CURRENT_PAGE}
								currentPageSize={currentPageSize ?? DEFAULT_PAGE_SIZE}
								rowData={vehicleSubscriptionConsultingValues}
								totalCount={totalCount}
								isFetching={isFetching}
								handlePageChange={onPageChange || (() => {})}
								handlePageSizeChange={onPageSizeChange || (() => {})}
							/>
						</div>
					</Col>
				</Row>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
};

export default VehicleSubscriptionConsultingTemplate;
