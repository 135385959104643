import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import {
	MakeVehicleDto,
	VehicleDetailDto,
	VehicleHistoryChange,
} from '../../../interface/vehicle';

const vehiclesApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVehicles: builder.query<
			ListResponse<VehicleDetailDto>,
			Record<any, any>
		>({
			query: (args) => ({
				url: `/members/vehicles`,
				params: args,
			}),
			providesTags: ['Vehicles'],
		}),
		getVehicleDetail: builder.query<
			ObjectResponse<VehicleDetailDto>,
			{ id: number | undefined }
		>({
			query: ({ id }) => ({
				url: `/members/vehicles/id/${id}`,
			}),
			providesTags: ['Vehicles'],
		}),
		getVehicleDetailByVehicleNumber: builder.query<
			ObjectResponse<VehicleDetailDto>,
			{ vehicleNumber: string | undefined }
		>({
			query: ({ vehicleNumber }) => ({
				url: `/members/vehicles/${vehicleNumber}`,
			}),
			providesTags: ['Vehicles'],
		}),
		makeVehicle: builder.mutation<
			ObjectResponse<VehicleDetailDto>,
			MakeVehicleDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/vehicles`,
				body,
			}),
			invalidatesTags: ['Vehicles', 'VehiclePlate'],
		}),
		updateVehicle: builder.mutation<
			ObjectResponse<VehicleDetailDto>,
			{ id: number; body: MakeVehicleDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/vehicles/id/${id}`,
				body,
			}),
			invalidatesTags: ['Vehicles', 'VehiclePlate'],
		}),
		deleteVehicle: builder.mutation<unknown, { id: number }>({
			query: ({ id }) => ({
				method: 'delete',
				url: `/members/vehicles/id/${id}`,
			}),
			invalidatesTags: ['Vehicles', 'VehiclePlate'],
		}),
		updateVehicleByVehicleNumber: builder.mutation<
			ObjectResponse<VehicleDetailDto>,
			{ vehicleNumber: string | undefined; body: MakeVehicleDto }
		>({
			query: ({ vehicleNumber, body }) => ({
				method: 'PUT',
				url: `/members/vehicles/${vehicleNumber}`,
				body,
			}),
			invalidatesTags: ['Vehicles', 'VehiclePlate'],
		}),
		// 차량 변경 내역 조회
		getVehicleChangeHistories: builder.query<
			ListResponse<VehicleHistoryChange>,
			{ vehicleId: number | undefined; page: number; pageSize: number }
		>({
			query: ({ vehicleId, page, pageSize }) => ({
				method: 'GET',
				url: `/members/vehicles/${vehicleId}/histories`,
				params: { page, pageSize },
			}),
			providesTags: ['Vehicles'],
		}),
		// 차량 정보 상세 조회(취득가)
		getVehiclePurchasePrice: builder.query<
			ObjectResponse<MakeVehicleDto>,
			{ vehicleId: number | undefined }
		>({
			query: ({ vehicleId }) => ({
				url: `/members/vehicles/id/${vehicleId}/purchase-price`,
			}),
			providesTags: ['Vehicles'],
		}),
	}),
});

export const {
	useGetVehiclesQuery,
	useGetVehicleDetailQuery,
	useGetVehicleDetailByVehicleNumberQuery,
	useMakeVehicleMutation,
	useUpdateVehicleMutation,
	useDeleteVehicleMutation,
	useGetVehicleChangeHistoriesQuery,
	useGetVehiclePurchasePriceQuery,
} = vehiclesApiSlice;
