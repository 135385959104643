import {
	BaseQueryFn,
	createApi,
	FetchArgs,
} from '@reduxjs/toolkit/query/react';
import {
	GenerateAuthTokenResponseDto,
	GenerateAuthTokenDto,
	GenerateAuthTokenErrorDto,
} from '../../../interface/authToken';
import { ObjectResponse } from '../@types';
import { publicBaseQuery } from '../query';

const authTokenApi = createApi({
	reducerPath: 'authTokenApi',
	baseQuery: publicBaseQuery as unknown as BaseQueryFn<
		string | FetchArgs,
		unknown,
		GenerateAuthTokenErrorDto
	>,
	endpoints: (builder) => ({
		authToken: builder.mutation<
			ObjectResponse<GenerateAuthTokenResponseDto>,
			GenerateAuthTokenDto
		>({
			query: ({ type, key, kind }) => ({
				method: 'POST',
				url: `/core/auth-tokens`,
				body: {
					type,
					key,
					kind,
				},
			}),
		}),
	}),
});

export const { useAuthTokenMutation } = authTokenApi;
export default authTokenApi;
