import { BadgeColorType } from '../components/atoms/Badge';

export interface LabelValue {
	label: string;
	value: string | boolean | number;
}

export const ALL_OPTION_VALUE = '';
export const ALL_OPTION_LABEL = '전체';

const Y_OPTION_VALUE = 'true';
const N_OPTION_VALUE = 'false';

export type YorNType = boolean;

export const MARKETING_RECEIVABLE_OPTIONS: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: '동의', value: Y_OPTION_VALUE },
	{ label: '미동의', value: N_OPTION_VALUE },
];

export const PROVIDER_KAKAO_VALUE = 'kakao';
export const PROVIDER_GOOGLE_VALUE = 'google';
export const PROVIDER_NAVER_VALUE = 'naver';
export const PROVIDER_APPLE_VALUE = 'apple';

export const PROVIDER_OPTIONS: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: '카카오', value: PROVIDER_KAKAO_VALUE },
	{ label: '구글', value: PROVIDER_GOOGLE_VALUE },
	{ label: '네이버', value: PROVIDER_NAVER_VALUE },
	{ label: '애플', value: PROVIDER_APPLE_VALUE },
];

export const CUSTOMER_STATE_NORMAL_VALUE = 'activated';
export const CUSTOMER_STATE_WITHDRAW_VALUE = 'withdraw';
export const CUSTOMER_STATE_SUSPENSION_VALUE = 'suspended';
export const CUSTOMER_STATE_DEREGISTERED_VALUE = 'deregistered';
export const CUSTOMER_STATE_NORMAL_LABEL = '활동중';
export const CUSTOMER_STATE_WITHDRAW_LABEL = '탈퇴';
export const CUSTOMER_STATE_SUSPENSION_LABEL = '제한됨';
export const CUSTOMER_STATE_DEREGISTERED_LABEL = '탈퇴';

export const CUSTOMER_STATE_OPTIONS: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: CUSTOMER_STATE_NORMAL_LABEL, value: CUSTOMER_STATE_NORMAL_VALUE },
	{
		label: CUSTOMER_STATE_SUSPENSION_LABEL,
		value: CUSTOMER_STATE_SUSPENSION_VALUE,
	},
	{
		label: CUSTOMER_STATE_DEREGISTERED_LABEL,
		value: CUSTOMER_STATE_DEREGISTERED_VALUE,
	},
];

export const MANAGER_STATE_NORMAL_VALUE = 'activated';
export const MANAGER_STATE_PENDING_VALUE = 'pending';
export const MANAGER_STATE_SUSPENSION_VALUE = 'suspended';
export const MANAGER_STATE_NORMAL_LABEL = '활동중';
export const MANAGER_STATE_PENDING_LABEL = '대기중';
export const MANAGER_STATE_SUSPENSION_LABEL = '제한됨';
// const MANAGER_STATE_WITHDRAW_VALUE = 'withdraw';

export const MANAGER_STATE_OPTIONS: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: MANAGER_STATE_NORMAL_LABEL, value: MANAGER_STATE_NORMAL_VALUE },
	{
		label: MANAGER_STATE_PENDING_LABEL,
		value: MANAGER_STATE_PENDING_VALUE,
	},
	// { label: '탈퇴', value: MANAGER_STATE_WITHDRAW_VALUE },
	{
		label: MANAGER_STATE_SUSPENSION_LABEL,
		value: MANAGER_STATE_SUSPENSION_VALUE,
	},
];

export const INQUIRY_PENDING_VALUE = 'pending';
export const INQUIRY_REPLIED_VALUE = 'replied';

export const INQUIRY_STATUS: LabelValue[] = [
	{ label: '답변대기', value: INQUIRY_PENDING_VALUE },
	{ label: '답변완료', value: INQUIRY_REPLIED_VALUE },
];

export const PRODUCT_STATE_VISIBLE_VALUE = 'true';
export const PRODUCT_STATE_HIDE_VALUE = 'false';
export const PRODUCT_STATE_VISIBLE_LABEL = '판매중';
export const PRODUCT_STATE_HIDE_LABEL = '숨김';

export const PRODICT_STATE_OPTIONS: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: PRODUCT_STATE_VISIBLE_LABEL, value: PRODUCT_STATE_VISIBLE_VALUE },
	{ label: PRODUCT_STATE_HIDE_LABEL, value: PRODUCT_STATE_HIDE_VALUE },
];

/* 상품유형 */
// 순회정비
export const REGULAR_INSPECTION = 'REGULAR_INSPECTION';
// 타이어 교체
export const TIRE_REPLACEMENT = 'TIRE_REPLACEMENT';
// 검사대행
export const INSPECTION_AGENCY = 'INSPECTION_AGENCY';
// OBD 설치
// export const INSTALL = 'INSTALL';
export const REGULAR_INSPECTION_LABEL = '순회정비';
export const TIRE_REPLACEMENT_LABEL = '타이어 교체';
export const INSPECTION_AGENCY_LABEL = '검사대행';
// export const INSTALL_LABEL = 'INSTALL';

export const PRODUCT_TYPE_OPTIONS: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: REGULAR_INSPECTION, label: REGULAR_INSPECTION_LABEL },
	{ value: TIRE_REPLACEMENT, label: TIRE_REPLACEMENT_LABEL },
	{ value: INSPECTION_AGENCY, label: INSPECTION_AGENCY_LABEL },
];

// 작업 현황 서비스 유형
export const REGULAR_INSPECTION_SERVICE_LABEL = '순회점검';
export const SERVICE_TYPE_OPTIONS: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: REGULAR_INSPECTION, label: REGULAR_INSPECTION_SERVICE_LABEL },
	{ value: INSPECTION_AGENCY, label: INSPECTION_AGENCY_LABEL },
	{ value: TIRE_REPLACEMENT, label: TIRE_REPLACEMENT_LABEL },
];

// 작업상태
export const SUBSCRIPTION_STATUS_REQUESTED_VALUE = 'REQUESTED';
export const SUBSCRIPTION_STATUS_REQUESTED_LABEL = '작업요청';
export const SUBSCRIPTION_STATUS_ASSIGNED_VALUE = 'ASSIGNED';
export const SUBSCRIPTION_STATUS_ASSIGNED_LABEL = '배정완료';
export const SUBSCRIPTION_STATUS_COMPLETED_VALUE = 'COMPLETED';
export const SUBSCRIPTION_STATUS_COMPLETED_LABEL = '작업완료';

export const SUBSCRIPTION_ITEM_STATUS_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{
		value: SUBSCRIPTION_STATUS_REQUESTED_VALUE,
		label: SUBSCRIPTION_STATUS_REQUESTED_LABEL,
	},
	{
		value: SUBSCRIPTION_STATUS_ASSIGNED_VALUE,
		label: SUBSCRIPTION_STATUS_ASSIGNED_LABEL,
	},
	{
		value: SUBSCRIPTION_STATUS_COMPLETED_VALUE,
		label: SUBSCRIPTION_STATUS_COMPLETED_LABEL,
	},
];

// 매니저 작업상태
export const MANAGER_STATUS_PENDING_VALUE = 'PENDING';
export const MANAGER_STATUS_PENDING_LABEL = '작업전';
export const MANAGER_STATUS_STARTED_VALUE = 'STARTED';
export const MANAGER_STATUS_STARTED_LABEL = '작업중';
export const MANAGER_STATUS_COMPLETE_VALUE = 'COMPLETE';
export const MANAGER_STATUS_COMPLETE_LABEL = '작업완료';
export const MANAGER_STATUS_CONFIRMED_VALUE = 'CONFIRMED';
export const MANAGER_STATUS_CONFIRMED_LABEL = '작업완료 확정';

export const MANAGER_STATUS_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{
		value: MANAGER_STATUS_PENDING_VALUE,
		label: MANAGER_STATUS_PENDING_LABEL,
	},
	{
		value: MANAGER_STATUS_STARTED_VALUE,
		label: MANAGER_STATUS_STARTED_LABEL,
	},
	{
		value: MANAGER_STATUS_COMPLETE_VALUE,
		label: MANAGER_STATUS_COMPLETE_LABEL,
	},
	{
		value: MANAGER_STATUS_CONFIRMED_VALUE,
		label: MANAGER_STATUS_CONFIRMED_LABEL,
	},
];

// 매니저 소속
export const OPEN_MILE_VALUE = 'OPENMILE';
export const OPEN_MILE_LABEL = '오픈마일';
export const DIRECT_MANGED_VALUE = 'DIRECT_MANGED';
export const DIRECT_MANGED_LABEL = 'DIRECT_MANGED';
export const AFFILIATED_VALUE = 'AFFILIATED';
export const AFFILIATED_LABEL = 'AFFILIATED';
export const BODY_SHOP_VALUE = 'BODY_SHOP';
export const BODY_SHOP_LABEL = 'BODY_SHOP';

export const MANAGER_DEPARTMENT_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: OPEN_MILE_VALUE, label: OPEN_MILE_LABEL },
	{ value: DIRECT_MANGED_VALUE, label: DIRECT_MANGED_LABEL },
	{ value: AFFILIATED_VALUE, label: AFFILIATED_LABEL },
	{ value: BODY_SHOP_VALUE, label: BODY_SHOP_LABEL },
];

// 지도 Pin
export const MAP_PIN_CUSTOMER_VALUE = 'CUSTOMER';
export const MAP_PIN_CUSTOMER_LABEL = '고객';
export const MAP_PIN_MANAGER_VALUE = 'MANAGER';
export const MAP_PIN_MANAGER_LABEL = '매니저';

export const MAP_PIN_FILTER_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: MAP_PIN_CUSTOMER_VALUE, label: MAP_PIN_CUSTOMER_LABEL },
	{ value: MAP_PIN_MANAGER_VALUE, label: MAP_PIN_MANAGER_LABEL },
];

/* 구독상태 */
export const SUBSCRIBING_VALUE = 'subscribing';
export const SUBSCRIBING_LABEL = '구독중';
export const SUBSCRIBING_TERMINATION_VALUE = 'subscribingTermination';
export const SUBSCRIBING_TERMINATION_LABEL = '중도 해지';
export const SUBSCRIBING_ENDED_VALUE = 'subscribingEnded';
export const SUBSCRIBING_ENDED_LABEL = '구독 종료';

export const SUBSCRIBING_STATE_OPTIONS: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: SUBSCRIBING_VALUE, label: SUBSCRIBING_LABEL },
	{
		value: SUBSCRIBING_TERMINATION_VALUE,
		label: SUBSCRIBING_TERMINATION_LABEL,
	},
	{ value: SUBSCRIBING_ENDED_VALUE, label: SUBSCRIBING_ENDED_LABEL },
];

// 앱 버전 관리
export const CUSTOMER_APP = 'CUSTOMER_APP';
export const CUSTOMER_APP_LABEL = '소비자 앱';
export const MANAGER_APP = 'MANAGER_APP';
export const MANAGER_APP_LABEL = '매니저 앱';
export const IOS = 'ios';
export const AOS = 'android';

export const APP_VERSION_MANAGEMENT_PLATFORM_OPTION: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: CUSTOMER_APP_LABEL, value: CUSTOMER_APP },
	{ label: MANAGER_APP_LABEL, value: MANAGER_APP },
];

export const APP_VERSION_MANAGEMENT_OS_OPTION: LabelValue[] = [
	{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
	{ label: 'iOS', value: IOS },
	{ label: 'AOS', value: AOS },
];

/* 해지 상태 */
export const CANCEL_APPLY_VALUE = 'REQUESTED_CANCEL';
export const CANCEL_APPLY_LABEL = '해지 신청';
export const CANCEL_ENDED_VALUE = 'CONFIRMED_CANCEL';
export const CANCEL_ENDED_LABEL = '해지 완료';
export const CANCEL_APPROVED_VALUE = 'APPROVED';
export const CANCEL_APPROVED_LABEL = '해지 승인(위약금 확인필요)';
export const CANCEL_VALUE = '';

export const CANCEL_STATE_OPTIONS: LabelValue[] = [
	{ value: CANCEL_VALUE, label: ALL_OPTION_LABEL },
	{ value: CANCEL_APPLY_VALUE, label: CANCEL_APPLY_LABEL },
	{
		value: CANCEL_ENDED_VALUE,
		label: CANCEL_ENDED_LABEL,
	},
	{
		value: CANCEL_APPROVED_VALUE,
		label: CANCEL_APPROVED_LABEL,
	},
];

export const CANCEL_STATE_BADGE_COLOR: Record<string, BadgeColorType> = {
	[CANCEL_APPLY_VALUE]: 'red',
	[CANCEL_APPROVED_VALUE]: 'red',
	[CANCEL_ENDED_VALUE]: 'gray',
};

/* 재고현황 (입/출고 관리) */
export const INANDOUT_STORED_VALUE = 'stored';
export const INANDOUT_STORED_LABEL = '입고';
export const INANDOUT_RELEASED_VALUE = 'released';
export const INANDOUT_RELEASED_LABEL = '출고';

export const INVENTORY_MANAGEMENT_INANDOUT_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: INANDOUT_STORED_VALUE, label: INANDOUT_STORED_LABEL },
	{
		value: INANDOUT_RELEASED_VALUE,
		label: INANDOUT_RELEASED_LABEL,
	},
];

/* 약관 관리 */
export const TERMS_TYPE_SIGNUP_VALUE = 'SIGN_UP';
export const TERMS_TYPE_SIGNUP_LABEL = '회원가입';
export const TERMS_TYPE_CARD_REGISTER_VALUE = 'CARD_REGISTER';
export const TERMS_TYPE_CARD_REGISTER_LABEL = '카드등록';

/* 월별 매출관리 */
export const PAYMENT_STATUS_SUCCESS_VALUE = 'CONFIRMATION';
export const PAYMENT_STATUS_SUCCESS_LABEL = '결제완료';
export const PAYMENT_STATUS_EXPECTATION_VALUE = 'EXPECTATION';
export const PAYMENT_STATUS_EXPECTATION_LABEL = '결제예정';
export const PAYMENT_STATUS_FAIL_VALUE = 'REFUND';
export const PAYMENT_STATUS_FAIL_LABEL = '환불';

export const PAYMENT_STATUS_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: PAYMENT_STATUS_SUCCESS_VALUE, label: PAYMENT_STATUS_SUCCESS_LABEL },
	{
		value: PAYMENT_STATUS_EXPECTATION_VALUE,
		label: PAYMENT_STATUS_EXPECTATION_LABEL,
	},
	{
		value: PAYMENT_STATUS_FAIL_VALUE,
		label: PAYMENT_STATUS_FAIL_LABEL,
	},
];

export const PAYMENT_STATUS_BADGE_COLOR: Record<string, BadgeColorType> = {
	[PAYMENT_STATUS_SUCCESS_VALUE]: 'blue',
	[PAYMENT_STATUS_FAIL_VALUE]: 'red',
};

// 	MONTHLY_PRICE_OPTION;

const SINGLE_PAYMENT_VALUE = 'SINGLE_PAYMENT';
const TWO_MONTH_INSTALLMENT_VALUE = 'TWO_MONTH_INSTALLMENT';
const THREE_MONTH_INSTALLMENT_VALUE = 'THREE_MONTH_INSTALLMENT';
const FOUR_MONTH_INSTALLMENT_VALUE = 'FOUR_MONTH_INSTALLMENT';
const FIVE_MONTH_INSTALLMENT_VALUE = 'FIVE_MONTH_INSTALLMENT';
const SIX_MONTH_INSTALLMENT_VALUE = 'SIX_MONTH_INSTALLMENT';

export type Installment =
	| typeof SINGLE_PAYMENT_VALUE
	| typeof TWO_MONTH_INSTALLMENT_VALUE
	| typeof THREE_MONTH_INSTALLMENT_VALUE
	| typeof FOUR_MONTH_INSTALLMENT_VALUE
	| typeof FIVE_MONTH_INSTALLMENT_VALUE
	| typeof SIX_MONTH_INSTALLMENT_VALUE;

export const MONTHLY_PRICE_OPTION: LabelValue[] = [
	{ value: ALL_OPTION_VALUE, label: ALL_OPTION_LABEL },
	{ value: SINGLE_PAYMENT_VALUE, label: '일시불' },
	{ value: TWO_MONTH_INSTALLMENT_VALUE, label: '2개월' },
	{ value: THREE_MONTH_INSTALLMENT_VALUE, label: '3개월' },
	{ value: FOUR_MONTH_INSTALLMENT_VALUE, label: '4개월' },
	{ value: FIVE_MONTH_INSTALLMENT_VALUE, label: '5개월' },
	{ value: SIX_MONTH_INSTALLMENT_VALUE, label: '6개월' },
];

// 카서비스

export const INSPECTION_RECEPTION_STATE_CODE: LabelValue[] = [
	{ value: '0', label: '접수대기' },
	{ value: '2', label: '발주대기' },
	{ value: '4', label: '청구/지급' },
	{ value: '5', label: '정산확인(1차)' },
	{ value: '6', label: '정산확인(2차)' },
	{ value: '8', label: '진행중단' },
	{ value: '9', label: '최종완료' },
];

export const INSPECTION_ORDER_TYPE: LabelValue[] = [
	{ value: '업체대행', label: '업체대행' },
	{ value: '고객직검', label: '고객직검' },
];

export const INSPECTION_RESULT_TYPE: LabelValue[] = [
	{ value: '적합', label: '적합' },
	{ value: '부적합', label: '부적합' },
	{ value: '제외', label: '제외' },
];

export const INSPECTION_CANCELLATION_TYPE: LabelValue[] = [
	{ value: '정보불명', label: '정보불명' },
	{ value: '비협조', label: '비협조' },
	{ value: '수리/폐차', label: '수리/폐차' },
	{ value: '반납', label: '반납' },
	{ value: '반납예정', label: '반납예정' },
	{ value: '말소', label: '말소' },
	{ value: '미귀/도난', label: '미귀/도난' },
	{ value: '부적합', label: '부적합' },
	{ value: '제외', label: '제외' },
	{ value: '고객주소지 변경', label: '고객주소지 변경' },
];

export const INSPECTION_CALL_RESULT_TYPE: LabelValue[] = [
	{ value: '통화완료', label: '통화완료' },
	{ value: '통화불가', label: '통화불가' },
	{ value: '재통화', label: '재통화' },
	{ value: '진행중단', label: '진행중단' },
	{ value: '_재진행', label: '_재진행' },
	{ value: '_재진행완료', label: '_재진행완료' },
];

export const COMPANY_CONTRACT_TYPE: LabelValue[] = [
	{ value: '협력A1', label: '협력A1' },
	{ value: '_검사01', label: '_검사01' },
	{ value: '_검사02', label: '_검사02' },
];

export const INSPECTION_PAYMENT_STATUS: LabelValue[] = [
	{ value: '지급요청', label: '지급요청' },
	{ value: '지급대기', label: '지급대기' },
	{ value: '지급완료', label: '지급완료' },
	{ value: '지급취소', label: '지급취소' },
];

export const INSPECTION_PAYMENT_TYPE: LabelValue[] = [
	{ value: '즉시(개별)', label: '즉시(개별)' },
	{ value: '후불(일괄)', label: '후불(일괄)' },
];
