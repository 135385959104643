import React from 'react';
import { Select as AntdSelectElement, SelectProps } from 'antd';
import styled from 'styled-components';

const AntdSelect = styled(AntdSelectElement)`
	font-size: 16px;

	.ant-select-selection-placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
	}
	//.ant-select-arrow {
	//	right: 16px;
	//}
`;

const Select = ({ value, ...rest }: SelectProps<any, any>) => {
	return <AntdSelect value={value || undefined} {...rest} />;
};

Select.Option = AntdSelectElement.Option;
export default Select;
