import {
	FaqCategoryResponseDto,
	FaqDto,
	FaqResponseDto,
} from '../../../interface/faq';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const faqApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		faqCreate: builder.mutation<ObjectResponse<FaqResponseDto>, FaqDto>({
			query: (body) => ({
				method: 'POST',
				url: `/core/admin/faqs`,
				body,
			}),
		}),
		faqUpdate: builder.mutation<
			ObjectResponse<FaqResponseDto>,
			{ id: number; body: FaqDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/core/admin/faqs/${id}`,
				body,
			}),
		}),
		faqDelete: builder.mutation<ObjectResponse<FaqResponseDto>, { id: number }>(
			{
				query: ({ id }) => ({
					method: 'DELETE',
					url: `/core/admin/faqs/${id}`,
				}),
			},
		),
	}),
});

export const getFaqDetail = async (id: number) => {
	const url = `/core/admin/faqs/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<FaqResponseDto>>(url);

	return data.row;
};

export const getFaqCategory = async () => {
	const url = `/core/faqs/categories`;

	const { data } = await axiosInstance.get<
		ListResponse<FaqCategoryResponseDto>
	>(url);

	return data.rows;
};

export const {
	useFaqCreateMutation,
	useFaqDeleteMutation,
	useFaqUpdateMutation,
} = faqApi;
export default faqApi;
