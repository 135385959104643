import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import GridDetailTemplate from '../../components/templates/GridDetailTemplate';
import { useGetMyCarCareSubscriptionDetailQuery } from '../../store/apis/carCareSubscription';
import CarCareDetail from '../../components/templates/CarCareTemplate/CarCareDetail';
import ContentBox from '../../components/atoms/ContentBox';
import ContentBoxWithHeader from '../../components/molecules/ContentBoxWithHeader';
import Typo from '../../components/atoms/Typo';
import RequestCarCarService from '../../components/templates/CarCareTemplate/RequestCarCarService';
import { CarCareTaskDto } from '../../interface/subscription';
import Button from '../../components/atoms/Button';
import Divider from '../../styles/divider';
import { ButtonTypeType } from '../../styles/theme';

const MyCarCareSubscriptionDetailPage = () => {
	const { subscriptionNo } = useParams();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [taskInfo, setTaskInfo] = useState<CarCareTaskDto | undefined>(
		undefined,
	);
	const handleClose = () => {
		setOpen(false);
		setTaskInfo(undefined);
	};

	const { data } = useGetMyCarCareSubscriptionDetailQuery(
		{
			subscriptionNo,
		},
		{ skip: !subscriptionNo },
	);

	const detailData = data?.row;

	return (
		<GridDetailTemplate detailTitle="카케어 상세" onBack={() => navigate(-1)}>
			{detailData && (
				<ContentBoxWithHeader
					title="신청가능한 서비스"
					borderRadius="10px"
					$isCompact
				>
					<Row gutter={[12, 12]}>
						{detailData.totalRemainTasks.map((task) => (
							<Col key={task.productId}>
								<ContentBox borderRadius="10px">
									<Typo>{task.productName}</Typo>
									<br />
									<Typo $typoType="b7">남은 횟수: {task.remainTaskCount}</Typo>
									<Divider $verticalGap={12} />
									<Button
										$buttonType={ButtonTypeType.GHOST}
										size="small"
										onClick={() => {
											setTaskInfo(task);
											setOpen(true);
										}}
									>
										신청하기
									</Button>
								</ContentBox>
							</Col>
						))}
					</Row>
				</ContentBoxWithHeader>
			)}
			<CarCareDetail detailData={detailData} />
			<RequestCarCarService
				open={open}
				onClose={handleClose}
				taskInfo={taskInfo}
				subscriptionNo={subscriptionNo as string}
			/>
		</GridDetailTemplate>
	);
};

export default MyCarCareSubscriptionDetailPage;
