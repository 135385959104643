import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../atoms/Typo';
import { ToastContext } from '../../../contexts/Toast';
import { useSubscriptionItemUpdateMutation } from '../../../store/apis/subscriptionItem';
import Alert from '../../atoms/Alert';
import { CustomErrorType } from '../../../store/apis/@types';

interface EditModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: string;
	id: string;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormLabel = styled.p`
	${({ theme }) => theme.typo.label1};
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

function MemoModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	id,
}: EditModalProps) {
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [content, setContent] = useState(defaultValue);

	const [updateMemo] = useSubscriptionItemUpdateMutation();

	const handleToastVisibleClick = useContext(ToastContext);

	const handleModalClose = () => {
		onClose();
		setContent('');
	};

	const handleAlertOpen = () => {
		if (content.length > 0) {
			setIsAlertOpen(true);
		}
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubmitClick = () => {
		handleAlertOpen();
	};

	const handleSubmit = async () => {
		handleAlertClose();
		const result = await updateMemo({
			id,
			subscriptionMemo: content,
		});
		if (!('error' in result)) {
			handleToastVisibleClick('info', '관리자 메모가 작성되었습니다.');

			if (onRefetch) onRefetch();
			if (onClose) onClose();
		} else if ('error' in result) {
			handleToastVisibleClick(
				'error',
				(result.error as CustomErrorType).data.message,
			);
		}
		setContent('');
	};

	const renderContent = () => {
		return (
			<FormItem name="reason" label={<FormLabel>메모</FormLabel>} colon={false}>
				<Input.TextArea
					style={{ minHeight: '200px', fontSize: 16 }}
					placeholder="메모를 작성해 주세요"
					value={content}
					defaultValue={defaultValue}
					onChange={(e) => setContent(e.target.value)}
				/>
			</FormItem>
		);
	};

	return (
		<>
			<Modal
				title={<Typo $typoType="h2">메모 작성</Typo>}
				open={isVisible}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button onClick={handleSubmitClick}>완료</Button>
					</ModalFooterWrapper>
				}
			>
				<Form>{renderContent()}</Form>
			</Modal>

			{isAlertOpen && (
				<Alert
					title="관리자 메모 저장"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={handleSubmit}
					onConfirmButtonText="저장하기"
					isVisible={isAlertOpen}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							관리자 메모를 저장하시겠습니까?
						</Typo>
					</p>
				</Alert>
			)}
		</>
	);
}

export default MemoModal;
