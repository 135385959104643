/* eslint-disable no-use-before-define */
import InspectionTemplate from '../../../components/templates/InspectionTemplate';

function PartnerInspectionAgencyManagerAssignmentPage() {
	return InspectionTemplate({
		productType: 'INSPECTION_AGENCY',
		departmentId: true,
	});
}

export default PartnerInspectionAgencyManagerAssignmentPage;
