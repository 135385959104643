import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import {
	DriverDto,
	MakeDriverDto,
	UpdateDriverDto,
} from '../../../interface/driver';

const DriverApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDriversOfCompany: builder.query<
			ListResponse<DriverDto>,
			{
				companyId: string | undefined;
				driverName: string | undefined;
				driverContact: string | undefined;
				page: number;
				pageSize: number;
			}
		>({
			query: ({ companyId, driverName, driverContact, page, pageSize }) => ({
				url: `/members/drivers`,
				params: { companyId, driverName, driverContact, page, pageSize },
			}),
			providesTags: ['Driver'],
		}),
		getDriverDetailOfCompany: builder.query<
			ObjectResponse<DriverDto>,
			{ driverId: string | undefined }
		>({
			query: ({ driverId }) => ({
				url: `/members/drivers/${driverId}`,
			}),
			providesTags: ['Driver'],
		}),
		makeDriversOfCompany: builder.mutation<
			ObjectResponse<DriverDto>,
			{ body: MakeDriverDto }
		>({
			query: ({ body }) => ({
				method: 'POST',
				url: `/members/drivers`,
				body,
			}),
			invalidatesTags: ['Driver'],
		}),
		updateDriverOfCompany: builder.mutation<
			ObjectResponse<DriverDto>,
			{ driverId: string; body: UpdateDriverDto }
		>({
			query: ({ driverId, body }) => ({
				method: 'PUT',
				url: `/members/drivers/${driverId}`,
				body,
			}),
			invalidatesTags: ['Driver'],
		}),
	}),
});

const MyDriverApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMyDrivers: builder.query<
			ListResponse<DriverDto>,
			{
				companyId: string | undefined;
				driverName: string | undefined;
				driverContact: string | undefined;
			}
		>({
			query: ({ companyId, driverName, driverContact }) => ({
				url: `/members/companies/me/drivers`,
				params: { companyId, driverName, driverContact },
			}),
			providesTags: ['Driver'],
		}),
		getMyDriverDetail: builder.query<
			ObjectResponse<DriverDto>,
			{ driverId: string | undefined }
		>({
			query: ({ driverId }) => ({
				url: `/members/companies/me/drivers/${driverId}`,
			}),
			providesTags: ['Driver'],
		}),
		makeMyDriver: builder.mutation<
			ObjectResponse<DriverDto>,
			{ body: Omit<MakeDriverDto, 'companyId'> }
		>({
			query: ({ body }) => ({
				method: 'POST',
				url: `/members/companies/me/drivers`,
				body,
			}),
			invalidatesTags: ['Driver'],
		}),
		updateMyDriver: builder.mutation<
			ObjectResponse<DriverDto>,
			{ driverId: string; body: UpdateDriverDto }
		>({
			query: ({ driverId, body }) => ({
				method: 'PUT',
				url: `/members/companies/me/drivers/${driverId}`,
				body,
			}),
			invalidatesTags: ['Driver'],
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetDriversOfCompanyQuery,
	useGetDriverDetailOfCompanyQuery,
	useMakeDriversOfCompanyMutation,
	useUpdateDriverOfCompanyMutation,
} = DriverApiSlice;

export const {
	useGetMyDriversQuery,
	useGetMyDriverDetailQuery,
	useMakeMyDriverMutation,
	useUpdateMyDriverMutation,
} = MyDriverApiSlice;
