import axiosInstanceV1, { axiosInstance } from '../../../network/axios';
import { ListResponse } from '../@types';

const getListViaPathV1 = async (queryPath: string, params: any) => {
	const { data } = await axiosInstanceV1.get<ListResponse<any>>(queryPath, {
		params,
	});
	return data;
};

const getListViaPath = async (queryPath: string, params: any) => {
	const { data } = await axiosInstance.get<ListResponse<any>>(queryPath, {
		params,
	});
	return data;
};

export { getListViaPath, getListViaPathV1 };
