import React from 'react';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { TERMINATION_DETAIL_PATH } from '../../../../routes/constants/urls';
import {
	CANCEL_APPLY_LABEL,
	CANCEL_APPLY_VALUE,
	CANCEL_ENDED_LABEL,
	CANCEL_ENDED_VALUE,
	CANCEL_APPROVED_LABEL,
	CANCEL_APPROVED_VALUE,
} from '../../../../utils/const';
import { createBadgeTextCellRender } from '../../../../utils/row-data-util';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetSubscriptionsCancellationQuery } from '../../../../store/apis/termination';

function TerminationPage() {
	const { colsList, forms, formInitialValues } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetSubscriptionsCancellationQuery,
		formInitialValues,
		colsList,
		detailPath: TERMINATION_DETAIL_PATH,
	});

	return (
		<GridContentTemplate
			formInitialValues={formInitialValues}
			forms={forms}
			gridRef={setGridApi}
			mainTitle="해지 관리"
			gridTitle="해지 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			frameworkComponents={{
				...createBadgeTextCellRender([
					{
						label: CANCEL_APPLY_LABEL,
						value: CANCEL_APPLY_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_APPROVED_LABEL,
						value: CANCEL_APPROVED_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_ENDED_LABEL,
						value: CANCEL_ENDED_VALUE,
						color: 'gray',
					},
				]),
			}}
		/>
	);
}

export default TerminationPage;
