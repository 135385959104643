import React, {
	PropsWithChildren,
	useCallback,
	useContext,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import {
	ID_SEARCH,
	PASSWORD_RESET,
	PASSWORD_SEARCH,
} from '../../../routes/constants/urls';
import { useLoginMutation } from '../../../store/apis/auth';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';

import CheckboxLabel from '../../molecules/CheckboxLabel';
import LoginInputForm from '../../molecules/LoginInputForm';
import FormLayout from '../../organisms/LoginFormLayout';
import {
	SigninAdministratorDto,
	SignInErrorDto,
} from '../../../interface/auth';
import { getDefaultPathUrl, setUser, setUserTemp } from '../../../store/auth';
import { ToastContext } from '../../../contexts/Toast';
import { asideMenuList } from '../../organisms/Aside/data';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';

interface LoginTemplateProps {
	title: string;
}

const LoginTemplateContainer = styled.main`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;

	.title {
		margin-bottom: 40px;
	}

	.button-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 16px;
		margin-bottom: 24px;
		color: ${({ theme }) => theme.common.colors.gray_6};
	}

	.label {
		display: inline-block;
		margin-bottom: 6px;
	}

	.signin-keepable {
		cursor: pointer;
	}
`;

const CheckboxLabelWrap = styled.div`
	margin-bottom: 66px;
`;

const CustomButton = styled.button`
	background: none;
	border: none;
	outline: none;
	text-decoration: underline;
	text-underline-offset: 3px;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;

const Copyright = styled.p`
	color: ${({ theme }) => theme.common.colors.gray_5};
	text-align: center;
`;

function LoginTemplate({ title }: PropsWithChildren<LoginTemplateProps>) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);
	const [isCheckbox, setIsCheckbox] = useState(false);
	const [userId, setUserId] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [login] = useLoginMutation();

	const handleCheckboxClick = () => {
		setIsCheckbox((prev) => !prev);
	};

	const getUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserId(event.target.value);
	};

	const getUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserPassword(event.target.value);
	};

	const idReset = () => {
		setUserId('');
	};

	const passwordReset = () => {
		setUserPassword('');
	};

	const goToPasswordSearch = () => {
		navigate(PASSWORD_SEARCH);
	};

	const goToUserIdSearch = () => {
		navigate(ID_SEARCH);
	};

	const handleLoginClick = useCallback(
		async (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
			dispatch(fullLoadingOn());
			const res = await login({
				administratorApplicationId: userId,
				password: userPassword,
				signinKeepable: isCheckbox,
			} as SigninAdministratorDto);

			if (!('error' in res) && !!res.data.row.administrator.id) {
				dispatch(setUser(res.data.row.administrator));

				if (!res.data.row.changedTempPassword) {
					navigate(
						getDefaultPathUrl(
							asideMenuList,
							res.data.row.administrator.role.roleItems,
						),
					);
				} else {
					dispatch(setUserTemp(res.data.row.administrator));
					navigate(PASSWORD_RESET);
				}
			}
			if ('error' in res && (res.error as SignInErrorDto)) {
				toast(
					'error',
					(res.error as SignInErrorDto).data.translate ||
						(res.error as SignInErrorDto).data.message,
				);
			}

			dispatch(fullLoadingOff());
		},
		[login, userId, userPassword, isCheckbox, dispatch, navigate, toast],
	);

	return (
		<LoginTemplateContainer>
			<h1 className="title">
				<Typo $typoType="h1">{title}</Typo>
			</h1>

			<FormLayout
				formTitle={
					<h2>
						<Typo $typoType="btn1">
							탁송과 물류를
							<br /> 모두가 더 쉽게 더 이롭게
						</Typo>
					</h2>
				}
				$status="logo"
			>
				<LoginInputForm>
					<Label name="userId" essential>
						<span className="label">
							<Typo $typoType="label1">아이디</Typo>
						</span>
					</Label>
					<Input
						name="userId"
						placeholder="아이디를 입력해 주세요"
						type="text"
						$inputSize="large"
						$inputRadius="large"
						value={userId}
						onChange={getUserId}
						onReset={idReset}
					/>
				</LoginInputForm>

				<LoginInputForm>
					<Label name="userPassword" essential>
						<span className="label">
							<Typo $typoType="label1">비밀번호</Typo>
						</span>
					</Label>
					<Input
						name="userPassword"
						placeholder="비밀번호를 입력해 주세요"
						type="password"
						$inputSize="large"
						$inputRadius="large"
						value={userPassword}
						onChange={getUserPassword}
						onReset={passwordReset}
					/>
				</LoginInputForm>

				<CheckboxLabelWrap>
					<CheckboxLabel
						name="로그인 유지"
						isChecked={isCheckbox}
						onClick={handleCheckboxClick}
						$checkboxStyle="circle"
					>
						<Label name="로그인 유지">
							<p>
								<Typo $typoType="btn4" className="signin-keepable">
									로그인 상태 유지
								</Typo>
							</p>
						</Label>
					</CheckboxLabel>
				</CheckboxLabelWrap>
				<Button
					type="submit"
					size="large"
					$textCenter
					onClick={handleLoginClick}
				>
					로그인
				</Button>

				<div className="button-container">
					<CustomButton type="button" onClick={goToPasswordSearch}>
						<Typo $typoType="btn4" color="gray_6">
							비밀번호 찾기
						</Typo>
					</CustomButton>
					|
					<CustomButton type="button" onClick={goToUserIdSearch}>
						<Typo $typoType="btn4" color="gray_6">
							아이디 찾기
						</Typo>
					</CustomButton>
				</div>
				<Copyright>
					<Typo $typoType="b9" color="gray_5">
						Copyright © 2024 OPENMILE. All Rights Reserved.
					</Typo>
				</Copyright>
			</FormLayout>
		</LoginTemplateContainer>
	);
}

export default LoginTemplate;
