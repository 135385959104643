import React from 'react';
import { useNavigate } from 'react-router-dom';
import userData from './gridData';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useClientGridWithRTK from '../../../../hooks/useClientGridWithRTK';
import { useGetCompaniesCarServiceQuery } from '../../../../store/apis/carService';
import Button from '../../../../components/atoms/Button';
import { CAR_SERVICE_COMPANY_CREATE_PATH } from '../../../../routes/constants/urls';

const CarServiceCompany = () => {
	const { formInitialValues, colsList, forms } = userData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useClientGridWithRTK({
		useQuery: useGetCompaniesCarServiceQuery,
		detailPath: '',
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="업체 관리"
			gridTitle="업체 관리"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			rightAccessory={
				<Button
					onClick={() => navigate(CAR_SERVICE_COMPANY_CREATE_PATH)}
					size="small"
				>
					업체 등록하기
				</Button>
			}
			isFetching={isFetching}
			$isCompact
		/>
	);
};

export default CarServiceCompany;
