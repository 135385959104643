import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { numberWithCommas } from '../../../../utils/data-format';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'inspectedAt',
				headerName: '점검일시',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return dayjs(value).format('YYYY.MM.DD(dd)');
				},
			},
		},
		{
			tableInfo: {
				field: 'managerId',
				headerName: '담당 매니저',
			},
			options: {
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					return data.manager
						? `${data.manager.managerName} | ${data.manager.department.departmentName}`
						: data.managerName;
				},
				valueGetter: ({ data }) =>
					data.manager ? data.manager.managerName : data.managerName,
			},
		},
		{
			tableInfo: {
				field: 'mileage',
				headerName: '총 주행거리',
			},
			options: {
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value && `${numberWithCommas(value)} km`;
				},
			},
		},
		{
			tableInfo: {
				field: 'memo',
				headerName: '메모',
			},
			options: {
				flex: 1,
				width: ColSize.CW_LONG_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '상세보기',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [],
};

export default userData;
