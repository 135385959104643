import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Checkbox, { CheckboxProps } from '../../atoms/Checkbox';

const CheckboxLabelContainer = styled.div`
	display: flex;
	align-items: center;
`;

function CheckboxLabel({
	name,
	children,
	isChecked,
	$checkboxStyle = 'circle',
	onClick,
}: PropsWithChildren<CheckboxProps>) {
	return (
		<CheckboxLabelContainer>
			<Checkbox
				name={name}
				isChecked={isChecked}
				$checkboxStyle={$checkboxStyle}
				onClick={onClick}
			/>
			{children}
		</CheckboxLabelContainer>
	);
}

export default CheckboxLabel;
