import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export interface ContentBoxProps {
	className?: string;
	backgroundColor?: string;
	borderColor?: string;
	borderRadius?: string;
	$isCompact?: boolean;
}
interface ContentBoxContainerStyleProps {
	$backgroundColor: string;
	$borderColor: string;
	$borderRadius: string;
	$isCompact?: boolean;
}

const ContentBoxContainer = styled.div<ContentBoxContainerStyleProps>`
	padding: ${({ $isCompact }) => ($isCompact ? '12px' : '16px')};
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};

	${({ $backgroundColor, $borderColor, $borderRadius }) => css`
		background-color: ${$backgroundColor};
		border: 1px solid ${$borderColor};
		border-radius: ${$borderRadius};
	`}
`;

function ContentBox({
	className,
	children,
	backgroundColor = '#fff',
	borderColor = 'none',
	borderRadius = '0px',
	$isCompact = false,
}: PropsWithChildren<ContentBoxProps>) {
	return (
		<ContentBoxContainer
			className={className}
			$backgroundColor={backgroundColor}
			$borderColor={borderColor}
			$borderRadius={borderRadius}
			$isCompact={$isCompact}
		>
			{children}
		</ContentBoxContainer>
	);
}

export default ContentBox;
