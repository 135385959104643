import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import Typo from '../../../../components/atoms/Typo';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_BADGE_TEXT,
	CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION,
} from '../../../../utils/row-data-util';
import theme from '../../../../styles/theme';
import {
	INSPECTION_AGENCY,
	REGULAR_INSPECTION,
	TIRE_REPLACEMENT,
} from '../../../../utils/const';

const renderManagerStatusCell = ({ value }: ICellRendererParams) => {
	let managerStatus = '-';
	let colorStatus: keyof typeof theme.common.colors;

	if (value === null) {
		return <Typo color="gray_11">-</Typo>;
	}

	switch (value) {
		case 'PENDING':
			managerStatus = '작업 전';
			colorStatus = 'warning_5_main';
			break;
		case 'STARTED':
			managerStatus = '작업 중';
			colorStatus = 'primary_6_main';
			break;
		case 'COMPLETE':
		case 'CONFIRMED':
			managerStatus = '작업완료';
			colorStatus = 'gray_11';
			break;
		default:
			managerStatus = '-';
			colorStatus = 'gray_11';
			return null;
	}
	return <Typo color={colorStatus}>&#8226; {managerStatus}</Typo>;
};

const renderSubscriptionProductNameCell = ({ value }: ICellRendererParams) => {
	let subscprtionProductName = '';
	if (!value) return '-';

	switch (value) {
		case REGULAR_INSPECTION:
			subscprtionProductName = '순회점검';
			break;
		case INSPECTION_AGENCY:
			subscprtionProductName = '검사대행';
			break;
		case TIRE_REPLACEMENT:
			subscprtionProductName = '타이어교환';
			break;
		default:
			return '-';
	}
	return <Typo color="gray_11">{subscprtionProductName}</Typo>;
};

const TerminationDetailKeys: GridDataModel = {
	colsList: [
		{
			tableInfo: {
				field: 'managerAssignedAt',
				headerName: '관리자 배정 시간',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'subscriptionItemStatus',
				headerName: '작업상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_BADGE_TEXT,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionItemManagerStatus',
				headerName: '매니저 작업상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderManagerStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'expectationVisitedAt',
				headerName: '배정 일시',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					if (!value) {
						return '-';
					}

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'reservationVisitedAt',
				headerName: '서비스 일시',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					if (!value) {
						return '-';
					}

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'productType',
				headerName: '서비스 유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderSubscriptionProductNameCell,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionItemName',
				headerName: '작업내용',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'administratorName',
				headerName: '관리자명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'managerName',
				headerName: '매니저명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION,
			},
		},
	],
};

export default TerminationDetailKeys;
