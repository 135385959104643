import dayjs from 'dayjs';
import { PRODICT_STATE_OPTIONS } from '../../../../utils/const';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_PRICE_TEXT,
	CELL_TYPE_SELECT,
} from '../../../../utils/row-data-util';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'id',
				headerName: 'No',
			},
			options: {
				maxWidth: 72,
				flex: 1,
			},
		},

		{
			tableInfo: {
				field: 'subscriptionProductName',
				headerName: '구독 상품명',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'monthlyPriceIncludedTax',
				headerName: '판매가',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				valueGetter: (params) =>
					params.data.monthlyPrice + params.data.monthlyTax,
				cellRenderer: (params: any) => {
					return `${Number(
						params.data.monthlyPrice + params.data.monthlyTax,
					).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'monthlyPrice',
				headerName: '공급가',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'monthlyTax',
				headerName: '부가세',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionProductViewable',
				headerName: '상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_SELECT,
			},
		},
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm');
				},
			},
		},
		{
			tableInfo: {
				field: 'updatedAt',
				headerName: '수정일자',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm');
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '등록일',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'subscriptionProductViewable',
			label: '상태',
			type: FilterElementType.SELECT,
			options: PRODICT_STATE_OPTIONS,
		},
		{
			key: 'subscriptionProductName',
			label: '구독 상품명',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
