import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import {
	INANDOUT_STORED_VALUE,
	INVENTORY_MANAGEMENT_INANDOUT_OPTION,
} from '../../../utils/const';
import { FilterElementType } from '../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import {
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_OPTIONS_BULLET_TEXT,
	CELL_TYPE_PRICE_TEXT,
} from '../../../utils/row-data-util';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일시',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'updatedAt',
				headerName: '업데이트 일시',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'stockPlace',
				headerName: '지점',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'inAndOut',
				headerName: '구분',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_OPTIONS_BULLET_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'stockName',
				headerName: '제품명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'beforeQuantity',
				headerName: '기존 수량',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					return value.toLocaleString();
				},
			},
		},
		{
			tableInfo: {
				field: 'beforePrice',
				headerName: '기존 금액',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'inAndOutPlace',
				headerName: '입고/출고처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'changedQuantity',
				headerName: '변경수량',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const {
						value,
						data: { inAndOut },
					} = params;

					const prefix = inAndOut === INANDOUT_STORED_VALUE ? '+' : '-';
					return `${prefix}${value.toLocaleString()}`;
				},
			},
		},
		{
			tableInfo: {
				field: 'changedPrice',
				headerName: '변경금액',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const {
						value,
						data: { inAndOut },
					} = params;

					const prefix = inAndOut === INANDOUT_STORED_VALUE ? '+' : '-';

					return `${prefix}${value.toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'afterQuantity',
				headerName: '잔량',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: ICellRendererParams) => {
					const { value } = params;
					return value.toLocaleString();
				},
			},
		},
		{
			tableInfo: {
				field: 'afterPrice',
				headerName: '잔량금액',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'inAndOut',
			label: '구분',
			type: FilterElementType.SELECT,
			options: INVENTORY_MANAGEMENT_INANDOUT_OPTION,
		},
		{
			key: 'inAndOutPlace',
			label: '입고/출고처',
			type: FilterElementType.INPUT,
		},
		{
			key: 'stockPlace',
			label: '지점명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'stockName',
			label: '제품명',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
