import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { utils, writeFile } from 'xlsx';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import ContentBox from '../../../components/atoms/ContentBox';
import PieChartTotal from './ReportCharts/PieChartTotal';
import HeatmapChartMonthFull from './ReportCharts/HeatmapChartMonthFull';
import BarChartMonthRequest from './ReportCharts/BarChartMonthRequest';
import BarChartMonthWarning from './ReportCharts/BarChartMonthWarning';
import { CarServiceDashboardDto } from '../../../interface/carService';
import Button from '../../../components/atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import {
	useGetCarServiceReportsByReportQuery,
	useGetMyCarServiceReportsByReportQuery,
} from '../../../store/apis/carService';
import ReportDataTable from './ReportCharts/ActiveContent/ReportDataTable';

const ButtonContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
`;

const ButtonsControlContainer = styled.div`
	display: flex;
	gap: 8px;
`;

interface MyLatestDashboardDataProps {
	groupId?: string;
	selectedDate?: string;
	dashboardData: CarServiceDashboardDto | undefined;
}

type ContentReportMapping = {
	[key: string]: string;
};

// groupId는 '대쉬보드 관리'가 권한이 있는 경우에만 빈 string이 아닌 값으로 넘어옴
const ChartGroup = ({
	groupId,
	selectedDate,
	dashboardData,
}: MyLatestDashboardDataProps) => {
	// const [isSelectDate, setIsSelectDate] = useState<boolean>(false);

	const barMonthRequestData = dashboardData?.barChartMonthRequest || [];
	const pieChartTotalData = dashboardData?.pieChartTotal || [];
	const heatmapChartData = dashboardData?.heatmapChartMonth || [];
	const barMonthRedData = dashboardData?.barChartMonthRed || [];

	const [activeContent, setActiveContent] = useState<string>('');
	const handleRenderComp = (contentName: string) => {
		setActiveContent(contentName);
	};

	const contentReportMapping: ContentReportMapping = {
		진행중단: 'REQUEST_STOP',
		검사부적합: 'RESULT_NONPASS',
		검사제외: 'RESULT_EXCLUDE',
		일정임박: 'SCHEDULE_TIGHT',
		일정경과: 'SCHEDULE_MISS',
	};

	const { data: myReportData } = useGetMyCarServiceReportsByReportQuery(
		{
			dashboardStackDate: selectedDate as string, // 날짜 선택 ???
			report: contentReportMapping[activeContent],
		},
		{
			skip: !contentReportMapping[activeContent] || !selectedDate || !!groupId,
		},
	);

	const { data: reportDataByGroupId } = useGetCarServiceReportsByReportQuery(
		{
			groupId: groupId as string,
			dashboardStackDate: selectedDate as string,
			report: contentReportMapping[activeContent],
		},
		{ skip: !groupId || !contentReportMapping[activeContent] || !selectedDate },
	);

	const reportData = groupId ? reportDataByGroupId : myReportData;

	// 전체 대쉬보드 데이터에서 개별 리포트 데이터를 포함하고 있으므로 그것을 쓰되
	// 간혹 리스트를 빈 값으로 주는 경우에는 개별 리포트 데이터로 처리하도록 함
	const requestStopData = dashboardData?.inspectionRequestStopList?.length
		? dashboardData?.inspectionRequestStopList
		: reportData?.row.inspectionRequestStopList;
	const resultNonpassData = dashboardData?.inspectionResultNonpassList?.length
		? dashboardData?.inspectionResultNonpassList
		: reportData?.row.inspectionResultNonpassList;
	const resultExcludeData = dashboardData?.inspectionResultExcludeList?.length
		? dashboardData?.inspectionResultExcludeList
		: reportData?.row.inspectionResultExcludeList;
	const scheduleTightData = dashboardData?.inspectionScheduleTightList?.length
		? dashboardData?.inspectionScheduleTightList
		: reportData?.row.inspectionScheduleTightList;
	const scheduleMissData = dashboardData?.inspectionScheduleMissList?.length
		? dashboardData?.inspectionScheduleMissList
		: reportData?.row.inspectionScheduleMissList;

	const handleDataDownload = () => {
		// activeContent 따라 데이터 다운로드
		let rawData;
		switch (activeContent) {
			case '진행중단':
				rawData = requestStopData;
				break;
			case '검사부적합':
				rawData = resultNonpassData;
				break;
			case '검사제외':
				rawData = resultExcludeData;
				break;
			case '일정임박':
				rawData = scheduleTightData;
				break;
			case '일정경과':
				rawData = scheduleMissData;
				break;
			default:
				rawData = null;
		}

		const rows = rawData?.map((row) => ({
			검사접수일: row.proxyInspectionReceptionRegistrationDate,
			차량번호: row.carLicensePlateNumber,
			검사만료일: row.carInspectionRestrictedDate,
			검출유형: row.reasonTitle,
			중단취소사유: row.proxyInspectionReceptionCancellationReason,
			중단취소일: row.proxyInspectionReceptionCancellationDate,
			메모: row.proxyInspectionReceptionMemo,
		}));

		const worksheet = utils.json_to_sheet(rows as any[]);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, activeContent);

		const excelTableHead = [
			[
				'검사접수일',
				'차량번호',
				'검사만료일',
				'검출유형',
				'중단취소사유',
				'중단취소일',
				'메모',
			],
		];
		utils.sheet_add_aoa(worksheet, excelTableHead);

		// create XLSX file and save to warningData.xlsx
		writeFile(workbook, `${activeContent}.xlsx`);
	};

	// 버튼 리스트 클릭 시 해당 컴포넌트 렌더링
	const renderReportContent = () => {
		switch (activeContent) {
			case '진행중단':
				return dashboardData ? (
					<ReportDataTable
						dashboardStackDate={dashboardData.dashboardStackDate}
						dashboardStackTitle={dashboardData.dashboardStackTitle}
						listData={requestStopData}
					/>
				) : (
					'데이터 없음'
				);
			case '검사부적합':
				return dashboardData ? (
					<ReportDataTable
						dashboardStackDate={dashboardData.dashboardStackDate}
						dashboardStackTitle={dashboardData.dashboardStackTitle}
						listData={resultNonpassData}
					/>
				) : (
					'데이터 없음'
				);
			case '검사제외':
				return dashboardData ? (
					<ReportDataTable
						dashboardStackDate={dashboardData.dashboardStackDate}
						dashboardStackTitle={dashboardData.dashboardStackTitle}
						listData={resultExcludeData}
					/>
				) : (
					'데이터 없음'
				);
			case '일정임박':
				return dashboardData ? (
					<ReportDataTable
						dashboardStackDate={dashboardData.dashboardStackDate}
						dashboardStackTitle={dashboardData.dashboardStackTitle}
						listData={scheduleTightData}
					/>
				) : (
					'데이터 없음'
				);
			case '일정경과':
				return dashboardData ? (
					<ReportDataTable
						dashboardStackDate={dashboardData.dashboardStackDate}
						dashboardStackTitle={dashboardData.dashboardStackTitle}
						listData={scheduleMissData}
					/>
				) : (
					'데이터 없음'
				);
			default:
				return (
					<ContentBox borderRadius="10px">
						원하는 데이터를 클릭해주세요.
					</ContentBox>
				);
		}
	};

	useEffect(() => {
		setActiveContent('');
	}, [groupId]);

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col md={14}>
					<ContentBoxWithHeader
						title="접수 건수"
						$isCompact
						borderRadius="10px"
					>
						<BarChartMonthRequest barMonthRequestData={barMonthRequestData} />
					</ContentBoxWithHeader>
				</Col>
				<Col md={10}>
					<ContentBoxWithHeader
						title="누적 진행율"
						$isCompact
						borderRadius="10px"
					>
						<PieChartTotal pieChartTotalData={pieChartTotalData} />
					</ContentBoxWithHeader>
				</Col>
			</Row>
			<Row gutter={[12, 12]}>
				<Col md={14}>
					<ContentBoxWithHeader
						title="진행 상세"
						$isCompact
						borderRadius="10px"
					>
						<HeatmapChartMonthFull heatmapChartData={heatmapChartData} />
					</ContentBoxWithHeader>
				</Col>
				<Col md={10}>
					<ContentBoxWithHeader
						title="일정 관리"
						$isCompact
						borderRadius="10px"
					>
						<BarChartMonthWarning barMonthRedData={barMonthRedData} />
					</ContentBoxWithHeader>
				</Col>
			</Row>
			<Row>
				<Col md={24}>
					<ButtonContainer>
						<ButtonsControlContainer>
							<Button
								$buttonType={
									activeContent === '진행중단'
										? ButtonTypeType.PRIMARY
										: ButtonTypeType.GHOST
								}
								size="small"
								onClick={() => handleRenderComp('진행중단')}
							>
								진행중단
							</Button>
							<Button
								$buttonType={
									activeContent === '검사부적합'
										? ButtonTypeType.PRIMARY
										: ButtonTypeType.GHOST
								}
								size="small"
								onClick={() => handleRenderComp('검사부적합')}
							>
								검사부적합
							</Button>
							<Button
								$buttonType={
									activeContent === '검사제외'
										? ButtonTypeType.PRIMARY
										: ButtonTypeType.GHOST
								}
								size="small"
								onClick={() => handleRenderComp('검사제외')}
							>
								검사제외
							</Button>
							<Button
								$buttonType={
									activeContent === '일정임박'
										? ButtonTypeType.PRIMARY
										: ButtonTypeType.GHOST
								}
								size="small"
								onClick={() => handleRenderComp('일정임박')}
							>
								일정임박
							</Button>
							<Button
								$buttonType={
									activeContent === '일정경과'
										? ButtonTypeType.PRIMARY
										: ButtonTypeType.GHOST
								}
								size="small"
								onClick={() => handleRenderComp('일정경과')}
							>
								일정경과
							</Button>
						</ButtonsControlContainer>

						<div>
							{activeContent !== '' && (
								<Button
									size="small"
									$buttonType={ButtonTypeType.GREEN}
									onClick={handleDataDownload}
								>
									{activeContent} 엑셀 다운로드
								</Button>
							)}
						</div>
					</ButtonContainer>
					{renderReportContent()}
				</Col>
			</Row>
		</>
	);
};

export default ChartGroup;
