import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { MakeVehicleDto } from '../../../../interface/vehicle';
import VehicleTemplate from '../../../../components/templates/VehicleTemplate';
import { RootState } from '../../../../store';

const VehiclesCreate = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const companyId = user?.company?.id;

	const initialValues: MakeVehicleDto = {
		color: null,
		driverId: undefined,
		insuranceStartDate: dayjs().format('YYYY-MM-DD'),
		insuranceEndDate: dayjs().format('YYYY-MM-DD'),
		insuranceLossPct: null,
		insurancePremiums: null,
		legalInspectionDate: dayjs().format('YYYY-MM-DD'),
		legalInspectionEndDate: dayjs().format('YYYY-MM-DD'),
		registrationDate: dayjs().format('YYYY-MM-DD'),
		status: 'ACTIVE',
		transmission: '오토',
		vehicleNumber: undefined,
		vin: undefined,
		isDtgInstalled: false,
		isObdInstalled: false,
		lastInspectionDate: dayjs().format('YYYYMMDD'),
		manufacturingCompany: '현대',
		carLoadingBoxType: '하이탑',
		loadingCapacity: 1,
		vehicleCategory: undefined,
		vehicleName: undefined,
		vehicleRegistration: null,
		vehicleYear: undefined,
		factoryPrice: undefined,
		purchasePrice: undefined,
		purchaseDate: null,
		salePrice: undefined,
		saleDate: null,
		importDomestic: '국산',
		fuelType: '디젤',
		insurer: '화물공제조합',
		insuranceAge: '만 26세 이상',
		insuranceInPersonam: undefined,
		insuranceInRem: undefined,
		insuranceSelf: undefined,
		insuranceSelfVehicle: undefined,
		insuranceDriverType: '법인',
		insuranceIndemnification: undefined,
		departmentId: undefined,
		ownerCompanyId: companyId ?? undefined,
	};

	return <VehicleTemplate initialValues={initialValues} />;
};

export default VehiclesCreate;
