import dayjs, { Dayjs } from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';

const VehicleHistoryChangeKeys: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '변경일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: { value: string | Dayjs }) => {
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'memo',
				headerName: '메모',
			},
			options: {
				minWidth: ColSize.CW_LONG_TEXT,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'status',
				headerName: '사용유무',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: { value: string }) => {
					switch (value) {
						case 'ACTIVE':
							return '사용';
						case 'INACTIVE':
							return '미사용';
						default:
							return '-';
					}
				},
			},
		},
		{
			tableInfo: {
				field: 'administratorName',
				headerName: '관리자 이름',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'ownerCompanyName',
				headerName: '차량소유자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'departmentName',
				headerName: '차량소속',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vin',
				headerName: '차대번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleCategory',
				headerName: '차군',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'manufacturingCompany',
				headerName: '제조사',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleName',
				headerName: '차종명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},

		{
			tableInfo: {
				field: 'factoryPrice',
				headerName: '신차가(출고가)',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'purchasePrice',
				headerName: '취득가(매입가)',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'purchaseDate',
				headerName: '취득일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'salePrice',
				headerName: '매각가',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'saleDate',
				headerName: '매각일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'transmission',
				headerName: '미션',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'fuelType',
				headerName: '유종',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'color',
				headerName: '색상',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'loadingCapacity',
				headerName: '적재량',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carLoadingBoxType',
				headerName: '탑 형식',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleYear',
				headerName: '연식',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'legalInspectionDate',
				headerName: '최종검사일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return value ? dayjs(value).format('YYYY-MM-DD') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'legalInspectionEndDate',
				headerName: '검사종료일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return value ? dayjs(value).format('YYYY-MM-DD') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'importDomestic',
				headerName: '수입/국산',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'isDtgInstalled',
				headerName: 'DTG 설치여부',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: { value: boolean }) => {
					return value ? '설치' : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'lastInspectionDate',
				headerName: '마지막 점검일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: { value: string | null }) => {
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'insurer',
				headerName: '보험사',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceAge',
				headerName: '운전자연령',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insurancePremiums',
				headerName: '보험금',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceLossPct',
				headerName: '손해율',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceStartDate',
				headerName: '보험시작일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: { value: string | null }) => {
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'insuranceEndDate',
				headerName: '보험종료일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: { value: string | null }) => {
					if (!value) {
						return '-';
					}
					return dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'insuranceInPersonam',
				headerName: '대인배상',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceInRem',
				headerName: '대물배상',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceSelf',
				headerName: '자기신체사고',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'insuranceSelfVehicle',
				headerName: '자차손해',
			},
		},
		{
			tableInfo: {
				field: 'insuranceDriverType',
				headerName: '운전자범위',
			},
		},
		{
			tableInfo: {
				field: 'insuranceIndemnification',
				headerName: '면책제도',
			},
		},
	],
};

export default VehicleHistoryChangeKeys;
