import {
	BaseQueryFn,
	createApi,
	FetchArgs,
} from '@reduxjs/toolkit/query/react';

import {
	ResetPasswordAdministratorDto,
	ResetPasswordAdministratorErrorDto,
} from '../../../interface/passwordReset';
import { ObjectResponse } from '../@types';
import { publicBaseQuery } from '../query';

const resetPasswordApi = createApi({
	reducerPath: 'resetPasswordApi',
	baseQuery: publicBaseQuery as unknown as BaseQueryFn<
		string | FetchArgs,
		unknown,
		ResetPasswordAdministratorErrorDto
	>,
	endpoints: (builder) => ({
		resetPassword: builder.mutation<
			ObjectResponse<any>,
			ResetPasswordAdministratorDto
		>({
			query: ({
				authTokenId,
				accessToken,
				removable,
				administratorApplicationId,
				newPassword,
			}) => ({
				method: 'PUT',
				url: `/members/administrators/reset-password`,
				body: {
					authTokenId,
					accessToken,
					removable,
					administratorApplicationId,
					newPassword,
				},
			}),
		}),
	}),
});

export const { useResetPasswordMutation } = resetPasswordApi;
export default resetPasswordApi;
