import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { arrowUpIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';

interface AccordionTabProps {
	title: string;
	iconSrc: string;
	isOpenInit?: boolean;
}

const AccordionContainer = styled.div`
	overflow: hidden;
`;
const AccordionTabContainer = styled.div<{ $isOpen: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 9px 15px;

	.tab-icon {
		flex-shrink: 0;
		margin-right: 8px;
	}
	.tab-text {
		flex: 1;
		margin-right: 8px;
		font-weight: 500;
	}
	.arrow-icon {
		flex-shrink: 0;
		transition: 0.3s;
		transform: ${({ $isOpen }) =>
			$isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
	}
`;
const AccordionListContainer = styled.div`
	transition: 0.3s;
`;

function Accordion({
	children,
	title,
	iconSrc,
	isOpenInit = false,
}: PropsWithChildren<AccordionTabProps>) {
	const accordionListRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(isOpenInit);
	const [height, setHeight] = useState<string>('');

	const handleTabClick = () => {
		setIsOpen((prev) => !prev);
	};

	useEffect(() => {
		if (accordionListRef.current) {
			accordionListRef.current.style.height = 'auto';
			setHeight(`${accordionListRef.current.getBoundingClientRect().height}px`);
		}
	}, [height, title]);

	useEffect(() => {
		if (accordionListRef.current && height && isOpen) {
			accordionListRef.current.style.height = height;
		} else if (accordionListRef.current && !isOpen) {
			accordionListRef.current.style.height = '0px';
		}
	}, [isOpen, height]);

	return (
		<AccordionContainer>
			<AccordionTabContainer onClick={handleTabClick} $isOpen={isOpen}>
				<Icon className="tab-icon" width={20} height={20} src={iconSrc} />
				<Typo $typoType="h9" className="tab-text" color="gray_10">
					{title}
				</Typo>
				<Icon className="arrow-icon" width={20} height={20} src={arrowUpIcon} />
			</AccordionTabContainer>
			<AccordionListContainer ref={accordionListRef}>
				{children}
			</AccordionListContainer>
		</AccordionContainer>
	);
}

export default Accordion;
