import React, { ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NewTableValueType } from '../../../utils/table-util';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import theme, { ButtonTypeType } from '../../../styles/theme';
import {
	CANCEL_APPROVED_VALUE,
	CANCEL_ENDED_VALUE,
	CANCEL_STATE_BADGE_COLOR,
	CANCEL_STATE_OPTIONS,
	CUSTOMER_STATE_DEREGISTERED_VALUE,
	CUSTOMER_STATE_NORMAL_VALUE,
	CUSTOMER_STATE_OPTIONS,
	CUSTOMER_STATE_SUSPENSION_VALUE,
} from '../../../utils/const';
import Badge from '../../atoms/Badge';
import {
	companyRegisterNumberFormat,
	phoneFormat,
} from '../../../utils/data-format';
import { SubscriptionItemDto } from '../../../interface/subscriptionItem';
import {
	INSPECTION_AGENCY_PATH,
	REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
	TERMINATION_MANAGEMENT_PATH,
} from '../../../routes/constants/urls';
import { ToastContext } from '../../../contexts/Toast';
import {
	useGetSubscriptionsCancellationDetailQuery,
	useTerminationApprovedConfirmMutation,
	useTerminationCancelMutation,
	useTerminationConfirmMutation,
	useTerminationFeeMutation,
} from '../../../store/apis/termination';
import {
	CustomerDataDto,
	PenaltyDataDto,
	PurchaseDataDto,
	RequestedSubscriptionItem,
} from '../../../interface/termination';
import { popupUpdateHandler } from '../../../store/popup';
import { CustomErrorType } from '../../../store/apis/@types';
import Alert from '../../atoms/Alert';
import Typo from '../../atoms/Typo';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import Grid from '../../atoms/Grid';
import { createColList } from '../../../utils/grid-util';
import TerminationDetailKeys from '../../../pages/OpenSpannerManagement/TerminationManagement/TerminationDetail/gridData';
import { createBadgeTextCellRender } from '../../../utils/row-data-util';
import SetCancellationFeeModal from './SetCancellationFeeModal';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const GridContainer = styled.div`
	height: 336px;
`;

const FlexItem = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	img {
		object-fit: contain;
	}
`;

const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, NewTableValueType>;
	data: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);
	let providerValue = '';

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof T];

		const { suffix, label, span } = value;

		if (!tableContent) {
			return (
				<GridItem
					key={idx.toString()}
					title={label}
					size={GridTitleSize.GT_MEDIUM}
					span={span}
				>
					-
				</GridItem>
			);
		}

		switch (key) {
			case 'createdAt':
			case 'cancelApprovedAt':
			case 'cancelConfirmedAt':
			case 'startedAt':
			case 'endedAt':
			case 'purchasedAt':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent
							? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
							: '-'}
					</GridItem>
				);

			case 'emailReceivable':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent}
					</GridItem>
				);
			case 'pushReceivable':
			case 'messageReceivable':
			case 'customerStatus': {
				let color: keyof typeof theme.common.colors = 'gray_11';
				let viewValue = '-';

				switch (tableContent) {
					case CUSTOMER_STATE_NORMAL_VALUE:
						color = 'gray_11';
						viewValue = '활동중';
						break;
					case CUSTOMER_STATE_SUSPENSION_VALUE:
						color = 'gray_6';
						viewValue = '제한됨';
						break;
					case CUSTOMER_STATE_DEREGISTERED_VALUE:
						color = 'danger_5_main';
						viewValue = '탈퇴';
						break;
					default:
						break;
				}
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						<span style={{ color: theme.common.colors[color] }}>
							{viewValue}
						</span>
					</GridItem>
				);
			}
			case 'provider': {
				if (tableContent === 'google') {
					providerValue = '구글';
				}
				if (tableContent === 'password') {
					providerValue = '일반회원';
				}
				if (tableContent === 'kakao') {
					providerValue = '카카오';
				}
				if (tableContent === 'apple') {
					providerValue = '애플';
				}
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{providerValue}
					</GridItem>
				);
			}
			case 'vehicleRegistration':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						<Image width={32} height={32} src={tableContent as string} />
					</GridItem>
				);
			case 'totalRemainTasks':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
						$isAutoHeight
					>
						<ul
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 8,
								listStyle: 'initial',
								paddingLeft: 20,
							}}
						>
							{(tableContent as any[]).map((item: any, index) => (
								<li key={index.toString()}>
									{item.productName} {item.taskCount}회
								</li>
							))}
						</ul>
					</GridItem>
				);
			case 'totalTasks':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
						$isAutoHeight
					>
						<ul
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 8,
								listStyle: 'initial',
								paddingLeft: 20,
							}}
						>
							{(tableContent as any[]).map((item: any, index) => (
								<li key={index.toString()}>
									{item.productName} {item.totalCount}회
								</li>
							))}
						</ul>
					</GridItem>
				);
			case 'totalCount':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent}
						{suffix}
					</GridItem>
				);
			case 'monthlyPriceTax':
			case 'cancellationFee':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{!Number.isNaN(Number(tableContent))
							? `${Number(tableContent).toLocaleString()}${suffix}`
							: '-'}
					</GridItem>
				);
			case 'subscriptionCancelStatus':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						<Badge color={CANCEL_STATE_BADGE_COLOR[tableContent] || 'red'}>
							{CANCEL_STATE_OPTIONS.find((item) => item.value === tableContent)
								?.label || '-'}
						</Badge>
					</GridItem>
				);
			case 'representativePhoneNumber':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{phoneFormat(tableContent as string)}
					</GridItem>
				);
			case 'businessLicenseNumber':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{companyRegisterNumberFormat(tableContent as string)}
					</GridItem>
				);
			case 'customerPhone':
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{phoneFormat(tableContent as string)}
					</GridItem>
				);
			case 'subscriptionProductName':
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<FlexItem>
							<Image
								width={32}
								height={32}
								src={(data as any).subscriptionProductImage}
							/>
							<p>{tableContent as string}</p>
						</FlexItem>
					</GridItem>
				);
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={label}
						size={GridTitleSize.GT_MEDIUM}
						span={span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};

const penaltyInfoTableKey: Record<string, NewTableValueType> = {
	subscriptionCancelStatus: {
		key: 'subscriptionCancelStatus',
		label: '상태',
	},
	cancelApprovedAt: {
		key: 'cancelApprovedAt',
		label: '해지승인 일시',
	},
	cancelConfirmedAt: {
		key: 'cancelConfirmedAt',
		label: '해지완료 일시',
	},
	cancellationFee: {
		key: 'cancellationFee',
		label: '해지 위약금 금액',
		suffix: '원',
	},
	paymentMethod: {
		key: 'paymentMethod',
		label: '결제방법',
	},
	cancellationWord: {
		key: 'cancellationWord',
		label: '해지 안내 문구',
		span: 3,
	},
};

const companyInfoTableKey: Record<string, NewTableValueType> = {
	name: {
		key: 'name',
		label: '법인명',
	},
	representativePhoneNumber: {
		key: 'representativePhoneNumber',
		label: '대표 연락처',
	},
	businessLicenseNumber: {
		key: 'businessLicenseNumber',
		label: '사업자등록번호',
	},
	administratorApplicationId: {
		key: 'administratorApplicationId',
		label: '관리자 ID',
	},
};

const customerInfoTableKey: Record<string, NewTableValueType> = {
	createdAt: {
		key: 'createdAt',
		label: '가입일자',
	},
	customerName: {
		key: 'customerName',
		label: '고객명',
	},
	provider: {
		key: 'provider',
		label: '가입채널',
	},
	customerPhone: {
		key: 'customerPhone',
		label: '휴대폰 번호',
	},
	vehicleNumber: {
		key: 'vehicleNumber',
		label: '차량번호',
	},
	// emailReceivable: {
	// 	key: 'emailReceivable',
	// 	label: '이메일수신동의',

	// 	bulletOptions: [
	// 		{ key: true, value: '동의' },
	// 		{ key: false, value: '미동의' },
	// 	],
	// },
	// pushReceivable: {
	// 	key: 'pushReceivable',
	// 	label: '푸시수신동의',

	// 	bulletOptions: [
	// 		{ key: true, value: '동의' },
	// 		{ key: false, value: '미동의' },
	// 	],
	// },
	// messageReceivable: {
	// 	key: 'messageReceivable',
	// 	label: '메세지수신동의',

	// 	bulletOptions: [
	// 		{ key: true, value: '동의' },
	// 		{ key: false, value: '미동의' },
	// 	],
	// },
	customerStatus: {
		key: 'customerStatus',
		label: '회원상태',
		bulletOptions: CUSTOMER_STATE_OPTIONS.filter((item) => item.value).map(
			({ label, value }) => ({ key: value as string, value: label }),
		),
	},
	vehicleRegistration: {
		key: 'vehicleRegistration',
		label: '차량등록증',
		span: 3,
	},
};

const purchaseInfoTableKey: Record<string, NewTableValueType> = {
	purchasedAt: {
		key: 'purchasedAt',
		label: '구매 일자',
	},
	subscriptionProductName: {
		key: 'subscriptionProductName',
		label: '구독 상품명',
	},
	startedAt: {
		key: 'startedAt',
		label: '구독 시작일자',
	},
	endedAt: {
		key: 'endedAt',
		label: '구독 종료일자',
	},
	totalCount: {
		key: 'totalCount',
		label: '서비스 회차',
		suffix: '차',
	},
	monthlyPriceTax: {
		key: 'monthlyPriceTax',
		label: '월 단위 가격',
		suffix: '원',
	},

	addOptions: {
		key: 'addOptions',
		label: '추가 옵션',
		span: 2,
	},
	totalTasks: {
		key: 'totalTasks',
		label: '기본 제공 서비스',
		span: 2,
	},
	totalRemainTasks: {
		key: 'totalRemainTasks',
		label: '잔여 서비스',
		span: 2,
	},
};

// const backUrl = TERMINATION_MANAGEMENT_PATH;

const getTargetUrl = (item: SubscriptionItemDto) => {
	let baseUrl = REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH;

	switch (item.productType) {
		case 'REGULAR_INSPECTION':
			baseUrl = REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH;
			break;
		case 'INSPECTION_AGENCY':
			baseUrl = INSPECTION_AGENCY_PATH;
			break;
		default:
			break;
	}

	return `${baseUrl}?selectedDate=${dayjs(item.firstVisitedAt).format(
		'YYYY-MM-DD',
	)}&subscriptionItemNo=${item.subscriptionItemNo}`;
};

const CancellationDetail = () => {
	const toast = useContext(ToastContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { subscriptionNo } = useParams();

	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [isApprovedConfirmAlertOpen, setApprovedConfirmAlertOpen] =
		useState(false);
	const [isConfirmAlertOpen, setConfirmIsAlertOpen] = useState(false);
	const [isManagerAssignedAlertOpen, setIsManagerAssignedAlertOpen] =
		useState(false);

	const [isRequestedAlertOpen, setIsRequestedAlertOpen] = useState(false);

	const [visibleTerminationConfigModal, setVisibleTerminationConfigModal] =
		useState(false);

	const [terminationCancel] = useTerminationCancelMutation();
	const [terminationApprovedConfirm] = useTerminationApprovedConfirmMutation();
	const [terminationConfirm] = useTerminationConfirmMutation();

	const { data, refetch } = useGetSubscriptionsCancellationDetailQuery({
		subscriptionNo,
	});

	const penaltyData: PenaltyDataDto | undefined = data && {
		subscriptionCancelStatus: data.row.subscriptionCancelStatus,
		cancelConfirmedAt: data.row.cancelConfirmedAt,
		cancelApprovedAt: data.row.cancelApprovedAt,
		cancellationFee: data.row.cancellationFee,
		cancellationWord: data.row.cancellationWord,
		paymentMethod: data.row.paymentMethod,
	};

	const customerData: CustomerDataDto | undefined = data && {
		createdAt: data.row.customerCreatedAt,
		customerName: data.row.customerName,
		provider: data.row.provider,
		customerPhone: data.row.customerPhone,
		vehicleNumber: data.row.vehicleNumber,
		emailReceivable: data.row.marketingAgreement,
		pushReceivable: data.row.marketingAgreement,
		messageReceivable: data.row.marketingAgreement,
		customerStatus: data.row.customerStatus,
		vehicleRegistration: data.row.vehicleRegistration,
	};

	const purchaseData: PurchaseDataDto | undefined = data && {
		purchasedAt: data.row.purchasedAt,
		subscriptionProductName: data.row.subscriptionProductName,
		subscriptionProductImage: data.row.subscriptionProductImage,
		startedAt: data.row.startedAt,
		endedAt: data.row.endedAt,
		totalCount: data.row.currentCount,
		monthlyPriceTax: data.row.monthlyPriceTax,
		totalTasks: data.row.totalTasks,
		totalRemainTasks: data.row.totalRemainTasks,
		addOptions: '',
	};

	const companyData = data?.row.company;

	const subscriptionItemList: RequestedSubscriptionItem[] = data
		? data.row.requestedSubscriptionItems
		: [];

	const handleOpenTerminationConfigModal = () => {
		setVisibleTerminationConfigModal(true);
	};
	const handleCloseTerminationConfigModal = () => {
		setVisibleTerminationConfigModal(false);
	};

	const handleConfirmAlertOpen = () => {
		// 배정완료가 하나라도 있을경우
		if (
			subscriptionItemList.some(
				(item) => item.subscriptionItemStatus === 'ASSIGNED',
			)
		) {
			return setIsManagerAssignedAlertOpen(true);
		}

		// 작업요청이 하나라도 있을경우
		if (
			subscriptionItemList.some(
				(item) => item.subscriptionItemStatus === 'REQUESTED',
			)
		) {
			return setIsRequestedAlertOpen(true);
		}

		return setConfirmIsAlertOpen(true);
	};

	const handleConfirmAlertClose = () => {
		setConfirmIsAlertOpen(false);
	};

	const handleAlertOpen = () => {
		if (customerData?.customerStatus === CUSTOMER_STATE_DEREGISTERED_VALUE) {
			toast('error', '탈퇴한 회원은 해지신청 철회가 불가능합니다.');
		} else {
			setIsAlertOpen(true);
		}
	};

	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleApprovedConfirmAlertClose = () => {
		setApprovedConfirmAlertOpen(false);
	};

	const handleManagerAssignedAlertClose = () => {
		setIsManagerAssignedAlertOpen(false);
	};

	const handleRequestedAlertClose = () => {
		setIsRequestedAlertOpen(false);
	};

	const goBack = () => {
		navigate(-1);
	};

	const handleTerminationCancelClick = async () => {
		if (subscriptionNo) {
			const res = await terminationCancel({ subscriptionNo });

			if (!('error' in res)) {
				handleAlertClose();
				toast('info', '해지신청 철회가 완료되었습니다');
				navigate(TERMINATION_MANAGEMENT_PATH);
				dispatch(popupUpdateHandler());
			}
			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				handleAlertClose();
				toast('error', errorMessage);
			}
		}
	};

	const handleTerminationApprovedConfirmClick = async () => {
		if (subscriptionNo) {
			const res = await terminationApprovedConfirm({ subscriptionNo });

			if (!('error' in res)) {
				handleAlertClose();
				toast('info', '위약금 확인처리가 완료되었습니다');
				navigate(TERMINATION_MANAGEMENT_PATH);
				dispatch(popupUpdateHandler());
			}
			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				handleApprovedConfirmAlertClose();
				toast('error', errorMessage);
			}
		}
	};

	const handleTerminationConfirmClick = async () => {
		if (penaltyData && !penaltyData.cancellationWord) {
			toast('error', '해지환불 설정을 입력해주세요');
			return;
		}

		if (subscriptionNo) {
			const res = await terminationConfirm({ subscriptionNo });

			if (!('error' in res)) {
				handleConfirmAlertClose();
				handleRequestedAlertClose();
				toast('info', '해지신청이 완료되었습니다');
				refetch();
				dispatch(popupUpdateHandler());
			}

			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				handleConfirmAlertClose();
				handleRequestedAlertClose();
				toast('error', errorMessage);
			}
		}
	};

	const handleConfigClick = () => {
		handleOpenTerminationConfigModal();
	};

	const handleDetailClick = (value: any) => {
		if (!value) return;
		navigate(getTargetUrl(value));
	};

	const renderAlertOpen = () => {
		return (
			<Alert
				title="해지신청 철회"
				closeButtonClick={handleAlertClose}
				onConfirmButtonClick={handleTerminationCancelClick}
				onConfirmButtonText="철회하기"
				isVisible={isAlertOpen}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						해지신청을 철회하시겠습니까?
					</Typo>
				</p>
			</Alert>
		);
	};

	const renderApprovedConfirmAlertModal = () => {
		return (
			<Alert
				title="해지위약금 확인"
				closeButtonClick={handleApprovedConfirmAlertClose}
				onConfirmButtonClick={handleTerminationApprovedConfirmClick}
				onConfirmButtonText="위약금 확인"
				isVisible={isApprovedConfirmAlertOpen}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						위약금 결제가 완료된 것으로 변경합니다. 해지위약금을
						확인하시겠습니까?
					</Typo>
				</p>
			</Alert>
		);
	};

	const renderConfirmAlertModal = () => {
		return (
			<Alert
				title="해지신청 처리"
				closeButtonClick={handleConfirmAlertClose}
				onConfirmButtonClick={handleTerminationConfirmClick}
				onConfirmButtonText="확인"
				isVisible={isConfirmAlertOpen}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						해지신청을 하시겠습니까?
					</Typo>
				</p>
			</Alert>
		);
	};

	const renderRequestedAlertModal = () => {
		return (
			<Alert
				title="작업 요청중인 작업이 있습니다."
				closeButtonClick={handleRequestedAlertClose}
				onConfirmButtonClick={handleTerminationConfirmClick}
				onConfirmButtonText="그래도 해지신청 처리"
				isVisible={isRequestedAlertOpen}
				closeButtonText={undefined}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						해지신청을 진행하면 모두 취소되고 해지도 완료 됩니다. 그래도
						해지완료 처리를 할까요?
					</Typo>
				</p>
			</Alert>
		);
	};

	const renderManagerAssignedAlertModal = () => {
		return (
			<Alert
				title="매니저가 작업중인 작업이 있습니다."
				closeButtonClick={handleManagerAssignedAlertClose}
				onConfirmButtonClick={handleManagerAssignedAlertClose}
				onConfirmButtonText="확인"
				isVisible={isManagerAssignedAlertOpen}
				closeButtonText={undefined}
			>
				<p>
					<Typo $typoType="b5" color="gray_8">
						아직 작업중인 작업이 있습니다. 매니저 배정을 취소한 다음 해지신청
						완료를 진행해 주세요.
					</Typo>
				</p>
			</Alert>
		);
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="해지 상세"
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={handleAlertOpen}
							size="small"
						>
							해지신청 철회
						</Button>
						{penaltyData?.subscriptionCancelStatus !== CANCEL_ENDED_VALUE ? (
							<Button
								$buttonType={ButtonTypeType.PRIMARY}
								onClick={handleConfirmAlertOpen}
								size="small"
							>
								해지신청 처리
							</Button>
						) : null}
					</ButtonContainer>
				}
				onBack={goBack}
			>
				<ContentBoxWithHeader
					title="해지 위약금 정보"
					borderRadius="10px"
					className="inner-content"
					rightAccessory={
						<ButtonContainer>
							<Button
								$buttonType={ButtonTypeType.GHOST_BLACK}
								onClick={() => setApprovedConfirmAlertOpen(true)}
								size="small"
								disabled={
									penaltyData?.subscriptionCancelStatus !==
									CANCEL_APPROVED_VALUE
								}
							>
								위약금 확인처리
							</Button>
							<Button
								onClick={handleConfigClick}
								size="small"
								disabled={
									penaltyData?.subscriptionCancelStatus === CANCEL_ENDED_VALUE
								}
							>
								해지 환불 설정
							</Button>
						</ButtonContainer>
					}
				>
					<GridLayout>
						{penaltyData &&
							renderTable({
								tableKeys: penaltyInfoTableKey,
								data: penaltyData as any,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
				{companyData && (
					<ContentBoxWithHeader
						title="법인정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{renderTable({
								tableKeys: companyInfoTableKey,
								data: companyData as any,
							})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
				{customerData?.customerName && (
					<ContentBoxWithHeader
						title="회원정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{customerData &&
								renderTable({
									tableKeys: customerInfoTableKey,
									data: customerData as any,
								})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
				<ContentBoxWithHeader
					title="진행서비스"
					borderRadius="10px"
					className="inner-content"
				>
					<GridContainer>
						<Grid
							cols={createColList(TerminationDetailKeys.colsList)}
							rowData={subscriptionItemList}
							onRowScheduleClicked={handleDetailClick}
							frameworkComponents={{
								...createBadgeTextCellRender([
									{
										label: '배정완료',
										value: 'ASSIGNED',
										color: 'blue',
									},
									{
										label: '작업완료',
										value: 'COMPLETED',
										color: 'gray',
									},
									{
										label: '작업요청',
										value: 'REQUESTED',
										color: 'red',
									},
								]),
							}}
						/>
					</GridContainer>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="구매정보"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout $columnCount={2}>
						{purchaseData &&
							renderTable({
								tableKeys: purchaseInfoTableKey,
								data: purchaseData as any,
							})}
					</GridLayout>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			<SetCancellationFeeModal
				id={subscriptionNo || ''}
				defaultValue={{ cancellationWord: '', cancellationFee: 0 }}
				isVisible={visibleTerminationConfigModal}
				onClose={handleCloseTerminationConfigModal}
				onRefetch={refetch}
				useMutation={useTerminationFeeMutation}
			/>

			{/* 해지신청 철회 Alert */}
			{isAlertOpen && renderAlertOpen()}

			{/* 위약금 완료 처리 Alert */}
			{isApprovedConfirmAlertOpen && renderApprovedConfirmAlertModal()}

			{/* 해지신청 처리 Alert */}
			{isConfirmAlertOpen && renderConfirmAlertModal()}

			{/* 매니저 배정완료 있을시 Alert */}
			{isManagerAssignedAlertOpen && renderManagerAssignedAlertModal()}

			{/* 작업요청이 있을시 Alert */}
			{isRequestedAlertOpen && renderRequestedAlertModal()}
		</>
	);
};

export default CancellationDetail;
