import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Alert from '../../../components/atoms/Alert';

import Button from '../../../components/atoms/Button';
import ContentBoxItem from '../../../components/atoms/ContentBoxItem';
import InputAlert from '../../../components/atoms/InputAlert';
import Label from '../../../components/atoms/Label';
import Select from '../../../components/atoms/Select';
import TextArea from '../../../components/atoms/TextArea';
import Typo from '../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	getSubscriptionNoticeByOne,
	useUpdateSubscriptionNoticeMutation,
} from '../../../store/apis/subscriptionNotice';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { LabelValue } from '../../../utils/const';
import CONTRACT_MONTH from './selectData';

const initialValues = {
	minimumSubscriptionMonthPeriod: 0,
	agreementInformation: '',
	terminationNotice: '',
	serviceAddingNotice: '',
	tireReplacementNotice: '',
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const CustomContentBoxItem = styled(ContentBoxItem)`
	margin-top: 0;
`;

function RulesManagementPage() {
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [id, setId] = useState<number | null>(null);

	const [updateSubscriptionNotice] = useUpdateSubscriptionNoticeMutation();

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.minimumSubscriptionMonthPeriod === 0) {
			errors.minimumSubscriptionMonthPeriod =
				'약정 개월 수 선택를 선택해주세요.';
		}
		if (values.agreementInformation === '') {
			errors.agreementInformation = '약정정보 안내 문구를 입력해주세요';
		}
		if (values.terminationNotice === '') {
			errors.terminationNotice = '환불규정 안내 문구를 입력해주세요.';
		}
		if (values.serviceAddingNotice === '') {
			errors.serviceAddingNotice =
				'서비스 추가 시 유의사항 문구를 입력해주세요.';
		}
		if (values.tireReplacementNotice === '') {
			errors.tireReplacementNotice =
				'타이어 교환 시 유의사항 문구를 입력해주세요.';
		}

		return errors;
	};

	const {
		setValues,
		handleSubmit,
		errors,
		values: formikValues,
		setFieldValue,
		validateForm,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (values) => {
			if (!id) {
				return;
			}

			const res = await updateSubscriptionNotice({ id, body: values });

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				setToast('info', '약정/해지 규정 저장이 완료되었습니다.');
				setIsAlertOpen(false);
			}
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertOpenClick = async () => {
		const res = await validateForm();

		if (!Object.keys(res).length) {
			setIsAlertOpen(true);
		}
	};

	const handleAlertCloseClick = () => {
		setIsAlertOpen(false);
	};

	const getData = useCallback(async () => {
		dispatch(fullLoadingOn());
		try {
			const res = await getSubscriptionNoticeByOne();

			setValues({
				minimumSubscriptionMonthPeriod: res.minimumSubscriptionMonthPeriod,
				agreementInformation: res.agreementInformation,
				terminationNotice: res.terminationNotice,
				serviceAddingNotice: res.serviceAddingNotice,
				tireReplacementNotice: res.tireReplacementNotice,
			});

			setId(res.id);
		} catch (e: any) {
			setToast(
				'error',
				(e.response as CustomErrorType).data.translate ||
					(e.response as CustomErrorType).data.message,
			);
		} finally {
			dispatch(fullLoadingOff());
		}
	}, [dispatch, setValues, setToast]);

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="약정/해지 규정 관리"
				rightAccessory={
					<ButtonContainer>
						<Button onClick={handleAlertOpenClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="구독상품 약정 규정"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBoxItem>
						<Label name="subscriptionMonthPeriod" essential>
							<span>
								<Typo $typoType="label2">약정 개월 수</Typo>
							</span>
						</Label>
						<Select
							placeholder="약정 개월 수 선택"
							value={formikValues.minimumSubscriptionMonthPeriod}
							onChange={(value: string) =>
								setFieldValue('minimumSubscriptionMonthPeriod', value)
							}
						>
							{CONTRACT_MONTH.filter((item: LabelValue) => item.value).map(
								(option, idx) => (
									<Select.Option value={option.value} key={idx.toString()}>
										{option.label}
									</Select.Option>
								),
							)}
						</Select>
						{errors.minimumSubscriptionMonthPeriod && (
							<InputAlert $inputStatus="error">
								{errors.minimumSubscriptionMonthPeriod}
							</InputAlert>
						)}
					</ContentBoxItem>
					<ContentBoxItem>
						<Label name="agreementInformation" essential>
							<span>
								<Typo $typoType="label2">약정정보 안내 문구</Typo>
							</span>
						</Label>
						<TextArea
							style={{
								minHeight: '240px',
							}}
							placeholder="규정 내용을 작성해 주세요"
							value={formikValues.agreementInformation}
							defaultValue={formikValues.agreementInformation}
							onChange={(e) =>
								setFieldValue('agreementInformation', e.target.value)
							}
						/>
						{errors.agreementInformation && (
							<InputAlert $inputStatus="error">
								{errors.agreementInformation}
							</InputAlert>
						)}
					</ContentBoxItem>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="구독상품 해지 시 유의사항"
					borderRadius="10px"
					className="inner-content"
				>
					<CustomContentBoxItem>
						<Label name="terminationNotice" essential>
							<span>
								<Typo $typoType="label2">구독상품 해지 시 유의사항</Typo>
							</span>
						</Label>
						<TextArea
							style={{
								minHeight: '240px',
							}}
							placeholder="규정 내용을 작성해 주세요"
							value={formikValues.terminationNotice}
							defaultValue={formikValues.terminationNotice}
							onChange={(e) =>
								setFieldValue('terminationNotice', e.target.value)
							}
						/>
						{errors.terminationNotice && (
							<InputAlert $inputStatus="error">
								{errors.terminationNotice}
							</InputAlert>
						)}
					</CustomContentBoxItem>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="서비스 추가 시 유의사항"
					borderRadius="10px"
					className="inner-content"
				>
					<CustomContentBoxItem>
						<Label name="serviceAddingNotice" essential>
							<span>
								<Typo $typoType="label2">서비스 추가 시 유의사항</Typo>
							</span>
						</Label>
						<TextArea
							style={{
								minHeight: '240px',
							}}
							placeholder="규정 내용을 작성해 주세요"
							value={formikValues.serviceAddingNotice}
							defaultValue={formikValues.serviceAddingNotice}
							onChange={(e) =>
								setFieldValue('serviceAddingNotice', e.target.value)
							}
						/>
						{errors.serviceAddingNotice && (
							<InputAlert $inputStatus="error">
								{errors.serviceAddingNotice}
							</InputAlert>
						)}
					</CustomContentBoxItem>
				</ContentBoxWithHeader>
				<ContentBoxWithHeader
					title="타이어 교환 시 유의사항"
					borderRadius="10px"
					className="inner-content"
				>
					<CustomContentBoxItem>
						<Label name="tireReplacementNotice" essential>
							<span>
								<Typo $typoType="label2">타이어 교환 시 유의사항</Typo>
							</span>
						</Label>
						<TextArea
							style={{
								minHeight: '240px',
							}}
							placeholder="규정 내용을 작성해 주세요"
							value={formikValues.tireReplacementNotice}
							defaultValue={formikValues.tireReplacementNotice}
							onChange={(e) =>
								setFieldValue('tireReplacementNotice', e.target.value)
							}
						/>
						{errors.tireReplacementNotice && (
							<InputAlert $inputStatus="error">
								{errors.tireReplacementNotice}
							</InputAlert>
						)}
					</CustomContentBoxItem>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{isAlertOpen && (
				<Alert
					title="약정/해지 규정 관리"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={() => {
						handleSubmit();
					}}
					isVisible={isAlertOpen}
				>
					약정/해지 규정을 저장하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default RulesManagementPage;
