import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useGetOutsourcingCarServiceDetailQuery,
	useUpdateOutSourcingCarServiceDetailMutation,
} from '../../store/apis/carService';
import { CarServiceDto } from '../../interface/carService';
import CarServiceDetailTemplate from '../../components/templates/CarServiceTemplate/CarServiceDetailTemplate';

const CarServiceOutsourcingDetail = () => {
	const { inspectionId } = useParams();
	const [openCallHistoryTabPane, setOpenCallHistoryTabPane] = useState(true);

	const onCloseCallHistoryTabPane = () => {
		setOpenCallHistoryTabPane(false);
	};

	const handleCallHistoryTab = () => {
		setOpenCallHistoryTabPane(!openCallHistoryTabPane);
	};

	const { isLoading, data, refetch } = useGetOutsourcingCarServiceDetailQuery({
		inspectionId,
	});

	const initialValues: Partial<CarServiceDto> | undefined = data?.row;

	const [updateCarServiceDetail, { isLoading: isUpdating }] =
		useUpdateOutSourcingCarServiceDetailMutation();

	return (
		<CarServiceDetailTemplate
			inspectionId={inspectionId}
			callHistoryTabPane={{
				toUse: true,
				open: openCallHistoryTabPane,
				handleTabPane: handleCallHistoryTab,
				onCloseTab: onCloseCallHistoryTabPane,
			}}
			settlementTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			inspectionPaymentTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			inspectionPaymentStatusTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			transferHistoryTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			billingTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			initialValues={initialValues}
			updateCarServiceDetail={updateCarServiceDetail}
			refetch={refetch}
			isLoading={isLoading}
			isUpdating={isUpdating}
		/>
	);
};

export default CarServiceOutsourcingDetail;
