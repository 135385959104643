import React, { PropsWithChildren, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';
import Typo from '../../atoms/Typo';

interface TabPaneBoxProps {
	title: string;
	rightAccessary?: ReactNode;
	style?: CSSObject;
}

const Container = styled.div<{ $hasRightAccessary: boolean }>`
	display: flex;
	flex-direction: column;
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
	padding: 20px;
	border-radius: 8px;

	header {
		display: flex;
		align-items: center;
		padding-bottom: ${({ $hasRightAccessary }) =>
			$hasRightAccessary ? 12 : 20}px;

		div.right-accessary {
			margin-left: auto;
		}
	}
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

function TabPaneBox({
	children,
	title,
	rightAccessary,
	style,
}: PropsWithChildren<TabPaneBoxProps>) {
	return (
		<Container $hasRightAccessary={!!rightAccessary} style={style}>
			<header>
				<Typo $typoType="h4">{title}</Typo>

				{rightAccessary && (
					<div className="right-accessary">{rightAccessary}</div>
				)}
			</header>

			<ItemsContainer>{children}</ItemsContainer>
		</Container>
	);
}

export default TabPaneBox;
