import {
	FindManagerOrderedByDistanceResponseDto,
	ManagerDto,
} from '../../../interface/manager';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const managerApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getManagers: builder.query<ListResponse<ManagerDto>, Record<any, any>>({
			query: (args) => {
				return {
					url: `/members/managers`,
					params: args,
				};
			},
		}),
		getManagerDetail: builder.query<
			ObjectResponse<ManagerDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => {
				return {
					url: `/members/managers/${id}`,
				};
			},
		}),
		updateManagerState: builder.mutation<
			ObjectResponse<{ updateResult: boolean }>,
			{
				id: string;
				body: { managerStatus: string; statusChangedReason: string };
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/managers/${id}/status`,
				body,
			}),
		}),
		updateManagerAccountState: builder.mutation<
			ObjectResponse<{ updateResult: boolean }>,
			{
				id: string;
				body: { isLock: boolean };
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/managers/${id}/lock`,
				body,
			}),
		}),
		updateManagerProfile: builder.mutation<
			any,
			{ id: string; body: { profileMedia: string } }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/managers/${id}/image`,
				body,
			}),
		}),
		deleteManager: builder.mutation<ObjectResponse<ManagerDto>, string>({
			query: (id) => ({
				method: 'DELETE',
				url: `/members/managers/${id}`,
			}),
		}),
	}),
});

export const {
	useGetManagersQuery,
	useGetManagerDetailQuery,
	useUpdateManagerStateMutation,
	useUpdateManagerAccountStateMutation,
	useUpdateManagerProfileMutation,
	useDeleteManagerMutation,
} = managerApiSlice;

export const getManagerDetailViaId = async (id: string) => {
	const url = `/members/managers/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<ManagerDto>>(url);

	return data.row;
};

const excludeDepartmentList = ['타이어픽'];

export const getManagerListViaNearest = async (params: {
	lat: number;
	lng: number;
	page: number;
	pageSize: number;
}) => {
	const url = `/members/managers/near`;

	const { data } = await axiosInstance.get<
		ListResponse<FindManagerOrderedByDistanceResponseDto>
	>(url, {
		params,
	});

	const includeData = data.rows.filter(
		(item) => !excludeDepartmentList.includes(item.managerDepartment),
	);

	return {
		rows: includeData,
		count: includeData.length,
	};
};
