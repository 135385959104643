import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import {
	CELL_TYPE_BADGE_TEXT,
	CELL_TYPE_DETAIL_ACTION,
} from '../../../utils/row-data-util';
import { FilterElementType } from '../../../utils/filter-util';
import { CANCEL_STATE_OPTIONS } from '../../../utils/const';
import { phoneFormat } from '../../../utils/data-format';

const vehicleSubscriptionData: GridDataModel = {
	formInitialValues: { subscriptionType: 'SUBSCRIPTION_VEHICLE' },
	colsList: [
		{
			tableInfo: {
				field: 'cancelRequestedAt',
				headerName: '해지신청일',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'cancelApprovedAt',
				headerName: '해지승인일',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value ? dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'cancelConfirmedAt',
				headerName: '해지완료일',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value ? dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'cancelStatus',
				headerName: '해지상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_BADGE_TEXT,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionNo',
				headerName: '구독신청번호',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
					return value?.split('_')[0];
				},
			},
		},
		{
			tableInfo: {
				field: 'subscriptionNo',
				headerName: '계약차량순서',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
					return Number(value?.split('_')[1]) + 1;
				},
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '대표연락처',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'subscriptionProductName',
				headerName: '구독상품',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '상세보기',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'startedAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'customerName',
			label: '법인명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'subscriptionCancelStatus',
			label: '상태',
			type: FilterElementType.SELECT,
			options: CANCEL_STATE_OPTIONS,
		},
		{
			key: 'customerPhone',
			label: '대표연락처',
			type: FilterElementType.INPUT,
		},
		{
			key: 'vehicleNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
	],
};

export default vehicleSubscriptionData;
