import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import dayjs from 'dayjs';
import Badge from '../../../../components/atoms/Badge';
import { renderCustomerStatusCell } from '../../../UserManagement/CustomerManagement/gridData/gridData';
import { phoneFormat } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';

export const renderSubscriptionStatusCell = ({
	value,
}: ICellRendererParams) => {
	switch (value) {
		case 'PENDING':
			return <Badge color="red">결제대기중</Badge>;
		case 'GOING':
			return <Badge color="blue">구독중</Badge>;
		case 'QUIT':
			return <Badge color="yellow">중도 해지</Badge>;
		default:
			return <Badge color="gray">구독종료</Badge>;
	}
};

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'startedAt',
				headerName: '구독시작일자',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value && dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '기사명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionProductName',
				headerName: '구독 상품명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '휴대폰 번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerStatus',
				headerName: '기사상태',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				cellRenderer: renderCustomerStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionStatus',
				headerName: '구독상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderSubscriptionStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '구매일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'customerName',
			label: '고객명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'subscriptionProductName',
			label: '구독 상품명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerPhone',
			label: '휴대폰 번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'vehicleNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
