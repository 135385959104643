import React, { useCallback, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	FirstDataRenderedEvent,
	// RowNode,
	RowSelectedEvent,
} from 'ag-grid-community';
import { AxiosError } from 'axios';
import Modal from '../../../../components/atoms/Modal';
import Button from '../../../../components/atoms/Button';
import { ButtonTypeType } from '../../../../styles/theme';
import Typo from '../../../../components/atoms/Typo';
import Alert from '../../../../components/atoms/Alert';
import { ToastContext } from '../../../../contexts/Toast';
import { createOptionsTextCellRender } from '../../../../utils/row-data-util';
import { modalColumn } from './gridData';
import GridFilterForm from '../../../../components/organisms/GridFilterForm';
import {
	FilterElementType,
	FilterFormInfo,
} from '../../../../utils/filter-util';

import SelectionGrid from '../../../../components/atoms/SelectionGrid';
import { getProductModalList } from '../../../../store/apis/product';
import { GenerateProductDto } from '../../../../interface/product';
import { ProductIdServiceCount } from '../SubscribeProductCreate';
import { ProductType } from '../../../../interface/subscriptionProducts';
import {
	INSPECTION_AGENCY,
	REGULAR_INSPECTION,
	TIRE_REPLACEMENT,
} from '../../../../utils/const';

export interface ProductIdAndAmount extends GenerateProductDto {
	taskCount?: number;
	limitationCount?: number;
}

const searchInput: FilterFormInfo[] = [
	{
		key: 'productName',
		type: FilterElementType.INPUT,
		label: '상품명',
		placeholder: '입력',
	},
];

interface EditModalProps {
	isVisible: boolean;
	onClose: () => void;
	onChange: (event: any) => void;
	type: ProductType;
	selectedList: ProductIdServiceCount[];
}

const StyledModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
`;
const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;
const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;
const ModalContentContainer = styled.div`
	padding: 32px 32px 70px 32px;
	.modal-subTitle {
		margin-bottom: 12px;
	}
	.row-total-count-container {
		margin-bottom: 15px;
	}
	.row-total-count {
		color: ${({ theme }) => theme.common.colors.primary_6_main};
	}
`;
const SelectionGridWrapper = styled.div`
	height: 384px;
`;

function EditModal({
	isVisible,
	onClose,
	onChange,
	type,
	selectedList,
}: EditModalProps) {
	const toast = useContext(ToastContext);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [productList, setProductList] = useState<ProductIdAndAmount[]>([]);
	const [selectItem, setSelectItem] = useState<GenerateProductDto[]>([]);
	const [search, setSearch] = useState<{ productName: string }>({
		productName: '',
	});

	const handleModalClose = () => {
		onClose();
	};
	const handleAlertOpen = () => {
		setIsAlertOpen(true);
	};
	const handleAlertClose = () => {
		setIsAlertOpen(false);
	};

	const handleSubmit = async () => {
		handleAlertClose();

		if (
			!selectItem.every(
				(item: ProductIdAndAmount) =>
					Number(item.limitationCount) || Number(item.taskCount),
			)
		) {
			return toast('error', '제공 횟수, 추가선택 가능횟수를 입력해주세요');
		}

		if (
			!selectItem.every(
				(item: ProductIdAndAmount) =>
					!Number.isNaN(Number(item.limitationCount)) &&
					String(item.limitationCount) !== '',
			)
		) {
			return toast('error', '추가선택 가능횟수를 입력해주세요');
		}

		if (
			!selectItem.every(
				(item: ProductIdAndAmount) =>
					!Number.isNaN(Number(item.taskCount)) &&
					String(item.taskCount) !== '',
			)
		) {
			return toast('error', '제공 횟수를 입력해주세요');
		}

		handleModalClose();
		return onChange(selectItem);
	};

	const onRowSelected = (params: RowSelectedEvent) => {
		const selectedRows = params.api.getSelectedRows();

		return setSelectItem(selectedRows);
	};

	const getGridData = useCallback(async () => {
		try {
			const result = await getProductModalList(type);
			const amount = result.map((item: ProductIdAndAmount) => ({
				...item,
				taskCount: undefined,
				limitationCount: undefined,
			}));

			if (search.productName) {
				const filter = result.filter((item) =>
					item.productName.includes(search.productName),
				);
				setProductList(filter);
			} else {
				setProductList(amount);
			}
		} catch (e) {
			const err = e as AxiosError<{ message: string; translate: string }>;
			if (err.response)
				toast(
					'error',
					err.response.data.translate || err.response.data.message,
				);
		}
	}, [search.productName, toast, type]);

	const onFirstDataRendered = useCallback(
		(e: FirstDataRenderedEvent) => {
			e.api.forEachNode((node: any) => {
				const idList = selectedList.map((item) => item);
				const selectedTarget = idList.find((item) => item.id === node.data.id);

				node.setData({
					...node.data,
					taskCount: selectedTarget?.taskCount,
					limitationCount: selectedTarget?.limitationCount,
				});
				node.setSelected(!!node.data && node.data.id === selectedTarget?.id);
			});
		},
		[selectedList],
	);

	useEffect(() => {
		getGridData();
	}, [getGridData]);

	const renderContent = () => {
		return (
			<ModalContentContainer>
				<h1 className="modal-subTitle">
					<Typo $typoType="h4">상품 조회</Typo>
				</h1>
				<GridFilterForm
					forms={searchInput}
					useQuerySave={false}
					onSearchSubmit={(values) => {
						setSearch(values);
					}}
					$columnCount={1}
				/>
				<p className="row-total-count-container">
					<Typo $typoType="label2">
						<span className="row-total-count">
							<Typo $typoType="h9" color="primary_6_main">
								{productList.length}개{' '}
							</Typo>
						</span>
						상품이 조회되었습니다
					</Typo>
				</p>

				<SelectionGridWrapper>
					<SelectionGrid
						type="checkbox"
						rowData={productList}
						column={modalColumn.colsList}
						onRowSelected={onRowSelected}
						stopEditingWhenCellsLoseFocus
						onFirstDataRendered={onFirstDataRendered}
						frameworkComponents={{
							...createOptionsTextCellRender([
								{
									label: '순회정비',
									value: REGULAR_INSPECTION,
									color: 'black',
								},
								{
									label: '타이어 교체',
									value: TIRE_REPLACEMENT,
									color: 'black',
								},
								{ label: '검사대행', value: INSPECTION_AGENCY, color: 'black' },
							]),
						}}
					/>
				</SelectionGridWrapper>
			</ModalContentContainer>
		);
	};

	return (
		<>
			<StyledModal
				title={<ModalTitle>개별 상품 불러오기</ModalTitle>}
				open={isVisible}
				onCancel={handleModalClose}
				width={980}
				destroyOnClose
				noContentPadding
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button
							disabled={selectItem.length === 0}
							onClick={handleAlertOpen}
						>
							연관상품 등록/수정
						</Button>
					</ModalFooterWrapper>
				}
			>
				{renderContent()}
			</StyledModal>

			{isAlertOpen ? (
				<Alert
					title="개별 상품 등록"
					closeButtonClick={handleAlertClose}
					onConfirmButtonClick={handleSubmit}
					onConfirmButtonText="등록하기"
					isVisible={isAlertOpen}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							선택된 개별 상품을 등록하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default EditModal;
