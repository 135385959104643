import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	PLATES_CREATE_PATH,
	PLATES_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import vehiclePlateData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetVehiclePlatesQuery } from '../../../../store/apis/vehiclePlate';
import { createDetailAction } from '../../../../utils/row-data-util';
import Button from '../../../../components/atoms/Button';
import useCheckRole from '../../../../hooks/useCheckRole';
import { ToastContext } from '../../../../contexts/Toast';
import { axiosInstance } from '../../../../network/axios';
import { blobFileDownload } from '../../../../utils/file-util';
import { ButtonTypeType } from '../../../../styles/theme';
import useCompaniesOption from '../../../../hooks/useCompaniesOption';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function VehiclePlatesPage() {
	const navigate = useNavigate();
	const companiesOption = useCompaniesOption();
	const { formInitialValues, colsList, forms } =
		vehiclePlateData(companiesOption);

	const isSuper = useCheckRole({
		roleType: 'isRead',
		roleName: '번호판관리',
		includeSuper: true,
	});

	const isCreateVehiclePlate = useCheckRole({
		roleType: 'isCreate',
		roleName: '번호판관리',
	});

	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);

	const downloadFile = async () => {
		setIsLoading(true);
		await axiosInstance
			.get(`/members/vehicle-plates/excel`, {
				responseType: 'blob',
			})
			.then((response) => response.data)
			.then((blob) => {
				const fileName = `오픈트럭 번호판 현황.xlsx`;
				blobFileDownload(blob, fileName);
			})
			.catch((err) => {
				if (err.response && err.response.status !== 404) {
					toast('error', '요청을 처리할 수 없습니다.');
				}
				if (err.response && err.response.status === 404) {
					toast('error', '데이터가 없습니다.');
				}
			})
			.finally(() => setIsLoading(false));
	};

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetVehiclePlatesQuery,
		detailPath: PLATES_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const handleCreateClick = () => {
		navigate(PLATES_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="번호판 관리"
			gridTitle="번호판 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'vehicleNumber'),
			}}
			rightAccessory={
				<ButtonContainer>
					{isSuper && (
						<Button
							$buttonType={ButtonTypeType.GHOST}
							size="small"
							onClick={downloadFile}
						>
							{!isLoading ? '엑셀 다운로드' : '다운로드 중..'}
						</Button>
					)}
					{isCreateVehiclePlate && (
						<Button onClick={handleCreateClick} size="small">
							번호판 등록
						</Button>
					)}
				</ButtonContainer>
			}
		/>
	);
}

export default VehiclePlatesPage;
