/* eslint-disable no-use-before-define */
import InspectionTemplate from '../../../components/templates/InspectionTemplate';

function TireReplacementManagement() {
	return InspectionTemplate({
		productType: 'TIRE_REPLACEMENT',
		departmentId: false,
	});
}

export default TireReplacementManagement;
