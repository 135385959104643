import React from 'react';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { PURCHASE_HISTORY_DETAIL_PATH } from '../../../../routes/constants/urls';
import { createDetailAction } from '../../../../utils/row-data-util';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetSubscriptionsQuery } from '../../../../store/apis/subscription';

function PurchaseHistoryPage() {
	const { colsList, forms, formInitialValues } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetSubscriptionsQuery,
		formInitialValues: {},
		colsList,
		detailPath: PURCHASE_HISTORY_DETAIL_PATH,
	});

	return (
		<GridContentTemplate
			formInitialValues={formInitialValues}
			forms={forms}
			gridRef={setGridApi}
			mainTitle="구독내역 관리"
			gridTitle="구독내역 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'subscriptionNo'),
			}}
		/>
	);
}

export default PurchaseHistoryPage;
