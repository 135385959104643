import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Radio, RadioChangeEvent } from 'antd';
import { useDispatch } from 'react-redux';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import InputForm from '../../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import Alert from '../../../../components/atoms/Alert';
import { ADMIN_MANAGEMENT_PATH } from '../../../../routes/constants/urls';
import { ButtonTypeType } from '../../../../styles/theme';
import InputAlert from '../../../../components/atoms/InputAlert';
import { useCreateAdministratorMutation } from '../../../../store/apis/administrator';
import Select from '../../../../components/atoms/Select';
import { useGetRolesQuery } from '../../../../store/apis/role';
import { LabelValue } from '../../../../utils/const';
import { useGetDepartmentsQuery } from '../../../../store/apis/department';
import { CustomErrorType } from '../../../../store/apis/@types';
import { ToastContext } from '../../../../contexts/Toast';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { phoneFormat } from '../../../../utils/data-format';
import { CreateAdministratorDto } from '../../../../interface/administrator';
import DepartmentModal from '../../../../components/templates/DepartmentModal';
import { getDepartmentQuery } from '../../../../utils/string-util';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

// const InnerInputForm = styled(InputForm)`
// 	padding: 20px;
// 	background: ${({ theme }) => theme.common.colors.gray_1};
// 	border-radius: 12px;
// `;

const initialValues: CreateAdministratorDto = {
	administratorApplicationId: '',
	administratorName: '',
	departmentId: '',
	administratorPhone: '',
	departmentType: 'ADMIN',
	roleId: '',
	dashboardGroupId: null,
};

const backUrl = ADMIN_MANAGEMENT_PATH;

function AdminCreatePage() {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [createAdministrator] = useCreateAdministratorMutation();
	const dispatch = useDispatch();

	const { data: roles } = useGetRolesQuery({ pageSize: 500 });

	const roleOptionList: LabelValue[] = roles
		? roles.rows.map((item) => ({
				label: item.roleName,
				value: item.id,
		  }))
		: [];

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.administratorApplicationId.length < 6) {
			errors.administratorApplicationId =
				'영문,숫자 포함 6글자 이상 입력해주세요.';

			if (values.administratorApplicationId === '') {
				errors.administratorApplicationId = '아이디를 입력해주세요.';
			}
		}

		if (values.administratorName === '') {
			errors.administratorName = '이름을 입력해주세요.';
		}

		if (values.administratorPhone === '') {
			errors.administratorPhone = '휴대폰 번호를 입력해주세요.';
		}

		if (values.administratorPhone.length < 9) {
			errors.administratorPhone = '휴대폰 번호를 확인해주세요.';
		}

		if (values.roleId === '') {
			errors.roleId = '권한을 선택해주세요.';
		}

		if (values.departmentId === '') {
			errors.departmentId = '제휴 소속을 선택해주세요.';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());

			const result = await createAdministrator(values);

			if ('error' in result) {
				toast(
					'error',
					(result.error as CustomErrorType).data.translate ||
						(result.error as CustomErrorType).data.message,
				);
			} else {
				toast('info', '관리자 생성이 완료되었습니다.');
				navigate(backUrl);
			}

			setAlertVisible(false);
			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const { data: departmentsList } = useGetDepartmentsQuery({
		service: getDepartmentQuery(formik.values.departmentType) ?? 'BOS_WEB',
	});

	const departmentOptionList: LabelValue[] = departmentsList
		? departmentsList.rows.map((item) => ({
				label: item.departmentName,
				value: item.id,
		  }))
		: [];

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			handleAlertOpenClick();
		}
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="관리자 등록"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleCreateClick} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="관리자 상세"
					borderRadius="10px"
					className="inner-content"
				>
					<InnerContent>
						<InputForm>
							<Label name="departmentType" essential>
								<span className="label">
									<Typo $typoType="label2">관리자 소속</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									formik.setFieldValue('departmentType', e.target.value);
									formik.setFieldValue('departmentId', '');
								}}
								value={formik.values.departmentType}
							>
								<Radio value="ADMIN">
									<Typo $typoType="label1">오픈마일 관리자</Typo>
								</Radio>
								<Radio value="MANAGER">
									<Typo $typoType="label1">매니저 관리자</Typo>
								</Radio>
								<Radio value="CUSTOMER">
									<Typo $typoType="label1">법인 관리자</Typo>
								</Radio>
							</Radio.Group>
						</InputForm>
						<InputForm>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'flex-end',
								}}
							>
								<Label name="departmentId" essential>
									<span className="label">
										<Typo $typoType="label2">소속을 선택해주세요</Typo>
									</span>
								</Label>
								<span style={{ margin: '0 4px' }} />
								<DepartmentModal userType="ADMIN" />
							</div>
							<Select
								placeholder="소속 선택"
								value={formik.values.departmentId}
								onChange={(value: string) =>
									formik.setFieldValue('departmentId', value)
								}
							>
								{departmentOptionList.map((item, idx) => (
									<Select.Option value={item.value} key={idx.toString()}>
										{item.label}
									</Select.Option>
								))}
							</Select>
							{formik.errors.departmentId && (
								<InputAlert $inputStatus="error">
									{formik.errors.departmentId}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="roleId" essential>
								<span className="label">
									<Typo $typoType="label2">권한</Typo>
								</span>
							</Label>
							<Select
								placeholder="권한 선택"
								value={formik.values.roleId}
								onChange={(value: string) =>
									formik.setFieldValue('roleId', value)
								}
							>
								{roleOptionList.map((item, idx) => (
									<Select.Option value={item.value} key={idx.toString()}>
										{item.label}
									</Select.Option>
								))}
							</Select>
							{formik.errors.roleId && (
								<InputAlert $inputStatus="error">
									{formik.errors.roleId}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorApplicationId" essential>
								<span className="label">
									<Typo $typoType="label2">아이디</Typo>
								</span>
							</Label>
							<Input
								name="administratorApplicationId"
								placeholder="영문, 숫자 6글자 이상 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.administratorApplicationId}
								onChange={formik.handleChange}
								onReset={() =>
									formik.setFieldValue('administratorApplicationId', '')
								}
							/>
							{formik.errors.administratorApplicationId && (
								<InputAlert $inputStatus="error">
									{formik.errors.administratorApplicationId}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorName" essential>
								<span className="label">
									<Typo $typoType="label2">이름</Typo>
								</span>
							</Label>
							<Input
								name="administratorName"
								placeholder="이름을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.administratorName}
								onChange={formik.handleChange}
								onReset={() => formik.setFieldValue('administratorName', '')}
							/>
							{formik.errors.administratorName && (
								<InputAlert $inputStatus="error">
									{formik.errors.administratorName}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorPhone" essential>
								<span className="label">
									<Typo $typoType="label2">휴대폰 번호</Typo>
								</span>
							</Label>
							<Input
								name="administratorPhone"
								placeholder="휴대폰 번호를 입력해 주세요"
								maxLength={13}
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneFormat(formik.values.administratorPhone)}
								onChange={(e) => {
									formik.setFieldValue(
										'administratorPhone',
										e.target.value.replaceAll('-', ''),
									);
								}}
								onReset={() => formik.setFieldValue('administratorPhone', '')}
							/>
							{formik.errors.administratorPhone && (
								<InputAlert $inputStatus="error">
									{formik.errors.administratorPhone}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="dashboardGroupId">
								<span className="label">
									<Typo $typoType="label2">검사대행 대시보드 그룹 ID</Typo>
								</span>
							</Label>
							<Input
								name="dashboardGroupId"
								placeholder="대시보드 그룹 ID를 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.dashboardGroupId || ''}
								onChange={(e) =>
									formik.setFieldValue(
										'dashboardGroupId',
										e.target.value || null,
									)
								}
								onReset={() => formik.setFieldValue('dashboardGroupId', null)}
							/>
							{formik.errors.dashboardGroupId && (
								<InputAlert $inputStatus="error">
									{formik.errors.dashboardGroupId}
								</InputAlert>
							)}
						</InputForm>
					</InnerContent>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible && (
				<Alert
					title="관리자 등록"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={() => formik.handleSubmit()}
					isVisible={isAlertVisible}
				>
					관리자를 등록하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default AdminCreatePage;
