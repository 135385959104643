import React, { useState } from 'react';
import styled from 'styled-components';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/src/query/endpointDefinitions';
// import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { Pagination } from 'antd';
import { FilterFormInfo } from '../../../utils/filter-util';
import Modal from '../../atoms/Modal';
import { ListResponse } from '../../../store/apis/@types';
import Typo from '../../atoms/Typo';
import GridFilterForm from '../GridFilterForm';
import SelectionGrid from '../../atoms/SelectionGrid';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import { createRadioCellRender } from '../../../utils/row-data-util';

interface SelectionModalProps {
	isVisible: boolean;
	title: string;
	onClose: () => void;
	onChange: (event: any) => void;
	column: ColDef[];
	useQuery: UseQuery<QueryDefinition<any, any, any, ListResponse<any>, 'api'>>;
	defaultQueryParams?: Record<any, any>;
	searchInput?: FilterFormInfo[];
	defaultSelectedIdList?: any[];
	width?: number;
}

const StyledModal = styled(Modal)`
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-header {
		padding: 16px 16px 8px 16px;
	}
	.ant-modal-footer {
		padding: 16px;
	}
`;
const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;
const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;
const ModalContentContainer = styled.div`
	padding: 16px;

	.modal-subTitle {
		margin-bottom: 12px;
	}
	.modal-search-form {
		margin-bottom: 8px;
	}
	.row-total-count-container {
		margin-bottom: 15px;
	}
	.row-total-count {
		color: ${({ theme }) => theme.common.colors.primary_6_main};
	}
`;
const SelectionGridWrapper = styled.div`
	height: 384px;
`;

const DEFAULT_PAGE_SIZE = 20;

const SelectionModal = ({
	isVisible,
	title,
	onClose,
	onChange,
	column,
	useQuery,
	defaultQueryParams,
	searchInput,
	defaultSelectedIdList = [],
	width = 1280,
}: SelectionModalProps) => {
	const [selectItem, setSelectItem] = useState<any[]>([]);
	const [search, setSearch] = useState<Record<any, any>>({});
	const [page, setPage] = useState(1);

	const onPageChange = (clickedPage: number) => {
		setPage(clickedPage);
	};

	const handleModalClose = () => {
		onClose();
	};

	const handleSubmit = async () => {
		handleModalClose();
		return onChange(selectItem);
	};

	const { currentData: data } = useQuery({
		...defaultQueryParams,
		...search,
		page,
		pageSize: DEFAULT_PAGE_SIZE,
	});
	const targetList = data ? data.rows : [];
	const totalCount = data ? data.count : 0;

	const renderContent = () => {
		return (
			<ModalContentContainer>
				{searchInput?.length ? (
					<GridFilterForm
						className="modal-search-form"
						forms={searchInput}
						useQuerySave={false}
						onSearchSubmit={(values) => {
							setSearch(values);
						}}
						$columnCount={4}
					/>
				) : (
					''
				)}
				<p className="row-total-count-container">
					<Typo $typoType="label2">
						<span className="row-total-count">
							<Typo $typoType="h9" color="primary_6_main">
								{targetList.length}개{' '}
							</Typo>
						</span>
						조회되었습니다.
					</Typo>
				</p>

				<SelectionGridWrapper>
					<SelectionGrid
						type="radio"
						rowData={targetList}
						column={column}
						// onRowSelected={onRowSelected}
						stopEditingWhenCellsLoseFocus
						// frameworkComponents={{
						// 	...createRadioCellRender((value) => {
						// 		setSelectItem([value]);
						// 	}, defaultSelectedIdList),
						// }}
						components={createRadioCellRender((value) => {
							setSelectItem([value]);
						}, defaultSelectedIdList)}
						// onFirstDataRendered={onFirstDataRendered}
					/>
					<Pagination
						simple
						current={page}
						defaultPageSize={DEFAULT_PAGE_SIZE}
						defaultCurrent={1}
						onChange={onPageChange}
						total={totalCount}
						style={{ marginTop: '8px' }}
					/>
				</SelectionGridWrapper>
			</ModalContentContainer>
		);
	};

	return (
		<StyledModal
			title={<ModalTitle>{title}</ModalTitle>}
			open={isVisible}
			onCancel={handleModalClose}
			width={width ?? 1280}
			destroyOnClose
			noContentPadding
			footer={
				<ModalFooterWrapper>
					<Button
						$buttonType={ButtonTypeType.OPAQUE}
						onClick={handleModalClose}
					>
						닫기
					</Button>
					<Button disabled={selectItem.length === 0} onClick={handleSubmit}>
						선택
					</Button>
				</ModalFooterWrapper>
			}
		>
			{renderContent()}
		</StyledModal>
	);
};

export default SelectionModal;
