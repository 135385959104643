import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Image } from 'antd';
import dayjs from 'dayjs';
import Button from '../components/atoms/Button';
import { GridItem, GridTitleSize } from '../components/molecules/GridLayout';
import theme, { ButtonTypeType, ThemeType } from '../styles/theme';

export interface NewTableValueType {
	key: string;
	label: string;
	bulletOptions?: {
		key: string | boolean;
		value: string;
		color?: keyof ThemeType['common']['colors'];
	}[];
	buttonOptions?: { label: string; onClick: () => void };
	suffix?: string;
	span?: number;
	dateFormat?: string;
}

export enum TableContentType {
	TEXT,
	DATE,
	IMAGE,
	IMAGES,
	BULLET,
	BUTTET_BUTTON,
	TEXT_LIST,
	SUFFIX_TEXT,
	SUFFIX_PRICE_TEXT,
	PHONE_NUMBER,
}

const StatusButton = styled(Button)`
	margin-left: auto;
`;

export interface TableValueType {
	key: string;
	label: string;
	type: TableContentType;
	bulletOptions?: {
		key: string | boolean;
		value: string;
		color?: keyof ThemeType['common']['colors'];
	}[];
	buttonOptions?: { label: string; onClick: () => void };
	span?: number;
	listKeyValue?: { key: string; value: string | number };
	suffixItem?: string;
	authHypen?: string;
}

export const renderTable = <T extends Record<keyof T, ReactNode>>({
	tableKeys,
	data,
}: {
	tableKeys: Record<string, TableValueType>;
	data: T;
}) => {
	const tableKeyList = Object.entries(tableKeys);

	return tableKeyList.map(([key, value], idx) => {
		const tableContent = data[key as keyof T];

		switch (value.type) {
			case TableContentType.TEXT:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{tableContent || '-'}
					</GridItem>
				);
			case TableContentType.DATE:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss') ||
							'-'}
					</GridItem>
				);

			case TableContentType.BULLET: {
				const color =
					value.bulletOptions?.find((item) => item.key === tableContent)
						?.color ?? 'black';

				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<span style={{ color: theme.common.colors[color] }}>
							&#8226;{' '}
							{value.bulletOptions?.find((item) => item.key === tableContent)
								?.value ?? '-'}
						</span>
					</GridItem>
				);
			}

			case TableContentType.BUTTET_BUTTON:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						&#8226;{' '}
						{value.bulletOptions
							? value.bulletOptions?.find((item) => item.key === tableContent)
									?.value ?? '-'
							: tableContent}
						<StatusButton
							type="submit"
							size="small"
							$buttonType={ButtonTypeType.GHOST}
							onClick={tableKeys[key].buttonOptions?.onClick}
						>
							{tableKeys[key].buttonOptions?.label || '상태관리'}
						</StatusButton>
					</GridItem>
				);

			case TableContentType.IMAGE:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<Image width={32} height={32} src={tableContent as string} />
					</GridItem>
				);

			case TableContentType.IMAGES:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						<div style={{ display: 'flex', gap: '4px' }}>
							{(tableContent as string[])?.map((src: string, index) => {
								return (
									<Image
										key={index.toString()}
										width={32}
										height={32}
										src={src as string}
									/>
								);
							})}
						</div>
					</GridItem>
				);
			case TableContentType.TEXT_LIST:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
						$isAutoHeight
					>
						<ul
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 8,
								listStyle: 'initial',
								paddingLeft: 20,
							}}
						>
							{(tableContent as any[]).map((item: any) => (
								<li>
									{tableKeys[key]?.listKeyValue &&
										`${item[tableKeys[key].listKeyValue?.key || '']} ${
											item[tableKeys[key].listKeyValue?.value || '']
										}회`}
								</li>
							))}
						</ul>
					</GridItem>
				);
			case TableContentType.SUFFIX_TEXT:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{tableContent || '-'}
						{tableKeys[key]?.suffixItem}
					</GridItem>
				);
			case TableContentType.SUFFIX_PRICE_TEXT:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{Number(tableContent).toLocaleString() || '-'}
						{tableKeys[key]?.suffixItem}
					</GridItem>
				);
			case TableContentType.PHONE_NUMBER:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{String(tableContent)?.replace(
							/^(\d{2,3})(\d{3,4})(\d{4})$/,
							`$1-$2-$3`,
						) || ''}
						{tableKeys[key]?.authHypen}
					</GridItem>
				);
			default:
				return (
					<GridItem
						key={idx.toString()}
						title={value.label}
						size={GridTitleSize.GT_MEDIUM}
						span={value.span}
					>
						{tableContent || '-'}
					</GridItem>
				);
		}
	});
};
