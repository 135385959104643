/**
 * Public Routes
 */
export const HOME_PATH = '/';
export const LOGIN_PATH = '/login';
export const PASSWORD_RESET = '/password/reset';
export const ID_SEARCH = '/id/search';
export const PASSWORD_SEARCH = '/password/search';

/**
 * Private Routes
 */
// 회원 관리
export const CUSTOMER_MANAGEMENT_PATH = '/customers';
export const CUSTOMER_DETAIL_PATH = '/customer';
export const CUSTOMER_CREATE_PATH = '/customer/create';

// 매니저 관리
export const MANAGER_MANAGEMENT_PATH = '/managers';
export const MANAGER_DETAIL_PATH = '/manager';
export const MANAGER_ASSIGNMENT_PATH = '/managers/assignment';

// 관리자 관리
export const ADMIN_MANAGEMENT_PATH = '/admins';
export const ADMIN_CREATE_PATH = '/admin/create';
export const ADMIN_DETAIL_PATH = '/admin';
export const ADMIN_ROLE_MANAGEMENT_PATH = '/admin-roles';
export const ADMIN_ROLE_DETAIL_PATH = '/admin-role';
export const ADMIN_ROLE_CREATE_PATH = '/admin-role/create';

// 관리자 소속 관리
export const DEPARTMENT_MANAGEMENT_PATH = '/departments';
export const DEPARTMENT_DETAIL_PATH = '/department';

// 작업 관리
export const TASK_MANAGEMENT_PATH = '/tasks';
export const REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH =
	'/inspection/assignment/regular';
export const INSPECTION_AGENCY_PATH = '/inspection/assignment/agency';
export const PARTNER_INSPECTION_AGENCY_PATH =
	'/inspection/assignment/agency/partner';
export const SERVICE_SCHEDULE_MANAGEMENT_PATH = '/service/schedules';
export const EMERGENCY_DISPATCH_MANAGEMENT_PATH = '/emergency/dispatches';
export const BUYER_INSPECTION_AGENCY_PATH = '/inspection/agency/buyer';

// 타이어 관리
export const TIRE_REPLACEMENT_MANAGEMENT_PATH = '/tire/replacement';

// 상품 관리, 구독 관리
export const SUBSCRIBE_PRODUCT_MANAGEMENT_PATH = '/subscribes';
export const SUBSCRIBE_PRODUCT_DETAIL_PATH = '/subscribe';
export const SUBSCRIBE_PRODUCT_CREATE_PATH = '/subscribe/create';
export const PRODUCT_MANAGEMENT_PATH = '/products';
export const PRODUCT_DETAIL_PATH = '/product';
export const PRODUCT_CREATE_PATH = '/product/create';
export const RULES_MANAGEMENT_PATH = '/rules';

// 차량 구매구독 관리 - 전체 관리자
export const VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH = '/vehicle/subscriptions';
export const VEHICLE_SUBSCRIPTION_DETAIL_PATH = '/vehicle/subscriptions';
export const VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH =
	'/vehicle/subscription/register';
export const VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH =
	'/vehicle/subscription/cancellation';
export const VEHICLE_SUBSCRIPTION_CANCEL_DETAIL_PATH =
	'/vehicle/subscription/cancellation';
export const VEHICLE_SUBSCRIPTION_POLICY_MANAGEMENT_PATH =
	'/vehicle/subscription/policy';
export const VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH =
	'/vehicle/subscription/consulting';
export const VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH =
	'/vehicle/subscription/consulting';
export const VEHICLE_SUBSCRIPTION_CONSULTING_CREATE_PATH =
	'/vehicle/subscription/consulting/create';

// 차량 구독 견적
export const VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH =
	'/vehicle/subscription/quotes';
export const VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH =
	'/vehicle/subscription/quotes';
export const VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH =
	'/vehicle/subscription/quotes/create';

// 법인 카케어 관리 - 법인 관리자
export const CAR_CARE_SUBSCRIPTION_MANAGEMENT_PATH = '/car-care/subscriptions';
export const CAR_CARE_SUBSCRIPTION_DETAIL_PATH = '/car-care/subscription';
export const CAR_CARE_SUBSCRIPTION_REQUEST_PATH =
	'/car-care/subscription/request';

// 차량관리
export const VEHICLES_STATUS_MANAGEMENT_PATH = '/vehicles/status';
export const VEHICLES_STATUS_DETAIL_PATH = '/vehicles/status';
export const VEHICLES_STATUS_CREATE_PATH = '/vehicles/status/create';
export const VEHICLE_DTG_PATH = '/vehicle/dtg';
export const VEHICLE_DTG_BY_TRIP_PATH = '/vehicle/trips';
export const VEHICLE_DTG_MAP_BY_TRIP_PATH = '/vehicle/trip';
export const VEHICLES_CURRENT_LOCATIONS_PATH = '/vehicles/locations';
export const VEHICLES_OBD_PATH = '/vehicles/obd';
export const VEHICLE_OBD_PATH = '/vehicle/obd';
export const VEHICLES_MANAGEMENT_PATH = '/vehicles/info';
export const VEHICLES_DETAIL_PATH = '/vehicle/info';
export const VEHICLES_CREATE_PATH = '/vehicle/create';

// 번호판 관리
export const PLATES_MANAGEMENT_PATH = '/plates';
export const PLATES_DETAIL_PATH = '/plate';
export const PLATES_CREATE_PATH = '/plate/create';

// 법인 관리
export const COMPANY_MANAGEMENT_PATH = '/companies';
export const COMPANY_DETAIL_PATH = '/company';
export const COMPANY_CREATE_PATH = '/company/create';

// 기사 괸리
export const DRIVER_MANAGEMENT_PATH = '/drivers';
export const DRIVER_DETAIL_PATH = '/driver';
export const DRIVER_CREATE_PATH = '/driver/create';

// 주문 관리
export const PURCHASE_HISTORY_MANAGEMENT_PATH = '/purchase/histories';
export const PURCHASE_HISTORY_DETAIL_PATH = '/purchase/history';
export const TERMINATION_MANAGEMENT_PATH = '/terminations';
export const TERMINATION_DETAIL_PATH = '/termination';

// 재고 관리
export const INVENTORY_STATE_PATH = '/inventory/state';
export const INVENTORY_STATE_STANDARD_MANAGEMENT_PATH =
	'/inventory/state/standards';
export const INVENTORY_STATE_STANDARD_CREATE_PATH =
	'/inventory/state/standard/create';
export const INVENTORY_STATE_STANDARD_DETAIL_PATH = '/inventory/state/standard';

// 정산 관리
export const MONTHLY_SALES_MANAGEMENT_PATH = '/sales/monthly';
export const MONTHLY_SALES_DETAIL_PATH = '/sales/monthly';
export const MANAGER_SETTLEMENT_MANAGEMENT_PATH = '/manager/settlement';

// 마케팅 관리
export const BANNER_MANAGEMENT_PATH = '/banners';

// CS 관리
export const CS_TEL_PATH = '/cs/tel';
export const CS_TEL_DETAIL_PATH = '/cs/tel';
export const CS_TEL_CREATE_PATH = '/cs/tel/create';
export const CS_CHAT_PATH = '/cs/chat';
export const CS_CHAT_DETAIL_PATH = '/cs/chat';
export const CS_FAQ_PATH = '/cs/faq';
export const CS_FAQ_DETAIL_PATH = '/cs/faq';
export const CS_FAQ_CREATE_PATH = '/cs/faq/create';
export const CS_APP_VERSION_MANAGEMENT = '/cs/app-versions';
export const CS_APP_VERSION_DETAIL_PATH = '/cs/app-version';
export const CS_APP_VERSION_CREATE_PATH = '/cs/app-version/create';
export const CS_NOTICE_PATH = '/cs/notice';
export const CS_NOTICE_DETAIL_PATH = '/cs/notice';
export const CS_NOTICE_CREATE_PATH = '/cs/notice/create';

// 약관 관리
export const TERMS_MANAGEMENT_PATH = '/terms';
export const TERMS_CREATE_PATH = '/terms/create';
export const TERMS_DETAIL_PATH = '/terms';

// 카서비스
export const CAR_SERVICE_DASHBOARD_PATH = '/car-service/dashboard';
export const CAR_SERVICE_MYDASHBOARD_PATH = '/car-service/mydashboard';

export const CAR_SERVICE_MANAGEMENT_PATH = '/car-services';
export const CAR_SERVICE_DETAIL_PATH = '/car-services';
export const CAR_SERVICE_PARTNER_MANAGEMENT_PATH = '/car-service/partner';
export const CAR_SERVICE_PARTNER_DETAIL_PATH = '/car-service/partner';
export const CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH =
	'/car-service/outsourcing';
export const CAR_SERVICE_OUTSOURCING_DETAIL_PATH = '/car-service/outsourcing';
export const CAR_SERVICE_COMPANY_MANAGEMENT_PATH = '/car-service/companies';
export const CAR_SERVICE_COMPANY_CREATE_PATH = '/car-service/company/create';
export const CAR_SERVICE_EXCEL_DOWNLOAD_PATH = '/car-service/excel/download';
export const CAR_SERVICE_EXCEL_UPLOAD_PATH = '/car-service/excel/upload';
export const CAR_SERVICE_TS_INSPECTION_RESULTS = '/car-service/ts';
export const CAR_SERVICE_ALL_TRANSFERS = '/car-service/transfers';

// 에러 페이지 404 , 500, 503
export const ERROR_PATH = '/error';
