import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { transportDatetime } from '../../../../utils/date-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { numberWithCommas } from '../../../../utils/data-format';
import { FilterElementType } from '../../../../utils/filter-util';

interface userDataProps {
	departmentId: number;
	departmentsOption: Array<{ label: string; value: number }>;
	isSuper: boolean;
}

const userData: ({
	departmentId,
	departmentsOption,
	isSuper,
}: userDataProps) => GridDataModel = ({
	departmentId,
	departmentsOption,
	isSuper,
}) => {
	return {
		formInitialValues: { departmentId: String(departmentId) },
		colsList: [
			{
				tableInfo: {
					field: 'vehicleNumber',
					headerName: '차량번호',
				},
				options: {
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'reportedAt',
					headerName: '측정시각',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value
							? dayjs(`20${value.slice(0, 6)}T${value.slice(6)}`).format(
									'YYYY.MM.DD(dd) HH:mm:ss',
							  )
							: 'DTG 설치정보 없음';
					},
				},
			},
			{
				tableInfo: {
					field: 'reportedAt',
					headerName: '측정',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return (
							value && (
								<Tag color="blue">
									{transportDatetime(
										dayjs(
											`20${value.slice(0, 6)}T${value.slice(6)}`,
										).toISOString(),
									)}
								</Tag>
							)
						);
					},
				},
			},
			{
				tableInfo: {
					field: 'v',
					headerName: '속도',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value && `${numberWithCommas(value)} km/s`;
					},
				},
			},
			{
				tableInfo: {
					field: 'r',
					headerName: 'RPM',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value && numberWithCommas(value);
					},
				},
			},
			{
				tableInfo: {
					field: 'm',
					headerName: '총 주행거리',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value && `${numberWithCommas(value)} km`;
					},
				},
			},
			{
				tableInfo: {
					field: 'bv',
					headerName: '배터리 전압',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value && `${value} V`;
					},
				},
			},
			{
				tableInfo: {
					field: 'ftli',
					headerName: '연료 잔량',
				},
				options: {
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value && `${value} %`;
					},
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '상세보기',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: isSuper
			? [
					{
						key: 'departmentId',
						label: '부서',
						type: FilterElementType.SELECT,
						options: departmentsOption,
						optionalActivedValue: String(departmentId),
						optionalActivedKey: 'departmentId',
					},
			  ]
			: [],
	};
};

export default userData;
