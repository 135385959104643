import { PaymentDto } from '../../../interface/payment';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const paymentsApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPayments: builder.query<ListResponse<PaymentDto>, Record<any, any>>({
			query: (args) => ({
				method: 'GET',
				url: '/members/payments',
				params: args,
			}),
			providesTags: ['Payments'],
		}),
		getPaymentDetail: builder.query<
			ObjectResponse<PaymentDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => ({
				method: 'GET',
				url: `/members/payments/${id}`,
			}),
			providesTags: ['Payments'],
		}),
		confirmPayment: builder.mutation<ObjectResponse<any>, { id: string }>({
			query: ({ id }) => ({
				method: 'POST',
				url: `/members/payments/${id}/confirm`,
			}),
			invalidatesTags: ['Payments'],
		}),
		cancelPayment: builder.mutation<
			ObjectResponse<any>,
			{ id: number | undefined }
		>({
			query: ({ id }) => ({
				method: 'POST',
				url: `/members/payments/${id}/cancellation`,
			}),
			invalidatesTags: ['Payments'],
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetPaymentsQuery,
	useGetPaymentDetailQuery,
	useConfirmPaymentMutation,
	useCancelPaymentMutation,
} = paymentsApiSlice;
