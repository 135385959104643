/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Pagination } from 'antd';
import Dot from '../../../components/atoms/Dot';
import Grid from '../../../components/atoms/Grid';
import Tab from '../../../components/atoms/Tab';
import Typo from '../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import ViewPointButton from '../../../components/molecules/ViewPointButton';
import GridFilterForm from '../../../components/organisms/GridFilterForm';
import getPinSrc, {
	getManagerPinSrc,
} from '../../../components/organisms/NaverMap/utils';
import {
	SubscriptionItemDto,
	SubscriptionItemStatusType,
} from '../../../interface/subscriptionItem';
import {
	INSPECTION_AGENCY_PATH,
	REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
	TIRE_REPLACEMENT_MANAGEMENT_PATH,
} from '../../../routes/constants/urls';
import {
	GetSubscriptionParam,
	useGetSubscriptionItemAllQuery,
} from '../../../store/apis/subscriptionItem';
import {
	MANAGER_STATUS_OPTION,
	MAP_PIN_CUSTOMER_VALUE,
	MAP_PIN_FILTER_OPTION,
	MAP_PIN_MANAGER_VALUE,
	SERVICE_TYPE_OPTIONS,
	SUBSCRIPTION_ITEM_STATUS_OPTION,
} from '../../../utils/const';
import { phoneFormat } from '../../../utils/data-format';
import { FilterElementType, FilterFormInfo } from '../../../utils/filter-util';
import circuitInspectionGridData from './gridData';

export enum TabState {
	ALL = '',
	REQUEST = 'REQUESTED',
	PENDING = 'PENDING',
	ASSIGNED = 'ASSIGNED',
	COMPLETED = 'COMPLETED',
}

const REFETCH_TIMEOUT = 30000;
const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface SearchParams extends GetSubscriptionParam {
	pinFilter?: 'CUSTOMER' | 'MANAGER';
}

const searchInput: FilterFormInfo[] = [
	{
		key: 'requestedAt',
		type: FilterElementType.DATE_RANGE_PICKER,
		label: '생성일시',
		span: 1,
	},
	{
		key: 'assignedAt',
		type: FilterElementType.DATE_RANGE_PICKER,
		label: '매니저 배정일시',
		span: 1,
	},
	{
		key: 'completeConfirmedAt',
		type: FilterElementType.DATE_RANGE_PICKER,
		label: '서비스 확정일시',
		span: 1,
	},
	{
		key: 'administratorName',
		type: FilterElementType.INPUT,
		label: '관리자명',
		span: 1,
	},
	{
		key: 'customerName',
		type: FilterElementType.INPUT,
		label: '고객명',
		span: 1,
	},
	{
		key: 'customerPhone',
		type: FilterElementType.INPUT,
		label: '고객 전화번호',
		span: 1,
	},
	{
		key: 'managerName',
		type: FilterElementType.INPUT,
		label: '매니저명',
		span: 1,
	},
	{
		key: 'managerPhone',
		type: FilterElementType.INPUT,
		label: '매니저 전화번호',
		span: 1,
	},
	{
		key: 'productType',
		type: FilterElementType.SELECT,
		label: '서비스 유형',
		span: 1,
		options: SERVICE_TYPE_OPTIONS,
	},
	{
		key: 'subscriptionItemStatus',
		type: FilterElementType.SELECT,
		label: '작업 상태',
		span: 1,
		options: SUBSCRIPTION_ITEM_STATUS_OPTION,
	},
	{
		key: 'subscriptionItemManagerStatus',
		type: FilterElementType.SELECT,
		label: '매니저 작업 상태',
		span: 1,
		options: MANAGER_STATUS_OPTION,
	},
	{
		key: 'street',
		type: FilterElementType.INPUT,
		label: '차량 위치',
		span: 1,
	},
	{
		key: 'managerDepartment',
		type: FilterElementType.INPUT,
		label: '매니저 소속',
		span: 1,
	},
	{
		key: 'pinFilter',
		type: FilterElementType.SELECT,
		label: '지도 Pin 필터',
		span: 1,
		options: MAP_PIN_FILTER_OPTION,
	},
	{
		key: 'minimumAdditionalPaidPriceTax',
		type: FilterElementType.INPUT,
		label: '추가금액 (이상)',
		placeholder: '숫자 입력',
		span: 1,
	},
	{
		key: 'maximumAdditionalPaidPriceTax',
		type: FilterElementType.INPUT,
		label: '추가금액 (이하)',
		placeholder: '숫자 입력',
		span: 1,
	},
];

const OverlayContainer = styled.div`
	background: #fff;
	box-shadow: 0px 2px 16px rgba(94, 101, 110, 0.16);
	padding: 12px;
	border-radius: 12px;
	display: flex;
	align-items: center;

	h2 {
		font-size: 14px;
		margin-bottom: 4px;
		font-weight: 700;

		span {
			font-size: 13px;
			font-weight: 500;
			color: #5e656e;
		}
	}

	p {
		font-size: 13px;
		font-weight: 500;
	}

	a {
		cursor: pointer;
		background: transparent;
		padding: 6px 10px;
		border-radius: 7px;
		border: 1px solid #2559f6;
		color: #2559f6;
		font-size: 10px;
		margin-left: 11px;
	}

	button {
		cursor: not-allowed;
		background: transparent;
		padding: 6px 10px;
		border-radius: 7px;
		border: 1px solid #dcdee0;
		color: #dcdee0;
		font-size: 10px;
		margin-left: 11px;
	}
`;

const PaginationContainer = styled.div`
	margin-top: 12px;

	.grid-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.grid-pagination {
		display: flex;
		align-items: center;
	}

	.grid-total-count {
		display: flex;
		gap: 8px;
	}
`;

const getTargetUrl = (item: SubscriptionItemDto) => {
	let baseUrl = REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH;

	switch (item.productType) {
		case 'REGULAR_INSPECTION':
			baseUrl = REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH;
			break;
		case 'INSPECTION_AGENCY':
			baseUrl = INSPECTION_AGENCY_PATH;
			break;
		case 'TIRE_REPLACEMENT':
			baseUrl = TIRE_REPLACEMENT_MANAGEMENT_PATH;
			break;
		default:
			break;
	}

	return `${baseUrl}?selectedDate=${dayjs(item.firstVisitedAt).format(
		'YYYY-MM-DD',
	)}&subscriptionItemNo=${item.subscriptionItemNo}`;
};

function ServiceScheduleManagement() {
	const navigate = useNavigate();
	const defaultFilterValue = useMemo(
		() => ({
			requestedAtFrom: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
			requestedAtTo: dayjs().format('YYYY-MM-DD'),
		}),
		[],
	);

	const [isMapRender, setIsMapRender] = useState(false);

	const [filterValue, setFilterValue] =
		useState<SearchParams>(defaultFilterValue);
	const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
	const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);

	// map
	const mapRef = useRef<naver.maps.Map | null>(null);
	const infoWindowRef = useRef<naver.maps.InfoWindow | null>(null);

	const managerMarkerListRef = useRef<naver.maps.Marker[]>([]);
	const markerListRef = useRef<naver.maps.Marker[]>([]);
	const managerSelectedMarkerRef = useRef<naver.maps.Marker | null>(null);
	const selectedMarkerRef = useRef<naver.maps.Marker | null>(null);
	const selectedServiceItemRef = useRef<SubscriptionItemDto | null>(null);
	const managerSelectedServiceItemRef = useRef<SubscriptionItemDto | null>(
		null,
	);
	const initStateRef = useRef(true);

	const [tab, setTab] = useState<TabState>(TabState.ALL);

	const pinFilterRef = useRef<
		typeof MAP_PIN_CUSTOMER_VALUE | typeof MAP_PIN_MANAGER_VALUE | ''
	>();

	const { data } = useGetSubscriptionItemAllQuery(
		{
			...filterValue,
			page: currentPage,
			pageSize: currentPageSize,
		},
		{ pollingInterval: REFETCH_TIMEOUT },
	);

	const taskList = data
		? data.rows.map((item) => ({
				...item,
				id: item.subscriptionItemNo,
				isMarkerOn: false,
		  }))
		: [];

	const filterTaskList = taskList
		.filter((item) => item.subscriptionItemStatus !== TabState.PENDING)
		.map((mapItem) => {
			const pinFilterItem = { ...mapItem };

			if (pinFilterRef.current === 'CUSTOMER') {
				pinFilterItem.managerLat = 0;
				pinFilterItem.managerLng = 0;
			}
			if (pinFilterRef.current === 'MANAGER') {
				pinFilterItem.lat = 0;
				pinFilterItem.lng = 0;
			}

			return pinFilterItem;
		});

	const tableData = filterTaskList;
	const clientFilterAllTableData = filterTaskList.filter(
		(item) => item.subscriptionItemStatus === tab,
	);

	const handleSubmitClick = useCallback(async (value: SearchParams) => {
		initStateRef.current = true;

		const searchParams: SearchParams = Object.entries(value)
			.filter((item) => item[1] !== '')
			.reduce((initialValue, currentValue) => {
				const returnValue: any = {
					...initialValue,
				};

				const [key, nextValue] = currentValue;
				returnValue[key] = nextValue;

				return returnValue;
			}, {});

		setFilterValue(searchParams);
		pinFilterRef.current = searchParams.pinFilter;
	}, []);

	const handleDetailClick = (value: any) => {
		if (!value) return;
		navigate(getTargetUrl(value));
	};

	useEffect(() => {
		if (!isMapRender) return;

		mapRef.current = new naver.maps.Map('map', {
			zoom: 15,
			zoomControlOptions: {
				position: naver.maps.Position.RIGHT_TOP,
				style: naver.maps.ZoomControlStyle.SMALL,
			},
			zoomControl: true,
		});

		if (managerMarkerListRef.current) {
			managerMarkerListRef.current.forEach((item) => {
				item.setMap(mapRef.current);
			});
		}

		if (markerListRef.current) {
			markerListRef.current.forEach((item) => {
				item.setMap(mapRef.current);
			});
		}
	}, [isMapRender]);

	const renderInfoWindowContent = () => {
		if (!selectedServiceItemRef.current) return <div />;

		return (
			<OverlayContainer>
				<div>
					<h2>
						{selectedServiceItemRef.current.customerName} <span>고객</span>
					</h2>
					<p>{phoneFormat(selectedServiceItemRef.current.customerPhone)}</p>
				</div>
				{selectedServiceItemRef.current.productType === 'TIRE_REPLACEMENT' ? (
					<button type="button">타이어 교체</button>
				) : (
					<a href={getTargetUrl(selectedServiceItemRef.current)}>
						작업상세보기
					</a>
				)}
			</OverlayContainer>
		);
	};

	const renderManagerInfoWindowContent = () => {
		if (!managerSelectedServiceItemRef.current) return <div />;

		return (
			<OverlayContainer>
				<div>
					<h2>
						{managerSelectedServiceItemRef.current.managerName}{' '}
						<span>매니저</span>
					</h2>
					<p>
						{phoneFormat(managerSelectedServiceItemRef.current.managerPhone)}
					</p>
				</div>
				{managerSelectedServiceItemRef.current.productType ===
				'TIRE_REPLACEMENT' ? (
					<button type="button">작업상세보기</button>
				) : (
					<a href={getTargetUrl(managerSelectedServiceItemRef.current)}>
						작업상세보기
					</a>
				)}
			</OverlayContainer>
		);
	};

	const handleTabClick = (value: TabState) => {
		setTab(value);
	};

	const getSubscriptionListCountViaStatus = (
		status: SubscriptionItemStatusType,
	) => {
		return tableData.filter((item) => item.subscriptionItemStatus === status)
			.length;
	};

	useEffect(() => {
		if (mapRef.current === null) return;

		if (
			!tableData.find(
				(item) =>
					item.subscriptionItemNo ===
						selectedServiceItemRef.current?.subscriptionItemNo ||
					item.subscriptionItemNo ===
						managerSelectedServiceItemRef.current?.subscriptionItemNo,
			)
		) {
			infoWindowRef.current?.close();
			selectedMarkerRef.current = null;
			selectedServiceItemRef.current = null;
			managerSelectedMarkerRef.current = null;
			managerSelectedServiceItemRef.current = null;
			infoWindowRef.current = null;
		}

		const filteredServiceList = [
			...(tab !== TabState.ALL ? clientFilterAllTableData : tableData),
		];

		const managerIncludeIdList: number[] = [];

		const filteredManagerList = (
			tab !== TabState.ALL ? clientFilterAllTableData : tableData
		).filter((item) => {
			if (!item.managerId || !managerIncludeIdList.includes(item.managerId)) {
				managerIncludeIdList.push(item.managerId);
				return false;
			}
			return true;
		});

		markerListRef.current.forEach((markerItem) => {
			markerItem.setMap(null);
		});

		managerMarkerListRef.current.forEach((markerItem) => {
			markerItem.setMap(null);
		});

		let newMarkerList: naver.maps.Marker[] = [];
		let newManagerMarkerList: naver.maps.Marker[] = [];

		if (pinFilterRef.current !== 'MANAGER') {
			newMarkerList = filteredServiceList.map((item) => {
				const newMarker = new naver.maps.Marker({
					map: mapRef.current as naver.maps.Map,
					position: item,
					icon: getPinSrc({
						status: item.subscriptionItemStatus,
						isMarkerOn:
							item.subscriptionItemNo ===
							selectedServiceItemRef.current?.subscriptionItemNo,
					}),
				});
				newMarker.setMap(mapRef.current);

				return newMarker;
			});
		}

		if (pinFilterRef.current !== 'CUSTOMER') {
			newManagerMarkerList = filteredManagerList.map((item) => {
				const newManagerMarker = new naver.maps.Marker({
					map: mapRef.current as naver.maps.Map,
					position: { lat: item.managerLat, lng: item.managerLng },
					icon: getManagerPinSrc({
						isMarkerOn: false,
					}),
				});
				newManagerMarker.setMap(mapRef.current);

				return newManagerMarker;
			});
		}

		newMarkerList.forEach((item, index) => {
			item.addListener('click', () => {
				if (!mapRef.current) return;

				markerListRef.current.forEach((targetMarker, targetIndex) => {
					targetMarker.setIcon(
						getPinSrc({
							status: filteredServiceList[targetIndex].subscriptionItemStatus,
							isMarkerOn: false,
						}),
					);

					targetMarker.setZIndex(1);
				});
				managerMarkerListRef.current.forEach((targetMarker) => {
					targetMarker.setIcon(
						getManagerPinSrc({
							isMarkerOn: false,
						}),
					);

					targetMarker.setZIndex(1);
				});

				selectedMarkerRef.current = item;
				selectedServiceItemRef.current = filteredServiceList[index];

				item.setZIndex(2);

				selectedMarkerRef.current.setIcon(
					getPinSrc({
						status: filteredServiceList[index].subscriptionItemStatus,
						isMarkerOn: true,
					}),
				);

				if (infoWindowRef.current) {
					infoWindowRef.current.close();
				}

				const infoWindow = new naver.maps.InfoWindow({
					content: renderToString(renderInfoWindowContent()),
					pixelOffset: new naver.maps.Point(0, -20),
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					disableAnchor: true,
				});

				infoWindow.open(
					mapRef.current,
					selectedMarkerRef.current as naver.maps.Marker,
				);

				infoWindowRef.current = infoWindow;

				if (mapRef.current.getZoom() < 16) {
					mapRef.current.morph(selectedMarkerRef.current.getPosition(), 16);
				}
			});
		});

		newManagerMarkerList.forEach((item, index) => {
			item.addListener('click', () => {
				if (!mapRef.current) return;
				markerListRef.current.forEach((targetMarker, targetIndex) => {
					targetMarker.setIcon(
						getPinSrc({
							status: filteredServiceList[targetIndex].subscriptionItemStatus,
							isMarkerOn: false,
						}),
					);
					targetMarker.setZIndex(1);
				});
				managerMarkerListRef.current.forEach((targetMarker) => {
					targetMarker.setIcon(
						getManagerPinSrc({
							isMarkerOn: false,
						}),
					);
					targetMarker.setZIndex(1);
				});

				managerSelectedMarkerRef.current = item;
				managerSelectedServiceItemRef.current = filteredManagerList[index];

				item.setZIndex(2);

				if (infoWindowRef.current) {
					infoWindowRef.current.close();
				}

				const infoWindow = new naver.maps.InfoWindow({
					content: renderToString(renderManagerInfoWindowContent()),
					pixelOffset: new naver.maps.Point(0, -20),
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					disableAnchor: true,
				});

				infoWindowRef.current = infoWindow;

				item.setIcon(
					getManagerPinSrc({
						isMarkerOn: true,
					}),
				);

				infoWindow.open(
					mapRef.current,
					managerSelectedMarkerRef.current as naver.maps.Marker,
				);

				if (mapRef.current.getZoom() < 16) {
					mapRef.current.morph(
						managerSelectedMarkerRef.current.getPosition(),
						16,
					);
				}
			});
		});

		setTimeout(() => {
			markerListRef.current.forEach((markerItem) => {
				markerItem.setMap(null);
			});

			managerMarkerListRef.current.forEach((markerItem) => {
				markerItem.setMap(null);
			});
			markerListRef.current = newMarkerList;
			managerMarkerListRef.current = newManagerMarkerList;
		}, 0);
	}, [tableData, isMapRender, tab, clientFilterAllTableData]);

	useEffect(() => {
		if (!initStateRef.current) {
			return;
		}

		if (
			(tab !== TabState.ALL ? clientFilterAllTableData : tableData).length > 0
		) {
			if (
				(tab !== TabState.ALL ? clientFilterAllTableData : tableData).length ===
				1
			) {
				if (mapRef.current) {
					mapRef.current.morph({
						lat: (tab !== TabState.ALL
							? clientFilterAllTableData
							: tableData)[0].lat,
						lng: (tab !== TabState.ALL
							? clientFilterAllTableData
							: tableData)[0].lng,
					});
				}
			} else {
				const board = (
					tab !== TabState.ALL ? clientFilterAllTableData : tableData
				).reduce(
					(acc, val) => {
						const lowLng =
							val.lng > val.managerLng && val.managerLng !== null
								? val.managerLng
								: val.lng;
						const lowLat =
							val.lat > val.managerLat && val.managerLat !== null
								? val.managerLat
								: val.lat;
						const highLng = val.lng < val.managerLng ? val.managerLng : val.lng;
						const highLat = val.lat < val.managerLat ? val.managerLat : val.lat;

						const getMinValue: (type: 'minX' | 'minY') => number = (
							type: 'minX' | 'minY',
						) => {
							if (type === 'minX') {
								if (acc.minX === 0) {
									return lowLng;
								}

								return lowLng < acc.minX ? lowLng : acc.minX;
							}
							if (type === 'minY') {
								if (acc.minY === 0) {
									return lowLat;
								}

								return lowLat < acc.minY ? lowLat : acc.minY;
							}

							return 0;
						};

						return {
							maxX: highLng > acc.maxX ? highLng : acc.maxX,
							minX: getMinValue('minX'),
							maxY: highLat > acc.maxY ? highLat : acc.maxY,
							minY: getMinValue('minY'),
						};
					},
					{
						maxX: 0,
						minX: 0,
						maxY: 0,
						minY: 0,
					},
				);

				if (mapRef.current) {
					if (!board.maxX || !board.minX || !board.maxY || !board.minY) {
						mapRef.current.morph({
							lat: board.maxY,
							lng: board.maxX,
						});
					} else {
						mapRef.current.panToBounds(
							board,
							{ duration: 500 },
							{ bottom: 200, left: 100, right: 100 },
						);
					}
				}
			}

			initStateRef.current = false;
		}
	}, [tab, clientFilterAllTableData, tableData]);

	useEffect(() => {
		infoWindowRef.current?.close();
		selectedMarkerRef.current = null;
		selectedServiceItemRef.current = null;
		managerSelectedMarkerRef.current = null;
		managerSelectedServiceItemRef.current = null;
		infoWindowRef.current = null;
	}, []);

	return (
		<Article>
			<MapContainer>
				<ControlSection style={{ position: 'relative' }}>
					<GridFilterForm
						formInitialValues={{
							requestedAt: [dayjs().subtract(7, 'day'), dayjs()],
						}}
						forms={searchInput}
						useQuerySave={false}
						onSearchSubmit={(value) => {
							setTab(TabState.ALL);
							setFilterValue(value);
							handleSubmitClick(value);
						}}
						$columnCount={4}
					/>
					<TotalCountContainer>
						<TabList>
							<Tab
								isActive={tab === TabState.ALL}
								value={TabState.ALL}
								label={`전체 ${tableData.length}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.REQUEST}
								value={TabState.REQUEST}
								label={`작업요청 ${getSubscriptionListCountViaStatus(
									'REQUESTED',
								)}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.ASSIGNED}
								value={TabState.ASSIGNED}
								label={`배정완료 ${getSubscriptionListCountViaStatus(
									'ASSIGNED',
								)}`}
								onClick={handleTabClick}
							/>
							<Tab
								isActive={tab === TabState.COMPLETED}
								value={TabState.COMPLETED}
								label={`작업완료 ${getSubscriptionListCountViaStatus(
									'COMPLETED',
								)}`}
								onClick={handleTabClick}
							/>
						</TabList>
						<DotList>
							<Dot $dotColor="danger_4">
								<Typo $typoType="hm9" className="dot-text">
									작업요청
								</Typo>
							</Dot>
							<Dot>
								<Typo $typoType="hm9" className="dot-text">
									배정완료
								</Typo>
							</Dot>
							<Dot $dotColor="gray_6">
								<Typo $typoType="hm9" className="dot-text">
									작업완료
								</Typo>
							</Dot>
						</DotList>
					</TotalCountContainer>
				</ControlSection>

				<MapWrapper>
					{isMapRender ? (
						<NaverMapContainer id="map" />
					) : (
						<Box>
							<ContentBoxWithHeader
								title="작업 목록"
								borderRadius="10px"
								className="inner-content-grid"
								innerClassName="grid-content-wrapper"
							>
								<div className="grid-content">
									<Grid
										rowData={
											tab !== TabState.ALL
												? clientFilterAllTableData
												: tableData
										}
										cols={circuitInspectionGridData.colsList}
										onRowScheduleClicked={handleDetailClick}
									/>
								</div>
								<PaginationContainer>
									<div className="grid-footer">
										<div className="grid-total-count">
											<Typo $typoType="hm8" color="gray_8">
												총
											</Typo>
											<Typo $typoType="hm8">
												{data && data.count ? data.count.toLocaleString() : 0}
											</Typo>
										</div>
										<div className="grid-pagination">
											<Pagination
												showSizeChanger
												onShowSizeChange={(page: number, pageSize: number) => {
													setCurrentPage(page);
													setCurrentPageSize(pageSize);
												}}
												onChange={(page: number, pageSize: number) => {
													setCurrentPage(page);
													setCurrentPageSize(pageSize);
												}}
												defaultCurrent={1}
												current={currentPage}
												defaultPageSize={50}
												pageSize={currentPageSize}
												total={data && data.count ? data.count : 0}
												locale={{ items_per_page: '개씩 보기' }}
											/>
										</div>
									</div>
								</PaginationContainer>
							</ContentBoxWithHeader>
						</Box>
					)}

					<ViewPointButton
						type={isMapRender ? 'list' : 'map'}
						className="view-point-button"
						onClick={() => setIsMapRender((prev) => !prev)}
					/>
				</MapWrapper>
			</MapContainer>
		</Article>
	);
}

export default ServiceScheduleManagement;

const Article = styled.article`
	display: flex;
	height: 100%;
`;

const MapContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
	padding-top: 30px;
`;

const NaverMapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const ControlSection = styled.section`
	padding: 0 32px;
	white-space: nowrap;
	box-shadow: 0px 16px 16px rgba(94, 101, 110, 0.1);
	z-index: 1;

	form {
		margin-bottom: 20px;
	}
`;

const MapWrapper = styled.div`
	flex: 1;
	position: relative;

	.view-point-button {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translate(-50%);
	}

	.content-wrapper {
		height: 100%;
	}
`;

const Box = styled.div`
	padding: 20px;
	border: 1px solid
		${({ theme: globalTheme }) => globalTheme.common.colors.gray_3};
	background: ${({ theme: globalTheme }) => globalTheme.common.colors.gray_1};
	display: flex;
	height: 100%;

	.inner-content-grid {
		display: flex;
		flex: 1;
		height: 100%;

		.grid-content-wrapper {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;

			div.grid-content {
				display: flex;
				flex: 1;
			}
		}
	}
`;

const TabList = styled.ul`
	display: flex;
	gap: 24px;
`;

const DotList = styled.ul`
	display: flex;
	gap: 20px;

	span.dot-text {
		padding-left: 6px;
	}
`;

const TotalCountContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
