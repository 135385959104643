import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import { FilterElementType } from '../../../utils/filter-util';
import { numberWithCommas } from '../../../utils/data-format';

const userData: GridDataModel = {
	formInitialValues: {
		duzonTransferRequestDate: [dayjs().add(-1, 'months'), dayjs()],
	},
	colsList: [
		{
			tableInfo: {
				field: 'duzonTransferRequestDate',
				headerName: '요청일',
			},
			options: {
				maxWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestBank',
				headerName: '은행코드',
			},
			options: {
				maxWidth: 100,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestBankName',
				headerName: '은행명',
			},
			options: {
				maxWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestAccount',
				headerName: '계좌번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestName',
				headerName: '계좌주',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestPrice',
				headerName: '금액',
			},
			options: {
				maxWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value ? numberWithCommas(value) : '';
				},
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestMemo',
				headerName: '메모',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferRequestStatus',
				headerName: '이체상태',
			},
			options: {
				maxWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'duzonTransferConfirmationDate',
				headerName: '이체확정일',
			},
			options: {
				maxWidth: ColSize.CW_SMALL_M,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionId',
				headerName: '접수번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
	],
	forms: [
		{
			key: 'duzonTransferRequestDate',
			label: '요청일',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
	],
};

export default userData;
