/* eslint-disable react/jsx-no-useless-fragment */
import React, { PropsWithChildren, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';
import { selectGridNoDataIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';

interface TabPaneProps {
	title: ReactNode;
	isPlaceHolder?: boolean;
	style?: CSSObject;
	placeHolder?: string;
}

const Article = styled.article<{ isPlaceHolder: boolean }>`
	display: flex;
	flex-direction: column;

	min-width: 480px;
	max-width: 480px;
	height: 100%;
	padding: 40px;

	span.tab-pane-title {
		${({ theme }) => theme.typo.h4};
	}
`;

const PlaceHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;

	.placeholder-description {
		color: ${({ theme }) => theme.common.colors.gray_8};
		padding-top: 8px;
	}
`;

function TabPane({
	children,
	title,
	isPlaceHolder = false,
	placeHolder = '선택된 내용이 없습니다',
	style,
}: PropsWithChildren<TabPaneProps>) {
	const renderTitle = () => {
		if (React.isValidElement(title)) {
			return title;
		}

		return <span className="tab-pane-title">{title}</span>;
	};

	return (
		<Article isPlaceHolder={isPlaceHolder} style={style}>
			{renderTitle()}
			{isPlaceHolder ? (
				<PlaceHolder>
					<Icon src={selectGridNoDataIcon} width={56} height={56} />
					<Typo $typoType="b7" className="placeholder-description">
						{placeHolder}
					</Typo>
				</PlaceHolder>
			) : (
				<>{children}</>
			)}
		</Article>
	);
}

export default TabPane;
