import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Radio, RadioChangeEvent, Space } from 'antd';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../atoms/Typo';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import {
	UserType,
	CreateDepartmentDto,
	DepartmentService,
} from '../../../interface/department';
import { useCreateDepartmentMutation } from '../../../store/apis/department';
import { useGetCompaniesQuery } from '../../../store/apis/company';
import useCompaniesOption from '../../../hooks/useCompaniesOption';
import { LabelValue } from '../../../utils/const';
import { companyModalColumn } from '../modalColumn';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';
import InputForm from '../../molecules/InputForm';
import Select from '../../atoms/Select';
import SelectionModal from '../../organisms/SelectionModal';

interface DepartmentModalProps {
	userType: UserType;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

function DepartmentModal({ userType }: DepartmentModalProps) {
	const [createDepartment] = useCreateDepartmentMutation();
	const [customDepartmentType, setCustomDepartmentType] =
		useState<UserType>(userType);
	const [isCompanyModal, setIsCompanyModal] = useState<boolean>(false);

	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const companiesOptionList: LabelValue[] = useCompaniesOption();

	const departmentServiceMap: { [key: string]: DepartmentService } = {
		ADMIN: 'BOS_WEB',
		MANAGER: 'MANAGER_APP',
		CUSTOMER: 'CUSTOMER_APP',
	};

	const initialValues: CreateDepartmentDto = {
		service: departmentServiceMap[customDepartmentType as UserType],
		departmentName: '',
		companyId: 0,
	};

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleSubmit = async (values: typeof initialValues) => {
		const result = await createDepartment(values as CreateDepartmentDto);
		dispatch(fullLoadingOn());

		if (result && !('error' in result)) {
			setToast('info', '소속 등록이 완료되었습니다.');
			handleModalClose();
		} else {
			setToast(
				'error',
				(result?.error as CustomErrorType).data.translate ||
					(result?.error as CustomErrorType).data.message,
			);
		}

		dispatch(fullLoadingOff());
	};

	const formik = useFormik({ initialValues, onSubmit: handleSubmit });

	return (
		<>
			<Button
				size="small"
				$buttonType={ButtonTypeType.PRIMARY_LIGHT}
				onClick={handleModalOpen}
			>
				소속 등록
			</Button>
			<Modal
				title={<ModalTitle>소속 등록</ModalTitle>}
				open={isModalOpen}
				onCancel={handleModalClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleModalClose}
						>
							닫기
						</Button>
						<Button
							disabled={
								!(formik.values.departmentName && formik.values.companyId)
							}
							onClick={() => formik.handleSubmit()}
						>
							완료
						</Button>
					</ModalFooterWrapper>
				}
			>
				<InnerContent>
					{userType === 'CUSTOMER' || (
						<InputForm>
							<Label name="departmentType" essential>
								<span className="label">
									<Typo $typoType="label2">관리자 분류</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setCustomDepartmentType(e.target.value);
									formik.setFieldValue(
										'service',
										departmentServiceMap[e.target.value as UserType],
									);
								}}
								value={customDepartmentType}
							>
								<Radio value="ADMIN">
									<Typo $typoType="label1">오픈마일 관리자</Typo>
								</Radio>
								<Radio value="MANAGER">
									<Typo $typoType="label1">매니저 관리자</Typo>
								</Radio>
								<Radio value="CUSTOMER">
									<Typo $typoType="label1">법인 관리자</Typo>
								</Radio>
							</Radio.Group>
						</InputForm>
					)}
					<InputForm>
						<Label name="companyName" essential>
							<span className="label">
								<Typo $typoType="label2">소속의 법인</Typo>
							</span>
						</Label>
						<Space>
							<Button size="small" onClick={() => setIsCompanyModal(true)}>
								법인 선택
							</Button>
						</Space>
						<Select
							placeholder="법인을 선택해주세요"
							value={formik.values.companyId}
							disabled
							onChange={(e) => {
								formik.setFieldValue('companyId', e);
							}}
						>
							{companiesOptionList.map((item, idx) => (
								<Select.Option value={item.value} key={idx.toString()}>
									{item.label}
								</Select.Option>
							))}
						</Select>
					</InputForm>
					<InputForm>
						<Label name="departmentName" essential>
							<span className="label">
								<Typo $typoType="label2">소속명</Typo>
							</span>
						</Label>
						<Input
							name="departmentName"
							placeholder="새롭게 만들 소속명을 입력해 주세요"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							onChange={(e) =>
								formik.setFieldValue('departmentName', e.target.value)
							}
							onReset={() => formik.setFieldValue('departmentName', '')}
							value={formik.values.departmentName}
						/>
					</InputForm>
				</InnerContent>
			</Modal>
			{isCompanyModal && (
				<SelectionModal
					onClose={() => setIsCompanyModal(false)}
					onChange={(e) => formik.setFieldValue('companyId', e[0].id)}
					column={companyModalColumn.colsList}
					useQuery={useGetCompaniesQuery}
					searchInput={companyModalColumn.forms}
					defaultSelectedIdList={[formik.values.companyId]}
					isVisible={isCompanyModal}
					title="법인 등록"
				/>
			)}
		</>
	);
}

export default DepartmentModal;
