import { authApiSlice } from '../query';

interface RegionInfo {
	regionDepth1: string;
	regionDepth2: string;
	regionDepth3: string;
	zipCode: string | null;
	country: string;
	detail: string | null;
	street: string | null;
	zibun: string;
	lat: string | null;
	lng: string | null;
}

const coordinateApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCoordinateDetailRegion: builder.query<
			RegionInfo,
			{ lat: string; lng: string }
		>({
			query: ({ lat, lng }) => {
				return { url: `/core/coordinate-detail-region`, params: { lat, lng } };
			},
			transformResponse: (response: { row: RegionInfo }) => response.row,
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetCoordinateDetailRegionQuery } = coordinateApiSlice;
