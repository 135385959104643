import {
	TermsDto,
	GenerateTermsDto,
	ModifyTermsDto,
} from '../../../interface/terms';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const termsApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getTerms: builder.query<ListResponse<TermsDto>, Record<any, any>>({
			query: (args) => ({
				url: `/core/terms`,
				params: args,
			}),
		}),
		createTerm: builder.mutation<ObjectResponse<TermsDto>, GenerateTermsDto>({
			query: (body) => ({
				method: 'POST',
				url: `/core/terms`,
				body,
			}),
		}),
		updateTerm: builder.mutation<
			ObjectResponse<TermsDto>,
			{
				id: number;
				body: ModifyTermsDto;
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/core/terms/${id}`,
				body,
			}),
		}),
		deleteTerm: builder.mutation<
			ObjectResponse<TermsDto>,
			{
				id: number;
			}
		>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `/core/terms/${id}`,
			}),
		}),
	}),
});

export const {
	useGetTermsQuery,
	useCreateTermMutation,
	useUpdateTermMutation,
	useDeleteTermMutation,
} = termsApi;
export default termsApi;

export const getTermsDetailViaId = async (id: number) => {
	const url = `/core/terms/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<TermsDto>>(url);

	return data.row;
};

export const getTermsTypeList = async () => {
	const url = '/core/terms/type';

	const { data } = await axiosInstance.get(url);

	return data.rows;
};
