import { VehicleHistoryChange } from '../interface/vehicle';
import { ContractUpdatedHistory } from '../interface/vehicleSubscription';

const sortedColsList = (
	rowData:
		| Partial<VehicleHistoryChange>[]
		| Partial<ContractUpdatedHistory>[]
		| undefined,
	colsList: { tableInfo: { field: string } }[],
	fixedColumnsKeys: string[],
) => {
	if (rowData) {
		const sortedColumns = [...colsList];

		const fixedColumns = sortedColumns.filter((column) =>
			fixedColumnsKeys.includes(column.tableInfo.field),
		);
		const remainingColumns = sortedColumns.filter(
			(column) => !fixedColumnsKeys.includes(column.tableInfo.field),
		);

		// 데이터가 있는 컬럼
		const dataColumns = remainingColumns.filter((column) => {
			const { field } = column.tableInfo;

			const dataExists = rowData?.some((row) => {
				const value = row.payload?.[field];
				return value !== undefined && value !== '';
			});

			return dataExists;
		});

		// // 데이터가 없는 컬럼
		const noDataColumns = remainingColumns.filter((column) => {
			const { field } = column.tableInfo;
			const noDataExists = !rowData?.some((row) => {
				const value = row.payload?.[field];
				return value !== undefined && value !== '';
			});

			return noDataExists;
		});

		const sortedRemainingColumns = dataColumns.concat(noDataColumns);

		// console.log('데이터가 있는 컬럼', dataColumns);
		// console.log('데이터 X 컬럼', noDataColumns);

		return { colsList: fixedColumns.concat(sortedRemainingColumns) };
	}
	return { colsList: [] };
};

export default sortedColsList;
