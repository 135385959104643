import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ContentBoxItemProps {
	className?: string;
}

const ContentBoxItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 830px;
	margin: 32px auto 0 auto;
	gap: 12px;
`;

function ContentBoxItem({
	children,
	className,
}: PropsWithChildren<ContentBoxItemProps>) {
	return (
		<ContentBoxItemContainer className={className}>
			{children}
		</ContentBoxItemContainer>
	);
}

export default ContentBoxItem;
