import React from 'react';
import { ICellRendererParams, RowSelectedEvent } from 'ag-grid-community';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FilterElementType, FormOption } from './filter-util';
import { TableColumnOptions } from './grid-util';
import { productDefaultImage } from '../assets/images';
import { ALL_OPTION_VALUE, LabelValue } from './const';
import FilterSelect from '../components/atoms/FilterSelect';
import Button from '../components/atoms/Button';
import theme, { ButtonTypeType } from '../styles/theme';
import Typo from '../components/atoms/Typo';
import Badge, { BadgeColorType } from '../components/atoms/Badge';
import { SubscriptionItemDto } from '../interface/subscriptionItem';

export interface RowDataInfo {
	key: string;
	label: string;
	type: FilterElementType;
	rowOptions: TableColumnOptions;
	filterOptions: FormOption[];
	filteringKeys: string[];
}

export const getFormInitialValues = (filteringKeys: string[]) => {
	const formInitialValues: Record<string, string> = {};

	filteringKeys.forEach((key) => {
		formInitialValues[key] = '';
	});

	return formInitialValues;
};

export const getForms = (rowDataList: RowDataInfo[]) =>
	rowDataList.map((rowData) => ({
		key: rowData.key,
		label: rowData.label,
		type: rowData.type,
	}));

export const getColsList = (rowDataList: RowDataInfo[]) =>
	rowDataList.map((rowData) => ({
		tableInfo: {
			filed: rowData.key,
			headerName: rowData.label,
		},
	}));

/* 테이블 내 아이템 추가 시 여기에 추가 */
const RenderCellContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;

	.prefix-img {
		width: 32px;
		height: 32px;
		margin-right: 12px;
	}

	.select-cell {
		height: 100%;
	}
`;

// CELL_TYPE_DETAIL_ACTION : 상세보기
export const CELL_TYPE_DETAIL_ACTION = 'CELL_TYPE_DETAIL_ACTION';
export const renderDetailAction = (
	onClick?: (id: RowSelectedEvent) => void,
	detailKey?: string,
) => {
	return (props: ICellRendererParams) => {
		const { data } = props;

		return (
			<RenderCellContainer>
				<Button
					$buttonType={ButtonTypeType.GHOST}
					size="x-small"
					onClick={() => {
						if (detailKey && onClick) {
							onClick(data[detailKey]);
						} else if (onClick && data.id) {
							onClick(data.id);
						} else if (onClick && data.subscriptionNo)
							onClick(data.subscriptionNo);
					}}
				>
					상세보기
				</Button>
			</RenderCellContainer>
		);
	};
};
export const createDetailAction = (
	onClick?: (id: RowSelectedEvent<any>) => void,
	detailKey?: string,
) => {
	return {
		[CELL_TYPE_DETAIL_ACTION]: renderDetailAction(onClick, detailKey),
	};
};

// CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION : 작업현황 상세보기
export const CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION =
	'SCHEDULE_MANAGEMENT_DETAIL_ACTION';
export const renderScheduleManagementDetailAction = (
	onClick?: (id: RowSelectedEvent) => void,
) => {
	return (props: ICellRendererParams) => {
		const { data } = props;

		return (
			<RenderCellContainer>
				<Button
					// disabled={data.productType === TIRE_REPLACEMENT}
					$buttonType={ButtonTypeType.GHOST}
					size="x-small"
					onClick={() => {
						if (onClick && data) {
							onClick(data);
						}
					}}
				>
					상세보기
				</Button>
			</RenderCellContainer>
		);
	};
};

export const createScheduleManagementDetailAction = (
	onClick?: (id: RowSelectedEvent) => void,
) => {
	return {
		[CELL_TYPE_SCHEDULE_MANAGEMENT_DETAIL_ACTION]:
			renderScheduleManagementDetailAction(onClick),
	};
};

// CELL_TYPE_IMAGE_TEXT : 이미지 + 텍스트
export const CELL_TYPE_IMAGE_TEXT = 'CELL_TYPE_IMAGE_TEXT';
export const renderImageText = (srcFiled: string, isArray?: boolean) => {
	return (props: ICellRendererParams) => {
		const { data, value } = props;

		return (
			<RenderCellContainer>
				<img
					className="prefix-img"
					src={
						isArray ? data[srcFiled][0] : data[srcFiled] || productDefaultImage
					}
					alt="상세 이미지"
				/>{' '}
				{value || '-'}
			</RenderCellContainer>
		);
	};
};
export const createImageTextCellRenderer = (
	srcFiled: string,
	isArray?: boolean,
) => {
	return {
		[CELL_TYPE_IMAGE_TEXT]: renderImageText(srcFiled, isArray),
	};
};

// CELL_TYPE_SELECT
export const CELL_TYPE_SELECT = 'CELL_TYPE_SELECT';
export const renderSelect = (
	options: LabelValue[],
	onChange: ({ id, state }: { id: number; state: string }) => void,
) => {
	return (props: ICellRendererParams) => {
		const { data, value } = props;

		return (
			<RenderCellContainer>
				<FilterSelect
					className="select-cell"
					onClick={(e) => {
						e.stopPropagation();
					}}
					onChange={(e) => {
						onChange({ id: data.id, state: e.target.value });
					}}
					options={options.filter((item) => item.value !== ALL_OPTION_VALUE)}
					value={value}
				/>
			</RenderCellContainer>
		);
	};
};
export const createSelectRenderer = ({
	options,
	onChange,
}: {
	options: LabelValue[];
	onChange: ({ id, state }: { id: number; state: string }) => void;
}) => ({
	[CELL_TYPE_SELECT]: renderSelect(options, onChange),
});

// CELL_TYPE_OPTIONS_BULLET_TEXT
interface OptionsTextCell {
	label: string;
	value: string | boolean | null;
	color: keyof typeof theme.common.colors;
}
const OptionText = styled.p<{ color: keyof typeof theme.common.colors }>`
	color: ${({ theme: globalTheme, color }) => globalTheme.common.colors[color]};
`;

export const CELL_TYPE_OPTIONS_BULLET_TEXT = 'CELL_TYPE_OPTIONS_BULLET_TEXT';
export const renderOptionsBulletText = (options: OptionsTextCell[]) => {
	return (props: ICellRendererParams) => {
		const { value } = props;
		const viewItem = options.find((option) => option.value === value);

		return (
			<RenderCellContainer>
				{viewItem?.label ? (
					<Typo $typoType="b6" color={viewItem.color}>
						&#8226; {viewItem.label}
					</Typo>
				) : (
					'-'
				)}
			</RenderCellContainer>
		);
	};
};

export const createOptionsBulletTextCellRender = (
	options: OptionsTextCell[],
) => ({
	[CELL_TYPE_OPTIONS_BULLET_TEXT]: renderOptionsBulletText(options),
});

// CELL_TYPE_OPTIONS_TEXT
export const CELL_TYPE_OPTIONS_TEXT = 'CELL_TYPE_OPTIONS_TEXT';
export const renderOptionsText = (options: OptionsTextCell[]) => {
	return (props: ICellRendererParams) => {
		const { value } = props;
		const viewItem = options.find((option) => option.value === value);

		return (
			<RenderCellContainer>
				{viewItem?.label ? (
					<OptionText color={viewItem.color}>
						<Typo $typoType="b6">{viewItem.label}</Typo>
					</OptionText>
				) : (
					'-'
				)}
			</RenderCellContainer>
		);
	};
};
export const createOptionsTextCellRender = (options: OptionsTextCell[]) => ({
	[CELL_TYPE_OPTIONS_TEXT]: renderOptionsText(options),
});

// CELL_TYPE_DATE : date 타입
export const CELL_TYPE_DATE = 'CELL_TYPE_DATE';
export const renderDate = (format: string) => {
	return (props: ICellRendererParams) => {
		const { value } = props;

		return (
			<RenderCellContainer>{dayjs(value).format(format)}</RenderCellContainer>
		);
	};
};
export const createDateCellRender = (format = 'YYYY-MM-DD HH:mm:ss') => ({
	[CELL_TYPE_DATE]: renderDate(format),
});

// CELL_TYPE_PRICE_TEXT : 금액 표기
export const CELL_TYPE_PRICE_TEXT = 'CELL_TYPE_PRICE_TEXT';
export const renderPriceText = () => {
	return (props: ICellRendererParams) => {
		const { value } = props;

		return (
			<RenderCellContainer>
				{Number(value).toLocaleString()}원
			</RenderCellContainer>
		);
	};
};
export const createPriceTextCellRender = () => {
	return {
		[CELL_TYPE_PRICE_TEXT]: renderPriceText(),
	};
};

export const CELL_TYPE_RADIO = 'CELL_TYPE_RADIO';
export const renderRadio = (
	onChange: (data: any) => void,
	defaultValue?: string[],
) => {
	return (value: ICellRendererParams) => {
		const { data, colDef } = value;

		let checked;

		if (defaultValue?.length) {
			checked = defaultValue?.includes(data?.id);
		}

		return (
			<input
				type="radio"
				className="radio-input"
				name={colDef?.field}
				onChange={() => onChange(data)}
				defaultChecked={checked}
			/>
		);
	};
};
export const createRadioCellRender = (
	onChange: (data: any) => void,
	defaultValue?: string[],
) => {
	return {
		[CELL_TYPE_RADIO]: renderRadio(onChange, defaultValue),
	};
};

// CELL_TYPE_BADGE_TEXT : 뱃지
interface BadgeTextCell {
	label: string;
	value: string | boolean | null;
	color: BadgeColorType;
}
export const CELL_TYPE_BADGE_TEXT = 'CELL_TYPE_BADGE_TEXT';
export const renderBadgeText = (options: BadgeTextCell[]) => {
	return (props: ICellRendererParams) => {
		const { value } = props;
		const viewItem = options.find((option) => option.value === value);

		return (
			<RenderCellContainer>
				{viewItem?.label ? (
					<Badge color={viewItem.color}>{viewItem.label}</Badge>
				) : (
					'-'
				)}
			</RenderCellContainer>
		);
	};
};
export const createBadgeTextCellRender = (options: BadgeTextCell[]) => {
	return {
		[CELL_TYPE_BADGE_TEXT]: renderBadgeText(options),
	};
};

export const renderSubscriptionItemStatusCell = ({
	value,
}: ICellRendererParams) => {
	let content;
	let badgeColor: BadgeColorType;

	switch (value) {
		case 'REQUESTED':
			content = '작업요청';
			badgeColor = 'red';
			break;
		case 'ASSIGNED':
			content = '배정완료';
			badgeColor = 'blue';
			break;
		case 'COMPLETED':
			content = '작업완료';
			badgeColor = 'gray';
			break;

		default:
			content = '-';
			badgeColor = 'blue';
			break;
	}
	return <Badge color={badgeColor}>{content}</Badge>;
};

export const renderManagerStatusCell = ({
	data,
	value,
}: ICellRendererParams<SubscriptionItemDto>) => {
	let content = '-';
	let color: keyof typeof theme.common.colors = 'primary_6_main';

	if (data?.subscriptionItemStatus === 'REQUESTED') {
		return (
			<Typo $typoType="b6" color="gray_11">
				-
			</Typo>
		);
	}

	switch (value) {
		case 'PENDING':
			content = '작업전';
			color = 'warning_6';
			break;
		case 'STARTED':
			content = '작업중';
			color = 'primary_6_main';
			break;
		case 'COMPLETE':
			content = '작업완료';
			color = 'gray_11';
			break;
		case 'CONFIRMED':
			content = '작업완료 확정';
			color = 'gray_11';
			break;

		default:
			return (
				<Typo $typoType="b6" color="gray_11">
					-
				</Typo>
			);
	}
	return (
		<Typo $typoType="b6" color={color}>
			&#8226; {content}
		</Typo>
	);
};

export const renderProductTypeCell = ({ value }: ICellRendererParams) => {
	switch (value) {
		case 'REGULAR_INSPECTION':
			return '순회점검';
		case 'TIRE_REPLACEMENT':
			return '타이어 교체';
		case 'INSPECTION_AGENCY':
			return '검사대행';
		case 'EMERGENCY_RUSH':
			return '긴급출동';
		default:
			return '-';
	}
};
