import React, { PropsWithChildren, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ToastContext } from '../../../contexts/Toast';
import { RootState } from '../../../store';
import { CustomErrorType } from '../../../store/apis/@types';
import { usePasswordMutation } from '../../../store/apis/password';
import { getDefaultPathUrl, setUser } from '../../../store/auth';
import { formatPassword } from '../../../utils/data-format';

import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import InputAlert, { InputAlertMessage } from '../../atoms/InputAlert';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import LoginInputForm from '../../molecules/LoginInputForm';
import { asideMenuList } from '../../organisms/Aside/data';
import FormLayout from '../../organisms/LoginFormLayout';

interface PasswordResetTemplateProps {
	title: string;
}

const PasswordResetTemplateContainer = styled.main`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;

	.title {
		margin-bottom: 40px;
	}

	.password-reset-notice {
		margin-left: 20px;
		margin-top: 6px;
		color: ${({ theme }) => theme.common.colors.gray_8};
		list-style: disc;
	}

	.button-container {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
	}

	.label {
		display: inline-block;
		margin-bottom: 6px;
	}
`;

function PasswordResetTemplate({
	title,
}: PropsWithChildren<PasswordResetTemplateProps>) {
	const toast = useContext(ToastContext);
	const [userPassword, setUserPassword] = useState('');
	const [userPasswordCheck, setUserPasswordCheck] = useState('');
	const [password] = usePasswordMutation();
	const { userTemp } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();

	const passwordReconfirm =
		formatPassword(userPasswordCheck) && userPassword === userPasswordCheck;

	const getUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserPassword(event?.target.value);
	};

	const getUserPasswordCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserPasswordCheck(event?.target.value);
	};

	const passwordOnReset = () => {
		setUserPassword('');
	};

	const passwordCheckOnReset = () => {
		setUserPasswordCheck('');
	};

	const goToMain = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (!userTemp) return;

		const res = await password({
			administratorApplicationId: userTemp.administratorApplicationId as string,
			newPassword: userPasswordCheck,
		});

		if (!('error' in res)) {
			toast('info', '비밀번호 변경이 완료되었습니다.');
			dispatch(setUser(userTemp));
			window.location.replace(
				getDefaultPathUrl(asideMenuList, userTemp.role.roleItems),
			);
		}

		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	return (
		<PasswordResetTemplateContainer>
			<h1 className="title">
				<Typo $typoType="h1">{title}</Typo>
			</h1>
			<FormLayout
				formTitle={
					<h2>
						<Typo $typoType="btn1">
							임시 비밀번호로 로그인하였습니다
							<br /> 새로운 비밀번호를 설정해 주세요
						</Typo>
					</h2>
				}
			>
				<LoginInputForm>
					<Label name="userPassword" essential>
						<span className="label">
							<Typo $typoType="label2">변경 비밀번호</Typo>
						</span>
					</Label>
					<Input
						name="userPassword"
						placeholder="비밀번호를 입력해 주세요"
						type="password"
						$inputSize="large"
						$inputRadius="large"
						value={userPassword}
						onChange={getUserPassword}
						onReset={passwordOnReset}
						$inputStatus={
							(userPassword.length === 0 && 'normal') ||
							(formatPassword(userPassword) ? 'success' : 'error')
						}
					/>
					{userPassword && (
						<InputAlert
							$inputStatus={
								(userPassword.length === 0 && 'normal') ||
								formatPassword(userPassword)
									? 'success'
									: 'error'
							}
						>
							<p>
								<Typo
									$typoType="label3"
									color={
										formatPassword(userPassword)
											? 'success_5_main'
											: 'danger_5_main'
									}
								>
									{formatPassword(userPassword)
										? InputAlertMessage.success
										: InputAlertMessage.error}
								</Typo>
							</p>
						</InputAlert>
					)}
				</LoginInputForm>

				<LoginInputForm>
					<Label name="userPasswordCheck" essential>
						<span className="label">
							<Typo $typoType="label2">비밀번호 재확인</Typo>
						</span>
					</Label>
					<Input
						name="userPasswordCheck"
						placeholder="비밀번호를 입력해 주세요"
						type="password"
						$inputSize="large"
						$inputRadius="large"
						value={userPasswordCheck}
						onChange={getUserPasswordCheck}
						onReset={passwordCheckOnReset}
						$inputStatus={
							(userPasswordCheck.length === 0 && 'normal') ||
							(passwordReconfirm ? 'success' : 'error')
						}
					/>
					{userPasswordCheck && (
						<InputAlert $inputStatus={passwordReconfirm ? 'success' : 'error'}>
							<Typo
								$typoType="label3"
								color={passwordReconfirm ? 'success_5_main' : 'danger_5_main'}
							>
								{passwordReconfirm
									? InputAlertMessage.success
									: InputAlertMessage.error}
							</Typo>
						</InputAlert>
					)}
				</LoginInputForm>

				<ul>
					<p>
						<Typo $typoType="label2">비밀번호를 만들 때는...</Typo>
					</p>
					<li className="password-reset-notice">
						<Typo $typoType="b7" color="gray_8">
							영문 대소문자, 숫자, 특수문자(@$!%*?&)를 모두 조합하여 10~20자로
							만들어주세요.
						</Typo>
					</li>
				</ul>

				<div className="button-container">
					<Button
						size="large"
						$isFull
						$textCenter
						disabled={!passwordReconfirm}
						onClick={goToMain}
						type="submit"
					>
						비밀번호 변경
					</Button>
				</div>
			</FormLayout>
		</PasswordResetTemplateContainer>
	);
}

export default PasswordResetTemplate;
