import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '../../atoms/Modal';
import Typo from '../../atoms/Typo';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import { grayNoticeIcon } from '../../../assets/icon';
import SelectionGrid from '../../atoms/SelectionGrid';
import assignManagerModalGridData from './assignManagerModalGridData';
import { createRadioCellRender } from '../../../utils/row-data-util';
import { SubscriptionItemDto } from '../../../interface/subscriptionItem';
import { getManagerListViaNearest } from '../../../store/apis/manager';
import { ToastContext } from '../../../contexts/Toast';
import { FindManagerOrderedByDistanceResponseDto } from '../../../interface/manager';
import Alert from '../../atoms/Alert';
import { useSubscriptionItemManagerUpdateMutation } from '../../../store/apis/subscriptionItem';
import { CustomErrorType } from '../../../store/apis/@types';
import { popupUpdateHandler } from '../../../store/popup';

interface AssignManagerModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultManagerIdList?: string[];
	selectedItem: SubscriptionItemDto;
	defaultAsignedAt: string;
}

const StyledModal = styled(Modal)`
	.grid-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 16px;
	}
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const HeaderGrayBox = styled.div`
	background: ${({ theme }) => theme.common.colors.gray_1};
	border-radius: 12px;
	padding: 20px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: column;

	p.gray-box-sub-text {
		display: flex;
		align-items: center;
		padding-top: 16px;
	}
`;

function AssignManagerModal({
	isVisible,
	onClose,
	onRefetch,
	defaultManagerIdList,
	selectedItem,
	defaultAsignedAt,
}: AssignManagerModalProps) {
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedManager, setSelectedManager] =
		useState<FindManagerOrderedByDistanceResponseDto | null>(null);
	const [data, setData] = useState<FindManagerOrderedByDistanceResponseDto[]>(
		[],
	);
	const [alertVisible, setAlertVisble] = useState(false);

	const [updateManager] = useSubscriptionItemManagerUpdateMutation();

	const handelSubmit = async () => {
		if (!selectedManager) return;
		setAlertVisble(false);

		const res = await updateManager({
			id: selectedItem.subscriptionItemNo,
			body: {
				managerId: selectedManager.managerId,
				expectationVisitedAt: defaultAsignedAt,
			},
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '매니저 배정이 완료되었습니다.');
		}

		await dispatch(popupUpdateHandler());
		onRefetch();
		onClose();
	};

	const handelSubmitClick = () => {
		if (!selectedManager && defaultManagerIdList) {
			setToast('error', '같은 매니저로 배정할 수 없습니다.');
			return;
		}
		if (!selectedManager) {
			setToast('error', '매니저를 선택해주세요.');
			return;
		}

		setAlertVisble(true);
	};

	const getData = useCallback(async () => {
		const param = {
			lat: selectedItem.lat,
			lng: selectedItem.lng,
			page,
			pageSize,
			requestedDate: dayjs(defaultAsignedAt).format('YYYY-MM-DD'),
		};
		try {
			const { rows, count } = await getManagerListViaNearest(param);

			setData(rows.map((item) => ({ ...item, id: String(item.managerId) })));
			setTotalCount(count || 0);
		} catch (e) {
			setData([]);
			setTotalCount(0);
			const err = e as AxiosError<{ message: string; translate: string }>;
			if (err.response)
				setToast(
					'error',
					err.response.data.translate || err.response.data.message,
				);
		}
	}, [page, pageSize, selectedItem, setToast, defaultAsignedAt]);

	useEffect(() => {
		getData();
	}, [getData]);

	const renderContent = () => {
		return (
			<div style={{ height: '470px' }}>
				<SelectionGrid
					type="radio"
					column={assignManagerModalGridData.colsList}
					rowData={data}
					// frameworkComponents={{
					// 	...createRadioCellRender((value) => {
					// 		setSelectedManager(value);
					// 	}, defaultManagerIdList),
					// }}
					components={createRadioCellRender((value) => {
						setSelectedManager(value);
					}, defaultManagerIdList)}
				/>
			</div>
		);
	};

	return (
		<>
			<StyledModal
				title={<Typo $typoType="h2">매니저 배정</Typo>}
				open={isVisible}
				onCancel={onClose}
				width={980}
				style={{
					top: '50px',
				}}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button $buttonType={ButtonTypeType.OPAQUE} onClick={onClose}>
							닫기
						</Button>
						<Button onClick={handelSubmitClick}>배정하기</Button>
					</ModalFooterWrapper>
				}
			>
				<HeaderGrayBox>
					<Typo $typoType="h4" color="gray_11">
						배정 일시{' '}
						{defaultAsignedAt
							? dayjs(defaultAsignedAt).format('YYYY.MM.DD(dd) A hh:mm')
							: '-'}
					</Typo>
					<p className="gray-box-sub-text">
						<Icon src={grayNoticeIcon} width={20} height={20} />
						<Typo $typoType="hm9" color="gray_9">
							직경 거리순으로 노출됩니다
						</Typo>
					</p>
				</HeaderGrayBox>

				{renderContent()}

				<div className="grid-footer">
					<div>총 {totalCount.toLocaleString()}</div>
					<div className="grid-pagination">
						<Pagination
							showSizeChanger
							onShowSizeChange={() => {}}
							onChange={(changePage, changePageSize) => {
								setPage(changePage);
								setPageSize(changePageSize);
							}}
							defaultCurrent={page}
							current={page}
							defaultPageSize={10}
							pageSize={pageSize}
							total={totalCount}
						/>
					</div>
				</div>
			</StyledModal>
			{alertVisible && (
				<Alert
					title="매니저 배정"
					closeButtonClick={() => setAlertVisble(false)}
					onConfirmButtonText="배정하기"
					onConfirmButtonClick={handelSubmit}
					isVisible={alertVisible}
				>
					매니저를 배정하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default AssignManagerModal;
