import React from 'react';
import { useSelector } from 'react-redux';
import { MakeVehiclePlateDto } from '../../../../interface/vehiclePlate';
import VehiclePlateTemplate from '../../../../components/templates/VehiclePlateTemplate';
import { RootState } from '../../../../store';

const VehiclePlateCreate = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const companyId = user?.company?.id;

	const initialValues: MakeVehiclePlateDto = {
		vehicleNumber: '',
		vehiclePlateType: '영업용',
		companyId: companyId ?? null,
	};

	return <VehiclePlateTemplate initialValues={initialValues} />;
};

export default VehiclePlateCreate;
