import {
	AnyMobilityDTGInfo,
	VelocityRange,
} from '../interface/vehicleConditions';

export default function generatePathsAndPreviousSegment(
	paths: AnyMobilityDTGInfo[],
	velocityRanges: VelocityRange[],
) {
	if (!paths || paths.length === 0) {
		return { pathsWithPreviousSegment: [] };
	}

	function calculateVelocityRange(velocity: number) {
		if (velocity === 0) {
			return 'A';
		}
		const matched = velocityRanges.find(
			(range) => velocity >= range.minVelocity && velocity <= range.maxVelocity,
		);
		return matched?.range ?? '';
	}

	const pathsWithPreviousSegment: (AnyMobilityDTGInfo & {
		velocityRange: string;
	})[][] = [];
	let currentSegment: (AnyMobilityDTGInfo & { velocityRange: string })[] = [];
	let prevVelocityRange: string = calculateVelocityRange(paths[0].v);

	paths.forEach((path: AnyMobilityDTGInfo) => {
		const velocityRange = calculateVelocityRange(path.v);
		const pathWithVelocityRange = { ...path, velocityRange };

		currentSegment.push(pathWithVelocityRange);

		// 이전 속도와 다르면 새로운 세그먼트 시작
		if (prevVelocityRange !== velocityRange) {
			// 먼저 currentSegment를 pathsWithPreviousSegment 배열에 추가하고 currentSegment를 현재 path로 초기화
			pathsWithPreviousSegment.push([...currentSegment]);
			currentSegment = [pathWithVelocityRange];
		}

		prevVelocityRange = velocityRange;
	});

	return { pathsWithPreviousSegment };
}

export const velocityRanges = [
	{
		color: '#42cb30',
		range: 'A',
		minVelocity: 0,
		maxVelocity: 19,
	},
	{
		color: '#70e61c',
		range: 'B',
		minVelocity: 20,
		maxVelocity: 39,
	},
	{
		color: '#f2ec36',
		range: 'C',
		minVelocity: 40,
		maxVelocity: 59,
	},
	{
		color: '#f3a15a',
		range: 'D',
		minVelocity: 60,
		maxVelocity: 79,
	},
	{
		color: '#f5150d',
		range: 'E',
		minVelocity: 80,
		maxVelocity: 99,
	},
	{
		color: '#a1110c',
		range: 'F',
		minVelocity: 100,
		maxVelocity: 300,
	},
].map((item, index, arr) => ({
	...item,
	text:
		index !== arr.length - 1
			? `${item.minVelocity} ~ ${item.maxVelocity} km/h`
			: `${item.minVelocity} km/h 이상`,
}));
