import dayjs from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../../utils/row-data-util';
import { FilterElementType } from '../../../../../utils/filter-util';
import {
	companyRegisterNumberFormat,
	phoneFormat,
} from '../../../../../utils/data-format';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '가입일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'name',
				headerName: '법인명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'businessLicenseNumber',
				headerName: '사업자번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return companyRegisterNumberFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'administratorApplicationId',
				headerName: '관리자 아이디',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ data }: ICellRendererParams) => {
					return data.admin?.administratorApplicationId ?? '-';
				},
				valueGetter: ({ data }) => {
					return data.admin?.administratorApplicationId ?? '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'representativePhoneNumber',
				headerName: '대표번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'contactName',
				headerName: '담당자 이름',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'contactPhoneNumber',
				headerName: '담당자 연락처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'createdAt',
			label: '가입일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
	],
};

export default userData;
