import { axiosInstance } from '../../../network/axios';
import { InquiryDto, InquiryRequestParams } from '../../../interface/inquiry';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const inquiryApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateInquiryResponse: builder.mutation<
			ObjectResponse<InquiryDto>,
			{ id: number | string; body: { reply: string } }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/members/admin/inquiries/${id}`,
				body,
			}),
		}),
		deleteInquiry: builder.mutation<any, { id: number }>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `/members/admin/inquiries/this/reply/${id}`,
			}),
		}),
	}),
});

export const { useUpdateInquiryResponseMutation, useDeleteInquiryMutation } =
	inquiryApiSlice;

export const getInquiriesList = async (params: InquiryRequestParams) => {
	const url = `/members/admin/inquiries`;

	const { data } = await axiosInstance.get<ListResponse<InquiryDto>>(url, {
		params,
	});

	return data.rows;
};

export const getInquiryItemViaId = async (id: string | number) => {
	const url = `/members/admin/inquiries/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<InquiryDto>>(url);

	return data.row;
};
