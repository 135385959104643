import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';

interface Address {
	regionDepth1: string;
	regionDepth2: string;
	regionDepth3: string;
	zipCode: string;
	country: string;
	detail: string;
	street: string;
	zibun: string;
}

interface Coordinate extends Address {
	lat: string;
	lng: string;
}

const addressApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAddress: builder.query<ListResponse<Address>, { keyword: string }>({
			query: ({ keyword }) => ({
				method: 'GET',
				url: '/core/addresses',
				params: { keyword },
			}),
		}),
		getCoordinate: builder.query<
			ObjectResponse<Coordinate>,
			{ address: string }
		>({
			query: ({ address }) => ({
				method: 'GET',
				url: '/core/coordinate',
				params: { address },
			}),
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const { useLazyGetAddressQuery, useLazyGetCoordinateQuery } =
	addressApiSlice;
