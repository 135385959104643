import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import App from './App';
import { ToastProvider } from './contexts/Toast';
import reportWebVitals from './reportWebVitals';
import store from './store';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import FullLoading from './components/organisms/FullLoading';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

// eslint-disable-next-line import/prefer-default-export
export const persistor = persistStore(store);

dayjs.locale('ko');

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<ConfigProvider
					locale={koKR}
					theme={{
						token: {
							fontSize: 13,
							fontFamily:
								"Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue,'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', Apple Color Emoji,sans-serif;",
						},
					}}
				>
					<ToastProvider>
						<GlobalStyle />
						<BrowserRouter>
							<Routes>
								<Route path="/*" element={<App />} />
							</Routes>
						</BrowserRouter>
					</ToastProvider>
					<FullLoading />
				</ConfigProvider>
			</ThemeProvider>
		</PersistGate>
	</Provider>,
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
