import React, { PropsWithChildren } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import theme from '../../../styles/theme';

export type BadgeColorType = 'blue' | 'yellow' | 'red' | 'green' | 'gray';

interface BadgeProps {
	color: BadgeColorType;
	style?: CSSObject;
}

const getBadgeColor = (color: BadgeColorType) => {
	let $backgroundColor;
	let fontColor;

	switch (color) {
		case 'blue':
			$backgroundColor = theme.common.colors.info_1;
			fontColor = theme.common.colors.info_5_main;
			break;
		case 'yellow':
			$backgroundColor = theme.common.colors.warning_1;
			fontColor = theme.common.colors.warning_5_main;
			break;
		case 'red':
			$backgroundColor = theme.common.colors.danger_1;
			fontColor = theme.common.colors.danger_5_main;
			break;
		case 'green':
			$backgroundColor = theme.common.colors.success_1;
			fontColor = theme.common.colors.success_5_main;
			break;
		case 'gray':
			$backgroundColor = theme.common.colors.gray_1;
			fontColor = theme.common.colors.gray_11;
			break;
		default:
			return '';
	}

	return css`
		background: ${$backgroundColor};
		color: ${fontColor};
	`;
};

const BadgeArticle = styled.article<{ color: BadgeColorType }>`
	${({ color }) => getBadgeColor(color)};
	${theme.typo.label3};
	padding: 6px 10px;
	border-radius: 100px;
	display: inline-block;
`;

function Badge({
	children,
	color = 'blue',
	style,
}: PropsWithChildren<BadgeProps>) {
	return (
		<BadgeArticle style={style} color={color}>
			{children}
		</BadgeArticle>
	);
}

export default Badge;
