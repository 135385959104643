import {
	GenerateNoticeDto,
	GenerateNoticeResponseDto,
	GenerateNoticeUpdateDto,
} from '../../../interface/notice';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const noticeApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getNotice: builder.query<
			ListResponse<GenerateNoticeResponseDto>,
			{ page: string; pageSize: string }
		>({
			query: ({ page, pageSize }) => ({
				method: 'GET',
				url: '/members/notices',
				params: { page, pageSize },
			}),
		}),
		getNoticeDetail: builder.query<
			ObjectResponse<GenerateNoticeResponseDto>,
			{ id: string | undefined }
		>({
			query: ({ id }) => ({
				method: 'GET',
				url: `/members/notices/${id}`,
			}),
		}),
		noticeCreate: builder.mutation<
			ObjectResponse<GenerateNoticeResponseDto>,
			GenerateNoticeDto
		>({
			query: (body) => ({
				method: 'POST',
				url: '/members/notices',
				body,
			}),
		}),
		noticeDelete: builder.mutation<
			ObjectResponse<GenerateNoticeResponseDto>,
			{ id: number }
		>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `/members/notices/${id}`,
			}),
		}),
		noticeModify: builder.mutation<
			ObjectResponse<GenerateNoticeResponseDto>,
			GenerateNoticeUpdateDto
		>({
			query: ({ id, viewable, title, body, service }) => ({
				method: 'PUT',
				url: `/members/notices/${id}`,
				body: { viewable, title, body, service },
			}),
		}),
	}),
});

export const {
	useGetNoticeQuery,
	useGetNoticeDetailQuery,
	useNoticeCreateMutation,
	useNoticeModifyMutation,
	useNoticeDeleteMutation,
} = noticeApi;
export default noticeApi;
