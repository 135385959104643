import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import {
	AnyMobilityOBDInfo,
	DTGInfo,
} from '../../../interface/vehicleConditions';

const vehicleConditionsApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVehicleOBDInfo: builder.query<
			ObjectResponse<AnyMobilityOBDInfo>,
			{ vehicleNumber: string | undefined }
		>({
			query: ({ vehicleNumber }) => `/members/obds/${vehicleNumber}`,
		}),

		getTimeIndex: builder.query<
			ListResponse<string>,
			{ vehicleNumber: string; date: string }
		>({
			query: ({ vehicleNumber, date }) => ({
				url: `/members/dtgs/${vehicleNumber}/${date}/index`,
			}),
		}),

		getVehicleTrip: builder.query<
			ListResponse<{ startedAt?: string; finishedAt?: string }>,
			{ vehicleNumber: string; date: string }
		>({
			query: ({ vehicleNumber, date }) => ({
				url: `/members/dtgs/${vehicleNumber}/${date}/trips`,
			}),
			providesTags: ['VehicleConditions'],
		}),

		getMonthVehicleTrips: builder.query<
			ListResponse<{
				startedAt?: string;
				finishedAt?: string;
			}>,
			{ vehicleNumber: string; startDate: string; endDate: string }
		>({
			query: ({ vehicleNumber, startDate, endDate }) => ({
				url: `/members/dtgs/${vehicleNumber}/trips`,
				params: { startDate, endDate },
			}),
		}),

		getVehicleDTGInfo: builder.query<
			ListResponse<DTGInfo>,
			{ vehicleNumber: string; date: string; time: string }
		>({
			query: ({ vehicleNumber, date, time }) => {
				return {
					url: `/members/dtgs/${vehicleNumber}/${date}`,
					params: time === '전일' ? {} : { time },
				};
			},
			extraOptions: { retries: 0 },
		}),

		getVehicleDTGInfoByDateTime: builder.query<
			ListResponse<DTGInfo>,
			{
				vehicleNumber: string | undefined;
				startedAt: string;
				finishedAt: string;
			}
		>({
			query: ({ vehicleNumber, startedAt, finishedAt }) => {
				return {
					url: `/members/dtgs/${vehicleNumber}`,
					params: { startedAt, finishedAt },
				};
			},
			extraOptions: { retries: 0 },
		}),

		getVehicleCurrentLocations: builder.query<
			ListResponse<DTGInfo>,
			{ companyId?: number; departmentId?: number }
		>({
			query: ({ companyId, departmentId }) => {
				return {
					url: `/members/dtgs/currentLocations`,
					params: { companyId, departmentId },
				};
			},
		}),

		getVehiclesOBDInfo: builder.query<
			ListResponse<AnyMobilityOBDInfo & { vehicleNumber: string }>,
			{ departmentId: number }
		>({
			query: ({ departmentId }) => {
				return { url: `/members/obds`, params: { departmentId } };
			},
		}),
	}),
});

export const {
	useGetVehicleOBDInfoQuery,
	useGetTimeIndexQuery,
	useGetVehicleTripQuery,
	useGetMonthVehicleTripsQuery,
	useGetVehicleDTGInfoQuery,
	useGetVehicleDTGInfoByDateTimeQuery,
	useGetVehicleCurrentLocationsQuery,
	useGetVehiclesOBDInfoQuery,
} = vehicleConditionsApiSlice;
