import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
	FindAdministratorApplicationIdDto,
	FindAdministratorApplicationIdResponseDto,
} from '../../../interface/findAdminId';
import { ObjectResponse } from '../@types';

const findAdminId = createApi({
	reducerPath: 'findAdminId',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
	}),
	endpoints: (builder) => ({
		findAdminId: builder.mutation<
			ObjectResponse<FindAdministratorApplicationIdResponseDto>,
			FindAdministratorApplicationIdDto
		>({
			query: ({ authTokenId, accessToken, removable }) => ({
				method: 'POST',
				url: `/members/administrators/application-id`,
				body: {
					authTokenId,
					accessToken,
					removable,
				},
			}),
		}),
	}),
});

export const { useFindAdminIdMutation } = findAdminId;
export default findAdminId;
