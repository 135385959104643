import {
	SubscriptionNoticeDto,
	SubscriptionNoticeUpdateDto,
} from '../../../interface/subscriptionNotice';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const subscriptionNoticeApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateSubscriptionNotice: builder.mutation<
			ObjectResponse<SubscriptionNoticeDto>,
			{ id: number; body: SubscriptionNoticeUpdateDto }
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `members/subscription-notices/${id}`,
				body,
			}),
		}),
	}),
});

export const getSubscriptionNoticeByOne = async () => {
	const url = `members/subscription-notices`;

	const { data } = await axiosInstance.get<ListResponse<SubscriptionNoticeDto>>(
		url,
	);

	return data.rows[0];
};

// eslint-disable-next-line import/prefer-default-export
export const { useUpdateSubscriptionNoticeMutation } =
	subscriptionNoticeApiSlice;
