import React, { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import { numberWithCommas } from '../../../utils/data-format';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import {
	UpdateVehicleStatus,
	CreateVehicleStatus,
} from '../../../interface/vehicleStatus';
import GridDetailTemplate from '../GridDetailTemplate';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../atoms/Button';
import DateTimePicker from '../../atoms/DateTimePicker';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import Alert from '../../atoms/Alert';
import { useUpdateVehicleStatusMutation } from '../../../store/apis/vehicleStatus';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import { ManagerDto } from '../../../interface/manager';
import { VEHICLES_STATUS_MANAGEMENT_PATH } from '../../../routes/constants/urls';

interface VehicleTemplateProps {
	initialValues: UpdateVehicleStatus;
	manager?: ManagerDto | null;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
}

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const GridLayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const STATUS_OPTIONS = [
	{ label: '불량', value: 0 },
	{ label: '위험', value: 1 },
	{ label: '주의', value: 2 },
	{ label: '보통', value: 3 },
	{ label: '양호', value: 4 },
	{ label: '정상', value: 5 },
];

const VehicleStatusTemplate = ({
	initialValues,
	manager,
}: VehicleTemplateProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const goBack = () => {
		navigate(-1);
	};

	const [updateVehicleStatus] = useUpdateVehicleStatusMutation();

	const { id: vehicleStatusId } = useParams();

	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const result = await updateVehicleStatus({
			id: Number(vehicleStatusId),
			...(values as CreateVehicleStatus),
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 상태 정보가 수정되었습니다.');
			navigate(VEHICLES_STATUS_MANAGEMENT_PATH);
		}
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			// 여기서 submit api 호출

			await handleUpdateSubmit({
				...(values as UpdateVehicleStatus),
			});

			dispatch(fullLoadingOff());
		},
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleUpdateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.UPDATE);
		}
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.UPDATE:
				return (
					<Alert
						title="차량상태 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						차량상태 정보를 수정하시겠습니까?
					</Alert>
				);
			default:
				return '';
		}
	}, [alertState, formik]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="차량상태 상세"
				onBack={goBack}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goBack}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleUpdateClick} size="small">
							수정하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="차량 상태"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayoutWrapper>
						<GridLayout $columnCount={3}>
							<GridItem title="차량번호" size={GridTitleSize.GT_LARGE}>
								{initialValues && initialValues.vehicleNumber}
							</GridItem>
							<GridItem
								title="점검일"
								size={GridTitleSize.GT_LARGE}
								$padding="4px 12px"
							>
								<DateTimePicker
									name="inspectedAt"
									value={dayjs(formik.values.inspectedAt)}
									onChange={(date, dateString) => {
										formik.setFieldValue(
											'inspectedAt',
											dayjs(dateString).format('YYYY-MM-DD'),
										);
									}}
									placeholder="점검일자"
									allowClear={false}
									style={{ borderRadius: '4px' }}
								/>
							</GridItem>
							<GridItem title="총 주행거리" size={GridTitleSize.GT_LARGE}>
								{initialValues &&
									`${numberWithCommas(Number(initialValues.mileage))} km`}
							</GridItem>
							<GridItem title="담당매니저" size={GridTitleSize.GT_LARGE}>
								{initialValues && initialValues.managerName
									? initialValues?.managerName
									: ''}
							</GridItem>
							<GridItem title="매니저 소속" size={GridTitleSize.GT_LARGE}>
								{manager && manager.department.departmentName}
							</GridItem>
							<GridItem title="메모" size={GridTitleSize.GT_LARGE}>
								<Input
									name="memo"
									type="text"
									value={formik.values.memo ?? ''}
									onChange={formik.handleChange}
								/>
							</GridItem>
						</GridLayout>
						<GridLayout $columnCount={4}>
							{initialValues && formik.values.itemList
								? formik.values.itemList.map((item, index) => {
										return (
											<GridItem
												title={item.itemName}
												size={170}
												key={item.itemName}
											>
												{item.itemName.includes('타이어') ||
												item.itemName.includes('배터리') ||
												item.itemName.includes('브레이크패드') ? (
													// 나머지 숫자 상태값 (item.status)
													<Input
														name={`status_${item.itemName}`}
														value={item.status}
														onChange={(e) => {
															const newValue = parseFloat(e.target.value);
															formik.setFieldValue(
																`itemList.${index}.status`,
																newValue,
															);
														}}
														type="number"
													/>
												) : (
													// 상태 status text (getStatusText(item.status))
													<Select
														defaultValue={item.status}
														// value={item.status}
														onChange={(value) => {
															const convertedValue = value;

															formik.setFieldValue(
																`itemList.${index}.status`,
																convertedValue,
															);
														}}
													>
														{STATUS_OPTIONS.map((status) => (
															<Select.Option
																key={status.value}
																value={status.value}
															>
																{status.label}
															</Select.Option>
														))}
													</Select>
												)}
											</GridItem>
										);
								  })
								: ''}
						</GridLayout>
					</GridLayoutWrapper>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{renderAlert()}
		</>
	);
};

export default VehicleStatusTemplate;
