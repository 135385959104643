import React from 'react';
import styled from 'styled-components';
import Typo from '../Typo';

interface PopupProps {
	count?: number;
	className?: string;
}

const PopupContainer = styled.div`
	display: inline-block;
	background: ${({ theme }) => theme.common.colors.danger_5_main};
	padding: 2px 7px;
	border-radius: 64px;
`;

function Popup({ count, className }: PopupProps) {
	const renderPopup = () => {
		return count ? (
			<PopupContainer className={className}>
				<Typo $typoType="h11" color="white">
					{count > 99 ? '99+' : count}
				</Typo>
			</PopupContainer>
		) : null;
	};

	return renderPopup();
}

export default Popup;
