import React from 'react';
import styled from 'styled-components';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import CheckboxLabel from '../CheckboxLabel';
import { CrudDataItemType } from '../NestablePanels';

export type PanelItemCrudType =
	| 'read'
	| 'create'
	| 'update'
	| 'delete'
	| 'super';

interface NestablePanelItemListProps {
	data: CrudDataItemType[];
	parentKey: string;
	onCrudToggle: ({
		type,
		panelParentKey,
		panelItemKey,
	}: {
		type: PanelItemCrudType;
		panelParentKey: string;
		panelItemKey: string;
	}) => void;
}

const ListItem = styled.li`
	padding: 12px 14px;
	background: ${({ theme }) => theme.common.colors.white};
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
	border-radius: 8px;
	${({ theme }) => theme.typo.b7};
	color: ${({ theme }) => theme.common.colors.gray_11};
	margin-left: 28px;

	display: flex;
	justify-content: space-between;
`;

const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-top: 8px;
`;

const CheckBoxContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-left: auto;
`;

export function NestableCrudPanelItem({
	itemInfo,
	parentKey,
	onCrudToggle,
}: {
	itemInfo: CrudDataItemType;
	parentKey: string;
	onCrudToggle: ({
		type,
		panelParentKey,
		panelItemKey,
	}: {
		type: PanelItemCrudType;
		panelParentKey: string;
		panelItemKey: string;
	}) => void;
}) {
	return (
		<ListItem>
			<>
				{itemInfo.title}

				<CheckBoxContainer>
					<CheckboxLabel
						name={`${itemInfo.title}-read`}
						isChecked={itemInfo.isRead}
						onClick={() =>
							onCrudToggle({
								type: 'read',
								panelParentKey: parentKey,
								panelItemKey: itemInfo.key,
							})
						}
						$checkboxStyle="square"
					>
						<Label name={`${itemInfo.title}-read`}>
							<p>
								<Typo $typoType="btn4">조회</Typo>
							</p>
						</Label>
					</CheckboxLabel>

					<CheckboxLabel
						name={`${itemInfo.title}-create`}
						isChecked={itemInfo.isCreate}
						onClick={() =>
							onCrudToggle({
								type: 'create',
								panelParentKey: parentKey,
								panelItemKey: itemInfo.key,
							})
						}
						$checkboxStyle="square"
					>
						<Label name={`${itemInfo.title}-create`}>
							<p>
								<Typo $typoType="btn4">등록</Typo>
							</p>
						</Label>
					</CheckboxLabel>

					<CheckboxLabel
						name={`${itemInfo.title}-update`}
						isChecked={itemInfo.isUpdate}
						onClick={() =>
							onCrudToggle({
								type: 'update',
								panelParentKey: parentKey,
								panelItemKey: itemInfo.key,
							})
						}
						$checkboxStyle="square"
					>
						<Label name={`${itemInfo.title}-update`}>
							<p>
								<Typo $typoType="btn4">수정</Typo>
							</p>
						</Label>
					</CheckboxLabel>

					<CheckboxLabel
						name={`${itemInfo.title}-delete`}
						isChecked={itemInfo.isDelete}
						onClick={() =>
							onCrudToggle({
								type: 'delete',
								panelParentKey: parentKey,
								panelItemKey: itemInfo.key,
							})
						}
						$checkboxStyle="square"
					>
						<Label name={`${itemInfo.title}-delete`}>
							<p>
								<Typo $typoType="btn4">삭제</Typo>
							</p>
						</Label>
					</CheckboxLabel>

					<CheckboxLabel
						name={`${itemInfo.title}-super`}
						isChecked={itemInfo.isSuper}
						onClick={() =>
							onCrudToggle({
								type: 'super',
								panelParentKey: parentKey,
								panelItemKey: itemInfo.key,
							})
						}
						$checkboxStyle="square"
					>
						<Label name={`${itemInfo.title}-super`}>
							<p>
								<Typo $typoType="btn4">전체권한</Typo>
							</p>
						</Label>
					</CheckboxLabel>
				</CheckBoxContainer>
			</>
		</ListItem>
	);
}

function NestablePanelCrudItemList({
	data,
	parentKey,
	onCrudToggle,
}: NestablePanelItemListProps) {
	return (
		<List>
			{data.map((list) => {
				return (
					<NestableCrudPanelItem
						key={list.key}
						itemInfo={list}
						parentKey={parentKey}
						onCrudToggle={onCrudToggle}
					/>
				);
			})}
		</List>
	);
}

export default NestablePanelCrudItemList;
