import React from 'react';
import styled, { css, CSSObject } from 'styled-components';
import Lottie from 'lottie-react';
import LoadingLottie from '../../../assets/lottie/loading.json';

interface SectionLoadingProps {
	padding?: number;
	style?: CSSObject;
	loadingSize?: number;
}

const Article = styled.article<{ $verticalPadding?: number }>`
	//display: flex;
	align-items: center;
	justify-content: center;

	${({ $verticalPadding }) =>
		$verticalPadding
			? css`
					padding: ${$verticalPadding}px 0;
			  `
			: css`
					padding: 30px 0;
			  `};
`;

function SectionLoading({
	padding,
	style,
	loadingSize = 150,
}: SectionLoadingProps) {
	return (
		<Article $verticalPadding={padding} style={style}>
			<Lottie
				animationData={LoadingLottie}
				loop
				style={{ width: `${loadingSize}px` }}
			/>
		</Article>
	);
}

export default SectionLoading;
