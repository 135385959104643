import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/atoms/Button';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import {
	TERMS_CREATE_PATH,
	TERMS_DETAIL_PATH,
} from '../../../routes/constants/urls';
import termsManagementData from './gridData';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import { useGetTermsQuery } from '../../../store/apis/terms';

function TermsPage() {
	const { formInitialValues, colsList } = termsManagementData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetTermsQuery,
		formInitialValues,
		colsList,
		detailPath: TERMS_DETAIL_PATH,
	});

	const goTo = (route: string) => {
		navigate(route);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="약관 관리"
			gridTitle="약관 리스트"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			rightAccessory={
				<Button onClick={() => goTo(TERMS_CREATE_PATH)} size="small">
					약관등록
				</Button>
			}
			isFetching={isFetching}
		/>
	);
}

export default TermsPage;
