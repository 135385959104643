import { RcFile } from 'antd/lib/upload';
import { ListResponse } from '../@types';
import { authApiSlice } from '../query';

interface UploadImageFile {
	file?: RcFile | RcFile[];
	folder: string;
}

interface UploadFile {
	file?: File | File[];
	folder: string;
}

const uploadApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		bigImagesUpload: builder.mutation<ListResponse<string>, UploadImageFile>({
			query: (body) => {
				const formData = new FormData();
				if (body.file && 'length' in body.file && body.file.length) {
					body.file.forEach((item) => {
						formData.append('image', item as Blob);
					});
				} else if (body.file) {
					formData.append('image', body.file as Blob);
				}
				formData.append('folder', body.folder);
				formData.append('service', 'BOS_WEB');

				return {
					method: 'POST',
					url: '/media/big-images',
					body: formData,
				};
			},
		}),
		bigFilesUploadSecure: builder.mutation<ListResponse<string>, UploadFile>({
			query: (body) => {
				const formData = new FormData();
				if (body.file && 'length' in body.file && body.file.length) {
					body.file.forEach((item) => {
						formData.append('file', item as Blob);
					});
				} else if (body.file) {
					formData.append('file', body.file as Blob);
				}
				formData.append('folder', body.folder);
				formData.append('service', 'BOS_WEB');

				return {
					method: 'POST',
					url: '/media/secure/big-files',
					body: formData,
				};
			},
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const { useBigImagesUploadMutation, useBigFilesUploadSecureMutation } =
	uploadApi;
