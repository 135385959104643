import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface LoginInputFormProps {
	className?: string;
}

const LoginInputFormContainer = styled.div<LoginInputFormProps>`
	width: 320px;
	margin-bottom: 16px;
`;

function LoginInputForm({
	children,
	className,
}: PropsWithChildren<LoginInputFormProps>) {
	return (
		<LoginInputFormContainer className={className}>
			{children}
		</LoginInputFormContainer>
	);
}

export default LoginInputForm;
