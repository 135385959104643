import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ResponsiveHeatMap } from '@nivo/heatmap';

import { HeatmapChartMonthItem } from '../../../../interface/carService';

interface HeatmapChartDataProps {
	heatmapChartData: HeatmapChartMonthItem[];
}

const HeatmapChartMonthFull = ({ heatmapChartData }: HeatmapChartDataProps) => {
	const styles: Record<string, React.CSSProperties> = {
		root: {
			position: 'relative',
			width: '100%',
			height: `${heatmapChartData.length * 30}px`,
		},
		overlay: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, 0%)',
			fontSize: 36,
			color: 'black',
			textAlign: 'center',
			pointerEvents: 'none',
		},
		totalLabel: {
			fontSize: 24,
		},
	};
	return (
		<div style={styles.root}>
			<ResponsiveHeatMap
				data={heatmapChartData}
				margin={{ top: 50, right: 40, bottom: 10, left: 80 }}
				valueFormat=" >-,~g"
				axisTop={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: -25,
					legend: '',
					legendOffset: 46,
					truncateTickAt: 0,
				}}
				axisRight={null}
				axisLeft={{
					tickSize: 5,
					tickPadding: 10,
					tickRotation: 0,
					legend: null, // legend: '기준년월',
					legendPosition: 'middle',
					legendOffset: 70,
					truncateTickAt: 0,
				}}
				colors={{
					type: 'diverging',
					scheme: 'green_blue',
					// steps: 20,
					minValue: 0,
				}}
				emptyColor="#555555"
				borderRadius={0}
				// labelTextColor="#c74c60"
				legends={[]}
				theme={{
					text: {
						fontFamily:
							'Pretendard, "Noto Sans KR", "Malgun Gothic", sans-serif',
						fontSize: '14',
					},
					axis: { ticks: { text: { fontSize: '12' } } },
					legends: { text: { fontSize: '11' } },
					labels: { text: { fontSize: '11' } },
				}}
			/>
		</div>
	);
};

export default HeatmapChartMonthFull;
