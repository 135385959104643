import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ThemeType } from '../../../styles/theme';
import ToolTip from '../Tooltip';

interface LabelProps {
	name: string;
	essential?: boolean;
	className?: string;
	tooltip?: string;
}

const LabelContainer = styled.label``;

const Essential = styled.span<{ theme: ThemeType }>`
	color: ${({ theme }) => theme.common.colors.danger_5_main};
	margin-left: 2px;
`;

function Label({
	name,
	essential = false,
	children,
	className,
	tooltip,
}: PropsWithChildren<LabelProps>) {
	return (
		<LabelContainer className={className} htmlFor={name}>
			{children}
			{essential ? <Essential>*</Essential> : <span />}
			{tooltip && <ToolTip title={tooltip || ''} />}
		</LabelContainer>
	);
}

export default Label;
