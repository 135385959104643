import {
	GenerateTermsHistoryDto,
	ModifyTermsHistoryDto,
	TermsHistoryDto,
} from '../../../interface/termsHistory';
import { axiosInstance } from '../../../network/axios';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const termsHistoryApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createTermsHistory: builder.mutation<
			TermsHistoryDto,
			GenerateTermsHistoryDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/core/term-histories`,
				body,
			}),
		}),
		updateTermsHistory: builder.mutation<
			TermsHistoryDto,
			{
				id: number;
				body: ModifyTermsHistoryDto;
			}
		>({
			query: ({ id, body }) => ({
				method: 'PUT',
				url: `/core/term-histories/${id}`,
				body,
			}),
		}),
		deleteTermsHistory: builder.mutation<
			TermsHistoryDto,
			{
				id: number;
			}
		>({
			query: ({ id }) => ({
				method: 'DELETE',
				url: `/core/term-histories/${id}`,
			}),
		}),
	}),
});

export const {
	useCreateTermsHistoryMutation,
	useUpdateTermsHistoryMutation,
	useDeleteTermsHistoryMutation,
} = termsHistoryApi;

export default termsHistoryApi;

export const getTermsHistoryListViaId = async (id: number) => {
	const url = `/core/terms/${id}/terms-histories`;

	const { data } = await axiosInstance.get<ListResponse<TermsHistoryDto>>(url);

	return data.rows;
};

export const getTermsHistoryDetailViaId = async (id: number) => {
	const url = `/core/term-histories/${id}`;

	const { data } = await axiosInstance.get<ObjectResponse<TermsHistoryDto>>(
		url,
	);

	return data.row;
};
