import dayjs from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { LabelValue } from '../../../../utils/const';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { phoneFormat } from '../../../../utils/data-format';

interface DriverGridDataProps {
	companiesOption: LabelValue[];
}

const userData: ({ companiesOption }: DriverGridDataProps) => GridDataModel = ({
	companiesOption,
}) => {
	return {
		formInitialValues: {},
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '등록일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'companyName',
					headerName: '법인명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.company?.name ?? '-';
					},
					valueGetter: ({ data }) => {
						return data.company?.name ?? '-';
					},
				},
			},
			{
				tableInfo: {
					field: 'driverName',
					headerName: '기사명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'driverPhone',
					headerName: '기사 휴대폰번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return value ? phoneFormat(value) : '-';
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicle.vehicleNumber',
					headerName: '차량번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicle.vin',
					headerName: '차대번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicle.vehicleName',
					headerName: '차량명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicle.carLoadingBoxType',
					headerName: '적재함종류',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicle.loadingCapacity',
					headerName: '적재량(톤)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customer.customerName',
					headerName: '연결된 회원명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customer.customerApplicationId',
					headerName: '연결된 회원 아이디',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: [
			{
				key: 'companyId',
				label: '법인명',
				type: FilterElementType.SELECT,
				options: [{ label: '전체', value: '' }, ...companiesOption],
			},
			{
				key: 'driverName',
				label: '기사명',
				type: FilterElementType.INPUT,
			},
			{
				key: 'driverPhone',
				label: '기사 휴대폰',
				type: FilterElementType.PHONE_INPUT,
			},
		],
	};
};

export default userData;
