import { FormikErrors } from 'formik';
import React, { useState } from 'react';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../atoms/Typo';
import {
	useGetBankCodesQuery,
	useLazyCheckAccountQuery,
} from '../../../store/apis/carService';
import { LabelValue } from '../../../utils/const';

interface CheckBankAccountProps {
	bankCodeFieldName: string[];
	bankNameFieldName: string[];
	bankAccountFieldName: string[];
	bankAccountOwnerFieldName: string[];
	verifiedDateFieldName?: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<FormikErrors<any>> | Promise<void>;
	setDuzonFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<FormikErrors<any>> | Promise<void>;
	style?: React.CSSProperties | undefined;
	$columnCount?: number;
}

const CheckBankAccount = ({
	bankCodeFieldName,
	bankNameFieldName,
	bankAccountFieldName,
	bankAccountOwnerFieldName,
	verifiedDateFieldName,
	setFieldValue,
	setDuzonFieldValue,
	style = {},
	$columnCount = 4,
}: CheckBankAccountProps) => {
	const [isChecked, setIsChecked] = useState(false);
	const [bankCode, setBankCode] = useState('');
	const [bankName, setBankName] = useState('');
	const [bankAccount, setBankAccount] = useState('');

	const { data: bankCodes } = useGetBankCodesQuery({
		bankCodeKey: 'B0001',
	});

	const bankCodeLabel: LabelValue[] = bankCodes
		? bankCodes.rows.map((row) => ({ label: row.NAME, value: row.CODE }))
		: [];

	const [
		checkAccount,
		{ data: depositorResult, isLoading, isError, isFetching, isSuccess },
	] = useLazyCheckAccountQuery();

	const depositor =
		depositorResult && !isError ? depositorResult.row.depositor : '';

	const handleSetValueEmpty = () => {
		setBankCode('');
		setBankName('');
		setBankAccount('');
	};

	const handleSetFieldValue = () => {
		// eslint-disable-next-line array-callback-return
		bankCodeFieldName.map((fieldName) => {
			setFieldValue(fieldName, bankCode);
			setDuzonFieldValue(fieldName, bankCode);
		});
		// eslint-disable-next-line array-callback-return
		bankNameFieldName.map((fieldName) => {
			setFieldValue(fieldName, bankName);
		});
		// eslint-disable-next-line array-callback-return
		bankAccountFieldName.map((fieldName) => {
			setFieldValue(fieldName, bankAccount);
			setDuzonFieldValue(fieldName, bankAccount);
		});
		// eslint-disable-next-line array-callback-return
		bankAccountOwnerFieldName.map((fieldName) => {
			setFieldValue(fieldName, depositor);
			setDuzonFieldValue(fieldName, depositor);
		});
		if (verifiedDateFieldName) {
			setFieldValue(verifiedDateFieldName, dayjs().format('YYYY-MM-DD'));
		}
	};

	return (
		<div style={style}>
			<GridLayout $columnCount={$columnCount} $isCompact>
				<GridItem
					key="bankCode"
					title="은행기관"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Select
						value={bankCode}
						onChange={(value: string, option) => {
							setBankCode(value);
							if (value) {
								setBankName(option.children);
							} else {
								setBankName('');
							}
						}}
						placeholder="은행기관"
						allowClear
						disabled={isChecked}
						style={{ minWidth: '100px', fontSize: '14px' }}
					>
						{bankCodeLabel.map((item, idx) => (
							<Select.Option value={item.value} key={idx.toString()}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</GridItem>
				<GridItem
					key="bankAccount"
					title="계좌번호"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Input
						name="bankAccount"
						placeholder="계좌번호"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						$isDisabled={isChecked}
						value={bankAccount}
						onChange={(e) => setBankAccount(e.target.value)}
						onReset={() => setBankAccount('')}
						$isCompact
					/>
				</GridItem>
				<GridItem
					key="checkAccount"
					title="예금주 확인"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					<Button
						size="small"
						$buttonType={ButtonTypeType.PRIMARY_LIGHT}
						onClick={() => {
							checkAccount({
								bankCode,
								bankAccount,
							});
						}}
						disabled={isChecked || !bankCode || !bankAccount}
					>
						확인하기
					</Button>
				</GridItem>
				<GridItem
					key="bankAccountOwner"
					title="예금주"
					size={GridTitleSize.GT_X_SMALL}
					$isCompact
				>
					{isError ? (
						<Typo
							$typoType="b6"
							style={{ marginLeft: '4px', marginRight: '4px' }}
						>
							확인 실패
						</Typo>
					) : (
						''
					)}
					{isLoading || isFetching ? (
						<Skeleton.Input active style={{ borderRadius: '10px' }} />
					) : (
						<>
							<Typo
								$typoType="b6"
								style={
									depositor
										? { marginRight: '12px', marginLeft: '4px' }
										: { marginLeft: '0px' }
								}
							>
								{depositor}
							</Typo>
							{isChecked ? (
								<Button
									size="small"
									$buttonType={ButtonTypeType.GHOST_DANGER}
									disabled={!isChecked}
									onClick={() => {
										handleSetValueEmpty();
										setIsChecked(false);
									}}
								>
									재설정하기
								</Button>
							) : (
								<Button
									size="small"
									$buttonType={ButtonTypeType.GREEN}
									disabled={
										isError ||
										isLoading ||
										isFetching ||
										!isSuccess ||
										!bankCode ||
										!bankAccount
									}
									onClick={() => {
										handleSetFieldValue();
										setIsChecked(true);
									}}
								>
									사용하기
								</Button>
							)}
						</>
					)}
				</GridItem>
			</GridLayout>
		</div>
	);
};

export default CheckBankAccount;
