import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import {
	SUBSCRIBE_PRODUCT_DETAIL_PATH,
	SUBSCRIBE_PRODUCT_CREATE_PATH,
} from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	useGetSubscriptionProductsQuery,
	useUpdateSubscribeViewableMutation,
} from '../../../../store/apis/subscribeProduct';
import {
	PRODICT_STATE_OPTIONS,
	PRODUCT_STATE_VISIBLE_VALUE,
} from '../../../../utils/const';
import { createSelectRenderer } from '../../../../utils/row-data-util';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function SubscriptionProductPage() {
	const { formInitialValues, colsList, forms } = userData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		refetch,
	} = useGridDataWithRTK({
		useQuery: useGetSubscriptionProductsQuery,
		formInitialValues,
		colsList,
		detailPath: SUBSCRIBE_PRODUCT_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(SUBSCRIBE_PRODUCT_CREATE_PATH);
	};
	const toast = useContext(ToastContext);
	const [updateStateBody, setUpdateStateBody] = useState({ id: 0, state: '' });
	const [visibleStateUpdateAlert, setVisibleStateUpdateAlert] = useState(false);
	const [updateProductState] = useUpdateSubscribeViewableMutation();

	const handleUpdateState = async () => {
		const result = await updateProductState({
			id: updateStateBody.id,
			body: {
				subscriptionProductViewable:
					updateStateBody.state === PRODUCT_STATE_VISIBLE_VALUE,
			},
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
			setVisibleStateUpdateAlert(false);
		} else {
			toast('info', '구독상품 상태변경이 완료되었습니다.');
			setVisibleStateUpdateAlert(false);
			refetch();
		}
	};

	const handleAlertCloseClick = () => {
		setVisibleStateUpdateAlert(false);
	};

	return (
		<>
			<GridContentTemplate
				gridRef={setGridApi}
				mainTitle="구독상품 관리"
				gridTitle="구독상품 목록"
				totalCount={totalCount}
				currentPage={page}
				currentPageSize={pageSize}
				onPageChange={handlePageChange}
				onPageSizeChange={handlePageSizeChange}
				rowData={rowData}
				cols={cols}
				formInitialValues={formInitialValues}
				forms={forms}
				onSearchSubmit={handleSearchSubmit}
				onRowClicked={handleDetailClick}
				frameworkComponents={{
					...createSelectRenderer({
						options: PRODICT_STATE_OPTIONS,
						onChange: (body) => {
							setUpdateStateBody(body);
							setVisibleStateUpdateAlert(true);
						},
					}),
				}}
				rightAccessory={
					<ButtonContainer>
						<Button onClick={handleCreateClick} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			/>

			{visibleStateUpdateAlert && (
				<Alert
					title="구독상품 상태를 변경하시겠습니까?"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="변경하기"
					onConfirmButtonClick={handleUpdateState}
					isVisible={visibleStateUpdateAlert}
				/>
			)}
		</>
	);
}

export default SubscriptionProductPage;
