import React, { useState } from 'react';
import {
	Calendar,
	Col,
	Row,
	Select,
	Skeleton,
	Tooltip,
	Typography,
} from 'antd';
import { SelectInfo } from 'antd/lib/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import ContentBox from '../../atoms/ContentBox';
import { useGetVehicleTripQuery } from '../../../store/apis/vehicleConditions';
import { CustomErrorType } from '../../../store/apis/@types';
import Typo from '../../atoms/Typo';
import { transformTimeToHour } from '../../../utils/date-util';
import Badge from '../../atoms/Badge';

const TripCellItem = ({
	date,
	vehicleNumber,
	isYearSelected,
	$isCompact,
}: {
	date: string;
	vehicleNumber: string;
	isYearSelected: boolean;
	$isCompact: boolean;
}) => {
	const { isError, error, isLoading, currentData } = useGetVehicleTripQuery(
		{
			vehicleNumber,
			date: dayjs(date).format('YYMMDD'),
		},
		{
			skip:
				!vehicleNumber ||
				!date ||
				dayjs(date).isAfter(dayjs(), 'day') ||
				!isYearSelected,
		},
	);

	if (!vehicleNumber) {
		return (
			<Typo $typoType="b6" color="gray_5">
				차량번호를 선택해주세요.
			</Typo>
		);
	}

	const trips = currentData && !isError ? currentData.rows : [];
	const totalTime = trips.map((trip) => {
		if (trip.finishedAt && trip.startedAt) {
			return dayjs(`${date}T${trip.finishedAt}`).diff(
				dayjs(`${date}T${trip.startedAt}`),
			);
		}
		return 0;
	});

	if (isError && (error as CustomErrorType).status === 404) {
		return $isCompact ? (
			<p>{}</p>
		) : (
			<Typo color="gray_6">운행기록이 없습니다.</Typo>
		);
	}

	if (isError) {
		return <p>{(error as CustomErrorType).data.translate}</p>;
	}

	if (isLoading) {
		return (
			<div>
				<Skeleton active style={{ borderRadius: '10px' }} />
			</div>
		);
	}

	if (!trips.length) {
		return $isCompact ? (
			<p>{}</p>
		) : (
			<Typo color="gray_6">데이터가 없습니다.</Typo>
		);
	}

	return $isCompact ? (
		<Tooltip
			title={
				<div>
					<p>
						<Typo $typoType="b6" color="gray_2">
							{trips.length}회
						</Typo>
						의 운행
					</p>
					<p>
						총{' '}
						<Typo $typoType="b6" color="gray_2">
							{transformTimeToHour(
								totalTime.reduce((a, b) => a + b, 0),
								3600000,
							)}
						</Typo>
					</p>
				</div>
			}
			color="black"
		>
			<div>
				<Badge color="green">+</Badge>
			</div>
		</Tooltip>
	) : (
		<div>
			<p>
				<Typo $typoType="h7">{trips.length}회</Typo>의 운행
			</p>
			<p>
				총 운행시간:{' '}
				<Typo $typoType="h7">
					{transformTimeToHour(
						totalTime.reduce((a, b) => a + b, 0),
						3600000,
					)}
				</Typo>
			</p>
		</div>
	);
};

interface VehicleCalendarProps {
	vehicleNumber: string;
	selectedDate: Dayjs;
	setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
	selectedYear: number;
	setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
	selectedMonth: number;
	setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
	isYearSelected: boolean;
	setIsYearSelected?: React.Dispatch<React.SetStateAction<boolean>>;
	handleDateSelect:
		| ((date: dayjs.Dayjs, selectInfo: SelectInfo) => void)
		| undefined;
	$isCompact?: boolean;
}

const VehicleCalendar = ({
	vehicleNumber,
	selectedDate,
	setSelectedDate,
	selectedYear,
	selectedMonth,
	setSelectedYear,
	setSelectedMonth,
	setIsYearSelected = () => {},
	isYearSelected,
	handleDateSelect,
	$isCompact = false,
}: VehicleCalendarProps) => {
	const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);

	const disabledDate = (current: Dayjs) => {
		return current.isAfter(dayjs(), 'day');
	};

	const dateCellRender = (value: Dayjs) => {
		return (
			<TripCellItem
				date={value.format('YYYYMMDD')}
				vehicleNumber={vehicleNumber}
				isYearSelected={isYearSelected}
				$isCompact={$isCompact || false}
			/>
		);
	};

	return (
		<ContentBox borderRadius="10px" className="inner-content" $isCompact>
			<div>
				{$isCompact || (
					<Typography.Title level={4}>
						{selectedDate.format('YYYY-MM-DD')}
					</Typography.Title>
				)}
				<Calendar
					cellRender={dateCellRender}
					value={selectedDate || dayjs()}
					onChange={(value) => setSelectedDate(value)}
					onSelect={handleDateSelect}
					disabledDate={disabledDate}
					className="calendar-wrapper"
					// eslint-disable-next-line react/no-unstable-nested-components
					headerRender={({ value, onChange, onTypeChange }) => {
						let current = value.clone();
						const instanceLocaleData = value.localeData();
						const months = [];

						for (let i = 0; i < 12; i += 1) {
							current = current.month(i);
							months.push(instanceLocaleData.monthsShort(current));
						}

						const currentYear = current.year();
						const years = [];
						for (let i = currentYear - 10; i < currentYear + 10; i += 1) {
							years.push(i);
						}

						return (
							<Row gutter={[6, 6]}>
								<Col>
									<Select
										value={selectedYear}
										onChange={(selectedYearValue) => {
											setSelectedYear(selectedYearValue);
											if (setIsYearSelected) {
												setIsYearSelected(false);
											}
											const sYear = value.clone().year(selectedYearValue);
											onChange(sYear);
											onTypeChange('month');
											setIsMonthDropdownOpen(true);
										}}
									>
										{years.map((year, idx) => (
											<Select.Option
												key={idx}
												value={year}
												className="year-item"
											>
												{year}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col>
									<Select
										value={selectedMonth}
										onSelect={(selectedMonthValue) => {
											setSelectedMonth(selectedMonthValue);
											if (setIsYearSelected) {
												setIsYearSelected(true);
											}
											const sMonth = value
												.clone()
												.month(selectedMonthValue as number);
											onChange(sMonth);
										}}
										open={isMonthDropdownOpen}
										onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
									>
										{months.map((month, idx) => (
											<Select.Option
												key={idx}
												value={idx}
												className="month-item"
											>
												{month}
											</Select.Option>
										))}
									</Select>
								</Col>
							</Row>
						);
					}}
				/>
			</div>
		</ContentBox>
	);
};

export default VehicleCalendar;
