import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import { useGetMyCompanyQuery } from '../../../store/apis/company';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import companyInfoTableKey from '../../../pages/UserManagement/CompanyManagement/Companies/gridData/detailGridData';
import { NewTableValueType } from '../../../utils/table-util';
import {
	companyRegisterNumberFormat,
	phoneFormat,
} from '../../../utils/data-format';

const CompanyInfo = () => {
	const { data } = useGetMyCompanyQuery();
	const myCompanyData = data?.row;

	const renderTable = <T extends Record<keyof T, ReactNode>>({
		tableKeys,
		tableData,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		tableData: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value], idx) => {
			const tableContent = tableData[key as keyof T];

			const { label, span } = value;

			switch (key) {
				case 'createdAt':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent
								? dayjs(tableContent as string).format('YYYY.MM.DD  HH:mm:ss')
								: '-'}
						</GridItem>
					);
				case 'name':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{tableContent as string}
						</GridItem>
					);
				case 'registrationNumber':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{tableContent as string}
						</GridItem>
					);
				case 'businessLicenseNumber':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{companyRegisterNumberFormat(tableContent as string)}
						</GridItem>
					);
				case 'representativePhoneNumber':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{phoneFormat(tableContent as string)}
						</GridItem>
					);
				case 'contactName':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{tableContent as string}
						</GridItem>
					);

				case 'contactPhoneNumber':
					return (
						<GridItem
							key={key}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							{phoneFormat(tableContent as string)}
						</GridItem>
					);

				default:
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent || '-'}
						</GridItem>
					);
			}
		});
	};

	return (
		<ContentBoxWithHeader
			title="법인 정보"
			borderRadius="10px"
			className="inner-content"
		>
			<GridLayout>
				{myCompanyData &&
					renderTable({
						tableKeys: companyInfoTableKey,
						tableData: myCompanyData as any,
					})}
			</GridLayout>
		</ContentBoxWithHeader>
	);
};

export default CompanyInfo;
