import React from 'react';
import { ProductCreateUpdateDto } from '../../../../interface/product';
import ProductTemplate from '../../../../components/templates/ProductTemplate';

const initialValues: ProductCreateUpdateDto = {
	productType: '',
	productName: '',
	productIntroduction: '',
	productPrice: 0,
	productTax: 0,
	productTaxType: 'TAX',
	productViewable: true,
	productAddible: true,
	productImage: undefined,
	productIcon: undefined,
	productDescription: '',
	generalPrice: 0,
	generalTax: 0,
	generalCount: 0,
	generalExpireDays: 0,
};

function ProductCreatePage() {
	return <ProductTemplate initialValues={initialValues} />;
}

export default ProductCreatePage;
