import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
	VerifyAndGenAccessTokenDto,
	VerifyAndGenAccessTokenErrorDto,
	VerifyAndGenAccessTokenResponseDto,
} from '../../../interface/authTokenVerify';
import { ObjectResponse } from '../@types';

const authTokenVerifyApi = createApi({
	reducerPath: 'authTokenVerifyApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
	}) as unknown as BaseQueryFn<
		string | FetchArgs,
		unknown,
		VerifyAndGenAccessTokenErrorDto
	>,
	endpoints: (builder) => ({
		authTokenVerify: builder.mutation<
			ObjectResponse<VerifyAndGenAccessTokenResponseDto>,
			VerifyAndGenAccessTokenDto
		>({
			query: ({ type, kind, authTokenId, token }) => ({
				method: 'PUT',
				url: `/core/auth-tokens/verify`,
				body: {
					type,
					kind,
					authTokenId,
					token,
				},
			}),
		}),
	}),
});

export const { useAuthTokenVerifyMutation } = authTokenVerifyApi;
export default authTokenVerifyApi;
