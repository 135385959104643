import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface CheckRoleProps {
	roleType: 'isRead' | 'isCreate' | 'isUpdate' | 'isDelete' | 'isSuper';
	roleName: string;
	includeSuper?: boolean;
}

const useCheckRole = ({ roleType, roleName, includeSuper }: CheckRoleProps) => {
	const { user } = useSelector((state: RootState) => state.auth);
	let isSuper = false;
	let hasRole = false;

	hasRole = !!(
		user &&
		user.role.roleItems
			.filter((item) => item[roleType])
			.find((item) => {
				return item.roleTemplate.roleName.replaceAll(' ', '') === roleName;
			})
	);

	if (includeSuper) {
		isSuper = !!(
			user &&
			user.role.roleItems
				.filter((item) => item.isSuper)
				.find((item) => {
					return item.roleTemplate.roleName.replaceAll(' ', '') === roleName;
				})
		);
		return isSuper && hasRole;
	}

	return hasRole;
};

export default useCheckRole;
