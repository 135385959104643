/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import LoadingLottie from '../../../assets/lottie/loading.json';
import { zIndex } from '../../../styles/GlobalStyle';
import { RootState } from '../../../store';

function FullLoading() {
	const { fullLoading } = useSelector((state: RootState) => state.webUtil);

	const renderFullLoading = () => {
		return fullLoading ? (
			<Article>
				<Lottie animationData={LoadingLottie} loop style={{ width: '250px' }} />
			</Article>
		) : null;
	};

	return ReactDOM.createPortal(
		renderFullLoading(),
		document.getElementById('full-loading') as HTMLElement,
	);
}

export default FullLoading;

const Article = styled.article`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
	z-index: ${zIndex.fullLoading};
	display: flex;
	align-items: center;
	justify-content: center;
`;
