import React, { useContext, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios, { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import Modal from '../../../components/atoms/Modal';
import Typo from '../../../components/atoms/Typo';
import Editor from '../../../components/atoms/Editor';
import Button from '../../../components/atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import { useCreateTermsHistoryMutation } from '../../../store/apis/termsHistory';
import { GenerateTermsHistoryDto } from '../../../interface/termsHistory';
import Alert from '../../../components/atoms/Alert';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import Icon from '../../../components/atoms/Icon';
import { grayCalendarIcon } from '../../../assets/icon';
import DateTimePicker from '../../../components/atoms/DateTimePicker';

interface RegisterModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => Promise<void>;
}

const ModalContentContainer = styled.div`
	.antd-date-picker {
		border-radius: 8px;

		input {
			${({ theme }) => theme.typo.label1};
		}
	}
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

enum AlertState {
	NULL,
	REGISTER,
}

function RegisterModal({ isVisible, onClose, onRefetch }: RegisterModalProps) {
	const [releasedDate, setReleasedDate] = useState<Dayjs>(dayjs());
	const [editorValue, setEditorValue] = useState<string>('');
	const [alert, setAlert] = useState<AlertState>(AlertState.NULL);
	const [isEditorContentEmpty, setIsEditorContentEmpty] = useState(true);

	const dispatch = useDispatch();
	const { id } = useParams();

	const setToast = useContext(ToastContext);

	const [createTermsHistory] = useCreateTermsHistoryMutation();

	const handleCreateTermsHistory = async () => {
		const body: GenerateTermsHistoryDto = {
			termsId: Number(id),
			body: editorValue,
			releasedAt: releasedDate.format('YYYY-MM-DD'),
		};

		const result = await createTermsHistory(body);

		if ('error' in result) {
			setToast('error', (result.error as CustomErrorType).data.translate);
		}
	};

	const handleClickRegister = () => {
		setAlert(AlertState.REGISTER);
	};

	const closeAlert = () => {
		setAlert(AlertState.NULL);
	};

	const handleConfirm = async () => {
		dispatch(fullLoadingOn());
		await handleCreateTermsHistory();
		try {
			await onRefetch();
			onClose();
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const msg =
					(error as AxiosError<{ translate: string }>).response?.data!
						.translate ||
					(error as AxiosError<{ message: string }>).response?.data.message;

				setToast('error', msg as string);
			}
		} finally {
			closeAlert();
			dispatch(fullLoadingOff());
		}
	};

	const renderContent = () => {
		return (
			<>
				<Form layout="vertical">
					<FormItem
						name="serviceDate"
						label={
							<Typo $typoType="label2" color="gray_11">
								게시 날짜
							</Typo>
						}
						rules={[{ required: true }]}
						colon={false}
					>
						<DateTimePicker
							allowClear={false}
							style={{ width: '100%', height: 32 }}
							defaultValue={releasedDate}
							showTime={{ defaultValue: dayjs() }}
							format="YYYY-MM-DD"
							className="antd-date-picker"
							onOk={(value) => setReleasedDate(value)}
							suffixIcon={
								<Icon src={grayCalendarIcon} width={20} height={20} />
							}
						/>
					</FormItem>
				</Form>
				<Editor
					value={editorValue}
					onChange={(value, _, __, editor) => {
						setEditorValue(value);
						setIsEditorContentEmpty(editor.getLength() === 1);
					}}
				/>
			</>
		);
	};

	return (
		<Modal
			title={<Typo $typoType="h2">약관 이력 등록</Typo>}
			open={isVisible}
			onCancel={onClose}
			width={980}
			destroyOnClose
			footer={
				<ModalFooterWrapper>
					<Button $buttonType={ButtonTypeType.OPAQUE} onClick={onClose}>
						닫기
					</Button>
					<Button onClick={handleClickRegister} disabled={isEditorContentEmpty}>
						약관 등록
					</Button>
				</ModalFooterWrapper>
			}
		>
			<ModalContentContainer>{renderContent()}</ModalContentContainer>

			{alert === AlertState.REGISTER && (
				<Alert
					title="약관 등록"
					closeButtonClick={closeAlert}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleConfirm}
					isVisible={alert === AlertState.REGISTER}
				>
					<Typo color="gray_8">약관을 등록하시겠습니까?</Typo>
				</Alert>
			)}
		</Modal>
	);
}

export default RegisterModal;
