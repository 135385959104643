import React, { PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Aside from '../Aside';
import Header from '../Header';

interface DefaultLayoutProps {
	className?: string;
	hasAside?: boolean;
}

const GlobalStyleOverride = createGlobalStyle`
    #root {
        height: 100%;
        min-width: 1280px;
    }
`;

const DefaultLayoutContainer = styled.div<{ $isOverflowHidden: boolean }>`
	display: flex;
	flex-direction: column;
	height: 100%;

	& > default-aside {
		flex-shrink: 0;
	}

	& > .content-area {
		flex: 1;
		display: flex;
		overflow: ${({ $isOverflowHidden }) =>
			$isOverflowHidden ? 'hidden' : 'auto'};

		& > main {
			flex: 1;
			overflow: ${({ $isOverflowHidden }) =>
				$isOverflowHidden ? 'hidden' : 'auto'};
		}
	}
`;

function DefaultLayout({
	className,
	children,
	hasAside = false,
}: PropsWithChildren<DefaultLayoutProps>) {
	return (
		<>
			<GlobalStyleOverride />
			<DefaultLayoutContainer
				$isOverflowHidden={hasAside}
				className={className}
			>
				<Header />
				<div className="content-area">
					{hasAside && <Aside className="default-aside" />}
					<main>{children}</main>
				</div>
			</DefaultLayoutContainer>
		</>
	);
}

export default DefaultLayout;
