import { Radio, RadioChangeEvent, Switch } from 'antd';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Select from '../../../../components/atoms/Select';
import TextArea from '../../../../components/atoms/TextArea';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import {
	FaqCategoryResponseDto,
	FaqDto,
	FaqServiceType,
} from '../../../../interface/faq';
import { CS_FAQ_PATH } from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	getFaqCategory,
	getFaqDetail,
	useFaqDeleteMutation,
	useFaqUpdateMutation,
} from '../../../../store/apis/faq';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';

import { ButtonTypeType } from '../../../../styles/theme';
import { CUSTOMER_APP, MANAGER_APP } from '../FaqCreate';

const initialValues: FaqDto = {
	question: '',
	category: '',
	answer: '',
	viewable: true,
	service: CUSTOMER_APP as FaqServiceType,
};
const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

function FaqDetailPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const { userId } = useParams();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [isDeleteAlertVisible, setDeleteAlertVisible] = useState(false);
	const [categoryList, setCategoryList] = useState<FaqCategoryResponseDto[]>(
		[],
	);
	const [isCategoryLoading, setIsCategoryLoading] = useState(true);
	const [faqUpdate] = useFaqUpdateMutation();
	const [faqDelete] = useFaqDeleteMutation();

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};
	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleDeleteAlertOpenClick = () => {
		setDeleteAlertVisible(true);
	};
	const handleDeleteAlertCloseClick = () => {
		setDeleteAlertVisible(false);
	};

	const goToFaqList = () => {
		navigate(-1);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.question === '') {
			errors.question = '제목을 입력해주세요.';
		}
		if (values.category === '') {
			errors.category = '분류를 선택해주세요.';
		}
		if (values.answer === '') {
			errors.answer = '내용을 입력해주세요.';
		}
		if (values.service && values.service.length === 0) {
			errors.service = '서비스를 선택해주세요.';
		}

		handleAlertCloseClick();
		return errors;
	};

	const {
		handleSubmit,
		setFieldValue,
		values,
		errors,
		handleChange,
		validateForm,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (value) => {
			dispatch(fullLoadingOn());
			const res = await faqUpdate({
				id: Number(userId),
				body: value,
			});

			if ('error' in res) {
				setToast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				handleAlertCloseClick();
				setToast('info', 'FAQ가 수정되었습니다.');
			}
			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleSubmitAlertClick = async () => {
		const validateValue = await validateForm();

		if (Object.keys(validateValue).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleSubmitClick = () => {
		handleSubmit();
		handleAlertCloseClick();
	};

	const handleFaqDeleteClick = async () => {
		dispatch(fullLoadingOn());
		const res = await faqDelete({ id: Number(userId) });

		if (!('error' in res)) {
			setToast('info', 'FAQ가 삭제되었습니다.');
			navigate(CS_FAQ_PATH);
		}

		if ('error' in res) {
			setToast(
				'info',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
		dispatch(fullLoadingOff());
	};

	const getFaqCategoryData = useCallback(async () => {
		try {
			const data = await getFaqCategory();
			setCategoryList(data);
			setIsCategoryLoading(false);
		} catch (e) {
			setIsCategoryLoading(true);

			const err = e as AxiosError<{ message: string; translate: string }>;
			if (err.response)
				setToast(
					'error',
					err.response.data.translate || err.response.data.message,
				);
		}
	}, [setToast]);

	useEffect(() => {
		(async () => {
			try {
				dispatch(fullLoadingOn());
				const data = await getFaqDetail(Number(userId));
				setFieldValue('question', data.question);
				setFieldValue('category', data.category);
				setFieldValue('answer', data.answer);
				setFieldValue('service', data.service);
			} catch (e) {
				const err = e as AxiosError<{ message: string; translate: string }>;

				if (err.response)
					setToast(
						'error',
						err.response.data.translate || err.response.data.message,
					);
			} finally {
				dispatch(fullLoadingOff());
			}
		})();
	}, [dispatch, setFieldValue, setToast, userId]);

	useEffect(() => {
		getFaqCategoryData();
	}, [getFaqCategoryData]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="FAQ 상세"
				onBack={goToFaqList}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.GHOST_DANGER}
							onClick={handleDeleteAlertOpenClick}
							size="small"
						>
							삭제
						</Button>
						<Button onClick={handleSubmitAlertClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="FAQ 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="viewable" essential>
								<span>
									<Typo $typoType="label2">사용유무</Typo>
								</span>
							</Label>
							<Switch
								onChange={(value: boolean) => {
									setFieldValue('viewable', value);
								}}
								checked={values.viewable}
							/>
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="service" essential>
								<span>
									<Typo $typoType="label2">적용할 서비스</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setFieldValue('service', e.target.value);
								}}
								value={values.service}
							>
								<Radio value={CUSTOMER_APP}>
									<Typo $typoType="label1">소비자 앱</Typo>
								</Radio>
								<Radio value={MANAGER_APP}>
									<Typo $typoType="label1">매니저 앱</Typo>
								</Radio>
							</Radio.Group>
							{errors.service && (
								<InputAlert $inputStatus="error">{errors.service}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="category" essential>
								<span>
									<Typo $typoType="label2">분류</Typo>
								</span>
							</Label>
							<Select
								value={values.category}
								loading={isCategoryLoading}
								onChange={(value: string) => setFieldValue('category', value)}
								placeholder="분류를 선택해주세요"
							>
								{categoryList.map((option, idx) => (
									<Select.Option value={option.key} key={idx.toString()}>
										{option.value}
									</Select.Option>
								))}
							</Select>
						</ContentBoxItem>

						<ContentBoxItem>
							<Label name="question" essential>
								<span>
									<Typo $typoType="label2">FAQ 제목</Typo>
								</span>
							</Label>
							<Input
								name="question"
								placeholder="FAQ 제목을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.question as string}
								onChange={handleChange}
								onReset={() => setFieldValue('question', '')}
							/>
							{errors.question && (
								<InputAlert $inputStatus="error">{errors.question}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="subscribeProductTextArea" essential>
								<span>
									<Typo $typoType="label2">FAQ 내용</Typo>
								</span>
							</Label>
							<TextArea
								placeholder="상세 설명을 입력해 주세요"
								value={values.answer}
								onChange={(e) => setFieldValue('answer', e.target.value)}
							/>
							{errors.answer && (
								<InputAlert $inputStatus="error">{errors.answer}</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible ? (
				<Alert
					title="FAQ 저장"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="저장하기"
					onConfirmButtonClick={handleSubmitClick}
					isVisible={isAlertVisible}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							FAQ를 저장하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}

			{isDeleteAlertVisible ? (
				<Alert
					title="FAQ 삭제"
					closeButtonClick={handleDeleteAlertCloseClick}
					onConfirmButtonText="삭제하기"
					onConfirmButtonClick={handleFaqDeleteClick}
					isVisible={isDeleteAlertVisible}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							FAQ를 삭제하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default FaqDetailPage;
