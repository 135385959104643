const departmentInfoTableKey = {
	createdAt: {
		key: 'createdAt',
		label: '등록일자',
	},
	company: {
		key: 'company',
		label: '법인명',
	},
};

export default departmentInfoTableKey;
