import {
	MakeVehicleSubscriptionConsultingDto,
	UpdateVehicleSubscriptionConsultingDto,
	VehicleSubscriptionConsultingDetailDto,
} from '../../../interface/vehicleSubscriptionConsulting';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const subscriptionConsultingApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionVehicleConsulting: builder.query<
			ListResponse<VehicleSubscriptionConsultingDetailDto>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle-consultations`,
				params: args,
			}),
			providesTags: ['VehicleSubscriptionConsulting'],
		}),

		getSubscriptionVehicleConsultingDetail: builder.query<
			ObjectResponse<VehicleSubscriptionConsultingDetailDto>,
			{ id: number }
		>({
			query: ({ id }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle-consultations/${id}`,
			}),
			providesTags: (args) => [
				{ type: 'VehicleSubscriptionConsulting', id: args?.row.id },
			],
		}),

		// 상담내역 변경 내역 조회
		getSubscriptionVehicleConsultingHistory: builder.query<
			ListResponse<VehicleSubscriptionConsultingDetailDto>,
			{ id: number | undefined; page: number; pageSize: number }
		>({
			query: ({ id, page, pageSize }) => ({
				method: 'GET',
				url: `/members/subscriptions-vehicle-consultations/${id}/histories`,
				params: { page, pageSize },
			}),
			providesTags: ['VehicleSubscriptionConsulting'],
		}),

		makeSubscriptionVehicleConsulting: builder.mutation<
			ObjectResponse<VehicleSubscriptionConsultingDetailDto>,
			MakeVehicleSubscriptionConsultingDto
		>({
			query: (body) => ({
				method: 'POST',
				url: `/members/subscriptions-vehicle-consultations`,
				body,
			}),
			invalidatesTags: ['VehicleSubscriptionConsulting'],
		}),

		updateSubscriptionVehicleConsulting: builder.mutation<
			ObjectResponse<VehicleSubscriptionConsultingDetailDto>,
			{
				consultationId: number;
				body: UpdateVehicleSubscriptionConsultingDto;
			}
		>({
			query: ({ consultationId, body }) => ({
				method: 'PUT',
				url: `/members/subscriptions-vehicle-consultations/${consultationId}`,
				body,
			}),
			invalidatesTags: ['VehicleSubscriptionConsulting'],
		}),
	}),
});

export const {
	useGetSubscriptionVehicleConsultingQuery,
	useGetSubscriptionVehicleConsultingDetailQuery,
	useGetSubscriptionVehicleConsultingHistoryQuery,
	useMakeSubscriptionVehicleConsultingMutation,
	useUpdateSubscriptionVehicleConsultingMutation,
} = subscriptionConsultingApi;
export default subscriptionConsultingApi;
