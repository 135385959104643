import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { persistor } from '../../..';
import { mainLogoIcon } from '../../../assets/icon';
import { LOGIN_PATH } from '../../../routes/constants/urls';
import { RootState } from '../../../store';
import { useLogoutMutation } from '../../../store/apis/auth';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Typo from '../../atoms/Typo';
import { asideMenuHandler } from '../../../store/menuOpen';
import { ButtonTypeType } from '../../../styles/theme';

interface HeaderProps {
	className?: string;
}

const HeaderLayoutContainer = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 11px 20px;
	border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_3};

	.icon-and-menu {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.user-info {
		display: flex;
		align-items: center;
		gap: 16px;
	}
`;

function Header({ className }: HeaderProps) {
	const dispatch = useDispatch();
	const sessionId = useSelector((state: RootState) => state.auth.user?.id);
	const defaultPath = useSelector((state: RootState) => state.auth.defaultPath);
	const sessionUserName = useSelector(
		(state: RootState) => state.auth.user?.administratorName,
	);
	const navigate = useNavigate();
	const [logout] = useLogoutMutation();

	const isLogin = !!sessionId;

	const handleLogOutClick = async () => {
		localStorage.removeItem(`tutorialguide_${sessionId}`);

		await logout();
		await persistor.purge();

		await navigate('/login');
	};

	const handleAsideMenuOpen = () => {
		dispatch(asideMenuHandler());
	};

	return (
		<HeaderLayoutContainer className={className}>
			<div className="icon-and-menu">
				<Link to={defaultPath || LOGIN_PATH}>
					<Icon src={mainLogoIcon} width={138} height={26} />
				</Link>
				{isLogin && (
					<Button
						onClick={handleAsideMenuOpen}
						$buttonType={ButtonTypeType.GHOST_BLACK}
						size="x-small"
					>
						메뉴
					</Button>
				)}
			</div>
			{isLogin && (
				<div className="user-info">
					<Typo $typoType="h7">{sessionUserName}</Typo>
					<Button
						onClick={handleLogOutClick}
						$buttonType={ButtonTypeType.GHOST_BLACK}
						size="x-small"
					>
						로그아웃
					</Button>
				</div>
			)}
		</HeaderLayoutContainer>
	);
}

export default Header;
