import React, { useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Col, Radio, RadioChangeEvent, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
import diff from 'microdiff';
import _ from 'lodash';
import { GridApi } from 'ag-grid-community';
// eslint-disable-next-line import/no-extraneous-dependencies
import Joyride, { Step } from 'react-joyride';
import { ToastContext } from '../../../contexts/Toast';
import {
	carLoadingBox,
	MakeVehicleDto,
	VehicleDetailDto,
	VehicleHistoryChange,
} from '../../../interface/vehicle';
import {
	useDeleteVehicleMutation,
	useMakeVehicleMutation,
	useUpdateVehicleMutation,
} from '../../../store/apis/vehicle';
import useCheckRole from '../../../hooks/useCheckRole';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	PLATES_DETAIL_PATH,
	VEHICLES_CREATE_PATH,
	VEHICLES_MANAGEMENT_PATH,
} from '../../../routes/constants/urls';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import Alert from '../../atoms/Alert';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import theme, { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import InputForm from '../../molecules/InputForm';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import InputAlert from '../../atoms/InputAlert';
import DateTimePicker from '../../atoms/DateTimePicker';
import TextArea from '../../atoms/TextArea';
import SelectionModal from '../../organisms/SelectionModal';
import { useGetVehiclePlatesQuery } from '../../../store/apis/vehiclePlate';
import {
	companyModalColumn,
	departmentModalColumn,
	driverModalColumn,
	plateModalColumn,
} from '../modalColumn';
import { useGetDepartmentsQuery } from '../../../store/apis/department';
import {
	companyRegisterNumberFormat,
	getPriceAsNumber,
	numberWithCommas,
	phoneFormat,
} from '../../../utils/data-format';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import { DepartmentDto } from '../../../interface/department';
import { useGetCompaniesQuery } from '../../../store/apis/company';
import { CompanyDto } from '../../../interface/company';
import {
	useGetDriversOfCompanyQuery,
	useGetMyDriversQuery,
} from '../../../store/apis/driver';
import { DriverDto } from '../../../interface/driver';
import Divider from '../../../styles/divider';
import Checkbox from '../../atoms/Checkbox';
import Icon from '../../atoms/Icon';
import { detailIcon } from '../../../assets/icon';
import HistoryDetail from '../../molecules/HistoryDetail';
import VehicleHistoryChangeKeys from '../../../pages/AssetManagement/VehiclesManagement/VehiclesDetail/gridData';
import sortedColsList from '../../../utils/historyDetail-util';
import markTutorialCompleted from '../../../utils/tutorial-utils';
import { RootState } from '../../../store';
import useTutorialGuide from '../../../hooks/useTutorialGuide';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

interface VehicleTemplateProp {
	gridRef?: (api: GridApi) => void;
	initialValues: MakeVehicleDto;
	// 차량 생성시, 사용 안함
	extraInfo?: VehicleDetailDto;
	totalCount?: number;
	rowData?: Partial<VehicleHistoryChange>[];
	currentPage?: number;
	currentPageSize?: number;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (page: number, size: number) => void;
	isFetching?: boolean;
	vehiclePurchasePrice?: number;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
	DELETE,
}

const RowFlexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const ColumnFlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const VehicleTemplate = ({
	initialValues,
	extraInfo,
	totalCount = 0,
	rowData = [],
	currentPage = DEFAULT_CURRENT_PAGE,
	currentPageSize = DEFAULT_PAGE_SIZE,
	onPageChange,
	onPageSizeChange,
	gridRef,
	isFetching = false,
	vehiclePurchasePrice = 0,
}: VehicleTemplateProp) => {
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;
	const { id } = useParams();
	const tutorialId = 'vehiclesDetail';
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const isVehicleCreatePage = location.pathname === VEHICLES_CREATE_PATH;
	const [isCompanyModal, setIsCompanyModal] = useState(false);
	const [isDepartmentModal, setIsDepartmentModal] = useState(false);
	const [isDriverModal, setIsDriverModal] = useState(false);
	const [isPlateModal, setIsPlateModal] = useState(false);
	const [isOptionalPlateNumber, setIsOptionalPlateNumber] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [selectedCompanyInfo, setSelectedCompanyInfo] = useState<
		CompanyDto | undefined
	>(undefined);
	const [selectedDepartmentInfo, setSelectedDepartmentInfo] = useState<
		DepartmentDto | undefined
	>(undefined);
	const [selectedDriverInfo, setSelectedDriverInfo] = useState<
		DriverDto | undefined
	>(undefined);

	const [makeVehicle] = useMakeVehicleMutation();
	const [updateVehicle] = useUpdateVehicleMutation();
	const [deleteVehicle] = useDeleteVehicleMutation();

	const isCreateVehicleInfo = useCheckRole({
		roleType: 'isCreate',
		roleName: '차량관리',
	});
	const isUpdateVehicleInfo = useCheckRole({
		roleType: 'isUpdate',
		roleName: '차량관리',
	});
	const isSuperVehicleInfo = useCheckRole({
		roleType: 'isUpdate',
		roleName: '차량관리',
		includeSuper: true,
	});
	const isDeleteVehicle = useCheckRole({
		roleType: 'isDelete',
		roleName: '차량관리',
		includeSuper: true,
	});
	const isSuperDriver = useCheckRole({
		roleType: 'isRead',
		roleName: '법인관리',
		includeSuper: true,
	});

	const isGetVehiclePurchasePrice = useCheckRole({
		roleType: 'isRead',
		roleName: '차량관리(취득가)',
		includeSuper: true,
	});

	const vehicleHistoryChangeValues = rowData?.map((vehicleHistory) => {
		return {
			id: vehicleHistory.id,
			createdAt: vehicleHistory.createdAt,
			memo: vehicleHistory.memo,
			status: vehicleHistory.payload?.status,
			ownerCompanyName: vehicleHistory.payload?.ownerCompany?.name,
			departmentName: vehicleHistory.payload?.department?.departmentName,
			administratorName: vehicleHistory.admin?.administratorName,
			vin: vehicleHistory.payload?.vin,
			vehicleNumber: vehicleHistory.payload?.vehicleNumber,
			vehicleCategory: vehicleHistory.payload?.vehicleCategory,
			manufacturingCompany: vehicleHistory.payload?.manufacturingCompany,
			vehicleName: vehicleHistory.payload?.vehicleName,
			factoryPrice: vehicleHistory.payload?.factoryPrice,
			purchasePrice: vehicleHistory.payload?.purchasePrice,
			purchaseDate: vehicleHistory.payload?.purchaseDate,
			salePrice: vehicleHistory.payload?.salePrice,
			saleDate: vehicleHistory.payload?.saleDate,
			transmission: vehicleHistory.payload?.transmission,
			fuelType: vehicleHistory.payload?.fuelType,
			color: vehicleHistory.payload?.color,
			loadingCapacity: vehicleHistory.payload?.loadingCapacity,
			carLoadingBoxType: vehicleHistory.payload?.carLoadingBoxType,
			vehicleYear: vehicleHistory.payload?.vehicleYear,
			legalInspectionDate: vehicleHistory.payload?.legalInspectionDate,
			legalInspectionEndDate: vehicleHistory.payload?.legalInspectionEndDate,
			importDomestic: vehicleHistory.payload?.importDomestic,
			isDtgInstalled: vehicleHistory.payload?.isDtgInstalled,
			lastInspectionDate: vehicleHistory.payload?.lastInspectionDate,
			insurer: vehicleHistory.payload?.insurer,
			insuranceAge: vehicleHistory.payload?.insuranceAge,
			insurancePremiums: vehicleHistory.payload?.insurancePremiums,
			insuranceLossPct: vehicleHistory.payload?.insuranceLossPct,
			insuranceStartDate: vehicleHistory.payload?.insuranceStartDate,
			insuranceEndDate: vehicleHistory.payload?.insuranceEndDate,
			insuranceInPersonam: vehicleHistory.payload?.insuranceInPersonam,
			insuranceInRem: vehicleHistory.payload?.insuranceInRem,
			insuranceSelf: vehicleHistory.payload?.insuranceSelf,
			insuranceSelfVehicle: vehicleHistory.payload?.insuranceSelfVehicle,
			insuranceDriverType: vehicleHistory.payload?.insuranceDriverType,
			insuranceIndemnification:
				vehicleHistory.payload?.insuranceIndemnification,
		};
	});

	const { colsList } = VehicleHistoryChangeKeys;

	// 취득가 권한이 없을 경우, 취득가 컬럼을 필터링
	let filteredCols = colsList;
	if (!isGetVehiclePurchasePrice) {
		filteredCols = colsList.filter(
			(col) => col.tableInfo.field !== 'purchasePrice',
		);
	}

	const fixedColumnsKeys = ['createdAt', 'memo', 'administratorName'];

	const sortedColsListResult = sortedColsList(
		rowData,
		filteredCols,
		fixedColumnsKeys,
	);

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.vin === '') {
			errors.vin =
				'차대번호를 한글자 이상 입력해주세요. 이미 차대번호가 입력된 차량은 차대번호를 지울 수 없습니다.';
		}
		return errors;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const result = await makeVehicle(values as MakeVehicleDto);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 등록이 완료되었습니다.');
			navigate(VEHICLES_MANAGEMENT_PATH);
		}
	};

	const diffUpdateValues = useCallback(
		(initValues: typeof initialValues, values: typeof initialValues) => {
			const diffValues = diff(initValues, values);
			const updateValues: MakeVehicleDto = {};

			diffValues.map((item) => {
				if (item.type === 'CREATE' || item.type === 'CHANGE') {
					_.set(updateValues, item.path.join('.'), item.value);
				}
				return null;
			});
			return updateValues;
		},
		[],
	);

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const updateValues: MakeVehicleDto = diffUpdateValues(
			initialValues,
			values,
		);
		const result = await updateVehicle({
			body: updateValues,
			id: Number(id),
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 수정이 완료되었습니다.');
			navigate(VEHICLES_MANAGEMENT_PATH);
		}
	};

	const handleDelete = useCallback(async () => {
		const result = await deleteVehicle({ id: Number(id) });

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량이 삭제되었습니다.');
			navigate(VEHICLES_MANAGEMENT_PATH);
		}
	}, [deleteVehicle, id, navigate, toast]);

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			if (isVehicleCreatePage) {
				await handleCreateSubmit(values);
			} else {
				await handleUpdateSubmit(values);
			}

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleCancelClick = () => {
		navigate(VEHICLES_MANAGEMENT_PATH);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.CREATE);
		}
	};

	const handleUpdateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.UPDATE);
		}
	};

	const handleDeleteClick = async () => {
		setAlertState(AlertState.DELETE);
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="차량 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						차량을 등록하시겠습니까?
					</Alert>
				);
			case AlertState.UPDATE:
				return (
					<Alert
						title="차량 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						<>
							<ColumnFlexWrapper>
								{_.keys(diffUpdateValues(initialValues, formik.values))
									.length ? (
									_.keys(diffUpdateValues(initialValues, formik.values)).map(
										(key) =>
											key !== 'memo' && (
												<div key={key}>
													<Typo>{_.get(initialValues, key)}</Typo>
													<Typo>{` >> `}</Typo>
													<Typo>{_.get(formik.values, key)}</Typo>
												</div>
											),
									)
								) : (
									<Typo>수정사항 없음</Typo>
								)}
							</ColumnFlexWrapper>
							<TextArea
								name="memo"
								placeholder="수정 사유를 적어주세요. (선택 입력)"
								value={formik.values.memo}
								onChange={formik.handleChange}
							/>
						</>
					</Alert>
				);

			case AlertState.DELETE:
				return (
					<Alert
						title="차량 삭제"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="삭제하기"
						onConfirmButtonClick={() => handleDelete()}
						isVisible={alertState === AlertState.DELETE}
					>
						차량을 삭제하시겠습니까?
					</Alert>
				);
			default:
				return null;
		}
	}, [alertState, diffUpdateValues, formik, handleDelete, initialValues]);

	const handleTutorialCompletion = () => {
		markTutorialCompleted(userId!, tutorialId); // Pass userId and tutorialKey
	};

	const steps: Step[] = [
		{
			content: (
				<div style={{ textAlign: 'left', padding: '1rem' }}>
					<h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
						차량 등록 페이지입니다.
					</h3>

					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						오픈트럭에서 관리하는 차량을 등록하고 수정할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'center',
			target: 'body',
		},
		{
			title: '차량 소유자 및 소속 배정',
			content: (
				<div style={{ textAlign: 'left' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							&bull; <strong>차량 소유자:</strong> 등록된 법인 중에 선택할 수
							있습니다.
						</li>
						<li>
							&bull; <strong>소속:</strong> 차량을 현재 이용하고 있는 곳으로
							등록된 소속 중에 선택할 수 있습니다. 다른 소유자의 소속을 선택할
							수 있습니다.
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'right-start',
			target: '#step-1',
		},
		{
			title: '차량번호 관련 디테일',
			content: (
				<div style={{ textAlign: 'left' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							<span style={{ color: 'red' }}>&bull; (필수) </span>
							<strong>차대번호:</strong> 차량을 구분하는 값이기 때문에 반드시
							입력합니다. 차대번호를 모르는 경우에는 나중에 식별할 수 있는
							값으로 자유롭게 입력할수 있습니다. 예) KMHEL13CPYA000001 또는
							2024-07-31 매입차량 포터1
						</li>
						<li>
							&bull; <strong>차량번호:</strong> 등록된 번호판 중에서 선택하거나
							임의로 입력할 수 있습니다. 임의로 입력한 넘버가 등록된 번호판의
							넘버와 동일하면 자동으로 매칭됩니다.
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'right-start',
			target: '#step-2',
		},
		{
			title: '차량 상세 디테일',
			content: (
				<div style={{ textAlign: 'left', overflow: 'scroll', height: '200px' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							&bull; <strong>차군:</strong> 해당 차량의 차군을 입력합니다. 예)
							5톤
						</li>
						<li>
							&bull; <strong>제조사:</strong> 제조사를 입력합니다. 예) BMW
						</li>
						<li>
							&bull; <strong>차종명:</strong> 차종명을 입력합니다. 예) 520d
						</li>
						<li>
							&bull; <strong>신차가(출고가):</strong> 신차가를 입력합니다. 예)
							50,000,000원
						</li>
						<li>
							&bull; <strong>취득가(매입가):</strong> 취득가를 입력합니다. 예)
							40,000,000원
						</li>
						<li>
							&bull; <strong>취득일자:</strong> 차량을 취득한 날짜를 입력합니다.
							예) 2024년 7월 10일
						</li>
						<li>
							&bull; <strong>미션:</strong> 미션을 선택합니다. 예) 자동 | 수동
						</li>
						<li>
							&bull; <strong>유종:</strong> 유종을 입력합니다. 예) 경유 | 휘발유
						</li>
						<li>
							&bull; <strong>색상:</strong> 차량의 색상을 입력합니다. 예) 검정
						</li>
						<li>
							&bull; <strong>적재량:</strong> 차량의 적재량을 입력합니다. 예)
							5톤
						</li>
						<li>
							&bull; <strong>탑 형식:</strong> 탑 형식을 선택합니다. 예) 하이탑
						</li>
						<li>
							&bull; <strong>차량등록일:</strong> 차량등록일을 선택합니다. 예)
							2024년 7월 10일
						</li>
						<li>
							&bull; <strong>연식:</strong> 차량의 연식을 입력합니다. 예) 2024년
						</li>
						<li>
							&bull; <strong>최종검사일:</strong> 최종검사일을 선택합니다. 예)
							2024-07-10
						</li>
						<li>
							&bull; <strong>검사종료일:</strong> 정기검사의 종료일을
							선택합니다. 예)2024-07-15
						</li>
						<li>
							&bull; <strong>수입/국산:</strong>두개중 하나를 선택합니다. 예)
							수입
						</li>
						<li>
							&bull; <strong>DTG 설치여부:</strong>두개중 하나를 선택합니다. 예)
							설치
						</li>
						<li>
							&bull; <strong>마지막 점검일:</strong> 내부에서 수행한 점검일
							기준으로 마지막날을 선택합니다.
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'right-start',
			target: '#step-3',
		},
		{
			title: '차량 보험',
			content: (
				<div style={{ textAlign: 'left' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							&bull; <strong>보험사:</strong> 보험사를 선택합니다. 예)
							DB손해보험
						</li>
						<li>
							&bull; <strong>운전자연령:</strong> 운전자의 연령대를 선택합니다.
							예) 만 21세 이상 | 만 26세 이상
						</li>
						<li>
							&bull; <strong>보험금:</strong> 보험금을 입력합니다. 예)
							1,000,000원
						</li>
						<li>
							&bull; <strong>손해율:</strong> 손해율을 입력합니다. 숫자만 예) 5
						</li>
						<li>
							&bull; <strong>보험시작일:</strong> 보험시작일을 선택합니다. 예)
							2024-07-10
						</li>
						<li>
							&bull; <strong>보험종류일:</strong> 보험종료일을 선택합니다. 예)
							2025-09-15
						</li>
						<li>
							&bull; <strong>대인배상:</strong> 대인배상을 입력합니다. 예) 무한
						</li>
						<li>
							&bull; <strong>대물배상:</strong> 대물배상을 입력합니다. 예) 무한
						</li>
						<li>
							&bull; <strong>자기신체사고:</strong> 자기신체사고을 입력합니다.
							예) 무한
						</li>
						<li>
							&bull; <strong>자차손해:</strong> 자차손해을 입력합니다. 예) 무한
						</li>
						<li>
							&bull; <strong>운전자범위:</strong>
							운전자범위를 선택합니다. 예) 법인 임직원특약
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'right-start',
			target: '#step-4',
		},
		{
			title: '사용유무',
			content: (
				<div style={{ textAlign: 'left' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							&bull; <strong>사용유무:</strong> 차량의 사용유무를 선택해주세요.
							미사용시 체크를 해지해주세요.
						</li>
						<li>
							&bull; <strong>매각가:</strong> 매각가를 입력합니다. 예)
							40,000,000원
						</li>
						<li>
							&bull; <strong>취득일자:</strong> 차량을 매각한 날짜를 입력합니다.
							매각가를 넣는 경우에 같이 입력할 수 있습니다. 예) 2024년 7월 10일
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-start',
			target: '#step-5',
		},
		{
			title: '기사 정보',
			content:
				'차량을 사용하고 있는 기사를 배정하거나 삭제할 수 있습니다. 이미 배정된 기사가 있는 경우 기존 기사 정보가 표기됩니다.',
			locale: {
				skip: (
					<strong aria-label="skip" onClick={handleTutorialCompletion}>
						건너뛰기
					</strong>
				),
				last: '닫기',
				back: '뒤로',
			},
			placement: 'left-start',
			target: '#step-6',
		},
	];

	const { handleJoyrideCallback, localStorageDetail } = useTutorialGuide(
		userId!,
		tutorialId,
		steps,
	);

	return (
		<>
			<Joyride
				continuous
				callback={handleJoyrideCallback}
				run={isVehicleCreatePage && !localStorageDetail}
				steps={steps}
				hideCloseButton
				scrollToFirstStep
				showSkipButton
				showProgress
				disableScrollParentFix
				disableScrolling
				styles={{
					overlay: { minHeight: document.body.scrollHeight },
					options: {
						width: 550,
						primaryColor: theme.common.colors.primary_6_main,
					},
				}}
			/>

			<GridDetailTemplate
				detailTitle="소속차량 관리"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						{!id ? (
							<Button
								onClick={handleCreateClick}
								size="small"
								disabled={!isCreateVehicleInfo}
							>
								{isCreateVehicleInfo ? '등록하기' : '등록 권한없음'}
							</Button>
						) : (
							<>
								<Button
									onClick={handleUpdateClick}
									size="small"
									disabled={!isUpdateVehicleInfo}
								>
									{isUpdateVehicleInfo ? '수정하기' : '수정 권한없음'}
								</Button>
								<Button
									onClick={handleDeleteClick}
									size="small"
									$buttonType={ButtonTypeType.GHOST_DANGER}
									disabled={!isDeleteVehicle}
								>
									{isDeleteVehicle ? '삭제하기' : '삭제 권한없음'}
								</Button>
							</>
						)}
					</ButtonContainer>
				}
			>
				<Row gutter={[12, 12]}>
					<Col md={12}>
						<ContentBoxWithHeader
							title={
								initialValues?.vin
									? `${initialValues?.vin} (${initialValues?.vehicleNumber})`
									: '차량 상세정보'
							}
							borderRadius="10px"
							className="inner-content"
						>
							<div id="step-1">
								{isSuperVehicleInfo && (
									<>
										<GridLayout $columnCount={3} $isCompact>
											<GridItem title="차량 소유자" $isCompact span={3}>
												<RowFlexWrapper>
													<Button
														size="small"
														$buttonType={ButtonTypeType.GHOST}
														onClick={() => setIsCompanyModal(true)}
													>
														차량 소유자 배정
													</Button>
													<Button
														size="small"
														$buttonType={ButtonTypeType.GHOST_DANGER}
														onClick={() =>
															formik.setFieldValue('ownerCompanyId', null)
														}
													>
														소유자 없음
													</Button>
												</RowFlexWrapper>
											</GridItem>
											{extraInfo?.ownerCompany && (
												<GridItem title="" $isCompact span={3}>
													<RowFlexWrapper>
														<Typo
															style={
																formik.values.ownerCompanyId === null
																	? {
																			textDecoration: 'line-through',
																	  }
																	: {}
															}
														>
															{extraInfo.ownerCompany.name}
														</Typo>{' '}
														<Typo
															style={
																formik.values.ownerCompanyId === null
																	? {
																			textDecoration: 'line-through',
																	  }
																	: {}
															}
														>
															{companyRegisterNumberFormat(
																extraInfo.ownerCompany.businessLicenseNumber,
															)}
														</Typo>
													</RowFlexWrapper>
												</GridItem>
											)}
											{selectedCompanyInfo && formik.values.ownerCompanyId && (
												<GridItem title="신규 소유자" $isCompact span={3}>
													<RowFlexWrapper>
														<Typo>{selectedCompanyInfo.name}</Typo>{' '}
														<Typo>
															{companyRegisterNumberFormat(
																selectedCompanyInfo.businessLicenseNumber,
															)}
														</Typo>
													</RowFlexWrapper>
												</GridItem>
											)}
										</GridLayout>
										<Divider $verticalGap={16} />
									</>
								)}
								{isSuperVehicleInfo && (
									<>
										<GridLayout $columnCount={3} $isCompact>
											<GridItem title="소속 배정" $isCompact span={3}>
												<RowFlexWrapper>
													<Button
														size="small"
														$buttonType={ButtonTypeType.GHOST}
														onClick={() => setIsDepartmentModal(true)}
													>
														차량 소속 배정
													</Button>
													<Button
														size="small"
														$buttonType={ButtonTypeType.GHOST_DANGER}
														onClick={() =>
															formik.setFieldValue('departmentId', null)
														}
													>
														소속 없음
													</Button>
												</RowFlexWrapper>
											</GridItem>
											{extraInfo && extraInfo.department && (
												<GridItem title="" $isCompact span={3}>
													<RowFlexWrapper>
														<Typo
															style={
																formik.values.departmentId === null
																	? {
																			textDecoration: 'line-through',
																	  }
																	: {}
															}
														>
															{extraInfo.department?.departmentName}
														</Typo>{' '}
														{extraInfo.department?.company && (
															<Typo
																style={
																	formik.values.departmentId === null
																		? {
																				textDecoration: 'line-through',
																		  }
																		: {}
																}
															>
																- {extraInfo.department?.company.name}
															</Typo>
														)}
													</RowFlexWrapper>
												</GridItem>
											)}

											{selectedDepartmentInfo && formik.values.departmentId && (
												<GridItem title="신규 소속" $isCompact span={3}>
													<RowFlexWrapper>
														<Typo>{selectedDepartmentInfo.departmentName}</Typo>
														<Typo>
															<Tag>소속의 법인</Tag>
															{selectedDepartmentInfo.company?.name}
														</Typo>
													</RowFlexWrapper>
												</GridItem>
											)}
										</GridLayout>
										<Divider $verticalGap={16} />
									</>
								)}
							</div>

							<div id="step-2">
								<GridLayout>
									<GridItem
										title="차대번호"
										$isCompact
										essential
										span={3}
										tooltip="차대번호는 중복 등록이 불가능합니다."
									>
										<Input
											name="vin"
											placeholder="차대번호를 입력해주세요"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.vin ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('vin', initialValues?.vin)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
										{formik.errors.vin && (
											<InputAlert $inputStatus="error">
												{formik.errors.vin}
											</InputAlert>
										)}
									</GridItem>
									<GridItem title="차량번호" span={3} $isCompact>
										{(isCreateVehicleInfo || isUpdateVehicleInfo) && (
											<RowFlexWrapper>
												<Button
													size="small"
													onClick={() => setIsPlateModal(true)}
												>
													번호판 배정
												</Button>
												<Button
													size="small"
													$buttonType={ButtonTypeType.GHOST_BLACK}
													onClick={() =>
														setIsOptionalPlateNumber(!isOptionalPlateNumber)
													}
												>
													번호판 임의입력
												</Button>
												<Button
													size="small"
													disabled={!formik.values.vehicleNumber}
													$buttonType={ButtonTypeType.GHOST_DANGER}
													onClick={() =>
														formik.setFieldValue('vehicleNumber', null)
													}
												>
													번호판 없음
												</Button>
											</RowFlexWrapper>
										)}
									</GridItem>
									<GridItem title="" span={3} $isCompact>
										<Input
											name="vehicleNumber"
											placeholder="차량번호를 입력해주세요"
											type="text"
											$isDisabled={
												!isOptionalPlateNumber ||
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.vehicleNumber ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('vehicleNumber', undefined)
											}
										/>
										{formik.errors.vehicleNumber && (
											<InputAlert $inputStatus="error">
												{formik.errors.vehicleNumber}
											</InputAlert>
										)}
									</GridItem>
									{extraInfo?.vehiclePlate && (
										<GridItem title="장착 번호판" span={3} $isCompact>
											<a
												href={`${PLATES_DETAIL_PATH}/${
													extraInfo?.vehiclePlate?.vehicleNumber ||
													formik.values.vehicleNumber
												}`}
												target="_blank"
												rel="noreferrer"
												style={{ display: 'flex', alignItems: 'center' }}
											>
												<Icon src={detailIcon} width={24} height={24} />
												{extraInfo?.vehiclePlate?.vehiclePlateType ||
													formik.values.vehicleNumber}
												- 상세보기
											</a>
										</GridItem>
									)}
								</GridLayout>
							</div>

							<div id="step-3">
								<GridLayout>
									<GridItem title="차군" $isCompact span={3}>
										<Input
											name="vehicleCategory"
											placeholder="차군을 입력해 주세요(ex. 소형화물/중형화물)"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.vehicleCategory ?? ''}
											onChange={(e) =>
												formik.setFieldValue(
													'vehicleCategory',
													e.target.value || null,
												)
											}
											onReset={() =>
												formik.setFieldValue('vehicleCategory', null)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
										{formik.errors.vehicleCategory && (
											<InputAlert $inputStatus="error">
												{formik.errors.vehicleCategory}
											</InputAlert>
										)}
									</GridItem>
									<GridItem title="제조사" $isCompact span={3}>
										<Select
											value={formik.values.manufacturingCompany}
											onChange={(value: string) =>
												formik.setFieldValue('manufacturingCompany', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											{['현대', '기아', '기타'].map((name) => (
												<Select.Option key={name} value={name}>
													{name}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="차종명" $isCompact span={3}>
										<Input
											name="vehicleName"
											placeholder="차종명을 입력해 주세요(ex. 포터)"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.vehicleName ?? ''}
											onChange={(e) =>
												formik.setFieldValue(
													'vehicleName',
													e.target.value || null,
												)
											}
											onReset={() => formik.setFieldValue('vehicleName', null)}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="신차가(출고가)" $isCompact span={3}>
										<Input
											name="factoryPrice"
											placeholder="신차가를 입력해 주세요"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(formik.values.factoryPrice ?? 0)}
											onChange={(e) =>
												formik.setFieldValue(
													'factoryPrice',
													getPriceAsNumber(e.target.value),
												)
											}
											onReset={() =>
												formik.setFieldValue('factoryPrice', undefined)
											}
											unitText="원"
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>

									{isGetVehiclePurchasePrice && (
										<GridItem title="취득가(매입가)" $isCompact span={3}>
											<Input
												name="purchasePrice"
												placeholder="취득가를 입력해 주세요"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												value={numberWithCommas(
													formik.values.purchasePrice !== undefined
														? formik.values.purchasePrice
														: vehiclePurchasePrice ?? 0,
												)}
												onChange={(e) =>
													formik.setFieldValue(
														'purchasePrice',
														getPriceAsNumber(e.target.value),
													)
												}
												onReset={() =>
													formik.setFieldValue('purchasePrice', undefined)
												}
												unitText="원"
											/>
										</GridItem>
									)}
									{isGetVehiclePurchasePrice && (
										<GridItem title="취득일자" $isCompact span={3}>
											<DateTimePicker
												name="purchaseDate"
												value={
													formik.values.purchaseDate === null
														? formik.values.purchaseDate
														: dayjs(formik.values.purchaseDate)
												}
												onChange={(date, dateString) => {
													formik.setFieldValue(
														'purchaseDate',
														dayjs(dateString).format('YYYY-MM-DD'),
													);
												}}
												placeholder="취득일자"
												allowClear={false}
												style={{ borderRadius: '4px' }}
												disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
											/>
										</GridItem>
									)}

									<GridItem title="미션" $isCompact span={3}>
										<Select
											value={formik.values.transmission}
											onChange={(value: string) =>
												formik.setFieldValue('transmission', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											<Select.Option value="오토">오토</Select.Option>
											<Select.Option value="수동">수동</Select.Option>
										</Select>
									</GridItem>
									<GridItem title="유종" $isCompact span={3}>
										<Input
											name="fuelType"
											placeholder="유종을 입력해 주세요(예: 디젤, LPG 등)"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.fuelType ?? ''}
											onChange={(e) =>
												formik.setFieldValue('fuelType', e.target.value || null)
											}
											onReset={() => formik.setFieldValue('fuelType', null)}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="색상" $isCompact span={3}>
										<Input
											name="color"
											placeholder="색상을 입력해주세요"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.color ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('color', initialValues?.color)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
										{formik.errors.color && (
											<InputAlert $inputStatus="error">
												{formik.errors.color}
											</InputAlert>
										)}
									</GridItem>
									<GridItem title="적재량" $isCompact span={3}>
										<Input
											name="loadingCapacity"
											placeholder="적재량을 입력해 주세요"
											type="number"
											$inputSize="small"
											$inputRadius="small"
											value={Number(formik.values.loadingCapacity) ?? 0}
											onChange={(e) =>
												formik.setFieldValue(
													'loadingCapacity',
													e.target.value !== '' ? Number(e.target.value) : 0,
												)
											}
											onReset={() => formik.setFieldValue('loadingCapacity', 0)}
											unitText="톤"
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
										{formik.errors.loadingCapacity && (
											<InputAlert $inputStatus="error">
												{formik.errors.loadingCapacity}
											</InputAlert>
										)}
									</GridItem>
									<GridItem title="탑 형식" $isCompact span={3}>
										<Select
											value={formik.values.carLoadingBoxType}
											onChange={(value: string) =>
												formik.setFieldValue('carLoadingBoxType', value)
											}
											style={{ minWidth: 200 }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											{carLoadingBox.map((name) => (
												<Select.Option key={name} value={name}>
													{name}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="차량등록일" $isCompact span={3}>
										<DateTimePicker
											name="registrationDate"
											value={dayjs(formik.values.registrationDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'registrationDate',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="차량등록일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
									<GridItem title="연식" $isCompact span={3}>
										<Input
											name="vehicleYear"
											placeholder="연식을 입력해주세요(ex. 2023)"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.vehicleYear ?? ''}
											onChange={(e) =>
												formik.setFieldValue(
													'vehicleYear',
													Number(e.target.value) || null,
												)
											}
											onReset={() => formik.setFieldValue('vehicleYear', null)}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="최종검사일" $isCompact span={3}>
										<DateTimePicker
											name="legalInspectionDate"
											value={dayjs(formik.values.legalInspectionDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'legalInspectionDate',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="최종검사일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
									<GridItem title="검사종료일" $isCompact span={3}>
										<DateTimePicker
											name="legalInspectionEndDate"
											value={dayjs(formik.values.legalInspectionEndDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'legalInspectionEndDate',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="검사종료일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
									<GridItem title="수입/국산" $isCompact span={3}>
										<Select
											value={formik.values.importDomestic}
											onChange={(value: string) =>
												formik.setFieldValue('importDomestic', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											{['국산', '수입'].map((name) => (
												<Select.Option key={name} value={name}>
													{name}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="DTG 설치여부" $isCompact span={3}>
										<Radio.Group
											onChange={(e: RadioChangeEvent) => {
												formik.setFieldValue('isDtgInstalled', e.target.value);
											}}
											value={formik.values.isDtgInstalled}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											<Radio value>
												<Typo $typoType="label1">설치</Typo>
											</Radio>
											<Radio value={false}>
												<Typo $typoType="label1">미설치</Typo>
											</Radio>
										</Radio.Group>
									</GridItem>
									<GridItem title="마지막 점검일" $isCompact span={3}>
										<DateTimePicker
											name="lastInspectionDate"
											value={dayjs(formik.values.lastInspectionDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'lastInspectionDate',
													dayjs(dateString).format('YYYYMMDD'),
												);
											}}
											placeholder="마지막 점검일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
								</GridLayout>
							</div>
						</ContentBoxWithHeader>
					</Col>

					<Col
						md={12}
						style={{
							height: '100%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '12px',
								height: '100%',
							}}
						>
							<ContentBoxWithHeader
								title="보험정보"
								borderRadius="10px"
								id="step-4"
							>
								<GridLayout $columnCount={2} $isCompact>
									<GridItem title="보험사" $isCompact>
										<Select
											value={formik.values.insurer}
											onChange={(value: string) =>
												formik.setFieldValue('insurer', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											{[
												'화물공제조합',
												'삼성화재',
												'DB손해보험',
												'캐롯손해보험',
												'AXA손해보험',
												'KB손해보험',
												'현대해상',
											].map((name) => (
												<Select.Option key={name} value={name}>
													{name}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="운전자연령" $isCompact>
										<Select
											value={formik.values.insuranceAge}
											onChange={(value: string) =>
												formik.setFieldValue('insuranceAge', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											{['만 21세 이상', '만 26세 이상'].map((name) => (
												<Select.Option key={name} value={name}>
													{name}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="보험금" $isCompact>
										<Input
											name="insurancePremiums"
											placeholder="보험금을 입력해 주세요"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(
												formik.values.insurancePremiums ?? 0,
											)}
											onChange={(e) =>
												formik.setFieldValue(
													'insurancePremiums',
													getPriceAsNumber(e.target.value),
												)
											}
											onReset={() =>
												formik.setFieldValue('insurancePremiums', undefined)
											}
											unitText="원"
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="손해율" $isCompact>
										<Input
											name="insuranceLossPct"
											placeholder="손해율을 입력해 주세요"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.insuranceLossPct ?? ''}
											onChange={(e) =>
												formik.setFieldValue(
													'insuranceLossPct',
													Number(e.target.value) || 0,
												)
											}
											onReset={() =>
												formik.setFieldValue('insuranceLossPct', '')
											}
											unitText="%"
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="보험시작일" $isCompact>
										<DateTimePicker
											name="insuranceStartDate"
											value={dayjs(formik.values.insuranceStartDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'insuranceStartDate',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="보험시작일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
									<GridItem title="보험종료일" $isCompact>
										<DateTimePicker
											name="insuranceEndDate"
											value={dayjs(formik.values.insuranceEndDate)}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'insuranceEndDate',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="보험종료일"
											allowClear={false}
											style={{ borderRadius: '4px' }}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</GridItem>
									<GridItem title="대인배상" $isCompact>
										<Input
											name="insuranceInPersonam"
											placeholder="예: 무한"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.insuranceInPersonam ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('insuranceInPersonam', undefined)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="대물배상" $isCompact>
										<Input
											name="insuranceInRem"
											placeholder="예: 1억원"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.insuranceInRem ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('insuranceInRem', undefined)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="자기신체사고" $isCompact>
										<Input
											name="insuranceSelf"
											placeholder="예: 1억원"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.insuranceSelf ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('insuranceSelf', undefined)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="자차손해" $isCompact>
										<Input
											name="insuranceSelfVehicle"
											placeholder="예: 1억원"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.insuranceSelfVehicle ?? ''}
											onChange={formik.handleChange}
											onReset={() =>
												formik.setFieldValue('insuranceSelfVehicle', undefined)
											}
											$isDisabled={
												!(isCreateVehicleInfo || isUpdateVehicleInfo)
											}
										/>
									</GridItem>
									<GridItem title="운전자범위" $isCompact>
										<Select
											value={formik.values.insuranceDriverType}
											onChange={(value: string) =>
												formik.setFieldValue('insuranceDriverType', value)
											}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										>
											<Select.Option value="개인">
												개인/개인사업자
											</Select.Option>
											<Select.Option value="법인">
												법인 임직원특약
											</Select.Option>
										</Select>
									</GridItem>
									<InputForm>
										<Label name="insuranceIndemnification">
											<span className="label" hidden>
												<Typo $typoType="label2">면책제도</Typo>
											</span>
										</Label>
										<TextArea
											placeholder="면책제도"
											name="insuranceIndemnification"
											value={formik.values.insuranceIndemnification}
											onChange={formik.handleChange}
											disabled={!(isCreateVehicleInfo || isUpdateVehicleInfo)}
										/>
									</InputForm>
								</GridLayout>
							</ContentBoxWithHeader>

							<ContentBoxWithHeader
								title="사용유무"
								borderRadius="10px"
								id="step-5"
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '8px',
									}}
								>
									<Checkbox
										onClick={() =>
											formik.setFieldValue(
												'status',
												formik.values.status === 'ACTIVE'
													? 'INACTIVE'
													: 'ACTIVE',
											)
										}
										name="status"
										isChecked={formik.values.status === 'ACTIVE'}
									/>
									{isGetVehiclePurchasePrice && (
										<GridLayout $columnCount={1} $isCompact>
											<GridItem title="매각가" $isCompact span={3}>
												<Input
													name="salePrice"
													placeholder="매각가를 입력해 주세요"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													value={numberWithCommas(
														formik.values.salePrice !== undefined
															? formik.values.salePrice
															: vehiclePurchasePrice ?? 0,
													)}
													onChange={(e) =>
														formik.setFieldValue(
															'salePrice',
															getPriceAsNumber(e.target.value),
														)
													}
													onReset={() =>
														formik.setFieldValue('salePrice', undefined)
													}
													unitText="원"
												/>
											</GridItem>
											<GridItem title="매각일자" $isCompact span={3}>
												<DateTimePicker
													name="saleDate"
													value={
														formik.values.saleDate === null
															? formik.values.saleDate
															: dayjs(formik.values.saleDate)
													}
													onChange={(date, dateString) => {
														formik.setFieldValue(
															'saleDate',
															dayjs(dateString).format('YYYY-MM-DD'),
														);
													}}
													placeholder="매각일자"
													allowClear={false}
													style={{ borderRadius: '4px' }}
													disabled={
														!(isCreateVehicleInfo || isUpdateVehicleInfo)
													}
												/>
											</GridItem>
										</GridLayout>
									)}
								</div>
							</ContentBoxWithHeader>
							{isSuperVehicleInfo && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: '8px',
									}}
									id="step-6"
								>
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST}
										onClick={() => setIsDriverModal(true)}
									>
										기사 배정
									</Button>
									{formik.values.driverId !== null && (
										<Button
											size="small"
											$buttonType={ButtonTypeType.GHOST_DANGER}
											onClick={() => {
												formik.setFieldValue('driverId', null);
												setSelectedDriverInfo(undefined);
											}}
										>
											기사 삭제
										</Button>
									)}

									{initialValues.driverId && selectedDriverInfo && (
										<Button
											size="small"
											$buttonType={ButtonTypeType.GHOST_DANGER}
											disabled={!selectedDriverInfo}
											onClick={() => {
												formik.setFieldValue('driverId', undefined);
												setSelectedDriverInfo(undefined);
											}}
										>
											새로 배정한 기사 삭제
										</Button>
									)}
								</div>
							)}

							{selectedDriverInfo && (
								<ContentBoxWithHeader
									title="새로 배정할 기사"
									borderRadius="10px"
								>
									<GridLayout $columnCount={2}>
										<GridItem title="이름" size={GridTitleSize.GT_MEDIUM}>
											{selectedDriverInfo.driverName}
										</GridItem>
										<GridItem title="전화번호" size={GridTitleSize.GT_MEDIUM}>
											{phoneFormat(selectedDriverInfo.driverPhone)}
										</GridItem>
										<GridItem
											title="연결된 회원계정"
											size={GridTitleSize.GT_MEDIUM}
										>
											{selectedDriverInfo.customer?.customerApplicationId}
										</GridItem>
									</GridLayout>
								</ContentBoxWithHeader>
							)}

							{/* 새로 기사를 배정하지 않을 때는 formik.values.driverId === undefined, 기존에 기사가 있는 경우에도 변경하지 않는다 */}
							{/* 기사를 아예 배정하지 않을 때는 formik.values.driverId === null, 기존의 기사도 삭제한다 */}
							{formik.values.driverId !== null && initialValues.driverId && (
								<ContentBoxWithHeader
									title="기존 기사 정보"
									borderRadius="10px"
								>
									<GridLayout $columnCount={2}>
										<GridItem title="이름" size={GridTitleSize.GT_MEDIUM}>
											{extraInfo?.driver?.driverName}
										</GridItem>
										<GridItem title="전화번호" size={GridTitleSize.GT_MEDIUM}>
											{phoneFormat(extraInfo?.driver?.driverPhone ?? '')}
										</GridItem>
										<GridItem
											title="연결된 회원계정"
											size={GridTitleSize.GT_MEDIUM}
										>
											{extraInfo?.driver?.customer?.customerApplicationId}
										</GridItem>
									</GridLayout>
								</ContentBoxWithHeader>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={24}>
						{!isVehicleCreatePage && (
							<HistoryDetail
								gridRef={gridRef}
								gridTitle="차량정보 변경 내역"
								colsList={sortedColsListResult}
								isFetching={isFetching || false}
								rowData={vehicleHistoryChangeValues || []}
								totalCount={totalCount || 0}
								currentPage={currentPage ?? DEFAULT_CURRENT_PAGE}
								currentPageSize={currentPageSize ?? DEFAULT_PAGE_SIZE}
								handlePageChange={onPageChange || (() => {})}
								handlePageSizeChange={onPageSizeChange || (() => {})}
							/>
						)}
					</Col>
				</Row>
			</GridDetailTemplate>
			{renderAlert()}
			{isCompanyModal && (
				<SelectionModal
					isVisible={isCompanyModal}
					title="차량 소유자 배정"
					onClose={() => setIsCompanyModal(false)}
					onChange={(e) => {
						formik.setFieldValue('ownerCompanyId', e[0].id);
						setSelectedCompanyInfo(e[0]);
					}}
					column={companyModalColumn.colsList}
					useQuery={useGetCompaniesQuery}
					searchInput={companyModalColumn.forms}
					defaultSelectedIdList={[formik.values.ownerCompanyId]}
				/>
			)}
			{isDepartmentModal && (
				<SelectionModal
					isVisible={isDepartmentModal}
					title="차량 소속 배정"
					onClose={() => setIsDepartmentModal(false)}
					onChange={(e) => {
						formik.setFieldValue('departmentId', e[0].id);
						setSelectedDepartmentInfo(e[0]);
					}}
					column={departmentModalColumn.colsList}
					useQuery={useGetDepartmentsQuery}
					defaultQueryParams={{ service: 'CUSTOMER_APP' }}
					searchInput={departmentModalColumn.forms}
					defaultSelectedIdList={[formik.values.departmentId]}
				/>
			)}
			{isPlateModal && (
				<SelectionModal
					isVisible={isPlateModal}
					title="번호판 배정"
					onClose={() => setIsPlateModal(false)}
					onChange={(e) => {
						formik.setFieldValue('vehicleNumber', e[0].vehicleNumber);
						setIsOptionalPlateNumber(false);
					}}
					column={plateModalColumn.colsList}
					useQuery={useGetVehiclePlatesQuery}
					searchInput={plateModalColumn.forms}
					defaultSelectedIdList={[formik.values.vehicleNumber]}
				/>
			)}
			{isDriverModal && (
				<SelectionModal
					isVisible={isDriverModal}
					title="기사 배정"
					onClose={() => setIsDriverModal(false)}
					onChange={(e) => {
						formik.setFieldValue('driverId', e[0].id);
						setSelectedDriverInfo(e[0]);
					}}
					column={driverModalColumn.colsList}
					useQuery={
						isSuperDriver ? useGetDriversOfCompanyQuery : useGetMyDriversQuery
					}
					searchInput={driverModalColumn.forms}
					defaultSelectedIdList={[formik.values.driverId]}
				/>
			)}
		</>
	);
};
export default VehicleTemplate;
