import React from 'react';
import userData from './gridData';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import { useGetPublicCorpCarServiceQuery } from '../../../store/apis/carService';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';

const CarServicePublicCorp = () => {
	const { formInitialValues, colsList, forms } = userData;
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetPublicCorpCarServiceQuery,
		detailPath: '',
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="공단검사결과 조회"
			gridTitle="공단검사결과 조회"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			$isCompact
		/>
	);
};

export default CarServicePublicCorp;
