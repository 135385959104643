import React from 'react';
import { Col, Row, Skeleton, Table } from 'antd';
import { useGetCarServiceTransfersQuery } from '../../../store/apis/carService';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';

const transactionColumns = [
	{
		title: '순번',
		dataIndex: 'duzonTransferRequestSequenceNo',
		key: 'duzonTransferRequestSequenceNo',
	},
	{
		title: '이체일자',
		dataIndex: 'duzonTransferRequestDate',
		key: 'duzonTransferRequestDate',
	},
	{
		title: '상태',
		dataIndex: 'duzonTransferRequestStatus',
		key: 'duzonTransferRequestStatus',
		render: (text: string) => {
			let status;
			switch (text) {
				case 'C':
					status = '이체전';
					break;
				case 'R':
					status = '이체중';
					break;
				case 'Y':
					status = '이체성공';
					break;
				case 'N':
					status = '취소';
					break;
				case 'F':
					status = '실패';
					break;
				case 'E':
					status = '오류';
					break;
				default:
					status = text;
			}
			return <span style={{ fontWeight: 'bold' }}>{status}</span>;
		},
	},
	{
		title: '금액',
		dataIndex: 'duzonTransferRequestPrice',
		key: 'duzonTransferRequestPrice',
	},
	{
		title: '예금주',
		dataIndex: 'duzonTransferRequestName',
		key: 'duzonTransferRequestName',
	},
	{
		title: '계좌번호',
		dataIndex: 'duzonTransferRequestAccount',
		key: 'duzonTransferRequestAccount',
	},
	{
		title: '은행',
		dataIndex: 'duzonTransferRequestBank',
		key: 'duzonTransferRequestBank',
	},
	{
		title: '지급확인일',
		dataIndex: 'duzonTransferConfirmationDate',
		key: 'duzonTransferConfirmationDate',
	},

	{
		title: '지급확인메모',
		dataIndex: 'duzonTransferRequestMemo',
		key: 'duzonTransferRequestMemo',
	},
];

interface TransferHistoryTabPaneProps {
	inspectionId: string | undefined;
}

const TransferHistoryTabPane = ({
	inspectionId,
}: TransferHistoryTabPaneProps) => {
	const { isLoading: isTransfersInfoLoading, data: transfersInfoData } =
		useGetCarServiceTransfersQuery({ inspectionId }, { skip: !inspectionId });

	const transfersInfo = transfersInfoData ? transfersInfoData.rows : [];

	return (
		<Row gutter={[6, 6]}>
			<Col span={24}>
				<ContentBoxWithHeader
					title="계좌이체 결과"
					borderRadius="10px"
					$isCompact
				>
					{isTransfersInfoLoading ? (
						<Skeleton active />
					) : (
						<Table
							dataSource={transfersInfo}
							columns={transactionColumns}
							size="small"
							pagination={{ pageSize: 5 }}
							rowKey={(record) => record.duzonTransferRequestSequenceNo}
						/>
					)}
				</ContentBoxWithHeader>
			</Col>
		</Row>
	);
};

export default TransferHistoryTabPane;
