import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { whiteListIcon, whiteMapIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';

type ViewPoint$buttonType = 'list' | 'map';

interface ViewPointButtonProps {
	type: ViewPoint$buttonType;
	onClick: () => void;
	className?: string;
	style?: CSSObject;
}

const StyledButton = styled.button`
	padding: 8px 12px 8px 16px;
	background: ${({ theme }) => theme.common.colors.gray_11};
	color: ${({ theme }) => theme.common.colors.white};
	${({ theme }) => theme.typo.hm9};
	border-radius: 64px;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;

	.view-point-icon {
		margin-left: 8px;
		flex-shrink: 0;
	}
`;

function ViewPointButton({
	type,
	className,
	style,
	onClick,
}: ViewPointButtonProps) {
	const renderText = () => {
		switch (type) {
			case 'list':
				return '목록으로 보기';
			case 'map':
				return '지도로 보기';

			default:
				return '목록으로 보기';
		}
	};

	const renderIcon = () => {
		switch (type) {
			case 'list':
				return whiteListIcon;
			case 'map':
				return whiteMapIcon;

			default:
				return whiteListIcon;
		}
	};

	return (
		<StyledButton
			type="button"
			style={style}
			className={className}
			onClick={onClick}
		>
			{renderText()}
			<Icon
				src={renderIcon()}
				width={24}
				height={24}
				className="view-point-icon"
			/>
		</StyledButton>
	);
}

export default ViewPointButton;
