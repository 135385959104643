import React, { useContext, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import DateTimePicker from '../../../../components/atoms/DateTimePicker';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
// import { CS_TEL_PATH } from '../../routes/constants/urls';
import {
	getTelephoneCategory,
	getTelephoneInquiryViaId,
	useUpdateTelephoneInquiryMutation,
} from '../../../../store/apis/telephoneInquiry';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import {
	GenerateTelephoneInquiryDto,
	ModifyTelephoneInquiryDto,
} from '../../../../interface/telephoneInquiry';
import { ButtonTypeType } from '../../../../styles/theme';
import { CustomErrorType } from '../../../../store/apis/@types';
import Icon from '../../../../components/atoms/Icon';
import { clockIcon } from '../../../../assets/icon';
import { phoneFormat } from '../../../../utils/data-format';
import TextArea from '../../../../components/atoms/TextArea';
import Select from '../../../../components/atoms/Select';
import { LabelValue } from '../../../../utils/const';

const initialValues: GenerateTelephoneInquiryDto = {
	category: '',
	phoneCalledAt: '',
	customerName: '',
	customerPhone: '',
	body: '',
};

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

const StyleDateTimePicker = styled(DateTimePicker)`
	height: 34px;
	padding: 5px 10px;
	border-radius: 8px;
	padding-top: 5px;
	padding-bottom: 5px;
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};

	.ant-picker-input > input {
		${({ theme }) => theme.typo.input2};
	}
`;

function TelInquiryDetailPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const setToast = useContext(ToastContext);
	const dispatch = useDispatch();
	const [updateTelephoneInquiry] = useUpdateTelephoneInquiryMutation();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [isCategoryLoading, setIsCategoryLoading] = useState(true);
	const [categoryList, setCategoryList] = useState<LabelValue[]>([]);

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const goToCsTelList = () => {
		navigate(-1);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.category === '') {
			errors.category = '문의유형을 입력해주세요.';
		}
		if (values.phoneCalledAt === '') {
			errors.phoneCalledAt = '전화 시간 선택을 선택해주세요';
		}
		if (values.customerName === '') {
			errors.customerName = '고객명을 입력해주세요.';
		}
		if (
			!/^01([0|1|6|7|8|9]){1}-[0-9]{3,4}-[0-9]{4}/.test(values.customerPhone)
		) {
			errors.customerPhone = '유효한 휴대폰번호를 입력해 주세요.';
		}
		if (!values.customerPhone.length) {
			errors.customerPhone = '휴대폰번호를 입력해 주세요.';
		}
		if (values.body === '') {
			errors.body = '내용을 입력해주세요.';
		}
		return errors;
	};

	const {
		setFieldValue,
		values,
		handleSubmit,
		handleChange,
		errors,
		validateForm,
	} = useFormik({
		initialValues,
		onSubmit: async (value) => {
			handleAlertCloseClick();

			if (id) {
				const { phoneCalledAt, customerPhone, ...rest } = value;

				const body: ModifyTelephoneInquiryDto = {
					phoneCalledAt: new Date(phoneCalledAt).toISOString(),
					customerPhone: customerPhone.replaceAll('-', ''),
					...rest,
				};

				dispatch(fullLoadingOn());

				const res = await updateTelephoneInquiry({ id: Number(id), body });

				dispatch(fullLoadingOff());

				if ('error' in res) {
					const msg =
						(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message;

					setToast('error', msg);
				} else {
					setToast('info', '상담내역 저장이 완료되었습니다.');
				}
			}
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertOpenClick = async () => {
		const validateRes = await validateForm();

		const isNoError = Object.keys(validateRes).length === 0;

		if (isNoError) {
			setAlertVisible(true);
		}
	};

	const phoneNumberAutoHyphen = phoneFormat(values.customerPhone);

	useEffect(() => {
		(async () => {
			if (id) {
				try {
					dispatch(fullLoadingOn());
					const res = await getTelephoneCategory();
					const { category, phoneCalledAt, customerName, customerPhone, body } =
						await getTelephoneInquiryViaId(id);

					setFieldValue('category', category);
					setFieldValue(
						'phoneCalledAt',
						dayjs(phoneCalledAt).format('YYYY-MM-DD HH:mm:ss'),
					);
					setFieldValue('customerName', customerName);
					setFieldValue('customerPhone', phoneFormat(customerPhone));
					setFieldValue('body', body);
					setIsCategoryLoading(false);
					setCategoryList(res);
				} catch (e) {
					setIsCategoryLoading(true);
					const msg =
						(e as CustomErrorType).data.translate ||
						(e as CustomErrorType).data.message;

					setToast('error', msg);
				} finally {
					dispatch(fullLoadingOff());
				}
			}
		})();
	}, [setFieldValue, setToast, id, dispatch]);

	const renderAlert = () => {
		if (!isAlertVisible) return null;

		return (
			<Alert
				title="상담내역 저장"
				closeButtonClick={handleAlertCloseClick}
				onConfirmButtonText="저장하기"
				onConfirmButtonClick={handleSubmit}
				isVisible={isAlertVisible}
			>
				<Typo $typoType="b5" color="gray_8">
					상담내역을 저장하시겠습니까?
				</Typo>
			</Alert>
		);
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="상담내역 상세"
				onBack={goToCsTelList}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goToCsTelList}
							size="small"
						>
							뒤로가기
						</Button>
						<Button onClick={handleAlertOpenClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="상담 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="category" essential>
								<span>
									<Typo $typoType="label2">문의유형</Typo>
								</span>
							</Label>
							<Select
								placeholder="문의 유형을 작성해 주세요"
								loading={isCategoryLoading}
								value={values.category}
								onChange={(value: string) => setFieldValue('category', value)}
							>
								{categoryList.map((option, idx) => (
									<Select.Option value={option.value} key={idx.toString()}>
										{option.label}
									</Select.Option>
								))}
							</Select>
							{errors.category && (
								<InputAlert $inputStatus="error">{errors.category}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="phoneCalledAt" essential>
								<span>
									<Typo $typoType="label2">전화시간</Typo>
								</span>
							</Label>
							<StyleDateTimePicker
								name="phoneCalledAt"
								allowClear={false}
								showTime
								onChange={(m) =>
									setFieldValue(
										'phoneCalledAt',
										m?.format('YYYY-MM-DD HH:mm:ss'),
									)
								}
								placeholder="전화 시간 선택"
								format="YYYY-MM-DD HH:mm:ss"
								suffixIcon={<Icon src={clockIcon} width={24} height={24} />}
								value={
									values.phoneCalledAt ? dayjs(values.phoneCalledAt) : dayjs()
								}
							/>
							{errors.phoneCalledAt && (
								<InputAlert $inputStatus="error">
									{errors.phoneCalledAt}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="customerName" essential>
								<span>
									<Typo $typoType="label2">고객명</Typo>
								</span>
							</Label>
							<Input
								name="customerName"
								placeholder="고객명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.customerName}
								onChange={handleChange}
								onReset={() => setFieldValue('customerName', '')}
							/>
							{errors.customerName && (
								<InputAlert $inputStatus="error">
									{errors.customerName}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="customerPhone" essential>
								<span>
									<Typo $typoType="label2">휴대폰 번호</Typo>
								</span>
							</Label>
							<Input
								name="customerPhone"
								placeholder="‘-없이’ 숫자를 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneNumberAutoHyphen}
								onChange={handleChange}
								onReset={() => setFieldValue('customerPhone', '')}
								maxLength={13}
							/>
							{errors.customerPhone && (
								<InputAlert $inputStatus="error">
									{errors.customerPhone}
								</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="body" essential>
								<span>
									<Typo $typoType="label2">내용</Typo>
								</span>
							</Label>
							<TextArea
								style={{
									minHeight: '240px',
								}}
								placeholder="전화 문의 내용을 작성해 주세요"
								value={values.body}
								defaultValue={values.body}
								onChange={(e) => setFieldValue('body', e.target.value)}
							/>
							{errors.body && (
								<InputAlert $inputStatus="error">{errors.body}</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{renderAlert()}
		</>
	);
}

export default TelInquiryDetailPage;
