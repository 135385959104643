import { authApiSlice } from '../query';
import { ListResponse, ObjectResponse } from '../@types';
import CarServiceCompanyInfo, {
	CarServiceCallHistory,
	CarServiceDto,
	PublicCorpCarServiceDto,
	DuzonTransfersInfo,
	PaymentsInfo,
	SettlementInfo,
	CarServiceDashboardStackDate,
	CarServiceDashboardGroupItem,
	CarServicePaymentRequest,
	DuzonTransfersDto,
	CarServiceDashboardDto,
	CarServiceInspectionItem,
} from '../../../interface/carService';

const carServiceApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCarServices: builder.query<
			ListResponse<CarServiceDto>,
			{
				proxyInspectionReceptionRegistrationDateFrom: string;
				proxyInspectionReceptionRegistrationDateTo?: string;
				proxyInspectionReceptionId?: string;
				proxyInspectionReceptionChannel?: string;
				carLicensePlateNumber?: string;
				customerPhoneNumber1?: string;
				proxyInspectionReceptionStateCode?: string;
				rwManagerName?: string;
				page?: number;
				pageSize?: number;
			}
		>({
			query: ({
				proxyInspectionReceptionRegistrationDateFrom,
				proxyInspectionReceptionRegistrationDateTo,
				proxyInspectionReceptionId,
				proxyInspectionReceptionChannel,
				carLicensePlateNumber,
				customerPhoneNumber1,
				proxyInspectionReceptionStateCode,
				rwManagerName,
				page,
				pageSize,
			}) => ({
				url: `/car-services/inspections`,
				params: {
					proxyInspectionReceptionRegistrationDateFrom,
					proxyInspectionReceptionRegistrationDateTo,
					proxyInspectionReceptionId,
					proxyInspectionReceptionChannel,
					carLicensePlateNumber,
					customerPhoneNumber1,
					proxyInspectionReceptionStateCode,
					rwManagerName,
					page,
					pageSize,
				},
			}),
		}),
		getCarServiceDetail: builder.query<
			ObjectResponse<CarServiceDto>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => `/car-services/inspections/${inspectionId}`,
		}),
		updateCarServiceDetail: builder.mutation<
			ObjectResponse<Partial<CarServiceDto>>,
			{ inspectionId: string | undefined; body: Partial<CarServiceDto> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'put',
				url: `/car-services/inspections/${inspectionId}/ro`,
				body,
			}),
		}),
		uploadCarServiceExcel: builder.mutation<
			ObjectResponse<Partial<CarServiceDto>>,
			{ body: FormData }
		>({
			query: ({ body }) => ({
				method: 'post',
				url: `/car-services/inspections/excel`,
				body,
			}),
		}),
	}),
});

const carServiceCallHistoryApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCarServiceCallHistory: builder.query<
			ListResponse<CarServiceCallHistory>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => {
				return { url: `/car-services/inspections/${inspectionId}/calls` };
			},
		}),
		makeCarServiceCallHistory: builder.mutation<
			ObjectResponse<Partial<CarServiceCallHistory>>,
			{ inspectionId: string | undefined; body: Partial<CarServiceCallHistory> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/calls`,
				body,
			}),
		}),
	}),
});

const carServicePaymentRequestApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCarServicePaymentRequest: builder.query<
			ListResponse<CarServicePaymentRequest>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => {
				return {
					url: `/car-services/inspections/${inspectionId}/payment-requests`,
				};
			},
			providesTags: ['CarServicePaymentRequest'],
		}),
		getCarServicePaymentRequestDetail: builder.query<
			CarServicePaymentRequest,
			{
				inspectionId: string | undefined;
				sequenceNo: number | undefined;
			}
		>({
			query: ({ inspectionId, sequenceNo }) => {
				return {
					url: `/car-services/inspections/${inspectionId}/payment-requests/${sequenceNo}`,
				};
			},
			providesTags: ['CarServicePaymentRequest'],
		}),

		getCarServiceTotalPaymentRequest: builder.query<
			ListResponse<CarServicePaymentRequest>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => {
				return {
					url: `/car-services/inspections/${inspectionId}/payment-requests/total`,
				};
			},
		}),
		makeCarServicePaymentRequest: builder.mutation<
			ObjectResponse<Partial<CarServicePaymentRequest>>,
			{
				inspectionId: string | undefined;
				body: Partial<CarServicePaymentRequest>;
			}
		>({
			query: ({ inspectionId, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/payment-requests`,
				body,
			}),
			invalidatesTags: ['CarServicePaymentRequest'],
		}),
		updateCarServicePaymentRequest: builder.mutation<
			ObjectResponse<Partial<CarServicePaymentRequest>>,
			{
				inspectionId: string | undefined;
				sequenceNo: number | undefined;
				body: Partial<CarServicePaymentRequest>;
			}
		>({
			query: ({ inspectionId, sequenceNo, body }) => ({
				method: 'put',
				url: `/car-services/inspections/${inspectionId}/payment-requests/${sequenceNo}`,
				body,
			}),
			invalidatesTags: ['CarServicePaymentRequest'],
		}),

		makeCarServiceTransfers: builder.mutation<
			ObjectResponse<DuzonTransfersDto>,
			{
				inspectionId: string | undefined;
				sequenceNo: number | undefined;
				body: Partial<CarServicePaymentRequest>;
			}
		>({
			query: ({ inspectionId, sequenceNo, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/payment-requests/${sequenceNo}/transfers`,
				body,
			}),
		}),

		getCarServiceTransfers: builder.query<
			ListResponse<DuzonTransfersInfo>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => {
				return {
					url: `/car-services/inspections/${inspectionId}/transfers`,
				};
			},
		}),
	}),
});

const carServiceOutsourcingApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getOutsourcingCarServices: builder.query<
			ListResponse<CarServiceDto>,
			{
				proxyInspectionReceptionRegistrationDateFrom: string;
				proxyInspectionReceptionRegistrationDateTo?: string;
				rwManagerName: string;
				proxyInspectionReceptionId?: string;
				proxyInspectionReceptionChannel?: string;
				carLicensePlateNumber?: string;
				customerPhoneNumber1?: string;
				proxyInspectionReceptionStateCode?: string;
				page?: number;
				pageSize?: number;
			}
		>({
			query: ({
				proxyInspectionReceptionRegistrationDateFrom,
				proxyInspectionReceptionRegistrationDateTo,
				rwManagerName,
				proxyInspectionReceptionId,
				proxyInspectionReceptionChannel,
				carLicensePlateNumber,
				customerPhoneNumber1,
				proxyInspectionReceptionStateCode,
				page,
				pageSize,
			}) => {
				return {
					url: `/car-services/outsourcing`,
					params: {
						proxyInspectionReceptionRegistrationDateFrom,
						proxyInspectionReceptionRegistrationDateTo,
						rwManagerName,
						proxyInspectionReceptionId,
						proxyInspectionReceptionChannel,
						carLicensePlateNumber,
						customerPhoneNumber1,
						proxyInspectionReceptionStateCode,
						page,
						pageSize,
					},
				};
			},
		}),
		getOutsourcingCarServiceDetail: builder.query<
			ObjectResponse<CarServiceDto>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => `/car-services/outsourcing/${inspectionId}`,
		}),
		updateOutSourcingCarServiceDetail: builder.mutation<
			ObjectResponse<Partial<CarServiceDto>>,
			{ inspectionId: string | undefined; body: Partial<CarServiceDto> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'put',
				url: `/car-services/outsourcing/${inspectionId}/ro`,
				body,
			}),
		}),
	}),
});

const carServicePartnerApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPartnerCarServices: builder.query<
			ListResponse<CarServiceDto>,
			{
				proxyInspectionReceptionRegistrationDateFrom: string;
				proxyInspectionReceptionRegistrationDateTo?: string;
				proxyInspectionOrderCompanyName: string;
				proxyInspectionReceptionId?: string;
				proxyInspectionReceptionChannel?: string;
				carLicensePlateNumber?: string;
				customerPhoneNumber1?: string;
				proxyInspectionReceptionStateCode?: string;
				page?: number;
				pageSize?: number;
			}
		>({
			query: ({
				proxyInspectionReceptionRegistrationDateFrom,
				proxyInspectionReceptionRegistrationDateTo,
				proxyInspectionOrderCompanyName,
				proxyInspectionReceptionId,
				proxyInspectionReceptionChannel,
				carLicensePlateNumber,
				customerPhoneNumber1,
				proxyInspectionReceptionStateCode,
				page,
				pageSize,
			}) => {
				return {
					url: `/car-services/partners`,
					params: {
						proxyInspectionReceptionRegistrationDateFrom,
						proxyInspectionReceptionRegistrationDateTo,
						proxyInspectionOrderCompanyName,
						proxyInspectionReceptionId,
						proxyInspectionReceptionChannel,
						carLicensePlateNumber,
						customerPhoneNumber1,
						proxyInspectionReceptionStateCode,
						page,
						pageSize,
					},
				};
			},
		}),
		getPartnerCarServiceDetail: builder.query<
			ObjectResponse<CarServiceDto>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) => `/car-services/partners/${inspectionId}`,
		}),
		updatePartnerCarServiceDetail: builder.mutation<
			ObjectResponse<Partial<CarServiceDto>>,
			{ inspectionId: string | undefined; body: Partial<CarServiceDto> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'put',
				url: `/car-services/partners/${inspectionId}/order`,
				body,
			}),
		}),
		updatePartnerPayment: builder.mutation<
			ObjectResponse<Partial<PaymentsInfo>>,
			{ inspectionId: string | undefined; body: Partial<PaymentsInfo> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'put',
				url: `/car-services/partners/${inspectionId}/payment`,
				body,
			}),
		}),
	}),
});

const carServiceTransfersApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBankCodes: builder.query<
			ListResponse<{ CODE: string; NAME: string }>,
			{ bankCodeKey: string }
		>({
			query: ({ bankCodeKey }) => `/car-services/banks/${bankCodeKey}`,
		}),
		checkAccount: builder.query<
			ObjectResponse<{ companyRegistrationNumber: string; depositor: string }>,
			{ bankCode: string; bankAccount: string }
		>({
			query: ({ bankCode, bankAccount }) => {
				return {
					url: `/car-services/check-account`,
					params: { bankCode, bankAccount },
				};
			},
		}),
		getTransfersHistory: builder.query<
			ListResponse<any>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) =>
				`/car-services/inspections/${inspectionId}/transfers`,
		}),
		updateTransferInfo: builder.mutation<
			ObjectResponse<Partial<PaymentsInfo>>,
			{ inspectionId: string | undefined; body: Partial<PaymentsInfo> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/transfers/save`,
				body,
			}),
		}),
		makeTransfer: builder.mutation<
			ObjectResponse<Partial<DuzonTransfersInfo>>,
			{ inspectionId: string | undefined; body: Partial<DuzonTransfersInfo> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/transfers/execute`,
				body,
			}),
		}),
		makeAdditionalTransfer: builder.mutation<
			ObjectResponse<Partial<DuzonTransfersInfo>>,
			{ inspectionId: string | undefined; body: Partial<DuzonTransfersInfo> }
		>({
			query: ({ inspectionId, body }) => ({
				method: 'post',
				url: `/car-services/inspections/${inspectionId}/transfers/execute-additional`,
				body,
			}),
		}),
		getAllTransfers: builder.query<
			ListResponse<DuzonTransfersInfo>,
			Record<any, any>
		>({
			query: (arg) => {
				return { url: `/car-services/transfers`, params: arg };
			},
		}),
		getPaymentsInfo: builder.query<
			ObjectResponse<PaymentsInfo>,
			{ inspectionId: string | undefined }
		>({
			query: ({ inspectionId }) =>
				`/car-services/inspections/${inspectionId}/payments`,
		}),
		updatePaymentsAndSettlementInfo: builder.mutation<
			ObjectResponse<Partial<PaymentsInfo & SettlementInfo>>,
			{
				inspectionId: string | undefined;
				body: Partial<PaymentsInfo & SettlementInfo>;
			}
		>({
			query: ({ inspectionId, body }) => ({
				method: 'put',
				url: `/car-services/inspections/${inspectionId}/pbs`,
				body,
			}),
		}),
	}),
});

const carServicePublicCorpApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPublicCorpCarService: builder.query<
			ListResponse<PublicCorpCarServiceDto>,
			Record<any, any>
		>({
			query: (arg) => {
				return { url: `/car-services/public-corp`, params: arg };
			},
		}),
		uploadPublicCorpExcel: builder.mutation<
			ObjectResponse<Partial<CarServiceDto>>,
			{ body: FormData }
		>({
			query: ({ body }) => ({
				method: 'post',
				url: `/car-services/public-corp/excel`,
				body,
			}),
		}),
	}),
});

const carServiceCompanyApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCompaniesCarService: builder.query<
			ListResponse<CarServiceCompanyInfo>,
			Record<any, any>
		>({
			query: (arg) => {
				return { url: `/car-services/companies`, params: arg };
			},
		}),
		makeCompanyCarService: builder.mutation<
			ObjectResponse<Partial<CarServiceCompanyInfo>>,
			{ body: Partial<CarServiceCompanyInfo> }
		>({
			query: ({ body }) => ({
				method: 'post',
				url: `/car-services/companies`,
				body,
			}),
		}),
	}),
});

const carServiceDashboardApiSlice = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMyLatestCarServiceDashboard: builder.query<
			ObjectResponse<CarServiceDashboardDto>,
			{}
		>({
			query: () => {
				return '/car-services/dashboard/me-latest';
			},
			providesTags: ['InspectionDashboard'],
		}),
		// 대시보드 보고대상 조회 with stackDate & report type (["REQUEST_STOP", "RESULT_NONPASS", "RESULT_EXCLUDE", "SCHEDULE_TIGHT", "SCHEDULE_MISS"])
		getMyCarServiceReportsByReport: builder.query<
			ObjectResponse<Partial<CarServiceDashboardDto>>,
			{ dashboardStackDate: string; report: string }
		>({
			query: ({ dashboardStackDate, report }) => {
				return `/car-services/dashboard/me/${dashboardStackDate}/${report}`;
			},
		}),
		getMyCarServiceDashboardByStackDate: builder.query<
			ObjectResponse<CarServiceDashboardDto>,
			{ dashboardStackDate: string }
		>({
			query: ({ dashboardStackDate }) => {
				return `/car-services/dashboard/me/${dashboardStackDate}`;
			},
		}),
		// 대시보드 날짜 리스트 조회
		getMyCarServiceDashboardStackDateList: builder.query<
			ListResponse<Partial<CarServiceDashboardStackDate>>,
			{}
		>({
			query: () => {
				return '/car-services/dashboard/me';
			},
		}),

		getCarServiceDashBoardGroups: builder.query<
			ListResponse<CarServiceDashboardGroupItem>,
			{}
		>({
			query: () => {
				return '/car-services/dashboard/groups';
			},
		}),
		getCarServiceReportsByReport: builder.query<
			ObjectResponse<Partial<CarServiceDashboardDto>>,
			{ groupId: string; dashboardStackDate: string; report: string }
		>({
			query: ({ groupId, dashboardStackDate, report }) => {
				return `/car-services/dashboard/groups/${groupId}/${dashboardStackDate}/${report}`;
			},
		}),
		getCarServiceDashboardByStackDate: builder.query<
			ObjectResponse<CarServiceDashboardDto>,
			{ groupId: string; dashboardStackDate: string }
		>({
			query: ({ groupId, dashboardStackDate }) => {
				return `/car-services/dashboard/groups/${groupId}/${dashboardStackDate}`;
			},
		}),
		getCarServiceDashboardStackDateList: builder.query<
			ListResponse<CarServiceDashboardStackDate>,
			{ groupId: string }
		>({
			query: ({ groupId }) => {
				return `/car-services/dashboard/groups/${groupId}`;
			},
		}),
		// 검사대행 고객통화 내역 목록 조회
		getCarServiceDashboadInspectionCallsByInspectionId: builder.query<
			ListResponse<CarServiceCallHistory>,
			{ inspectionId: string }
		>({
			query: ({ inspectionId }) => {
				return `/car-services/dashboard/inspections/${inspectionId}/calls`;
			},
		}),
		// 검사대행 상세 조회
		getCarServiceDashboardInspectionsByInspectionId: builder.query<
			ObjectResponse<CarServiceInspectionItem>,
			{ inspectionId: string }
		>({
			query: ({ inspectionId }) => {
				return `/car-services/dashboard/inspections/${inspectionId}`;
			},
		}),
	}),
});

// eslint-disable-next-line import/prefer-default-export
export const {
	useGetCarServicesQuery,
	useGetCarServiceDetailQuery,
	useUpdateCarServiceDetailMutation,
	useUploadCarServiceExcelMutation,
} = carServiceApiSlice;

export const {
	useGetCarServiceCallHistoryQuery,
	useMakeCarServiceCallHistoryMutation,
} = carServiceCallHistoryApiSlice;

export const {
	useGetCarServicePaymentRequestQuery,
	useGetCarServicePaymentRequestDetailQuery,
	useLazyGetCarServicePaymentRequestDetailQuery,
	useGetCarServiceTotalPaymentRequestQuery,
	useMakeCarServicePaymentRequestMutation,
	useUpdateCarServicePaymentRequestMutation,
	useMakeCarServiceTransfersMutation,
	useGetCarServiceTransfersQuery,
} = carServicePaymentRequestApiSlice;

export const {
	useGetOutsourcingCarServicesQuery,
	useGetOutsourcingCarServiceDetailQuery,
	useUpdateOutSourcingCarServiceDetailMutation,
} = carServiceOutsourcingApiSlice;

export const {
	useGetPartnerCarServicesQuery,
	useGetPartnerCarServiceDetailQuery,
	useUpdatePartnerCarServiceDetailMutation,
	useUpdatePartnerPaymentMutation,
} = carServicePartnerApiSlice;

export const {
	useGetBankCodesQuery,
	useLazyCheckAccountQuery,
	useGetTransfersHistoryQuery,
	useUpdateTransferInfoMutation,
	useMakeTransferMutation,
	useMakeAdditionalTransferMutation,
	useGetAllTransfersQuery,
	useGetPaymentsInfoQuery,
	useUpdatePaymentsAndSettlementInfoMutation,
} = carServiceTransfersApiSlice;

export const {
	useGetPublicCorpCarServiceQuery,
	useUploadPublicCorpExcelMutation,
} = carServicePublicCorpApiSlice;

export const {
	useGetCompaniesCarServiceQuery,
	useMakeCompanyCarServiceMutation,
} = carServiceCompanyApiSlice;

export const {
	useGetMyLatestCarServiceDashboardQuery,
	useGetMyCarServiceReportsByReportQuery,
	useGetMyCarServiceDashboardByStackDateQuery,
	useGetMyCarServiceDashboardStackDateListQuery,
	useGetCarServiceDashBoardGroupsQuery,
	useGetCarServiceReportsByReportQuery,
	useGetCarServiceDashboardByStackDateQuery,
	useGetCarServiceDashboardStackDateListQuery,
	useGetCarServiceDashboadInspectionCallsByInspectionIdQuery,
	useGetCarServiceDashboardInspectionsByInspectionIdQuery,
} = carServiceDashboardApiSlice;
