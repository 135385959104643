/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
	AnyMobilityDTGInfo,
	DTGInfo,
} from '../../../interface/vehicleConditions';
import { transportDatetime } from '../../../utils/date-util';
import theme from '../../../styles/theme';
import getPinSrc from './utils';
import {
	TruckICon,
	clusterMarkerIcon1,
	clusterMarkerIcon2,
	clusterMarkerIcon3,
	clusterMarkerIcon4,
	clusterMarkerIcon5,
} from '../../../assets/icon';
import MarkerClustering from '../../../utils/MarkerClustering';

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

interface NaverMapProps {
	markerList?: DTGInfo[];
	pathList?: AnyMobilityDTGInfo[];
	onMarkerClick?: (markerInfo: any) => void;
	hasZoomController?: boolean;
	defaultZoom?: number;
	mapId?: string;
	imageIcon?: boolean;
	isStatic?: boolean;
}

const getVehicleIcon = (vehicleNumber: string | undefined, time: string) => {
	return `<div style="display: flex; flex-direction: column; align-items: center; gap: 2px"><span class="ant-tag ant-tag-red">${transportDatetime(
		dayjs(`20${time.slice(0, 6)}T${time.slice(6)}`).toISOString(),
	)}</span><span class="ant-tag ant-tag-blue">${vehicleNumber}</span><img src=${TruckICon} width="40" height="40" alt="트럭"></div>`;
};

const getClusterIcon1 = () => {
	return `<div style="cursor:pointer;width:60px;height:60px;line-height:60px;font-size:14px;color:white;text-align:center;font-weight:bold;background:url(${clusterMarkerIcon1});background-size:contain;"></div>`;
};
const getClusterIcon2 = () => {
	return `<div style="cursor:pointer;width:60px;height:60px;line-height:60px;font-size:14px;color:white;text-align:center;font-weight:bold;background:url(${clusterMarkerIcon2});background-size:contain;"></div>`;
};
const getClusterIcon3 = () => {
	return `<div style="cursor:pointer;width:50px;height:50px;line-height:60px;font-size:14px;color:white;text-align:center;font-weight:bold;background:url(${clusterMarkerIcon3});background-size:contain;"></div>`;
};
const getClusterIcon4 = () => {
	return `<div style="cursor:pointer;width:60px;height:60px;line-height:60px;font-size:14px;color:white;text-align:center;font-weight:bold;background:url(${clusterMarkerIcon4});background-size:contain;"></div>`;
};
const getClusterIcon5 = () => {
	return `<div style="cursor:pointer;width:60px;height:60px;line-height:60px;font-size:14px;color:white;text-align:center;font-weight:bold;background:url(${clusterMarkerIcon5});background-size:contain;"></div>`;
};

// const getCarIcon = () => CarIcon;

const VehiclesMap = ({
	markerList = [],
	pathList = [],
	onMarkerClick,
	hasZoomController = true,
	defaultZoom = 15,
	mapId = 'map',
	imageIcon = false,
	isStatic = false,
}: NaverMapProps) => {
	const [defaultMarkerList] = useState(markerList);

	const mapRef = useRef<naver.maps.Map | undefined>(undefined);
	const markerListRef = useRef<naver.maps.Marker[]>([]);
	const selectedMarkerRef = useRef<naver.maps.Marker | null>(null);
	const polygonRef = useRef<naver.maps.Polyline | null>(null);
	const clusterRef = useRef<any>(null);

	const resetMarker = useCallback((marker: naver.maps.Marker) => {
		marker.setZIndex(0);
		marker.setAnimation(null!);
	}, []);

	const handleMarkerClick = useCallback(
		(markerItem: DTGInfo, noMove?: boolean) => {
			if (onMarkerClick) {
				onMarkerClick(markerItem);
			}
			if (selectedMarkerRef.current) {
				selectedMarkerRef.current.setZIndex(1);
				selectedMarkerRef.current.setIcon(
					imageIcon
						? getPinSrc({
								status: '',
								isMarkerOn: false,
						  })
						: {
								content: getVehicleIcon(markerItem.vehicleNumber, markerItem.t),
						  },
				);
			}
			if (mapRef.current && !noMove) {
				mapRef.current.morph(
					new naver.maps.LatLng(markerItem.y / 1000000, markerItem.x / 1000000),
					17,
				);
			}
		},
		[imageIcon, onMarkerClick],
	);

	useEffect(() => {
		if (mapRef.current) return;

		const staticOptions: naver.maps.MapOptions = isStatic
			? { minZoom: 15, maxZoom: 15, draggable: false }
			: {};

		mapRef.current = new naver.maps.Map(mapId || 'map', {
			// zoom: defaultZoom || 15,
			zoomControlOptions: {
				position: naver.maps.Position.RIGHT_TOP,
				style: naver.maps.ZoomControlStyle.SMALL,
			},
			zoomControl: hasZoomController,
			...staticOptions,
		});
	}, [hasZoomController, defaultZoom, mapId, isStatic]);

	useEffect(() => {
		if (markerListRef.current.length) {
			markerListRef.current.forEach((item) => {
				item.setMap(null);
			});
			clusterRef.current.setMap(null);
		}

		const markerListTemp: naver.maps.Marker[] = [];

		markerList?.forEach((markerItem) => {
			if (mapRef.current === undefined) return;

			const marker = new naver.maps.Marker({
				map: mapRef.current,
				position: new naver.maps.LatLng(
					markerItem.y / 1000000,
					markerItem.x / 1000000,
				),
				icon: imageIcon
					? getPinSrc({
							status: '',
							isMarkerOn: false,
					  })
					: {
							content: getVehicleIcon(markerItem.vehicleNumber, markerItem.t),
					  },
			});

			marker.addListener('click', () => {
				if (
					selectedMarkerRef.current &&
					!(marker === selectedMarkerRef.current)
				) {
					resetMarker(selectedMarkerRef.current);
				}
				selectedMarkerRef.current = marker;
				handleMarkerClick(markerItem);
			});
			markerListTemp.push(marker);
		});

		markerListRef.current = markerListTemp;

		const cluster = new MarkerClustering({
			// 클러스터 마커를 올릴 지도입니다.
			map: mapRef.current,
			// 클러스터 마커를 구성할 마커입니다.
			markers: markerListRef.current,
			// 클러스터 마커 클릭 시 줌 동작 여부입니다.
			disableClickZoom: false,
			// 클러스터를 구성할 최소 마커 수입니다.
			minClusterSize: 2,
			// 클러스터 마커로 표현할 최대 줌 레벨입니다. 해당 줌 레벨보다 높으면, 클러스터를 구성하고 있는 마커를 노출합니다.
			maxZoom: 16,
			// 클러스터를 구성할 그리드 크기입니다. 단위는 픽셀입니다.
			gridSize: 110,
			// 클러스터 마커의 아이콘입니다. NAVER Maps JavaScript API v3에서 제공하는 아이콘, 심볼, HTML 마커 유형을 모두 사용할 수 있습니다.
			icons: [
				{
					content: getClusterIcon4(),
				},
				{
					content: getClusterIcon5(),
				},
				{
					content: getClusterIcon3(),
				},
				{
					content: getClusterIcon2(),
				},
				{
					content: getClusterIcon1(),
				},
			],
			// 클러스터 마커의 아이콘 배열에서 어떤 아이콘을 선택할 것인지 인덱스를 결정합니다.
			indexGenerator: [5, 10, 15, 20, 30],
			// 클러스터 마커의 위치를 클러스터를 구성하고 있는 마커의 평균 좌표로 할 것인지 여부입니다.
			averageCenter: false,
			// 클러스터 마커를 갱신할 때 호출하는 콜백함수입니다. 이 함수를 통해 클러스터 마커에 개수를 표현하는 등의 엘리먼트를 조작할 수 있습니다.
			// eslint-disable-next-line func-names, object-shorthand
			stylingFunction: function (clusterMarker: any, count: number) {
				// eslint-disable-next-line no-param-reassign
				clusterMarker.getElement().querySelector('div:first-child').innerText =
					count;
			},
		});
		clusterRef.current = cluster;
	}, [markerList, resetMarker, handleMarkerClick, imageIcon]);

	useEffect(() => {
		if (polygonRef.current) {
			polygonRef.current.setMap(null);
			markerListRef.current.forEach((item) => item.setMap(null));
		}
		if (pathList && pathList.length) {
			const paths: naver.maps.PointArrayLiteral[] = pathList
				.filter((record) => record.s !== 11)
				.map((record: { x: number; y: number }) => {
					return [record.x / 1000000, record.y / 1000000];
				});

			polygonRef.current = new naver.maps.Polyline({
				path: paths,
				strokeColor: theme.common.colors.danger_4,
				strokeOpacity: 1,
				strokeWeight: 5,
				strokeLineCap: 'round',
				strokeLineJoin: 'round',
				map: mapRef.current,
				startIcon: 3,
				endIcon: 2,
			});
			// polygonRef.current.setPath(paths);
			mapRef.current?.fitBounds(paths);
		}
	}, [pathList]);

	const handleInitZoomCenter = useCallback(() => {
		if (defaultMarkerList?.length && defaultMarkerList?.length > 0) {
			if (defaultMarkerList?.length === 1) {
				if (mapRef.current) {
					mapRef.current.morph({
						lat: defaultMarkerList[0].y / 1000000,
						lng: defaultMarkerList[0].x / 1000000,
					});
				}
			} else {
				const board = defaultMarkerList.reduce(
					(acc, val) => {
						const getMinValue: (type: 'minX' | 'minY') => number = (
							type: 'minX' | 'minY',
						) => {
							if (type === 'minX') {
								if (acc.minX === 0) {
									return val.x / 1000000;
								}
								return val.x / 1000000 < acc.minX ? val.x / 1000000 : acc.minX;
							}
							if (type === 'minY') {
								if (acc.minY === 0) {
									return val.y / 1000000;
								}
								return val.y / 1000000 < acc.minY ? val.y / 1000000 : acc.minY;
							}
							return 0;
						};

						return {
							maxX: val.x / 1000000 > acc.maxX ? val.x / 1000000 : acc.maxX,
							minX: getMinValue('minX'),
							maxY: val.y / 1000000 > acc.maxY ? val.y / 1000000 : acc.maxY,
							minY: getMinValue('minY'),
						};
					},
					{
						maxX: 0,
						minX: 0,
						maxY: 0,
						minY: 0,
					},
				);

				if (mapRef.current) {
					mapRef.current.panToBounds(board, { duration: 500 });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultMarkerList, mapId]);

	useEffect(() => {
		handleInitZoomCenter();
	}, [handleInitZoomCenter]);

	return <MapContainer id={mapId || 'map'} />;
};

export default VehiclesMap;
