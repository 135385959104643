import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useGetPartnerCarServiceDetailQuery,
	useUpdatePartnerCarServiceDetailMutation,
} from '../../store/apis/carService';
import CarServiceDetailTemplate from '../../components/templates/CarServiceTemplate/CarServiceDetailTemplate';

const CarServicePartnerDetail = () => {
	const { inspectionId } = useParams();
	const [openBillingTabPane, setOpenBillingTabPane] = useState(false);

	const onCloseBillingTabPane = () => {
		setOpenBillingTabPane(false);
	};

	const handleBillingTab = () => {
		setOpenBillingTabPane(true);
	};

	const { isLoading, data, refetch } = useGetPartnerCarServiceDetailQuery({
		inspectionId,
	});

	const [updateCarServiceDetail, { isLoading: isUpdating }] =
		useUpdatePartnerCarServiceDetailMutation();

	return (
		<CarServiceDetailTemplate
			inspectionId={inspectionId}
			callHistoryTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			settlementTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			inspectionPaymentTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			inspectionPaymentStatusTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			transferHistoryTabPane={{
				toUse: false,
				open: false,
				handleTabPane: undefined,
				onCloseTab: undefined,
			}}
			billingTabPane={{
				toUse: true,
				open: openBillingTabPane,
				handleTabPane: handleBillingTab,
				onCloseTab: onCloseBillingTabPane,
			}}
			initialValues={data?.row}
			updateCarServiceDetail={updateCarServiceDetail}
			refetch={refetch}
			isLoading={isLoading}
			isUpdating={isUpdating}
			partnerCase
		/>
	);
};

export default CarServicePartnerDetail;
