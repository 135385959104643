import { RowSelectedEvent } from 'ag-grid-community';
import { Radio, RadioChangeEvent, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import ImageUpload from '../../../../components/atoms/ImageUpload';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import SelectionGrid from '../../../../components/atoms/SelectionGrid';
import TextArea from '../../../../components/atoms/TextArea';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import InputForm from '../../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { GenerateProductDto } from '../../../../interface/product';
import { SubscriptionBadgeType } from '../../../../interface/subscriptionProducts';
import { SUBSCRIBE_PRODUCT_MANAGEMENT_PATH } from '../../../../routes/constants/urls';
import { CustomErrorType } from '../../../../store/apis/@types';
import { useSubscribeCreateMutation } from '../../../../store/apis/subscribeProduct';
import { useBigImagesUploadMutation } from '../../../../store/apis/upload';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { ButtonTypeType } from '../../../../styles/theme';
import {
	INSPECTION_AGENCY,
	REGULAR_INSPECTION,
	TIRE_REPLACEMENT,
} from '../../../../utils/const';
import EditModal from './EditModal';
import { column } from './gridData';
import {
	getPriceAsNumber,
	numberWithCommas,
} from '../../../../utils/data-format';

export type ProductListNameType =
	| 'circular'
	| 'tire'
	| 'inspectionAgency'
	| 'emergencyDispatch';
export interface ProductIdServiceCount extends GenerateProductDto {
	taskCount: number;
	limitationCount: number;
}

const initialValues = {
	subscriptionProductName: '',
	subscriptionProductImage: '',
	subscriptionProductIntroduction: '',
	subscriptionProductViewable: true,
	badge: 'recommendation' as SubscriptionBadgeType,
	monthlyPriceIncludedTax: '',
	serviceCounte: '',
	isDtg: false,
	subscriptionProductDescription: '',
	productItems: [],
	productTypes: [],
};

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBoxItemCustom = styled(ContentBoxItem)`
	margin: 0;
`;

const ContentBoxItemTaxCustom = styled(ContentBoxItem)`
	flex-direction: row;
	align-items: center;
	margin-left: 0;
	width: 100%;

	.monthlyPrice-tax-container {
		width: 780px;
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.tax-monthlyPrice {
		width: 100%;
	}
`;

const ContentContainer = styled.div`
	position: relative;

	& > * {
		margin-bottom: 1rem;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
`;

const SelectionGridWrapper = styled.div`
	height: 384px;
`;

function SubscribeProductCreatePage() {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();
	const [subscribeRegister] = useSubscribeCreateMutation();
	const [imageUpload] = useBigImagesUploadMutation();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setIsAlertVisible] = useState(false);
	const [productImageList, setProductImageList] = useState<UploadFile<any>[]>(
		[],
	);
	// modal visible
	const [isCircularModal, setIsCircularModal] = useState(false);
	const [isTireModal, setIsTireModal] = useState(false);
	const [isInspectionModal, setIsInspectionModal] = useState(false);
	// select grid data
	const [circularSelect, setCircularSelect] = useState<GenerateProductDto[]>(
		[],
	);
	const [tireSelect, setTireSelect] = useState<GenerateProductDto[]>([]);
	const [inspectionSelect, setInspectionSelect] = useState<
		GenerateProductDto[]
	>([]);

	// grid data
	const [circular, setCircular] = useState<ProductIdServiceCount[]>([]);
	const [tire, setTire] = useState<ProductIdServiceCount[]>([]);
	const [inspectionAgency, setInspectionAgency] = useState<
		ProductIdServiceCount[]
	>([]);

	const goToSubscribeProduct = () => {
		navigate(-1);
	};

	const handleAlertCloseClick = () => {
		setIsAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setIsAlertVisible(true);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.subscriptionProductName === '') {
			errors.subscriptionProductName = '상품명을 입력해주세요.';
		}
		if (!productImageList.length) {
			errors.subscriptionProductImage = '이미지를 업로드해주세요';
		}
		if (values.subscriptionProductIntroduction === '') {
			errors.subscriptionProductIntroduction = '상품소개를 입력해주세요';
		}
		if (values.subscriptionProductDescription === '') {
			errors.subscriptionProductDescription = '상세 설명을 입력해주세요';
		}
		if (values.subscriptionProductViewable === null) {
			errors.subscriptionProductViewable = '판매상태를 선택해주세요';
		}
		if (values.badge.length === 0) {
			errors.badge = '추천 상품 여부를 선택해주세요';
		}
		if (values.isDtg === null) {
			errors.isDtg = 'DTG 옵션 설치 여부를 선택해주세요';
		}

		return errors;
	};

	const handleImageChange = async ({
		fileList,
	}: UploadChangeParam<UploadFile<any>>) => {
		setProductImageList(fileList.map((item) => ({ ...item, status: 'done' })));
	};

	const handleImageUpload = async (
		formikHelpers: FormikHelpers<typeof initialValues>,
	) => {
		if (productImageList.length) {
			const res = await imageUpload({
				file: productImageList[0].originFileObj,
				folder: '/subscribe/product',
			});

			if ('error' in res) {
				toast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			}
			if ('data' in res) {
				formikHelpers.setFieldValue(
					'subscriptionProductImage',
					res.data.rows[0],
				);
				return res.data.rows[0];
			}
		}
		return null;
	};

	const {
		validateForm,
		handleSubmit,
		values,
		handleChange,
		setFieldValue,
		errors,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (value, formikHelpers) => {
			const imageData = await handleImageUpload(formikHelpers);
			dispatch(fullLoadingOn());
			if (imageData === null) {
				return;
			}

			const res = await subscribeRegister({
				subscriptionProductName: value.subscriptionProductName,
				subscriptionProductImage: imageData,
				subscriptionProductViewable: value.subscriptionProductViewable,
				badge: value.badge,
				monthlyPrice:
					getPriceAsNumber(value.monthlyPriceIncludedTax) -
					getPriceAsNumber(value.monthlyPriceIncludedTax, true),
				monthlyTax: getPriceAsNumber(value.monthlyPriceIncludedTax, true),
				monthlyTaxType: 'TAX',
				isDtg: value.isDtg,
				isObd: false,
				subscriptionProductIntroduction: value.subscriptionProductIntroduction,
				subscriptionProductDescription: value.subscriptionProductDescription,
				productItems: value.productItems,
				productTypes: value.productTypes,
			});

			if ('error' in res) {
				toast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				handleAlertCloseClick();
				navigate(SUBSCRIBE_PRODUCT_MANAGEMENT_PATH);
				toast('info', '구독상품 등록이 완료되었습니다.');
			}
			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleSubmitAlertClick = async () => {
		const validateValue = await validateForm();

		if (Object.keys(validateValue).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleSubmitClick = () => {
		handleSubmit();
		handleAlertCloseClick();
	};

	const handleSelectRemoveClick = (
		select: number[],
		name: ProductListNameType,
	) => {
		switch (name) {
			case 'circular':
				return setCircular((prev) =>
					prev.filter((item) => !select.includes(item.id)),
				);
			case 'tire':
				return setTire((prev) =>
					prev.filter((item) => !select.includes(item.id)),
				);
			case 'inspectionAgency':
				return setInspectionAgency((prev) =>
					prev.filter((item) => !select.includes(item.id)),
				);
			default:
				return null;
		}
	};

	const onRowSelected = (params: RowSelectedEvent) => {
		const selectedRows = params.api.getSelectedRows();
		setCircularSelect(selectedRows);
	};
	const onRowSelectedTire = (params: RowSelectedEvent) => {
		const selectedRows = params.api.getSelectedRows();
		setTireSelect(selectedRows);
	};
	const onRowSelectedInspection = (params: RowSelectedEvent) => {
		const selectedRows = params.api.getSelectedRows();
		setInspectionSelect(selectedRows);
	};

	const circularHandler = (value: ProductIdServiceCount[]) => {
		if (value.some((item) => circular.some((item2) => item2.id === item.id))) {
			toast('info', '개별상품 등록이 완료되었습니다');
			return setCircular((prev) => [
				...prev.filter(
					(item) => !value.find((selectItem) => item.id === selectItem.id),
				),
				...value,
			]);
		}

		toast('info', '개별상품 등록이 완료되었습니다');
		return setCircular((prev) => [...prev, ...value]);
	};

	const tireHandler = (value: ProductIdServiceCount[]) => {
		if (value.some((item) => tire.some((item2) => item2.id === item.id))) {
			toast('info', '개별상품 등록이 완료되었습니다');
			return setTire((prev) => [
				...prev.filter(
					(item) => !value.find((selectItem) => item.id === selectItem.id),
				),
				...value,
			]);
		}

		toast('info', '개별상품 등록이 완료되었습니다');
		return setTire((prev) => [...prev, ...value]);
	};

	const inspectionAgencyHandler = (value: ProductIdServiceCount[]) => {
		if (
			value.some((item) =>
				inspectionAgency.some((item2) => item2.id === item.id),
			)
		) {
			toast('info', '개별상품 등록이 완료되었습니다');
			return setInspectionAgency((prev) => [
				...prev.filter(
					(item) => !value.find((selectItem) => item.id === selectItem.id),
				),
				...value,
			]);
		}

		toast('info', '개별상품 등록이 완료되었습니다');
		return setInspectionAgency((prev) => [...prev, ...value]);
	};

	const getProductIdServiceCount = useCallback(() => {
		const copyList = [...circular, ...tire, ...inspectionAgency];
		const productIdServiceCount = copyList.map(
			(item: ProductIdServiceCount) => {
				return {
					productId: item.id,
					taskCount: Number(item.taskCount),
					limitationCount: Number(item.limitationCount),
				};
			},
		);
		setFieldValue('productItems', productIdServiceCount);
	}, [circular, inspectionAgency, setFieldValue, tire]);

	const getProductTypes = useCallback(() => {
		const list = [];
		let circularSumValue = 0;
		let tireSumValue = 0;
		let inspectionAgencySumValue = 0;

		if (circular.length >= 1) {
			circular.forEach((item) => {
				if (item.taskCount) circularSumValue += Number(item.taskCount);
			});

			list.push({
				productType: REGULAR_INSPECTION,
				serviceCount: circularSumValue,
			});
		}

		if (tire.length >= 1) {
			tire.forEach((item) => {
				if (item.taskCount) tireSumValue += Number(item.taskCount);
			});

			list.push({
				productType: TIRE_REPLACEMENT,
				serviceCount: tireSumValue,
			});
		}

		if (inspectionAgency.length >= 1) {
			inspectionAgency.forEach((item) => {
				if (item.taskCount) inspectionAgencySumValue += Number(item.taskCount);
			});

			list.push({
				productType: INSPECTION_AGENCY,
				serviceCount: inspectionAgencySumValue,
			});
		}

		setFieldValue('productTypes', list);
	}, [circular, inspectionAgency, setFieldValue, tire]);

	const getGridData = () => {
		setCircular([]);
		setTire([]);
		setInspectionAgency([]);
	};

	useEffect(() => {
		getGridData();
	}, []);

	useEffect(() => {
		getProductTypes();
	}, [getProductTypes]);

	useEffect(() => {
		getProductIdServiceCount();
	}, [getProductIdServiceCount]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="구독상품 등록"
				onBack={goToSubscribeProduct}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goToSubscribeProduct}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleSubmitAlertClick} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentContainer>
					<ContentBoxWithHeader
						title="상품 상세"
						borderRadius="10px"
						className="inner-content"
					>
						<ContentBox>
							<ContentBoxItem>
								<InputForm>
									<Label name="subscriptionProductName" essential>
										<span>
											<Typo $typoType="label2">상품명</Typo>
										</span>
									</Label>
									<Input
										name="subscriptionProductName"
										placeholder="상품명을 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={values.subscriptionProductName}
										onChange={handleChange}
										onReset={() => setFieldValue('subscriptionProductName', '')}
									/>
									{errors.subscriptionProductName && (
										<InputAlert $inputStatus="error">
											{errors.subscriptionProductName}
										</InputAlert>
									)}
								</InputForm>
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="subscriptionProductIntroduction" essential>
									<span>
										<Typo $typoType="label2">상품소개</Typo>
									</span>
								</Label>
								<TextArea
									style={{
										minHeight: '106px',
									}}
									placeholder="ex. 빈틈없는 내 차 정비"
									value={values.subscriptionProductIntroduction}
									defaultValue={values.subscriptionProductIntroduction}
									onChange={(e) =>
										setFieldValue(
											'subscriptionProductIntroduction',
											e.target.value,
										)
									}
								/>
								{errors.subscriptionProductIntroduction && (
									<InputAlert $inputStatus="error">
										{errors.subscriptionProductIntroduction}
									</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<div className="label-content-bundle-container">
									<Label name="subscriptionProductImage" essential>
										<span>
											<Typo $typoType="label2">상품 이미지</Typo>
										</span>
									</Label>
									<ImageUpload
										isMultiple={false}
										size="large"
										onChange={handleImageChange}
										fileList={productImageList || []}
									/>
								</div>
								{errors.subscriptionProductImage && (
									<InputAlert $inputStatus="error">
										{errors.subscriptionProductImage}
									</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="subscriptionProductViewable" essential>
									<span>
										<Typo $typoType="label2">판매 상태</Typo>
									</span>
								</Label>
								<Radio.Group
									onChange={(e: RadioChangeEvent) => {
										setFieldValue(
											'subscriptionProductViewable',
											e.target.value,
										);
									}}
									value={values.subscriptionProductViewable}
								>
									<Radio value>
										<Typo $typoType="label1">판매중</Typo>
									</Radio>
									<Radio value={false}>
										<Typo $typoType="label1">숨김</Typo>
									</Radio>
								</Radio.Group>
								{errors.subscriptionProductViewable && (
									<InputAlert $inputStatus="error">
										{errors.subscriptionProductViewable}
									</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="badge" essential>
									<span>
										<Typo $typoType="label2">추천 상품 여부</Typo>
									</span>
								</Label>
								<Radio.Group
									onChange={(e: RadioChangeEvent) => {
										setFieldValue('badge', e.target.value);
									}}
									value={values.badge}
								>
									<Radio value="recommendation">
										<Typo $typoType="label1">추천상품</Typo>
									</Radio>
									<Radio value="normal">
										<Typo $typoType="label1">추천상품 아님</Typo>
									</Radio>
								</Radio.Group>
								{errors.badge && (
									<InputAlert $inputStatus="error">{errors.badge}</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItemTaxCustom>
								<InputForm>
									<Label name="monthlyPriceIncludedTax" essential>
										<span>
											<Typo $typoType="label2">월 구독비용 (KRW)</Typo>
										</span>
									</Label>

									<div className="monthlyPrice-tax-container">
										<Input
											name="monthlyPriceIncludedTax"
											placeholder="숫자 입력"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(
												getPriceAsNumber(values.monthlyPriceIncludedTax),
											)}
											onChange={handleChange}
											onReset={() =>
												setFieldValue('monthlyPriceIncludedTax', '')
											}
											unitText="원"
											maxLength={11}
										/>
										<div className="tax-monthlyPrice">
											<Typo $typoType="label2" color="gray_6">
												공급가액{' '}
												{numberWithCommas(
													getPriceAsNumber(values.monthlyPriceIncludedTax) -
														getPriceAsNumber(
															values.monthlyPriceIncludedTax,
															true,
														),
												)}
												원 / 부가세(10%){' '}
												{numberWithCommas(
													getPriceAsNumber(
														values.monthlyPriceIncludedTax,
														true,
													),
												)}
												원
											</Typo>
										</div>
									</div>

									{errors.monthlyPriceIncludedTax && (
										<InputAlert $inputStatus="error">
											{errors.monthlyPriceIncludedTax}
										</InputAlert>
									)}
								</InputForm>
							</ContentBoxItemTaxCustom>

							<ContentBoxItem>
								<Label name="isDtg" essential>
									<span>
										<Typo $typoType="label2">DTG 옵션 설치 여부</Typo>
									</span>
								</Label>
								<Radio.Group
									onChange={(e: RadioChangeEvent) => {
										setFieldValue('isDtg', e.target.value);
									}}
									value={values.isDtg}
								>
									<Radio value>
										<Typo $typoType="label1">설치함</Typo>
									</Radio>
									<Radio value={false}>
										<Typo $typoType="label1">설치 안함</Typo>
									</Radio>
								</Radio.Group>
								{errors.isDtg && (
									<InputAlert $inputStatus="error">{errors.isDtg}</InputAlert>
								)}
							</ContentBoxItem>

							<ContentBoxItem>
								<Label name="subscriptionProductDescription" essential>
									<span>
										<Typo $typoType="label2">상세 설명</Typo>
									</span>
								</Label>
								<TextArea
									style={{
										minHeight: '140px',
									}}
									placeholder="상세 설명을 입력해 주세요"
									value={values.subscriptionProductDescription}
									defaultValue={values.subscriptionProductDescription}
									onChange={(e) =>
										setFieldValue(
											'subscriptionProductDescription',
											e.target.value,
										)
									}
								/>
								{errors.subscriptionProductDescription && (
									<InputAlert $inputStatus="error">
										{errors.subscriptionProductDescription}
									</InputAlert>
								)}
							</ContentBoxItem>
						</ContentBox>
					</ContentBoxWithHeader>

					<ContentBoxWithHeader
						title="순회정비"
						borderRadius="10px"
						className="inner-content"
					>
						<ContentBox>
							<ContentBoxItemCustom>
								<ButtonContainer>
									<Button
										size="small"
										onClick={() => {
											setIsCircularModal(true);
										}}
									>
										개별 상품 불러오기
									</Button>
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST_BLACK}
										onClick={() =>
											handleSelectRemoveClick(
												circularSelect.map((item) => item.id),
												'circular',
											)
										}
										disabled={circular.length === 0}
									>
										선택 상품 삭제
									</Button>
								</ButtonContainer>
								<SelectionGridWrapper>
									<SelectionGrid
										type="checkbox"
										onGridReady={getGridData}
										rowData={circular}
										column={column.colsList}
										onRowSelected={onRowSelected}
									/>
								</SelectionGridWrapper>
							</ContentBoxItemCustom>
						</ContentBox>
					</ContentBoxWithHeader>
					<ContentBoxWithHeader
						title="타이어 교환"
						borderRadius="10px"
						className="inner-content"
					>
						<ContentBox>
							<ContentBoxItemCustom>
								<ButtonContainer>
									<Button
										size="small"
										onClick={() => {
											setIsTireModal(true);
										}}
									>
										개별 상품 불러오기
									</Button>
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST_BLACK}
										onClick={() =>
											handleSelectRemoveClick(
												tireSelect.map((item) => item.id),
												'tire',
											)
										}
										disabled={tire.length === 0}
									>
										선택 상품 삭제
									</Button>
								</ButtonContainer>
								<SelectionGridWrapper>
									<SelectionGrid
										type="checkbox"
										onGridReady={getGridData}
										rowData={tire}
										column={column.colsList}
										onRowSelected={onRowSelectedTire}
									/>
								</SelectionGridWrapper>
							</ContentBoxItemCustom>
						</ContentBox>
					</ContentBoxWithHeader>
					<ContentBoxWithHeader
						title="검사대행"
						borderRadius="10px"
						className="inner-content"
					>
						<ContentBox>
							<ContentBoxItemCustom>
								<ButtonContainer>
									<Button
										size="small"
										onClick={() => {
											setIsInspectionModal(true);
										}}
									>
										개별 상품 불러오기
									</Button>
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST_BLACK}
										onClick={() =>
											handleSelectRemoveClick(
												inspectionSelect.map((item) => item.id),
												'inspectionAgency',
											)
										}
										disabled={inspectionAgency.length === 0}
									>
										선택 상품 삭제
									</Button>
								</ButtonContainer>
								<SelectionGridWrapper>
									<SelectionGrid
										type="checkbox"
										onGridReady={getGridData}
										rowData={inspectionAgency}
										column={column.colsList}
										onRowSelected={onRowSelectedInspection}
									/>
								</SelectionGridWrapper>
							</ContentBoxItemCustom>
						</ContentBox>
					</ContentBoxWithHeader>
				</ContentContainer>

				{isCircularModal && (
					<EditModal
						isVisible={isCircularModal}
						onClose={() => setIsCircularModal(false)}
						onChange={(e) => circularHandler(e)}
						type={REGULAR_INSPECTION}
						selectedList={circular}
					/>
				)}
				{isTireModal && (
					<EditModal
						isVisible={isTireModal}
						onClose={() => setIsTireModal(false)}
						onChange={(e) => tireHandler(e)}
						type={TIRE_REPLACEMENT}
						selectedList={tire}
					/>
				)}
				{isInspectionModal && (
					<EditModal
						isVisible={isInspectionModal}
						onClose={() => setIsInspectionModal(false)}
						onChange={(e) => inspectionAgencyHandler(e)}
						type={INSPECTION_AGENCY}
						selectedList={inspectionAgency}
					/>
				)}
			</GridDetailTemplate>

			{isAlertVisible ? (
				<Alert
					title="구독상품 등록"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleSubmitClick}
					isVisible={isAlertVisible}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							구독상품을 등록하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default SubscribeProductCreatePage;
