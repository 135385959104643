import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'antd';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import Button from '../../../components/atoms/Button';
import {
	useEditVSPolicyMutation,
	useGetVSPolicyDetailQuery,
} from '../../../store/apis/vehicleSubscription';
import {
	EditVehicleSubscriptionPolicy,
	VehicleSubscriptionRequestType,
} from '../../../interface/vehicleSubscription';
import InputForm from '../../../components/molecules/InputForm';
import Input from '../../../components/atoms/Input';
import Label from '../../../components/atoms/Label';
import TextArea from '../../../components/atoms/TextArea';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import { getPriceAsNumber, numberWithCommas } from '../../../utils/data-format';
import Typo from '../../../components/atoms/Typo';

interface VehicleSubscriptionPolicyProps {
	vehicleType: VehicleSubscriptionRequestType;
}

const VehicleSubscriptionPolicy = ({
	vehicleType,
}: VehicleSubscriptionPolicyProps) => {
	const toast = useContext(ToastContext);
	const [editVSPolicy] = useEditVSPolicyMutation();

	const { data } = useGetVSPolicyDetailQuery({ vehicleType });
	const policyData = data?.row;

	const initialValues: EditVehicleSubscriptionPolicy = {
		minMonth: policyData?.minMonth ?? 1,
		maxMonth: policyData?.maxMonth ?? 1,
		description: policyData?.description ?? '약정 기본 안내',
		fundingRatePct: policyData?.fundingRatePct ?? 1,
		acquisitionPricePct: policyData?.acquisitionPricePct ?? 50,
		irr: policyData?.irr ?? 10,
		prePaymentPct: policyData?.prePaymentPct ?? 20,
		vehicleRegistrationTax: policyData?.vehicleRegistrationTax ?? 0,
		insurancePrice: policyData?.insurancePrice ?? 0,
		vehiclePlatePrice: policyData?.vehiclePlatePrice ?? 0,
		vehicleType: policyData?.vehicleType ?? vehicleType,
	};

	const handleValidate = (values: typeof initialValues) => {
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (!values.description) {
			errors.description = '약정 기본 안내 문구를 작성해주세요.';
		}

		return errors;
	};

	const handleSubmit = async (values: typeof initialValues) => {
		const result = await editVSPolicy(values);
		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '정책이 수정되었습니다.');
		}
	};
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: handleSubmit,
		validate: handleValidate,
	});

	return (
		<ContentBoxWithHeader
			title={vehicleType}
			borderRadius="10px"
			rightAccessory={
				<Button size="small" onClick={() => formik.handleSubmit()}>
					수정하기
				</Button>
			}
		>
			<InputForm>
				<Label name="minMonth" essential>
					최소약정개월수
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="minMonth"
					value={formik.values.minMonth}
					unitText="개월"
				/>
				<Label name="maxMonth" essential>
					최대약정개월수
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="maxMonth"
					value={formik.values.maxMonth}
					unitText="개월"
				/>
				<Label name="description" essential>
					약정정보 안내 문구
				</Label>
				<TextArea
					onChange={formik.handleChange}
					name="description"
					value={formik.values.description}
				/>
				{formik.errors.description && (
					<Typo color="danger_5_main">{formik.errors.description}</Typo>
				)}
				<Label name="fundingRatePct" essential>
					조달금리
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="fundingRatePct"
					value={formik.values.fundingRatePct}
					unitText="%"
				/>
				<Label name="prePaymentPct" essential>
					선납률
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="prePaymentPct"
					value={formik.values.prePaymentPct}
					unitText="%"
				/>
				<Label name="acquisitionPricePct" essential>
					인수잔가율
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="acquisitionPricePct"
					value={formik.values.acquisitionPricePct}
					unitText="%"
				/>
				<Label name="irr" essential>
					내부수익(IRR)
				</Label>
				<Input
					onChange={formik.handleChange}
					type="number"
					name="irr"
					value={formik.values.irr}
					unitText="%"
				/>
				<Label name="vehicleRegistrationTax" essential>
					취등록세
				</Label>
				<Input
					onChange={(e) =>
						formik.setFieldValue(
							'vehicleRegistrationTax',
							getPriceAsNumber(e.target.value),
						)
					}
					type="text"
					name="vehicleRegistrationTax"
					value={numberWithCommas(formik.values.vehicleRegistrationTax)}
					unitText="원"
				/>
				<Label name="insurancePrice" essential>
					보험료
				</Label>
				<Input
					onChange={(e) =>
						formik.setFieldValue(
							'insurancePrice',
							getPriceAsNumber(e.target.value),
						)
					}
					type="text"
					name="insurancePrice"
					value={numberWithCommas(formik.values.insurancePrice)}
					unitText="원"
				/>
				<Label name="vehiclePlatePrice" essential>
					번호판비용
				</Label>
				<Input
					onChange={(e) =>
						formik.setFieldValue(
							'vehiclePlatePrice',
							getPriceAsNumber(e.target.value),
						)
					}
					type="text"
					name="vehiclePlatePrice"
					value={numberWithCommas(formik.values.vehiclePlatePrice)}
					unitText="원"
				/>
			</InputForm>
		</ContentBoxWithHeader>
	);
};

const VehicleSubscriptionPolicies = () => {
	return (
		<GridDetailTemplate detailTitle="차량구독 정책관리">
			<Row gutter={[12, 12]}>
				<Col md={12}>
					<VehicleSubscriptionPolicy vehicleType="신차출고" />
				</Col>
				<Col md={12}>
					<VehicleSubscriptionPolicy vehicleType="중고차출고" />
				</Col>
			</Row>
		</GridDetailTemplate>
	);
};

export default VehicleSubscriptionPolicies;
