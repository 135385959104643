import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { FilterFormInfo } from './filter-util';
import { FilterSelectFormInfo } from './filter-select-util';

// 타입 정의
export enum FilterType {
	Number = 'agNumberColumnFilter',
	Text = 'agTextColumnFilter',
	Date = 'agDateColumnFilter',
}

// 컬럼 사이즈
export enum ColSize {
	CW_X_SMALL = 50,
	CW_SMALL = 80,
	CW_SMALL_M = 96,
	CW_MEDIUM = 120,
	CW_LARGE = 150,
	CW_DATE_RANGE = 200,
	CW_LONG_TEXT = 180,
	CW_DESCRIPTION = 320,
}

export interface TableColInfo {
	field: string;
	headerName: string;
	filter?: FilterType;
}

export interface TableColumnOptions extends ColDef {
	valueGetter?: ((params: ValueGetterParams) => any) | string;
	minWidth?: ColSize;
	flex?: number;
}

export interface TableColItem {
	tableInfo: TableColInfo;
	options?: TableColumnOptions;
}

export interface TableColumn extends ColDef {
	headerName: string;
	field?: string;
	valueGetter?: ((params: ValueGetterParams) => any) | string;
	minWidth?: number;
	flex?: number;
}

export interface GridDataModel {
	formInitialValues?: Record<string, string | number | any[]>;
	colsList: TableColItem[];
	forms?: FilterFormInfo[];
	selectForms?: FilterSelectFormInfo[];
}
export interface GridDataColsListModel {
	formInitialValues: Record<string, string | number>;
	colsList: TableColItem[];
}

// 컬럼 아이템 생성
export const createTableCol = (
	{ field, headerName, filter = FilterType.Text }: TableColInfo,
	options?: TableColumnOptions,
): TableColumn => {
	const col: TableColumn = {
		headerName,
		field,
		filter,
	};
	if (options) {
		return {
			...col,
			...options,
		};
	}
	return col;
};

// 컬럼 리스트 생성
export const createColList = (colListItem: TableColItem[]): TableColumn[] =>
	colListItem.map((colItem) =>
		createTableCol(colItem.tableInfo, colItem.options),
	);

// 체크박스 그리드 컬럼

export interface SelectionColsList extends ColDef {
	headerName: string;
	field: string;
	flex?: number;
}

export interface SelectionGridColumn {
	colsList: SelectionColsList[];
	forms?: FilterFormInfo[];
}
