import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { ThemeType } from '../../../styles/theme';
import {
	deleteIcon,
	inputTypePasswordIcon,
	inputTypePasswordVisibleIcon,
} from '../../../assets/icon';
import Icon from '../Icon';
import Typo from '../Typo';

export type InputType = 'text' | 'password' | 'number';
export type InputSize = 'large' | 'small';
export type InputRadius = 'large' | 'small';
export type InputStatus = 'success' | 'error' | 'normal';

export interface InputProps {
	name: string;
	$isDisabled?: boolean;
	$hasError?: boolean;
	type: InputType;
	$inputRadius?: InputRadius;
	$inputSize?: InputSize;
	$inputStatus?: InputStatus;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	value: string | number;
	maxLength?: number;
	unitText?: string;
	defaultValue?: string | number;
	onReset?: (value: React.ChangeEvent) => void;
	placeholder?: string;
	$isCompact?: boolean;
	$textAlign?: 'left' | 'right' | 'center';
}

interface InputStyleProps {
	$inputSize: InputSize;
	$inputStatus: InputStatus;
	$inputRadius: InputRadius;
	$isDisabled: boolean;
	$hasError: boolean;
	$isCompact?: boolean;
}

const getInputRadius = (radius: InputRadius) => {
	let inputRadius;
	switch (radius) {
		case 'large':
			inputRadius = '12px';
			break;
		case 'small':
			inputRadius = '8px';
			break;
		default:
			return null;
	}
	return css`
		border-radius: ${inputRadius};
	`;
};

const getInputSize = (size: InputSize) => {
	let inputHeight;
	switch (size) {
		case 'large':
			inputHeight = '48px';
			break;
		case 'small':
			inputHeight = '32px';
			break;
		default:
			return null;
	}
	return css`
		height: ${inputHeight};
	`;
};

const getInputStatus = (inputStatus: InputStatus, theme: ThemeType) => {
	let status;
	let color;
	let outline;
	switch (inputStatus) {
		case 'error':
			status = theme.common.colors.danger_5_main;
			color = theme.common.colors.gray_11;
			outline = theme.common.colors.danger_1;
			break;
		case 'success':
			status = theme.common.colors.success_5_main;
			color = theme.common.colors.gray_11;
			outline = theme.common.colors.success_1;
			break;
		case 'normal':
			status = theme.common.colors.gray_4;
			color = theme.common.colors.gray_11;
			outline = 'none';
			break;
		default:
			return null;
	}
	return css`
		border: 1px solid ${status};
		outline: 2px solid ${outline};
		color: ${color};
	`;
};

const StyledInput = styled.input<{
	$inputRadius: InputRadius;
	$isCompact: boolean | undefined;
	$textAlign: 'left' | 'right' | 'center';
}>`
	width: 100%;
	height: 100%;
	background: transparent;
	outline: none;
	border: none;
	padding-left: ${({ $textAlign }) => ($textAlign === 'right' ? '0' : '10px')};
	padding-right: ${({ $textAlign }) => ($textAlign === 'right' ? '10px' : '0')};

	${({ $textAlign }) => `text-align: ${$textAlign}`};

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px white inset;
		box-shadow: 0 0 0 1000px white inset;
	}

	${({ $inputRadius }) => getInputRadius($inputRadius)};

	&::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
		${({ theme, $isCompact }) =>
			!$isCompact ? theme.typo.input2 : theme.typo.input3};
	}

	&:disabled {
		cursor: not-allowed;
	}

	&::placeholder {
		color: ${({ theme }) => theme.common.colors.gray_6};
		${({ theme, $isCompact }) =>
			!$isCompact ? theme.typo.input2 : theme.typo.input3};
	}
`;

const InputContainer = styled.div<InputStyleProps>`
	width: 100%;
	display: flex;
	transition: 0.3s;
	background: ${({ $hasError }) => ($hasError ? 'pink' : 'transparent')};
	border: ${({ $hasError }) =>
		$hasError ? '1px solid red !important' : 'none'};

	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	${({ theme, $isCompact }) =>
		!$isCompact ? theme.typo.input2 : theme.typo.input3};
	${({ $inputRadius }) => getInputRadius($inputRadius)};
	${({ $inputStatus, theme }) => getInputStatus($inputStatus, theme)};
	${({ $inputSize }) => getInputSize($inputSize)};
	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			background: ${({ theme }) => theme.common.colors.gray_2};
			border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
			cursor: not-allowed;
		`}

	&:hover {
		border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
		box-shadow: 0 0 0 2px ${({ theme }) => theme.common.colors.primary_1};
	}

	&:focus-within {
		border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};
		box-shadow: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.input-icon-unit-container {
		background: transparent;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-bottom-right-radius: ${({ $inputSize }) =>
			$inputSize === 'large' ? '12px' : '7px'};
		border-top-right-radius: ${({ $inputSize }) =>
			$inputSize === 'large' ? '12px' : '7px'};
	}

	.icon-container {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-right: 5px;
		height: 100%;
	}

	.input-unit {
		width: max-content;
		display: flex;
		align-items: center;
		height: 100%;
		color: ${({ theme }) => theme.common.colors.gray_6};
		background-color: ${({ theme }) => theme.common.colors.gray_2};
		border-bottom-right-radius: ${({ $inputSize }) =>
			$inputSize === 'large' ? '12px' : '7px'};
		border-top-right-radius: ${({ $inputSize }) =>
			$inputSize === 'large' ? '12px' : '7px'};
		padding: 0 10px;
		user-select: none;
		z-index: 1;
	}
`;

function Input({
	placeholder,
	type,
	name,
	$isDisabled = false,
	$hasError = false,
	$inputSize = 'small',
	$inputRadius = 'small',
	$inputStatus = 'normal',
	value,
	onChange,
	onKeyDown,
	maxLength = 10000,
	unitText = '',
	defaultValue,
	onReset,
	$isCompact = false,
	$textAlign = 'left',
	...rest
}: InputProps) {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const handlePasswordVisivleIcon = () => {
		setIsPasswordVisible((prev) => !prev);
	};

	return (
		<InputContainer
			$inputSize={$inputSize}
			$inputStatus={$inputStatus}
			$inputRadius={$inputRadius}
			$isDisabled={$isDisabled}
			$hasError={$hasError}
			$isCompact={$isCompact}
			{...rest}
		>
			<StyledInput
				placeholder={placeholder}
				type={isPasswordVisible ? 'text' : type}
				name={name}
				value={value}
				disabled={$isDisabled}
				onChange={onChange}
				maxLength={maxLength}
				defaultValue={defaultValue}
				$inputRadius={$inputRadius}
				onKeyDown={onKeyDown}
				$isCompact={$isCompact}
				$textAlign={$textAlign as 'left'}
			/>

			<div className="input-icon-unit-container">
				{!!value && onReset && (
					<div className="icon-container">
						{!$isDisabled && (
							<Icon src={deleteIcon} width={24} height={24} onClick={onReset} />
						)}
						{type === 'password' && (
							<Icon
								src={
									isPasswordVisible
										? inputTypePasswordVisibleIcon
										: inputTypePasswordIcon
								}
								width={24}
								height={24}
								onClick={handlePasswordVisivleIcon}
							/>
						)}
					</div>
				)}
				{!!unitText && (
					<span className="input-unit">
						<Typo $typoType="input2" color="gray_6">
							{unitText}
						</Typo>
					</span>
				)}
			</div>
		</InputContainer>
	);
}

export default Input;
