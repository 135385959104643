import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import CompanyTemplate from '../../../../components/templates/CompanyTemplate';
import {
	useGetCompanyDetailQuery,
	useGetMyCompanyQuery,
} from '../../../../store/apis/company';
import { UpdateCompanyDto } from '../../../../interface/company';
import useCheckRole from '../../../../hooks/useCheckRole';

function CompanyDetailPage() {
	const isSuper = useCheckRole({
		roleType: 'isSuper',
		roleName: '법인관리',
		includeSuper: true,
	});
	const { companyId } = useParams();
	const { data: myCompanyDetail } = useGetMyCompanyQuery(undefined, {
		skip: isSuper,
	});
	const { isLoading, data } = useGetCompanyDetailQuery(
		{ companyId },
		{ skip: !isSuper },
	);
	const companyDetail = isSuper ? data?.row : myCompanyDetail?.row;

	const initialValues: UpdateCompanyDto = {
		signUpChannel: companyDetail?.signUpChannel,
		name: companyDetail?.name,
		registrationNumber: companyDetail?.registrationNumber,
		businessLicenseNumber: companyDetail?.businessLicenseNumber,
		businessLicense: companyDetail?.businessLicense,
		representativePhoneNumber: companyDetail?.representativePhoneNumber,
		contactName: companyDetail?.contactName,
		contactPhoneNumber: companyDetail?.contactPhoneNumber,
		status: companyDetail?.status,
	};

	return isLoading ? (
		<Skeleton active paragraph={{ rows: 6 }} style={{ borderRadius: '10px' }} />
	) : (
		<CompanyTemplate
			initialValues={initialValues}
			title="법인 수정하기"
			createdAt={companyDetail?.createdAt}
			updatedAt={companyDetail?.updatedAt}
		/>
	);
}

export default CompanyDetailPage;
