import React, { useContext } from 'react';
import { Drawer, Tag } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import dayjs from 'dayjs';
import { useUpdatePartnerPaymentMutation } from '../../../store/apis/carService';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Input from '../../atoms/Input';
import { PaymentsInfo, SettlementInfo } from '../../../interface/carService';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import TextArea from '../../atoms/TextArea';
import { numberWithCommas } from '../../../utils/data-format';

interface BillingTabPaneProps {
	inspectionId: string | undefined;
	open: boolean;
	onClose: (() => void) | undefined;
	billingInfoData: Partial<PaymentsInfo & SettlementInfo> | undefined;
	detailInfoRefetch: () => void;
}

const BillingTabPane = ({
	inspectionId,
	open,
	onClose,
	billingInfoData,
	detailInfoRefetch,
}: BillingTabPaneProps) => {
	const setToast = useContext(ToastContext);

	const [updatePayments, { isLoading: isPaymentsUpdating }] =
		useUpdatePartnerPaymentMutation();

	const initialValues: Partial<PaymentsInfo & SettlementInfo> = billingInfoData
		? {
				purchaseInspectionAmount: billingInfoData.purchaseInspectionAmount,
				purchaseTransportAmount: billingInfoData.purchaseTransportAmount,
				purchaseEtceteraAmount: billingInfoData.purchaseEtceteraAmount,
				purchaseTotalAmount: billingInfoData.purchaseTotalAmount,
				penaltyAmount: billingInfoData.penaltyAmount,
				phoneCallAmount: billingInfoData.phoneCallAmount,
				proxyInspectionAmountPaymentDate:
					billingInfoData.proxyInspectionAmountPaymentDate,
				proxyInspectionAmountPaymentAmount:
					billingInfoData.proxyInspectionAmountPaymentAmount,
				proxyInspectionAmountPaymentMemo:
					billingInfoData.proxyInspectionAmountPaymentMemo,
		  }
		: {
				purchaseInspectionAmount: 0,
				purchaseTransportAmount: 0,
				purchaseEtceteraAmount: 0,
				purchaseTotalAmount: 0,
				penaltyAmount: 0,
				phoneCallAmount: 0,
				proxyInspectionAmountPaymentDate: '',
				proxyInspectionAmountPaymentAmount: 0,
				proxyInspectionAmountPaymentMemo: '',
		  };

	const handleSubmit = async (
		values: Partial<PaymentsInfo & SettlementInfo>,
		formikHelpers: FormikHelpers<any>,
	) => {
		const res = await updatePayments({
			inspectionId,
			body: {
				...values,
				proxyInspectionAmountPaymentDate: dayjs().format('YYYY-MM-DD'),
			},
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
			formikHelpers.setValues(initialValues);
		} else {
			detailInfoRefetch();
			setToast('info', '저장이 완료되었습니다.');
		}
	};

	const {
		// validateForm,
		// setValues,
		values: formikValues,
		// errors: formikErrors,
		// handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<Drawer open={open} width={980} closable={false} onClose={onClose}>
			<ContentBoxWithHeader title="지급요청" borderRadius="10px" $isCompact>
				<Tag color="green" style={{ fontSize: '13px', marginBottom: '16px' }}>
					{billingInfoData?.paymentState ?? '없음'}
				</Tag>
				<GridLayout $columnCount={3} $isCompact>
					<GridItem
						key="purchaseInspectionAmount"
						title="검사비매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseInspectionAmount"
							placeholder="검사비매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseInspectionAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseInspectionAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseInspectionAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="purchaseTransportAmount"
						title="이동비매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseTransportAmount"
							placeholder="이동비매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseTransportAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseTransportAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseTransportAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="purchaseEtceteraAmount"
						title="기타매입"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="purchaseEtceteraAmount"
							placeholder="기타매입"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							value={numberWithCommas(
								Number(formikValues.purchaseEtceteraAmount),
							)}
							onChange={(e) =>
								setFieldValue(
									'purchaseEtceteraAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
							onReset={() => {
								setFieldValue('purchaseEtceteraAmount', 0);
							}}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountPaymentAmount"
						title="매입총금액"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
					>
						<Input
							name="proxyInspectionAmountPaymentAmount"
							placeholder="매입총금액"
							type="text"
							$inputSize="small"
							$inputRadius="small"
							$isDisabled
							value={numberWithCommas(
								Number(formikValues.purchaseInspectionAmount ?? 0) +
									Number(formikValues.purchaseTransportAmount ?? 0) +
									Number(formikValues.purchaseEtceteraAmount ?? 0),
							)}
							onChange={(e) =>
								setFieldValue(
									'proxyInspectionAmountPaymentAmount',
									Number(String(e.target.value).replace(/[^0-9]/g, '')),
								)
							}
						/>
					</GridItem>
					<GridItem
						key="proxyInspectionAmountPaymentDate"
						title="매입일"
						size={GridTitleSize.GT_X_SMALL}
						$isCompact
						span={2}
					>
						<Tag color="blue" style={{ fontSize: '13px' }}>
							{formikValues.proxyInspectionAmountPaymentDate ||
								'요청한 날짜로 설정됩니다'}
						</Tag>
					</GridItem>
				</GridLayout>
				<TextArea
					name="proxyInspectionAmountPaymentMemo"
					placeholder="메모를 입력해주세요"
					value={formikValues.proxyInspectionAmountPaymentMemo}
					onChange={(e) => {
						setFieldValue('proxyInspectionAmountPaymentMemo', e.target.value);
					}}
					onReset={() => {
						setFieldValue('proxyInspectionAmountPaymentMemo', '');
					}}
					style={{ marginTop: '8px' }}
				/>
				<Button
					size="default"
					$buttonType={ButtonTypeType.PRIMARY_LIGHT}
					onClick={() => formikSubmit()}
					style={{ marginTop: '8px' }}
				>
					{isPaymentsUpdating ? '저장중..' : '저장하기'}
				</Button>
			</ContentBoxWithHeader>
		</Drawer>
	);
};

export default BillingTabPane;
