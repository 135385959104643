import {
	GenerateAppVersionDto,
	GenerateAppVersionResponseDto,
} from '../../../interface/appVersion';
import { axiosInstance } from '../../../network/axios';
import { ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const appVersionApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		appVersionCreate: builder.mutation<
			ObjectResponse<GenerateAppVersionResponseDto>,
			GenerateAppVersionDto
		>({
			query: ({ service, type, version, description, managerName }) => ({
				method: 'POST',
				url: '/core/app-versions',
				body: {
					service,
					type,
					version,
					description,
					managerName,
				},
			}),
		}),
		appVersionModify: builder.mutation<
			ObjectResponse<GenerateAppVersionResponseDto>,
			{ id: number; description: string }
		>({
			query: ({ id, description }) => ({
				method: 'PUT',
				url: `/core/app-versions/${id}`,
				body: {
					description,
				},
			}),
		}),
	}),
});

export const { useAppVersionCreateMutation, useAppVersionModifyMutation } =
	appVersionApi;
export default appVersionApi;

export const getAppVersionApiDetailId = async (params: number) => {
	const url = `/core/app-versions/${params}`;
	const { data } = await axiosInstance.request({ url });
	return data;
};
