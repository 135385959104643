import React from 'react';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useGridData from '../../../../hooks/useGridData';
import { CS_CHAT_DETAIL_PATH } from '../../../../routes/constants/urls';
import oneOnOneChatData from './gridData';

function OneOnOneChatPage() {
	const { formInitialValues, colsList } = oneOnOneChatData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
	} = useGridData({
		queryPath: 'members/admin/inquiries',
		detailPath: CS_CHAT_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="1:1 문의 관리"
			gridTitle="1:1 문의 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			onSearchSubmit={handleSearchSubmit}
		/>
	);
}

export default OneOnOneChatPage;
