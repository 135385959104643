import dayjs from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { companyRegisterNumberFormat } from '../../../../utils/data-format';
import { LabelValue } from '../../../../utils/const';

const vehiclePlateData: (companiesOption: LabelValue[]) => GridDataModel = (
	companiesOption,
) => {
	return {
		formInitialValues: {},
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '등록일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicleNumber',
					headerName: '번호판',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'companyName',
					headerName: '소유자',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.company?.name ?? '-';
					},
					valueGetter: ({ data }) => {
						return data.company?.name ?? '';
					},
				},
			},
			{
				tableInfo: {
					field: 'businessLicenseNumber',
					headerName: '사업자등록번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams) => {
						return (
							companyRegisterNumberFormat(
								data.company?.businessLicenseNumber,
							) ?? '-'
						);
					},
					valueGetter: ({ data }) => {
						return data.company?.businessLicenseNumber ?? '';
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicle.vin',
					headerName: '차대번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.vehicle
							? data.vehicle.vin ?? '차대번호 없음'
							: '배정된 차량없음';
					},
					valueGetter: ({ data }) => {
						return data.vehicle?.vin ?? '';
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicle.vehicleName',
					headerName: '차종명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: [
			{
				key: 'createdAt',
				label: '등록일자',
				type: FilterElementType.DATE_RANGE_PICKER,
			},
			{
				key: 'vehicleNumber',
				type: FilterElementType.INPUT,
				label: '차량번호',
			},
			{
				key: 'companyId',
				type: FilterElementType.SELECT,
				label: '소유자',
				options: [{ label: '전체', value: '' }, ...companiesOption],
			},
		],
	};
};

export default vehiclePlateData;
