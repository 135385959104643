import React from 'react';
import Input, { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import styled from 'styled-components';

const TextAreaContainer = styled(Input.TextArea)`
	&.ant-input {
		min-height: 140px;
		font-size: 13px;
		border-radius: 8px;

		&::placeholder {
			font-size: 13px;
		}
	}
`;

interface TextAreaProps extends AntdTextAreaProps {}

const TextArea = ({ ...rest }: TextAreaProps) => {
	return <TextAreaContainer autoSize={{ minRows: 3 }} {...rest} />;
};

export default TextArea;
