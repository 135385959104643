/* eslint-disable no-use-before-define */
import React, {
	useEffect,
	useState,
	useRef,
	forwardRef,
	useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import { ConfigProvider, DatePicker } from 'antd';
import locale from 'antd/lib/locale/ko_KR';
import dayjs, { Dayjs } from 'dayjs';
import { arrowLeftIcon, arrowRightIcon } from '../../../assets/icon';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Popup from '../../atoms/PopUp';

interface CalendarDayButtonProps {
	isActive: boolean;
	isToday?: boolean;
	isHoliday?: boolean;
	date: number;
	day: string;
	extraInfo?: string;
	onClick: () => void;
}

const CalendarDayButton = forwardRef<HTMLButtonElement, CalendarDayButtonProps>(
	(
		{
			isActive,
			isHoliday = false,
			isToday = false,
			date,
			day,
			extraInfo = undefined,
			onClick,
		},
		ref,
	) => {
		return (
			<DayButton
				ref={ref}
				$isActive={isActive}
				$isHoliday={isHoliday}
				$isToday={isToday}
				onClick={onClick}
			>
				<DayNumberSpan>{date}</DayNumberSpan>
				<DayStringSpan>{day}요일</DayStringSpan>
				{extraInfo && <DayExtraInfoSpan>{extraInfo}</DayExtraInfoSpan>}
			</DayButton>
		);
	},
);

const DIFF_FORMAT_STRING = 'YYYYMMDD';

const dayArray = ['일', '월', '화', '수', '목', '금', '토'];

const m = dayjs();

const getDay = (index: number) => {
	return dayArray[index];
};

const JUMP_TO = 470;

interface SwiperCalendarProps {
	onClick: (date: Dayjs) => void;
	defaultSelected?: string;
	jumpTo?: number;
	monthCounts: Record<number, number>;
	monthExtraInfo: Record<number, string>;
}

function SwiperCalendar({
	onClick,
	jumpTo = JUMP_TO,
	defaultSelected,
	monthCounts,
	monthExtraInfo,
}: SwiperCalendarProps) {
	const [year, setYear] = useState<number>(
		defaultSelected ? dayjs(defaultSelected).year() : m.year(),
	);
	const [month, setMonth] = useState<number>(
		defaultSelected ? dayjs(defaultSelected).month() : m.month(),
	);

	const [lastDateOfMonth, setLastDateOfMonth] = useState(
		m.endOf('month').date(),
	);
	const [selectedMoment, setSelectedMoment] = useState(dayjs(defaultSelected));
	const [isDisabledPrevButton, setIsDisabledPrevButton] =
		useState<boolean>(true);
	const [isDisabledNextButton, setIsDisabledNextButton] =
		useState<boolean>(false);

	const holeRef = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const selectedDayRef = useRef<HTMLButtonElement | null>(null);

	const handleMonthChange = (value: Dayjs | string) => {
		setYear(dayjs(value).year());
		setMonth(dayjs(value).month());
		setLastDateOfMonth(dayjs(value).endOf('month').date());
		setSelectedMoment(dayjs(value));
		disabledButtonTo('left');

		if (onClick) {
			onClick(dayjs(value));
		}

		if (wrapperRef.current) {
			const { style } = wrapperRef.current;
			style.right = '0px';
		}
	};

	const resetToday = () => {
		setYear(dayjs().year());
		setMonth(dayjs().month());
		setSelectedMoment(dayjs());

		if (onClick) {
			onClick(dayjs());
		}
	};

	const disabledButtonTo = (position: 'left' | 'right') => {
		if (position === 'left') {
			setIsDisabledNextButton(false);
			setIsDisabledPrevButton(true);
		} else {
			setIsDisabledNextButton(true);
			setIsDisabledPrevButton(false);
		}
	};

	const abledButton = () => {
		setIsDisabledNextButton(false);
		setIsDisabledPrevButton(false);
	};

	const disabledButton = () => {
		setIsDisabledNextButton(true);
		setIsDisabledPrevButton(true);
	};

	const moveCenter = useCallback(() => {
		if (selectedDayRef.current && wrapperRef.current && holeRef.current) {
			const { offsetLeft, scrollLeft, clientLeft } = selectedDayRef.current;
			const { style, scrollWidth } = wrapperRef.current;

			const fromStart = offsetLeft - scrollLeft - clientLeft;

			const isLeftEdge =
				holeRef.current.getBoundingClientRect().width / 2 >
				fromStart + selectedDayRef.current.getBoundingClientRect().width / 2;

			const isRightEdge =
				wrapperRef.current.scrollWidth -
					selectedDayRef.current.getBoundingClientRect().width / 2 -
					fromStart <=
				holeRef.current.getBoundingClientRect().width / 2;

			if (isRightEdge) {
				style.right = `${
					scrollWidth - wrapperRef.current.getBoundingClientRect().width
				}px`;

				disabledButtonTo('right');
			}

			if (isLeftEdge) {
				style.right = '0px';

				disabledButtonTo('left');
			}

			if (!isLeftEdge && !isRightEdge) {
				style.right = `${
					fromStart -
					holeRef.current.getBoundingClientRect().width / 2 +
					selectedDayRef.current.getBoundingClientRect().width / 2
				}px`;

				abledButton();
			}

			if (
				holeRef.current.getBoundingClientRect().width >=
				wrapperRef.current.scrollWidth
			) {
				disabledButton();
			}
		}
	}, []);

	const handlePrev = () => {
		if (wrapperRef.current) {
			const { style } = wrapperRef.current;

			const rightCoordination = parseInt(style.right || '0', 10);

			if (rightCoordination > 0) {
				if (rightCoordination - jumpTo < 0) {
					style.right = '0px';
					disabledButtonTo('left');
				} else {
					style.right = `${rightCoordination - jumpTo}px`;
					abledButton();
				}
			}
		}
	};

	const handleNext = () => {
		if (wrapperRef.current) {
			const { style, scrollWidth } = wrapperRef.current;

			const rightCoordination = parseInt(style.right || '0', 10);

			const endPointCoordination =
				wrapperRef.current.getBoundingClientRect().width + rightCoordination;

			if (endPointCoordination + jumpTo > scrollWidth) {
				style.right = `${
					scrollWidth - wrapperRef.current.getBoundingClientRect().width
				}px`;
				disabledButtonTo('right');
			} else {
				style.right = `${rightCoordination + jumpTo}px`;
				abledButton();
			}
		}
	};

	const handleClick = (clickedMoment: Dayjs) => {
		onClick(clickedMoment);
		setSelectedMoment(clickedMoment);
	};

	useEffect(() => {
		if (selectedMoment) {
			moveCenter();
		}
	}, [selectedMoment, moveCenter]);

	return (
		<article>
			<InputWrapper>
				<ConfigProvider locale={locale}>
					<div>
						<span className="input_title">날짜</span>
						<DatePicker
							defaultValue={dayjs().set('year', year).set('month', month)}
							value={dayjs().set('year', year).set('month', month)}
							onChange={(value: Dayjs | null) =>
								value ? handleMonthChange(value) : null
							}
							format="YYYY년 MM월"
							picker="month"
							style={{
								width: '200px',
								border: '1px solid #DCDEE0',
								borderRadius: '8px',
							}}
						/>
					</div>
				</ConfigProvider>

				<Button
					$buttonType={ButtonTypeType.GHOST_BLACK}
					size="small"
					onClick={resetToday}
				>
					오늘날짜
				</Button>
			</InputWrapper>

			<Section>
				<ArrowButton onClick={handlePrev} $isDisabled={isDisabledPrevButton}>
					<Icon src={arrowLeftIcon} width={24} height={24} />
				</ArrowButton>

				<HiddenWrapper ref={holeRef}>
					<CalendarDayWrapper ref={wrapperRef}>
						{Array.from({ length: lastDateOfMonth }, (_, index) => {
							const buttonMoment = dayjs()
								.set('year', year)
								.set('month', month)
								.set('date', index + 1);
							const isHoliday = buttonMoment.day() === 0;

							const isActive =
								selectedMoment.format(DIFF_FORMAT_STRING) ===
								buttonMoment.format(DIFF_FORMAT_STRING);

							const isToday = dayjs().isSame(buttonMoment, 'day');

							return (
								<CalendarDayTask key={index.toString()}>
									<Popup
										count={monthCounts[index + 1]}
										className="calendar-day-task"
									/>
									<CalendarDayButton
										isActive={isActive}
										isHoliday={isHoliday}
										isToday={isToday}
										extraInfo={monthExtraInfo[index + 1]}
										date={index + 1}
										onClick={() => {
											handleClick(buttonMoment);
										}}
										day={getDay(buttonMoment.day())}
										ref={isActive ? selectedDayRef : null}
									/>
								</CalendarDayTask>
							);
						})}
					</CalendarDayWrapper>
				</HiddenWrapper>

				<ArrowButton onClick={handleNext} $isDisabled={isDisabledNextButton}>
					<Icon src={arrowRightIcon} width={24} height={24} />
				</ArrowButton>
			</Section>
		</article>
	);
}

export default SwiperCalendar;

const Section = styled.section`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;

const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.input_title {
		font-size: 14px;
		margin-right: 10px;
	}
`;

const DayNumberSpan = styled.span`
	${({ theme }) => theme.typo.hm7};
	color: ${({ theme }) => theme.common.colors.gray_11};
`;

const DayStringSpan = styled.span`
	${({ theme }) => theme.typo.b7};
	color: ${({ theme }) => theme.common.colors.gray_8};
`;

const DayExtraInfoSpan = styled.span`
	${({ theme }) => theme.typo.b7};
	color: ${({ theme }) => theme.common.colors.gray_11};
`;

const DayButton = styled.button<{
	$isActive: boolean;
	$isHoliday?: boolean;
	$isToday: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
	padding: 10px 8px;
	border-radius: 10px;
	cursor: pointer;
	flex-shrink: 0;
	width: max-content;

	${({ $isActive }) =>
		$isActive
			? css`
					background: ${({ theme }) => theme.common.colors.primary_6_main};
					border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};

					${DayStringSpan}, ${DayNumberSpan}, ${DayExtraInfoSpan} {
						color: ${({ theme }) => theme.common.colors.white};
					}
			  `
			: css`
					background: ${({ theme }) => theme.common.colors.white};
					border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
			  `};

	${({ $isHoliday, $isActive }) =>
		$isHoliday &&
		css`
			${DayStringSpan}, ${DayNumberSpan} {
				color: ${({ theme }) =>
					$isActive
						? theme.common.colors.white
						: theme.common.colors.danger_5_main};
			}
		`};

	${({ $isToday }) =>
		$isToday &&
		css`
			position: relative;

			&::after {
				content: '오늘';
				position: absolute;
				bottom: -12px;
				background: ${({ theme }) => theme.common.colors.white};
				border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
				border-radius: 64px;
				${({ theme }) => theme.typo.btn6};
				padding: 1px 6px;
			}
		`};

	&:hover {
		${({ $isActive }) =>
			!$isActive &&
			css`
				background: ${({ theme }) => theme.common.colors.primary_1};
				border: 1px solid ${({ theme }) => theme.common.colors.primary_6_main};

				${DayStringSpan} {
					color: ${({ theme }) => theme.common.colors.gray_8};
				}

				${DayNumberSpan} {
					color: ${({ theme }) => theme.common.colors.gray_11};
				}
			`};
	}
`;

const ArrowButton = styled.button<{ $isDisabled: boolean }>`
	padding: 0;
	background: ${({ theme }) => theme.common.colors.white};
	border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
	border-radius: 10px;
	cursor: pointer;
	margin: 20px 0;

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			background: ${({ theme }) => theme.common.colors.gray_3};
			border: 1px solid ${({ theme }) => theme.common.colors.gray_3};
			cursor: not-allowed;
		`};
`;

const HiddenWrapper = styled.div`
	overflow: hidden;
	padding: 20px 0;
`;

const CalendarDayWrapper = styled.div`
	display: flex;
	gap: 8px;
	width: 100%;
	position: relative;
	transition: all 0.3s ease-in;
	right: 0;
`;

const CalendarDayTask = styled.div`
	display: flex;
	justify-content: center;
	.calendar-day-task {
		position: absolute;
		top: -12px;
		z-index: 1;
	}
`;
