import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Typo from '../../../../components/atoms/Typo';
import { FilterElementType } from '../../../../utils/filter-util';
import { SelectionGridColumn, ColSize } from '../../../../utils/grid-util';
import { CELL_TYPE_OPTIONS_TEXT } from '../../../../utils/row-data-util';

const renderServiceCountInputCell = ({ value }: ICellRendererParams) => {
	if (value !== undefined) {
		return (
			<Typo $typoType="b6" color={value !== '' ? 'gray_11' : 'gray_6'}>
				{value !== '' ? value : '숫자입력'}
			</Typo>
		);
	}
	if (!value)
		return (
			<Typo $typoType="b6" color="gray_6">
				숫자입력
			</Typo>
		);

	return '';
};
const renderPriceCell = ({ data }: ICellRendererParams) => {
	return (
		<Typo $typoType="b6" color="gray_11">
			{`${Number(data.productPrice + data.productTax).toLocaleString()}원`}
		</Typo>
	);
};

const renderServiceCountCell = ({ value }: ICellRendererParams) => {
	return (
		<Typo $typoType="b6" color="gray_11">
			{value}회
		</Typo>
	);
};

const renderProductAddibleCell = ({ value }: ICellRendererParams) => {
	return (
		<Typo $typoType="b6" color="gray_11">
			{value ? 'O' : 'X'}
		</Typo>
	);
};

export const column: SelectionGridColumn = {
	colsList: [
		{
			headerName: '개별 상품명',
			field: 'productName',
			flex: 2,
			minWidth: ColSize.CW_MEDIUM,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			cellStyle: {
				'text-overflow': 'ellipsis',
				overflow: 'hidden',
			},
		},
		{
			headerName: '추가 주문 가능',
			field: 'productAddible',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: renderProductAddibleCell,
		},
		{
			headerName: '판매가',
			field: 'productPriceIncludedTax',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: renderPriceCell,
		},
		{
			headerName: '제공 횟수',
			field: 'taskCount',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: renderServiceCountCell,
		},
		{
			headerName: '추가선택 가능 횟수',
			field: 'limitationCount',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: renderServiceCountCell,
		},
	],
	forms: [
		{
			key: 'productName',
			label: '상품명',
			type: FilterElementType.INPUT,
		},
	],
};

export const modalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '개별 상품명',
			field: 'productName',
			flex: 2,
			minWidth: ColSize.CW_MEDIUM,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			cellStyle: {
				// 'text-overflow': 'ellipsis',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		{
			headerName: '서비스 유형',
			field: 'productType',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: CELL_TYPE_OPTIONS_TEXT,
		},
		{
			headerName: '추가 주문 가능',
			field: 'productAddible',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: renderProductAddibleCell,
		},
		{
			headerName: '판매가',
			field: 'productPriceIncludedTax',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: renderPriceCell,
		},
		{
			headerName: '제공 횟수',
			field: 'taskCount',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			editable: true,
			cellRenderer: renderServiceCountInputCell,
		},
		{
			headerName: '추가선택 가능 횟수',
			field: 'limitationCount',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			editable: true,
			cellRenderer: renderServiceCountInputCell,
		},
	],
	forms: [
		{
			key: 'productName',
			label: '상품명',
			type: FilterElementType.INPUT,
			span: 3,
		},
	],
};
