import styled from 'styled-components';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tag, List, Collapse } from 'antd';
import dayjs from 'dayjs';
import { useGetVehicleDTGInfoByDateTimeQuery } from '../../../store/apis/vehicleConditions';
import Button from '../../../components/atoms/Button';
import Icon from '../../../components/atoms/Icon';
import { arrowLeftIcon, resetIcon } from '../../../assets/icon';
import { ButtonTypeType } from '../../../styles/theme';
import { CustomErrorType } from '../../../store/apis/@types';
import { VEHICLE_DTG_BY_TRIP_PATH } from '../../../routes/constants/urls';
import { formatTimeToString } from '../../../utils/date-util';
import { DTGInfo } from '../../../interface/vehicleConditions';
import generatePathsAndPreviousSegments, {
	velocityRanges,
} from '../../../utils/paths-util';
import { TimeWrapper } from './VehicleDrivingRecordByTime';
import VehiclePolyline from '../../../components/organisms/NaverMap/VehiclePolyline';
import calculateOverallValue from '../../../utils/time-util';
import SectionLoading from '../../../components/organisms/SectionLoading';
import VelocityInfo from '../../../components/atoms/VelocityInfo';
import Typo from '../../../components/atoms/Typo';
import { vehicleStatus } from './DTGInfoPane';

const Article = styled.article`
	display: flex;
	height: 100%;
`;

const MapContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
`;

const MapOverlayContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
`;

const TimeContainer = styled.div`
	position: absolute;
	margin-left: 12px;
	top: 16px;
	left: 1px;
	bottom: 16px;
	z-index: 900;
`;

const MapWrapper = styled.div`
	flex: 1;
	position: relative;

	.view-point-button {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translate(-50%);
	}

	.content-wrapper {
		height: 100%;
	}
`;

const VehiclePathByDateTime = () => {
	const [mapKey] = useState('mapDTG');
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { vehicleNumber } = useParams();
	const [startDate] = useState<string>(searchParams.get('startDate') || '');
	const [startedAt] = useState<string>(searchParams.get('startedAt') || '');
	const [finishDate] = useState<string>(searchParams.get('finishDate') || '');
	const [finishedAt] = useState<string>(searchParams.get('finishedAt') || '');
	const [dtgInfo, setDtgInfo] = useState<DTGInfo | null>(null);

	const { isLoading, isFetching, data, isError, error, refetch } =
		useGetVehicleDTGInfoByDateTimeQuery(
			{
				vehicleNumber,
				startedAt: startDate + startedAt,
				finishedAt: finishDate + finishedAt,
			},
			{
				skip:
					!vehicleNumber ||
					!startDate ||
					!startedAt ||
					!finishDate ||
					!finishedAt,
			},
		);

	const pathList = data ? data.rows : [];
	const { pathsWithPreviousSegment } = generatePathsAndPreviousSegments(
		pathList,
		velocityRanges,
	);

	if (isError) {
		return (
			<Article>
				<p>{(error as CustomErrorType).data.translate}</p>
			</Article>
		);
	}

	const items = pathsWithPreviousSegment.map((paths, index) => ({
		key: index.toString(),
		label: `
			구간 ${index + 1},
			속도: ${paths[0].velocityRange},
			시간: ${calculateOverallValue(paths)}
		`,
		children: (
			<List
				bordered={false}
				dataSource={paths}
				renderItem={(info) => (
					<List.Item
						onClick={() => {
							setDtgInfo({ vehicleNumber, boolAnyMobility: true, ...info });
						}}
					>
						<Typo
							color={info.s === 0 ? 'gray_11' : 'danger_5_main'}
							$typoType="b6"
						>
							{formatTimeToString(info.t)}{' '}
							{info.s ? (
								<>
									<br />
									<Typo $typoType="b7">{vehicleStatus(info.s)}</Typo>
								</>
							) : (
								''
							)}
						</Typo>
					</List.Item>
				)}
			/>
		),
	}));

	return (
		<Article>
			<MapContainer>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						margin: '8px 0',
					}}
				>
					<Button
						size="x-small"
						$buttonType={ButtonTypeType.NO_LINE}
						onClick={() =>
							navigate(
								`${VEHICLE_DTG_BY_TRIP_PATH}?vehicleNumber=${vehicleNumber}&date=${dayjs(
									`20${startDate}`,
								).format('YYYY-MM-DD')}`,
							)
						}
					>
						<Icon
							src={arrowLeftIcon}
							className="reset-icon"
							width={24}
							height={24}
						/>
					</Button>
					{!startDate || !startedAt || !finishDate || !finishedAt ? (
						<Tag color="red">운행기록의 날짜가 지정되지 않았습니다</Tag>
					) : (
						<>
							<Tag color="blue">{vehicleNumber}</Tag>
							<Tag color="magenta">출발</Tag>
							<Tag>
								{dayjs(`20${startDate}T${startedAt}`).format(
									'MM월 DD일 HH시 mm분 ss초',
								)}
							</Tag>
							<Tag color="magenta">도착</Tag>
							<Tag>
								{dayjs(`20${finishDate}T${finishedAt}`).format(
									'MM월 DD일 HH시 mm분 ss초',
								)}
							</Tag>
							<Button
								size="x-small"
								$buttonType={ButtonTypeType.NO_LINE}
								onClick={refetch}
							>
								<Icon
									src={resetIcon}
									className="reset-icon"
									width={24}
									height={24}
								/>
							</Button>
						</>
					)}
				</div>
				<MapWrapper>
					<TimeContainer>
						<TimeWrapper>
							{isLoading || isFetching ? (
								<SectionLoading />
							) : (
								<Collapse accordion items={items} />
							)}
						</TimeWrapper>
					</TimeContainer>

					<MapOverlayContainer>
						<VehiclePolyline
							mapId={mapKey}
							pathList={pathList}
							dtgInfo={dtgInfo}
							vehicleNumber={vehicleNumber || ''}
							pathsWithPreviousSegment={pathsWithPreviousSegment}
						/>
						<VelocityInfo velocityRanges={velocityRanges} />
					</MapOverlayContainer>
				</MapWrapper>
			</MapContainer>
		</Article>
	);
};

export default VehiclePathByDateTime;
