import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import {
	Installment,
	MONTHLY_PRICE_OPTION,
	PAYMENT_STATUS_OPTION,
} from '../../../utils/const';
import { FilterElementType } from '../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import {
	CELL_TYPE_BADGE_TEXT,
	CELL_TYPE_DETAIL_ACTION,
	CELL_TYPE_PRICE_TEXT,
} from '../../../utils/row-data-util';

const getInstallment = (tableValue: Installment) => {
	let value;

	switch (tableValue) {
		case 'SINGLE_PAYMENT':
			value = '일시불';
			break;
		case 'TWO_MONTH_INSTALLMENT':
			value = '2개월';
			break;
		case 'THREE_MONTH_INSTALLMENT':
			value = '3개월';
			break;
		case 'FOUR_MONTH_INSTALLMENT':
			value = '4개월';
			break;
		case 'FIVE_MONTH_INSTALLMENT':
			value = '5개월';
			break;
		case 'SIX_MONTH_INSTALLMENT':
			value = '6개월';
			break;
		default:
			value = '-';
			break;
	}

	return value;
};

const userData: GridDataModel = {
	formInitialValues: {
		expectedAt: [dayjs().subtract(1, 'month'), dayjs()],
	},
	colsList: [
		{
			tableInfo: {
				field: 'expectedAt',
				headerName: '결제예정일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value && dayjs(value).format('YYYY-MM-DD(dd)');
				},
			},
		},
		{
			tableInfo: {
				field: 'confirmedAt',
				headerName: '결제일',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value && dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'subscription.subscriptionVehicle.status',
				headerName: '오픈트럭 상태',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 2,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value || '오픈스패너 구독형(오픈트럭 X)';
				},
			},
		},
		{
			tableInfo: {
				field: 'subscription.subscriptionCarCare.subscriptionProductName',
				headerName: '카케어 상품',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscription.clientType',
				headerName: '계약종류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value === 'COMPANY' ? '법인' : '개인';
				},
			},
		},
		{
			tableInfo: {
				field: 'subscription.clientName',
				headerName: '계약자명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'subscription.vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'paymentName',
				headerName: '결제항목',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 2,
			},
		},
		{
			tableInfo: {
				field: 'paymentStatus',
				headerName: '결제상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_BADGE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'paymentPriceTax',
				headerName: '총 결제금액',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: CELL_TYPE_PRICE_TEXT,
			},
		},
		{
			tableInfo: {
				field: 'paymentMethod',
				headerName: '결제수단',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'installment',
				headerName: '할부',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (value === null) {
						return '일시불';
					}
					return getInstallment(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [
		{
			key: 'expectedAt',
			label: '결제예정일',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'confirmedAt',
			label: '결제일',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'customerName',
			label: '계약자명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'paymentName',
			label: '결제항목',
			type: FilterElementType.INPUT,
		},
		{
			key: 'paymentStatus',
			label: '결제상태',
			type: FilterElementType.SELECT,
			options: PAYMENT_STATUS_OPTION,
		},
		{
			key: 'paymentPriceTax',
			label: '총 결제금액',
			type: FilterElementType.INPUT,
		},
		{
			key: 'installment',
			label: '할부',
			type: FilterElementType.SELECT,
			options: MONTHLY_PRICE_OPTION,
		},
	],
};

export default userData;
