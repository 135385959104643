import dayjs from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { LabelValue } from '../../../../utils/const';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { companyRegisterNumberFormat } from '../../../../utils/data-format';

interface DepartmentGridDataProps {
	companiesOption: LabelValue[];
}

const departmentData: ({
	companiesOption,
}: DepartmentGridDataProps) => GridDataModel = ({ companiesOption }) => {
	return {
		formInitialValues: { service: 'CUSTOMER_APP' },
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '생성일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'departmentName',
					headerName: '소속명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.departmentName ?? '-';
					},
					valueGetter: ({ data }) => {
						return data.departmentName ?? '-';
					},
				},
			},
			{
				tableInfo: {
					field: 'company.name',
					headerName: '법인명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'company.businessLicenseNumber',
					headerName: '사업자번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams) => {
						return companyRegisterNumberFormat(value ?? '');
					},
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: [
			{
				key: 'createdAt',
				label: '생성일',
				type: FilterElementType.DATE_RANGE_PICKER,
			},
			{
				key: 'companyId',
				label: '법인명',
				type: FilterElementType.SELECT,
				options: [{ label: '전체', value: '' }, ...companiesOption],
			},
			{
				key: 'departmentName',
				label: '소속명',
				type: FilterElementType.INPUT,
			},
		],
	};
};

export default departmentData;
