import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../components/atoms/Button';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useGridData from '../../../../hooks/useGridData';
import {
	CS_FAQ_CREATE_PATH,
	CS_FAQ_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import {
	createOptionsBulletTextCellRender,
	createOptionsTextCellRender,
} from '../../../../utils/row-data-util';
import { CUSTOMER_APP, MANAGER_APP } from '../FaqCreate';
import userData from './gridData';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function FaqPage() {
	const { formInitialValues, colsList } = userData;
	const navigate = useNavigate();

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
	} = useGridData({
		queryPath: 'core/admin/faqs',
		formInitialValues,
		colsList,
		detailPath: CS_FAQ_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(CS_FAQ_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="FAQ 관리"
			gridTitle="FAQ 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleCreateClick} size="small">
						FAQ 등록
					</Button>
				</ButtonContainer>
			}
			frameworkComponents={{
				...createOptionsBulletTextCellRender([
					{ label: '사용', value: true, color: 'black' },
					{ label: '미사용', value: false, color: 'gray_6' },
				]),
				...createOptionsTextCellRender([
					{ label: '소비자 앱', value: CUSTOMER_APP, color: 'black' },
					{ label: '매니저 앱', value: MANAGER_APP, color: 'black' },
				]),
			}}
		/>
	);
}

export default FaqPage;
