import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { FilterElementType } from '../../../../utils/filter-util';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'companyManagementId',
				headerName: '업체관리ID',
			},
			options: {
				pinned: 'left',
				width: ColSize.CW_MEDIUM,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionOrderCompanyName',
				headerName: '발주처명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyName',
				headerName: '업체명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyName',
				headerName: '사업자등록자명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRegistrationNumber',
				headerName: '사업자등록번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'beforeCompanyRegistrationNumber',
				headerName: '변경전사업자등록번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddress',
				headerName: '주소',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddressDetail',
				headerName: '주소상세',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddressZipCode',
				headerName: '우편번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddressSiDo',
				headerName: '시/도',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddressSiGunGu',
				headerName: '시/군/구',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyAddressEubMyeon',
				headerName: '읍/면',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyPhoneNumber',
				headerName: '전화번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyFaxNumber',
				headerName: '팩스',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyEmail',
				headerName: '이메일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyDescription',
				headerName: '업체설명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativeName1',
				headerName: '담당자1',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativePhoneNumber1',
				headerName: '담당자1 전화번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativeEmail1',
				headerName: '담당자1 이메일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativeName2',
				headerName: '담당자2',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativePhoneNumber2',
				headerName: '담당자2 전화번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyRepresentativeEmail2',
				headerName: '담당자2 이메일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementRelationType',
				headerName: '업체관리유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementContractDepartment',
				headerName: '계약담당부서',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementContractManagerName',
				headerName: '계약담당자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementMemo',
				headerName: '업체관리메모',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementContractSignedDate',
				headerName: '계약일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountName',
				headerName: '은행계좌주명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountNumber',
				headerName: '은행계좌',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountBank',
				headerName: '은행코드',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountBankName',
				headerName: '은행명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountSequence',
				headerName: '은행계좌순번',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountVerifiedDate',
				headerName: '계좌검증일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountClosedYn',
				headerName: '계좌해지여부',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountClosedDate',
				headerName: '계좌해지일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'companyManagementBankAccountMemo',
				headerName: '은행계좌메모',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
	],
	forms: [
		{
			key: 'companyManagementId',
			label: '업체관리ID',
			type: FilterElementType.INPUT,
		},
		{
			key: 'requestData',
			label: '요청일',
			type: FilterElementType.DATE_PICKER,
		},
		{
			key: 'companyName',
			label: '업체명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'proxyInspectionOrderCompanyName',
			label: '발주처',
			type: FilterElementType.INPUT,
		},
		{
			key: 'companyRegistrationNumber',
			label: '사업자번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'companyAddressSiDo',
			label: '시/도',
			type: FilterElementType.INPUT,
		},
		{
			key: 'companyAddressSiGunGu',
			label: '시/군/구',
			type: FilterElementType.INPUT,
		},
		{
			key: 'companyAddressEubMyeon',
			label: '읍/면',
			type: FilterElementType.INPUT,
		},
	],
};

export default userData;
