import OpenMilelogoImage from './logo.png';
import LoginSearchSuccessImage from './login-search_success.png';
import ProductDefaultImage from './img-product_default.png';
import UploadLengthNullImage from './img-upload-length_null.png';

import Error404Image from './img-error_404.png';
import Error500Image from './img-error_500.png';
import Error503Image from './img-error_503.png';

export const openMilelogoImage = OpenMilelogoImage;
export const loginSearchSuccessImage = LoginSearchSuccessImage;
export const uploadLengthNullImage = UploadLengthNullImage;

export const productDefaultImage = ProductDefaultImage;

// Error Image
export const error404Image = Error404Image;
export const error500Image = Error500Image;
export const error503Image = Error503Image;
