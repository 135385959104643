import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { phoneFormat } from '../../../utils/data-format';
import { ColSize, SelectionGridColumn } from '../../../utils/grid-util';
import Typo from '../../atoms/Typo';
import {
	MANAGER_STATE_NORMAL_LABEL,
	MANAGER_STATE_NORMAL_VALUE,
	MANAGER_STATE_SUSPENSION_LABEL,
	MANAGER_STATE_SUSPENSION_VALUE,
} from '../../../utils/const';

const renderManagerStatusCell = ({ value }: ICellRendererParams) => {
	switch (value) {
		case MANAGER_STATE_NORMAL_VALUE:
			return (
				<Typo $typoType="b6" color="gray_11">
					{' '}
					&#8226; {MANAGER_STATE_NORMAL_LABEL}
				</Typo>
			);
		case MANAGER_STATE_SUSPENSION_VALUE:
			return (
				<Typo $typoType="b6" color="gray_7">
					{' '}
					&#8226; {MANAGER_STATE_SUSPENSION_LABEL}
				</Typo>
			);
		default:
			return '-';
	}
};

export const assignManagerModalGridData: SelectionGridColumn = {
	colsList: [
		{
			headerName: '거리',
			field: 'dist',
			flex: 1,
			minWidth: 80,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return `${Number(value).toFixed(1)}km`;
			},
		},
		{
			headerName: '매니저 명',
			field: 'managerName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '소속',
			field: 'managerDepartment',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '휴대폰 번호',
			field: 'managerPhone',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
			cellRenderer: ({ value }: ICellRendererParams) => {
				if (!value) return '-';

				return phoneFormat(value);
			},
		},
		{
			headerName: '매니저 상태',
			field: 'managerStatus',
			flex: 1,
			minWidth: ColSize.CW_SMALL_M,
			cellRenderer: renderManagerStatusCell,
		},
		{
			headerName: '배정작업 개수',
			field: 'assignedCount',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '가입일자',
			field: 'createdAt',
			flex: 1,
			minWidth: ColSize.CW_LARGE,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
			},
		},
	],
};

export default assignManagerModalGridData;
