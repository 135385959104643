/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
	AnyMobilityDTGInfo,
	DTGInfo,
} from '../../../interface/vehicleConditions';
import { formatTimeToString } from '../../../utils/date-util';
import getPinSrc from './utils';
import { vehicleStatus } from '../../../pages/RealtimeVehicleManagement/VehicleDTG/DTGInfoPane';
import { numberWithCommas } from '../../../utils/data-format';
import { useGetCoordinateDetailRegionQuery } from '../../../store/apis/etc';
import { velocityRanges } from '../../../utils/paths-util';

interface NaverMapProps {
	mapId?: string;
	pathList: AnyMobilityDTGInfo[];
	dtgInfo: DTGInfo | null;
	vehicleNumber: string;
	date?: string; // 시간별 운행기록에서만 사용
	pathsWithPreviousSegment: AnyMobilityDTGInfo[][];
	hasZoomController?: boolean;
}

const MapWrapper = styled.div`
	flex: 1;
	position: relative;

	.view-point-button {
		position: absolute;
		bottom: 48px;
		left: 50%;
		transform: translate(-50%);
	}

	.content-wrapper {
		height: 100%;
	}
`;

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const VehiclePolyline = ({
	pathList,
	hasZoomController = true,
	mapId = 'map',
	dtgInfo,
	vehicleNumber,
	date = '',
	pathsWithPreviousSegment,
}: NaverMapProps) => {
	const theme = useTheme();
	const mapRef = useRef<naver.maps.Map | undefined>(undefined);
	const selectedMarkerRef = useRef<naver.maps.Marker | null>(null);
	const polygonRef = useRef<naver.maps.Polyline[]>([]);

	const { data: regionData } = useGetCoordinateDetailRegionQuery(
		{
			lat: dtgInfo ? String(dtgInfo.y / 1000000) : '',
			lng: dtgInfo ? String(dtgInfo.x / 1000000) : '',
		},
		{
			skip: !dtgInfo,
		},
	);

	const contentString = `
		<div style="padding: 1rem; width: 380px; border-radius: 10px; font-size: .875rem; background:white;">
			<h1 style="font-size: 18px; font-weight: 700; margin-bottom: 16px;">
			${
				vehicleNumber
					? `운행시간 ${dtgInfo && formatTimeToString(dtgInfo.t)}`
					: vehicleNumber
			}
			</h1>
			<div class='info-contents' style="display: grid; grid-template-columns: 1fr; gap: 6px">
				<div style="display:flex; justify-content: space-between;">
					<div>차량상태</div>
					<div>${dtgInfo && vehicleStatus(dtgInfo.s)}</div>
				</div>

				<div style="display:flex; justify-content: space-between;">
					<div>브레이크</div>
					<div>${dtgInfo && dtgInfo.b === 1 ? '밟음' : '밟지않음'}</div>
				</div>

				<div style="display:flex; justify-content: space-between;">
					<div>RPM</div>
					<div>${dtgInfo && numberWithCommas(dtgInfo.r)}</div>
				</div>

				<div style="display:flex; justify-content: space-between;">
					<div>속도</div>
					<div>${dtgInfo && `${numberWithCommas(dtgInfo.v)} km/h`}</div>
				</div>
			</div>

			<div style="width: 100%; margin: 10px 0; border-top: 1px solid #dcdee0"></div>

			<div style="display: grid; grid-template-columns: 1fr; gap:  6px">
				<div style="display:flex; justify-content: space-between;">
					<div>금일 운행거리</div>
					<div>${dtgInfo && `${numberWithCommas(dtgInfo.d)} km`}</div>
				</div>
				<div style="display:flex; justify-content: space-between;">
					<div>총 운행거리</div>
					<div>${dtgInfo && `${numberWithCommas(dtgInfo.m)} km`}</div>
				</div>
			</div>

			<div style="width: 100%; margin: 10px 0; border-top: 1px solid #dcdee0"></div>

			<div style="display: grid; grid-template-columns: 1fr; gap: 6px">
				<div style="display:flex; justify-content: space-between;">
					 <div>주소</div>
					<div>${regionData?.street || regionData?.zibun}</div>
				 </div>

				 <div style="display:flex; justify-content: space-between;">
					<div>위도</div>
					<div>${dtgInfo && dtgInfo.y / 1000000}</div>
				</div>

				 <div style="display:flex; justify-content: space-between;">
					<div>경도</div>
					<div>${dtgInfo && dtgInfo.x / 1000000}</div>
				 </div>
			</div>
		</div>
	`;

	const getColor = (velocityRange: string) => {
		const velocityRangeColors: { [key: string]: string } = {};

		velocityRanges.forEach(({ range, color }) => {
			velocityRangeColors[range] = color;
		});

		return velocityRangeColors[velocityRange] || velocityRanges[0].color;
	};

	// 인포윈도우 생성
	const infoWindow = useRef(
		new naver.maps.InfoWindow({
			content: '',
			borderWidth: 1,
			backgroundColor: 'white',
			borderColor: theme.common.colors.primary_6_main,
			disableAnchor: false,
		}),
	);

	// 리스크 클릭시, 해당 마커의 위치로 setCenter 화면이동
	useEffect(() => {
		if (mapRef.current && selectedMarkerRef.current) {
			// morph : 지정한 좌표와 줌 레벨을 사용하는 새로운 위치로 지도를 이동.

			mapRef.current.morph(
				selectedMarkerRef.current.getPosition(),
				mapRef.current.getZoom(),
			);
		}
	}, [contentString, selectedMarkerRef, dtgInfo]);

	// 마커 클릭시, 해당 마커의 위치로 morph() 화면이동
	const handleMarkerClick = useCallback((markerItem: naver.maps.Marker) => {
		const lat = markerItem.getPosition().y;
		const lng = markerItem.getPosition().x;

		if (mapRef.current) {
			mapRef.current.morph(new naver.maps.LatLng(lat, lng), 15);
		}
	}, []);

	useEffect(() => {
		if (mapRef.current) return;

		mapRef.current = new naver.maps.Map(mapId || 'map', {
			zoomControlOptions: {
				position: naver.maps.Position.RIGHT_TOP,
				style: naver.maps.ZoomControlStyle.SMALL,
			},
			zoomControl: hasZoomController,
		});
	}, [hasZoomController, mapId]);

	// 마커 설정
	useEffect(() => {
		// 현제 선택된 마커가 있을시, 지도에서 초기화
		if (selectedMarkerRef.current) {
			selectedMarkerRef.current.setMap(null);
		}

		if (dtgInfo) {
			const marker = new naver.maps.Marker({
				map: mapRef.current,
				position: new naver.maps.LatLng(
					dtgInfo.y / 1000000,
					dtgInfo.x / 1000000,
				),
				icon: {
					url: getPinSrc({
						status: '',
						isMarkerOn: false,
					}),
					size: new naver.maps.Size(40, 40),
				},
			});

			// 현재 선택된 마커로 설정
			selectedMarkerRef.current = marker;

			marker.addListener('click', () => {
				// 인포윈도우 열었을시, 닫아주기
				if (infoWindow.current.getMap()) {
					infoWindow.current.close();
				} else {
					// 인포윈도우 닫혓을시, 열어주기
					selectedMarkerRef.current = marker;
					infoWindow.current.setContent(contentString);
					if (mapRef.current) {
						infoWindow.current.open(mapRef.current, marker);
					}
					handleMarkerClick(marker);
				}
			});

			// 현재 마커의 위치로 인포윈도우 위치 설정
			const currentMarkerPosition = selectedMarkerRef.current.getPosition();
			infoWindow.current.setPosition(currentMarkerPosition);

			infoWindow.current.setContent(contentString);
			if (mapRef.current) {
				infoWindow.current.open(mapRef.current, selectedMarkerRef.current);
			}
		}
	}, [
		handleMarkerClick,
		contentString,
		dtgInfo,
		date,
		pathsWithPreviousSegment,
	]);

	useEffect(() => {
		// 모든 폴리라인, 인포윈도우, 마커 초기화
		if (polygonRef.current.length > 0) {
			polygonRef.current.forEach((line) => line.setMap(null));
			polygonRef.current = [];
			selectedMarkerRef.current?.setMap(null);
			infoWindow.current.close();
		}

		// 변동성 리턴값
		// TODO 모든 점에 대해서 계산하다보니 연산이 지나치게 많아질 수 있으므로 추후에 검토
		const calculateFluctuation = (segment: AnyMobilityDTGInfo[]): boolean => {
			for (let i = 1; i < segment.length; i += 1) {
				const currentPoint = segment[i];
				const previousPoint = segment[i - 1];

				// 거리 구하는법 distance = sqrt((x2 - x1)^2 + (y2 - y1)^2)
				const distance = Math.sqrt(
					(currentPoint.x - previousPoint.x) ** 2 +
						(currentPoint.y - previousPoint.y) ** 2,
				);

				const distanceThreshold = 500;

				// 거리 변화가 임계값을 초과하면 변동으로 간주
				if (distance > distanceThreshold) {
					return true;
				}
			}
			return false;
		};

		// 각 세그먼트를 폴리라인으로 그리기
		pathsWithPreviousSegment.forEach(
			(segment: AnyMobilityDTGInfo[], index: number) => {
				const pathCoordinates = segment.map((point: AnyMobilityDTGInfo) => {
					return [point.x / 1000000, point.y / 1000000] as [number, number];
				});

				const hasFluctuation = calculateFluctuation(segment);

				const polyline = new naver.maps.Polyline({
					path: pathCoordinates,
					strokeColor: getColor(segment[0].velocityRange || ''),
					strokeStyle: hasFluctuation ? 'shortdot' : 'solid',
					strokeOpacity: 1,
					strokeWeight: 5,
					strokeLineCap: 'round',
					strokeLineJoin: 'round',
					map: mapRef.current,
					startIcon: index === 0 ? 3 : 0,
					endIcon: 2,
				});

				polygonRef.current.push(polyline);
			},
		);

		const bounds = pathsWithPreviousSegment.map(
			(path: AnyMobilityDTGInfo[]) => {
				return new naver.maps.LatLng(path[0].y / 1000000, path[0].x / 1000000);
			},
		);

		// Fit the map to the bounds of the polyline
		mapRef.current?.fitBounds(bounds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathList, date]);

	return (
		<MapWrapper>
			<MapContainer id={mapId || 'map'} />;
		</MapWrapper>
	);
};

export default VehiclePolyline;
