import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import userData from './gridData';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useCheckRole from '../../../../hooks/useCheckRole';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetVehicleSubscriptionsQuotesQuery } from '../../../../store/apis/vehicleSubscriptionQuotes';
import Button from '../../../../components/atoms/Button';
import {
	VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import { createDetailAction } from '../../../../utils/row-data-util';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const VehicleSubscriptionsQuotes = () => {
	const { formInitialValues, colsList, forms } = userData();

	const navigate = useNavigate();

	const isCreateVeihcleSubscriptionQuotes = useCheckRole({
		roleType: 'isCreate',
		roleName: '신청관리',
		includeSuper: true,
	});

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetVehicleSubscriptionsQuotesQuery,
		formInitialValues,
		colsList,
		detailPath: VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH,
	});

	const handleCreateClick = () => {
		navigate(VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="차량구독 견적"
			gridTitle="차량구독 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			onRowClicked={handleDetailClick}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'subscriptionVehicleQuoteNo'),
			}}
			rightAccessory={
				isCreateVeihcleSubscriptionQuotes && (
					<ButtonContainer>
						<Button onClick={handleCreateClick} size="small">
							견적 생성
						</Button>
					</ButtonContainer>
				)
			}
		/>
	);
};

export default VehicleSubscriptionsQuotes;
