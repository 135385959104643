export const testCarList = [
	'서울90바8025',
	'서울83바6548',
	'서울90바1972',
	'서울84바2090',
	'서울83사6724',
	'서울85자5595',
	'01머9297',
	'62나2316',
	'101하6530',
	'176호2847',
	'서울80바8831',
	'서울84자2905',
	'서울87자4376',
	'서울90바1973',
	'서울90바6739',
	'03모5718',
	'12바0002',
	'323어6373',
	'339루7480',
	'65도1527',
	'서울31아3239',
];

export default testCarList;
