import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface TimerProps {
	duration?: number;
}

function useTimer({ duration = 180 }: TimerProps) {
	const [countDown, setCountDown] = useState<Dayjs | string>('03:00');
	const [isCountStart, setIsCountStart] = useState(false);
	const [isCountEnd, setIsCountEnd] = useState(false);
	const [time, setTime] = useState<number>(duration * 1000);
	const [endTime, setEndTime] = useState<number>(
		new Date().getTime() + duration * 1000,
	);

	// 카운트다운 moment 숫자화 (끝나는 시간 - 현재시간)
	const calculateTime = useCallback(() => {
		const currTime = new Date().getTime();
		return endTime - currTime;
	}, [endTime]);

	const getMomentTime = useCallback(() => {
		setTime(calculateTime());
	}, [calculateTime]);

	const getCountDownTime = useCallback(() => {
		getMomentTime();
		setCountDown(dayjs(time).format('mm:ss'));
	}, [getMomentTime, time]);

	useEffect(() => {
		getMomentTime();
	}, [getMomentTime]);

	// 카운트 다운
	useEffect(() => {
		const timer = setTimeout(() => {
			if (isCountStart) {
				getCountDownTime();
			}
		}, 1000);

		if (isCountEnd) {
			return clearTimeout(timer);
		}
		if (!isCountStart) {
			clearTimeout(timer);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [isCountStart, getCountDownTime, countDown, isCountEnd]);

	// 00 : 00 멈춤
	useEffect(() => {
		if (time <= 0) {
			setTime(0);
			setEndTime(0);
			setIsCountStart(false);

			if (countDown === '00:00') {
				setIsCountEnd(true);
			}
		}
	}, [countDown, time]);

	// 카운트 리셋
	const resetCount = useCallback(() => {
		setTime(duration);
		setIsCountEnd(false);
		setIsCountStart(true);
		setEndTime(new Date().getTime() + duration * 1000);
	}, [duration]);

	return {
		state: { countDown, isCountEnd },
		func: { resetCount, setIsCountEnd },
	};
}

export default useTimer;
