import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	DRIVER_CREATE_PATH,
	DRIVER_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { createDetailAction } from '../../../../utils/row-data-util';
import Button from '../../../../components/atoms/Button';
import useCheckRole from '../../../../hooks/useCheckRole';
import {
	useGetDriversOfCompanyQuery,
	useGetMyDriversQuery,
} from '../../../../store/apis/driver';
import useCompaniesOption from '../../../../hooks/useCompaniesOption';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function DriversPage() {
	const navigate = useNavigate();
	const companiesOption = useCompaniesOption();
	const { formInitialValues, colsList, forms } = userData({
		companiesOption,
	});

	const isSuper = useCheckRole({
		roleType: 'isRead',
		roleName: '법인관리',
		includeSuper: true,
	});
	const isCreateDriver = useCheckRole({
		roleType: 'isCreate',
		roleName: '법인관리',
	});

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: !isSuper ? useGetMyDriversQuery : useGetDriversOfCompanyQuery,
		detailPath: DRIVER_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const handleCreateClick = () => {
		navigate(DRIVER_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="기사 관리"
			gridTitle="기사 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'id'),
			}}
			rightAccessory={
				isCreateDriver && (
					<ButtonContainer>
						<Button onClick={handleCreateClick} size="small">
							기사 등록
						</Button>
					</ButtonContainer>
				)
			}
		/>
	);
}

export default DriversPage;
