import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useFormik, FormikErrors } from 'formik';
import dayjs from 'dayjs';
import Joyride, { Step } from 'react-joyride';
import { useSelector } from 'react-redux';
import GridDetailTemplate from '../../components/templates/GridDetailTemplate';
import {
	VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
} from '../../routes/constants/urls';
import GridLayout, { GridItem } from '../../components/molecules/GridLayout';
import ContentBoxWithHeader from '../../components/molecules/ContentBoxWithHeader';
import Button from '../../components/atoms/Button';
import { CompanyDto } from '../../interface/company';
import {
	companyRegisterNumberFormat,
	numberWithCommas,
	phoneFormat,
} from '../../utils/data-format';
import {
	RegisterVehicleSubscriptionContract,
	vehicleSubscriptionEndActionType,
} from '../../interface/vehicleSubscription';
import { useRegisterVSContractMutation } from '../../store/apis/vehicleSubscription';
import { CustomErrorType } from '../../store/apis/@types';
import { ToastContext } from '../../contexts/Toast';
import SelectionModal from '../../components/organisms/SelectionModal';
import {
	companyModalColumn,
	customerModalColumn,
	subscriptionProductModalColumn,
	vehicleModalColumn,
} from '../../components/templates/modalColumn';
import { useGetCompaniesQuery } from '../../store/apis/company';
import theme, { ButtonTypeType } from '../../styles/theme';
import { VehicleDetailDto } from '../../interface/vehicle';
import { useGetVehiclesQuery } from '../../store/apis/vehicle';
import {
	SubscriptionProductDto,
	// SubscriptionProductOnSale,
} from '../../interface/subscriptionProducts';
import { useGetSubscriptionProductsQuery } from '../../store/apis/subscribeProduct';
import DateTimePicker from '../../components/atoms/DateTimePicker';
import Input from '../../components/atoms/Input';
import Select from '../../components/atoms/Select';
import FileUpload from '../../components/atoms/FileUpload';
import Typo from '../../components/atoms/Typo';
import { RootState } from '../../store';
import markTutorialCompleted from '../../utils/tutorial-utils';
import useTutorialGuide from '../../hooks/useTutorialGuide';
import { useGetCustomersQuery } from '../../store/apis/customer';
import { CustomerDto } from '../../interface/customer';

const VehicleSubscriptionRegisterPage = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;
	const tutorialId = 'vehicleSubscriptionDetail';
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const location = useLocation();

	const isVehicleSubscriptionDetailPage =
		location.pathname === VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH;

	const handleTutorialCompletion = () => {
		markTutorialCompleted(userId!, tutorialId); // Pass userId and tutorialKey
	};

	const steps: Step[] = [
		{
			content: (
				<div style={{ textAlign: 'left', padding: '1rem' }}>
					<h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
						오픈트럭 계약등록 페이지입니다.
					</h3>

					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						계약된 오픈트럭 정보를 입력할 수 있습니다. 법인정보, 계약정보, 차량
						정보 및 오픈스패너 정보 등을 입력하고 계약서를 첨부합니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'center',
			target: 'body',
		},
		{
			title: '법인정보',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					오픈트럭을 계약한 법인을 선택해주세요. 등록된 법인 중에 선택할 수
					있습니다.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left',
			target: '#step-1',
		},
		{
			title: '계약정보',
			content: (
				<div style={{ textAlign: 'left' }}>
					<ul
						style={{
							fontSize: '0.9rem',
							lineHeight: '1.6',
							paddingLeft: '20px',
						}}
					>
						<li>
							<span style={{ color: 'red' }}>&bull; (필수) </span>
							<strong>월 구독료:</strong> 월 구독 비용으로 0보다 커야 합니다.
							예) 100,000원
						</li>
						<li>
							&bull; <strong>구독기간:</strong> 구독이 시작되는 날짜와 종료일
							선택시 자동 계산됩니다.
						</li>
						<li>
							<span style={{ color: 'red' }}>&bull; (필수) </span>
							<strong>구독시작일:</strong> 구독이 시작되는 날짜를 선택합니다.
							예) 2024년 7월 10일
						</li>
						<li>
							<span style={{ color: 'red' }}>&bull; (필수) </span>
							<strong>구독종료일:</strong> 구독이 종료되는 날짜를 선택합니다.
							예) 2024년 7월 10일
						</li>
						<li>
							&bull; <strong>차량가:</strong> 구독하는 차량의 가격을 입력합니다.
							예) 50,000,000원
						</li>
						<li>
							&bull; <strong>선납금:</strong> 선결제할 금액을 입력합니다. 예)
							10,000,000원
						</li>
						<li>
							&bull; <strong>인수잔가:</strong> 계약 종료 시 차량의 인수가격을
							입력합니다. 예) 20,000,000원
						</li>
						<li>
							&bull; <strong>AG 수수료:</strong> Agent 수수료를 입력합니다. 예)
							5%
						</li>
						<li>
							&bull; <strong>만기시:</strong> 계약 만기 시 처리 방법을
							선택합니다. 예) 인수 | 반납
						</li>
						<li>
							&bull; <strong>결제방법:</strong> 구독료를 결제하는 방법을
							선택합니다. 예) 현금 | 신용카드
						</li>
					</ul>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'right',
			target: '#step-2',
		},
		{
			title: '계약서',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					계약서를 첨부해주세요.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-end',
			target: '#step-3',
		},
		{
			title: '차량정보',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					차량을 선택해주세요. 이미 다른 오픈트럭 계약에 배정된 차량은 선택할 수
					없습니다.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left',
			target: '#step-4',
		},
		{
			title: '오픈스패너 정보',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					계약에 포함된 오픈스패너를 선택해주세요. 오픈스패너를 사용하지 않는
					경우에는 셀프 상품을 선택해주세요.
				</div>
			),
			locale: {
				last: '닫기',
				back: '뒤로',
			},
			placement: 'left',
			target: '#step-5',
		},
	];

	const { handleJoyrideCallback, localStorageDetail } = useTutorialGuide(
		userId!,
		tutorialId,
		steps,
	);

	const [isClientModal, setIsClientModal] = useState(false);
	const [isVehicleModal, setIsVehicleModal] = useState(false);
	const [isOpenSpannerModal, setIsOpenSpannerModal] = useState(false);
	const [selectedClientInfo, setSelectedClientInfo] = useState<
		CompanyDto | CustomerDto | undefined
	>(undefined);
	const [selectedVehicleInfo, setSelectedVehicleInfo] = useState<
		VehicleDetailDto | undefined
	>(undefined);
	const [selectedOpenSpannerInfo, setSelectedOpenSpannerInfo] = useState<
		SubscriptionProductDto | undefined
	>(undefined);

	const [registerVSContract] = useRegisterVSContractMutation();

	const initialValues: RegisterVehicleSubscriptionContract = {
		acquisitionPrice: 0,
		agCommissionPct: 0,
		eContract: '',
		endActionType: '반납',
		paymentMethod: '현금',
		prePaymentPrice: 0,
		subscriptionEndedAt: null,
		subscriptionPeriod: 0,
		subscriptionPrice: 0,
		subscriptionProductId: 0,
		subscriptionStartedAt: null,
		svInfoList: [],
		vehiclePrice: 0,
		clientType: 'COMPANY',
		clientId: 0,
		clientName: '',
	};
	const handleValidate = async (values: typeof initialValues) => {
		const errors: FormikErrors<typeof initialValues> = {};
		if (!values.clientId) {
			errors.svInfoList = '계약자를 선택해주세요.';
		}
		if (!values.subscriptionStartedAt) {
			errors.subscriptionPrice = '구독시작일을 선택해주세요.';
		}
		if (!values.subscriptionEndedAt) {
			errors.subscriptionPrice = '구독종료일을 선택해주세요.';
		}
		if (!values.subscriptionPrice) {
			errors.subscriptionPrice = '월 구독료는 0원일 수 없습니다.';
		}
		if (!values.eContract) {
			errors.eContract = '계약서를 업로드해주세요.';
		}
		if (!values.svInfoList.length) {
			errors.svInfoList = '차량을 선택해주세요.';
		}
		if (!values.subscriptionProductId) {
			errors.subscriptionProductId = '오픈스패너를 선택해주세요.';
		}
		return errors;
	};

	const handleSubmit = async (values: typeof initialValues) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { subscriptionPeriod, svInfoList, ...rest } = values;
		const res = await registerVSContract({
			subscriptionPeriod: dayjs(values.subscriptionEndedAt).diff(
				values.subscriptionStartedAt,
				'day',
			),
			svInfoList,
			...rest,
		});

		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '오픈트럭 계약이 등록되었습니다.');
			navigate(VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH);
		}
	};
	const formik = useFormik({
		initialValues,
		validate: handleValidate,
		onSubmit: handleSubmit,
	});

	return (
		<>
			<Joyride
				continuous
				callback={handleJoyrideCallback}
				run={isVehicleSubscriptionDetailPage && !localStorageDetail}
				steps={steps}
				hideCloseButton
				scrollToFirstStep
				showSkipButton
				showProgress
				disableScrollParentFix
				disableScrolling
				styles={{
					options: {
						width: 550,
						primaryColor: theme.common.colors.primary_6_main,
					},
				}}
			/>

			<GridDetailTemplate
				detailTitle="오픈트럭 계약등록"
				onBack={() => navigate(VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH)}
				rightAccessory={
					<Button size="small" onClick={() => formik.handleSubmit()}>
						계약 등록하기
					</Button>
				}
			>
				<Row gutter={[12, 12]}>
					<Col md={12}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
							<ContentBoxWithHeader
								title="계약자 정보"
								borderRadius="10px"
								id="step-1"
								essential
								tooltip='"법인 관리" 페이지에서 법인을 등록해주세요.'
								rightAccessory={
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Select
											style={{ minWidth: '120px' }}
											value={formik.values.clientType}
											onChange={(e) => {
												formik.setFieldValue('clientType', e);
												formik.setFieldValue(
													'clientId',
													initialValues.clientId,
												);
												formik.setFieldValue(
													'clientName',
													initialValues.clientName,
												);
												setSelectedClientInfo(undefined);
											}}
										>
											<Select.Option value="CUSTOMER">개인</Select.Option>
											<Select.Option value="COMPANY">법인</Select.Option>
										</Select>
										<Button
											size="small"
											$buttonType={ButtonTypeType.GHOST}
											onClick={() => setIsClientModal(true)}
										>
											{formik.values.clientType === 'COMPANY'
												? '법인 선택'
												: '개인 선택'}
										</Button>
									</div>
								}
							>
								<GridLayout $columnCount={2} $isCompact>
									{isClientModal && (
										<SelectionModal
											isVisible={isClientModal}
											title="=계약법인 선택"
											onClose={() => setIsClientModal(false)}
											onChange={(e) => {
												formik.setFieldValue('clientId', e[0].id);
												if (formik.values.clientType === 'COMPANY') {
													formik.setFieldValue('clientName', e[0].name);
												} else {
													formik.setFieldValue('clientName', e[0].customerName);
												}
												setSelectedClientInfo(e[0]);
											}}
											column={
												formik.values.clientType === 'COMPANY'
													? companyModalColumn.colsList
													: customerModalColumn.colsList
											}
											useQuery={
												formik.values.clientType === 'COMPANY'
													? useGetCompaniesQuery
													: useGetCustomersQuery
											}
											searchInput={
												formik.values.clientType === 'COMPANY'
													? companyModalColumn.forms
													: customerModalColumn.forms
											}
											defaultSelectedIdList={[formik.values.clientId]}
										/>
									)}
									{selectedClientInfo &&
										(formik.values.clientType === 'COMPANY' ? (
											<>
												<GridItem title="법인명">
													{(selectedClientInfo as CompanyDto).name}
												</GridItem>
												<GridItem title="법인등록번호">
													{
														(selectedClientInfo as CompanyDto)
															.registrationNumber
													}
												</GridItem>
												<GridItem title="사업자번호">
													{companyRegisterNumberFormat(
														(selectedClientInfo as CompanyDto)
															.businessLicenseNumber,
													)}
												</GridItem>
											</>
										) : (
											<>
												<GridItem title="고객명">
													{(selectedClientInfo as CustomerDto).customerName}
												</GridItem>
												<GridItem title="휴대폰번호">
													{phoneFormat(
														(selectedClientInfo as CustomerDto).customerPhone,
													)}
												</GridItem>
												<GridItem title="회원상태">
													{(selectedClientInfo as CustomerDto).customerStatus}
												</GridItem>
											</>
										))}
								</GridLayout>
							</ContentBoxWithHeader>
							<Typo color="danger_6">
								{formik.errors.clientId && formik.errors.clientId}
							</Typo>
							<ContentBoxWithHeader
								title="계약 정보"
								borderRadius="10px"
								id="step-2"
							>
								<GridLayout $columnCount={2}>
									<GridItem
										title="월 구독료"
										$isCompact
										essential
										span={2}
										tooltip="월 구독료는 0원보다 커야 합니다."
									>
										<Input
											name="subscriptionPrice"
											placeholder="월 구독료"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(formik.values.subscriptionPrice)}
											onChange={(e) =>
												formik.setFieldValue(
													'subscriptionPrice',
													Number(String(e.target.value).replace(/[^0-9]/g, '')),
												)
											}
											onReset={() =>
												formik.setFieldValue('subscriptionPrice', 0)
											}
											unitText="원"
											$isCompact
										/>
									</GridItem>
									<GridItem title="구독기간" $isCompact>
										<Input
											name="subscriptionPeriod"
											placeholder="구독기간"
											type="number"
											$inputSize="small"
											$inputRadius="small"
											value={dayjs(
												formik.values.subscriptionEndedAt || undefined,
											).diff(
												dayjs(formik.values.subscriptionStartedAt || undefined),
												'day',
											)}
											$isDisabled
											onChange={formik.handleChange}
											unitText="일"
											$isCompact
										/>
									</GridItem>
									<GridItem
										title="구독시작일"
										$isCompact
										essential
										tooltip="오늘 이후의 날짜를 선택해주세요."
									>
										<DateTimePicker
											name="subscriptionStartedAt"
											value={
												formik.values.subscriptionStartedAt === null
													? formik.values.subscriptionStartedAt
													: dayjs(formik.values.subscriptionStartedAt)
											}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'subscriptionStartedAt',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="차량구독 시작날짜"
											allowClear={false}
											style={{ borderRadius: '4px' }}
										/>
										{formik.errors.subscriptionStartedAt}
									</GridItem>
									<GridItem
										title="구독종료일"
										essential
										$isCompact
										tooltip="시작일 이후의 날짜를 선택해주세요."
									>
										<DateTimePicker
											name="subscriptionEndedAt"
											value={
												formik.values.subscriptionEndedAt === null
													? formik.values.subscriptionEndedAt
													: dayjs(formik.values.subscriptionEndedAt)
											}
											onChange={(date, dateString) => {
												formik.setFieldValue(
													'subscriptionEndedAt',
													dayjs(dateString).format('YYYY-MM-DD'),
												);
											}}
											placeholder="차량구독 종료날짜"
											allowClear={false}
											style={{ borderRadius: '4px' }}
										/>
									</GridItem>
									<GridItem title="차량가" $isCompact>
										<Input
											name="vehiclePrice"
											placeholder="차량가"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(formik.values.vehiclePrice)}
											onChange={(e) =>
												formik.setFieldValue(
													'vehiclePrice',
													Number(String(e.target.value).replace(/[^0-9]/g, '')),
												)
											}
											onReset={() => formik.setFieldValue('vehiclePrice', 0)}
											unitText="원"
											$isCompact
										/>
									</GridItem>
									<GridItem title="선납금" $isCompact>
										<Input
											name="prePaymentPrice"
											placeholder="선납금"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(formik.values.prePaymentPrice)}
											onChange={(e) =>
												formik.setFieldValue(
													'prePaymentPrice',
													Number(String(e.target.value).replace(/[^0-9]/g, '')),
												)
											}
											onReset={() => formik.setFieldValue('prePaymentPrice', 0)}
											unitText="원"
											$isCompact
										/>
									</GridItem>
									<GridItem title="인수잔가" $isCompact>
										<Input
											name="acquisitionPrice"
											placeholder="인수잔가"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(formik.values.acquisitionPrice)}
											onChange={(e) =>
												formik.setFieldValue(
													'acquisitionPrice',
													Number(String(e.target.value).replace(/[^0-9]/g, '')),
												)
											}
											onReset={() =>
												formik.setFieldValue('acquisitionPrice', 0)
											}
											unitText="원"
											$isCompact
										/>
									</GridItem>
									<GridItem title="AG수수료" $isCompact>
										<Input
											name="agCommissionPct"
											placeholder="AG수수료"
											type="number"
											$inputSize="small"
											$inputRadius="small"
											value={formik.values.agCommissionPct}
											onChange={formik.handleChange}
											onReset={() => formik.setFieldValue('agCommissionPct', 0)}
											unitText="%"
											$isCompact
										/>
									</GridItem>
									<GridItem title="만기시" $isCompact>
										<Select
											style={{ minWidth: '120px' }}
											value={formik.values.endActionType}
											onChange={(e) => formik.setFieldValue('endActionType', e)}
										>
											{vehicleSubscriptionEndActionType.map((item) => (
												<Select.Option key={item} value={item}>
													{item}
												</Select.Option>
											))}
										</Select>
									</GridItem>
									<GridItem title="결제방법" $isCompact>
										<Select
											style={{ minWidth: '120px' }}
											value={formik.values.paymentMethod}
											onChange={(e) => formik.setFieldValue('paymentMethod', e)}
										>
											<Select.Option value="신용카드">신용카드</Select.Option>
											<Select.Option value="현금">현금</Select.Option>
										</Select>
									</GridItem>
								</GridLayout>
							</ContentBoxWithHeader>
							<Typo color="danger_6">
								{formik.errors.subscriptionStartedAt &&
									formik.errors.subscriptionStartedAt}
								{formik.errors.subscriptionEndedAt &&
									formik.errors.subscriptionEndedAt}
								{formik.errors.subscriptionPrice &&
									formik.errors.subscriptionPrice}
							</Typo>
							<div id="step-3">
								<FileUpload
									setFieldValue={formik.setFieldValue}
									field="eContract"
									targetS3Key="vehicleSubscription/general/contracts"
									fileType="계약서"
								/>
							</div>

							<Typo color="danger_6">
								{formik.errors.eContract && formik.errors.eContract}
							</Typo>
						</div>
					</Col>
					<Col md={12}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
							<ContentBoxWithHeader
								title="차량 정보"
								borderRadius="10px"
								id="step-4"
								essential
								tooltip="해당 차량은 법인과 같은 소속이어야 합니다."
								rightAccessory={
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST}
										onClick={() => setIsVehicleModal(true)}
									>
										차량 선택
									</Button>
								}
							>
								<GridLayout $columnCount={3} $isCompact>
									{isVehicleModal && (
										<SelectionModal
											isVisible={isVehicleModal}
											title="계약차량 선택"
											onClose={() => setIsVehicleModal(false)}
											onChange={(e) => {
												formik.setFieldValue('svInfoList', [
													{ vehicleId: e[0].id },
												]);
												setSelectedVehicleInfo(e[0]);
											}}
											column={vehicleModalColumn.colsList}
											useQuery={useGetVehiclesQuery}
											searchInput={vehicleModalColumn.forms}
											defaultSelectedIdList={[formik.values.svInfoList[0]]}
										/>
									)}
									{selectedVehicleInfo && (
										<>
											<GridItem title="차량번호">
												{selectedVehicleInfo?.vehicleNumber}
											</GridItem>
											<GridItem title="차대번호">
												{selectedVehicleInfo?.vin}
											</GridItem>
											<GridItem title="차종">
												{selectedVehicleInfo?.vehicleCategory}
											</GridItem>
											<GridItem title="적재함종류">
												{selectedVehicleInfo?.carLoadingBoxType}
											</GridItem>
											<GridItem title="차명">
												{selectedVehicleInfo?.vehicleName}
											</GridItem>
											<GridItem title="적재함종류">
												{selectedVehicleInfo?.loadingCapacity}
											</GridItem>
										</>
									)}
								</GridLayout>
							</ContentBoxWithHeader>
							<Typo color="danger_6">
								{formik.errors.svInfoList &&
									(formik.errors.svInfoList as string)}
							</Typo>
							<ContentBoxWithHeader
								title="오픈스패너 정보"
								borderRadius="10px"
								id="step-5"
								essential
								tooltip='추가 상품을 선택하기 위해서는 "오픈스패너 선택" 버튼을 클릭해주세요.'
								rightAccessory={
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST}
										onClick={() => setIsOpenSpannerModal(true)}
									>
										오픈스패너 선택
									</Button>
								}
							>
								<GridLayout $columnCount={3} $isCompact>
									{isOpenSpannerModal && (
										<SelectionModal
											isVisible={isOpenSpannerModal}
											title="계약차량 선택"
											onClose={() => setIsOpenSpannerModal(false)}
											onChange={(e) => {
												formik.setFieldValue('subscriptionProductId', e[0].id);
												setSelectedOpenSpannerInfo(e[0]);
											}}
											column={subscriptionProductModalColumn.colsList}
											useQuery={useGetSubscriptionProductsQuery}
											searchInput={subscriptionProductModalColumn.forms}
											defaultSelectedIdList={[
												formik.values.subscriptionProductId,
											]}
											width={500}
										/>
									)}
									{selectedOpenSpannerInfo && (
										<>
											<GridItem title="상품명">
												{selectedOpenSpannerInfo?.subscriptionProductName}
											</GridItem>
											<GridItem title="설명">
												{
													selectedOpenSpannerInfo?.subscriptionProductIntroduction
												}
											</GridItem>
											<GridItem title="가격">
												{numberWithCommas(
													selectedOpenSpannerInfo.monthlyPrice +
														selectedOpenSpannerInfo.monthlyTax,
												)}
												원
											</GridItem>
											<GridItem title="상세설명" span={3}>
												{
													selectedOpenSpannerInfo?.subscriptionProductDescription
												}
											</GridItem>
										</>
									)}
								</GridLayout>
							</ContentBoxWithHeader>
							<Typo color="danger_6">
								{formik.errors.subscriptionProductId &&
									formik.errors.subscriptionProductId}
							</Typo>
						</div>
					</Col>
				</Row>
			</GridDetailTemplate>
		</>
	);
};

export default VehicleSubscriptionRegisterPage;
