import React, { useContext, useRef, useState } from 'react';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { ToastContext } from '../../../contexts/Toast';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../../components/atoms/Button';
import { CustomErrorType } from '../../../store/apis/@types';
import GridLayout, { GridItem } from '../../../components/molecules/GridLayout';

interface UploadExcelProps {
	uploadExcel: MutationTrigger<
		MutationDefinition<{ body: FormData }, any, any, any>
	>;
	isUpdating: boolean;
}

const UploadCarServiceExcel = ({
	uploadExcel,
	isUpdating,
}: UploadExcelProps) => {
	const setToast = useContext(ToastContext);
	const fileRef = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState('');

	const handleFocus = () => {
		fileRef?.current?.click();
	};

	const uploadFile = async () => {
		if (!fileRef) {
			return;
		}
		if (!fileRef.current?.files) {
			return;
		}
		const targetFile = fileRef.current.files[0];
		const formData = new FormData();
		formData.append('file', targetFile);

		const res = await uploadExcel({ body: formData });

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '업로드가 완료되었습니다.');
		}
		setFileName('');
	};

	return (
		<>
			<GridLayout $columnCount={3}>
				<GridItem title="선택된 파일">
					{(fileRef.current?.files?.length && fileName) || '선택된 파일없음'}
				</GridItem>
				<GridItem title="파일 선택하기">
					<Button
						size="small"
						$buttonType={ButtonTypeType.PRIMARY_LIGHT}
						onClick={() => handleFocus()}
					>
						파일 선택하기
					</Button>
				</GridItem>
			</GridLayout>
			<input
				type="file"
				ref={fileRef}
				onChange={(e) => {
					if (e.target.files?.length) {
						setFileName(e.target.files[0].name);
					}
				}}
				hidden
			/>
			<Button
				size="large"
				$buttonType={ButtonTypeType.GREEN}
				onClick={uploadFile}
				style={{ marginTop: '10px' }}
				disabled={!fileRef.current?.files?.length || !fileName}
			>
				{isUpdating ? '업로드중...' : '업로드하기'}
			</Button>
		</>
	);
};

export default UploadCarServiceExcel;
