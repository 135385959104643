import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import userData from './gridData';
import {
	CAR_SERVICE_OUTSOURCING_DETAIL_PATH,
	CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH,
} from '../../../routes/constants/urls';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import { createDetailAction } from '../../../utils/row-data-util';
import { useGetOutsourcingCarServicesQuery } from '../../../store/apis/carService';
import { RootState } from '../../../store';
import Input from '../../../components/atoms/Input';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';

const CarServiceOutsourcingManagement = () => {
	const { user } = useSelector((state: RootState) => state.auth);

	const { formInitialValues, colsList, forms, selectForms } = userData(
		user?.administratorName ?? '',
	);
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		handleQuickSearchText,
		setQuickSearchText,
		quickSearchText,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetOutsourcingCarServicesQuery,
		detailPath: CAR_SERVICE_OUTSOURCING_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const resetQuickFilterText = useCallback(() => {
		handleQuickSearchText();
	}, [handleQuickSearchText]);

	useEffect(() => {
		if (quickSearchText === '' || !quickSearchText) {
			resetQuickFilterText();
		}
	}, [quickSearchText, resetQuickFilterText]);

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="통화 관리(외주)"
			gridTitle="통화 관리(외주)"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			selectForms={selectForms}
			onSearchSubmit={handleSearchSubmit}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'proxyInspectionReceptionId'),
			}}
			isFetching={isFetching}
			$isCompact
			columnName={CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH}
			rightAccessory={
				<div>
					<Input
						onChange={(e) => {
							setQuickSearchText(e.target.value);
						}}
						name="quickSearch"
						type="text"
						placeholder="페이지내 검색"
						value={quickSearchText}
						onReset={() => setQuickSearchText('')}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleQuickSearchText();
							}
						}}
					/>
				</div>
			}
		/>
	);
};

export default CarServiceOutsourcingManagement;
