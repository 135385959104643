import {
	BaseQueryFn,
	createApi,
	FetchArgs,
} from '@reduxjs/toolkit/query/react';

import {
	ModifyPasswordAdministratorDto,
	ModifyPasswordAdministratorErrorDto,
	ModifyPasswordAdministratorResponseDto,
} from '../../../interface/password';
import { ObjectResponse } from '../@types';
import { publicBaseQuery } from '../query';

const authPasswordApi = createApi({
	reducerPath: 'authPasswordApi',
	baseQuery: publicBaseQuery as unknown as BaseQueryFn<
		string | FetchArgs,
		unknown,
		ModifyPasswordAdministratorErrorDto
	>,
	endpoints: (builder) => ({
		password: builder.mutation<
			ObjectResponse<ModifyPasswordAdministratorResponseDto>,
			ModifyPasswordAdministratorDto
		>({
			query: ({ administratorApplicationId, newPassword }) => ({
				method: 'PUT',
				url: `/members/administrators/password`,
				body: {
					administratorApplicationId,
					newPassword,
				},
			}),
		}),
	}),
});

export const { usePasswordMutation } = authPasswordApi;
export default authPasswordApi;
