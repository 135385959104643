import React, { useContext, useState } from 'react';
import { DatePicker, Form } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Typo from '../../atoms/Typo';
import { ToastContext } from '../../../contexts/Toast';
import Alert from '../../atoms/Alert';
import { useSubscriptionItemExpectationVisitDateUpdateMutation } from '../../../store/apis/subscriptionItem';
import { CustomErrorType } from '../../../store/apis/@types';

interface ServiceDateModifyModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: string;
	selectedId: string;
}

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

const FormItem = styled(Form.Item)`
	.ant-form-item-label {
		min-width: 140px;
		text-align: unset;
	}
`;

const StyledDatePicker = styled(DatePicker)`
	width: 100%;
	padding: 5px 10px;
	border-radius: 8px;

	div.ant-picker-input {
		> input {
			${({ theme }) => theme.typo.input2};
		}
	}
`;

function ServiceDateModifyModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	selectedId,
}: ServiceDateModifyModalProps) {
	const setToast = useContext(ToastContext);

	const [serviceDate, setServiceDate] = useState<string>(defaultValue);
	const [alertVisible, setAlertVisible] = useState(false);

	const [updateVisitDate] =
		useSubscriptionItemExpectationVisitDateUpdateMutation();

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};
	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleConfirm = async () => {
		handleAlertCloseClick();

		const res = await updateVisitDate({
			id: selectedId,
			body: { reservationVisitedAt: new Date(serviceDate).toISOString() },
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			onRefetch();
			onClose();
			setToast('info', '서비스 확정 일시 변경이 완료되었습니다.');
		}
	};

	const handleConfirmClick = () => {
		if (!serviceDate) {
			setToast('error', '서비스 확정 일시를 선택해주세요.');
			return;
		}

		handleAlertOpenClick();
	};

	return (
		<>
			{' '}
			<Modal
				title={
					<Typo $typoType="h2" color="gray_11">
						서비스 확정일시 변경
					</Typo>
				}
				open={isVisible}
				onCancel={onClose}
				width={640}
				destroyOnClose
				footer={
					<ModalFooterWrapper>
						<Button $buttonType={ButtonTypeType.OPAQUE} onClick={onClose}>
							닫기
						</Button>
						<Button onClick={handleConfirmClick}>완료</Button>
					</ModalFooterWrapper>
				}
			>
				<Form>
					<FormItem
						name="serviceDate"
						label={
							<Typo $typoType="label1" color="gray_11">
								서비스 확정 일시
							</Typo>
						}
						rules={[{ required: true }]}
						colon={false}
					>
						<StyledDatePicker
							allowClear={false}
							style={{ width: '100%' }}
							defaultValue={serviceDate ? dayjs(serviceDate) : undefined}
							showTime
							format="YYYY-MM-DD HH:mm"
							placeholder="서비스 확정 일시를 선택해주세요."
							onOk={(value) =>
								setServiceDate(value.format('YYYY-MM-DD HH:mm:ss'))
							}
						/>
					</FormItem>
				</Form>
			</Modal>
			{alertVisible && (
				<Alert
					title="서비스 확정일시 변경"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="변경하기"
					onConfirmButtonClick={handleConfirm}
					isVisible={alertVisible}
				>
					서비스 확정일시를 변경하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default ServiceDateModifyModal;
