import React from 'react';
import styled from 'styled-components';
import { VelocityRange } from '../../../interface/vehicleConditions';
import Divider from '../../../styles/divider';

const VelocityInfoContainer = styled.div`
	position: absolute;
	height: 250px;
	margin-right: 5rem;
	top: 16px;
	right: 1px;
	bottom: 16px;
	z-index: 800;
	background: #fff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 5px 0 15px 0 rgba(0, 0, 0, 0.1);
	border-radius: 10px;
`;

const VelocityWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 6px;
	overflow-y: auto;
	font-size: 0.9rem;
	font-feature-settings: 'tnum';
	font-variant-numeric: tabular-nums;
`;

const VelocityRangeWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const VelocityCircle = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid black;
	background: ${(props) => props.color};
`;

const VelocityText = styled.span`
	margin-left: 1rem;
`;

const DottedLine = styled.span`
	display: inline-block;
	border-bottom: 5px dotted #000;
	width: 30px;
	vertical-align: middle;
	margin-right: 10px;
`;

const SolidLine = styled.span`
	display: inline-block;
	border-bottom: 5px solid #000;
	width: 30px;
	vertical-align: middle;
	margin-right: 10px;
`;

const VelocityInfo = ({
	velocityRanges,
}: {
	velocityRanges: VelocityRange[];
}) => {
	return (
		<VelocityInfoContainer>
			<VelocityWrapper>
				{velocityRanges.map((velocityRange: VelocityRange) => {
					return (
						<VelocityRangeWrapper key={velocityRange.color}>
							<VelocityCircle color={velocityRange.color} />
							<VelocityText>{velocityRange.text}</VelocityText>
						</VelocityRangeWrapper>
					);
				})}
			</VelocityWrapper>
			<Divider />
			<VelocityWrapper>
				<p>
					<DottedLine />
					변동성이 있는 경로
				</p>
				<p>
					<SolidLine />
					일정한 경로
				</p>
			</VelocityWrapper>
		</VelocityInfoContainer>
	);
};

export default VelocityInfo;
