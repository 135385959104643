import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BarChartMonthRedItem } from '../../../../interface/carService';

interface BarMonthRedDataProps {
	barMonthRedData: BarChartMonthRedItem[];
}

const BarChartMonthWarning = ({ barMonthRedData }: BarMonthRedDataProps) => {
	// console.log(barMonthRedData);
	const styles: Record<string, React.CSSProperties> = {
		root: {
			position: 'relative',
			width: '100%',
			height: `${barMonthRedData.length * 30}px`,
		},
		overlay: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, 0%)',
			fontSize: 36,
			color: 'black',
			textAlign: 'center',
			pointerEvents: 'none',
		},
		totalLabel: {
			fontSize: 24,
		},
	};
	return (
		<div style={styles.root}>
			<ResponsiveBar
				data={barMonthRedData}
				keys={['일정임박', '일정경과']}
				indexBy="접수년월"
				layout="horizontal"
				// groupMode="grouped"
				groupMode="stacked"
				margin={{ top: 30, right: 100, bottom: 10, left: 80 }}
				padding={0.4}
				innerPadding={1}
				valueScale={{ type: 'linear' }}
				valueFormat=" >-,~g"
				indexScale={{ type: 'band', round: true }}
				// colors={{ scheme: 'set2' }}
				borderRadius={10}
				borderColor={{
					from: 'color',
					modifiers: [
						['darker', 1.6],
						['opacity', 0.85],
					],
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={null}
				axisLeft={{
					tickSize: 5,
					tickPadding: 7,
					tickRotation: 0,
					legend: '',
					legendOffset: 0,
					legendPosition: 'end',
					truncateTickAt: 0,
				}}
				enableTotals={false}
				// enableTotals={true}
				// totalsOffset={1}
				labelSkipWidth={1}
				labelSkipHeight={0}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 3.0]],
				}}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 12,
						effects: [
							{
								on: 'hover',
								style: { itemOpacity: 1 },
							},
						],
					},
				]}
				theme={{
					text: {
						fontFamily:
							'Pretendard, "Noto Sans KR", "Malgun Gothic", sans-serif',
						fontSize: '14',
					},
					axis: { ticks: { text: { fontSize: '12' } } },
					legends: { text: { fontSize: '11' } },
					labels: { text: { fontSize: '11' } },
				}}
				role="application"
				ariaLabel="접수월별 Risk 일정 관리"
				// barAriaLabel={(e) =>
				// 	e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
				// }
			/>
		</div>
	);
};

export default BarChartMonthWarning;
