import { DepartmentDto } from './department';
import { VehiclePlateDto } from './vehiclePlate';
import { CompanyDto } from './company';
import { DriverDto } from './driver';

export const carLoadingBox = [
	'하이탑',
	'저상',
	'정탑',
	'로우탑',
	'윙바디',
	'트레일러',
	'캐리어',
	'슈퍼캡 내장탑',
	'슈퍼캡 냉동탑',
	'슈퍼캡 윙바디',
	'슈퍼캡 카고',
	'일반캡 냉동탑',
	'일반캡 카고',
	'킹캡 내장탑',
	'킹캡 냉동탑',
	'킹캡 냉동 저상',
	'킹캡 카고',
] as const;

export type CarLoadingBoxType = (typeof carLoadingBox)[number];

export interface MakeVehicleDto {
	vehicleNumber?: string | null;
	vin?: string;
	isObdInstalled?: boolean;
	isDtgInstalled?: boolean;
	lastInspectionDate?: string;
	manufacturingCompany?: '현대' | '기아' | '기타';
	vehicleCategory?: string | null;
	vehicleName?: string | null;
	carLoadingBoxType?: CarLoadingBoxType;
	loadingCapacity?: number;
	vehicleYear?: number | null;
	factoryPrice?: number;
	purchasePrice?: number;
	purchaseDate?: string | null;
	salePrice?: number;
	saleDate?: string | null;
	importDomestic?: '국산' | '수입';
	fuelType?: string | null;
	transmission?: '오토' | '수동' | null;
	color?: string | null;
	registrationDate?: string | null;
	legalInspectionDate?: string | null;
	legalInspectionEndDate?: string | null;
	vehicleRegistration?: string | null;
	insurer?: string;
	insuranceAge?: '만 21세 이상' | '만 26세 이상';
	insuranceInPersonam?: string;
	insuranceInRem?: string;
	insuranceSelf?: string;
	insuranceSelfVehicle?: string;
	insuranceDriverType?: '개인' | '법인';
	insuranceIndemnification?: string;
	insurancePremiums?: number | null;
	insuranceLossPct?: number | null;
	insuranceStartDate?: string | null;
	insuranceEndDate?: string | null;
	departmentId?: number;
	ownerCompanyId?: number;
	driverId?: number;
	status?: 'ACTIVE' | 'INACTIVE';
	memo?: string;
	administratorName?: string;
}

export interface VehicleDetailDto extends MakeVehicleDto {
	id: number;
	createdAt: string;
	deletedAt: string;
	department: DepartmentDto | null;
	ownerCompany: CompanyDto | null;
	vehiclePlate: VehiclePlateDto | null;
	driver: DriverDto | null;
}

export interface VehicleHistoryChange {
	createdAt: string;
	id: number;
	vehicle: MakeVehicleDto & {
		id: number;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	};
	admin: {
		accountId: number;
		administratorName: string;
	};
	historyActionType: string;
	memo: string;
	payload: Record<any, any>;
}
