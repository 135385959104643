import { Radio, RadioChangeEvent } from 'antd';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import ContentBoxItem from '../../../../components/atoms/ContentBoxItem';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import TextArea from '../../../../components/atoms/TextArea';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { OsType, Service } from '../../../../interface/appVersion';
import { CS_APP_VERSION_MANAGEMENT } from '../../../../routes/constants/urls';
import { RootState } from '../../../../store';
import { CustomErrorType } from '../../../../store/apis/@types';
import { useAppVersionCreateMutation } from '../../../../store/apis/appVersion';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { ButtonTypeType } from '../../../../styles/theme';
import { AOS, IOS } from '../../../../utils/const';

const initialValues = {
	service: 'CUSTOMER_APP',
	type: 'ios',
	version: '',
	description: '',
};

export const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBox = styled.div`
	max-width: 830px;
	margin: 0 auto;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

function AppVersionCreatePage() {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();
	const userName = useSelector(
		(state: RootState) => state.auth.user?.administratorName,
	);

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isAlertVisible, setAlertVisible] = useState(false);
	const [appVersionCreate] = useAppVersionCreateMutation();
	const versionReg = /^[0-9]+\.[0-9]+\.[0-9]+$/;

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};
	const goToBackPage = () => {
		navigate(-1);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};
		if (values.service === '') {
			errors.service = '서비스를 선택해주세요.';
		}
		if (values.type === '') {
			errors.type = 'OS를 선택해주세요.';
		}

		if (values.version === '') {
			errors.version = '버전을 입력해주세요.';
		}
		if (!versionReg.test(values.version)) {
			errors.version = '버전형식을 확인해주세요 ex) xx.xx.xx';
		}
		if (values.description === '') {
			errors.description = '내용을 입력해주세요.';
		}

		return errors;
	};

	const {
		handleSubmit,
		setFieldValue,
		values,
		errors,
		handleChange,
		validateForm,
	} = useFormik({
		initialValues: { ...initialValues },
		onSubmit: async (value) => {
			dispatch(fullLoadingOn());
			const res = await appVersionCreate({
				service: value.service as Service,
				type: value.type as OsType,
				version: value.version,
				description: value.description,
				managerName: userName as string,
			});

			if ('error' in res) {
				toast(
					'error',
					(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message,
				);
			} else {
				navigate(CS_APP_VERSION_MANAGEMENT);
				toast('info', '앱버전 등록이 완료되었습니다.');
			}
			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleSubmitAlertClick = async () => {
		const validateValue = await validateForm();

		if (Object.keys(validateValue).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleSubmitClick = () => {
		handleSubmit();
		handleAlertCloseClick();
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="앱 버전 등록"
				onBack={goToBackPage}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goToBackPage}
							size="small"
						>
							취소
						</Button>
						<Button onClick={handleSubmitAlertClick} size="small">
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="버전 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBox>
						<ContentBoxItem>
							<Label name="service" essential>
								<span>
									<Typo $typoType="label2">플랫폼</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setFieldValue('service', e.target.value);
								}}
								value={values.service}
							>
								<Radio value="CUSTOMER_APP">
									<Typo $typoType="label1">소비자 앱</Typo>
								</Radio>
								<Radio value="MANAGER_APP">
									<Typo $typoType="label1">매니저 앱</Typo>
								</Radio>
							</Radio.Group>
							{errors.service && (
								<InputAlert $inputStatus="error">{errors.service}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="type" essential>
								<span>
									<Typo $typoType="label2">OS</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									setFieldValue('type', e.target.value);
								}}
								value={values.type}
							>
								<Radio value={IOS}>
									<Typo $typoType="label1">iOS</Typo>
								</Radio>
								<Radio value={AOS}>
									<Typo $typoType="label1">AOS</Typo>
								</Radio>
							</Radio.Group>
							{errors.type && (
								<InputAlert $inputStatus="error">{errors.type}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="version" essential>
								<span>
									<Typo $typoType="label2">버전</Typo>
								</span>
							</Label>
							<Input
								name="version"
								placeholder="버전을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={values.version}
								onChange={handleChange}
								onReset={() => setFieldValue('version', '')}
							/>
							{errors.version && (
								<InputAlert $inputStatus="error">{errors.version}</InputAlert>
							)}
						</ContentBoxItem>
						<ContentBoxItem>
							<Label name="description" essential>
								<span>
									<Typo $typoType="label2">릴리즈 노트</Typo>
								</span>
							</Label>
							<TextArea
								placeholder="릴리즈 노트를 입력해 주세요"
								value={values.description}
								defaultValue={values.description}
								onChange={(e) => setFieldValue('description', e.target.value)}
							/>
							{errors.description && (
								<InputAlert $inputStatus="error">
									{errors.description}
								</InputAlert>
							)}
						</ContentBoxItem>
					</ContentBox>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible ? (
				<Alert
					title="앱 버전 등록"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleSubmitClick}
					isVisible={isAlertVisible}
				>
					<p>
						<Typo $typoType="b5" color="gray_8">
							앱 버전을 등록하시겠습니까?
						</Typo>
					</p>
				</Alert>
			) : null}
		</>
	);
}

export default AppVersionCreatePage;
