import React from 'react';
import styled from 'styled-components';
import Typo from '../../atoms/Typo';

interface MainHeaderProps {
	mainTitle: string;
}

const MainHeaderLayout = styled.header`
	background: #fff;
	padding: 16px 24px;
	border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_3};
`;

function MainHeader({ mainTitle }: MainHeaderProps) {
	return (
		<MainHeaderLayout>
			<h1>
				<Typo $typoType="h4">{mainTitle}</Typo>
			</h1>
		</MainHeaderLayout>
	);
}

export default MainHeader;
