/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	createContext,
	useCallback,
	useState,
	PropsWithChildren,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../../components/atoms/Toast';

export type ToastStatusType = 'info' | 'error';
export interface ToastItemType {
	id: string;
	description: string;
	status: ToastStatusType;
	toastlist?: ToastItemType[];
	setList?: React.Dispatch<React.SetStateAction<ToastItemType[]>>;
}

const ToastContext = createContext(
	(status: ToastStatusType, description: string) => {},
);

function ToastProvider({ children }: PropsWithChildren<any>) {
	const [toastList, setToastList] = useState<ToastItemType[]>([]);

	const handleToastVisibleClick = useCallback(
		(status: ToastStatusType, description: string) => {
			let toastProperties: ToastItemType;
			switch (status) {
				case 'info':
					toastProperties = {
						id: uuidv4(),
						description,
						status,
					};
					break;
				case 'error':
					toastProperties = {
						id: uuidv4(),
						description,
						status,
					};
					break;
				default:
					return 'info';
			}
			return setToastList((prev) => [...prev, toastProperties]);
		},
		[],
	);

	return (
		<ToastContext.Provider value={handleToastVisibleClick}>
			{toastList && <Toast toastList={toastList} setList={setToastList} />}
			{children}
		</ToastContext.Provider>
	);
}

export { ToastContext, ToastProvider };
