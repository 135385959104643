import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Typo from '../../atoms/Typo';
import { ThemeType } from '../../../styles/theme';
import ToolTip from '../../atoms/Tooltip';

export enum GridTitleSize {
	GT_X_SMALL = 88,
	GT_SMALL = 116,
	GT_MEDIUM = 140,
	GT_LARGE = 161,
}
interface GridItemProps {
	title: string;
	labelName?: string;
	span?: number;
	size?: GridTitleSize | number;
	className?: string;
	$isAutoHeight?: boolean;
	$isCompact?: boolean;
	$padding?: React.CSSProperties['padding'];
	essential?: boolean;
	tooltip?: string;
}

interface GridItemContainerStyleProps {
	span?: number;
	size: GridTitleSize;
	$isAutoHeight?: boolean;
	$isCompact?: boolean;
	$padding?: React.CSSProperties['padding'];
}

const GridItemContainer = styled.div<GridItemContainerStyleProps>`
	display: flex;
	align-items: center;
	max-height: ${({ $isAutoHeight }) => ($isAutoHeight ? 'auto' : '36px')};
	border-bottom: 1px solid ${({ theme }) => theme.common.colors.gray_2};
	border-bottom-width: ${({ $isCompact }) => $isCompact && '0'};
	grid-column: auto / span ${({ span }) => span || 1};
	.grid-title {
		display: flex;
		align-items: center;
		background: ${({ theme }) => theme.common.colors.gray_1};
		width: ${({ size, $isCompact }) => ($isCompact ? size - 4 : size)}px;
		padding: ${({ $isCompact }) => (!$isCompact ? '12px 10px' : '4px 6px')};
		height: 100%;
	}
	.grid-children {
		display: flex;
		align-items: center;
		flex: 1;
		padding: ${({ $isCompact, $padding }) =>
			!$isCompact ? $padding ?? '12px 10px' : '4px'};
		height: 100%;
		font-size: ${({ $isCompact }) => (!$isCompact ? '13px' : '12px')};
	}
	padding: ${({ $isCompact }) => (!$isCompact ? 0 : '')};
`;

const FilterGridItemContainer = styled(GridItemContainer)`
	max-height: ${({ $isAutoHeight }) => ($isAutoHeight ? 'auto' : '36px')};
	padding: ${({ $isCompact, $padding }) =>
		!$isCompact ? $padding ?? '' : '4px 6px'};

	.grid-title {
		padding: 6px 10px;
	}
	.grid-children {
		padding: 6px 10px;
		font-size: ${({ $isCompact }) => (!$isCompact ? '13px' : '12px')};
	}
`;

const Essential = styled.span<{ theme: ThemeType }>`
	color: ${({ theme }) => theme.common.colors.danger_5_main};
	margin-left: 2px;
`;

export function BlankGridItem() {
	return <GridItemContainer size={GridTitleSize.GT_SMALL} />;
}

export function GridItem({
	title,
	span,
	labelName,
	size = GridTitleSize.GT_SMALL,
	children,
	className,
	$isAutoHeight = false,
	$isCompact = false,
	$padding,
	essential,
	tooltip,
}: PropsWithChildren<GridItemProps>) {
	return (
		<GridItemContainer
			className={className}
			span={span}
			size={size}
			$isAutoHeight={$isAutoHeight}
			$isCompact={$isCompact}
			$padding={$padding}
		>
			<label htmlFor={labelName} className="grid-title">
				<Typo $typoType={!$isCompact ? 'hm8' : 'hm9'}>
					{title}
					{essential && <Essential>*</Essential>}
					{tooltip && <ToolTip title={tooltip || ''} />}
				</Typo>
			</label>
			<div className="grid-children">{children}</div>
		</GridItemContainer>
	);
}

export function FilterGridItem({
	title,
	span,
	labelName,
	size = GridTitleSize.GT_SMALL,
	children,
	className,
	$isAutoHeight = false,
	$isCompact = false,
	$padding,
	essential,
	tooltip,
}: PropsWithChildren<GridItemProps>) {
	return (
		<FilterGridItemContainer
			className={className}
			span={span}
			size={size}
			$isAutoHeight={$isAutoHeight}
			$isCompact={$isCompact}
			$padding={$padding}
		>
			<label htmlFor={labelName} className="grid-title">
				<Typo $typoType={!$isCompact ? 'hm8' : 'hm9'}>
					{title}
					{tooltip && <ToolTip title={tooltip || ''} />}
				</Typo>
			</label>
			<div className="grid-children">
				{children}
				{essential && <Essential>*</Essential>}
			</div>
		</FilterGridItemContainer>
	);
}

interface GridLayoutProps {
	title?: string;
	$columnCount?: number;
	$isCompact?: boolean;
}

interface GridLayoutContainerStyleProps {
	$columnCount: number;
	$isCompact?: boolean;
}

const GridLayoutTitle = styled.h1`
	${({ theme }) => theme.typo.h7};
	color: ${({ theme }) => theme.common.colors.gray_11};
`;

const GridLayoutArticle = styled.article`
	${GridLayoutTitle} {
		padding-bottom: 12px;
	}
`;

const GridLayoutContainer = styled.div<GridLayoutContainerStyleProps>`
	display: grid;
	grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
	border-top: 1px solid ${({ theme }) => theme.common.colors.gray_2};
	border-top-width: ${({ $isCompact }) => $isCompact && '0'};
	padding: ${({ $isCompact }) => ($isCompact ? '0' : '')};
`;

function GridLayout({
	children,
	title,
	$columnCount = 4,
	$isCompact = false,
}: PropsWithChildren<GridLayoutProps>) {
	return (
		<GridLayoutArticle>
			{title && <GridLayoutTitle>{title}</GridLayoutTitle>}
			<GridLayoutContainer $columnCount={$columnCount} $isCompact={$isCompact}>
				{children}
			</GridLayoutContainer>
		</GridLayoutArticle>
	);
}

export default GridLayout;
