import React from 'react';
import styled from 'styled-components';
import { Pagination, Skeleton } from 'antd';
import { GridApi } from 'ag-grid-community';
import ContentBoxWithHeader from '../ContentBoxWithHeader';
import Grid from '../../atoms/Grid';
import { createColList } from '../../../utils/grid-util';
import { ContractUpdatedHistory } from '../../../interface/vehicleSubscription';
import { VehicleHistoryChange } from '../../../interface/vehicle';
import Typo from '../../atoms/Typo';

interface HistoryDetailProps {
	gridRef?: (api: GridApi) => void;
	gridTitle: string;
	colsList: { colsList: any[] };
	isFetching: boolean;
	rowData: Partial<ContractUpdatedHistory>[] | Partial<VehicleHistoryChange>[];
	totalCount: number;
	currentPage: number;
	currentPageSize: number;
	handlePageChange: (page: number) => void;
	handlePageSizeChange: (page: number, size: number) => void;
}

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_CURRENT_PAGE = 1;

const HistoryDetailSection = styled.div`
	display: flex;
	flex-direction: column;

	.content-wrapper {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border: none;
	}

	.inner-content-grid {
		flex: 1;
	}

	.grid-content-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.grid-content {
		flex: 1;
		margin-bottom: 16px;
	}

	.grid-content-body {
		height: 400px;
	}

	.grid-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.grid-pagination {
		display: flex;
		align-items: center;
	}
	.grid-total-count {
		display: flex;
		gap: 8px;
	}
`;

const HistoryDetail = ({
	gridRef,
	gridTitle,
	colsList,
	isFetching,
	rowData,
	totalCount,
	currentPage,
	currentPageSize,
	handlePageChange: onPageChange,
	handlePageSizeChange: onPageSizeChange,
}: HistoryDetailProps) => {
	return (
		<HistoryDetailSection>
			<ContentBoxWithHeader
				title={gridTitle}
				borderRadius="10px"
				className="inner-content-grid"
				innerClassName="grid-content-wrapper"
			>
				{isFetching ? (
					<Skeleton
						active
						paragraph={{ rows: 6 }}
						style={{ borderRadius: '10px' }}
					/>
				) : (
					<>
						<div className="grid-content">
							<div className="grid-content-body">
								<Grid
									cols={createColList(colsList.colsList)}
									rowData={rowData}
									gridRef={gridRef}
								/>
							</div>
						</div>
						<div className="grid-footer">
							<div className="grid-total-count">
								<Typo $typoType="hm8" color="gray_8">
									총
								</Typo>
								<Typo $typoType="hm8">{totalCount?.toLocaleString()}</Typo>
							</div>
							<div className="grid-pagination">
								<Pagination
									showSizeChanger
									onShowSizeChange={onPageSizeChange}
									onChange={onPageChange}
									defaultCurrent={DEFAULT_CURRENT_PAGE}
									current={currentPage}
									defaultPageSize={DEFAULT_PAGE_SIZE}
									pageSize={currentPageSize}
									total={totalCount}
									locale={{ items_per_page: '개씩 보기' }}
								/>
							</div>
						</div>
					</>
				)}
			</ContentBoxWithHeader>
		</HistoryDetailSection>
	);
};

export default HistoryDetail;
