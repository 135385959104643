import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CompanyInfo from '../../organisms/CompanyInfo';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import { COMPANY_DETAIL_PATH } from '../../../routes/constants/urls';
import { RootState } from '../../../store';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const CompanyInfoTemplate = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.auth);
	const companyId = user?.company?.id;

	const handleUpdateClick = () => {
		// 디테일 페이지로 이동해서 수정하기
		navigate(`${COMPANY_DETAIL_PATH}/${companyId}`);
	};

	return (
		<GridDetailTemplate
			detailTitle="법인 관리"
			rightAccessory={
				<ButtonContainer>
					<Button onClick={handleUpdateClick} size="small">
						법인 정보 수정
					</Button>
				</ButtonContainer>
			}
		>
			<CompanyInfo />
		</GridDetailTemplate>
	);
};

export default CompanyInfoTemplate;
