import dayjs from 'dayjs';
import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { numberWithCommas } from '../../../../utils/data-format';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { vehicleSubscriptionConsultationCustomerType } from '../../../../interface/vehicleSubscriptionConsulting';

const userData: () => GridDataModel = () => {
	return {
		formInitialValues: {},
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '등록일자',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionVehicleQuoteNo',
					headerName: '구독신청번호',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, string>) => {
						return value?.split('_')[0];
					},
				},
			},
			{
				tableInfo: {
					field: 'customerType',
					headerName: '고객유형',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerName',
					headerName: '고객명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerPhone',
					headerName: '고객연락처',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerEmail',
					headerName: '고객이메일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'contactName',
					headerName: '담당자명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscriptionStartedAt',
					headerName: '구독시작일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionEndedAt',
					headerName: '구독종료일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'subscriptionPeriod',
					headerName: '구독기간(일)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value || '0';
					},
				},
			},
			{
				tableInfo: {
					field: 'endActionType',
					headerName: '만기시',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'subscriptionPrice',
					headerName: '월 구독료(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'vehiclePrice',
					headerName: '차량가(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'prePaymentPrice',
					headerName: '선납금(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'acquisitionPrice',
					headerName: '인수잔가(원)',
				},
				options: {
					minWidth: ColSize.CW_SMALL_M,
					flex: 1,
					cellRenderer: ({ value }: ICellRendererParams<any, number>) => {
						return numberWithCommas(value ?? 0);
					},
				},
			},
			{
				tableInfo: {
					field: 'agCommissionPct',
					headerName: 'AG수수료(%)',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'memo',
					headerName: '메모',
				},
				options: {
					minWidth: ColSize.CW_LONG_TEXT,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'requestVehiclePlate',
					headerName: '번호판 신청유무',
				},
				options: {
					minWidth: ColSize.CW_LONG_TEXT,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value ? '신청함' : '신청안함';
					},
				},
			},
			{
				tableInfo: {
					field: 'paymentMethod',
					headerName: '결제수단',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 3,
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: [
			{
				key: 'subscriptionStartedAt',
				type: FilterElementType.DATE_RANGE_PICKER,
				label: '구독시작일',
			},
			{
				key: 'customerType',
				label: '고객유형',
				type: FilterElementType.SELECT,
				options: [
					{ label: '전체', value: '' },
					...vehicleSubscriptionConsultationCustomerType.map((item) => ({
						value: item,
						label: item,
					})),
				],
			},
			{
				key: 'customerName',
				type: FilterElementType.INPUT,
				label: '고객명',
			},
		],
	};
};
export default userData;
