import { Radio } from 'antd';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../components/atoms/Alert';
import Button from '../../../components/atoms/Button';
import ContentBoxItem from '../../../components/atoms/ContentBoxItem';
import Input from '../../../components/atoms/Input';
import Label from '../../../components/atoms/Label';
import Select from '../../../components/atoms/Select';
import Typo from '../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import InputForm from '../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../contexts/Toast';
import { Service } from '../../../interface/appVersion';
import { TermsType, TermsTypeList } from '../../../interface/terms';
import { TERMS_MANAGEMENT_PATH } from '../../../routes/constants/urls';
// import { TERMS_MANAGEMENT_PATH } from '../../routes/constants/urls';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	getTermsTypeList,
	useCreateTermMutation,
} from '../../../store/apis/terms';
import { ButtonTypeType } from '../../../styles/theme';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

function TermsCreatePage() {
	const [termTitle, setTermtTitle] = useState<string>('');
	const [flatform, setFlatform] = useState<Service>(Service.CUSTOMER_APP);
	const [isOptionable, setIsOptionable] = useState(false);
	const [isViewable, setIsViewable] = useState(true);
	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const [termsType, setTermsType] = useState<TermsType>();
	const [typeOptionList, setTypeOptionList] = useState<TermsTypeList[]>([]);
	const [isTypeOptionLoading, setIsTypeOptionLoading] = useState(true);

	const setToast = useContext(ToastContext);

	const navigate = useNavigate();
	const [createTerm] = useCreateTermMutation();

	const goBack = () => {
		navigate(-1);
	};

	const handleConfrim = () => {
		setIsAlertVisible(true);
	};

	const handleAlertClose = () => {
		setIsAlertVisible(false);
	};

	const handleSubmit = async () => {
		const result = await createTerm({
			title: termTitle,
			optionable: isOptionable,
			service: flatform,
			viewable: isViewable,
			type: termsType as TermsType,
		});

		if (!('error' in result) && result.data) {
			setToast('info', '약관 등록이 완료되었습니다.');
			navigate(TERMS_MANAGEMENT_PATH);
		} else if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}

		setIsAlertVisible(false);
	};

	const handleInputChange = (value: string) => {
		setTermtTitle(value);
	};

	useEffect(() => {
		(async () => {
			try {
				const res = await getTermsTypeList();
				setTypeOptionList(res);
				setIsTypeOptionLoading(false);
			} catch (e) {
				setIsTypeOptionLoading(true);
				const err = e as AxiosError<{ message: string; translate: string }>;

				if (err.response)
					setToast(
						'error',
						err.response.data.translate || err.response.data.message,
					);
			}
		})();
	}, [setToast]);

	const renderAlert = () => {
		if (isAlertVisible) {
			return (
				<Alert
					title="약관 등록"
					isVisible={isAlertVisible}
					closeButtonClick={handleAlertClose}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={handleSubmit}
				>
					<Typo $typoType="b5" color="gray_8">
						약관을 등록하시겠습니까?
					</Typo>
				</Alert>
			);
		}

		return null;
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="약관 등록"
				onBack={goBack}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={goBack}
							size="small"
						>
							취소
						</Button>
						<Button
							onClick={handleConfrim}
							size="small"
							disabled={!termTitle || !termsType}
						>
							등록하기
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="약관 내용"
					borderRadius="10px"
					className="inner-content"
				>
					<ContentBoxItem>
						<InputForm>
							<Label name="termTitle" essential>
								<span>
									<Typo $typoType="label2">약관명</Typo>
								</span>
							</Label>
							<Input
								name="termTitle"
								placeholder="약관명을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={termTitle}
								onChange={({ target }) => {
									handleInputChange(target.value);
								}}
							/>
						</InputForm>
					</ContentBoxItem>

					<ContentBoxItem>
						<Label name="flatform" essential>
							<span>
								<Typo $typoType="label2">플랫폼</Typo>
							</span>
						</Label>
						<Radio.Group
							onChange={(e) => {
								setFlatform(e.target.value);
							}}
							value={flatform}
						>
							<Radio value={Service.CUSTOMER_APP}>
								<Typo $typoType="label1">소비자 앱</Typo>
							</Radio>
							<Radio value={Service.MANAGER_APP}>
								<Typo $typoType="label1">매니저 앱</Typo>
							</Radio>
						</Radio.Group>
					</ContentBoxItem>

					<ContentBoxItem>
						<InputForm>
							<Label name="productType" essential>
								<span className="label">
									<Typo $typoType="label2">적용 영역</Typo>
								</span>
							</Label>
							<Select
								value={termsType}
								onChange={(value) => setTermsType(value)}
								loading={isTypeOptionLoading}
								placeholder="적용 영역을 선택해주세요"
							>
								{typeOptionList.map(({ value, key }) => (
									<Select.Option value={key} key={key}>
										{value}
									</Select.Option>
								))}
							</Select>
						</InputForm>
					</ContentBoxItem>

					<ContentBoxItem>
						<Label name="viewable" essential>
							<span>
								<Typo $typoType="label2">필수 여부</Typo>
							</span>
						</Label>
						<Radio.Group
							onChange={(e) => {
								setIsOptionable(e.target.value);
							}}
							value={isOptionable}
						>
							<Radio value={false}>
								<Typo $typoType="label1">필수</Typo>
							</Radio>
							<Radio value>
								<Typo $typoType="label1">선택</Typo>
							</Radio>
						</Radio.Group>
					</ContentBoxItem>
					<ContentBoxItem>
						<Label name="viewable" essential>
							<span>
								<Typo $typoType="label2">노출 여부</Typo>
							</span>
						</Label>
						<Radio.Group
							onChange={(e) => {
								setIsViewable(e.target.value);
							}}
							value={isViewable}
						>
							<Radio value>
								<Typo $typoType="label1">노출</Typo>
							</Radio>
							<Radio value={false}>
								<Typo $typoType="label1">미노출</Typo>
							</Radio>
						</Radio.Group>
					</ContentBoxItem>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
}

export default TermsCreatePage;
