import React from 'react';
import { useParams } from 'react-router-dom';
import VehicleSubscriptionQuotesTemplate from '../../../../components/templates/VehicleSubscriptionQuotesTemplate';
import { useGetVehicleSubscriptionQuoteDetailsQuery } from '../../../../store/apis/vehicleSubscriptionQuotes';

const VehicleQuotesDetail = () => {
	const { quoteNo } = useParams();

	const { data } = useGetVehicleSubscriptionQuoteDetailsQuery(
		{
			quoteNo: quoteNo as string,
		},
		{ skip: !quoteNo },
	);

	const vehicleSubscriptionQuote = data?.rows || [];

	return (
		<VehicleSubscriptionQuotesTemplate
			initialValues={{ rows: vehicleSubscriptionQuote }}
		/>
	);
};

export default VehicleQuotesDetail;
