import React, { useContext, useState } from 'react';
import { ButtonTypeType } from '../../../styles/theme';
import Button from '../../atoms/Button';
import { useCancelVehicleSubscriptionMutation } from '../../../store/apis/vehicleSubscription';
import { CustomErrorType } from '../../../store/apis/@types';
import { ToastContext } from '../../../contexts/Toast';
import Alert from '../../atoms/Alert';

interface CancelButtonProps {
	svNo: string;
}

const CancelButton = ({ svNo }: CancelButtonProps) => {
	const toast = useContext(ToastContext);
	const [isAlert, setIsAlert] = useState(false);

	const handleAlertOpen = () => {
		setIsAlert(true);
	};

	const handleAlertClose = () => {
		setIsAlert(false);
	};

	const [cancelVS] = useCancelVehicleSubscriptionMutation();

	const handleSubmit = async () => {
		const result = await cancelVS({
			svNo,
		});

		if (!('error' in result)) {
			toast('info', '해지신청을 완료하였습니다.');
			handleAlertClose();
		} else if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}
	};

	return (
		<>
			<Button
				size="small"
				$buttonType={ButtonTypeType.GHOST_DANGER}
				onClick={handleAlertOpen}
			>
				해지신청
			</Button>
			<Alert
				title="해지 신청"
				closeButtonClick={handleAlertClose}
				onConfirmButtonText="신청하기"
				onConfirmButtonClick={() => handleSubmit()}
				isVisible={isAlert}
			>
				구독을 해지 신청하시겠습니까?
			</Alert>
		</>
	);
};

export default CancelButton;
