import React from 'react';
import { MakeDriverDto } from '../../../../interface/driver';
import DriverTemplate from '../../../../components/templates/DriverTemplate';

const DriverCreate = () => {
	const initialValue: MakeDriverDto = {
		driverName: '',
		driverPhone: '',
		driverLicenseNumber: '',
		customerId: null,
		companyId: 0,
	};
	return <DriverTemplate initialValues={initialValue} />;
};

export default DriverCreate;
