import { useEffect, useRef, useState } from 'react';

const DEFAULT_REFETCH_TIMEOUT = 30000;

const useInterval = (timeout?: number) => {
	const timeoutIdCurrent = useRef<ReturnType<typeof setInterval> | null>(null);
	const [callback, setCallback] = useState<(() => void) | null>(null);

	useEffect(() => {
		if (timeoutIdCurrent.current) {
			clearInterval(timeoutIdCurrent.current);
		}

		if (callback) {
			timeoutIdCurrent.current = setInterval(
				() => callback(),
				timeout || DEFAULT_REFETCH_TIMEOUT,
			);
		}

		return () => {
			if (timeoutIdCurrent.current) {
				clearInterval(timeoutIdCurrent.current);
			}
		};
	}, [callback, timeout]);

	return [setCallback];
};

export default useInterval;
