import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import dayjs from 'dayjs';
import Typo from '../../../../components/atoms/Typo';
import { INQUIRY_REPLIED_VALUE } from '../../../../utils/const';
import { phoneFormat } from '../../../../utils/data-format';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';

type OneOnOneChatDataType = Omit<GridDataModel, 'forms'>;

const renderChatStatusCell = ({ value }: ICellRendererParams) => {
	switch (value) {
		case INQUIRY_REPLIED_VALUE:
			return (
				<Typo $typoType="b6" color="gray_11">
					&#8226; 답변완료
				</Typo>
			);
		default:
			return (
				<Typo $typoType="b6" color="danger_5_main">
					&#8226; 답변대기
				</Typo>
			);
	}
};

const oneOnOneChatData: OneOnOneChatDataType = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'service',
				headerName: '문의 출처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: any) => {
					if (value === 'CUSTOMER_APP') return '소비자';

					if (value === 'MANAGER_APP') return '매니저';

					return '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'category',
				headerName: '문의유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: any) => {
					return value;
				},
			},
		},
		{
			tableInfo: {
				field: 'customer.customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customer.customerPhone',
				headerName: '휴대폰 번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					if (!value) return '-';

					return phoneFormat(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'status',
				headerName: '답변유무',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderChatStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
};

export default oneOnOneChatData;
